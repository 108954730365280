import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { IAceEditorProps } from 'react-ace';
import { Ace } from 'ace-builds';
import { IAceEditor } from 'react-ace/lib/types';
import { isEmpty } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgAceEditorInput, ArgAceEditorInputError, ArgAceLanguage } from './arg-input-expression-editor';
import { Snippet, SNIPPET_DND_TYPE, SnippetsRepository } from './types';
import { ArgInputExpressionInformationPanel, InformationText } from './arg-input-expression-information-panel';
import { GLOBAL_PM, ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ArgInputExpressionCompleter } from './arg-input-expression-completer';
import { useToolContext } from '../arg-tools/use-tool-context';
import { useSetTimeout } from '../arg-hooks/use-set-timeout';
import { useArgNotifications } from '../arg-notifications/notifications';
import { ArgInputExpressionSnippetsPanel } from './arg-input-expression-snippets-panel';
import { useCallbackAsync } from '../arg-hooks/use-callback-async';
import { useToolItem } from '../arg-tools/use-tool-item';
import { ArgButton } from '../arg-button/arg-button';
import { getToolIcon, getToolLabel, Tool } from '../arg-tools/tool';
import { ArgUploaderButton } from '../arg-uploader/arg-uploader-button';
import { ArgDragAndDropUploader } from '../arg-uploader/arg-drag-and-drop-uploader';
import { ArgToolbarLayout } from '../arg-toolbar/arg-toolbar-layout';
import { DND_NO_SUPPORT, DND_SUPPORTED, DndAction, Droppable } from '../arg-dnd/droppable';
import { ListPanel } from '../../common/list-panel/list-panel';
import { ToolContext } from '../arg-tools/tool-context';
import { useMemoAsync } from '../arg-hooks/use-memo-async';
import { ArgTable2, ArgTable2Column } from '../arg-table/arg-table2';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgChangeReason, ArgRenderedText, ArgRenderFunction } from '../types';
import { ArgContextMenu, ArgContextMenuPosition } from '../arg-tools/arg-context-menu';
import { ArgToolMenu } from '../arg-tools/arg-tool-menu';

import './arg-input-expression-expander.less';

const messages = defineMessages({
    compile: {
        id: 'basic.arg-input-expression-expander.Compile',
        defaultMessage: 'Compile',
    },
    compileError: {
        id: 'basic.arg-input-expression-expander.CompileError',
        defaultMessage: 'Can not compile expression',
    },
    startTest: {
        id: 'basic.arg-input-expression-expander.StartTest',
        defaultMessage: 'Test',
    },
    stopTest: {
        id: 'basic.arg-input-expression-expander.StopTest',
        defaultMessage: 'Stop',
    },
    testError: {
        id: 'basic.arg-input-expression-expander.TestError',
        defaultMessage: 'Can not test expression',
    },
    import: {
        id: 'basic.arg-input-expression-expander.Import',
        defaultMessage: 'Import',
    },
    export: {
        id: 'basic.arg-input-expression-expander.Export',
        defaultMessage: 'Export',
    },
    importError: {
        id: 'basic.arg-input-expression-expander.importError',
        defaultMessage: 'The imported file isn\'t compatible',
    },
    codeSnippetsTooltip: {
        id: 'basic.arg-input-expression-expander.CodeSnippetsTooltip',
        defaultMessage: 'Show/hide code snippets',
    },
    errorsTitle: {
        id: 'basic.arg-input-expression-expander.errorsTitle',
        defaultMessage: 'Syntax errors ({count})',
    },
    expressionShowErrors: {
        id: 'basic.arg-input-expression-expander.showErrors',
        defaultMessage: 'Show syntax errors',
    },
    expressionHideErrors: {
        id: 'basic.arg-input-expression-expander.hideErrors',
        defaultMessage: 'Hide syntax errors',
    },
    successesTitle: {
        id: 'basic.arg-input-expression-expander.successesTitle',
        defaultMessage: 'Succeed tested expressions ({count})',
    },
    expressionsCompiledSuccess: {
        id: 'basic.arg-input-expression-expander.expressionsCompiledSuccess',
        defaultMessage: '{count, plural, =1 {Expression compiled with success} other {{count} expressions compiled with success}}',
    },
    expressionsTestedSuccess: {
        id: 'basic.arg-input-expression-expander.expressionsTestedSuccess',
        defaultMessage: '{count, plural, =1 {Expression tested with success ({result})} other {{count} expressions tested with success ({result})}}',
    },
    expressionResultCount: {
        id: 'basic.arg-input-expression-expander.expressionResultCount',
        defaultMessage: '{count, plural, =0 {no result} =1 {{count} result} other {{count} results}}',
    },
    expressionShowSuccesses: {
        id: 'basic.arg-input-expression-expander.showSuccesses',
        defaultMessage: 'Show tested expressions',
    },
    expressionHideSuccesses: {
        id: 'basic.arg-input-expression-expander.hideSuccesses',
        defaultMessage: 'Hide tested expressions',
    },
    valueColumn: {
        id: 'basic.arg-input-expression-expander.valueColumn',
        defaultMessage: 'Value',
    },
    itemsFound: {
        id: 'basic.arg-input-expression-expander.itemsFound',
        defaultMessage: '{count, plural, =1 {result found:} other {{count} results found:}}',
    },
    dropSnippetMessage: {
        id: 'basic.arg-input-expression-expander.DropSnippetMessage',
        defaultMessage: 'Add code snippet to the editor by dropping it here',
    },
    informationTooltip: {
        id: 'basic.arg-input-expression-expander.InformationTooltip',
        defaultMessage: 'Show/hide information panel',
    },
    snippetsError: {
        id: 'basic.arg-input-expression-expander.SnippetsError',
        defaultMessage: 'Can not get snippets',
    },
});

export type TestResultValue = any;

export interface TestResult {
    errors?: ArgAceEditorInputError[];
    values?: TestResultValue[];
    result?: number;
}

const DEFAULT_DEBOUNCE_DELAY = 1000;
const ROW_HEIGHT = 10;

export interface ArgInputExpressionExpanderProps {
    className?: ClassValue;
    bodyClassName?: ClassValue;
    editorToolbarClassName?: ClassValue;
    editorClassName?: ClassValue;
    valueEditor: string | undefined;
    title?: ArgRenderedText;
    onCancel?: (cursor?: Ace.Point) => void;
    aceProps?: IAceEditorProps;
    toolContext?: ToolContext;
    menuContext?: ToolContext;
    compileDisabled?: boolean;
    compileTooltip?: MessageDescriptor;
    testDisabled?: boolean;
    testTooltip?: MessageDescriptor;
    language: string | ArgAceLanguage;
    cursorStart?: Ace.Point;
    snippetsRepository?: SnippetsRepository;
    externalSnippets?: Snippet[];
    information?: InformationText;
    onImport?: (blob: Blob, progressMonitor: ProgressMonitor) => Promise<string | undefined>;
    onExport?: (value: string) => void;
    onSelectionChange?: (value?: string) => void;
    onSave?: (value: string, language: string | ArgAceLanguage, cursor?: Ace.Point) => void;
    onChange?: (value: string | null, reason?: ArgChangeReason) => void;
    onCompile?: (progressMonitor: ProgressMonitor, value: string) => Promise<TestResult | undefined>;
    onTest?: (progressMonitor: ProgressMonitor, value: string) => Promise<TestResult | undefined>;
    onStopTest?: (progressMonitor: ProgressMonitor) => Promise<void>;
    completers?: ArgInputExpressionCompleter[] | ArgInputExpressionCompleter;
    emptyRenderer?: ArgRenderFunction;
    acceptedFiles?: string[];

    environmentContext?: any; // TODO OO: Define <T>
}

export function ArgInputExpressionExpander(props: ArgInputExpressionExpanderProps) {
    const {
        className,
        bodyClassName,
        editorToolbarClassName,
        editorClassName,
        compileDisabled,
        compileTooltip,
        testDisabled,
        testTooltip,
        valueEditor,
        aceProps,
        language,
        cursorStart,
        snippetsRepository,
        externalSnippets,
        menuContext,
        information,
        title,
        onExport,
        onSelectionChange,
        onImport,
        onSave,
        onChange,
        onCompile,
        onTest,
        onStopTest,
        toolContext: externalToolContext,
        emptyRenderer,
        completers,
        acceptedFiles = ['.json'],
        environmentContext,
    } = props;

    const intl = useIntl();

    const classNames = useClassNames('arg-input-expression-expander');
    const notifications = useArgNotifications();

    const internalToolContext = useToolContext('basic.input-expression-expander.toolbar');
    const toolbarContext = externalToolContext || internalToolContext;

    const aceEditorRef = useRef<IAceEditor>(null);

    const [contextMenuPosition, setContextMenuPosition] = useState<ArgContextMenuPosition>();

    const [errors, setErrors] = useState<ArgAceEditorInputError[]>();
    const [successes, setSuccesses] = useState<any[]>();

    const [editorValue, setEditorValue] = useState<string>(valueEditor || '');

    const [rightPanelId, setRightPanelId] = useState<string | undefined>(() => {
        if (snippetsRepository) {
            return 'code-snippets';
        }

        return 'information';
    });
    const [isBottomPanelVisible, setIsBottomPanelVisible] = useState<boolean>(() => {
        return !!errors?.length;
    });

    const autoSaveQuery = useSetTimeout(aceProps?.debounceChangePeriod || DEFAULT_DEBOUNCE_DELAY);

    const hasValueChanged = useMemo(() => {
        return editorValue !== valueEditor;
    }, [editorValue, valueEditor]);

    const [snippets = [], snippetsProgressMonitor, snippetsError] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        if (!snippetsRepository) {
            return;
        }

        try {
            const ret = await snippetsRepository.getSnippets(progressMonitor);

            return ret;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.snippetsError }, error as Error);
        }
    }, [notifications, snippetsRepository]);

    const columns = useMemo<ArgTable2Column<TestResultValue>[]>(() => {
        const ret: ArgTable2Column<TestResultValue>[] = [];

        ret.push({
            key: 'value',
            ellipsis: true,
            title: messages.valueColumn,
            dataIndex: [],
            render: (data, rowData, rowIndex) => {
                if (!successes || !successes.length || rowIndex === undefined) {
                    return undefined;
                }

                const ret = successes[rowIndex]?.toString();

                return ret;
            },
        });

        return ret;
    }, [successes]);

    const handleSelectionChange = useCallback(() => {
        const value = aceEditorRef.current?.getSelectedText();

        onSelectionChange?.(value);
    }, [onSelectionChange]);

    const handleHideContextMenu = useCallback(() => {
        setContextMenuPosition(undefined);
    }, []);

    const handleRenderContextMenu = useCallback((getPopupContainer?: (node: HTMLElement) => HTMLElement) => {
        if (!menuContext) {
            return;
        }

        return <ArgToolMenu
            onCloseMenu={handleHideContextMenu}
            getPopupContainer={getPopupContainer}
            menuContext={menuContext}
            environmentContext={undefined}
        />;
    }, [handleHideContextMenu, menuContext]);

    const handleContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const selectedText = aceEditorRef.current?.getSelectedText();

        if (!selectedText || isEmpty(selectedText)) {
            return;
        }

        setContextMenuPosition({
            x: event.clientX,
            y: event.clientY,
        });
    }, []);

    const insertSnippetHandler = useCallback((snippet: Snippet) => {
        const editor = aceEditorRef.current!;

        editor.session.replace(editor.getSelection().getRange(), snippet.content);
        onSave?.(snippet.content, language, editor.getCursorPosition());
    }, [language, onSave]);

    const codeSnippetsRender = useCallback(() => {
        if (!snippets.length) {
            return;
        }

        const allSnippets = [...snippets, ...(externalSnippets || [])];

        return (
            <ArgInputExpressionSnippetsPanel
                    className={classNames('&-snippets')}
                    error={snippetsError}
                    language={language}
                    snippets={allSnippets}
                    progressMonitor={snippetsProgressMonitor}
                    onInsertSnippet={insertSnippetHandler}
            />
        );
    },
    [snippets, externalSnippets, snippetsError, language, snippetsProgressMonitor, insertSnippetHandler]);

    const informationRender = useCallback(() => {
        if (!information) {
            return;
        }

        return (
            <ArgInputExpressionInformationPanel
                information={information}
            />
        );
    }, [information]);

    const handleSetRightPanelId = useCallback((panelId: string) => {
        setRightPanelId((prev) => {
            if (prev === panelId) {
                return undefined;
            }

            return panelId;
        });
    }, []);

    const codeSnippetsClick = useCallback(() => {
        handleSetRightPanelId('code-snippets');
    }, [handleSetRightPanelId]);

    const informationClick = useCallback(() => {
        handleSetRightPanelId('information');
    }, [handleSetRightPanelId]);

    const [handleSaveExpression, saveProgressMonitor] = useCallbackAsync(async (progressMonitor, value: string) => {
        const position = aceEditorRef.current?.getCursorPosition();

        if (!onSave || !position) {
            return;
        }

        try {
            await onSave(value, language, position);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.importError }, error as Error);
        }
    }, [language, notifications, onSave]);

    const handleEditorBlur = useCallback(() => {
        onChange?.(editorValue, 'blur');
    }, [editorValue, onChange]);

    const handleEditorChange = useCallback((value: string | null) => {
        const newValue = value || '';

        setEditorValue(newValue);
        //onChange?.(newValue, 'debounce');

        autoSaveQuery(() => handleSaveExpression(newValue));
    }, [autoSaveQuery, handleSaveExpression]);

    const [handleCompileQuery, compileProgressMonitor] = useCallbackAsync(async (progressMonitor) => {
        if (!onCompile) {
            return;
        }

        if (hasValueChanged) {
            await handleSaveExpression(editorValue);
        }

        try {
            const results = await onCompile(progressMonitor, editorValue);

            if (!results) {
                return;
            }

            const { errors } = results;

            setErrors(errors);

            if (!errors || !errors.length) {
                const expressions = editorValue?.split('/n');

                notifications.snackSuccess({ message: messages.expressionsCompiledSuccess }, { count: expressions.length });
            }
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.compileError }, error as Error);
        }
    }, [onCompile, hasValueChanged, handleSaveExpression, editorValue, notifications]);

    const [handleTestQuery, testProgressMonitor] = useCallbackAsync(async (progressMonitor) => {
        if (testProgressMonitor?.isRunning) {
            await onStopTest?.(progressMonitor);
        } else {
            if (!onTest) {
                return;
            }

            if (hasValueChanged) {
                await handleSaveExpression(editorValue);
            }

            try {
                const results = await onTest(progressMonitor, editorValue);

                if (!results) {
                    return;
                }

                const { errors, values } = results;
                const resultCount = results?.result || values?.length || 0;

                setErrors(errors);
                setSuccesses(values);

                if (!errors || !errors.length) {
                    const expressions = editorValue?.split('/n');

                    notifications.snackSuccess({
                        message: messages.expressionsTestedSuccess,
                    }, {
                        count: expressions.length,
                        result: intl.formatMessage(messages.expressionResultCount, { count: resultCount }),
                    });
                }
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    throw error;
                }

                notifications.snackError({ message: messages.testError }, error as Error);
            }
        }
    }, [onStopTest, onTest, hasValueChanged, handleSaveExpression, editorValue, notifications, intl]);

    const renderTestButton = useCallback(() => {
        if (!onCompile && !onTest) {
            return;
        }

        const isCompileDisabled = compileDisabled || editorValue.length === 0 || compileProgressMonitor?.isRunning;
        const isTestDisabled = testDisabled || editorValue.length === 0 || saveProgressMonitor?.isRunning;

        if (onCompile) {
            const compileButton = <ArgButton
                type='primary'
                tooltip={compileTooltip}
                label={messages.compile}
                disabled={isCompileDisabled}
                data-testid='compile-button'
                onClick={handleCompileQuery}
                className={classNames('&-toolbar-compile-button')}
                loading={false /*saveProgressMonitor?.isRunning*/}
            />;

            if (!onTest) {
                return compileButton;
            }

            return <>
                {compileButton}
                <ArgButton
                    type='secondary'
                    tooltip={testTooltip}
                    label={messages.startTest}
                    disabled={isTestDisabled || saveProgressMonitor?.isRunning}
                    data-testid='test-button'
                    onClick={handleTestQuery}
                    className={classNames('&-toolbar-test-button')}
                />
            </>;
        }

        return (
            <ArgButton
                data-testid='test-button'
                tooltip={testTooltip}
                disabled={isTestDisabled}
                loading={saveProgressMonitor?.isRunning}
                type={testProgressMonitor?.isRunning ? 'secondary-danger' : 'primary'}
                label={testProgressMonitor?.isRunning ? messages.stopTest : messages.startTest}
                onClick={handleTestQuery}
                className={classNames('&-toolbar-compile-button')}
            />
        );
    }, [compileDisabled, compileProgressMonitor?.isRunning, compileTooltip, editorValue.length, handleCompileQuery, handleTestQuery, onCompile, onTest, saveProgressMonitor?.isRunning, testDisabled, testProgressMonitor?.isRunning, testTooltip]);

    // Start/Stop test button
    useToolItem(
        toolbarContext,
        {
            path: 'right/end/test',
            order: 500,
        },
        {
            visible: !!onTest || !!onCompile,
            customRender: renderTestButton,
        },
    );

    // Code Snippets Panel
    useToolItem(
        toolbarContext,
        {
            path: 'right/end/snippets',
            type: 'panel',
            icon: 'icon-code-braces',
            testid: 'snippets-button',
            tooltip: messages.codeSnippetsTooltip,
            order: 600,
        },
        {
            onClick: codeSnippetsClick,
            panelRender: codeSnippetsRender,
            visible: !!snippetsRepository || !!snippetsProgressMonitor?.isRunning,
            selected: rightPanelId === 'code-snippets',
        },
    );

    // Information Panel
    useToolItem(
        toolbarContext,
        {
            path: 'right/end/information',
            type: 'panel',
            icon: 'icon-information',
            testid: 'information-button',
            tooltip: messages.informationTooltip,
            order: 700,
        },
        {
            onClick: informationClick,
            visible: !!information,
            panelRender: informationRender,
            selected: rightPanelId === 'information',
        },
    );

    const [handleImportExpression] = useCallbackAsync(async (progressMonitor: ProgressMonitor, blob: Blob) => {
        if (!onImport) {
            return;
        }
        try {
            const expression = await onImport(blob, progressMonitor);
            if (!expression) {
                return;
            }
            setEditorValue(expression);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.importError }, error as Error);
            throw error;
        }
    }, [notifications, onImport], undefined, undefined, GLOBAL_PM);

    const handleExportExpression = useCallback(() => {
        if (!onExport) {
            return;
        }
        onExport(editorValue);
    }, [editorValue, onExport]);

    const onCloseBottomPanel = useCallback(() => {
        setIsBottomPanelVisible(false);
    }, []);

    const handlePanelVisibility = useCallback(() => {
        setIsBottomPanelVisible((visible) => !visible);
    }, []);

    const renderImportButton = useCallback((tool: Tool) => {
        return (
            <ArgUploaderButton
                icon={getToolIcon(tool, environmentContext)}
                label={getToolLabel(tool, environmentContext)}
                type='ghost'
                size='medium'
                className={classNames('&-toolbar-import-button')}
                acceptedFiles={acceptedFiles?.join(',')}
                method={handleImportExpression}
            />
        );
    }, [handleImportExpression, classNames, acceptedFiles, environmentContext]);

    const renderExportButton = useCallback((tool: Tool) => {
        return (
            <ArgButton
                icon={getToolIcon(tool, environmentContext)}
                label={getToolLabel(tool, environmentContext)}
                className={classNames('&-toolbar-export-button')}
                type='ghost'
                onClick={handleExportExpression}
                disabled={!editorValue}
                data-testid='expression-expander-export'
            />
        );
    }, [handleExportExpression, editorValue, classNames, environmentContext]);

    useToolItem(toolbarContext, {
        path: 'left/import-export',
        type: 'group',
        order: 100,
    });

    useToolItem(toolbarContext, {
        path: 'left/import-export/import',
        type: 'custom',
        icon: 'icon-download',
        label: messages.import,
    }, {
        customRender: renderImportButton,
        disabled: testProgressMonitor?.isRunning,
        visible: !!onImport,
    });

    useToolItem(toolbarContext, {
        path: 'left/import-export/export',
        type: 'custom',
        icon: 'icon-upload',
        label: messages.export,
    }, {
        customRender: renderExportButton,
        visible: !!onExport,
    });

    const hasErrors = !testProgressMonitor?.isRunning && !!errors?.length;
    const hasSuccesses = !testProgressMonitor?.isRunning && !!successes?.length;

    const bottomPanelLabel = useMemo<MessageDescriptor | undefined>(() => {
        if (!hasErrors && !hasSuccesses && !isBottomPanelVisible) {
            return undefined;
        }

        if (hasErrors) {
            return isBottomPanelVisible ? messages.expressionHideErrors : messages.expressionShowErrors;
        }

        return isBottomPanelVisible ? messages.expressionHideSuccesses : messages.expressionShowSuccesses;
    }, [hasErrors, hasSuccesses, isBottomPanelVisible]);

    const handleFocusLine = useCallback((lineNumber: number, columnNumber?: number) => {
        const editor = aceEditorRef.current;
        const selection = editor?.session.selection;

        selection?.clearSelection();
        selection?.moveCursorTo(lineNumber, columnNumber || 0);

        editor?.focus();
    }, []);

    const getItemLineNumber = useCallback((error: ArgAceEditorInputError) => {
        return error.line || 0;
    }, []);

    const getItemColumnNumber = useCallback((error: ArgAceEditorInputError) => {
        return error.column || 0;
    }, []);

    const getItemMessage = useCallback((error: ArgAceEditorInputError) => {
        return error.message;
    }, []);

    const dndAction = useMemo<DndAction>(() => {
        return {
            dragInfos: (event) => {
                if (!event.types.includes(SNIPPET_DND_TYPE)) {
                    return DND_NO_SUPPORT;
                }

                return DND_SUPPORTED;
            },
            onDrop: (dataTransfer) => {
            },
            dropEffect: 'copy',
        };
    }, []);

    useEffect(() => {
        setEditorValue(valueEditor || '');
    }, [valueEditor]);

    useEffect(() => {
        setIsBottomPanelVisible(!!errors?.length || !!successes?.length);
    }, [errors, successes]);

    useEffect(() => {
        setErrors(undefined);
        setSuccesses(undefined);
    }, [language]);

    const cls = {
        error: hasErrors,
        success: hasSuccesses,
    };

    return (
        <ArgDragAndDropUploader
            method={handleImportExpression}
            disabled={!onImport}
            className={classNames('&-dnd-uploader', className)}
        >
            <ArgToolbarLayout
                className={classNames('&-body', bodyClassName)}
                toolbarContext={toolbarContext}
                toolbarClassName={classNames('&-toolbar', editorToolbarClassName)}
                environmentContext={undefined}
            >
                {title && (
                    <span className={classNames('&-title')}>
                        {renderText(title)}
                    </span>
                )}
                <Droppable
                    actions={dndAction}
                    className={classNames('&-droppable')}
                >
                    {(provided, snapshot) => {
                        const dropMode = snapshot?.supportsDragging && snapshot?.draggingFromThisWith;

                        const isEmpty = !valueEditor && !!emptyRenderer;

                        const cls = {
                            '&-drop-zone': true,
                            '&-drop-mode': dropMode,
                        };

                        const editorCls = {
                            '&-editor-drop-mode': dropMode,
                        };

                        return <div
                            className={classNames(cls)}
                            style={isEmpty ? { position: 'relative' } : undefined}
                            data-label={intl.formatMessage(messages.dropSnippetMessage)}
                        >
                            <ArgAceEditorInput
                                cursorStart={cursorStart}
                                className={classNames('&-editor', editorCls, editorClassName)}
                                data-label={intl.formatMessage(messages.dropSnippetMessage)}
                                focus={true}
                                value={editorValue}
                                onChange={handleEditorChange}
                                language={language}
                                aceProps={{
                                    ...aceProps,
                                    debounceChangePeriod: undefined,
                                    showPrintMargin: false,
                                    readOnly: aceProps?.readOnly || testProgressMonitor?.isRunning,
                                    onSelectionChange: handleSelectionChange,
                                }}
                                inputRef={aceEditorRef}
                                errors={errors}
                                onContextMenu={handleContextMenu}
                                changeDebounce={DEFAULT_DEBOUNCE_DELAY}
                                completers={completers}
                                onBlur={handleEditorBlur}
                            />

                            {isEmpty && (
                                <div className={classNames('&-empty')}>
                                    {emptyRenderer()}
                                </div>
                            )}
                        </div>;
                    }}
                </Droppable>
                {isBottomPanelVisible && (hasErrors || hasSuccesses) && (
                    <div className={classNames('&-bottom-panel', cls)}>
                        {hasErrors && (
                            <ListPanel<ArgAceEditorInputError>
                                type='error'
                                title={messages.errorsTitle}
                                items={errors}
                                messageValues={{ count: errors?.length }}
                                onClose={onCloseBottomPanel}
                                getItemLineNumber={getItemLineNumber}
                                getItemColumnNumber={getItemColumnNumber}
                                getItemMessage={getItemMessage}
                                onFocusLine={handleFocusLine}
                            />
                        )}
                        {hasSuccesses && (
                            <div className={classNames('&-bottom-panel-successes')}>
                                <div className={classNames('&-bottom-panel-successes-header')}>
                                    <span className={classNames('&-bottom-panel-successes-header-title')}>
                                        {renderText(messages.itemsFound, { count: successes?.length })}
                                    </span>

                                    <ArgButton
                                        type='ghost'
                                        icon='icon-cross'
                                        className={classNames('&-bottom-panel-successes-header-close-button')}
                                        onClick={onCloseBottomPanel}
                                    />
                                </div>

                                <ArgTable2<TestResultValue>
                                    rowHeight={ROW_HEIGHT}
                                    bordered={true}
                                    columns={columns}
                                    dataSource={successes}
                                    className={classNames('&-bottom-panel-successes-table')}
                                />
                            </div>
                        )}
                    </div>
                )}

                {!isBottomPanelVisible && (hasErrors || hasSuccesses) && !testProgressMonitor?.isRunning && (
                    <ArgButton
                        icon='icon-embed2'
                        className={classNames('&-editor-button')}
                        label={bottomPanelLabel}
                        type='ghost'
                        onClick={handlePanelVisibility}
                    />
                )}

                {contextMenuPosition && (
                    <ArgContextMenu
                        visible={true}
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        overlay={handleRenderContextMenu}
                        onHide={handleHideContextMenu}
                    />
                )}
            </ArgToolbarLayout>
        </ArgDragAndDropUploader>
    );
}
