export type ConfigurationId = string;

export interface ImportExportOptions {
    options: ConfigurationOption[];
}

export interface OptionConfig {
    rawValue?: string;
    resetDefaultConfiguration?: boolean;
    setAsDefault?: boolean;
    parentId?: string;
    applicationId?: string;
    withPolicies?: boolean;
    requestedRolesEntities?: string[];
    everything?: boolean;
}

export interface ConfigurationOption {
    type: string;
    configurationKeys: string[];
    options?: OptionConfig;
    everything?: boolean;
}

export interface ImportExportManifest {
    configurations: ConfigurationManifest[];
}

export interface ConfigurationManifest {
    type: string;
    configurations: Configuration[];
}

export interface Configuration {
    id: ConfigurationId;
    metadata?: ConfigurationMetadata;
}

export interface ConfigurationImportResponseError {
    errors?: ConfigurationImportError[];
}

export interface ConfigurationImportError {
    type?: ImportConfigurationErrorType;
    code?: ImportConfigurationErrorCode;
    id?: string;
    configurationError?: ImportConfigurationError;
    description?: string;
}

type ConfigurationMetadata = ConfigurationTemplateMetadata | any;

export interface ConfigurationTemplateMetadata {
    templateName: string;
    parentId?: string;
}

export enum SynchronizationAction {
    RenameConflicts = 'RenameConflicts',
    UpdateExisting = 'UpdateExisting',
    Ignore = 'Ignore',
    ReplaceAll = 'ReplaceAll'
}

export enum ImportConfigurationError {
    BadIdentifier = 'BadIdentifier',
    FileNotFound = 'FileNotFound',
    ManifestNotFound = 'ManifestNotFound',
    RequestKeyNotFound = 'RequestKeyNotFound',
    ConfigurationNotFound = 'ConfigurationNotFound',
    ImportFailed = 'ImportFailed',
    ImportActionNotAllowed = 'ImportActionNotAllowed',
    ExportFailed = 'ExportFailed',
    ExportActionNotAllowed = 'ExportActionNotAllowed',
    ProviderNotFound = 'ProviderNotFound',
    ConfigurationList = 'ConfigurationList',
}

export enum ImportConfigurationErrorCode {
    OntologyNotFound = 'OntologyNotFound',
    ObjectTypeCreationFailed = 'ObjectTypeCreationFailed',
    RelationTypeCreationFailed = 'RelationTypeCreationFailed',
    MetaPropertyCreationFailed = 'MetaPropertyCreationFailed',
    ObjectTypeUpdateFailed = 'ObjectTypeUpdateFailed',
    RelationTypeUpdateFailed = 'RelationTypeUpdateFailed',
    MetaPropertyUpdateFailed = 'MetaPropertyUpdateFailed',
    OntologyStyleUpdateFailed = 'OntologyStyleUpdateFailed',
    RetentionPolicyUpdateFailed = 'RetentionPolicyUpdateFailed',
    RetentionPolicyPublicationFailed = 'RetentionPolicyPublicationFailed',
    ObjectFeedSourcesUpdateFailed = 'ObjectFeedSourcesUpdateFailed',
    RelationFeedSourcesUpdateFailed = 'RelationFeedSourcesUpdateFailed',
    MetaPropertyFeedSourcesUpdateFailed = 'MetaPropertyFeedSourcesUpdateFailed',
    ObjectDisplayConfigurationUpdateFailed = 'ObjectDisplayConfigurationUpdateFailed',
    RelationDisplayConfigurationUpdateFailed = 'RelationDisplayConfigurationUpdateFailed',
    OntologyPublishFailed = 'OntologyPublishFailed',
    MapUniverseFailed = 'MapUniverseFailed',
    OntologyUpdateFailed = 'OntologyUpdateFailed',
}

export enum ImportConfigurationErrorType {
    Generic = 'generic',
    AllApplicationSettings = 'all-application-settings',
    ContextualVariable = 'contextual-variable',
    DataAccessPolicies = 'data-access-policies',
    ValuationPolicies = 'valuation-policies',
    DisplayTemplatePolicies = 'display-template-policies',
    Ontologies = 'ontologies',
    Role = 'role',
    Template = 'template',
    Universes = 'universes',
    Users = 'users',
    UserGroups = 'user-groups',
    UserProfilesFieldsBundle = 'user-profiles-fields-bundle',
    Composites = 'composites',
    Cases = 'cases',
    Processes = 'processes',
    RemoteComponents = 'remote-components',
    Secrets = 'secrets'
}
