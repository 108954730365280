import React, { useMemo } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import Debug from 'debug';

import {
    ArgIcon,
    ArgRenderedText,
    ArgTable2,
    ArgTable2Column,
    ArgTooltip2,
    ClassValue,
    ProgressMonitor,
    useArgNotifications,
    useClassNames,
    useMemoAsync,
} from 'src/components/basic';
import { UserMetadata, UserMetadataValue, UserProfileField, UserProfileFieldId } from '../../../model/user-metadata';
import { UserMetadataField } from './user-metadata-field';
import { getPropertyIconName } from '../../../settings/utils/properties-utils';
import { ErrorPane } from '../panes/error-pane';
import { LoadingPane } from '../panes/loading-pane';
import { UsersConnector } from '../../../utils/connectors/users-connector';

import './user-metadata-table.less';

const CLASSNAME = 'common-user-metadata-table';

const FORCE_LOADING = false;
const FORCE_ERROR = false;

const messages = defineMessages({
    propertyTitle: {
        id: 'common.user-metadata.table.PropertyTitle',
        defaultMessage: 'Property',
    },
    propertyValue: {
        id: 'common.user-metadata.table.PropertyValue',
        defaultMessage: 'Value',
    },
    loading: {
        id: 'common.user-metadata.table.Loading',
        defaultMessage: 'Loading Metadata',
    },
    error: {
        id: 'common.user-metadata.table.Error',
        defaultMessage: 'Can not load Metadata',
    },
    profileError: {
        id: 'common.user-metadata.table.ProfileError',
        defaultMessage: 'Can not load Profile',
    },
});

const debug = Debug('components:common:user-metadata:user-metadata-table');

interface UserMetadataTableProps {
    className?: ClassValue;

    metadata: UserMetadata;

    onChange: (field: UserProfileField, value: any) => void;

    // if true, handle multi-values as comma separated list of values
    joinMultivalue?: boolean;

    // if omitted, user profiles are fetched using .usersConnector.
    userProfilesFields?: UserProfileField[];

    // the users connector, default to the one in src/utils/connectors/users-connector
    usersConnector?: UsersConnector;

    formErrors?: Record<UserProfileFieldId, ArgRenderedText>;

    // Disable all the table input field
    disabled?: boolean;
}

export function UserMetadataTable(props: UserMetadataTableProps) {
    const {
        className,
        onChange,
        metadata,
        joinMultivalue,
        usersConnector = UsersConnector.getInstance(),
        userProfilesFields,
        formErrors,
        disabled,
    } = props;

    debug('UserMetadataTable', 'metadata=', metadata);

    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const [fields, loadingFields, errorFields] = useMemoAsync<UserProfileField[]>(async (progressMonitor: ProgressMonitor) => {
        if (userProfilesFields) {
            return userProfilesFields;
        }

        try {
            const fields = await usersConnector.getUserProfileFields(progressMonitor);

            return fields;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            console.error(error);
            notifications.snackError({ message: messages.profileError }, error as Error);
            throw error;
        }
    }, []);

    const columns = useMemo<ArgTable2Column<UserProfileField>[]>(() => {
        const ret: ArgTable2Column<UserProfileField>[] = [{
            key: 'name',
            dataIndex: 'displayName',
            title: messages.propertyTitle,
            width: '30%',
            render: (displayName: string, field: UserProfileField) => {
                const cls = {
                    error: formErrors?.[field.id],
                };

                let label = <label className={classNames('&-row-label', cls)}>
                    <ArgIcon
                        className={classNames('&-row-label-icon')}
                        name={field.baseType ? getPropertyIconName(field.baseType, field.possibleValues !== undefined) : 'icon-question'}
                    />
                    <div className={classNames('&-row-label-text', 'clamp-2')}>
                        {displayName}
                    </div>
                </label>;

                if (field.description) {
                    label = <ArgTooltip2 title={field.description}>
                        {label}
                    </ArgTooltip2>;
                }

                return label;
            },
        }, {
            key: 'value',
            title: messages.propertyValue,
            dataIndex: 'displayName',
            render: (displayName: string, field: UserProfileField) => {
                return <UserMetadataField
                    className={classNames('&-row-value')}
                    field={field}
                    value={metadata[field.id]}
                    onChange={(value: UserMetadataValue | undefined) => onChange(field, value)}
                    joinMultivalue={joinMultivalue}
                    fieldError={formErrors?.[field.id]}
                    disabled={disabled}
                />;
            },

        }];

        return ret;
    }, [formErrors, classNames, metadata, joinMultivalue, onChange]);

    if (FORCE_ERROR || errorFields) {
        return <div className={classNames('&', 'error')}>
            <ErrorPane message={messages.loading} />
        </div>;
    }
    if (FORCE_LOADING || !fields || loadingFields?.isRunning) {
        return <div className={classNames('&', 'loading')}>
            <LoadingPane message={messages.loading} />
        </div>;
    }

    return (
        <ArgTable2<UserProfileField>
            className={classNames('&', 'scrollable-body', className)}
            getRowClassName={classNames('&-row')}
            rowKey='id'
            columns={columns}
            dataSource={fields}
        />
    );
}
