import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';

interface DragOption {
    index: number;
    id: string;
    type: string;
}

export interface DraggableItem extends Record<any, any> {
    id: string | number;
}

export const useReorder = (
    dragType: string,
    moveItems: (dragIndex: number, hoverIndex: number) => void,
    index: number,
    item: DraggableItem,
    onEnd?: (dragItem: { id: string | number; index: number }) => void,
) => {
    const ref = useRef(null);

    const [{ handlerId, canDrop }, drop] = useDrop<DragOption, void, { handlerId: Identifier | null; canDrop: boolean }>({
        accept: dragType,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                canDrop: monitor.canDrop(),
            };
        },
        hover(item: DragOption, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = (ref.current as HTMLElement)?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveItems(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: dragType,
        item: () => {
            return { id: item.id, index: index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (dragItem) => {
            onEnd && onEnd(dragItem);
        },
    });

    preview(drop(ref));

    return { handlerId, isDragging, drag, ref, canDrop };
};
