import React, { ReactNode, useContext } from 'react';

// The locales that we support
export type ArgUserLocale = 'fr'|'en';

export interface UserLocaleConfig {
    userLocale: ArgUserLocale;
    changeUserLocale: (newLocale: string) => Promise<void>;
}

interface UserLocaleProviderProps {
    userLocaleConfig: UserLocaleConfig;

    children: ReactNode;
}

export const UserLocaleContext = React.createContext<UserLocaleConfig | undefined>(undefined);

export function UserLocaleProvider(props: UserLocaleProviderProps) {
    const {
        userLocaleConfig,
        children,
    } = props;

    return (
        <UserLocaleContext.Provider value={userLocaleConfig}>
            {children}
        </UserLocaleContext.Provider>
    );
}

export function useUserLocale(): UserLocaleConfig {
    const userLocale = useContext(UserLocaleContext);

    if (userLocale === undefined) {
        throw new Error('useUserLocale must be used within a UserLocaleProvider');
    }

    return userLocale;
}
