import { defineMessages, useIntl } from 'react-intl';

import { ArgButton, ArgChangeReason, ArgCombo, useClassNames } from '../../../../basic';
import { CommonControlProps, useGetStyleModifier } from './utils';
import { BooleanControlMessages } from '../advanced-body';

import './discrete-control.less';

const messages = defineMessages({
    yesChoiceLabel: {
        id: 'exploration.boolean-control.YesChoiceLabel',
        defaultMessage: 'Yes',
    },
    noChoiceLabel: {
        id: 'exploration.boolean-control.NoChoiceLabel',
        defaultMessage: 'No',
    },
});

export interface BooleanControlProps extends CommonControlProps{
    fieldValue?: boolean;

    onFieldValueChange?: (value: boolean | undefined, reason: ArgChangeReason) => void;

    messages: BooleanControlMessages;
}

const getItemLabel = (values: [string, string]) => {
    return (value: boolean | undefined) => ((value === undefined || value === null) ? undefined : (value === true ? values[0] : values[1]));
};


export function BooleanControl({
    type,
    fieldValue,

    index,

    fillColor,
    strokeColor,
    iconColor,

    badgeFillColor,
    badgeIconName,
    doesBadgeBlink,

    size,

    iconName,
    lineStyle,

    onStyleChange,
    onFieldValueChange,
    canBeRemoved,
    onRemoveComponent,
    isUndefinedStyleRule,
    messages: externalMessages,
}: BooleanControlProps) {
    const classNames = useClassNames('discrete-control');
    const styleModifier = useGetStyleModifier(
        type,
        classNames,
        index,
        fillColor,
        iconColor,
        strokeColor,
        badgeFillColor,
        badgeIconName,
        size,
        iconName,
        onStyleChange,
        doesBadgeBlink,
        lineStyle,
    );
    const intl = useIntl();
    const cls = {
        size: type === 'size',
        'line-style': type === 'style',
    };

    const inputPlaceholder = isUndefinedStyleRule ? externalMessages.undefinedStyleRulePlaceholder : externalMessages.comboPlaceholder;

    const value = fieldValue === null ? undefined : fieldValue;

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-content', cls)}>
                {styleModifier}
                <ArgCombo<boolean>
                    placeholder={inputPlaceholder}
                    items={[true, false]}
                    cardinality='optional'
                    size='medium'
                    disabled={!!isUndefinedStyleRule}
                    value={value}
                    getItemLabel={getItemLabel([intl.formatMessage(messages.yesChoiceLabel), intl.formatMessage(messages.noChoiceLabel)])}
                    onChange={onFieldValueChange}
                />
            </div>

            {canBeRemoved && (
                <ArgButton
                    icon='icon-trash'
                    type='ghost'
                    className={classNames('&-trash-button')}
                    onClick={() => onRemoveComponent?.(type, index)}
                />
            )}
        </div>
    );
}
