import { Dispatch, SetStateAction, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { useArgModalManager } from 'src/components/basic/arg-modal/use-arg-modal-manager';
import retentionPolicyConnector from 'src/settings/connectors/retention-policy-connector';
import { RetentionPolicyActionTargetKind, RetentionPolicyVertexEdge } from 'src/settings/universes/retention/types';
import { VertexOrEdge } from 'src/exploration/hooks/use-graph-style-customisation';
import { ArgIcon, ClassValue, ProgressMonitor, useClassNames, useEffectAsync } from 'src/components/basic';
import { CreatePropertyModal } from '../property-and-metaproperty-modals/property-modals/create-property-modal';
import { PropertiesList } from '../property-components/properties-list';
import { RenameObjectModal } from '../rename-object-modal/rename-object-modal';
import { FullOntology, FullOntologyObjectType } from '../../types';
import { ObjectPropertiesPanelMenu } from '../object-properties-panel-menu/object-properties-panel-menu';

import './object-properties-panel.less';

const messages = defineMessages({
    title: {
        id: 'settings.object-properties.menu.title',
        defaultMessage: 'Object properties',
    },
    deletionAutomatic: {
        id: 'settings.object-properties.menu.deletionAutomatic',
        defaultMessage: 'Automatic deletion: {rule}',
    },
    definition: {
        id: 'settings.object-properties.menu.definition',
        defaultMessage: 'Properties',
    },
    newProperty: {
        id: 'settings.object-properties.menu.new-property',
        defaultMessage: 'New property',
    },
    fetchingRetentionError: {
        id: 'settings.object-properties.fetch-retention.message',
        defaultMessage: 'Loading retention',
    },
});

const DEFAULT_POLICIES: RetentionPolicyVertexEdge = {
    effects: [],
};

export interface ObjectPropertiesPanelProps {
    className?: ClassValue;

    vertex: FullOntologyObjectType;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    retentionSetUp: (retention: RetentionPolicyVertexEdge, type: RetentionPolicyActionTargetKind | 'Property', targetProperty?: string) => string;
    retention: RetentionPolicyVertexEdge;
    setRetention: Dispatch<SetStateAction<RetentionPolicyVertexEdge>>;
}

export function ObjectPropertiesPanel(props: ObjectPropertiesPanelProps) {
    const {
        className,
        vertex,
        ontology,
        setOntology,
        retentionSetUp,
        retention,
        setRetention,
    } = props;

    const classNames = useClassNames('settings-ontology-object-properties-panel');
    const intl = useIntl();

    const removeObjectModal = useArgModalManager((close) => {
        return (
            <RenameObjectModal
                closeModal={close}
                vertex={vertex}
                ontology={ontology}
                setOntology={setOntology}
            />
        );
    }, [vertex, ontology, setOntology]);

    const createPropertyModal = useArgModalManager((close) => {
        return (
            <CreatePropertyModal
                propertyList={vertex.properties}
                closeModal={close}
                edgeOrVertex={vertex}
                ontology={ontology}
                setOntology={setOntology}
                propertyOf={VertexOrEdge.Vertex}
            />
        );
    }, [vertex, ontology, setOntology]);

    useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const vertexRetention = await retentionPolicyConnector.getRetentionPolicyByEdgeOrVertex({
                ontologyId: ontology.id,
                edgeOrVertexName: vertex.name,
                retentionLinkKind: 'object-types',
            });
            setRetention(vertexRetention);
        } catch (error) {
            setRetention(DEFAULT_POLICIES);
            if (progressMonitor.isCancelled) {
                throw error;
            }
        }
    }, [ontology.id, vertex.name, setRetention], messages.fetchingRetentionError, 1, {
        global: true,
    });

    const _retentionSetUp = useMemo(() => retentionSetUp?.(retention, 'Vertex'), [retention, retentionSetUp]);

    return (
        <div className={classNames('&-body', className)}>
            <div className={classNames('&-header')}>
                <h4 className={classNames('&-header-title')}>
                    {vertex.displayName}
                </h4>
                <ObjectPropertiesPanelMenu
                    ontology={ontology}
                    edgeOrVertex={vertex}
                    editVertexOrEgde={removeObjectModal.open}
                    retentionTarget='Vertex'
                    retentionLink='object-types'
                    retention={retention}
                    setRetention={setRetention}
                />
            </div>
            <div className={classNames('&-name-row')}>
                <div className={classNames('&-name-title')}>
                    <FormattedMessage
                        {...messages.deletionAutomatic}
                        values={{ rule: _retentionSetUp }}
                    />
                </div>
            </div>
            <div className={classNames('&-definition-subtitle-row')}>
                <label>{intl.formatMessage(messages.definition)}</label>
                <button
                    className={classNames('&-new-property-button')}
                    onClick={createPropertyModal.open}
                    type='button'
                >
                    <ArgIcon color='primary' name='icon-add-outline' className={classNames('&-plus-sign')} />
                    <span><FormattedMessage {...messages.newProperty} /></span>
                </button>
            </div>
            <PropertiesList
                className={classNames('&-body-list')}
                propertyList={vertex.properties}
                edgeOrVertex={vertex}
                propertyOf={VertexOrEdge.Vertex}
                ontology={ontology}
                setOntology={setOntology}
                retentionSetUp={retentionSetUp}
                retention={retention}
                setRetention={setRetention}
                retentionLink='object-types'
            />
        </div>
    );
}
