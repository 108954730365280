import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { ProcessId } from '../model/process';
import { ProcessPrioritiesState } from './process-priorities-state';

export function preparationProcessPrioritiesStateFactory(url: string, processId: ProcessId) {
    return new ProcessPrioritiesState(url, processId);
}

export function computeProcessPrioritiesStateURL(processId: ProcessId) {
    return `/processes/${processId}/priorities`;
}

export function usePreparationProcessPrioritiesState(processId: ProcessId): ProcessPrioritiesState;
export function usePreparationProcessPrioritiesState(processId: ProcessId | undefined): ProcessPrioritiesState | undefined;

export function usePreparationProcessPrioritiesState(processId: ProcessId | undefined): ProcessPrioritiesState | undefined {
    const state = useBasicState<ProcessPrioritiesState>(
        (url) => preparationProcessPrioritiesStateFactory(url, processId!),
        (processId) ? computeProcessPrioritiesStateURL(processId) : undefined,
    );

    return state;
}
