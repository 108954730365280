import { useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import {
    ArgMenu,
    ArgMenuItem,
    ArgRenderedIcon,
    ArgSubMenu,
    ClassValue,
    getToolIcon,
    getToolLabel,
    isToolVisible,
    renderIcon,
    renderText,
    ToolContext,
    ToolTreeNode,
    useClassNames,
    useToolNodes,
} from '../../components/basic';
import { NavItem } from '../models/navigation';
import { Ontology } from '../models/dtoApi';
import { universeManagementNavItem, universesNavItems } from '../universes/utils';
import { contextualVariablesNavItem } from '../contextual-variables';
import { Environment } from '../../utils/environment';
import { rolesNavItems } from '../roles';
import { configurationNavItems } from '../configuration';
import { ExplorationPermissions } from '../../exploration/model/permissions';
import { useHasAnyPermissions, useHasPermission } from '../../contexts/user-permission';
import { SETTINGS_ROOT_URL } from '../../components/containers/routes-urls';
import { SettingsPermissions } from '../permissions/permissions';
import { ArgonosModulesRegistry } from '../../components/application/argonos-modules-registry';
import { SettingsEnvironmentContext } from '../envrionment-contexts/settings-environment-context';
import { extensionsNavItems } from '../extensions';
import { webhookNavItems } from '../webhooks-external-components';

import './settings-nav-menu.less';

export const DATA_EXPLO_ROUTE = `${SETTINGS_ROOT_URL}/data_exploration`;
export const PROCEO_ROUTE = `${SETTINGS_ROOT_URL}/proceo`;

const messages = defineMessages({
    centralAdministration: {
        id: 'settings.settings-nav-menu.centralAdministration',
        defaultMessage: 'Central administration',
    },
    modules: {
        id: 'settings.settings-nav-menu.modules',
        defaultMessage: 'Modules',
    },
    dataExploration: {
        id: 'settings.universes.menu.dataExploration',
        defaultMessage: 'Data Exploration',
    },
    dataPreparation: {
        id: 'settings.universes.menu.dataPreparation',
        defaultMessage: 'Data Preparation',
    },
    proceo: {
        id: 'settings.menu.proceo',
        defaultMessage: 'Proceo',
    },
    defaultPreferences: {
        id: 'settings.universes.menu.defaultPreferences',
        defaultMessage: 'Default preferences',
    },
});

export interface NavMenuProps {
    className?: ClassValue;
    ontologies?: Ontology[];
    settingsEnvironmentContext: SettingsEnvironmentContext;
    settingsToolContext: ToolContext<SettingsEnvironmentContext>;
}

export function SettingsNavMenu(props: NavMenuProps) {
    const {
        ontologies,
        className,
        settingsEnvironmentContext,
        settingsToolContext,
    } = props;

    const classNames = useClassNames('settings-nav-menu');
    const location = useLocation();

    const hasVisualIdentityAccess = useHasPermission<SettingsPermissions>('admin.visual.identity.management');
    //    const hasWebhookAccess = useHasAnyPermissions<SettingsPermissions>('admin.webhook.access');
    const hasRolesAccess = useHasPermission<SettingsPermissions>('admin.user.role.access');
    const hasContextualVariableAccess = useHasAnyPermissions<SettingsPermissions>('admin.contextual.variable.management', 'admin.contextual.variable.edition');
    const hasImportExportAccess = useHasPermission<SettingsPermissions>('admin.import.export.settings');
    const hasExtensionsAccess = useHasPermission<SettingsPermissions>('admin.moduleExtensions.access');

    const canAdminUniverse = useHasPermission<ExplorationPermissions>('exploration.universe.settings');

    const pathSections = location.pathname.split('/').slice(1);
    const subPaths: string[] = [];

    while (pathSections.length > 0) {
        subPaths.push(`${subPaths[subPaths.length - 1] || ''}/${pathSections.shift()}`);
    }

    const conditionalIcon = (icon: ArgRenderedIcon | undefined, iconSize?: number) => {
        return icon ? (
            <div className={classNames('&-icon-block', '&-menu-item-icon')}>
                {renderIcon(icon, undefined, undefined, iconSize)}
            </div>
        ) : undefined;
    };

    const renderSubMenuRecursive = (navItem: NavItem) => {
        if (!navItem.children?.length) {
            return (
                <ArgMenuItem key={navItem.path}>
                    <div className={classNames('&-menu-item')}>
                        {conditionalIcon(navItem.icon, navItem.iconSize)}
                        <Link to={navItem.path} className={classNames('&-menu-item-link')}>
                            <FormattedMessage {...navItem.label} />
                        </Link>
                    </div>
                </ArgMenuItem>
            );
        }

        return (
            <ArgSubMenu
                key={navItem.path}
                label={navItem.dataExplorationName || navItem.label}
                className={classNames('&-submenu')}
                icon={conditionalIcon(navItem.icon, navItem.iconSize)}
            >
                {navItem.children.map((nav) => renderSubMenuRecursive(nav))}
            </ArgSubMenu>
        );
    };

    function renderToolContext(toolNodes: ToolTreeNode<SettingsEnvironmentContext>[]): JSX.Element[] {
        const children: JSX.Element[] = [];
        toolNodes.forEach((toolNode) => {
            if (!isToolVisible<SettingsEnvironmentContext>(toolNode, settingsEnvironmentContext)) {
                return;
            }

            if (toolNode.type === 'group' || (!toolNode.type && toolNode.children)) {
                children.push(<ArgSubMenu
                    key={toolNode.path}
                    label={getToolLabel(toolNode, settingsEnvironmentContext)}
                    className={classNames('&-submenu')}
                    icon={conditionalIcon(getToolIcon(toolNode, settingsEnvironmentContext))}
                >
                    {toolNode.children && renderToolContext(toolNode.children)}
                </ArgSubMenu>);

                return;
            }
            if (toolNode.type !== 'button' && toolNode.type) {
                console.warn('Unsupported toolNode type', toolNode.type);

                return;
            }

            children.push(<ArgMenuItem key={toolNode.path} onClick={() => {
                toolNode.onClick?.(toolNode, settingsEnvironmentContext);
            }}>
                <div className={classNames('&-menu-item')}>
                    {conditionalIcon(getToolIcon(toolNode, settingsEnvironmentContext))}
                    <div className={classNames('&-menu-item-link')}>
                        {renderText(getToolLabel(toolNode, settingsEnvironmentContext))}
                    </div>
                </div>
            </ArgMenuItem>);
        });

        return children;
    }

    const dataExplorationNavItems = useMemo(() => {
        const explorationModule = ArgonosModulesRegistry.getInstance().getById('chapsVision.DataExploration');
        if (!explorationModule?.enabled) {
            return [];
        }

        const children = [
            ...(hasRolesAccess ? rolesNavItems(explorationModule) : []),
            ...(hasImportExportAccess ? configurationNavItems(explorationModule.scope) : []),
            ...(hasExtensionsAccess ? extensionsNavItems(explorationModule) : []),
            ...webhookNavItems(explorationModule),
            ...(canAdminUniverse ? universeManagementNavItem() : []),
            ...(!isEmpty(ontologies) ? universesNavItems(ontologies!) : []),
        ];

        const navItem: NavItem = {
            path: DATA_EXPLO_ROUTE,
            label: messages.dataExploration,
            dataExplorationName: Environment.dataExplorationName,
            icon: explorationModule.iconURL,
            iconSize: 20,
            children: children,
        };

        return [navItem];
    }, [hasRolesAccess, hasImportExportAccess, hasExtensionsAccess, canAdminUniverse, ontologies]);

    const proceoNavItems = useMemo(() => {
        const proceoModule = ArgonosModulesRegistry.getInstance().getById('chapsVision.Proceo');
        if (!proceoModule?.enabled) {
            return [];
        }

        const navItem: NavItem = {
            path: PROCEO_ROUTE,
            label: messages.proceo,
            dataExplorationName: Environment.proceoName,
            icon: proceoModule.iconURL,
            iconSize: 20,
            children: [
                ...(hasRolesAccess ? rolesNavItems(proceoModule) : []),
                ...(proceoModule.settings || []),
            ],
        };

        return [navItem];
    }, [hasRolesAccess]);

    const [mainToolNodes] = useToolNodes(settingsToolContext, settingsEnvironmentContext, 'global');
    const [modulesToolNodes] = useToolNodes(settingsToolContext, settingsEnvironmentContext, 'modules');

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-body')}>
                <div className={classNames('&-title')}>
                    <FormattedMessage {...messages.centralAdministration} />
                </div>
                <ArgMenu mode='inline' defaultOpenKeys={subPaths} selectedKeys={subPaths}>
                    {renderToolContext(mainToolNodes)}
                    {dataExplorationNavItems && hasContextualVariableAccess && (
                        contextualVariablesNavItem.map((navItem) => renderSubMenuRecursive(navItem))
                    )}
                </ArgMenu>
                {
                    (!isEmpty(dataExplorationNavItems) || !isEmpty(proceoNavItems) || !isEmpty(modulesToolNodes)) ? (
                        <>
                            <div className={classNames('&-title')}>
                                <FormattedMessage {...messages.modules} />
                            </div>
                            <ArgMenu
                                mode='inline'
                                defaultOpenKeys={subPaths}
                                selectedKeys={subPaths}
                            >
                                {renderToolContext(modulesToolNodes)}
                                {dataExplorationNavItems.map((navItem) => renderSubMenuRecursive(navItem))}
                                {proceoNavItems.map((navItem) => renderSubMenuRecursive(navItem))}
                            </ArgMenu>
                        </>
                    ) : null
                }
            </div>
        </div>
    );
}
