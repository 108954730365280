import { useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
    ArgModal,
    ArgTable2,
    ArgTable2Column,
    ProgressMonitor,
    SelectionProvider,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { Role, RoleId, RolesScope } from 'src/settings/models/dtoApi';
import { downloadBlob } from 'src/utils/file';
import { ImportExportOptions } from 'src/model/configuration';
import { ConfigurationType } from 'src/settings/configuration/configuration-type';
import { numberSorter, stringSorter } from 'src/utils/sorter';
import { ConfigurationConnector } from 'src/utils/connectors/configuration-connector';
import { SettingsConnector } from '../../connectors/settings-connector';

import './export-roles-modal.less';

const SELECTION_NAME = 'settings-export-roles-selection';

const messages = defineMessages({
    title: {
        id: 'settings.export-roles.title',
        defaultMessage: 'Export roles',
    },
    confirmExport: {
        id: 'settings.export-roles.confirmExport',
        defaultMessage: 'Export',
    },
    usersDisplay: {
        id: 'settings.export-roles.usersDisplay',
        defaultMessage: '{countUsers} users, {countGroups} groups',
    },
    name: {
        id: 'settings.export-roles.name',
        defaultMessage: 'Name',
    },
    description: {
        id: 'settings.export-roles.description',
        defaultMessage: 'Description',
    },
    users: {
        id: 'settings.export-roles.users',
        defaultMessage: 'Users',
    },
    exportSucceed: {
        id: 'settings.export-roles.exportSucceed',
        defaultMessage: 'Roles have successfully been exported',
    },
    exportError: {
        id: 'settings.export-roles.exportError',
        defaultMessage: 'An error occurred while exporting the roles',
    },
    loadRolesError: {
        id: 'settings.export-roles.loadRolesError',
        defaultMessage: 'An error occurred while loading the roles',
    },
    loadUsersGroupsError: {
        id: 'settings.export-roles.loadUserGroupsError',
        defaultMessage: 'An error occurred while loading users and groups',
    },
});

export interface RolesTableData {
    id: RoleId;
    name: string;
    description?: string;
    countUsers: number;
    countGroups: number;
}

export interface ExportRolesModalProps {
    rolesScope: RolesScope;
    onClose: () => void;
}

export function ExportRolesModal(props: ExportRolesModalProps) {
    const { onClose, rolesScope } = props;

    const classNames = useClassNames('settings-export-roles');
    const [roles, setRoles] = useState<Role[]>();
    const notifications = useArgNotifications();

    const [loadRoles, progressMonitorRoles] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const userRoles = await SettingsConnector.getInstance().getRoles(false, rolesScope, progressMonitor);
            setRoles(userRoles.filter((role) => role.scope === rolesScope && !role.readOnly));
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.loadRolesError }, error as Error);
            throw error;
        }
    }, [notifications, rolesScope]);

    useEffect(() => {
        loadRoles();
    }, []);

    const rolesTableData: RolesTableData[] = useMemo(() => {
        if (!roles) {
            return [];
        }

        return roles.map((role) => {
            const countUsers = 0; // Wait for API
            const countGroups = 0; // Wait for API

            return { id: role.id, name: role.displayName, description: role.description, countUsers, countGroups };
        });
    }, [roles]);

    const rolesSelectionProvider = useMemo(() => new SelectionProvider<RolesTableData>(SELECTION_NAME, (role) => role.id), []);

    const tableColumns: ArgTable2Column<RolesTableData>[] = useMemo(() => {
        return [
            {
                key: 'name',
                sortable: true,
                columnName: 'Name',
                title: messages.name,
                dataIndex: 'name',
                defaultSortOrder: 'ascend',
                render: function display(name: string) {
                    return <span>{name}</span>;
                },
                sorter: (a, b) => {
                    return stringSorter<RolesTableData>(a, b, item => item.name);
                },
            },
            {
                key: 'description',
                sortable: true,
                columnName: 'Description',
                title: messages.description,
                dataIndex: 'description',
                maxWidth: 250,
                ellipsis: true,
                render: function display(description: string) {
                    return <span>{description}</span>;
                },
                sorter: (a, b) => {
                    return stringSorter<RolesTableData>(a, b, item => item.name);
                },
            },
            {
                key: 'users',
                sortable: true,
                columnName: 'Users',
                title: messages.users,
                dataIndex: 'users',
                render: function display(users, role) {
                    return <FormattedMessage {...messages.usersDisplay}
                                             values={{ countUsers: role.countUsers, countGroups: role.countGroups }} />;
                },
                sorter: (a, b) => {
                    return numberSorter<RolesTableData>(a, b, item => item.countUsers + item.countGroups);
                },
            },
        ];
    }, []);

    const [handleExportRoles, progressMonitorExport] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const rolesToExport = roles?.filter((role) => rolesSelectionProvider.list().includes(role.id));

            if (!rolesToExport) {
                return;
            }

            const importExportOptions: ImportExportOptions = {
                options: [{
                    type: ConfigurationType.GlobalAdminRole,
                    configurationKeys: rolesSelectionProvider.list(),
                    options: {},
                }],
            };

            const { blob } = await ConfigurationConnector.getInstance().exportConfigurations(importExportOptions, rolesScope, progressMonitor);

            downloadBlob(`roles-${new Date().getTime()}.zip`, blob);

            notifications.snackInfo({ message: messages.exportSucceed });
            onClose();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.exportError }, error as Error);
            throw error;
        }
    }, [notifications, onClose, roles, rolesSelectionProvider, rolesScope]);


    return <ArgModal
        title={messages.title}
        className={classNames('&')}
        size='large'
        onClose={onClose}
        onCancel={onClose}
        onOk={handleExportRoles}
        okText={messages.confirmExport}
        confirmLoading={progressMonitorExport?.isRunning}
        loading={progressMonitorRoles?.isRunning}
    >
        <ArgTable2<RolesTableData>
            className={classNames('&-table')}
            headerClassName={classNames('&-table-header')}
            getRowClassName={classNames('&-table-row')}
            columns={tableColumns}
            rowHeight={45}
            showNbRows={10}
            dataSource={rolesTableData}
            selectionSource={SELECTION_NAME}
            selectionProvider={rolesSelectionProvider}
            bordered={true}
            areRowsSelectable={true}
            highlightedRowCondition={() => false}
        />
    </ArgModal>;
}
