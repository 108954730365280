import { cloneDeep, set, sum } from 'lodash';

import { Scope, ScopeProcessed } from '../../../../models/policy';
import { useScopeStateContext } from '../../providers/policy-rules-provider';
import { TREE_BLOCK_HEIGHT } from '../../policy-utils';
import { Tree } from '../../tree/tree';

interface ApplicationConditionTreeProps {
    scopes: Scope[];
    block: 'and' | 'or';
    currentPath: string;
}

export function ApplicationConditionTree(props: ApplicationConditionTreeProps) {
    const {
        scopes,
        block,
        currentPath,
    } = props;

    const { setScope, editable } = useScopeStateContext();
    const sizes = scopes.map((scope) => getRecursiveSizes(scope, editable));

    const changeOperator = () => {
        if (!editable) return;

        setScope((currentScope) => {
            const newOperator = block === 'and' ? 'or' : 'and';

            if (currentPath === '') {
                return { [newOperator]: scopes, id: currentScope.id } as ScopeProcessed;
            }

            return set(cloneDeep(currentScope), currentPath, {
                [newOperator]: scopes,
            });
        });
    };

    return <Tree sizes={sizes} block={block} changeOperator={changeOperator} />;
}

const getRecursiveSizes = (scope: Scope, editable: boolean): number => {
    const buttonsExtraHeight = editable ? 21 : 7.5;

    if (!('or' in scope) && !('and' in scope)) {
        return TREE_BLOCK_HEIGHT;
    }
    if ('and' in scope) {
        return (
            sum(scope.and.map((scope) => getRecursiveSizes(scope, editable))) + buttonsExtraHeight
        );
    }
    if ('or' in scope) {
        return (
            sum(scope.or.map((scope) => getRecursiveSizes(scope, editable))) + buttonsExtraHeight
        );
    }

    return 0;
};
