
export const splitMetapropertyPredicate = (
    input: string | undefined,
): {
    userOrMeta: 'user' | 'meta';
    operator: string | undefined;
    value?: string;
} => {
    if (input?.startsWith('profile')) {
        const match = input.match(/profile:(=|!=|>=|<=|>|<)(.*)/);

        return {
            userOrMeta: 'user',
            operator: match?.[1],
            value: match?.[2],
        };
    } else {
        const match = input?.match(/(=|!=|>=|<=|>|<)"(.*)"/);

        return {
            userOrMeta: 'meta',
            operator: match?.[1],
            value: match?.[2],
        };
    }
};

export const getPredicate = (
    userOrMeta: 'user' | 'meta',
    operator: string | undefined,
    value: string | undefined,
) => {
    if (userOrMeta === 'user') {
        return `profile:${operator || ''}${value || ''}`;
    } else {
        return `${operator || ''}"${value || ''}"`;
    }
};
