import React, { ReactNode } from 'react';

import { ArgModal, ArgRenderedText, renderText, useClassNames } from '../../../basic';

import './media-viewer-modal.less';

interface MediaViewerModalProps {
    renderMedia: (fitContainClassName: string) => ReactNode;
    title?: ArgRenderedText;
    messageValues?: Record<string, any>;
    onClose: () => void;
}

export function MediaViewerModal(props: MediaViewerModalProps) {
    const {
        renderMedia,
        onClose,
        title,
        messageValues,
    } = props;

    const classNames = useClassNames('media-viewer-modal');

    const _title = renderText(title, messageValues);

    return (
        <ArgModal
            size='large'
            footer={false}
            title={_title}
            cancelText={false}
            onClose={onClose}
            className={classNames('&')}
        >
            <div className={classNames('&-container')}>
                {renderMedia(classNames('&-fit-contain'))}
            </div>
        </ArgModal>
    );
}
