import { SettingsEnvironmentContext } from '../../settings/envrionment-contexts/settings-environment-context';
import { registerRolesToolItems } from '../../settings/roles';
import { registerToolItem } from '../../components/basic';
import { ArgonosModule } from '../../components/application/modules';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../../settings/envrionment-contexts/context-names';
import { registerWebhookToolItems } from 'src/settings/webhooks-external-components';


export function setupSettings(knowledgeBaseModule: ArgonosModule) {
    registerToolItem<SettingsEnvironmentContext>(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path: 'modules/knowledge-base',
        order: 300,
        type: 'group',
        icon: knowledgeBaseModule.iconURL,
        visible: !!knowledgeBaseModule.enabled,
        label: knowledgeBaseModule.name,
    });

    registerRolesToolItems('modules/knowledge-base/roles', 100, knowledgeBaseModule);
    registerWebhookToolItems('modules/knowledge-base/webhook', 50, knowledgeBaseModule);
}
