import {
    ArgCheckbox,
    ClassValue,
    highlightSplit,
    ProgressMonitor,
    useClassNames,
} from '../../../../../components/basic';
import { DateByUser } from '../../../../../components/common/date-by-user';
import { ExplorationStyleTemplate } from '../../../../../exploration/model/template';
import { TemplateActionsMenu } from '../actions/template-action-menu';

import './exploration-settings-styles-template-card.less';

interface ExplorationSettingsStylesTemplateCardProps {
    className?: ClassValue;
    explorationStyleTemplate: ExplorationStyleTemplate;
    searchedToken?: string;
    onDeleteTemplateConfirm?: (progressMonitor: ProgressMonitor, template: ExplorationStyleTemplate) => Promise<void>;
    onRenameTemplateConfirm?: (progressMonitor: ProgressMonitor, template: ExplorationStyleTemplate, newName: string) => Promise<void>;
    onCheckAction?: (value: boolean, template: ExplorationStyleTemplate) => void;
    isTemplateSelected?: (template: ExplorationStyleTemplate) => boolean;
}

const CLASSNAME = 'exploration-settings-styles-template-card';

export function ExplorationSettingsStylesTemplateCard({
    className,
    explorationStyleTemplate,
    searchedToken,
    onDeleteTemplateConfirm,
    onRenameTemplateConfirm,
    isTemplateSelected,
    onCheckAction,
}: ExplorationSettingsStylesTemplateCardProps) {
    const classNames = useClassNames(CLASSNAME);

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <div
                    className={classNames('&-header-title', 'ellipsis')}>{highlightSplit(explorationStyleTemplate.name, searchedToken)}</div>
                <div className={classNames('&-header-right')}>
                    <TemplateActionsMenu
                        className={classNames('&-header-right-actions-menu')}
                        template={explorationStyleTemplate}
                        onDeleteTemplateConfirm={onDeleteTemplateConfirm}
                        onRenameTemplateConfirm={onRenameTemplateConfirm}
                    />
                    {onCheckAction &&
                        <ArgCheckbox
                            className={classNames('&-header-right-checkbox')}
                            size='node'
                            value={isTemplateSelected?.(explorationStyleTemplate)}
                            onChange={(value) => onCheckAction(value, explorationStyleTemplate)} />}
                </div>
            </div>
            <div className={classNames('&-footer')}>
                <DateByUser
                    className={classNames('&-date-by-user')}
                    date={explorationStyleTemplate.createdDate}
                    user={explorationStyleTemplate.createdBy}
                    relative={true}
                />
            </div>
        </div>
    );
}
