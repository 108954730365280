import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { FormEvent, MouseEvent, useCallback, useMemo, useState } from 'react';

import {
    ArgAlertCard,
    ArgFormLabel,
    ArgInputText,
    ArgMessageValues,
    ArgModal,
    ArgModalSize,
    ArgRenderedText,
    isMessageDescriptor,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';

import './delete-item.less';

const CLASSNAME = 'common-delete-item-modal';

const messages = defineMessages({
    deleteToken: {
        id: 'common.delete-item-modal.deleteToken',
        defaultMessage: 'DELETE',
    },
    okText: {
        id: 'common.delete-item-modal.okText',
        defaultMessage: 'Delete',
    },
    confirmText: {
        id: 'common.delete-item-modal.confirmText',
        defaultMessage: 'Please enter {token} to confirm',
    },
});

export interface DeleteItemModalProps {
    title: ArgRenderedText;
    descriptionTitle?: ArgRenderedText;
    description?: ArgRenderedText;
    confirmText?: ArgRenderedText;
    deleteToken?: string|MessageDescriptor;
    messageValues?: ArgMessageValues;

    onClose: () => void;
    onConfirm: (progressMonitor: ProgressMonitor) => Promise<boolean | undefined | void>;
    size?: ArgModalSize;
    maxLength?: number;
}

export function DeleteItemModal(props: DeleteItemModalProps) {
    const {
        onClose,
        onConfirm,
        size,
        maxLength,
        descriptionTitle,
        description,
        title,
        deleteToken,
        messageValues,
        confirmText,
    } = props;

    const intl = useIntl();

    const classNames = useClassNames(CLASSNAME);

    const [value, setValue] = useState<string>('');

    const [callConfirm, confirmLoading] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const ret = await onConfirm(progressMonitor);
        if (ret === false) {
            return;
        }

        onClose();
    }, [onClose, onConfirm]);

    const _deleteToken = useMemo(() => {
        let token = isMessageDescriptor(deleteToken)
            ? intl.formatMessage(deleteToken, messageValues)
            : deleteToken;
        if (token) {
            return token;
        }

        token = intl.formatMessage(messages.deleteToken, messageValues);

        return token;
    }, [deleteToken, intl, messageValues]);

    const handleSubmit = useCallback(async (event: MouseEvent | FormEvent | undefined) => {
        event?.preventDefault();
        event?.stopPropagation();

        if (value.trim() !== _deleteToken) {
            return;
        }

        try {
            await callConfirm();
        } catch (error) {
            console.error(error);
        }
    }, [_deleteToken, callConfirm, value]);

    const handleCancel = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <ArgModal
            size={size || 'medium'}
            title={title}
            onOk={handleSubmit}
            onCancel={handleCancel}
            onClose={handleCancel}
            progressMonitor={confirmLoading}
            className={classNames('&')}
            okDisabled={value.trim() !== _deleteToken}
            okText={messages.okText}
            messageValues={messageValues}
        >

            {descriptionTitle &&
                <ArgAlertCard
                    type='warning'
                    className={classNames('&-alert')}
                    title={descriptionTitle}
                    description={description}
                    messageValues={messageValues}
                />}

            <form
                onSubmit={handleSubmit}
                className={classNames('&-form')}
            >
                <ArgFormLabel
                    propertyName={confirmText || messages.confirmText}
                    className={classNames('&-form-label')}
                    messageValues={{ ...messageValues, token: _deleteToken }}
                >
                    <ArgInputText
                        value={value}
                        maxLength={maxLength || 128}
                        autoFocus={true}
                        onInputChange={setValue}
                        className={classNames('&-input')}
                        htmlAutoComplete='off'
                        disableCopy={true}
                        disablePaste={true}
                        disableCut={true}
                    />
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
}
