import {
    chain,
    compact,
    find,
    forEach,
    isEqual,
    isNil,
    isNumber,
    mapValues,
    partition,
    pull,
    sortBy,
    union,
    without,
} from 'lodash';
import React, {
    CSSProperties,
    DragEvent,
    MouseEvent,
    MutableRefObject,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Debug from 'debug';
import useResizeObserver from '@react-hook/resize-observer';

import { $yield } from '../utils/yield';
import { expandColumn } from './expand-column';
import { SelectionCell } from './selection-cell';
import { performColumnMove } from './move-column';
import { handleColumnResize } from './resize-column';
import { useScroll } from '../../../hooks/use-scroll';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgContextMenu } from '../arg-tools/arg-context-menu';
import { VirtualColumnScrollContainer } from './virtual-columns-container';
import { ArgTableRowState, DataFilter, DataProvider, DataSorter, PropertySorter } from '../arg-providers/data-provider';
import { useDataProviderForTable } from '../arg-providers/use-data-provider-for-table';
import { ArgTableColumn3 } from '../arg-table3/arg-table3';
import { ARG_TABLE4_CLASSNAME } from './shared-classnames';
import { ArgDraggableType, useDraggableKey } from '../arg-hooks/use-draggable-key';
import { ArgGetItemKey, escapeColumnKey } from '../utils';
import { ColumnChangeReason } from '../arg-table3/types';
import { AdditionalRowsContainer } from './additional-rows-container';
import { ArgTable4CellIndex, ArgTable4Cursor } from './types';
import { ArgTable4SelectionManager } from './arg-table4-selection-manager';
import { SelectionProvider, SelectionSource } from '../arg-providers/selection-provider';
import { useSelection } from '../arg-providers/use-selection';
import { useMemoDeepEquals } from '../arg-hooks/use-memo-deep-equals';
import { areAdditionalRowsEqualTo, computeAdditionalRowsTotalHeight } from 'src/utils/scroll-display-manager';
import { useHorizontalScroll } from './use-horizontal-scroll';
import { useLatestCallback } from '../arg-hooks/use-latest-callback';
import { ArgRenderedText, ArgRenderFunction } from '../types';
import { isArgTableRowState } from '../arg-providers/arg-table-row-state';
import { SelectionAllCell } from './selection-all-cell';

import './arg-table4.less';

const debug = Debug('basic:components:ArgTable4');

const DEFAULT_HEADER_HEIGHT = 48;
const LIST_SELECTION_COLUMN_WIDTH = 32;
const TABLE_SELECTION_COLUMN_WIDTH = 40;
const DEFAULT_TYPE = 'table';
const DEFAULT_PROPERTY_SORTERS: PropertySorter[] = [];
const DEFAULT_SHOW_SELECT_ALL_BUTTON = false;

export const ARG_TABLE_4_DEFAULT_SORTABLE = true;

export const ARG_TABLE_4_SELECTION_COLUMN = 'selection-column';
export const ARG_TABLE_4_HORIZONTAL_SCROLLBAR_HEIGHT = 12;

export type ArgTable4ColumnKey = string;


export interface ArgTable4Column<T> extends ArgTableColumn3<T> {
    getRowKey?: ArgGetItemKey<T>;
    compareCells?: (a: T, b: T) => number;
    mergeSimilarCells?: boolean;
    renderHeaderContextMenu?: (column: ArgTable4Column<T>, locked: boolean, onCloseMenu: () => void) => ReactNode;
    dataType?: string;
    headerStyle?: React.CSSProperties;
    cellStyle?: React.CSSProperties;
    cellTooltip?: boolean | ((data: any, rowValue: T) => ArgRenderedText);
    cellTooltipClassName?: ClassValue;

    // Set to false to disable selection on cells for this column. Useful for table's with a selectionManager.
    selectable?: boolean;

    // Set to true to position the column left to the selection column.
    beforeSelection?: true;
}

export type ArgTable4OnDragStartHandler<T> = (event: DragEvent, row: T) => void;

export interface ArgTable4RowStateInfo<T> {
    data: T | ArgTableRowState;
    className?: ClassValue;
    draggable?: boolean;
}

export interface AdditionalRowRenderContext {

    // allows to attach some scrollable elements to the main table horizontal scroll
    registerScrollable(el: HTMLDivElement): void;

    // allows to detach some scrollable elements to the main table
    unregisterScrollable(el: HTMLDivElement): void;
}

export interface ArgTable4AdditionalRow<T> {
    key: string;
    index: number;
    height: number;
    render: (ctx: AdditionalRowRenderContext, item: T, index: number) => ReactNode;
}

export type ArgTable4AdditionalRowGen<T> = (item: T, index: number) => (ArgTable4AdditionalRow<T> | null);

export interface ArgTable4Props<T, F extends DataFilter> {
    columns: ArgTable4Column<T>[];
    initialItemsCount: number;
    rowHeight: number;
    dataProvider: DataProvider<T, F>;
    className?: ClassValue;
    header?: boolean;
    selectionProvider?: SelectionProvider<T>;
    selectionSource?: SelectionSource;
    onSelectionChange?: (row: T, newState: boolean, selectionManager: SelectionProvider<T>) => void;
    type?: 'table' | 'list';
    renderLoadingCell?: (column: ArgTable4Column<T>, index?: number) => ReactNode;
    renderErrorCell?: (column: ArgTable4Column<T>, index?: number, error?: Error) => ReactNode;
    sort?: DataSorter;
    filter?: F;
    onSortChange?: (sort: DataSorter | undefined, column?: ArgTable4Column<T>) => void;
    lockedColumns?: Record<ArgTable4ColumnKey, true>;
    onLockedColumnsChange?: (column: ArgTable4Column<T>, locks: Record<ArgTable4ColumnKey, boolean>) => void;
    initialLockedColumns?: Record<ArgTable4ColumnKey, boolean>;
    visibleColumns?: ArgTable4ColumnKey[];
    initialVisibleColumns?: ArgTable4ColumnKey[];
    onVisibleColumnsChange?: (visibleColumnKeys: ArgTable4ColumnKey[], changeReasons: ColumnChangeReason[]) => void;
    columnWidths?: Record<ArgTable4ColumnKey, number>;
    onColumnWidthChange?: (column: ArgTable4Column<T>, width: number) => void;
    searchScrollTop?: number;
    showSelectAllButton?: boolean;

    // Scroll up to the row with index matching number. When defined it overrides searchScrollTop.
    searchResultSkip?: number;

    onRowContextMenuRender?: (
        event: MouseEvent,
        row: T | undefined,
        rowIndex: number | undefined,
        colIndex: number | undefined,
        closeMenu: () => void,
        getPopupContainer?: (node: HTMLElement) => HTMLElement
    ) => ReactNode;

    searchValue?: string;
    adjustColumnsOnFirstDraw?: boolean;
    adjustColumns?: boolean;
    headerHeight?: number;
    additionalHeaderHeight?: number;
    disabled?: boolean;
    noSelectionColumn?: boolean;
    onRowClick?: (event: MouseEvent, row: T, rowIndex: number, dataColumn?: string) => void;
    draggable?: ArgDraggableType;
    draggableModifierKey?: 'Control';
    onDragStart?: ArgTable4OnDragStartHandler<T>;
    onDragEnd?: (event: DragEvent) => void;
    isRowSelectable?: (row: T) => boolean;
    scrollColumnName?: string;

    onRowDoubleClick?: (event: MouseEvent, row: T, rowIndex: number) => void;
    additionalRows?: ArgTable4AdditionalRowGen<T>;
    selectionManager?: ArgTable4SelectionManager;

    emptyRenderer?: ArgRenderFunction;

    // A reference to the horizontal scrollable elements of the table4. Usefull when nesting tables (using additional rows), this allows to register the sub tables horizontal scroll to the main table scroll.
    horizontalScrollableRef?: MutableRefObject<HTMLDivElement[] | null>;

    disableHeaderContextMenu?: boolean;
}

export function ArgTable4<T, F extends DataFilter = any>(props: ArgTable4Props<T, F>) {
    const {
        columns,
        initialItemsCount,
        rowHeight,
        dataProvider,
        className,
        header = true,
        selectionProvider,
        selectionSource,
        onSelectionChange,
        sort: externalSort,
        filter,
        onSortChange,
        renderLoadingCell,
        renderErrorCell,
        type = DEFAULT_TYPE,
        lockedColumns: externalLockedColumns,
        visibleColumns: externalVisibleColumns,
        initialLockedColumns = {},
        initialVisibleColumns,
        onLockedColumnsChange,
        onVisibleColumnsChange,
        searchScrollTop: searchScrollTopExternal,
        searchResultSkip,
        columnWidths: externalColumnWidths,
        onColumnWidthChange,
        onRowContextMenuRender,
        searchValue,
        adjustColumnsOnFirstDraw,
        headerHeight = DEFAULT_HEADER_HEIGHT,
        additionalHeaderHeight,
        disabled,
        noSelectionColumn,
        onRowClick,
        draggable,
        draggableModifierKey,
        onDragStart,
        onDragEnd,
        isRowSelectable,
        onRowDoubleClick,
        scrollColumnName,
        adjustColumns,
        additionalRows: additionalRowGen,
        selectionManager,
        horizontalScrollableRef,
        emptyRenderer,
        showSelectAllButton = DEFAULT_SHOW_SELECT_ALL_BUTTON,
        disableHeaderContextMenu,
    } = props;


    const classNames = useClassNames(ARG_TABLE4_CLASSNAME); // Be careful: also used for screenshot
    const areRowsDraggable = useDraggableKey(true, draggableModifierKey);

    const containerRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);
    const lockedBodyRef = useRef<HTMLDivElement>(null);
    const headerBodyRef = useRef<HTMLDivElement>(null);
    const additionalItemsBodyRef = useRef<HTMLDivElement>(null);
    const headerScrollContainerRef = useRef<HTMLDivElement>(null);

    const initialSort: DataSorter = useMemoDeepEquals(() => ({
        propertySorters: (
            chain([...columns])
                .filter((column) => !isNil(column.defaultSortOrder) && !isNil(column.columnSortName || column.key))
                .map((column) => {
                    const ret: PropertySorter = {
                        propertyName: column.columnSortName || column.key,
                        order: column.defaultSortOrder === 'ascend' ? 'ascending' : 'descending',
                    };

                    return ret;
                })
                .value()
        ),
    }), [columns]);

    const [hoverCellIndex, setHoverCellIndex] = useState<ArgTable4CellIndex>();
    const [internalSort, setInternalSort] = useState<DataSorter>();

    const [rowContextMenuVisible, setRowContextMenuVisible] = useState<{
        event: MouseEvent;
        row: T | undefined;
        rowIndex: number | undefined;
        columnIndex: number | undefined;
    }>();

    const [cursor, setCursor] = useState<ArgTable4Cursor | undefined>();

    const applyInternalSort = !('sort' in props);
    const sort = ((applyInternalSort) ? internalSort : externalSort) || initialSort;

    const [internalLockedColumns, setInternalLockedColumns] = useState<Record<ArgTable4ColumnKey, boolean>>(initialLockedColumns || {});
    const useInternalLockedColumns = !('lockedColumns' in props);
    const lockedColumns = (useInternalLockedColumns) ? internalLockedColumns : externalLockedColumns;

    const [internalVisibleColumns, setInternalVisibleColumns] = useState<ArgTable4ColumnKey[] | undefined>(initialVisibleColumns);
    const useInternalVisibleColumns = !('visibleColumns' in props);
    const visibleColumns = (useInternalVisibleColumns) ? internalVisibleColumns : externalVisibleColumns;

    const [internalColumnWidths, setInternalColumnWidths] = useState<Record<ArgTable4ColumnKey, number>>({});
    const useInternalColumnWidths = !('columnWidths' in props);
    const columnWidths = (useInternalColumnWidths) ? internalColumnWidths : externalColumnWidths;

    const [leftColumnsDragTransforms, setLeftColumnsDragTransforms] = useState<Record<ArgTable4ColumnKey, string>>();
    const [rightColumnsDragTransforms, setRightColumnsDragTransforms] = useState<Record<ArgTable4ColumnKey, string>>();
    const [draggedColumnKey, setDraggedColumnKey] = useState<ArgTable4ColumnKey>();

    const adjustColumnStateRef = useRef<'waiting' | 'done'>('waiting');
    const adjustColumnStateTimerRef = useRef<ReturnType<typeof setTimeout>>();

    let itemsCount = initialItemsCount;
    if (isNumber(dataProvider?.rowCount)) {
        itemsCount = dataProvider?.rowCount;
    }
    const additionalRowsMap = useRef(new Map<number, ArgTable4AdditionalRow<T> | null>()).current;
    const [additionalRows, setAdditionalRows] = useState<ArgTable4AdditionalRow<T>[]>([]);
    const [additionalRowsContent, setAdditionalRowsContent] = useState(new Map<number, ReactNode>());

    const columnRefsRef = useRef<Record<ArgTable4ColumnKey, MutableRefObject<HTMLElement|null>>>({});

    const {
        totalHeight,
        visibleNodeCount,
        startNode,
        scrollDisplayManager,
        scrollPosition: [_scrollLeft, scrollTop],
        containerHeight,
        containerWidth,
    } = useScroll<HTMLDivElement, T>(
        itemsCount,
        rowHeight,
        containerRef,
        bodyRef,
        undefined, // headerBodyRef is now handled by useHorizontalScroll
        lockedBodyRef,
        additionalItemsBodyRef,
        additionalRows,
        columnRefsRef.current,
    );

    const searchScrollTop = useMemo(() => {
        if (isNumber(searchResultSkip)) {
            return computeAdditionalRowsTotalHeight(additionalRows, searchResultSkip) + (searchResultSkip * rowHeight);
        }

        return searchScrollTopExternal;
    }, [searchScrollTopExternal, searchResultSkip, rowHeight, additionalRows]);

    const [additionalScrollables, setAdditionalScrollables] = useState<HTMLDivElement[]>([]);

    const [horizontalScrollBar, updateHorizontalScrollbar] = useHorizontalScroll([
        headerBodyRef.current,
        bodyRef.current,
        ...additionalScrollables,
    ],
    () => {
        const result = Math.max(
            (bodyRef.current?.scrollWidth ?? 0) + (lockedBodyRef.current?.scrollWidth ?? 0),
            ...additionalScrollables.map((e) => e.scrollWidth),
        );

        return result;
    },
    [columnWidths]);

    useEffect(() => {
        if (horizontalScrollableRef) {
            horizontalScrollableRef.current = compact([headerBodyRef.current, bodyRef.current]);
        }
    }, []);

    const dataProviderStateId = useDataProviderForTable(dataProvider, startNode, startNode + visibleNodeCount - 1, columns, filter, sort, searchValue);

    useSelection(selectionProvider);

    useEffect(() => {
        adjustColumnStateRef.current = 'waiting';
    }, [dataProvider]);

    useResizeObserver(containerRef.current, (entry: ResizeObserverEntry) => {
        if (!adjustColumns || columns.some((column) => column.resizable)) {
            return;
        }
        adjustColumnStateRef.current = 'done';
        handleAdjustColumnsWidth(entry.target as HTMLElement);
    });

    const columnsWithSelection: ArgTable4Column<T>[] = useMemo(() => {
        if (!selectionProvider || !columns || noSelectionColumn) {
            return columns || [];
        }

        const [before, after] = partition(columns, c => c.beforeSelection);
        const columnsWithSelection = [...before, {
            key: ARG_TABLE_4_SELECTION_COLUMN,
            columnName: '',
            dataIndex: '',
            width: type === 'list' ? LIST_SELECTION_COLUMN_WIDTH : TABLE_SELECTION_COLUMN_WIDTH,
            className: 'selection-column',
            rowHeader: true,
            selectable: false,
            disableContextMenu: true,
            title: function checkRender() {
                if (!showSelectAllButton) {
                    return null;
                }

                return <SelectionAllCell<T>
                    selectionProvider={selectionProvider}
                    dataProvider={dataProvider}
                    selectionSource={selectionSource}
                />;
            },
            render: function checkRender(_: any, row: T, rowIndex?: number) {
                const disabled = isRowSelectable ? !isRowSelectable(row) : undefined;
                if (rowIndex === undefined) {
                    return null;
                }

                return <SelectionCell<T>
                    row={row}
                    key={rowIndex}
                    disabled={disabled}
                    selectionProvider={selectionProvider}
                    onSelectionChange={onSelectionChange}
                    selectionSource={selectionSource}
                />;
            },

        } as ArgTable4Column<T>, ...after];

        return columnsWithSelection;
    }, [selectionProvider, columns, noSelectionColumn, type, dataProvider, dataProvider?.stateId, isRowSelectable, onSelectionChange, selectionSource, showSelectAllButton]);

    const handleDragStart: ArgTable4OnDragStartHandler<T> = useCallback((event, row): void => {
        //console.log('DRAG', event, row);
        onDragStart?.(event, row);
    }, [onDragStart]);

    const rowsCache = useMemo(() => {
        const map = new Map();

        const elements = scrollDisplayManager.getViewPortContent(startNode, visibleNodeCount, additionalRows);
        elements.forEach((element, _) => {
            if (element.row < 0) {
                return;
            }

            const rowData = dataProvider.getRow(element.row);

            // Row can be not found if it was deleted
            if (rowData === ArgTableRowState.NotFound) {
                return;
            }

            let rowClassName: string | undefined = undefined;
            let draggableRow = false;
            let hasSelection = false;

            if (typeof (rowData) === 'object') {
                if (areRowsDraggable && draggable === true) {
                    draggableRow = true;
                }

                hasSelection = !!selectionProvider?.has(rowData);
                if (draggable === 'selection') {
                    draggableRow = hasSelection && areRowsDraggable;
                }
                if (hasSelection) {
                    rowClassName = 'selected';
                }
            }
            if (hoverCellIndex?.rowIndex === element.row) {
                if (rowClassName) {
                    rowClassName += ' over';
                } else {
                    rowClassName = 'over';
                }
            }

            map.set(element.row, {
                data: rowData,
                className: rowClassName,
                draggable: draggableRow,
            });
        });

        return map;
    }, [
        dataProvider,
        dataProviderStateId,
        areRowsDraggable,
        draggable,
        startNode,
        visibleNodeCount,
        selectionManager?.stateId,
        hoverCellIndex,
        scrollDisplayManager,
        selectionProvider,
        additionalRows,
    ]);

    const registerScrollable = useLatestCallback((el: HTMLDivElement) => {
        setAdditionalScrollables(prev => {
            if (!find(prev, el)) {
                debug('registering scrollable', 'el=', el.className, prev);

                return [...prev, el];
            }

            return prev;
        });
    });

    const unregisterScrollable = useLatestCallback((el: HTMLDivElement) => {
        setAdditionalScrollables(prev => {
            if (prev.find(e => el === e)) {
                debug('unregistering scrollable', 'el=', el.className);

                return without(additionalScrollables, el);
            }

            return prev;
        });
    });

    useEffect(() => {
        if (!additionalRowGen) {
            return;
        }
        const contentMap = new Map<number, ReactNode>();
        for (const index of rowsCache.keys()) {
            const row = rowsCache.get(index).data;
            if (typeof row === 'object') {
                const additionalRow = additionalRowGen(row, index);
                additionalRowsMap.set(index, additionalRow);
                contentMap.set(index, additionalRow?.render({ registerScrollable, unregisterScrollable }, row, index));
            }
        }
        setAdditionalRowsContent(contentMap);
        const newAdditionalRows = chain([...additionalRowsMap.values()])
            .compact()
            .sortBy(r => r.index)
            .value();

        // Note that areAdditionalRowsEqualTo ignore .render when comparing additional rows, which is important because otherwise it can create a react update loop.
        if (!areAdditionalRowsEqualTo(additionalRows, newAdditionalRows, -1)) {
            setAdditionalRows(newAdditionalRows);
        }
    }, [rowsCache, additionalRowGen]);

    const handleColumnHeaderDoubleClick = useCallback((column: ArgTable4Column<T>, event: Event) => {
        event.preventDefault();

        if (column.rowHeader || !column.resizable) {
            return;
        }

        containerRef.current && expandColumn(containerRef.current,
            column,
            (newSize) => {
                if (useInternalColumnWidths) {
                    setInternalColumnWidths((prev) => {
                        return {
                            ...prev,
                            [column.key]: newSize,
                        };
                    });
                }
                onColumnWidthChange && onColumnWidthChange(column, newSize);
            },
        );
    }, [useInternalColumnWidths, onColumnWidthChange]);

    const handleMouseLeave = useCallback((event: MouseEvent<HTMLDivElement>) => {
        setHoverCellIndex(undefined);
    }, []);

    const handleRowContextMenu = useCallback((event: MouseEvent<HTMLDivElement>, row: T, rowIndex: number, columnIndex: number) => {
        setRowContextMenuVisible({
            event,
            row,
            rowIndex,
            columnIndex,
        });
    }, []);

    const handleHideContextMenu = useCallback(() => {
        setRowContextMenuVisible(undefined);
    }, []);

    const handleDoubleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();

        const { rowIndex } = computeEventContext(event);
        let row: T | undefined = undefined;
        if (rowIndex !== undefined) {
            const r = dataProvider.getRow(rowIndex);
            if (typeof (r) === 'object') {
                row = r as T;
            }
        }

        if (row && onRowDoubleClick && rowIndex !== undefined) {
            onRowDoubleClick(event, row, rowIndex);
        }
    }, [dataProvider, onRowDoubleClick]);

    const handleContextMenu = useCallback((event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();

        const { rowIndex, dataColumn } = computeEventContext(event);
        let row: T | undefined = undefined;
        if (rowIndex !== undefined) {
            const r = dataProvider.getRow(rowIndex);
            if (typeof (r) === 'object') {
                row = r as T;
            }
        }

        const columnIndex = columnsWithSelection.findIndex(c => c.key === dataColumn);

        if (row && onRowContextMenuRender && rowIndex !== undefined) {
            handleRowContextMenu(event, row, rowIndex, columnIndex);
        }
    }, [dataProvider, handleRowContextMenu, onRowContextMenuRender, columnsWithSelection]);

    const noVerticalScroll = containerHeight - headerHeight - (additionalHeaderHeight ?? 0) - ARG_TABLE_4_HORIZONTAL_SCROLLBAR_HEIGHT > totalHeight;

    const visibleHeight = totalHeight;


    const handleColumnVisible = useCallback((column: ArgTable4Column<T>, visible: boolean) => {
        let newList = (visibleColumns) ? [...visibleColumns] : columns.map((c) => c.key);
        if (visible) {
            newList = union(newList, [column.key]);
        } else {
            newList = pull(newList, column.key);
        }

        if (useInternalVisibleColumns) {
            setInternalVisibleColumns(newList);
        }

        const changeReason: ColumnChangeReason = {
            type: 'visible',
            state: visible,
            source: column.key,
        };

        onVisibleColumnsChange && onVisibleColumnsChange(newList, [changeReason]);
    }, [columns, visibleColumns, onVisibleColumnsChange, useInternalVisibleColumns]);

    const [leftColumns, rightsColumns, sortedColumns] = useMemo<[ArgTable4Column<T>[], ArgTable4Column<T>[], ArgTable4Column<T>[]]>(() => {
        const leftColumns: ArgTable4Column<T>[] = [];
        const rightsColumns: ArgTable4Column<T>[] = [];

        columnsWithSelection.forEach((c) => {
            if (!c.rowHeader) {
                return;
            }
            leftColumns.push(c);
        });

        if (visibleColumns?.length) {
            visibleColumns.forEach((columnKey) => {
                const col = columns.find((c) => c.key === columnKey);
                if (!col || col.rowHeader) {
                    return;
                }

                if (lockedColumns?.[columnKey]) {
                    leftColumns.push(col);

                    return;
                }
                rightsColumns.push(col);
            });
        } else {
            columnsWithSelection.forEach((c) => {
                if (c.rowHeader) {
                    return;
                }
                if (lockedColumns?.[c.key]) {
                    leftColumns.push(c);

                    return;
                }
                rightsColumns.push(c);
            });
        }

        return [leftColumns, rightsColumns, [...leftColumns, ...rightsColumns]];
    }, [columnsWithSelection, lockedColumns, visibleColumns, columns]);

    const handleColumnLock = useCallback((column: ArgTable4Column<T>, locked: boolean) => {
        const locks: Record<ArgTable4ColumnKey, boolean> = {};

        if (locked) {
            const idx = rightsColumns.indexOf(column);
            for (let i = 0; i <= idx; i++) {
                locks[rightsColumns[i].key] = true;
            }
        } else {
            const idx = leftColumns.indexOf(column);
            for (let i = idx; i < leftColumns.length; i++) {
                locks[leftColumns[i].key] = false;
            }
        }

        if (useInternalLockedColumns) {
            setInternalLockedColumns((prev) => {
                return { ...prev, ...locks };
            });
        }

        onLockedColumnsChange && onLockedColumnsChange(column, locks);
    }, [onLockedColumnsChange, useInternalLockedColumns, leftColumns, rightsColumns]);

    const handleColumnSort = useCallback((column: ArgTable4Column<T>, order: 'ascending' | 'descending' | undefined, replace: boolean) => {
        if (!(column.sortable ?? ARG_TABLE_4_DEFAULT_SORTABLE)) {
            return;
        }

        let propertySorters: PropertySorter[] | undefined = DEFAULT_PROPERTY_SORTERS;

        const propertySorter = sort?.propertySorters?.find((sorter) => {
            return sorter.propertyName === ((column.key || column.columnSortName));
        });

        if (replace) {
            if (order && order !== propertySorter?.order) {
                propertySorters = [{
                    propertyName: column.columnSortName || column.key,
                    order,
                }];
            }
        } else if (!propertySorter) {
            if (order) {
                propertySorters = [
                    ...(sort?.propertySorters || []),
                    {
                        propertyName: column.columnSortName || column.key,
                        order,
                    },
                ];
            }
        } else if (propertySorter.order === order || !order) {
            propertySorters = sort?.propertySorters.filter((sorter) => sorter.propertyName !== (column.key || column.columnSortName));
        } else {
            propertySorters = sort?.propertySorters.map((sorter) => {
                return sorter.propertyName === (column.key || column.columnSortName) ? {
                    ...sorter,
                    order,
                } : sorter;
            });
        }

        const sortedPropertySorters = propertySorters ?
            sortBy(propertySorters, (sorter) =>
                sortedColumns.findIndex((column) => (column.key || column.columnSortName) === sorter.propertyName),
            ) : [];

        const newSort = sortedPropertySorters ? {
            propertySorters: sortedPropertySorters,
        } : undefined;

        if (applyInternalSort) {
            setInternalSort(newSort);
        }

        onSortChange?.(newSort, column);
        selectionManager?.clearSelection();
    }, [sort?.propertySorters, applyInternalSort, onSortChange, selectionManager, sortedColumns]);

    useEffect(() => {
        const propertySorters = sortBy(sort.propertySorters, (sorter) =>
            sortedColumns.findIndex((column) => (column.key || column.columnSortName) === sorter.propertyName),
        );
        if (isEqual(propertySorters, sort.propertySorters)) {
            return;
        }
        const newSort = { propertySorters };
        if (applyInternalSort) {
            setInternalSort(newSort);
        }

        onSortChange?.(newSort);
    }, [sortedColumns]);

    const handleMouseOver = useCallback((event: MouseEvent<HTMLElement>) => {
        const { rowIndex, dataColumn } = computeEventContext(event);
        const columnIndex = sortedColumns.findIndex((column) => column.key === dataColumn);

        if (rowIndex === undefined || columnIndex === -1 || (hoverCellIndex?.rowIndex === rowIndex && hoverCellIndex?.columnIndex === columnIndex)) {
            return;
        }

        setHoverCellIndex({ rowIndex, columnIndex });
    }, [hoverCellIndex?.columnIndex, hoverCellIndex?.rowIndex, sortedColumns]);

    const handleColumnMove = useCallback((column: ArgTable4Column<T>, event: MouseEvent) => {
        const isLocked = !!lockedColumns?.[column.key];

        const changeReason: ColumnChangeReason = {
            type: 'move',
            source: column.key,
        };

        containerRef.current && performColumnMove(containerRef.current,
            column,
            (isLocked) ? leftColumns : rightsColumns,
            (!isLocked) ? leftColumns : rightsColumns,
            isLocked,
            column.movable !== false,
            (isLocked) ? setLeftColumnsDragTransforms : setRightColumnsDragTransforms,
            setDraggedColumnKey,
            handleColumnHeaderDoubleClick,
            event,
            (columnIds: string[]) => {
                if (useInternalVisibleColumns) {
                    setInternalVisibleColumns(columnIds);
                }
                onVisibleColumnsChange && onVisibleColumnsChange(columnIds, [changeReason]);
                $yield(() => {
                    selectionManager?.clearSelection();
                });
            });
    }, [
        useInternalVisibleColumns,
        onVisibleColumnsChange,
        lockedColumns,
        handleColumnHeaderDoubleClick,
        leftColumns,
        rightsColumns,
        selectionManager,
    ]);

    const handleMouseDown = useCallback((event: MouseEvent<HTMLElement>) => {
        const {
            rowIndex,
            headerColumnId,
            resizeColumnId,
        } = computeEventContext(event);

        if (event.defaultPrevented) {
            return;
        }

        if (resizeColumnId) {
            event.preventDefault();

            if (event.button !== 0) {
                return;
            }

            const column = columns.find((c) => c.key === resizeColumnId);
            if (!column) {
                return;
            }
            containerRef.current && handleColumnResize(containerRef.current,
                column,
                event,
                !!lockedColumns?.[column.key],
                (newSize: number) => {
                    if (useInternalColumnWidths) {
                        setInternalColumnWidths((prev) => {
                            return {
                                ...prev,
                                [column.key]: newSize,
                            };
                        });
                    }
                    onColumnWidthChange?.(column, newSize);
                    updateHorizontalScrollbar();
                }, () => {
                    let newVisibleColumns;

                    if (visibleColumns?.length) {
                        newVisibleColumns = [...visibleColumns];
                    } else {
                        newVisibleColumns = columns.map((c) => c.key);
                    }

                    newVisibleColumns = pull(newVisibleColumns, column.key);
                    if (useInternalVisibleColumns) {
                        setInternalVisibleColumns(newVisibleColumns);
                    }

                    const changeReason: ColumnChangeReason = {
                        type: 'visible',
                        state: false,
                        source: column.key,
                    };

                    onVisibleColumnsChange?.(newVisibleColumns, [changeReason]);
                },
            );

            return;
        }

        if (disabled) {
            return;
        }

        if (isNumber(rowIndex)) {
            return;
        }

        if (headerColumnId) {
            event.preventDefault();

            if (event.button !== 0) {
                return;
            }
            const column = columns.find((c) => c.key === headerColumnId);
            if (!column) {
                return;
            }

            if ((event.target as HTMLElement).tagName.toUpperCase() === 'INPUT') {
                return true; //Custom header with an editable title
            }

            column.movable && handleColumnMove(column, event);

            return;
        }
    }, [disabled, columns, lockedColumns, useInternalColumnWidths, onColumnWidthChange, updateHorizontalScrollbar, visibleColumns, useInternalVisibleColumns, onVisibleColumnsChange, handleColumnMove]);

    const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
        const {
            rowIndex,
            resizeColumnId,
            dataColumn,
        } = computeEventContext(event);

        if (event.defaultPrevented || resizeColumnId || disabled) {
            return;
        }

        if (!onRowClick) {
            return;
        }

        if (isNumber(rowIndex)) {
            event.preventDefault();

            const rowData = dataProvider.getRow(rowIndex);
            if (isArgTableRowState(rowData)) {
                return;
            }

            onRowClick?.(event, rowData as T, rowIndex, dataColumn);

            return;
        }
    }, [disabled, dataProvider, onRowClick]);

    const leftColumnsWidth = leftColumns.reduce((acc, col) => {
        let colWidth = columnWidths?.[col.key];

        if (colWidth === undefined) {
            if (col.width === undefined) {
                return acc;
            }

            colWidth = col.width;
        }

        return acc + colWidth;
    }, 0);

    const handleColumnScroll = useCallback(() => {
        if (!scrollColumnName) {
            return;
        }

        const scrollColumnElement = containerRef.current?.querySelector<HTMLDivElement>(`[data-header="${scrollColumnName}"]`);

        if (!scrollColumnElement) {
            return;
        }

        bodyRef.current?.scroll({ behavior: 'smooth', left: scrollColumnElement.offsetLeft });
    }, [scrollColumnName]);

    const handleCursorChange = useCallback((cursor: ArgTable4Cursor) => {
        setCursor(cursor);
    }, []);

    const handleAdjustColumnsWidth = useCallback((body: HTMLElement) => {
        debug('handleAdjustColumnsWidth', body, adjustColumnStateRef.current);

        for (; body;) {
            if (body.hasAttribute('data-table')) {
                break;
            }
            if (!body.parentElement) {
                console.error('This element has no parent table');

                return;
            }
            body = body.parentElement;
        }

        if (adjustColumnStateTimerRef.current) {
            clearTimeout(adjustColumnStateTimerRef.current);
            adjustColumnStateTimerRef.current = undefined;
        }

        function adjust() {
            const columnSizes: Record<ArgTable4ColumnKey, number> = {};
            let newColumnSizes: Record<ArgTable4ColumnKey, number> = {};

            let newTotalWidth = 0;

            const bodyWidth = (body.getBoundingClientRect().width - (selectionProvider ? 40 : 0));

            debug('handleAdjustColumnsWidth', 'Body width=', bodyWidth);

            const resizableColumnKeys:ArgTable4ColumnKey[] = [];
            columns.forEach((column) => {
                if (column.rowHeader) {
                    return;
                }

                const columnComponent = body.querySelector(`[data-column="${escapeColumnKey(column.key)}"]`) as HTMLElement;

                if (!columnComponent) {
                    debug('handleAdjustColumnsWidth', 'Can not find column', column.key, column.columnName);

                    return;
                }

                const columnWidth = columnComponent.getBoundingClientRect().width;
                columnSizes[column.key] = columnWidth;
                newColumnSizes[column.key] = columnWidth;
                newTotalWidth += columnWidth;

                if (column.resizable !== false) {
                    resizableColumnKeys.push(column.key);
                }

                expandColumn(body,
                    column, (newSize) => {
                        debug('handleAdjustColumnsWidth', 'Request new size=', newSize, 'for column', column.columnName);

                        newColumnSizes[column.key] = newSize;
                        newTotalWidth += newSize - columnSizes[column.key];
                    });
            });

            if (bodyWidth > 0) {
                const leftWidth = bodyWidth - newTotalWidth;
                if (leftWidth > 0 && resizableColumnKeys.length) {
                    const leftByColumn = Math.floor(leftWidth / resizableColumnKeys.length);
                    debug('handleAdjustColumnsWidth', 'Add', leftByColumn, 'pixels ');
                    newColumnSizes = mapValues(newColumnSizes, (currentWidth, columnKey) => {
                        if (!resizableColumnKeys.includes(columnKey)) {
                            return currentWidth;
                        }

                        return currentWidth + leftByColumn;
                    });
                }
            }

            //console.log(newColumnSizes);

            if (useInternalColumnWidths) {
                setInternalColumnWidths((prev) => {
                    const result: Record<ArgTable4ColumnKey, number> = {
                        ...prev || {},
                        ...newColumnSizes,
                    };

                    return result;
                });
            } else {
                forEach(newColumnSizes, (newWidth, columnKey) => {
                    const column = columns.find((c) => c.key === columnKey);
                    if (column) {
                        onColumnWidthChange?.(column, newWidth);
                    }
                });
            }

            handleColumnScroll();
        }

        function waitForAdjust() {
            // do it as soon as possible
            requestAnimationFrame(adjust);
        }

        adjustColumnStateTimerRef.current = setTimeout(waitForAdjust, 0);
    }, [selectionProvider, columns, useInternalColumnWidths, onColumnWidthChange, handleColumnScroll]);

    const handleDataLoaded = useCallback((body: HTMLElement) => {
        debug('handleDataLoaded', 'adjustColumnsOnFirstDraw=', adjustColumnsOnFirstDraw);
        if (!adjustColumnsOnFirstDraw) {
            return;
        }

        if (adjustColumnStateRef.current === 'done') {
            return;
        }

        adjustColumnStateRef.current = 'done';

        handleAdjustColumnsWidth(body);
    }, [adjustColumnsOnFirstDraw, handleAdjustColumnsWidth]);


    const style = {
        '--arg-table4-header-height': `${headerHeight}px`,
        '--arg-table4-additional-header-height': `${additionalHeaderHeight || 0}px`,
        '--arg-table4-row-height': `${rowHeight}px`,
    } as CSSProperties;

    const cls = {
        disabled,
        [`type-${type}`]: true,
    };

    return (
        <div className={classNames('&', className, cls)}
             onClick={handleClick}
             onMouseOver={handleMouseOver}
             onMouseDown={handleMouseDown}
             onMouseLeave={handleMouseLeave}
             onContextMenu={handleContextMenu}
             onDoubleClick={handleDoubleClick}
             ref={containerRef}
             style={style}
             data-table={true}
             data-clone-component='arg-table4'>

            {/* locked columns */}
            <VirtualColumnScrollContainer<T>
                rowsCache={rowsCache}
                rowHeight={rowHeight}
                startNode={startNode}
                visibleNodeCount={visibleNodeCount}
                scrollTop={scrollTop}
                globalScrollTop={scrollTop}
                scrollLeft={0}
                totalHeight={visibleHeight}
                noVerticalScroll={noVerticalScroll}
                columns={leftColumns}
                columnsStartIndex={0}
                columnsEndIndex={leftColumns.length}
                scrollDisplayManager={scrollDisplayManager}
                className={classNames('&-left')}
                locked={true}
                firstColumn={true}
                lastColumn={!rightsColumns.length}
                header={header}
                bodyRef={lockedBodyRef}
                itemsCount={itemsCount}
                headerHeight={headerHeight}
                additionalHeaderHeight={additionalHeaderHeight}
                renderLoadingCell={renderLoadingCell}
                renderErrorCell={renderErrorCell}
                sort={sort}
                onColumnLock={handleColumnLock}
                onColumnSort={handleColumnSort}
                onColumnVisible={handleColumnVisible}
                canLockColumn={!!lockedColumns}
                dragColumnTransforms={leftColumnsDragTransforms}
                draggedColumnKey={draggedColumnKey}
                columnWidths={columnWidths}
                onColumnWidthChange={onColumnWidthChange}
                disabled={disabled}
                onDragStart={handleDragStart}
                onDragEnd={onDragEnd}
                additionalRows={additionalRows}
                onCursorChange={handleCursorChange}
                cursor={cursor}
                selectionManager={selectionManager}
                hoverCellIndex={hoverCellIndex}
                search={searchValue}
                columnRefs={columnRefsRef.current}
                disableHeaderContextMenu={disableHeaderContextMenu}
            />

            {/* unlocked columns   */}
            <VirtualColumnScrollContainer<T>
                rowsCache={rowsCache}
                rowHeight={rowHeight}
                startNode={startNode}
                visibleNodeCount={visibleNodeCount}
                noVerticalScroll={noVerticalScroll}
                scrollLeft={0}
                searchScrollTop={searchScrollTop}
                globalScrollTop={scrollTop}
                totalHeight={visibleHeight}
                columns={sortedColumns}
                columnsStartIndex={leftColumns.length}
                columnsEndIndex={sortedColumns.length}
                scrollDisplayManager={scrollDisplayManager}
                bodyRef={bodyRef}
                className={classNames('&-right')}
                firstColumn={!leftColumns.length}
                lastColumn={true}
                header={header}
                headerBodyRef={headerBodyRef}
                headerScrollContainerRef={headerScrollContainerRef}
                itemsCount={itemsCount}
                headerHeight={headerHeight}
                additionalHeaderHeight={additionalHeaderHeight}
                renderLoadingCell={renderLoadingCell}
                renderErrorCell={renderErrorCell}
                sort={sort}
                leftColumnsWidth={leftColumnsWidth}
                onColumnLock={handleColumnLock}
                onColumnSort={handleColumnSort}
                onColumnVisible={handleColumnVisible}
                canLockColumn={!!lockedColumns}
                dragColumnTransforms={rightColumnsDragTransforms}
                draggedColumnKey={draggedColumnKey}
                columnWidths={columnWidths}
                onColumnWidthChange={onColumnWidthChange}
                onDataLoaded={handleDataLoaded}
                disabled={disabled}
                onDragStart={handleDragStart}
                onDragEnd={onDragEnd}
                data-screenshot-id='arg-table-right'
                additionalRows={additionalRows}
                onCursorChange={handleCursorChange}
                cursor={cursor}
                selectionManager={selectionManager}
                hoverCellIndex={hoverCellIndex}
                search={searchValue}
                columnRefs={columnRefsRef.current}
                disableHeaderContextMenu={disableHeaderContextMenu}
            />

            {!!additionalRows && (
                <AdditionalRowsContainer<T>
                    bodyRef={additionalItemsBodyRef}
                    startNode={startNode}
                    visibleNodeCount={visibleNodeCount}
                    scrollTop={scrollTop}
                    globalScrollTop={scrollTop}
                    additionalRows={additionalRows}
                    totalHeight={visibleHeight}
                    scrollDisplayManager={scrollDisplayManager}
                    additionalHeaderHeight={additionalHeaderHeight}
                    additionalRowsContent={additionalRowsContent}
                />
            )}

            {horizontalScrollBar}

            {rowContextMenuVisible && !disabled && onRowContextMenuRender &&
                <ArgContextMenu
                    overlay={(getPopupContainer) => onRowContextMenuRender(
                        rowContextMenuVisible.event,
                        rowContextMenuVisible.row,
                        rowContextMenuVisible.rowIndex,
                        rowContextMenuVisible.columnIndex,
                        handleHideContextMenu,
                        getPopupContainer,
                    )}
                    visible={true}
                    x={rowContextMenuVisible.event.clientX}
                    y={rowContextMenuVisible.event.clientY}
                    onHide={handleHideContextMenu} />}

            {itemsCount === 0 && emptyRenderer && (
                <div className={classNames('&-empty')}>
                    {emptyRenderer()}
                </div>
            )}
        </div>
    );
}

function computeEventContext(event: MouseEvent<HTMLElement>): {
    rowIndex?: number;
    headerColumnId?: string;
    dragColumnId?: string;
    resizeColumnId?: string;
    dataColumn?: string;
} {
    let target: HTMLElement | null = event.target as HTMLElement;

    let rowIndex: number | undefined;
    let headerColumnId: string | undefined;
    let dataColumn: string | undefined;
    let dragColumnId: string | undefined;
    let resizeColumnId: string | undefined;

    for (; target; target = target.parentElement) {
        const headerDrag = target.getAttribute('data-dragcursor');
        if (headerDrag) {
            dragColumnId = headerDrag;
            break;
        }

        const headerResizer = target.getAttribute('data-columnresizer');
        if (headerResizer) {
            resizeColumnId = headerResizer;
            break;
        }

        const $rowIndex = target.getAttribute('data-rowindex');
        if ($rowIndex) {
            rowIndex = parseInt($rowIndex);

            const $dataColumn = target.parentElement?.getAttribute('data-column');
            if ($dataColumn) {
                dataColumn = $dataColumn;
            }
            break;
        }

        const $columnId = target.getAttribute('data-header');
        if ($columnId) {
            headerColumnId = $columnId;
            break;
        }

        const $table = target.getAttribute('data-table');
        if ($table) {
            return {};
        }
    }

    return {
        rowIndex,
        headerColumnId,
        dragColumnId,
        resizeColumnId,
        dataColumn,
    };
}
