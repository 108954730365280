import { defineMessages } from 'react-intl';
import { useContext, useMemo, useState } from 'react';

import { ArgButton, ArgMenu, ArgMenuItem, ArgMessageRenderer, ProgressMonitor, SubProgressMonitor, useArgNotifications, useCallbackAsync, useClassNames } from 'src/components/basic';
import { User } from '../../../../model/user';
import { UsersAndGroupsStateContext } from '../../providers/usersState';
import { useHasAnyPermissions } from '../../../../contexts/user-permission';
import { SettingsPermissions } from '../../../permissions/permissions';
import { UsersAdminConnector } from '../../../../utils/connectors/users-admin-connector';


const CLASSNAME = 'settings-deleted-users-actions-button-menu';
const messages = defineMessages({
    actions: {
        id: 'settings.deleted-users-actions-button.menu.actions',
        defaultMessage: 'Actions',
    },
    restore: {
        id: 'settings.deleted-users-actions-button.menu.restore',
        defaultMessage: 'Restore',
    },
    deletePermanently: {
        id: 'settings.deleted-users-actions-button.menu.deletePermanently',
        defaultMessage: 'Permanently delete',
    },
    deleteUserErrorMsg: {
        id: 'settings.deleted-users-actions-button.menu.deleteUserErrorMsg',
        defaultMessage: 'An error occurred while deleting permanently the users',
    },
    restoreUserErrorMsg: {
        id: 'settings.deleted-users-actions-button.menu.restoreUserErrorMsg',
        defaultMessage: 'An error occurred while restoring the users',
    },
});

export interface DeletedUsersActionsButtonMenuProps {
    selectedUsers: User[];
}

export function DeletedUsersActionsButtonMenu(props: DeletedUsersActionsButtonMenuProps) {
    const {
        selectedUsers,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const [visible, setVisible] = useState<boolean>(false);

    const notifications = useArgNotifications();

    const { setUsers, setDeletedUsers } = useContext(UsersAndGroupsStateContext);

    const canEditUsers = useHasAnyPermissions<SettingsPermissions>('admin.user.edition', 'admin.user.management');

    const [handleDeleteUsers] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const promises = selectedUsers.map(async (user) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);

            const p = UsersAdminConnector.getInstance().deleteUser(user.id, true, sub);

            return p;
        });

        try {
            await Promise.all(promises);
            const deletedUsers = await UsersAdminConnector.getInstance().getUsers(true);
            const selectedUsersIds = new Set(selectedUsers.map((user) => user.id));
            setUsers((currentUsers) => currentUsers.filter((user) => !selectedUsersIds.has(user.id)));
            setDeletedUsers(deletedUsers);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.deleteUserErrorMsg }, error as Error);
            throw error;
        }
    }, [selectedUsers, setUsers, setDeletedUsers, notifications]);

    const [handleRestoreUsers] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const promises = selectedUsers.map(async (user) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);

            const p = UsersAdminConnector.getInstance().restoreUser(user.id, sub);

            return p;
        });

        try {
            await Promise.all(promises);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            const deletedUsers = await UsersAdminConnector.getInstance().getUsers(true, sub2);

            const sub3 = new SubProgressMonitor(progressMonitor, 1);
            const users = await UsersAdminConnector.getInstance().getUsers(false, sub3);
            setDeletedUsers(deletedUsers);
            setUsers(users);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.restoreUserErrorMsg }, error as Error);
            throw error;
        }
    }, [selectedUsers, setUsers, setDeletedUsers, notifications]);

    const actions = useMemo(() => [
        {
            key: 'restore',
            label: messages.restore,
            disabled: !canEditUsers,
            onClick: handleRestoreUsers,
        },
        {
            key: 'delete',
            label: messages.deletePermanently,
            disabled: !canEditUsers,
            onClick: handleDeleteUsers,
        },
    ], [canEditUsers, handleRestoreUsers, handleDeleteUsers]);

    const actionsMenu = (
        <ArgMenu>
            {actions.map((action) => (
                <ArgMenuItem
                    key={action.key}
                    onClick={() => {
                        action.onClick();
                        setVisible(!visible);
                    }}
                    disabled={action.disabled}
                >
                    <ArgMessageRenderer message={action.label} />
                </ArgMenuItem>
            ))}
        </ArgMenu>
    );

    return (
        <ArgButton
            size='medium'
            type='secondary'
            right='dropdown'
            label={messages.actions}
            disabled={selectedUsers.length === 0}
            popover={visible && (
                actionsMenu
            )}
            popoverTrigger='click'
            popoverVisible={visible}
            className={classNames('&')}
            data-testid='actions-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            popoverClassName={classNames('&-popover')}
        />
    );
}
