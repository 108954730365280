import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { ProgressMonitor, useEffectAsync } from '../basic';
import { Environment } from '../../utils/environment';
import { BrandingConnector } from 'src/settings/visual-identity/connectors/branding-connector';
import { ApplicationOrModuleBranding } from 'src/settings/models/visual-identity';

export type ArgonosApplicationId = string;

export interface ArgonosApplicationBranding extends ApplicationOrModuleBranding {
    applicationId: ArgonosApplicationId;
    documentTitle?: string;
}

interface ArgonosApplicationBrandingContextType {
    branding: ArgonosApplicationBranding | undefined;
    setBranding: (branding: ArgonosApplicationBranding) => void;
}

const ArgonosApplicationBrandingContext = createContext<ArgonosApplicationBrandingContextType | undefined>(undefined);

const DISABLE_APPLICATION_DESCRIPTOR = localStorage.ARG_NO_BRANDING;

interface ArgonosApplicationBrandingProviderProps {
    children: ReactNode;
}

export function ArgonosApplicationBrandingProvider(props: ArgonosApplicationBrandingProviderProps) {
    const {
        children,
    } = props;

    const [branding, setBranding] = useState<ArgonosApplicationBranding>();

    useEffectAsync(async (progressMonitor) => {
        try {
            const result = await getApplicationArgonosBranding(progressMonitor);
            if (result) {
                setBranding(result);

                return;
            }
        } catch (error) {
            console.error(error);
        }
        const result: ArgonosApplicationBranding = {
            applicationId: Environment.appId || 'default-argonos',
            brandingName: Environment.appName,
        };
        setBranding(result);
    }, []);

    const contextValue = useMemo(() => ({
        branding,
        setBranding,
    }), [branding]);

    return <ArgonosApplicationBrandingContext.Provider value={contextValue}>
        {children}
    </ArgonosApplicationBrandingContext.Provider>;
}

export async function getApplicationArgonosBranding(
    progressMonitor: ProgressMonitor,
): Promise<ArgonosApplicationBranding | undefined> {
    if (DISABLE_APPLICATION_DESCRIPTOR) {
        return undefined;
    }

    return BrandingConnector.getInstance().getAppBranding(progressMonitor);
}

export function useApplicationBrandingContext(): [ArgonosApplicationBranding, (branding: ArgonosApplicationBranding) => void] {
    const context = useContext(ArgonosApplicationBrandingContext);

    if (!context) {
        throw new Error('Using ArgonosApplicationBranding Context outside of its Provider');
    }

    const { branding, setBranding } = context;

    const applicationId = Environment.appId;

    const mergedBranding = useMemo<ArgonosApplicationBranding>(() => {
        return {
            applicationId,
            brandingName: branding?.brandingName || Environment.appName,
            brandingLogoURL: branding?.brandingLogoURL,
            brandingIconURL: branding?.brandingIconURL,
            documentTitle: branding?.documentTitle,
        };
    }, [branding, applicationId]);

    return [mergedBranding, setBranding];
}

export function useApplicationBranding(): ArgonosApplicationBranding {
    const [branding] = useApplicationBrandingContext();

    return branding;
}
