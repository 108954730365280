import type { PublicUser, User, Users } from '../../model/user';
import { Collaborator, CollaboratorRole, CollaboratorType } from 'src/model/collaborator';
import { ArgUserId, ProgressMonitor, SubProgressMonitor } from '../../components/basic';
import { ConnectorRequestInit } from '../connector';
import { BaseConnector } from './base-connector';
import { mapDate } from './mappers';
import { getAdministrationApi } from './api-url';
import { AddUserDTO, EditUserDTO } from 'src/settings/models/dtoApi';
import { UserGroupsConnector } from '../../settings/connectors/user-groups-connector';

export class UsersAdminConnector extends BaseConnector {
    private static instance: UsersAdminConnector;

    static getInstance(): UsersAdminConnector {
        if (!UsersAdminConnector.instance) {
            UsersAdminConnector.instance = new UsersAdminConnector('administration.users', getAdministrationApi());
        }

        return UsersAdminConnector.instance;
    }


    async getUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<User> {
        const url = `/users/${encodeURIComponent(userId)}`;

        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const userResult: any = await this.request(url, options, progressMonitor);

        const ret = mapUser(userResult);

        return ret;
    }

    async getUsers(
        deleted: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<User[]> {
        const url = '/users';
        const options = {
            method: 'GET',
            params: {
                deleted,
            },
            verifyJSONResponse: true,
        };
        const response: Users = await this.request(url, options, progressMonitor);

        const users = response.users?.map(mapUser) || [];

        return users;
    }

    async addUser(
        addUserPayload: AddUserDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<{id: ArgUserId}> {
        const url = '/users';
        const options = {
            method: 'POST',
            verifyJSONResponse: true,
            json: {
                ...addUserPayload,
                fullName: addUserPayload.displayName,
            },
        };
        const newUserId = await this.request(url, options, progressMonitor);

        return newUserId;
    }

    async deleteUser(
        userId: ArgUserId,
        deletePermanently: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}`;
        const options = {
            method: 'DELETE',
            params: {
                deletePermanently,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async restoreUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}/restore`;
        const options = {
            method: 'POST',
        };

        await this.request(url, options, progressMonitor);
    }

    async editUser(
        editUserPayload: EditUserDTO,
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}`;
        const options = {
            method: 'PUT',
            json: {
                ...editUserPayload,
                fullName: editUserPayload.displayName,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async resetPassword(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<{password: string}> {
        const url = `/users/${encodeURIComponent(userId)}/password`;
        const options = {
            method: 'POST',
        };
        const newPassword = await this.request(url, options, progressMonitor);

        return newPassword;
    }

    async changePassword(
        userId: ArgUserId,
        password: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<{password: string}> {
        const url = `/users/${encodeURIComponent(userId)}/password`;
        const options = {
            method: 'PUT',
            json: {
                password,
            },
        };

        const newPassword = await this.request(url, options, progressMonitor);

        return newPassword;
    }

    async getPublicUsers(
        search?: string,
        deleted?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<PublicUser[]> {
        const url = '/users/public';

        const options: ConnectorRequestInit = {
            params: {
                q: search,
                deleted,
            },
            verifyJSONResponse: true,
        };

        const userResults = await this.request(url, options, progressMonitor);

        const ret = userResults.users?.map(mapUser) || [];

        return ret;
    }

    async getCollaborators(
        search?: string,
        deleted?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Collaborator[]> {
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const usersPromise = this.getPublicUsers(search, deleted, sub1);

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        const userGroupPromise = UserGroupsConnector.getInstance().getPublicGroups(search, sub2);

        const [usersResponse, userGroupsResponse] = await Promise.allSettled([usersPromise, userGroupPromise]);

        let ret = usersResponse.status === 'fulfilled'
            ? usersResponse.value.map(user => {
                const collaborator: Collaborator = {
                    identityId: user.id,
                    name: user.displayName || '',
                    type: CollaboratorType.User,
                    user: user,
                    role: CollaboratorRole.Reader,
                };

                return collaborator;
            })
            : [];

        ret = userGroupsResponse.status === 'fulfilled'
            ? ret.concat(userGroupsResponse.value.map(userGroup => {
                const collaborator: Collaborator = {
                    identityId: userGroup.id,
                    type: CollaboratorType.Group,
                    name: userGroup.name,
                    role: CollaboratorRole.Reader,
                };

                return collaborator;
            }))
            : ret;

        return ret;
    }

    async getPublicUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<PublicUser> {
        const url = `/users/public/${encodeURIComponent(userId)}`;

        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const userResult: any = await this.request(url, options, progressMonitor);

        const ret = mapUser(userResult);

        return ret;
    }

    async enableUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}/enable`;
        const options = {
            method: 'POST',
        };

        await this.request(url, options, progressMonitor);
    }

    async disableUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}/disable`;
        const options = {
            method: 'POST',
        };

        await this.request(url, options, progressMonitor);
    }
}

export function mapUser(userInfo: any): User {
    const user: User = {
        ...userInfo,
        displayName: userInfo.displayName || userInfo.fullName || userInfo.userName,
        createdDate: mapDate(userInfo.createdDate),
        lastUpdatedDate: mapDate(userInfo.lastUpdatedDate),
    };

    return user;
}

export function mapMeToUser(basicUser: User): User {
    const user = mapUser(basicUser);

    return { ...user };
}

