import { Operator, Permission } from './operator-dropdown';

export const getPermissions = (permissionsWithOperators: string[], permissionsList: Permission[], operatorsList: Operator[]): {
    operator: Operator;
    permissions: Permission[];
} => {
    function mapPermission(ps: string[]): Permission[] {
        return ps.map((p) => {
            return permissionsList.find((op) => op.key === p)!;
        });
    }

    function mapOperator(operator: string) {
        return operatorsList.find((op) => op.key === operator)!;
    }

    const [operators, permissions] = permissionsWithOperators.reduce(
        (accumulator, permission) => {
            return [
                [...accumulator[0], permission.slice(0, 1)],
                [...accumulator[1], permission.slice(1)],
            ];
        },
        [[], []] as [string[], string[]],
    );

    if (operators.every((op) => op === '+')) {
        const permissionsWithoutNone = permissions.filter((permission) => permission !== 'none');
        const hasAll = permissionsWithoutNone.some((permissions) => permissions === 'all');

        return {
            operator: mapOperator(operators[0]),
            permissions: hasAll ? permissionsList : mapPermission(permissionsWithoutNone.filter(Boolean)),
        };
    }

    if (operators.every((op) => op === '-')) {
        const permissionsWithoutNone = permissions.filter((permission) => permission !== 'none');
        const hasAll = permissionsWithoutNone.some((permissions) => permissions === 'all');

        return {
            operator: mapOperator(operators[0]),
            permissions: hasAll ? permissionsList : mapPermission(permissionsWithoutNone.filter(Boolean)),
        };
    }

    if (operators.some((op) => op === '=')) {
        const lastEqualOperatorIdx = operators.lastIndexOf('=');
        const initialPermission = permissions[lastEqualOperatorIdx];

        let permList: string[];

        if (initialPermission === 'all') {
            permList = permissionsList.map((p) => p.key);
        } else if (initialPermission === 'none') {
            permList = [];
        } else {
            permList = [initialPermission];
        }

        for (let i = lastEqualOperatorIdx + 1; i < operators.length; i++) {
            if (operators[i] === '-' && permissions[i] === 'all') {
                permList = [];
            } else if (operators[i] === '-') {
                permList = permList.filter((p) => p !== permissions[i]);
            } else if (operators[i] === '+' && permissions[i] === 'all') {
                permList = permissionsList.map((p) => p.key);
            } else if (operators[i] === '+' && !permList.includes(permissions[i])) {
                permList = [...permList, permissions[i]];
            }
        }

        return {
            operator: mapOperator('='),
            permissions: mapPermission(permList.filter(Boolean)),
        };
    }

    throw new Error('Not supported');
};

export const splitMetapropertyPredicate = (
    input: string | undefined,
): {
    userOrMeta: 'user' | 'meta';
    operator: string | undefined;
    value?: string;
} => {
    if (input?.startsWith('profile')) {
        const match = input.match(/profile:(=|!=|>=|<=|>|<)(.*)/);

        return {
            userOrMeta: 'user',
            operator: match?.[1],
            value: match?.[2],
        };
    } else {
        const match = input?.match(/(=|!=|>=|<=|>|<)"(.*)"/);

        return {
            userOrMeta: 'meta',
            operator: match?.[1],
            value: match?.[2],
        };
    }
};

export const getPredicate = (
    userOrMeta: 'user' | 'meta',
    operator: string | undefined,
    value: string | undefined,
) => {
    if (userOrMeta === 'user') {
        return `profile:${operator || ''}${value || ''}`;
    } else {
        return `${operator || ''}"${value || ''}"`;
    }
};
