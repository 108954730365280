import { cloneDeep, isArray, keyBy, set } from 'lodash';
import { useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ArgCombo, useClassNames } from 'src/components/basic';
import { OntologyProperty } from 'src/settings/universes/ontology/types';
import { useOntologyContext, useValuationRuleStateContext } from 'src/settings/universes/common/providers/policy-rules-provider';
import { DropdownTooltipField } from '../../../../common/dropdown-tooltip-field/dropdown-tooltip-field';
import { ValuationEffectFilter } from 'src/settings/models/valuation-policy';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';
import { getTargetsUniqProperties } from 'src/settings/universes/common/policy-utils';

import './valuation-rule-effect-block.less';

const messages = defineMessages({
    select: {
        id: 'settings.valuation-rule-effect.item.select',
        defaultMessage: 'Select',
    },
    all: {
        id: 'settings.valuation-rule-effect.all',
        defaultMessage: 'all',
    },
});

interface UniverseItemDropdownProps {
    targetType: 'object' | 'property';
    effectValue: ValuationEffectFilter;
    index: number;
}

export function UniverseItemDropdown(props: UniverseItemDropdownProps) {
    const {
        effectValue,
        targetType,
        index,
    } = props;

    const classNames = useClassNames('settings-valuation-rule-effect-block');
    const { ontologySchema } = useOntologyContext();
    const { rule, editable, setRule } = useValuationRuleStateContext();

    const properties = useMemo(() => (
        getTargetsUniqProperties(ontologySchema, rule.Targets)
    ), [ontologySchema, rule.Targets]);

    const propertiesByName = useMemo(() => {
        return keyBy(properties, property => property.name);
    }, [properties]);

    const value = useMemo(() => {
        if (targetType === 'property') {
            return properties.filter((property) => {
                if (effectValue.match?.length === 0) {
                    return false;
                }

                return effectValue.match?.some((match) => `*${property.name}`.match(new RegExp(match.replace('*', '/*'))));
            });
        }

        return effectValue.setProperty?.name ? propertiesByName[effectValue.setProperty?.name] : undefined;
    }, [effectValue.match, effectValue.setProperty?.name, properties, propertiesByName, targetType]);

    const dropdownDisplayText = useMemo(() => {
        if (!value || (isArray(value) && value.length === 0)) {
            return undefined;
        }
        if (!isArray(value)) {
            return value.displayName;
        }

        return value.length === properties.length
            ? <FormattedMessage {...messages.all} />
            : effectValue.match?.map(matchValue => propertiesByName[matchValue]?.displayName || matchValue).join(', ');
    }, [value, properties.length, effectValue.match, propertiesByName]);

    return editable ? (
        <ArgCombo<OntologyProperty>
            className={classNames('&-universe-items-filters')}
            hideTags={true}
            placeholder={messages.select}
            items={properties}
            getItemKey={(option) => option.name}
            getItemLabel={(option) => option.displayName}
            initialValue={value}
            cardinality={targetType === 'object' ? 'one' : 'zeroMany'}
            size='small'
            popoverClassName='arg-input-popover-no-max-width'
            renderInput={() => (
                <DropdownTooltipField
                    className={classNames('&-universe-items-filters-input-text')}
                    value={dropdownDisplayText}
                />
            )}
            onChange={(value) => {
                setRule((currentRule) => {
                    const newEffectFilter: ValuationEffectFilter = targetType === 'property' ? {
                        ...effectValue,
                        match: Array.isArray(value) ? (value.length === properties.length ? ['*'] : value.map(val => val.name)) : value?.name,
                    } : {
                        ...effectValue,
                        setProperty: {
                            onEvent: effectValue.setProperty?.onEvent,
                            name: isArray(value) ? value[0]?.name : value?.name,
                        },
                    };

                    return set(cloneDeep(currentRule), `Effects[${index}]`, {
                        [targetType]: newEffectFilter,
                    });
                });
            }}
            enableFilter={true}
        />
    ) : (
        <ReadOnlyFilterInput
            className={classNames('&-universe-items-filters')}
            value={dropdownDisplayText}
        />
    );
}
