import { useEffect, useState } from 'react';
import { first, isArray, isEmpty } from 'lodash';

import { ToolContext, ToolContextId } from './tool-context';
import { useToolItem } from './use-tool-item';
import { immutableEmptyArray } from '../utils/immutable-set';
import { SelectionProvider } from '../arg-providers/selection-provider';


/**
 * Represents the context of a target item or items.
 *
 * @template K - The type of the target item.
 */
export interface TargetItemEnvironmentContext<K> {
    firstItem(): K|undefined;

    listItemKeys(): string[];
    getItem(key: string): K|undefined;
    countItem(): number;

    getItemsMimeTypes?(): string[];
}


/**
 * Represents the context of an async target item or items.
 *
 * @template K - The type of the target item.
 */
export interface AsyncTargetItemEnvironmentContext<K> extends Omit<TargetItemEnvironmentContext<K>, 'firstItem'|'getItem'> {
    firstItem(): Promise<K|undefined>;

    getItem(key: string): Promise<K|undefined>;
}

/**
 * Add a target feature to an environment context.
 *
 * @template T - The type of the environment context.
 * @template K - The type of the target item.
 */
export type TargetEnvironmentContext<T, K> =TargetItemEnvironmentContext<K> & T;
export type AsyncTargetEnvironmentContext<T, K> =AsyncTargetItemEnvironmentContext<K> & T;


const NO_KEYS = immutableEmptyArray<string>();

export function useTargetToolContext<T = undefined, K= any>(identifier: ToolContextId, overriddenToolContext?: ToolContext<TargetEnvironmentContext<T, K>>): ToolContext<TargetEnvironmentContext<T, K>> {
    const [toolContext] = useState<ToolContext<TargetEnvironmentContext<T, K>>>(
        () => new ToolContext<TargetEnvironmentContext<T, K>>(identifier),
    );

    useEffect(() => {
        return () => {
            toolContext.destroy();
        };
    }, []);

    useToolItem(toolContext, {
        path: 'open',
        order: 100,
        type: 'group',
    });

    useToolItem(toolContext, {
        path: 'undo-redo',
        order: 200,
        type: 'group',
    });

    useToolItem(toolContext, {
        path: 'edit',
        order: 300,
        type: 'group',
    });

    useToolItem(toolContext, {
        path: 'actions',
        order: 500,
        type: 'group',
    });

    useToolItem(toolContext, {
        path: 'delete',
        order: 800,
        type: 'group',
    });

    useToolItem(toolContext, {
        path: 'properties',
        order: 1000,
        type: 'group',
    });

    useToolItem(toolContext, {
        path: 'others',
        order: 2000,
        type: 'group',
    });

    return overriddenToolContext || toolContext;
}

export function createTargetEnvironmentContextFromItem<T=undefined, K=any>(parent: T, itemKey: string, item: K, itemMimeTypes?: string[]|string):TargetEnvironmentContext<T, K> {
    const result:TargetEnvironmentContext<T, K> = {
        ...parent,

        firstItem():K|undefined {
            return item;
        },
        listItemKeys(): string[] {
            return [itemKey];
        },
        getItem(key: string): K|undefined {
            if (key === itemKey) {
                return item;
            }

            return undefined;
        },
        countItem(): number {
            return 1;
        },
    };

    if (itemMimeTypes) {
        const i = isArray(itemMimeTypes) ? itemMimeTypes : [itemMimeTypes];
        result.getItemsMimeTypes = () => {
            return i;
        };
    }

    return result;
}

export function createTargetEnvironmentContextFromItems<T=undefined, K=any>(parent: T, itemKeys: string[], getItemFromKey: (key: string)=>K|undefined, itemsMimeTypes?: string[]):TargetEnvironmentContext<T, K> {
    const result:TargetEnvironmentContext<T, K> = {
        ...parent,

        firstItem():K|undefined {
            if (isEmpty(itemKeys)) {
                return undefined;
            }
            const firstKey = first(itemKeys)!;
            const firstItem = getItemFromKey(firstKey);

            return firstItem;
        },
        listItemKeys(): string[] {
            return itemKeys;
        },
        getItem(key: string): K|undefined {
            const item = getItemFromKey(key);

            return item;
        },
        countItem(): number {
            return itemKeys.length;
        },
    };

    if (itemsMimeTypes) {
        result.getItemsMimeTypes = () => {
            return itemsMimeTypes;
        };
    }

    return result;
}


export function createTargetEnvironmentContextFromSelectionProvider<T=undefined, K=any>(parent: T, selectionProvider: SelectionProvider<K>, getItemFromKey: (key: string)=>K|undefined, itemsMimeTypes?: string[]):TargetEnvironmentContext<T, K> {
    const result:TargetEnvironmentContext<T, K> = {
        ...parent,

        firstItem():K|undefined {
            if (!selectionProvider.count) {
                return undefined;
            }
            const firstKey = selectionProvider.first();
            if (!firstKey) {
                return undefined;
            }
            const firstItem = getItemFromKey(firstKey);

            return firstItem;
        },
        listItemKeys(): string[] {
            return selectionProvider.list();
        },
        getItem(key: string): K|undefined {
            const item = getItemFromKey(key);

            return item;
        },
        countItem(): number {
            return selectionProvider.count;
        },
    };

    if (itemsMimeTypes) {
        result.getItemsMimeTypes = () => {
            return itemsMimeTypes;
        };
    }

    return result;
}


export function createEmptyTargetEnvironmentContext<T=undefined, K=any>(parent: T):TargetEnvironmentContext<T, K> {
    const result:TargetEnvironmentContext<T, K> = {
        ...parent,

        firstItem():K|undefined {
            return undefined;
        },
        listItemKeys(): string[] {
            return NO_KEYS as string[];
        },
        getItem(key: string): K|undefined {
            return undefined;
        },
        countItem(): number {
            return 0;
        },
    };

    return result;
}
