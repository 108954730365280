import { createContext, ReactNode, useContext } from 'react';
import * as UUID from 'uuid';

export interface ApplicationSessionProps {
    children: ReactNode;
}

export type ApplicationSessionId = string;

const applicationSessionId = UUID.v4();

const ApplicationSessionIdContext = createContext<ApplicationSessionId>(applicationSessionId);

export function ApplicationSessionIdProvider(props: ApplicationSessionProps) {
    const { children } = props;

    return (
        <ApplicationSessionIdContext.Provider value={applicationSessionId}>
            {children}
        </ApplicationSessionIdContext.Provider>
    );
}

export function useApplicationSessionId():ApplicationSessionId {
    const applicationSessionId = useContext(ApplicationSessionIdContext);

    if (applicationSessionId === undefined) {
        throw new Error('useApplicationSessionId must be used within a ApplicationSessionIdProvider');
    }

    return applicationSessionId;
}
