import React, { MouseEvent, useCallback, useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';

import { ArgButton, ArgIcon, useClassNames, useIsSelected, ARG_BYPASS_DND_DISABLER_CLASSNAME } from 'src/components/basic';
import { FormElement } from 'src/components/common/forms/model';
import { FormRenderContext } from 'src/components/common/forms/render-factory';
import { OntologyFormContext } from '../types';
import { createFormRemoveElements } from '../actions/remove-elements';
import { DraggabeFormElements, canDropChildrenInside } from '../utils';
import { FormRepository } from '../actions/form-actions-engine';
import { useDraggingContext } from './dragging-context';

import './form-interaction-wrapper.less';

export interface FormInteractionWrapperProps {
    formElement: FormElement;
    formContext: FormRenderContext;
    ontologyContext: OntologyFormContext;
    children: React.ReactNode;
}

export function FormInteractionWrapper(props: FormInteractionWrapperProps) {
    const { formElement, ontologyContext, children, formContext } = props;
    const { formActionsEngine, selectionProvider } = ontologyContext;
    const { parentMap, formDocument, setInternalFormDocument } = formContext;

    const classNames = useClassNames('form-interaction-wrapper');
    const isSelected = useIsSelected(selectionProvider, formElement);
    const { dragging, setDragging } = useDraggingContext();
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId, canDrop }, drop] = useDrop<FormElement, void, { handlerId: Identifier | null; canDrop: boolean }>({
        accept: DraggabeFormElements,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                canDrop: monitor.canDrop(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            if (item.id === formElement.id) {
                return;
            }

            const isContainer = canDropChildrenInside(formElement);

            if (isContainer && formElement.children.includes(item)) {
                return;
            }

            const parentDrag = parentMap.get(item);
            const parentHover = isContainer ? formElement : parentMap.get(formElement);

            if (!parentHover || parentHover.id === dragging) {
                return;
            }

            const dragIndex = parentDrag?.children.indexOf(item);
            let hoverIndex = parentHover.children.indexOf(formElement);

            if (hoverIndex === -1 && parentHover.children.length > 0) {
                return;
            }

            if (hoverIndex !== -1) {
                if (dragIndex === hoverIndex && parentDrag === parentHover) {
                    return;
                }

                const hoverBoundingRect = ref.current?.getBoundingClientRect();

                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

                const clientOffset = monitor.getClientOffset();

                const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

                // Dragging in same parent
                if (dragIndex && parentDrag === parentHover) {
                    // Dragging downwards
                    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                        return;
                    }

                    // Dragging upwards
                    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                        return;
                    }
                } else if (hoverClientY > hoverMiddleY) {
                    hoverIndex += 1;
                }
            } else {
                hoverIndex = 0;
            }

            const tempForm = new FormRepository(formDocument);
            tempForm.add(parentHover, [item], hoverIndex);
            setInternalFormDocument(tempForm.formDocument);
        },
    });


    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: formElement.type,
        item: () => {
            setDragging(formElement.id);

            return formElement;
        },
        end(item, monitor) {
            setDragging(undefined);

            if (!monitor.didDrop()) {
                setInternalFormDocument(formDocument);
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
        isDragging(monitor) {
            return formElement.id === monitor.getItem().id;
        },
    }), [formElement]);

    const handleSelection = (event: MouseEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        // Make sure it select the element and not its parent in nested forms
        const closest = ((event.target as HTMLElement)).closest(`.${classNames('&')}`);
        if (closest !== ref.current) {
            return;
        }

        selectionProvider.set(formElement, 'wrapper-selection');
    };

    const handleRemove = useCallback(() => {
        const action = createFormRemoveElements([formElement], selectionProvider);

        formActionsEngine.do(action).catch((error) => {
            console.error(error);
        });
    }, [formActionsEngine, formElement, selectionProvider]);

    const cls = {
        selected: isSelected,
        dragging: isDragging,
        candrop: canDrop,
    };

    preview(drop(ref));

    return (
        <div
            ref={ref}
            className={classNames('&', cls, ARG_BYPASS_DND_DISABLER_CLASSNAME)}
            onMouseDown={handleSelection}
            data-handler-id={handlerId}
        >
            <div ref={drag} className='icon-wrapper'>
                <ArgIcon name='icon-6dots' />
            </div>
            <div className={classNames('&-content')}>
                {children}
            </div>
            <ArgButton icon='icon-trash' onClick={handleRemove} type='ghost' />
        </div>
    );
}
