import { defineMessages } from 'react-intl';
import { useContext, useMemo, useState } from 'react';

import { ArgButton, ArgMenu, ArgMenuItem, ArgMessageRenderer, ProgressMonitor, SubProgressMonitor, useArgModalContext, useArgNotifications, useCallbackAsync, useClassNames } from 'src/components/basic';
import { User } from '../../../../model/user';
import { UsersAdminConnector } from '../../../../utils/connectors/users-admin-connector';
import { UsersAndGroupsStateContext } from '../../providers/usersState';
import { ConfirmModal } from '../../../../components/common/modal2/confirm-modal/confirm-modal';
import { useHasAnyPermissions } from '../../../../contexts/user-permission';
import { SettingsPermissions } from '../../../permissions/permissions';
import { useUsers } from '../../../hooks/use-users';


const CLASSNAME = 'settings-users-actions-button-menu';
const USERS_DELETE_MODAL_ID = 'USERS_DELETE_MODAL_ID';
const messages = defineMessages({
    actions: {
        id: 'settings.users-actions-button.menu.actions',
        defaultMessage: 'Actions',
    },
    deactivate: {
        id: 'settings.users-actions-button.menu.deactivate',
        defaultMessage: 'Desactivate',
    },
    activate: {
        id: 'settings.users-actions-button.menu.activate',
        defaultMessage: 'Activate',
    },
    delete: {
        id: 'settings.users-actions-button.menu.delete',
        defaultMessage: 'Delete',
    },
    deleteUserErrorMsg: {
        id: 'settings.users-actions-button.menu.deleteUserErrorMsg',
        defaultMessage: 'An error occurred while deleting the users',
    },
    deleteConfirmTitle: {
        id: 'setting.users-actions-button.menu.deleteConfirmTitle',
        defaultMessage: '{count, plural, =1 {Delete user} other {Delete users}}',
    },
    deleteConfirmMessage: {
        id: 'settings.users-actions-button.menu.deleteConfirmMessage',
        defaultMessage: '{count, plural, =1 {Are you sure you want to delete \"{name}\" ?} other {Are you sure you want to delete these {count} users ?}}',
    },
});

export interface UsersActionsButtonMenuProps {
    selectedUsers: User[];
}

export function UsersActionsButtonMenu(props: UsersActionsButtonMenuProps) {
    const {
        selectedUsers,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const [visible, setVisible] = useState<boolean>(false);

    const notifications = useArgNotifications();

    const modalContext = useArgModalContext();

    const { setUsers, setDeletedUsers } = useContext(UsersAndGroupsStateContext);

    const canEditUsers = useHasAnyPermissions<SettingsPermissions>('admin.user.edition', 'admin.user.management');

    const changeStatus = useUsers();

    const [handleDeleteUsers] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const promises = selectedUsers.map(async (user) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);

            const p = UsersAdminConnector.getInstance().deleteUser(user.id, false, sub);

            return p;
        });

        try {
            await Promise.all(promises);
            const deletedUsers = await UsersAdminConnector.getInstance().getUsers(true);
            setUsers((currentUsers) =>
                currentUsers.filter((existingUser) => !selectedUsers.find((u) => u.id === existingUser.id)),
            );
            setDeletedUsers(deletedUsers);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.deleteUserErrorMsg }, error as Error);
            throw error;
        }
    }, [selectedUsers, setUsers, setDeletedUsers, notifications]);

    const [handleUpdateUsers] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const promises = selectedUsers.map(async (user) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);
            const p = UsersAdminConnector.getInstance().getUser(user.id, sub);

            return p;
        });

        try {
            const updatedUsers = await Promise.all(promises);

            setUsers((currentUsers) =>
                currentUsers.map((existingUser) => {
                    const updatedUser = updatedUsers.find((updatedUser) => updatedUser.id === existingUser.id);
                    if (updatedUser) {
                        return updatedUser;
                    }

                    return existingUser;
                }),
            );
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.deleteUserErrorMsg }, error as Error);
            throw error;
        }
    }, [notifications, selectedUsers, setUsers]);

    const [handleChangeStatus] = useCallbackAsync(async (progressMonitor: ProgressMonitor, enable: boolean) => {
        const promises = selectedUsers.map(async (user) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);

            if (!enable && user.isActive) {
                const p = UsersAdminConnector.getInstance().disableUser(user.id, sub);

                return p;
            }
            if (enable && !user.isActive) {
                const p = UsersAdminConnector.getInstance().enableUser(user.id, sub);

                return p;
            }
        });

        try {
            await Promise.all(promises);

            await handleUpdateUsers();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.deleteUserErrorMsg }, error as Error);
            throw error;
        }
    }, [handleUpdateUsers, notifications, selectedUsers]);

    const usersDeletedName = selectedUsers.length === 1 ? selectedUsers[0].displayName : undefined;
    const allUsersActive = selectedUsers.every((user) => user.isActive);
    const allUsersDesactive = selectedUsers.every((user) => !user.isActive);

    const actions = useMemo(() => [
        {
            key: 'deactivate',
            label: messages.deactivate,
            disabled: !canEditUsers || allUsersDesactive,
            onClick: () => handleChangeStatus(false),
        },
        {
            key: 'activate',
            label: messages.activate,
            disabled: !canEditUsers || allUsersActive,
            onClick: () => handleChangeStatus(true),
        },
        {
            key: 'delete',
            label: messages.delete,
            disabled: !canEditUsers,
            onClick: () => {
                modalContext.open(USERS_DELETE_MODAL_ID, (
                    <ConfirmModal
                        type='delete'
                        title={messages.deleteConfirmTitle}
                        alertMessage={messages.deleteConfirmMessage}
                        messageValues={{ count: selectedUsers.length, name: usersDeletedName }}
                        onClose={() => modalContext.close(USERS_DELETE_MODAL_ID)}
                        onConfirm={handleDeleteUsers}
                    />
                ));
            },
        },
    ], [allUsersActive, canEditUsers, allUsersDesactive, handleChangeStatus, modalContext, selectedUsers.length, usersDeletedName, handleDeleteUsers]);

    const actionsMenu = (
        <ArgMenu>
            {actions.map((action) => (
                <ArgMenuItem
                    key={action.key}
                    onClick={() => {
                        action.onClick();
                        setVisible(!visible);
                    }}
                    disabled={action.disabled}
                >
                    <ArgMessageRenderer message={action.label} />
                </ArgMenuItem>
            ))}
        </ArgMenu>
    );

    return (
        <ArgButton
            size='medium'
            type='secondary'
            right='dropdown'
            label={messages.actions}
            disabled={selectedUsers.length === 0}
            popover={visible && (
                actionsMenu
            )}
            popoverTrigger='click'
            popoverVisible={visible}
            className={classNames('&')}
            data-testid='actions-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            popoverClassName={classNames('&-popover')}
        />
    );
}
