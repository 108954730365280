import { ReactNode, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedNumber, MessageDescriptor } from 'react-intl';

import { ArgIcon, ArgImage, ArgMessageRenderer, ClassValue, useClassNames } from 'src/components/basic';
import { EmptyPane } from '../../../components/common/panes/empty-pane';
import { Extension } from '../../models/extension';
import { ArgonosModule } from '../../../components/application/modules';
import { getFileSize } from '../../../utils/file';
import { ExtensionComponents } from 'src/settings/extensions/components/extension-components';
import { ExtensionPreviewImages } from 'src/settings/extensions/components/extension-preview-images';

import './extension-information-panel.less';

const CLASSNAME = 'settings-extension-information-panel';

const messages = defineMessages({
    description: {
        id: 'settings.extension-information-panel.description',
        defaultMessage: 'Description',
    },
    version: {
        id: 'settings.extension-information-panel.version',
        defaultMessage: 'Version',
    },
    author: {
        id: 'settings.extension-information-panel.author',
        defaultMessage: 'Author',
    },
    fileSize: {
        id: 'settings.extension-information-panel.fileSize',
        defaultMessage: 'Size',
    },
    noExtensionSelected: {
        id: 'settings.extension-information-panel.noExtensionSelected',
        defaultMessage: 'No extension selected',
    },
    extensionProperties: {
        id: 'settings.extension-information-panel.ExtensionProperties',
        defaultMessage: 'Properties',
    },
});

interface PropertyInformation {
    label: MessageDescriptor;
    content: ReactNode;
    key: string;
}

export interface ExtensionInformationPanelProps {
    selectedExtensions: Extension[];
    className?: ClassValue;
    argonosModule?: ArgonosModule;
}

export function ExtensionInformationPanel(props: ExtensionInformationPanelProps) {
    const {
        selectedExtensions,
        className,
        argonosModule,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const [selectedExtensionIndex, setSelectedExtensionIndex] = useState(0);

    const extension: Extension | undefined = selectedExtensions[selectedExtensionIndex];
    const extensionName: string | undefined = extension?.name;
    const extensionContentHash: string | undefined = extension?.contentHash;

    // update selected index when the selection changes
    useEffect(() => {
        setSelectedExtensionIndex(0);
    }, [selectedExtensions]);

    const propertiesData = useMemo<PropertyInformation[]>(() => getProperyInformation(extension), [extension]);


    if (!extension) {
        return (
            <div className={classNames('&', className)}>
                <div className={classNames('&-no-extension-pane')}>
                    <EmptyPane
                        key='loading'
                        message={messages.noExtensionSelected}
                        backgroundAnimation='wave'
                        size='medium'
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <div className={classNames('&-header-title-container')}>
                    <ArgImage className={classNames('&-image')} src={extension.metadata.icon} />
                    <h3 className={classNames('&-header-title')}>
                        {extension.name}
                    </h3>
                </div>
                {selectedExtensions.length > 1 && (
                    <div className={classNames('&-toggle')}>
                        <div
                            onClick={() => {
                                setSelectedExtensionIndex((currentSelectedExtensionIndex) => {
                                    return Math.max(currentSelectedExtensionIndex - 1, 0);
                                });
                            }}
                            className={classNames(
                                selectedExtensionIndex === 0
                                    ? '&-toggle-disabled'
                                    : '&-toggle-enabled',
                            )}
                        >
                            <ArgIcon name='icon-previous' />
                        </div>
                        {selectedExtensionIndex + 1}
                        {'/'}
                        {selectedExtensions.length}
                        <div
                            onClick={() => {
                                setSelectedExtensionIndex((currentSelectedExtensionIndex) => {
                                    return Math.min(currentSelectedExtensionIndex + 1, selectedExtensions.length - 1);
                                });
                            }}
                            className={classNames(
                                selectedExtensionIndex === selectedExtensions.length - 1
                                    ? '&-toggle-disabled'
                                    : '&-toggle-enabled',
                            )}
                        >
                            <ArgIcon name='icon-next' />
                        </div>
                    </div>
                )}
            </div>
            <div className={classNames('&-scrollable-body')}>
                <div className='&-properties'>
                    <ArgMessageRenderer
                        className={classNames('&-properties-header')}
                        message={messages.extensionProperties}
                    />
                    <div className={classNames('&-properties-container')}>
                        {propertiesData.map((property) => (
                            <div key={property.key} className={classNames('&-property-row')}>
                                <ArgMessageRenderer className={classNames('&-property-row-title')} message={property.label} size='small' />
                                <div className={classNames('&-property-row-content')}>
                                    {property.content}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <ExtensionComponents className={classNames('&-components')} argonosModule={argonosModule} extensionName={extensionName} extensionContentHash={extensionContentHash} />
                <ExtensionPreviewImages className={classNames('&-images')} argonosModule={argonosModule} extensionName={extensionName} />
            </div>
        </div>
    );
}

function formatFileSize(fileSize: number) {
    const { unit, value } = getFileSize(fileSize);

    return <FormattedNumber value={value} style='unit' unit={unit} />;
}

function getProperyInformation(extension?: Extension): PropertyInformation[] {
    if (!extension?.metadata) {
        return [];
    }

    return [
        {
            key: 'description',
            label: messages.description,
            content: extension.metadata.description,
        },
        {
            key: 'version',
            label: messages.version,
            content: extension.metadata.version,
        },
        {
            key: 'author',
            label: messages.author,
            content: extension.metadata.author,
        },
        {
            key: 'size',
            label: messages.fileSize,
            content: formatFileSize(extension.metadata.packageSize),
        },
    ];
}
