import { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import { PropertyInformation } from './types';
import { ClassNamesFn, ClassValue, useClassNames } from '../../../../../components/basic';
import { UniverseKBFeedingLastSynchronization, UniverseKBLastFeedingStatus } from '../../../../models/dtoApi';
import { KBFeedingPropertyValue } from './kb-feeding-property-value';
import { ResultTag } from '../../../../../components/common/tags/result-tag';
import { buildRangeDatesContent } from './utils';


import './kb-feeding-monitoring-last-synchronization.less';

const CLASSNAME = 'settings-kb-feeding-monitoring-last-synchronization';

const messages = defineMessages({
    rangeDatesTitle: {
        id: 'settings.kb-feeding-monitoring-panel.lastSynchronization.rangeDatesTitle',
        defaultMessage: 'Range updates dates',
    },
    result: {
        id: 'settings.kb-feeding-monitoring-panel.lastSynchronization.result',
        defaultMessage: 'Result',
    },
});

interface KBFeedingLastSynchronizationProps {
    className?: ClassValue;
    lastSynchronization: UniverseKBFeedingLastSynchronization;
}

export function KBFeedingLastSynchronization({
    className,
    lastSynchronization,
}: KBFeedingLastSynchronizationProps) {
    const classNames = useClassNames(CLASSNAME);

    const propertiesData = useMemo<PropertyInformation[]>(() => getProperyInformation(lastSynchronization, classNames), [lastSynchronization, classNames]);

    return (
        <div className={classNames('&', className)}>
            {propertiesData.map(property => {
                return <KBFeedingPropertyValue key={property.key} propertyValue={property} />;
            })}
        </div>
    );
};

function getProperyInformation(lastSynchronization: UniverseKBFeedingLastSynchronization, classNames: ClassNamesFn) {
    const ret: PropertyInformation[] = [
        {
            key: 'result',
            label: messages.result,
            content: buildUniverseKBFeedingDataLastResult(lastSynchronization, classNames),
        },
        {
            key: 'rangeDates',
            label: messages.rangeDatesTitle,
            content: buildRangeDatesContent(lastSynchronization.rangeDates),
        },
    ];

    return ret;
}

function buildUniverseKBFeedingDataLastResult(lastSynchronization: UniverseKBFeedingLastSynchronization, classNames: ClassNamesFn) {
    const ret = (
        <ResultTag
            className={classNames('&-result')}
            hasFailed={lastSynchronization.synchronizationStatus === UniverseKBLastFeedingStatus.Failed}
        />
    );

    return ret;
}
