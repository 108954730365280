import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { EditorId } from '../features/editors/editor';
import { EditorState } from './editor-state';
import { useGetMe } from '../../contexts/user-context';
import { ArgUserId } from '../../components/basic';
import { EntityId } from '../model/entity';
import { computeEntityStateURL } from '../../utils/rt-states/folders/entity-state';

export function editorStateFactory(url: string, userId: ArgUserId, entityId: EntityId, editorId: EditorId) {
    return new EditorState(url, entityId, editorId, userId);
}

export function computeEditorStateURL(userId: ArgUserId, entityId: EntityId, editorId: EditorId) {
    return `${computeEntityStateURL(entityId)}/users/${userId}/editors/${editorId}`;
}

export function useEditorState(entityId: EntityId, editorId: EditorId): EditorState;
export function useEditorState(entityId: EntityId | undefined, editorId: EditorId): EditorState | undefined;
export function useEditorState(entityId: EntityId | undefined, editorId: EditorId | undefined): EditorState | undefined;
export function useEditorState(entityId: EntityId | undefined, editorId: EditorId | undefined): EditorState | undefined {
    const { me: user } = useGetMe();

    const state = useBasicState<EditorState>(
        (url: string) => {
            return editorStateFactory(url, user.id, entityId!, editorId!);
        },
        (entityId && editorId) ? computeEditorStateURL(user.id, entityId, editorId) : undefined,
    );

    return state;
}
