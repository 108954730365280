import { cloneDeep, set } from 'lodash';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ArgCombo, useClassNames } from 'src/components/basic';
import { DropdownTooltipField } from '../../../../common/dropdown-tooltip-field/dropdown-tooltip-field';
import { useOntologyContext, useValuationRuleStateContext } from 'src/settings/universes/common/providers/policy-rules-provider';
import { ValuationEffectFilter } from 'src/settings/models/valuation-policy';
import { OntologyMetaProperty } from 'src/settings/universes/ontology/types';
import './valuation-rule-effect-block';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';

const messages = defineMessages({
    select: {
        id: 'settings.valuation-policy.metaproperty-dropdowns.select',
        defaultMessage: 'Select',
    },
    metapropertyOf: {
        id: 'settings.valuation-policy.metaproperty-dropdowns.metaproperty-of',
        defaultMessage: 'of',
    },
});

interface MetapropertyDropdownsProps {
    effectValue: ValuationEffectFilter;
    index: number;
    targetType: 'object' | 'property' | undefined;
}

export function MetapropertyDropdowns(props: MetapropertyDropdownsProps) {
    const { effectValue, index } = props;

    const intl = useIntl();
    const classNames = useClassNames('settings-valuation-rule-effect-block');
    const { ontologySchema } = useOntologyContext();
    const { setRule, editable } = useValuationRuleStateContext();
    const metapropertiesList = ontologySchema.metaProperties || [];

    const metapropertySelected = metapropertiesList.find(
        (metaproperty) => metaproperty.name === effectValue.setMetadata?.name,
    );

    return (
        <>
            {editable ? (
                <ArgCombo<OntologyMetaProperty>
                    className={classNames('&-metaproperty-selection')}
                    placeholder={intl.formatMessage(messages.select)}
                    items={metapropertiesList}
                    getItemKey={(metaProperty) => metaProperty.name}
                    getItemLabel={(metaProperty) => metaProperty.displayName}
                    value={metapropertySelected}
                    popoverClassName='arg-input-popover-no-max-width'
                    cardinality='one'
                    size='small'
                    onChange={(newMetaProperty: OntologyMetaProperty) => {
                        setRule((currentRule) => {
                            if (newMetaProperty.name === metapropertySelected?.name) {
                                return currentRule;
                            }

                            return set(
                                cloneDeep(currentRule),
                                `Effects[${index}].property.setMetadata.name`,
                                newMetaProperty.name,
                            );
                        });
                    }}
                    renderInput={() => (
                        <DropdownTooltipField
                            className={classNames('&-metaproperty-selection')}
                            value={metapropertySelected?.displayName}
                        />
                    )}
                    enableFilter={true}
                />
            ) : (
                <>
                    <ReadOnlyFilterInput
                        className={classNames('&-property-operator')}
                        value={metapropertySelected?.displayName}
                    />
                </>
            )}
            {metapropertySelected && (
                <div className={classNames('&-sentence-text')}>
                    <FormattedMessage {...messages.metapropertyOf} />
                </div>
            )}
        </>
    );
}
