import { useContext, useMemo } from 'react';

import { useBasicEnvironmentContext } from '../../framework/environment-contexts/basic-environment-context';
import { StatesRegistryContext } from '../../utils/rt-states/states-registry';
import { PreparationEnvironmentContext } from '../environment-contexts/preparation-environment-context';


export function usePreparationEnvironmentContext(): PreparationEnvironmentContext {
    const basicEnvironmentContext = useBasicEnvironmentContext();

    const statesRegistry = useContext(StatesRegistryContext)!;

    const result = useMemo<PreparationEnvironmentContext>(() => {
        const result: PreparationEnvironmentContext = {
            ...basicEnvironmentContext,
            statesRegistry,
        };

        return result;
    }, [basicEnvironmentContext, statesRegistry]);

    return result;
}
