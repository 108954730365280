import { useEffect, useState } from 'react';
import { pick } from 'lodash';
import Debug from 'debug';

import { ArgTableColumn3 } from '../arg-table3/arg-table3';
import { ArgTableDataProviderColumn, DataFilter, DataProvider, DataSorter } from './data-provider';
import { useMemoDeepEquals } from '../arg-hooks/use-memo-deep-equals';

const debug = Debug('basic:components:UseDataProviderForTable');

export function useDataProviderForTable<T>(
    dataProvider: DataProvider<T> | undefined,
    firstIndex: number,
    lastIndex: number,
    columns?: ArgTableColumn3<T>[],
    dataFilter?: DataFilter,
    dataSorter?: DataSorter,
    searchValue?: string,
): number {
    const [stateId, setStateId] = useState<number>(0);

    const dataProviderColumns = useMemoDeepEquals<ArgTableDataProviderColumn<T>[] | undefined>(() => {
        const ret = columns?.map((column) => {
            return pick(column, ['key', 'sorter']);
        });

        return ret;
    }, [columns]);

    useEffect(() => {
        if (!dataProvider) {
            setStateId((prev) => {
                return prev + 1;
            });

            return;
        }

        const fct = () => {
            debug('Update state Id');
            setStateId((prev) => {
                return prev + 1;
            });
        };

        dataProvider.on('Loaded', fct);
        dataProvider.on('Cleared', fct);
        dataProvider.on('Refresh', fct);

        fct();

        return () => {
            dataProvider.off('Loaded', fct);
            dataProvider.off('Cleared', fct);
            dataProvider.off('Refresh', fct);
        };
    }, [dataProvider]);

    useEffect(() => {
        if (!dataProvider) {
            return;
        }

        dataProvider.setFilter(dataFilter, dataSorter, dataProviderColumns);
        setStateId((prev) => {
            return prev + 1;
        });
    }, [dataSorter, dataFilter, dataProviderColumns, dataProvider]);

    useEffect(() => {
        if (!dataProvider) {
            return;
        }
        dataProvider.setSearchTerm(searchValue);
        setStateId((prev) => {
            return prev + 1;
        });
    }, [searchValue, dataProvider]);

    useEffect(() => {
        if (!dataProvider || firstIndex > lastIndex) {
            // Invalid display, don't call setViewPort (keep data in data model)
            return;
        }
        dataProvider.setViewPort(firstIndex, lastIndex);
    }, [firstIndex, lastIndex, dataProvider]);

    return stateId;
}
