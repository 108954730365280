import { ExplorationId } from '../model/exploration';
import { EXPLORATION_EVENTS } from './events';
import { EntityState } from '../../utils/rt-states/folders/entity-state';
import { getDataExplorationEntitiesRtApi } from './rt-apis';

export class ExplorationState extends EntityState {
    constructor(url: string, explorationId: ExplorationId) {
        super(getDataExplorationEntitiesRtApi(), url, explorationId, EXPLORATION_EVENTS);
    }
}
