import { useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { first, partition } from 'lodash';
import * as UUID from 'uuid';

import { ArgButton, ArgCollapse, ArgTabKey, ClassValue, SelectionProvider, useClassNames } from 'src/components/basic';
import {
    FormButton,
    FormComposite,
    FormContainer,
    FormElement,
    FormElementId,
    FormIFrame,
    FormImage,
    FormProperty,
    FormSection,
    FormTab,
    FormTabs,
    FormUUID,
} from '../../../../../components/common/forms/model';
import { isFormProperty } from '../../utils';
import { walk } from 'src/components/common/forms/utils';
import { getPropertyIconClass } from '../../../../../exploration/utils/universe.utils';
import { FormActionsEngine } from '../../actions/form-actions-engine';
import { useFormRepository } from '../../use-form-repository';
import { createFormAddElements } from '../../actions/add-elements';
import { createFormRemoveElements } from '../../actions/remove-elements';
import { OntologyObjectType, OntologyProperty } from 'src/settings/universes/ontology/types';
import { FormDraggablePanelItem } from './form-draggable-panel-item';


import './form-items-panel.less';

const messages = defineMessages({
    title: {
        id: 'exploration.forms.form-items-panel.Title',
        defaultMessage: 'Form customization',
    },
    addAll: {
        id: 'exploration.forms.form-items-panel.AddAll',
        defaultMessage: 'Add all',
    },
    removeAll: {
        id: 'exploration.forms.form-items-panel.RemoveAll',
        defaultMessage: 'Remove all',
    },
    noMoreItems: {
        id: 'exploration.forms.form-items-panel.NoMoreItems',
        defaultMessage: 'All properties have been added',
    },
    primaryProperties: {
        id: 'exploration.forms.form-items-panel.PrimaryProperties',
        defaultMessage: 'Main properties',
    },
    secondaryProperties: {
        id: 'exploration.forms.form-items-panel.SecondaryProperties',
        defaultMessage: 'Secondary properties',
    },
    components: {
        id: 'exploration.forms.form-items-panel.Components',
        defaultMessage: 'Components',
    },
    section: {
        id: 'exploration.forms.form-items-panel.Section',
        defaultMessage: 'Section',
    },
    image: {
        id: 'exploration.forms.form-items-panel.Image',
        defaultMessage: 'Image',
    },
    iframe: {
        id: 'exploration.forms.form-items-panel.IFrame',
        defaultMessage: 'Internal frame',
    },
    button: {
        id: 'exploration.forms.form-items-panel.Button',
        defaultMessage: 'Button',
    },
    composite: {
        id: 'exploration.forms.form-items-panel.Composite',
        defaultMessage: 'Composite field',
    },
    required: {
        id: 'exploration.forms.form-items-panel.Required',
        defaultMessage: '(Required)',
    },
    uuid: {
        id: 'exploration.forms.form-items-panel.UUID',
        defaultMessage: 'GUID (Identifier)',
    },
});

interface FormElementType {
    type: string;
    label: MessageDescriptor;
    icon?: string;
    create: () => FormElement;
}

const COMPONENTS: FormElementType[] = [{
    type: 'section',
    icon: 'icon-list-box-outline',
    label: messages.section,
    create: (): FormElement => {
        const ret: FormSection = {
            type: 'section',
            id: UUID.v4(),
            children: [],
        };

        return ret;
    },
}, {
    type: 'composite',
    icon: 'icon-composite',
    label: messages.composite,
    create: (): FormElement => {
        const ret: FormComposite = {
            type: 'composite',
            id: UUID.v4(),
            children: [],
        };

        return ret;
    },
}, {
    type: 'image',
    icon: 'icon-photo1',
    label: messages.image,
    create: (): FormElement => {
        const ret: FormImage = {
            type: 'image',
            id: UUID.v4(),
        };

        return ret;
    },
}, {
    type: 'iframe',
    label: messages.iframe,
    icon: 'icon-window',
    create: (): FormElement => {
        const ret: FormIFrame = {
            type: 'iframe',
            name: 'Frame',
            id: UUID.v4(),
            width: '100%',
            height: '50px',
        };

        return ret;
    },
}, {
    type: 'button',
    icon: 'icon-button-cursor',
    label: messages.button,
    create: (): FormElement => {
        const ret: FormButton = {
            type: 'button',
            id: UUID.v4(),
        };

        return ret;
    },
}, {
    type: 'uuid',
    icon: 'icon-tag',
    label: messages.uuid,
    create: (): FormElement => {
        const ret: FormUUID = {
            type: 'uuid',
            id: UUID.v4(),
        };

        return ret;
    },
}];

interface FormItemsPanelProps {
    className?: ClassValue;

    ontologyVertexType: OntologyObjectType;

    formActionsEngine: FormActionsEngine;
    selectionProvider?: SelectionProvider<FormElement>;

    activeTabKeys: Record<FormElementId, ArgTabKey>;
}

export function FormItemsPanel(props: FormItemsPanelProps) {
    const {
        className,
        ontologyVertexType,
        formActionsEngine,
        selectionProvider,
        activeTabKeys,
    } = props;

    const classNames = useClassNames('exploration-form-items-panel');
    const formDocument = useFormRepository(formActionsEngine.repository);

    const {
        primaryList,
        primaryUsed,
        primaryUnused,
        secondaryUnused,
        secondaryUsed,
        secondaryList,
    } = useMemo<{
        primaryUnused: OntologyProperty[];
        primaryUsed: OntologyProperty[];
        primaryList: OntologyProperty[];
        secondaryUnused: OntologyProperty[];
        secondaryUsed: OntologyProperty[];
        secondaryList: OntologyProperty[];
    }>(() => {
        const listProperties: string[] = [];
        walk(formDocument, (node) => {
            if (isFormProperty(node)) {
                listProperties.push(node.propertyName);
            }
        });

        const [primaryList, secondaryList] = partition(ontologyVertexType.properties, (property) => property.isHeadProperty);
        const [primaryUsed, primaryUnused] = partition(primaryList, (property) => listProperties.includes(property.name));
        const [secondaryUsed, secondaryUnused] = partition(secondaryList, (property) => listProperties.includes(property.name));

        const lists = {
            primaryList,
            primaryUsed,
            primaryUnused,
            secondaryList,
            secondaryUsed,
            secondaryUnused,
        };

        return lists;
    }, [formDocument, ontologyVertexType.properties]);

    const resetForm = useCallback(() => {
        formActionsEngine.repository.setDocument({ ...formDocument }, false);
    }, [formActionsEngine.repository, formDocument]);

    const handleShowAddAll = useCallback((target: 'primary' | 'secondary') => {
        const list = (target === 'primary') ? primaryUnused : secondaryUnused;
        const newFormElements = list.map((p) => {
            const ret: FormElement = {
                type: 'property',
                id: p.name,
                propertyName: p.name,
            };

            return ret;
        });

        let container: FormContainer = formDocument;
        const tabs = formDocument.children?.find((c) => c.type === 'tabs') as FormTabs | undefined;
        if (tabs) {
            const tabElements = tabs.children?.filter((c) => c.type === 'tab') as FormTab[];
            const selectedTab = tabElements?.find((tab) => tab.id === activeTabKeys[tabs.id]) ?? first(tabElements);
            container = selectedTab ?? container;
        }

        const action = createFormAddElements(container, newFormElements, undefined, selectionProvider);

        formActionsEngine.do(action).catch((error) => {
            console.error(error);
        });
    }, [primaryUnused, secondaryUnused, formDocument, selectionProvider, formActionsEngine, activeTabKeys]);

    const handleRemoveAll = useCallback((target: 'primary' | 'secondary') => {
        const list = (target === 'primary') ? primaryList : secondaryList;
        const ret: FormProperty[] = [];
        walk(formDocument, (element) => {
            if (!isFormProperty(element) || list.findIndex((p) => p.name === element.propertyName) < 0) {
                return;
            }
            ret.push(element);
        });

        if (!ret.length) {
            return;
        }

        const action = createFormRemoveElements(ret, selectionProvider);

        formActionsEngine.do(action).catch((error) => {
            console.error(error);
        });
    }, [primaryList, secondaryList, formDocument, selectionProvider, formActionsEngine]);

    function renderList(type: 'primary' | 'secondary', title: MessageDescriptor, usedList: OntologyProperty[], unusedList: OntologyProperty[]) {
        return (
            <ArgCollapse title={title} className={classNames('&-primary')}>
                <div className={classNames('&-primary-button-group')}>
                    <ArgButton
                        type='ghost'
                        className={classNames('&-primary-button')}
                        onClick={() => handleShowAddAll(type)}
                        disabled={!unusedList.length}
                        label={messages.addAll}
                    />
                    <span className={classNames('&-primary-button-separator')}>|</span>
                    <ArgButton
                        type='ghost'
                        className={classNames('&-primary-button')}
                        onClick={() => handleRemoveAll(type)}
                        disabled={!usedList.length}
                        label={messages.removeAll}
                    />
                </div>
                <ul className={classNames('&-primary-list')}>
                    {!unusedList.length && (
                        <li className={classNames('&-primary-list-no-item')}>
                            <FormattedMessage {...messages.noMoreItems} />
                        </li>
                    )}
                    {unusedList.map((unusedProperty) => {
                        const typeIcon = getPropertyIconClass(unusedProperty.baseType);
                        const formElement: FormElement = {
                            type: 'property',
                            id: unusedProperty.name,
                            propertyName: unusedProperty.name,
                        };

                        return (
                            <FormDraggablePanelItem
                                key={unusedProperty.name}
                                icon={typeIcon}
                                label={unusedProperty.displayName}
                                formElement={formElement}
                                resetForm={resetForm}
                            />
                        );
                    })}
                </ul>
            </ArgCollapse>
        );
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-title')}>
                <FormattedMessage {...messages.title} />
            </div>

            <div className={classNames('&-body')}>
                {renderList('primary', messages.primaryProperties, primaryUsed, primaryUnused)}
                {renderList('secondary', messages.secondaryProperties, secondaryUsed, secondaryUnused)}
                <ArgCollapse title={messages.components} className={classNames('&-components')}>
                    <ul className={classNames('&-components-list')}>
                        {
                            COMPONENTS.map((component) => {
                                const typeIcon = component.icon;
                                const formElement = component.create();

                                return (
                                    <FormDraggablePanelItem
                                        key={component.type}
                                        icon={typeIcon || 'icon-help-circle-outline'}
                                        label={component.label}
                                        formElement={formElement}
                                        resetForm={resetForm}
                                    />
                                );
                            })
                        }
                    </ul>
                </ArgCollapse>
            </div>
        </div>
    );
}
