import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import {
    ArgIcon,
    ArgModalContainerContext,
    ArgSwitch,
    ArgTooltip2,
    ClassValue,
    dayjs,
    useClassNames,
} from 'src/components/basic';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { DetailedWebHook } from '../models/detailed-webhooks';
import { WebhookActionsMenu } from './webhook-actions-menu';
import { DateByUser } from 'src/components/common/date-by-user';
import { WebhooksActivationModal } from './components/webhooks-activation-modal/webhooks-activation-modal';
import { ArgonosModuleId } from 'src/components/application/modules';

import './webhook-information-panel.less';

const messages = defineMessages({
    headerTitle: {
        id: 'settings.webhooks.information-panel.title',
        defaultMessage: 'Information',
    },
    active: {
        id: 'settings.webhooks.information-panel.active',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'settings.webhooks.information-panel.inactive',
        defaultMessage: 'Inactive',
    },
    noWebhookSelected: {

        id: 'settings.webhooks.information-panel.noWebhookSelected',
        defaultMessage: 'No webhook selected',
    },
    activeLabel: {
        id: 'settings.webhooks.information-panel.activeLabel',
        defaultMessage: 'Active',
    },
    identifier: {
        id: 'settings.webhooks.information-panel.identifier',
        defaultMessage: 'Identifier',
    },
    url: {
        id: 'settings.webhooks.information-panel.url',
        defaultMessage: 'URL',
    },
    connectTimeout: {
        id: 'settings.webhooks.information-panel.connectTimeout',
        defaultMessage: 'Connect timeout',
    },
    readTimeout: {
        id: 'settings.webhooks.information-panel.readTimeout',
        defaultMessage: 'Read timeout',
    },
    duration: {
        id: 'settings.webhooks.information-panel.duration',
        defaultMessage: '{seconds} {seconds, plural, =0 {second} =1 {second} other {seconds}}',
    },
    associatedEvents: {
        id: 'settings.webhooks.information-panel.associatedEvents',
        defaultMessage: 'Associated events',
    },
    errorMessage: {
        id: 'settings.webhooks.information-panel.errorMessage',
        defaultMessage: 'Webhook "{name}" could not be reached.',
    },
    errorTitle: {
        id: 'settings.webhooks.information-panel.errorTitle',
        defaultMessage: 'Connection error',
    },
    creation: {
        id: 'settings.webhooks.information-panel.creation',
        defaultMessage: 'Creation',
    },
    modification: {
        id: 'settings.webhooks.information-panel.modification',
        defaultMessage: 'Modification',
    },
    activateError: {
        id: 'settings.webhooks.information-panel.activateError',
        defaultMessage: 'An error occurred while activating the webhook',
    },
    deactivateError: {
        id: 'settings.webhooks.information-panel.deactivateError',
        defaultMessage: 'An error occurred while deactivating the webhook',
    },
    description: {
        id: 'settings.webhooks.information-panel.description',
        defaultMessage: 'Description',
    },
});

interface PropertyInformation {
    label: MessageDescriptor;
    content: ReactNode;
    key: string;
}

export interface WebhookInformationPanelProps {
    selectedWebhooks: DetailedWebHook[];
    className?: ClassValue;
    onActionSuccess: () => void;
    argonosModule: ArgonosModuleId;
}

export function WebhookInformationPanel(props: WebhookInformationPanelProps) {
    const { selectedWebhooks, className, onActionSuccess, argonosModule } = props;

    const classNames = useClassNames('settings-webhook-information-panel');
    const intl = useIntl();
    const [selectedWebhookIndex, setSelectedWebhookIndex] = useState(0);

    const modalContainer = useContext(ArgModalContainerContext);

    const webhook: DetailedWebHook | undefined = selectedWebhooks[selectedWebhookIndex];

    const handleToggleWebhook = useCallback((targetStatus: boolean) => {
        modalContainer.open('settings-webhook-activation', <WebhooksActivationModal
            webhooks={[webhook]}
            targetStatus={targetStatus}
            onClose={() => modalContainer.close('settings-webhook-activation')}
            onSuccess={onActionSuccess}
            argonosModule={argonosModule}
        />);
    }, [modalContainer, onActionSuccess, webhook, argonosModule]);


    const renderContentWithTooltip = useCallback((content: ReactNode) => {
        return (
            <ArgTooltip2 title={content}>
                <div>
                    {content}
                </div>
            </ArgTooltip2>
        );
    }, []);

    const renderTimeout = useCallback((timeout: number | undefined) => {
        if (timeout === undefined) {
            return '-';
        }

        const seconds = dayjs.duration(timeout, 'milliseconds').asSeconds();

        return renderContentWithTooltip(<FormattedMessage {...messages.duration} values={{ seconds }} />);
    }, [renderContentWithTooltip]);

    useEffect(() => {
        if (selectedWebhookIndex === selectedWebhooks.length && selectedWebhookIndex !== 0) {
            setSelectedWebhookIndex((selectedWebhookIndex) => selectedWebhookIndex - 1);
        }
    }, [selectedWebhookIndex, selectedWebhooks]);
    const propertiesData = useMemo<PropertyInformation[]>(() => {
        if (!webhook) {
            return [];
        }

        return [
            {
                key: 'id',
                label: messages.identifier,
                content: renderContentWithTooltip(webhook.id),
            },
            {
                key: 'url',
                label: messages.url,
                content: renderContentWithTooltip(webhook.target.url),
            },
            {
                key: 'connectTimeout',
                label: messages.connectTimeout,
                content: renderTimeout(webhook.target.connectTimeout),
            },
            {
                key: 'readTimeout',
                label: messages.readTimeout,
                content: renderTimeout(webhook.target.readTimeout),
            },
            {
                key: 'creation',
                label: messages.creation,
                content: webhook.createdDate ? <DateByUser
                    date={webhook.createdDate}
                    user={webhook.createdBy}
                    relative={true}
                /> : '-',
            },
            {
                key: 'update',
                label: messages.modification,
                content: webhook.lastUpdatedDate ? <DateByUser
                    date={webhook.lastUpdatedDate}
                    user={webhook.lastUpdatedBy}
                    relative={true}
                /> : '-',
            },
            {
                key: 'description',
                label: messages.description,
                content: renderContentWithTooltip(webhook.description?.length ? webhook.description : '-'),
            },
        ];
    }, [renderContentWithTooltip, renderTimeout, webhook]);

    const [errorTitle, errorMessage] = useMemo(() => {
        const notifyError = webhook?.emissionError;
        if (!notifyError) {
            return [null, null];
        }

        return [intl.formatMessage(messages.errorTitle), intl.formatMessage(messages.errorMessage, { name: webhook.name })];
    }, [intl, webhook?.name, webhook?.emissionError]);

    if (!webhook) {
        return (
            <div className={classNames('&', className)}>
                <div className={classNames('&-header')}>
                    <h3 className={classNames('&-header-title')}>
                        <FormattedMessage {...messages.headerTitle} />
                    </h3>
                </div>
                <div className={classNames('&-no-webhook-pane')}>
                    <EmptyPane
                        key='loading'
                        message={messages.noWebhookSelected}
                        backgroundAnimation='wave'
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <h3 className={classNames('&-header-title')}>
                    <FormattedMessage {...messages.headerTitle} />
                </h3>
                {selectedWebhooks.length > 1 && (
                    <div className={classNames('&-webhooks-toggle')}>
                        <div
                            onClick={() => {
                                setSelectedWebhookIndex((currentSelectedWebhookIndex) => {
                                    return Math.max(currentSelectedWebhookIndex - 1, 0);
                                });
                            }}
                            className={classNames(
                                selectedWebhookIndex === 0
                                    ? '&-webhooks-toggle-disabled'
                                    : '&-webhooks-toggle-enabled',
                            )}
                        >
                            <ArgIcon name='icon-previous' />
                        </div>
                        {selectedWebhookIndex + 1}
                        {'/'}
                        {selectedWebhooks.length}
                        <div
                            onClick={() => {
                                setSelectedWebhookIndex((currentSelectedWebhookIndex) => {
                                    return Math.min(currentSelectedWebhookIndex + 1, selectedWebhooks.length - 1);
                                });
                            }}
                            className={classNames(
                                selectedWebhookIndex === selectedWebhooks.length - 1
                                    ? '&-webhooks-toggle-disabled'
                                    : '&-webhooks-toggle-enabled',
                            )}
                        >
                            <ArgIcon name='icon-next' />
                        </div>
                    </div>
                )}
            </div>
            <div className={classNames('&-scrollable-body')}>
                <div className={classNames('&-name-container')}>
                    <div className={classNames('&-name-label')}>
                        <div className={classNames('&-name')}>{webhook.name}</div>
                        <WebhookActionsMenu
                            webhook={webhook}
                            onActionSuccess={onActionSuccess}
                            argonosModule={argonosModule}
                        />
                    </div>
                    <div className={classNames('&-active-switch')}>
                        <span className={classNames('&-active-switch-label')}>
                            <FormattedMessage {...messages.activeLabel} />
                        </span>
                        <ArgSwitch
                            checked={webhook.isEnabled}
                            onChange={handleToggleWebhook}
                        />
                    </div>
                </div>
                {errorTitle && errorMessage && <div className={classNames('&-error-container')}>
                    <div className={classNames('&-error-title')}>
                        {errorTitle}
                    </div>
                    <div className={classNames('&-error-message')}>
                        {errorMessage}
                    </div>
                </div>}
                {propertiesData.map((property) => (
                    <div key={property.key} className={classNames('&-property-row')}>
                        <div className={classNames('&-property-row-title')}>
                            <FormattedMessage {...property.label} />
                        </div>
                        <div className={classNames('&-property-row-content')}>
                            {property.content}
                        </div>
                    </div>
                ))}
                <div className={classNames('&-events-title')}>
                    <FormattedMessage {...messages.associatedEvents} />
                </div>
                {webhook.events.map((event) => (
                    <div key={event.id} className={classNames('&-event-row')}>
                        <div className={classNames('&-event-row-title')}>
                            {event.id}
                        </div>
                        <div className={classNames('&-event-row-content')}>
                            <FormattedMessage {...(event.isEnabled ? messages.active : messages.inactive)} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
