import { RtBasicState, RtStateMessage } from '../../utils/rt-states/rt-basic-state';
import { WebSocketChannel } from '../../components/ws/websocket-connector';
import { RtApi } from '../../utils/rt-states/rt-api';
import { getDataPreparationEntitiesRtApi } from './rt-apis';
import { ReferenceTableEntityId } from '../knowledge-base/reference-tables/utils/reference-tables';
import { REFERENCES_TABLE_EVENT_NAMES } from './events';

export class ReferencesTableState extends RtBasicState {
    constructor(url: string) {
        super(url);
    }

    protected getRtApi(): RtApi {
        return getDataPreparationEntitiesRtApi();
    }

    protected isRtDisabled(): boolean {
        return true;
    }

    protected processMessage = async (channel: WebSocketChannel<RtStateMessage>, type: string, message: RtStateMessage): Promise<boolean | undefined> => {
        if (REFERENCES_TABLE_EVENT_NAMES[message.type]) {
            // Fire event
            return false;
        }

        // No Event
        return true;
    };

    handleDeleted(referenceEntityId: ReferenceTableEntityId):void {
        this.eventChange('ReferencesUpdated', referenceEntityId, 'local');
    }
}
