import { defineMessages } from 'react-intl';

import { ClassValue, ProgressMonitor, useClassNames } from '../../../../../components/basic';
import { EmptyPane } from '../../../../../components/common/panes/empty-pane';
import { ExplorationStyleTemplate } from '../../../../../exploration/model/template';
import { ExplorationSettingsStylesTemplateCard } from './exploration-settings-styles-template-card';

import './exploration-settings-styles-templates.less';

interface ExplorationSettingsStylesTemplatesProps {
    className?: ClassValue;
    stylesTemplates?: ExplorationStyleTemplate[];
    searchedToken?: string;
    onDeleteTemplateConfirm?: (progressMonitor: ProgressMonitor, template: ExplorationStyleTemplate) => Promise<void>;
    onRenameTemplateConfirm?: (progressMonitor: ProgressMonitor, template: ExplorationStyleTemplate, newName: string) => Promise<void>;
    onCheckAction?: (value: boolean, template: ExplorationStyleTemplate) => void;
    isTemplateSelected?: (template: ExplorationStyleTemplate) => boolean;
}

const messages = defineMessages({
    emptyMessage: {
        id: 'settings.library-configuration.exploration-settings-styles-templates.emptyMessage',
        defaultMessage: 'No templates',
    },
});


const CLASSNAME = 'exploration-settings-styles-templates';

export function ExplorationSettingsStylesTemplates({
    className,
    stylesTemplates,
    searchedToken,
    onDeleteTemplateConfirm,
    onRenameTemplateConfirm,
    isTemplateSelected,
    onCheckAction,
}: ExplorationSettingsStylesTemplatesProps) {
    const classNames = useClassNames(CLASSNAME);

    const hasAnyTemplate = !!stylesTemplates?.length;

    return <div className={classNames('&', className)}>
        {hasAnyTemplate &&
            <div className={classNames('&-body')}>
                {stylesTemplates?.map(stylesTemplate => (
                    <ExplorationSettingsStylesTemplateCard
                            key={stylesTemplate.id}
                            explorationStyleTemplate={stylesTemplate}
                            searchedToken={searchedToken}
                            onDeleteTemplateConfirm={onDeleteTemplateConfirm}
                            onRenameTemplateConfirm={onRenameTemplateConfirm}
                            onCheckAction={onCheckAction}
                            isTemplateSelected={isTemplateSelected}
                    />
                ))}
            </div>}
        {!hasAnyTemplate &&
            <div className={classNames('&-body-empty')}>
                <EmptyPane message={messages.emptyMessage}
                           size='medium'
                />
            </div>}
    </div>;
}
