import { ReactNode, useMemo } from 'react';

import { processSearch } from '../utils/highlight';

export interface HighlightedTextProps {
    text: string;
}

export function HighlightedText(props: HighlightedTextProps) {
    const {
        text,
    } = props;

    return <span className='search-highlight-other-result'>
        {text}
    </span>;
}

export interface HighlightedSearchProps {
    text: string;
    token?: string;
}

export function HighlightedSearchResult(props: HighlightedSearchProps) {
    const {
        text,
        token = 'em',
    } = props;

    const highlightedText = useMemo<ReactNode>(() => {
        const processedSearch = processSearch(text, token);

        return processedSearch;
    }, [text, token]);

    return <span>{highlightedText}</span>;
}
