import { ArgButton, ArgIcon, ArgInputText, useClassNames } from 'src/components/basic';
import { useReorder } from 'src/hooks/use-reorder';

import './fixed-values-option.less';

interface Option {
    value: string;
    id: number;
}

interface OptionComponentProps {
    option: Option;
    optionsList: Option[];
    index: number;
    moveOption: (dragIndex: number, hoverIndex: number) => void;
    setOptionsList: React.Dispatch<React.SetStateAction<Option[]>>;
}

export const ItemTypes = {
    OPTION: 'option',
};

export function OptionComponent(props: OptionComponentProps) {
    const {
        index,
        option,
        optionsList,
        setOptionsList,
        moveOption,
    } = props;

    const classNames = useClassNames('fixed-values-option');

    const deleteOption = () => {
        setOptionsList(() => {
            return optionsList.filter((x) => x.id != option.id);
        });
    };

    const { handlerId, isDragging, drag, ref } = useReorder(
        ItemTypes.OPTION,
        moveOption,
        index,
        option,
    );

    const handleChange = (value: string | null) => {
        const newOption = { value: value || '', id: option.id };

        setOptionsList((prevState) => (
            prevState.map((currentOption) => {
                return currentOption.id !== option.id ? currentOption : newOption;
            })
        ));
    };

    return (
        <li
            data-handler-id={handlerId}
            ref={ref}
            style={{ opacity: isDragging ? 0 : 1 }}
            className={classNames('&')}
        >
            <div ref={drag}>
                <ArgIcon name='icon-6dots' className={classNames('&-drag')} />
            </div>
            <ArgInputText value={option.value} clearable={false} onChange={handleChange} />
            <ArgButton
                onClick={deleteOption}
                type='ghost'
                icon='icon-trash'
                className={classNames('&-trash-button')}
            />
        </li>
    );
}
