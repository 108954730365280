import { cloneDeep, unset } from 'lodash';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { ReactNode } from 'react';
import dayjs from 'dayjs';

import { ContextualVariable, ContextualVariableType } from 'src/exploration/model/contextual-variable';
import { Scope, ScopeProcessed } from '../../../../models/policy';
import { UserProfileField } from '../../../../../model/user-metadata';
import { OntologyPropertyBaseType } from '../../../ontology/types';

const messages = defineMessages({
    user: {
        id: 'settings.application-condition-expression-of-logic.utils.User',
        defaultMessage: 'User',
    },
    date: {
        id: 'settings.application-condition-expression-of-logic.utils.date',
        defaultMessage: 'Date',
    },
    time: {
        id: 'settings.application-condition-expression-of-logic.utils.time',
        defaultMessage: 'Time',
    },
    dateTime: {
        id: 'settings.application-condition-expression-of-logic.utils.dateTime',
        defaultMessage: 'Date and Time',
    },
    userName: {
        id: 'settings.application-condition-expression-of-logic.utils.userName',
        defaultMessage: 'User: username',
    },
    fullName: {
        id: 'settings.application-condition-expression-of-logic.utils.fullName',
        defaultMessage: 'User: full name',
    },
    firstName: {
        id: 'settings.application-condition-expression-of-logic.utils.firstName',
        defaultMessage: 'User: first name',
    },
    lastName: {
        id: 'settings.application-condition-expression-of-logic.utils.lastName',
        defaultMessage: 'User: last name',
    },
    isActive: {
        id: 'settings.application-condition-expression-of-logic.utils.isActive',
        defaultMessage: 'User: is active',
    },
    isDeleted: {
        id: 'settings.application-condition-expression-of-logic.utils.isDeleted',
        defaultMessage: 'User: is deleted',
    },
    groupName: {
        id: 'settings.application-condition-expression-of-logic.utils.groupName',
        defaultMessage: 'Group: name',
    },
    isEqualTo: {
        id: 'settings.application-condition-expression-of-logic.utils.isEqualTo',
        defaultMessage: 'is equal to',
    },
    isDifferentFrom: {
        id: 'settings.application-condition-expression-of-logic.utils.isDifferentFrom',
        defaultMessage: 'is different from',
    },
    isGreaterThan: {
        id: 'settings.application-condition-expression-of-logic.utils.isGreaterThan',
        defaultMessage: 'is greater than',
    },
    isSmallerThan: {
        id: 'settings.application-condition-expression-of-logic.utils.isSmallerThan',
        defaultMessage: 'is smaller than',
    },
    isGreaterOrEqualTo: {
        id: 'settings.application-condition-expression-of-logic.utils.isGreaterOrEqualTo',
        defaultMessage: 'is greater than or equal to',
    },
    isSmallerOrEqualTo: {
        id: 'settings.application-condition-expression-of-logic.utils.isSmallerOrEqualTo',
        defaultMessage: 'is smaller than or equal to',
    },
    always: {
        id: 'settings.application-condition-expression-of-logic.utils.always',
        defaultMessage: 'Always',
    },
    currentDate: {
        id: 'settings.application-condition-expression-of-logic.utils.currentDate',
        defaultMessage: 'Current date',
    },
    currentYearlyDate: {
        id: 'settings.application-condition-expression-of-logic.utils.currentYearlyDate',
        defaultMessage: 'Current year date',
    },
    currentDailyDate: {
        id: 'settings.application-condition-expression-of-logic.utils.currentDailyDate',
        defaultMessage: 'Current daily date',
    },
    userId: {
        id: 'settings.application-condition-expression-of-logic.utils.userId',
        defaultMessage: 'Identifier',
    },
});

enum ScopeType {
    CONTEXT_VARIABLE = 'variable',
    SYSTEM_VARIABLE = 'variable',
    USER_SYSTEM_FIELD = 'user',
    USER_PROFILE_FIELD = 'user',
    GROUP_SYSTEM_FIELD = 'group',
}

export interface TargetOption {
    key: string | null;
    text: ReactNode | MessageDescriptor;
    id: string;
    type: ScopeType | 'always';
    variableType?: ContextualVariableType;
}

const SYSTEM_VARIABLES = [
    {
        key: 'system.CurrentDate',
        text: messages.currentDate,
        variableType: ContextualVariableType.string,
    },
    {
        key: 'system.CurrentYearlyDate',
        text: messages.currentYearlyDate,
        variableType: ContextualVariableType.int,
    },
    {
        key: 'system.CurrentDailyDate',
        text: messages.currentDailyDate,
        variableType: ContextualVariableType.hour,
    },
];

const USER_SYSTEM_FIELDS = [
    { key: 'UserName', text: messages.userName, variableType: ContextualVariableType.string },
    { key: 'FullName', text: messages.fullName, variableType: ContextualVariableType.string },
    { key: 'FirstName', text: messages.firstName, variableType: ContextualVariableType.string },
    { key: 'LastName', text: messages.lastName, variableType: ContextualVariableType.string },
    { key: 'IsActive', text: messages.isActive, variableType: ContextualVariableType.bool },
    { key: 'Id', text: messages.userId, variableType: ContextualVariableType.string },
];

const GROUP_SYSTEM_FIELDS = [{
    key: 'Name',
    text: messages.groupName,
    variableType: ContextualVariableType.string,
}];


export function useScopeTargetDropdown(
    contextualVariables: ContextualVariable[],
    userProfileFields: UserProfileField[],
): TargetOption[] {
    const intl = useIntl();

    const dropdownOptions: TargetOption[] = [];

    dropdownOptions.push({
        id: 'always',
        key: null,
        text: messages.always,
        type: 'always',
        variableType: undefined,
    });

    contextualVariables.forEach((variable) => {
        dropdownOptions.push({
            id: `contextVariables::${variable.id}::${variable.displayName}`,
            key: `contextual.${variable.id}`,
            text: `Variable: ${variable.displayName}`,
            type: ScopeType.CONTEXT_VARIABLE,
            variableType: variable.type,
        });
    });

    SYSTEM_VARIABLES.forEach((variable) => {
        dropdownOptions.push({
            id: `systemVariables::${variable.key}::${variable.text}`,
            key: variable.key,
            text: `Variable: ${intl.formatMessage(variable.text)}`,
            type: ScopeType.SYSTEM_VARIABLE,
            variableType: variable.variableType,
        });
    });

    USER_SYSTEM_FIELDS.forEach((variable) => {
        dropdownOptions.push({
            id: `userSystemFields:${variable.key}::${variable.text}`,
            key: variable.key,
            text: `Variable: ${intl.formatMessage(variable.text)}`,
            type: ScopeType.USER_SYSTEM_FIELD,
            variableType: variable.variableType,
        });
    });

    userProfileFields.forEach((profileField) => {
        dropdownOptions.push({
            id: `userProfileFields::${profileField.id}::${profileField.id}`,
            key: `${profileField.id}`,
            text: `${intl.formatMessage(messages.user)}: ${profileField.displayName}`,
            type: ScopeType.USER_PROFILE_FIELD,
            variableType: profileField.baseType ? convertToVariableType(profileField.baseType) : undefined,
        });
    });

    GROUP_SYSTEM_FIELDS.forEach((variable) => {
        dropdownOptions.push({
            id: `groupSystemFields:${variable.key}::${variable.text}`,
            key: variable.key,
            text: variable.text,
            type: ScopeType.GROUP_SYSTEM_FIELD,
            variableType: variable.variableType,
        });
    });

    return dropdownOptions;
}

export interface ScopeOperator {
    id: string;
    key: string;
    label: MessageDescriptor;
}

export const SCOPE_OPERATORS: ScopeOperator[] = [
    { id: 'isEqualTo', key: '=', label: messages.isEqualTo },
    { id: 'isDifferentThan', key: '!=', label: messages.isDifferentFrom },
    { id: 'isGreaterThan', key: '>', label: messages.isGreaterThan },
    { id: 'isSmallerThan', key: '<', label: messages.isSmallerThan },
    {
        id: 'isGreaterOrEqualTo',
        key: '>=',
        label: messages.isGreaterOrEqualTo,
    },
    {
        id: 'isSmallerOrEqualTo',
        key: '<=',
        label: messages.isSmallerOrEqualTo,
    },
];


export const SCOPE_OPERATORS_TEXT_BOOL: ScopeOperator[] = [
    { id: 'isEqualTo', key: '=', label: messages.isEqualTo },
    { id: 'isDifferentThan', key: '!=', label: messages.isDifferentFrom },
];

export function getScopeValues(scopeData?: Record<string, string | undefined>, editable?: boolean): {
    dataKey: string | undefined;
    dataOperator: ScopeOperator | undefined;
    dataValue: any;
} {
    if (!scopeData) {
        return { dataKey: undefined, dataOperator: undefined, dataValue: undefined };
    }
    const objElements = Object.entries(scopeData);
    if (objElements.length > 1) {
        throw new Error();
    }
    let dataValue;
    let match = objElements[0]?.[1]?.match(/^(=|!=|>|<|>=|<=)"(.*)"$/);
    if (match) {
        dataValue = match?.[2];
    } else if (!match) {
        match = objElements[0]?.[1]?.match(/^(=|!=|>|<|>=|<=)([\d\.]+)$/);
        if (match) {
            dataValue = parseFloat(match?.[2]);
        } else {
            match = objElements[0]?.[1]?.match(/^(=|!=)(true|false)$/i);
            if (match) {
                dataValue = match?.[2]?.toLowerCase() === 'true';
            }
        }
    }

    const dataOperator = SCOPE_OPERATORS.find((o) => o.key === match?.[1]);

    if (objElements[0]?.[0] === 'system.CurrentDailyDate' && !editable) {
        if (dataValue) {
            const d = dayjs().startOf('day');
            const d1 = d.second(dataValue as number);
            dataValue = d1.format('HH:mm:ss');
        }
    }

    return { dataKey: objElements[0]?.[0], dataOperator, dataValue };
}

function isInvalidScope(scope: Scope): boolean {
    if (!scope) return true;
    if (!('and' in scope) && !('or' in scope)) return false;

    if ('and' in scope) {
        if (scope.and.length === 0 || scope.and.every((s) => !s)) {
            return true;
        } else {
            return scope.and.every((t) => isInvalidScope(t));
        }
    }
    if (scope.or) {
        if (scope.or.length === 0 || scope.or.every((s) => !s)) {
            return true;
        } else {
            return scope.or.every((s) => isInvalidScope(s));
        }
    }

    return true;
}

function cleanUpScope(scope: ScopeProcessed | Scope): ScopeProcessed | Scope {
    if ('and' in scope) {
        return {
            and: scope.and.filter((el) => !isInvalidScope(el)).map(cleanUpScope),
        };
    }
    if ('or' in scope) {
        return {
            or: scope.or.filter((el) => !isInvalidScope(el)).map(cleanUpScope),
        };
    }

    return scope;
}

export function deleteScopeRow(
    currentPath: string,
    currentScope: ScopeProcessed,
): ScopeProcessed {
    let newScope = cloneDeep(currentScope);
    unset(newScope, currentPath);
    newScope = { ...cleanUpScope(newScope), id: currentScope.id };
    if ('and' in newScope && newScope.and.length === 1) {
        return { ...newScope.and[0], id: currentScope.id };
    }
    if ('or' in newScope && newScope.or.length === 1) {
        return { ...newScope.or[0], id: currentScope.id };
    }

    return newScope;
}

export function convertToVariableType(baseType: OntologyPropertyBaseType): ContextualVariableType {
    switch (baseType) {
        case 'Date':
        case 'DateTime':
        case 'Geoshape':
        case 'Guid':
        case 'String':
        case 'Text':
        case 'MultiString':
            return ContextualVariableType.string;
        case 'Bool':
            return ContextualVariableType.bool;
        case 'Short':
        case 'Int':
        case 'Long':
            return ContextualVariableType.int;
        case 'Float':
        case 'Double':
            return ContextualVariableType.decimal;
    }
    throw new Error('Not supported');
}
