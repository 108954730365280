import { useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { GroupsView } from 'src/settings/groups/views/groups-view';
import {
    ArgModalContainer,
    ArgTab,
    ArgTabsSubLevel,
    ProgressMonitor,
    useArgNotifications,
    useEffectAsync,
    useProgressMonitor,
} from 'src/components/basic';
import { UsersView } from './users-view';
import { Group, User } from 'src/model/user';
import { PageHeader } from 'src/settings/common-components/page-header';
import { UsersDeletedView } from './users-deleted';
import { UsersAndGroupsStateContext } from '../providers/usersState';
import { Role } from 'src/settings/models/dtoApi';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';
import { PropertiesView } from '../../properties/views/properties-view';
import { UserProfileField } from '../../../model/user-metadata';
import { SettingsConnector } from '../../connectors/settings-connector';
import { UsersAdminConnector } from '../../../utils/connectors/users-admin-connector';
import { UsersConnector } from '../../../utils/connectors/users-connector';
import { UserGroupsConnector } from '../../connectors/user-groups-connector';

import './users-and-groups-panel.less';

const messages = defineMessages({
    users: {
        id: 'settings.users-and-groups-panel.tabs.users',
        defaultMessage: 'Users ({totalNumber})',
    },
    deletedUsers: {
        id: 'settings.users-and-groups-panel.tabs.deleted-users',
        defaultMessage: 'Deleted Users ({totalNumber})',
    },
    groups: {
        id: 'settings.users-and-groups-panel.tabs.groups',
        defaultMessage: 'Groups ({totalNumber})',
    },
    properties: {
        id: 'settings.users-and-groups-panel.tabs.properties',
        defaultMessage: 'Properties ({totalNumber})',
    },
    loadUserGroupsError: {
        id: 'settings.users-page.loadUserGroupsError',
        defaultMessage: 'An error has occured while loading users and groups',
    },
    loadUserProfilesFieldsError: {
        id: 'settings.users-page.loadUserProfilesFieldsError',
        defaultMessage: 'An error has occured while loading user profiles fields',
    },
    usersAndGroupsTitle: {
        id: 'settings.users-and-groups-panel.title',
        defaultMessage: 'Users and groups',
    },
});

enum Tabs {
    Users = 'users',
    DeletedUsers = 'deleted-users',
    Groups = 'groups',
    Properties = 'properties',
}

export enum PageHeadersButtons {
    INFO = 'info',
}

export function UserAndGroupsPanel() {
    const [activeTabKey, setActiveTabKey] = useState<string>('users');
    const [getUsersMonitor] = useProgressMonitor();
    const [users, setUsers] = useState<User[]>([]);
    const [deletedUsers, setDeletedUsers] = useState<User[]>([]);
    const [allRoles, setAllRoles] = useState<Role[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);
    const [properties, setProperties] = useState<UserProfileField[]>([]);
    const [buttonSelected, setButtonSelected] = useState<string>('');
    const hasUserAccess = useHasPermission<SettingsPermissions>('admin.user.access');
    const hasUserGroupAccess = useHasPermission<SettingsPermissions>('admin.user.group.access');
    const hasUserPropertiesAccess = useHasPermission<SettingsPermissions>('admin.user.fields.access');

    const notifications = useArgNotifications();
    const [loadingUsers, errorUsers] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const promises: Promise<any>[] = [];

            if (hasUserAccess) {
                promises.push(
                    UsersAdminConnector.getInstance().getUsers(false),
                    UsersAdminConnector.getInstance().getUsers(true),
                    SettingsConnector.getInstance().getAllRoles(),
                );
            }

            if (hasUserGroupAccess) {
                promises.push(UserGroupsConnector.getInstance().getGroups());
            }

            const response = await Promise.all(promises);

            if (hasUserAccess) {
                const users: User[] = response[0];
                setUsers(users);

                const deletedUsers: User[] = response[1];
                setDeletedUsers(deletedUsers);

                const userRoles: Role[] = response[2];
                setAllRoles(userRoles.filter(role => !role.isDeleted));
            }

            if (hasUserGroupAccess) {
                const groups: Group[] = hasUserAccess ? response[3] : response[0];
                setGroups(groups);
            }
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.loadUserGroupsError }, error as Error);
            throw error;
        }
    }, []);

    useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            if (!hasUserPropertiesAccess) {
                return;
            }

            const _properties = await UsersConnector.getInstance().getUserProfileFields(progressMonitor);
            setProperties(_properties);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.loadUserProfilesFieldsError }, error as Error);
            throw error;
        }
    }, [notifications, hasUserPropertiesAccess]);

    const pageTabs = useMemo(() => {
        const tabs: ArgTab[] = [];

        if (hasUserAccess) {
            tabs.push(
                {
                    key: Tabs.Users,
                    title: <FormattedMessage {...messages.users} values={{ totalNumber: users.length }} />,
                },
            );
        }

        if (hasUserGroupAccess) {
            tabs.push(
                {
                    key: Tabs.Groups,
                    title: (
                        <FormattedMessage {...messages.groups} values={{ totalNumber: groups.length }} />
                    ),
                },
            );
        }

        if (deletedUsers?.length > 0) {
            tabs.splice(1, 0, {
                key: Tabs.DeletedUsers,
                title: (
                    <FormattedMessage
                        {...messages.deletedUsers}
                        values={{ totalNumber: deletedUsers.length }}
                    />
                ),
            });
        }

        if (hasUserPropertiesAccess) {
            tabs.push(
                {
                    key: Tabs.Properties,
                    title: <FormattedMessage {...messages.properties} values={{ totalNumber: properties?.length }} />,
                },
            );
        }

        return <ArgTabsSubLevel
            tabs={tabs}
            activeTabKey={activeTabKey}
            onChange={(tabKey) => tabKey && setActiveTabKey(tabKey)}
        />;
    }, [activeTabKey, deletedUsers.length, groups.length, users.length, properties?.length, hasUserAccess, hasUserGroupAccess, hasUserPropertiesAccess]);

    const usersAndGroupsContext = useMemo(() => {
        return {
            users,
            setUsers,
            deletedUsers,
            setDeletedUsers,
            groups,
            setGroups,
            allRoles,
            loadingUsers,
            errorUsers,
            properties,
            setProperties,
        };
    }, [allRoles, deletedUsers, groups, users, properties, setProperties]); // @TOTO OO: Dependencies ?

    useEffect(() => {
        switch (activeTabKey) {
            case Tabs.Users:
            case Tabs.DeletedUsers: {
                if (!hasUserAccess) {
                    setActiveTabKey(Tabs.Groups);
                }
                break;
            }
            case Tabs.Groups: {
                if (!hasUserGroupAccess) {
                    setActiveTabKey(Tabs.Users);
                }
                break;
            }
            case Tabs.Properties: {
                if (!hasUserPropertiesAccess) {
                    setActiveTabKey(Tabs.Users);
                }
                break;
            }
        }
    }, []);


    return (
        <UsersAndGroupsStateContext.Provider value={usersAndGroupsContext}>
            <ArgModalContainer>
                <div className='user-and-groups-wrapper'>
                    <PageHeader
                        title={messages.usersAndGroupsTitle}
                        selectedItem={buttonSelected}
                        setSelectedItem={setButtonSelected}
                        buttons={
                            activeTabKey === Tabs.Users
                                ? [{ key: PageHeadersButtons.INFO, icon: 'icon-info' }]
                                : []
                        }
                    />
                    {activeTabKey === Tabs.Users && (
                        <div className='user-and-groups-view-container'>
                            <UsersView
                                getUsersMonitor={getUsersMonitor}
                                headerButtonSelected={buttonSelected}
                                onUserClick={() => setButtonSelected(PageHeadersButtons.INFO)}
                                pageTabs={pageTabs}
                            />
                        </div>
                    )}
                    {activeTabKey === Tabs.DeletedUsers && (
                        <div className='user-and-groups-view-container'>
                            <UsersDeletedView
                                getUsersMonitor={getUsersMonitor}
                                setActiveTab={setActiveTabKey}
                                pageTabs={pageTabs}
                            />
                        </div>
                    )}
                    {activeTabKey === Tabs.Groups && (
                        <div className='user-and-groups-view-container'>
                            <GroupsView
                                pageTabs={pageTabs}
                            />
                        </div>
                    )}
                    {activeTabKey === Tabs.Properties && (
                        <div className='user-and-groups-view-container'>
                            <PropertiesView
                                pageTabs={pageTabs}
                            />
                        </div>
                    )}
                </div>
            </ArgModalContainer>
        </UsersAndGroupsStateContext.Provider>
    );
}
