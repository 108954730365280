
export interface TextSelection {
    start: number;
    end: number;
}

export interface TextState {
    value: string;
    selection: TextSelection;
}

export const NO_SELECTION: TextSelection = { start: 0, end: 0 };
