import { isNumber } from 'lodash';
import { CSSProperties, useMemo } from 'react';
import { IAceEditorProps } from 'react-ace';

import { ArgButton, useClassNames } from 'src/components/basic';
import { ArgAceEditorInput } from 'src/components/basic/arg-input/arg-input-expression-editor';
import { OntologyFeedSourceParseError } from 'src/settings/models/feed-sources';

import './feed-source-editor.less';

interface FeedSourceEditorProps {
    query: string;
    errors: OntologyFeedSourceParseError[];
    height?: number;
    onChange: (query: string) => void;
    onRemove: () => void;
    onFocus: () => void;
    focused: boolean;
    showTrash: boolean;
}

export function FeedSourceEditor(props: FeedSourceEditorProps) {
    const {
        query,
        errors,
        height,
        onChange,
        onRemove,
        focused,
        showTrash,
        onFocus,
    } = props;

    const classNames = useClassNames('settings-feed-sources-modal-editors-ace-container');

    const editorCls = { '&-editors-ace-focused': focused };

    const myAceProps = useMemo<IAceEditorProps>(() => ({
        showGutter: true,
        fontSize: 12,
        highlightActiveLine: false,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        maxLines: undefined,
        showPrintMargin: false,
    }), []);


    const style: CSSProperties = {};

    if (isNumber(height)) {
        style.height = `${height}px`;
    }

    return (
        <div
            className={classNames('&')}
            style={style}
        >
            {showTrash && (
                <div className={classNames('&-trash-button')}>
                    <ArgButton
                        type='ghost'
                        icon='icon-trash'
                        onClick={onRemove}
                    />
                </div>
            )}
            <ArgAceEditorInput
                language='mysql'
                focus={focused}
                value={query}
                onChange={(value) => onChange(value || '')}
                onFocus={onFocus}
                errors={errors}
                className={classNames('&-editor', editorCls)}
                height={height}
                aceProps={myAceProps}
            />
        </div>
    );
}
