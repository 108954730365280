import { useContext, useMemo } from 'react';

import { StatesRegistry, StatesRegistryContext } from '../../utils/rt-states/states-registry';
import { SecretState } from './secret-state';
import { SecretId } from '../secrets/secrets';
import { computeSecretStateURL, preparationSecretStateFactory } from './use-preparation-secret-state';
import { FolderState } from '../../utils/rt-states/folders/folder-state';
import { CasePieceId } from '../../model/basic-case-piece';
import { computeEntityStateURL, EntityState } from '../../utils/rt-states/folders/entity-state';
import { isPromise } from '../../components/basic';
import { FolderId } from '../../model/folder';
import { computeFolderStateURL } from '../../utils/rt-states/folders/use-folder-state';
import {
    computeEntitySettingsStateURL,
    EntitySettingsState,
} from '../../utils/rt-states/folders/entity-settings-state';
import {
    computeEntityUserSettingsStateURL,
    EntityUserSettingsState,
} from '../../utils/rt-states/folders/entity-user-settings-state';
import { preparationEntityStateFactory } from './use-preparation-entity-state';
import { preparationFolderStateFactory } from './use-preparation-folder-state';
import { entitySettingsStateFactory } from 'src/utils/rt-states/folders/use-entity-settings-state';
import { getDataPreparationEntitiesRtApi } from './rt-apis';
import { entityUserSettingsStateFactory } from '../../utils/rt-states/folders/use-entity-user-settings-state';
import { EntityId } from 'src/model/argonos-piece';
import { ProcessId } from '../model/process';
import { ProcessPrioritiesState } from './process-priorities-state';
import { ProcessComponentsPrioritiesState } from './process-components-priorities-state';
import {
    computeProcessPrioritiesStateURL,
    preparationProcessPrioritiesStateFactory,
} from './use-preparation-process-priorities-state';
import {
    computeProcessComponentsPrioritiesStateURL,
    preparationProcessComponentsPrioritiesStateFactory,
} from './use-preparation-process-components-priorities-state';
import { ProcessStatusState } from './process-status-state';
import {
    computeProcessStatusStateURL,
    preparationProcessStatusStateFactory,
} from './use-preparation-process-status-state';
import { ProcessMonitoringDataState } from './process-monitoring-data-state';
import {
    computeProcessMonitoringDataStateURL,
    preparationProcessMonitoringDataStateFactory,
} from './use-preparation-process-monitoring-data-state';
import { ProcessState } from './process-state';
import { computeProcessStateURL, preparationProcessStateFactory } from './use-preparation-process-state';
import { ProcessStatisticsState } from './process-statistics-state';
import {
    computeProcessStatisticsStateURL,
    preparationProcessStatisticsStateFactory,
} from './use-preparation-process-statistics-state';
import { ReferenceTablesState } from './reference-tables-state';
import { computeReferenceTablesStateURL, referenceTablesFactory } from './use-reference-tables-state';
import { ReferenceTableId } from '../knowledge-base/reference-tables/utils/reference-tables';
import { ReferencesTableState } from './references-table-state';
import { computeReferencesTableStateURL, referencesTableFactory } from './use-references-table-state';

export interface PreparationStateAccess {
    runSecretState<T>(secretId: SecretId, fct: (state: SecretState) => Promise<T>): Promise<T>;

    runEntityState: <T> (casePieceId: EntityId, fct: (state: EntityState) => Promise<T>) => Promise<T>;
    runFolderState: <T> (caseId: FolderId, fct: (state: FolderState) => Promise<T>) => Promise<T>;
    runEntitySettingsState: <T> (entityId: EntityId, fct: (state: EntitySettingsState) => Promise<T>) => Promise<T>;
    runEntityUserSettingsState: <T> (entityId: EntityId, fct: (state: EntityUserSettingsState) => Promise<T>) => Promise<T>;

    runProcessState: <T> (processId: ProcessId, fct: (state: ProcessState) => Promise<T>) => Promise<T>;
    runProcessPrioritiesState: <T> (processId: ProcessId, fct: (state: ProcessPrioritiesState) => Promise<T>) => Promise<T>;
    runProcessComponentsPrioritiesState: <T> (processId: ProcessId, fct: (state: ProcessComponentsPrioritiesState) => Promise<T>) => Promise<T>;
    // runProcessStatusState: <T> (processId: ProcessId, fct: (state: ProcessStatusState) => Promise<T>) => Promise<T>;

    runProcessMonitoringDataState: <T> (processId: ProcessId, fct: (state: ProcessMonitoringDataState) => Promise<T>) => Promise<T>;

    runProcessStatisticsState: <T> (processId: ProcessId, fct: (state: ProcessStatisticsState) => Promise<T>) => Promise<T>;
}

//
export function usePreparationStateAccess(): PreparationStateAccess {
    const registry = useContext(StatesRegistryContext)!;

    const access = useMemo<PreparationStateAccess>(() => {
        const runSecretState = async <T>(secretId: SecretId, fct: (state: SecretState) => Promise<T>): Promise<T> => {
            const url = computeSecretStateURL(secretId);
            const [, promise] = registry.get(url, (url) => preparationSecretStateFactory(url, secretId));

            const stateRecord = await promise;

            try {
                const retFct = fct(stateRecord.state);

                const ret = await retFct;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };

        const runEntityState = async <T>(casePieceId: CasePieceId, fct: (state: EntityState) => Promise<T>): Promise<T> => {
            const url = computeEntityStateURL(casePieceId);
            const [, promise] = registry.get(url, (url) => preparationEntityStateFactory(url, casePieceId));
            const stateRecord = await promise;

            try {
                const p = fct(stateRecord.state);
                if (!isPromise(p)) {
                    return undefined as T;
                }

                const ret: T = await p;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };
        const runFolderState = async <T>(folderId: FolderId, fct: (state: FolderState) => Promise<T>): Promise<T> => {
            const url = computeFolderStateURL(folderId);
            const [, promise] = registry.get(url, (url) => preparationFolderStateFactory(url, folderId));
            const stateRecord = await promise;
            try {
                const p = fct(stateRecord.state);
                if (!isPromise(p)) {
                    return undefined as T;
                }

                const ret: T = await p;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };


        const runEntitySettingsState = async <T>(entityId: EntityId, fct: (state: EntitySettingsState) => Promise<T>): Promise<T> => {
            const url = computeEntitySettingsStateURL(entityId);
            const [, promise] = registry.get(url, (url) => entitySettingsStateFactory(url, getDataPreparationEntitiesRtApi(), entityId));
            const stateRecord = await promise;
            try {
                const p = fct(stateRecord.state);
                if (!isPromise(p)) {
                    return undefined as T;
                }

                const ret: T = await p;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };

        const runEntityUserSettingsState = async <T>(entityId: EntityId, fct: (state: EntityUserSettingsState) => Promise<T>): Promise<T> => {
            const url = computeEntityUserSettingsStateURL(entityId);
            const [, promise] = registry.get(url, (url) => entityUserSettingsStateFactory(url, getDataPreparationEntitiesRtApi(), entityId));
            const stateRecord = await promise;
            try {
                const p = fct(stateRecord.state);
                if (!isPromise(p)) {
                    return undefined as T;
                }

                const ret: T = await p;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };

        const runProcessState = async <T>(processId: ProcessId, fct: (state: ProcessState) => Promise<T>): Promise<T> => {
            const url = computeProcessStateURL(processId);
            const [, promise] = registry.get(url, (url) => preparationProcessStateFactory(url, processId));
            const stateRecord = await promise;
            try {
                const p = fct(stateRecord.state);

                const ret: T = await p;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };

        const runProcessPrioritiesState = async <T>(processId: ProcessId, fct: (state: ProcessPrioritiesState) => Promise<T>): Promise<T> => {
            const url = computeProcessPrioritiesStateURL(processId);
            const [, promise] = registry.get(url, (url) => preparationProcessPrioritiesStateFactory(url, processId));
            const stateRecord = await promise;
            try {
                const p = fct(stateRecord.state);

                const ret: T = await p;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };

        const runProcessComponentsPrioritiesState = async <T>(processId: ProcessId, fct: (state: ProcessComponentsPrioritiesState) => Promise<T>): Promise<T> => {
            const url = computeProcessComponentsPrioritiesStateURL(processId);
            const [, promise] = registry.get(url, (url) => preparationProcessComponentsPrioritiesStateFactory(url, processId));
            const stateRecord = await promise;
            try {
                const p = fct(stateRecord.state);

                const ret: T = await p;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };

        const runProcessStatusState = async <T>(processId: ProcessId, fct: (state: ProcessStatusState) => Promise<T>): Promise<T> => {
            const url = computeProcessStatusStateURL(processId);
            const [, promise] = registry.get(url, (url) => preparationProcessStatusStateFactory(url, processId));
            const stateRecord = await promise;
            try {
                const p = fct(stateRecord.state);

                const ret: T = await p;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };

        const runProcessingDataState = async <T>(processId: ProcessId, fct: (state: ProcessMonitoringDataState) => Promise<T>): Promise<T> => {
            const url = computeProcessMonitoringDataStateURL(processId);
            const [, promise] = registry.get(url, (url) => preparationProcessMonitoringDataStateFactory(url, processId));
            const stateRecord = await promise;
            try {
                const p = fct(stateRecord.state);

                const ret: T = await p;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };
        const runProcessStatisticsState = async <T>(processId: ProcessId, fct: (state: ProcessStatisticsState) => Promise<T>): Promise<T> => {
            const url = computeProcessStatisticsStateURL(processId);
            const [, promise] = registry.get(url, (url) => preparationProcessStatisticsStateFactory(url, processId));

            const stateRecord = await promise;

            try {
                const retFct = fct(stateRecord.state);

                const ret = await retFct;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };


        return {
            runSecretState,
            runFolderState,
            runEntityState,
            runEntitySettingsState,
            runEntityUserSettingsState,
            runProcessPrioritiesState,
            runProcessComponentsPrioritiesState,
            runProcessStatusState,
            runProcessMonitoringDataState: runProcessingDataState,
            runProcessState,
            runProcessStatisticsState,
        };
    }, [registry]);

    return access;
}


export async function runReferencesTableState<T>(registry: StatesRegistry, referenceTableId: ReferenceTableId, fct: (state: ReferencesTableState) => Promise<T>): Promise<T> {
    const url = computeReferencesTableStateURL(referenceTableId);
    const [, promise] = registry.get(url, (url) => referencesTableFactory(url));

    const stateRecord = await promise;

    try {
        const retFct = fct(stateRecord.state);

        const ret = await retFct;

        return ret;
    } finally {
        await stateRecord.unregister();
    }
}


export async function runReferenceTablesState<T>(registry: StatesRegistry, fct: (state: ReferenceTablesState) => Promise<T>): Promise<T> {
    const url = computeReferenceTablesStateURL();
    const [, promise] = registry.get(url, (url: string) => referenceTablesFactory(url));

    const stateRecord = await promise;

    try {
        const retFct = fct(stateRecord.state);

        const ret = await retFct;

        return ret;
    } finally {
        await stateRecord.unregister();
    }
}

