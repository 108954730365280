import { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { EditorProps } from '../controls/controls-type';
import {
    ClassValue,
    GeoLocationValue,
    getDataTestIdFromProps,
    latitudeAndLongitudeToLatLng,
} from '../../basic';
import { latLngToLatitudeAndLongitude } from 'src/components/basic/arg-geo-picker/common/helpers/latLngToLatitudeAndLongitude';
import { ArgGeolocationPicker } from 'src/components/basic/arg-geo-picker/arg-geolocation-picker/arg-geolocation-picker';
// TODO: use a common api that doesnt use explo for address
import { ExplorationConnector } from 'src/exploration/utils/connector/exploration-connector';

export interface InputGeolocationEditorProps extends EditorProps<any> {
  className?: ClassValue;
}

export function InputGeolocationEditor(props: InputGeolocationEditorProps) {
    const {
        className,
        readOnly,
        value,
        onChange,
        autoFocus,
        placeholder,
        propertyDisplayName,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);

    let _placeholder: typeof placeholder = undefined;
    if (placeholder === null) {
        _placeholder = null;
    } else if (placeholder === undefined) {
        _placeholder = propertyDisplayName;
    } else {
        _placeholder = placeholder;
    }

    const latLongValue = useMemo<GeoLocationValue | undefined>(() => {
        if (!value) {
            return undefined;
        }

        return {
            coordinates: latitudeAndLongitudeToLatLng(value),
        };
    }, [value]);

    const handleChange = useCallback((value: any) => {
        const newLatitudeAndLogitude = (value as GeoLocationValue)?.coordinates
            ? latLngToLatitudeAndLongitude(value.coordinates)
            : undefined;

        onChange(newLatitudeAndLogitude);
    }, [onChange]);

    return (
        <ArgGeolocationPicker
            readOnly={readOnly}
            data-testid={dataTestId}
            className={classNames(className)}
            value={latLongValue}
            type='ghost'
            getAddressCoordinates={ExplorationConnector.getInstance().getAddressCoordinates}
            autoFocus={autoFocus}
            onChange={handleChange}
            placeholder={_placeholder}
        />
    );
}

export function GeoToText(value: any) {
    if (typeof (value) !== 'object' || value?.latitude === undefined || value?.longitude === undefined) {
        return undefined;
    }

    const coords = `${value.latitude} ${value.longitude}`;

    return coords;
}

