import { RtMessageEventList } from '../events';
import { FOLDER_OBJECT_EVENT_NAMES } from './events';
import { WatchCommands } from './folder-basic-state';
import { FoldersState, FoldersStateMessage } from './folders-state';
import { RtApi } from '../rt-api';
import { FolderId } from '../../../model/folder';

const WATCH_COMMANDS: WatchCommands = {
    watch: 'Watch',
    unwatch: 'Unwatch',
    linkName: 'watch',
};

export class FolderState extends FoldersState {
    readonly #folderId: FolderId;

    constructor(api: RtApi, url: string, caseId: FolderId, acceptedEvents: RtMessageEventList = FOLDER_OBJECT_EVENT_NAMES) {
        super(api, url, acceptedEvents);

        this.#folderId = caseId;
    }

    protected get keepChannelOpened() {
        return true;
    }

    get caseId(): FolderId {
        return this.#folderId;
    }

    protected get watchCommands(): WatchCommands {
        return WATCH_COMMANDS;
    }

    protected get watchKey(): string {
        return this.caseId;
    }

    protected handleCasesMessage(message: FoldersStateMessage) {
        const { caseId } = message.messageContent;

        if (caseId !== this.#folderId) {
            return true;
        }

        const ret = this.handleCaseMessage(message);

        return ret;
    }

    protected handleCaseMessage(message: FoldersStateMessage): boolean {
        return false;
    }
}
