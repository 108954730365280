import { defineMessages, MessageDescriptor } from 'react-intl';

import { dataTypesMessages } from '../../../../../components/common/data-types';
import { UserInputType } from '../../../../../model/user-metadata';
import { ArgRenderedText } from '../../../../../components/basic';

export const messages = defineMessages({
    title: {
        id: 'settings.create-property-modal.title',
        defaultMessage: 'Create new property',
    },
    editPropertyTitle: {
        id: 'settings.create-property-modal.editPropertyTitle',
        defaultMessage: 'Edit property',
    },
    useSlash: {
        id: 'settings.property-modals.options-list.useSlash',
        defaultMessage: 'Use the separator "/" to identify a hierarchy or a tree structure',
    },
    displayLastOptionOnly: {
        id: 'settings.property-modals.lastOptionOnly',
        defaultMessage:
            'Only display the last section of the option if the separator "/" is present.',
    },
    metapropertyTitle: {
        id: 'settings.create-property-modal.metapropertyTitle',
        defaultMessage: 'Create new metaproperty',
    },
    editMetapropertyTitle: {
        id: 'settings.create-property-modal.editMetapropertyTitle',
        defaultMessage: 'Edit metaproperty',
    },
    edit: {
        id: 'settings.edit-metaproperty-modal.edit',
        defaultMessage: 'Modify',
    },
    fieldName: {
        id: 'settings.create-property-modal.field.name',
        defaultMessage: 'Property name',
    },
    metapropertyFieldName: {
        id: 'settings.create-property-modal.metapropertyFieldName',
        defaultMessage: 'Metaproperty name',
    },
    fieldNameSubtitle: {
        id: 'settings.create-property-modal.field.nameSubtitle',
        defaultMessage: 'The name of the property must be unique',
    },
    metapropertySubtitle: {
        id: 'settings.create-property-modal.field.metapropertySubtitle',
        defaultMessage: 'The name of the metaproperty must be unique',
    },
    isTitle: {
        id: 'settings.create-property-modal.field.isTitle',
        defaultMessage:
            'Define this property as the title of the object "{fieldName}" in the exploration module',
    },
    isTitleDescription: {
        id: 'settings.create-property-modal.field.isTitleDescription',
        defaultMessage:
            'Since the title of an object is unique, by clicking this checkbox you will cancel any previously chosen titles',
    },
    fieldConstraints: {
        id: 'settings.create-property-modal.field.constraints',
        defaultMessage: 'Value constraints',
    },
    fieldType: {
        id: 'settings.create-property-modal.field.type',
        defaultMessage: 'Property type',
    },
    metapropertyFieldType: {
        id: 'settings.create-property-modal.metapropertyFieldType',
        defaultMessage: 'Metaproperty type',
    },
    fieldRequired: {
        id: 'settings.create-property-modal.field.required',
        defaultMessage: 'Required: this field must be completed',
    },
    isMultivalue: {
        id: 'settings.create-property-modal.field.isMultivalue',
        defaultMessage: 'Multivalued: the property will accept multiple values',
    },
    fieldInputType: {
        id: 'settings.create-property-modal.field.inputType',
        defaultMessage: 'Input type',
    },
    fieldDisplayFormat: {
        id: 'settings.create-property-modal.field.displayFormat',
        defaultMessage: 'Display format',
    },
    fieldMessage: {
        id: 'settings.create-property-modal.field.message',
        defaultMessage: 'Message',
    },
    fieldPriority: {
        id: 'settings.create-property-modal.field.priority',
        defaultMessage:
            "Primary: the property will appear as the main information in the object's description",
    },
    fieldMessageDescription: {
        id: 'settings.create-property-modal.field.messageDescription',
        defaultMessage:
            'This is the text which will be attached to your component to indicate the expected action',
    },
    fieldSearchWeight: {
        id: 'settings.create-property-modal.field.searchWeight',
        defaultMessage: 'Search weight',
    },
    submit: {
        id: 'settings.create-property-modal.submitButton',
        defaultMessage: 'Create',
    },
    submitAndAddAnother: {
        id: 'settings.create-property-modal.submitAndAddButton',
        defaultMessage: 'Create and add another',
    },
    cancel: {
        id: 'settings.create-property-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    deletionAutomatic: {
        id: 'settings.create-property-modal.deletionAutomatic',
        defaultMessage: 'Automatic deletion',
    },
    noDeletion: {
        id: 'settings.create-property-modal.noDeletion',
        defaultMessage: 'No deletion (default)',
    },
    addPropertyErrorMsg: {
        id: 'settings.error-message.adding-property',
        defaultMessage: 'Something went wrong while adding the property',
    },
    displayOptions: {
        id: 'settings.create-policy-modal.displayOptions',
        defaultMessage: 'Component display options',
    },
    default: {
        id: 'settings.create-policy-modal.default',
        defaultMessage: 'Default',
    },
    custom: {
        id: 'settings.create-policy-modal.custom',
        defaultMessage: 'Custom',
    },
    preview: {
        id: 'settings.create-policy-modal.preview',
        defaultMessage: 'Preview of property component',
    },
    metapropertyPreview: {
        id: 'settings.create-policy-modal.metapropertyPreview',
        defaultMessage: 'Preview of metaproperty component',
    },
    constraintNone: {
        id: 'settings.create-policy-modal.constraint.none',
        defaultMessage: 'None',
    },
    constraintCharacters: {
        id: 'settings.create-policy-modal.constraint.characters',
        defaultMessage: 'Number of characters',
    },
    constraintRange: {
        id: 'settings.create-policy-modal.constraint.range',
        defaultMessage: 'Range',
    },
    constraintAlphanumeric: {
        id: 'settings.create-policy-modal.constraint.alphanumeric',
        defaultMessage: 'Alphanumeric',
    },
    constraintExpression: {
        id: 'settings.create-policy-modal.constraint.expression',
        defaultMessage: 'Regular expression',
    },
    valueDisplayFormat: {
        id: 'settings.create-policy-modal.display-format.value',
        defaultMessage: 'Value display format',
    },
    valueDisplayFormatSelect: {
        id: 'settings.create-policy-modal.display-format.value-select',
        defaultMessage: 'Select a display format',
    },
    formatCheckbox: {
        id: 'settings.create-policy-modal.display-format.checkbox',
        defaultMessage: 'Checkbox',
    },
    formatTrueFalse: {
        id: 'settings.create-policy-modal.display-format.true-false',
        defaultMessage: 'True / False',
    },
    formatNumber: {
        id: 'settings.create-policy-modal.display-format.number',
        defaultMessage: 'Number',
    },
    formatPercentage: {
        id: 'settings.create-policy-modal.display-format.percentage',
        defaultMessage: 'Percentage',
    },
    inputFreeInput: {
        id: 'settings.create-policy-modal.input-format.free-input',
        defaultMessage: 'Free input',
    },
    inputSingleSelect: {
        id: 'settings.create-policy-modal.input-format.single-select',
        defaultMessage: 'Single selection',
    },
    inputMultiSelect: {
        id: 'settings.create-policy-modal.input-format.multi-select',
        defaultMessage: 'Multi selection',
    },
    optionsList: {
        id: 'settings.create-policy-modal.options-list',
        defaultMessage: 'List of options',
    },
    option: {
        id: 'settings.create-policy-modal.option',
        defaultMessage: 'Option',
    },
    continuousNumber: {
        id: 'settings.create-policy-modal.continuousNumber',
        defaultMessage: 'This property is a continuous value',
    },
    errorCreatingMetaproperty: {
        id: 'settings.create-policy-modal.errorCreatingMetaproperty',
        defaultMessage: 'An error ocurred while trying to add the metaproperty',
    },
    fetchingRetentionError: {
        id: 'settings.create-policy-modal.fetch-retention.message',
        defaultMessage: 'Loading retention',
    },
    typeStringTooltip: {
        id: 'settings.create-property-modal.field.typeStringTooltip',
        defaultMessage: 'Use for properties with a limited number of distinct values. Eg: nationality, day of the week.',
    },
    typeTextTooltip: {
        id: 'settings.create-property-modal.field.typeTextTooltip',
        defaultMessage: 'Use for properties that can take any value. E.g.: comment, last name, first name, address, etc.',
    },
});

export enum InputType {
    freeInput = 'freeInput',
    singleSelect = 'singleSelect',
}

export const getInputType = (textFormatValue: string | undefined): InputType | undefined => {
    if (textFormatValue === 'freeInput') {
        return InputType.freeInput;
    }
    if (textFormatValue === 'singleSelect') {
        return InputType.singleSelect;
    }

    return undefined;
};

type InputTypeDetails = {
    label: MessageDescriptor;
};

export const INPUT_TYPES: Record<InputType | UserInputType, InputTypeDetails> = {
    freeInput: {
        label: messages.inputFreeInput,
    },
    singleSelect: {
        label: messages.inputSingleSelect,
    },
    multiSelect: {
        label: messages.inputMultiSelect,
    },
};

export const ONTOLOGY_PROPERTY_INPUT_TYPES: Record<InputType, InputTypeDetails> = {
    freeInput: {
        label: messages.inputFreeInput,
    },
    singleSelect: {
        label: messages.inputSingleSelect,
    },
};

export enum TypeItem {
    boolean = 'boolean',
    integer8b = 'integer8b',
    integer32b = 'integer32b',
    integer64b = 'integer64b',
    float = 'float',
    character = 'character',
    string = 'string',
    text = 'text',
    date = 'date',
    dateAndTime = 'dateAndTime',
    geoCoordinates = 'geoCoordinates',
    email = 'email',
    url = 'url',
    phoneNumber = 'phoneNumber',
    address = 'address',
    country = 'country',
}
type TypeItemDetails = {
    name: MessageDescriptor;
    icon: string;
    tooltip?: ArgRenderedText;
};

export const TYPE_ITEMS: Record<TypeItem, TypeItemDetails> = {
    boolean: { name: dataTypesMessages.typeBoolean, icon: 'icon-boolean' },
    integer8b: { name: dataTypesMessages.typeInteger8b, icon: 'icon-numeric' },
    integer32b: { name: dataTypesMessages.typeInteger32b, icon: 'icon-numeric' },
    integer64b: { name: dataTypesMessages.typeInteger64b, icon: 'icon-numeric' },
    float: { name: dataTypesMessages.typeFloat, icon: 'icon-numeric' },
    character: { name: dataTypesMessages.typeCharacter, icon: 'icon-character' },
    string: { name: dataTypesMessages.typeString, icon: 'icon-text', tooltip: messages.typeStringTooltip },
    text: { name: dataTypesMessages.typeText, icon: 'icon-text', tooltip: messages.typeTextTooltip },
    date: { name: dataTypesMessages.typeDate, icon: 'icon-calendar' },
    dateAndTime: { name: dataTypesMessages.typeDateAndTime, icon: 'icon-calendar-clock' },
    geoCoordinates: { name: dataTypesMessages.typeGeoCoordinates, icon: 'icon-globe' },
    email: { name: dataTypesMessages.typeEmail, icon: 'icon-envelop' },
    url: { name: dataTypesMessages.typeUrl, icon: 'icon-sphere' },
    phoneNumber: { name: dataTypesMessages.typePhoneNumber, icon: 'icon-mobile' },
    address: { name: dataTypesMessages.typeAddress, icon: 'icon-location1' },
    country: { name: dataTypesMessages.typeCountry, icon: 'icon-flag' },
};

export enum FormatItem {
    checkbox = 'checkbox',
    trueFalse = 'trueFalse',
    number = 'number',
    percentage = 'percentage',
}

export const getFormatItem = (textFormatValue: string | undefined): FormatItem | undefined => {
    if (textFormatValue === 'checkbox') {
        return FormatItem.checkbox;
    }
    if (textFormatValue === 'trueFalse') {
        return FormatItem.trueFalse;
    }
    if (textFormatValue === 'number') {
        return FormatItem.number;
    }
    if (textFormatValue === 'percentage') {
        return FormatItem.percentage;
    }

    return undefined;
};

type FormatItemDetails = {
    label: MessageDescriptor;
    type: string[];
};

export const FORMAT_ITEMS: Record<FormatItem, FormatItemDetails> = {
    checkbox: { label: messages.formatCheckbox, type: [TypeItem.boolean] },
    trueFalse: { label: messages.formatTrueFalse, type: [TypeItem.boolean] },
    number: { label: messages.formatNumber, type: [TypeItem.integer32b, TypeItem.integer64b, TypeItem.integer8b, TypeItem.float] },
    percentage: {
        label: messages.formatPercentage,
        type: [TypeItem.integer32b, TypeItem.integer64b, TypeItem.integer8b, TypeItem.float],
    },
};

export enum ConstraintItem {
    none = 'none',
    characters = 'characters',
    expression = 'expression',
    range = 'range',
    alphanumeric = 'alphanumeric',
}

type ConstraintItemDetails = {
    label: MessageDescriptor;
    type: string[];
};

export const NUMERIC_ITEM_TYPES = [TypeItem.integer32b, TypeItem.integer64b, TypeItem.integer8b, TypeItem.float];

export const CONSTRAINT_ITEMS: Record<ConstraintItem, ConstraintItemDetails> = {
    none: {
        label: messages.constraintNone,
        type: [
            TypeItem.boolean,
            TypeItem.character,
            TypeItem.date,
            TypeItem.dateAndTime,
            TypeItem.string,
            TypeItem.text,
            TypeItem.integer8b,
            TypeItem.integer32b,
            TypeItem.integer64b,
            TypeItem.float,
            TypeItem.geoCoordinates,
        ],
    },
    characters: { label: messages.constraintCharacters, type: [TypeItem.text, TypeItem.string] },
    expression: { label: messages.constraintExpression, type: [TypeItem.text, TypeItem.string] },
    alphanumeric: { label: messages.constraintAlphanumeric, type: [TypeItem.character] },
    range: {
        label: messages.constraintRange,
        type: NUMERIC_ITEM_TYPES,
    },
};

export const TYPE_ICONS = {
    String: { icon: 'icon-text' },
    Char: { icon: 'icon-character' },
    Bool: { icon: 'icon-boolean' },
    Byte: { icon: 'icon-numeric' },
    Short: { icon: 'icon-numeric' },
    Int: { icon: 'icon-numeric' },
    Long: { icon: 'icon-numeric' },
    Float: { icon: 'icon-numeric' },
    Double: { icon: 'icon-numeric' },
    Date: { icon: 'icon-calendar' },
    Geoshape: { icon: 'icon-globe' },
    Guid: { icon: 'icon-numeric' },
    Text: { icon: 'icon-text' },
    DateTime: { icon: 'icon-calendar-clock' },
    MultiString: { icon: 'icon-text' },
    Email: { icon: 'icon-envelop' },
    Url: { icon: 'icon-sphere' },
    PhoneNumber: { icon: 'icon-mobile' },
    Address: { icon: 'icon-location1' },
    Country: { icon: 'icon-flag' },
    Action: { icon: 'icon-numeric' },
    Resources: { icon: 'icon-numeric' },
    Image: { icon: 'icon-image' },
    Object: { icon: 'icon-text' },
    AnnotationArea: { icon: 'icon-text' },
};

export interface OptionListItem {
    value: string;
    id: number;
}
