import { ArgInputMasks } from './arg-input-mask';

export function isDOMElement(node: HTMLElement): boolean {
    const result = !!node?.ownerDocument?.documentElement;

    return result;
}

export function parseMask(mask: string): ArgInputMasks {
    const exp = /([^\[]*)(\[[^\]]+\])?/g;

    const ret:(string|RegExp)[] = [];
    for (;;) {
        const reg = exp.exec(mask);

        if (!reg || (!reg[1] && !reg[2])) {
            break;
        }

        if (reg[1]) {
            ret.push(...reg[1]);
        }
        if (reg[2]) {
            ret.push(new RegExp(reg[2]));
        }
    }

    return ret;
}
