import React, { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { isEmpty, pick } from 'lodash';

import { ArgRadio, useClassNames } from '../../../../basic';
import { AdvancedStyleType, DiscreteValue, Interval, RuleSet } from '../graph-style';
import { GradientNumberControl } from './gradient-number-control';
import { IntervalNumberControl } from './interval-number-control';
import { getConditionAsGradient, getConditionAsInterval, getInvalidFields, getSpecificProps } from './utils';
import { GradientOrInterval, Side } from '../advanced-style';
import { RangeNumberControlMessages } from '../advanced-body';
import { GetGradientBounds, useGetPropertyBounds } from '../use-get-property-bounds';
import { Property } from 'src/components/common/controls/controls-type';

import './range-number-control.less';

export interface Messages {
    startValuePlaceholder: MessageDescriptor;
    endValuePlaceholder: MessageDescriptor;
    maxPlaceholder: MessageDescriptor;
    minPlaceholder: MessageDescriptor;
}
interface RangeNumberControlProps {
    ruleSets: RuleSet[] | undefined;
    type: AdvancedStyleType;
    property: Property;
    getGradientBounds?: GetGradientBounds;
    onStyleChange?: (label: AdvancedStyleType, style: Record<string, any>, index: number) => Promise<void>;
    onRangeValueChange?: (
        advancedStyleType: AdvancedStyleType,
        side: Side,
        value: number | null,
        index: number
    ) => Promise<void>;
    onRangeIntervalTypeChange?: (advancedStyleType: AdvancedStyleType, side: Side, index: number) => Promise<void>;
    onRemoveComponent?: (advancedStyleType: AdvancedStyleType, index: number) => Promise<void>;
    toggleGradientInterval: (
        advancedStyleType: AdvancedStyleType,
        gradientOrInterval: GradientOrInterval,
        property: string
    ) => Promise<void>;
    onGradientPropertyRangeValueChange: (type: 'property' | 'size', side: Side, value: number | null) => Promise<void>;
    messages: RangeNumberControlMessages;
}

export function RangeNumberControl({
    ruleSets,
    property,
    type,
    getGradientBounds,
    onStyleChange,
    onRangeValueChange,
    onRangeIntervalTypeChange,
    onRemoveComponent,
    toggleGradientInterval,
    onGradientPropertyRangeValueChange,
    messages,
}: RangeNumberControlProps) {
    const classNames = useClassNames('range-number-control');
    const [isGradient, setIsGradient] = useState(true);
    const setControlAsInterval = () => {
        isGradient && toggleGradientInterval('size', 'interval', property.name);
        setIsGradient(false);
    };
    const setControlAsGradient = () => {
        !isGradient && toggleGradientInterval('size', 'gradient', property.name);
        setIsGradient(true);
    };
    const { propertyBounds } = useGetPropertyBounds<number>(
        property.name,
        'number',
        getGradientBounds,
        setControlAsInterval,
    );

    if (ruleSets === undefined || ruleSets.length === 0) {
        return null;
    }

    let intervalContent: React.ReactNode = null;
    let gradientContent: React.ReactNode = null;
    const conditionGradient = getConditionAsGradient(ruleSets[0])?.gradient;
    const conditionInterval = getConditionAsInterval(ruleSets[0])?.interval;
    const doesContainsUndefinedValueStyle =
        isEmpty(ruleSets?.[ruleSets.length - 1].condition) ||
        (ruleSets?.[ruleSets.length - 1].condition as DiscreteValue)?.value === null;

    if (!!conditionInterval || doesContainsUndefinedValueStyle) {
        isGradient && setIsGradient(false);
        const intervalNumberControlMessages = pick(messages, ['maxPlaceholder', 'minPlaceholder', 'undefinedStyleRulePlaceholder']);
        const errors = getInvalidFields(ruleSets);

        intervalContent = (
            <>
                {ruleSets?.map((ruleSet, index) => {
                    const invalidFields: Record<
                        number,
                        {
                            invalidLeft: boolean;
                            invalidRight: boolean;
                        }> = ruleSets !== undefined ? errors[index] : {};

                    return (
                        <IntervalNumberControl
                            property={property}
                            key={ruleSet.userDefinedContent.id}
                            type={type}
                            index={index}
                            left={(ruleSet.condition as Interval)?.interval?.left ?? undefined}
                            right={(ruleSet.condition as Interval)?.interval?.right ?? undefined}
                            intervalType={(ruleSet.condition as Interval)?.interval?.type}
                            onStyleChange={onStyleChange}
                            onRangeValueChange={onRangeValueChange}
                            onRangeIntervalTypeChange={onRangeIntervalTypeChange}
                            onRemoveComponent={onRemoveComponent}
                            canBeRemoved={ruleSets.length > 1 && !ruleSet?.isUndefinedRuleSet}
                            {...getSpecificProps(type, ruleSet?.userDefinedContent)}
                            {...invalidFields}
                            messages={intervalNumberControlMessages}
                            isUndefinedStyleRule={ruleSet?.isUndefinedRuleSet}
                        />
                    );
                })}
                {!isEmpty(errors)
                    ? <div className={classNames('&', 'field-error')}>
                        <FormattedMessage {...messages.errorOverlap} />
                    </div>
                    : null}
            </>
        );
    }

    if (conditionGradient) {
        !isGradient && setIsGradient(true);
        gradientContent = (
            <>
                {ruleSets?.map((ruleSet) => {
                    return (
                        <GradientNumberControl
                            key={ruleSet.userDefinedContent.id}
                            sliderLeftBound={propertyBounds.leftBound}
                            sliderRightBound={propertyBounds.rightBound}
                            minPropertyValue={conditionGradient?.left ?? undefined}
                            maxPropertyValue={conditionGradient?.right ?? undefined}
                            minSizeValue={ruleSet?.userDefinedContent?.gradientSize?.[0]}
                            maxSizeValue={ruleSet?.userDefinedContent?.gradientSize?.[1]}
                            onGradientPropertyRangeValueChange={onGradientPropertyRangeValueChange}
                            messages={messages}
                        />
                    );
                })}
            </>
        );
    }

    return (
        <div className={classNames('&')}>
            {type === 'size' && (
                <>
                    <ArgRadio
                        className={classNames('&-gradient-radio')}
                        value={isGradient}
                        label='Gradient'
                        onChange={setControlAsGradient}
                        disabled={
                            propertyBounds.leftBound === undefined ||
                            propertyBounds.rightBound === undefined ||
                            propertyBounds.leftBound === propertyBounds.rightBound
                        }
                    />
                    {gradientContent}

                    <ArgRadio
                        className={classNames('&-interval-radio')}
                        value={!isGradient}
                        label='Interval'
                        onChange={setControlAsInterval}
                    />
                </>
            )}
            {intervalContent}
        </div>
    );
}
