import React from 'react';
import { isEqual } from 'lodash';
import { defineMessages, FormattedMessage } from 'react-intl';

import { HistoryManager } from '../../../utils/history-manager';
import { ProgressMonitor } from '../../../components/basic';
import { Action } from '../../../utils/actions/action';
import { ActionsEngine } from '../../../utils/actions/actions-engine';
import { ExplorationId } from '../../model/exploration';
import { GraphStyleCustomiserAction, TYPES } from '../../reducers/graph-style-customisation-reducer';
import { GraphCustomizerState, VertexOrEdge } from '../../hooks/use-graph-style-customisation';
import { ExplorationState } from '../../rt-states/exploration-state';
import { EditorState } from '../../rt-states/editor-state';
import { ExplorationConnector } from '../../utils/connector/exploration-connector';

const messages = defineMessages({
    changeGraphStyle: {
        id: 'exploration.actions.change-graph-stule.TaskName',
        defaultMessage: 'Change graph style',
    },
    changeGraphFillColor: {
        id: 'exploration.actions.change-graph-fillcolor.TaskName',
        defaultMessage: 'Change graph fill color',
    },
    undoChangeGraphFillColor: {
        id: 'exploration.actions.change-graph-fillcolor.Undo',
        defaultMessage: 'Cancel change graph fill color',
    },
    changeGraphStrokeColor: {
        id: 'exploration.actions.change-graph-stroke.TaskName',
        defaultMessage: 'Change graph stroke color',
    },
    undoChangeGraphStrokeColor: {
        id: 'exploration.actions.change-graph-strokecolor.Undo',
        defaultMessage: 'Cancel change graph fill color',
    },
    changeGraphNodeSize: {
        id: 'exploration.actions.change-graph-node-size.TaskName',
        defaultMessage: 'Change graph vertex size',
    },
    undoChangeGraphNodeSize: {
        id: 'exploration.actions.change-graph-node-size.Undo',
        defaultMessage: 'Cancel change graph vertex size',
    },
    changeGraphDisplayProperty: {
        id: 'exploration.actions.change-graph-display-property.TaskName',
        defaultMessage: 'Change graph display property',
    },
    undoChangeGraphDisplayProperty: {
        id: 'exploration.actions.change-graph-display-property.Undo',
        defaultMessage: 'Cancel change graph display property',
    },
    changeGraphVertexLabel: {
        id: 'exploration.actions.change-graph-vertex-label.TaskName',
        defaultMessage: 'Change graph vertex label',
    },
    undoChangeGraphVertexLabel: {
        id: 'exploration.actions.change-graph-vertex-label.Undo',
        defaultMessage: 'Cancel change graph vertex label',
    },
    changeGraphIcon: {
        id: 'exploration.actions.change-graph-icon.TaskName',
        defaultMessage: 'Change graph icon',
    },
    undoChangeGraphIcon: {
        id: 'exploration.actions.change-graph-icon.Undo',
        defaultMessage: 'Cancel change graph icon',
    },
    changeGraphIconColor: {
        id: 'exploration.actions.change-graph-icon-color.TaskName',
        defaultMessage: 'Change graph icon color',
    },
    undoChangeGraphIconColor: {
        id: 'exploration.actions.change-graph-icon-color.Undo',
        defaultMessage: 'Cancel change graph icon color',
    },
    changeObjectType: {
        id: 'exploration.actions.change-object-type.TaskName',
        defaultMessage: 'Change object type',
    },
    undoChangeObjectType: {
        id: 'exploration.actions.change-object-type.Undo',
        defaultMessage: 'Cancel change object type',
    },
    changeClusteringProperty: {
        id: 'exploration.actions.change-clustering-property.TaskName',
        defaultMessage: 'Change clustering property',
    },
    undoChangeClusteringProperty: {
        id: 'exploration.actions.change-clustering-property.Undo',
        defaultMessage: 'Cancel change clustering property',
    },
    changeAdvancedStylePropertyName: {
        id: 'exploration.actions.change-advanced-style-property-name.TaskName',
        defaultMessage: 'Change advanced style property name',
    },
    undoChangeAdvancedStylePropertyName: {
        id: 'exploration.actions.change-advanced-style-property-name.Undo',
        defaultMessage: 'Cancel change advanced style property name',
    },
    changeAdvancedStylePropertyValue: {
        id: 'exploration.actions.change-advanced-style-property-value.TaskName',
        defaultMessage: 'Change advanced style property value',
    },
    undoChangeAdvancedStylePropertyValue: {
        id: 'exploration.actions.change-advanced-style-property-value.Undo',
        defaultMessage: 'Cancel change advanced style property value',
    },
    changeAdvancedStyle: {
        id: 'exploration.actions.change-advanced-style.TaskName',
        defaultMessage: 'Change advanced style',
    },
    undoChangeAdvancedStyle: {
        id: 'exploration.actions.change-advanced-style.Undo',
        defaultMessage: 'Cancel change advanced style',
    },
    addNewAdvancedStyleRule: {
        id: 'exploration.actions.add-new-advanced-style-rule.TaskName',
        defaultMessage: 'Add new advanced style rule',
    },
    undoAddNewAdvancedStyleRule: {
        id: 'exploration.actions.add-new-advanced-style-rule.UndoAdd',
        defaultMessage: 'Cancel add new advanced style rule',
    },

    removeAdvancedStyleRule: {
        id: 'exploration.actions.remove-advanced-style-rule.TaskName',
        defaultMessage: 'Remove advanced style rule',
    },
    undoRemoveAdvancedStyleRule: {
        id: 'exploration.actions.add-new-advanced-style-rule.UndoRemove',
        defaultMessage: 'Cancel remove advanced style rule',
    },
    changeAdvancedStyleLine: {
        id: 'exploration.actions.change-advanced-style-line.TaskName',
        defaultMessage: 'Change advanced style line',
    },
    undoChangeAdvancedStyleLine: {
        id: 'exploration.actions.change-advanced-style-line.UndoRemove',
        defaultMessage: 'Cancel change advanced style line',
    },
    changeAdvancedStyleLineAnimation: {
        id: 'exploration.actions.change-advanced-style-line-animation.TaskName',
        defaultMessage: 'Change advanced style line animation',
    },
    undoChangeAdvancedStyleLineAnimation: {
        id: 'exploration.actions.change-advanced-style-line-animation.UndoRemove',
        defaultMessage: 'Cancel change advanced style line animation',
    },
    changeAdvancedStyleRuleIntervalType: {
        id: 'exploration.actions.change-advanced-style-rule-interval-type.TaskName',
        defaultMessage: 'Change advanced style interval type',
    },
    undoChangeAdvancedStyleRuleIntervalType: {
        id: 'exploration.actions.change-advanced-style-rule-interval-type.Undo',
        defaultMessage: 'Cancel change advanced style interval type',
    },
    resetStyleChanges: {
        id: 'exploration.actions.reset.style.changes.TaskName',
        defaultMessage: 'Reset style changes',
    },
    undoResetStyleChanges: {
        id: 'exploration.actions.reset.style.changes.Undo',
        defaultMessage: 'Cancel reset style changes',
    },
    addUndefinedValueStylesRule: {
        id: 'exploration.actions.add.undefined.value.styles.rule.TaskName',
        defaultMessage: 'Add undefined value styles rule',
    },
    undoAddUndefinedValueStylesRule: {
        id: 'exploration.actions.add.undefined.value.styles.rule.Undo',
        defaultMessage: 'Cancel add undefined value styles rule',
    },

    removeUndefinedValueStylesRule: {
        id: 'exploration.actions.remove.undefined.value.styles.rule.TaskName',
        defaultMessage: 'Remove undefined value styles rule',
    },
    undoRemoveUndefinedValueStylesRule: {
        id: 'exploration.actions.remove.undefined.value.styles.rule.Undo',
        defaultMessage: 'Cancel remove undefined value styles rule',
    },
    changeDefaultGeographyProperty: {
        id: 'exploration.actions.change-clustering-property.TaskName',
        defaultMessage: 'Change clustering property',
    },
    undoChangeDefaultGeographyProperty: {
        id: 'exploration.actions.change-clustering-property.Undo',
        defaultMessage: 'Cancel change clustering property',
    },

    unknownChange: {
        id: 'exploration.actions.change-graph-unknown-message.TaskName',
        defaultMessage: 'unknown change',
    },
    UndoUnknownChange: {
        id: 'exploration.actions.change-graph-unknown-message.Undo',
        defaultMessage: 'Cancel unknown change',
    },
});

export const CHANGE_GRAPH_STYLE = Symbol('ChangeGraphStyle');

const getUndoRedoMessage = (undo: boolean, actionType: TYPES) => {
    switch (actionType) {
        case TYPES.SET_VERTEX_LABEL:
            return !undo ? messages.changeGraphVertexLabel : messages.undoChangeGraphVertexLabel;
        case TYPES.UPDATE_DISPLAY_PROPERTY:
            return !undo ? messages.changeGraphDisplayProperty : messages.undoChangeGraphDisplayProperty;
        case TYPES.UPDATE_SIZE:
            return !undo ? messages.changeGraphNodeSize : messages.undoChangeGraphNodeSize;
        case TYPES.SET_FILL_COLOR:
            return !undo ? messages.changeGraphFillColor : messages.undoChangeGraphFillColor;
        case TYPES.SET_STROKE_COLOR:
        case TYPES.SET_STROKE_TRANSPARENT_COLOR:
            return !undo ? messages.changeGraphStrokeColor : messages.undoChangeGraphStrokeColor;
        case TYPES.SET_ICON:
            return !undo ? messages.changeGraphIcon : messages.undoChangeGraphIcon;
        case TYPES.SET_ICON_COLOR:
            return !undo ? messages.changeGraphIconColor : messages.undoChangeGraphIconColor;
        case TYPES.UPDATE_OBJECT_TYPE:
            return !undo ? messages.changeObjectType : messages.undoChangeObjectType;
        case TYPES.SET_CLUSTERING_PROPERTY:
            return !undo ? messages.changeClusteringProperty : messages.undoChangeClusteringProperty;
        case TYPES.SET_DEFAULT_GEOGRAPHY_PROPERTY:
            return !undo ? messages.changeDefaultGeographyProperty : messages.undoChangeDefaultGeographyProperty;
        case TYPES.SET_ADVANCED_STYLE_PROPERTY:
            return !undo ? messages.changeAdvancedStylePropertyName : messages.undoChangeAdvancedStylePropertyName;
        case TYPES.SET_ADVANCED_DISCRETE_PROPERTY_VALUE:
        case TYPES.SET_RANGE_VALUE:
            return !undo ? messages.changeAdvancedStylePropertyValue : messages.undoChangeAdvancedStylePropertyValue;
        case TYPES.SET_ADVANCED_STYLE:
            return !undo ? messages.changeAdvancedStyle : messages.undoChangeAdvancedStyle;
        case TYPES.ADD_NEW_ADVANCED_STYLE_RULE:
            return !undo ? messages.addNewAdvancedStyleRule : messages.undoAddNewAdvancedStyleRule;
        case TYPES.REMOVE_ADVANCED_STYLE_RULE:
            return !undo ? messages.removeAdvancedStyleRule : messages.undoRemoveAdvancedStyleRule;
        case TYPES.SET_ADVANCED_STYLE_LINE_STYLE:
            return !undo ? messages.changeAdvancedStyleLine : messages.undoChangeAdvancedStyleLine;
        case TYPES.SET_ADVANCED_STYLE_LINE_ANIMATION:
            return !undo ? messages.changeAdvancedStyleLineAnimation : messages.undoChangeAdvancedStyleLineAnimation;
        case TYPES.SET_ADVANCED_STYLE_INTERVAL_TYPE:
            return !undo
                ? messages.changeAdvancedStyleRuleIntervalType
                : messages.undoChangeAdvancedStyleRuleIntervalType;
        case TYPES.RESET:
            return !undo ? messages.resetStyleChanges : messages.undoResetStyleChanges;
        case TYPES.ADD_UNDEFINED_VALUE_STYLES_RULE:
            return !undo
                ? messages.addUndefinedValueStylesRule
                : messages.undoAddUndefinedValueStylesRule;
        case TYPES.REMOVE_UNDEFINED_VALUE_STYLES_RULE:
            return !undo
                ? messages.removeUndefinedValueStylesRule
                : messages.undoRemoveUndefinedValueStylesRule;
        default:
            console.error('UNKNOWN ACTION!');

            return !undo ? messages.unknownChange : messages.UndoUnknownChange;
    }
};

function getWhatChanged(actionType: TYPES): string | undefined {
    switch (actionType) {
        case TYPES.SET_CLUSTERING_PROPERTY:
        case TYPES.SET_CHRONOGRAM_GROUPING_PROPERTY:
        case TYPES.SET_CHRONOGRAM_TIMELINE_PROPERTY:
            return 'Content';
        case TYPES.UPDATE_DISPLAY_PROPERTY:
            return 'Info';
        case TYPES.SET_VERTEX_LABEL:
            return undefined;
        default:
            return 'Style';
    }
}

export function createChangeExplorationStyleUndoRedo(
    dispatch: (value: GraphStyleCustomiserAction) => void,
    initialStateRef: React.MutableRefObject<GraphCustomizerState>,
    state: GraphCustomizerState,
    explorationId: ExplorationId,
    explorationState: ExplorationState,
    editorState: EditorState,
    actionType: TYPES,
    vertexOrEdge: VertexOrEdge,
): Action | undefined {
    const initialState: GraphCustomizerState = initialStateRef.current;

    if (isEqual(initialStateRef.current, state)) {
        //If same state, does not add undo/redo
        return;
    }

    return {
        renderEntry: function changeGraphStyleActionTitle(undo) {
            const message = getUndoRedoMessage(undo, actionType);

            return <FormattedMessage {...message} />;
        },

        type: CHANGE_GRAPH_STYLE,
        action: async (
            history: HistoryManager<ActionsEngine>,
            actionsEngine: ActionsEngine,
            progressMonitor: ProgressMonitor,
            redo?: boolean | undefined,
        ) => {
            progressMonitor.beginTask(messages.changeGraphStyle);

            const whatChanged = getWhatChanged(actionType);
            if (whatChanged) {
                await ExplorationConnector.getInstance().saveExplorationStyleDefinition(explorationId, state, vertexOrEdge);

                editorState.performEditorUpdate('change-graph-style');
                explorationState.change();
            }

            redo && dispatch({
                type: TYPES.SET,
                payload: { ...state, colorAndIconEditorVisible: false },
            });

            initialStateRef.current = state;
        },

        reverseAction: async (
            history: HistoryManager<ActionsEngine>,
            actionsEngine: ActionsEngine,
            progressMonitor: ProgressMonitor,
        ) => {
            progressMonitor.beginTask(messages.changeGraphStyle);

            const whatChanged = getWhatChanged(actionType);
            if (whatChanged) {
                await ExplorationConnector.getInstance().saveExplorationStyleDefinition(explorationId, initialState, vertexOrEdge);
            }
            if (initialState.label) {
                const explorationStyleDefinition = await ExplorationConnector.getInstance().getExplorationStyleDefinition(
                    explorationId,
                    initialState.label,
                    vertexOrEdge,
                );

                if (explorationStyleDefinition) {
                    dispatch({
                        type: TYPES.SET,
                        payload: {
                            label: initialState.label,
                            titleProperty: explorationStyleDefinition.titleProperty,
                            userDefinedContent: explorationStyleDefinition.userDefinedContent,
                            clusteringProperty: explorationStyleDefinition.clusteringProperty,
                            geographicalProperty: explorationStyleDefinition.geographicalProperty,
                            ruleSets: explorationStyleDefinition.ruleSets,
                            colorAndIconEditorVisible: false,
                        },
                    });
                }
            }
            if (whatChanged) {
                editorState.performEditorUpdate('change-graph-style');
                explorationState.change();
            }
        },
    };
}
