import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { reduce } from 'lodash';
import classNames from 'classnames';

import { useMemoDeepEquals } from '../arg-hooks/use-memo-deep-equals';
import { ClassValue } from '../arg-hooks/use-classNames';
import { setImmutable } from '../utils/immutable-set';
import { $yield } from '../utils/yield';


class ArgStyleVariableRegistry {
    #domElement: HTMLElement;
    #computedStyle?: CSSStyleDeclaration;

    constructor(domElement: HTMLElement) {
        this.#domElement = domElement;
    }

    getVariable(variableName: string, defaultValue?: string): string | undefined {
        if (!this.#computedStyle) {
            const win = this.#domElement.ownerDocument.defaultView!;

            this.#computedStyle = win.getComputedStyle(this.#domElement);
        }

        const result = this.#computedStyle!.getPropertyValue(variableName);

        return result || defaultValue;
    }
}

const ArgStyleVariablesContext = createContext<ArgStyleVariableRegistry | null>(null);

export interface ArgStyleVariablesProviderProps {
    children?: ReactNode;
    className?: ClassValue;
    'data-testid'?: string;
}

export function ArgStyleVariablesProvider(props: ArgStyleVariablesProviderProps) {
    const {
        children,
        className,
    } = props;

    const [registry, setRegistry] = useState<ArgStyleVariableRegistry>();

    const localDomRef = useCallback((domElement: HTMLDivElement | null) => {
        if (!domElement) {
            return;
        }
        $yield(() => {
            setRegistry(new ArgStyleVariableRegistry(domElement));
        });
    }, []);

    const body = <div ref={localDomRef} className={classNames(className)} data-testid={props['data-testid']}>
        <ArgStyleVariablesContext.Provider value={registry || null}>
            {children}
        </ArgStyleVariablesContext.Provider>
    </div>;

    return body;
}

export type ArgGetStyleVariable = (variableName: string|undefined, defaultValue?: string) => (string | undefined);

export function useGetStyleVariable(): ArgGetStyleVariable {
    const context = useContext(ArgStyleVariablesContext);

    const result = useMemo<ArgGetStyleVariable>(() => {
        return (variableName: string|undefined, defaultValue: string | undefined): string | undefined => {
            if (!context || !variableName) {
                return defaultValue;
            }

            return context.getVariable(variableName, defaultValue);
        };
    }, [context]);

    return result;
}

export function useStyleVariable(variableName: string, defaultValue?: string): string | undefined {
    const context = useContext(ArgStyleVariablesContext);

    if (!context) {
        return defaultValue;
    }

    const result = context.getVariable(variableName, defaultValue);

    return result;
}

const NO_STYLES = setImmutable({});

export function useStyleVariables(variableNames: string[]): Record<string, string | undefined> {
    const context = useContext(ArgStyleVariablesContext);

    const result = useMemoDeepEquals<Record<string, string | undefined>>(() => {
        if (!context) {
            return NO_STYLES;
        }

        const result = reduce(variableNames, (acc: Record<string, string | undefined>, variableName: string) => {
            const result = context.getVariable(variableName);

            acc[variableName] = result;

            return acc;
        }, {} as Record<string, string | undefined>);

        return result;
    }, [variableNames]);

    return result;
}
