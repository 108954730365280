import { ArgUserId, ArgUserInfo } from '../components/basic';
import { CasePermissions } from './folder-case-piece';

export enum CollaboratorRole {
    Owner,
    Writer,
    Reader,
   //  Custom,
}

export enum CollaboratorType {
    User,
    Group,
}

export type Collaborator = {
    identityId: ArgUserId;
    name: string;
    role: CollaboratorRole;
    user?: ArgUserInfo;
    type: CollaboratorType;
    permission?: CasePermissions;
};

export const COLLABORATOR_ROLES: CollaboratorRole[] = [
    CollaboratorRole.Owner,
    CollaboratorRole.Writer,
    CollaboratorRole.Reader,
//    CollaboratorRole.Custom,
];
export const ROLE_TO_NUMBER = {
    [CollaboratorRole.Owner]: 0,
    [CollaboratorRole.Writer]: 1,
    [CollaboratorRole.Reader]: 2,
//    [CollaboratorRole.Custom]: 3,
};
