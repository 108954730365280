import { Dayjs } from 'dayjs';

import { Filter } from 'src/exploration/model/filter';
import { Pane } from 'src/exploration/model/pane';
import { UniverseId, UniverseVertexTypeName } from 'src/exploration/model/universe';
import { ExplorationId } from '../../model/exploration';
import { SearchModel } from '../universe/universe-filter/search-model-type';
import { ProcessId } from '../../../utils/connector';


export enum SortingType {
    Relevance = 'Relevance',
    Alphabetical = 'Alphabetical',
}

export enum ViewMode {
    List = 'List',
    Map = 'Map',
    Graph = 'Graph',
}

export enum SearchMode { // à retirer
    AdvancedSearch = 'fast',
    StructuredSearch = 'advanced',
    MassiveSearch = 'massive'
}
export enum SearchMode2 {
    FastSearch = 'fast',
    AdvancedSearch = 'advanced',
    MassiveSearch = 'massive'
}

export interface GlobalSearchSettingsUiState {
    searchMode: SearchMode;
}

export interface SearchSettingsUiState {
    viewMode: ViewMode;
    sorting: SortingType;
    groupByObjectTypes?: boolean;
}

export interface StructuredSearchSettingsState {
    structuredSearchModel?: SearchModel;
    explorationId?: ExplorationId;
    processId?: ProcessId;
    searchScopeFilter: Filter;
    searchTopBarFilter?: Filter;
    uiState: SearchSettingsUiState;
}

export interface AdvancedSearchSettingsState {
    searchScope: string[];
    searchScopeFilter: Filter;
    searchTopBarFilter?: Filter;
    'exploration-filter'?: Filter;
    uiState: SearchSettingsUiState;
}
export interface FastSearchSettingsState {
    searchScope: string[];
    searchScopeFilter: Filter;
    searchTopBarFilter?: Filter;
    'exploration-filter'?: Filter;
    uiState: SearchSettingsUiState;
}

export interface SearchSettings {
    advancedSearchSettingsState: AdvancedSearchSettingsState;
    fastSearchSettingsState: FastSearchSettingsState;
    structuredSearchSettingsState?: StructuredSearchSettingsState;
    uiState: GlobalSearchSettingsUiState;
}

//-----------------new
export interface FastSearchSettings {
    searchScope: string[];
    searchScopeFilter: Filter;
    searchTopBarFilter?: Filter;
    'exploration-filter'?: Filter;
    uiState: SearchSettingsUiState;
}
export interface AdvancedSearchSettings {
    structuredSearchModel?: SearchModel;
    explorationId?: ExplorationId;
    processId?: ProcessId;
    searchScopeFilter: Filter;
    searchTopBarFilter?: Filter;
    uiState: SearchSettingsUiState;
    'exploration-filter'?: Filter;
}

//-----------------new


export type GeographicState = 'all' | 'except' | 'only';
export type TimelineState = [Dayjs | null, Dayjs | null] | undefined;
export interface GeoAndTimeLineIndex {
    geoProperty?: string;
    timelineProperty?: string;
}

export type GeoAngTimeLinePropertyNameByObjectType = Record<UniverseVertexTypeName, GeoAndTimeLineIndex>;

export interface SearchPane extends Pane {
    universeId: UniverseId;
}

export interface GeographyOrTimelinePropertyNames {
    geographyPropertyNames: string[];
    timelinePropertyNames: string[];
}
