import { chain, defaults, isEmpty, isNumber, keyBy, mapValues, omit, sortBy } from 'lodash';
import Debug from 'debug';

import {
    CreatedVerticesResult,
    Vertex,
    VertexAttachment,
    VertexCapabilities,
    VertexId,
    VertexResource,
} from '../../model/vertex';
import { CreatedEdgesResult, EdgeId, RelationshipDTO } from '../../model/edge';
import {
    UniverseDescriptor,
    UniverseEdgeTypeName,
    UniverseId,
    UniverseStatistics,
    UniverseType,
    UniverseVertexTypeName,
    VertexSystemPropertyName,
} from 'src/exploration/model/universe';
import { ArgonosError, DataSorter, ProgressMonitor, SubProgressMonitor } from '../../../components/basic';
import { TraversalCountResponse } from '../../../model/traversal-count-request';
import {
    ExplorationEdgeDetails,
    ExplorationId,
    VisualizationEdgeDetails,
    VisualizationVertexDetails,
    VisualizationVertexForm,
} from '../../model/exploration';
import {
    ConnectorRequestInit,
    DEFAULT_CONTENT_TYPE,
    ETaggedObject,
    isNotChangedEtagError,
} from '../../../utils/connector';
import { Change } from '../../model/vertexHistoryChangesResponse';
import { GraphStylesInfos } from '../../hooks/use-graph-style-customisation';
import { TraversalRequest } from '../request-types';
import {
    dateToISOString,
    formatUniverseWithSettings,
    mapGraphVisualization,
    mapListVisualizationVertex,
    mapMapVisualization,
    mapRawVertexToVertex,
    mapStyleTemplate,
    mapSuggestResult,
    mapTableVisualization,
    mapVertexAttachment,
    mapVertexChange,
    mapVisualizationEdgeDetailed,
    mapVisualizationVertexDetailed,
    mapVisualizationVertexForm,
} from './mappers';
import { BaseConnector } from '../../../utils/connectors/base-connector';
import {
    ExplorationObjectTypesTypeItem,
    JsonPatchOperationRequest,
    VisualizeVertexQueryOptions,
} from '../../model/types';
import { StyleTemplate, StyleTemplateId, StyleTemplateName } from 'src/exploration/model/style-template';
import { SuggestResult } from '../../model/suggest';
import {
    RawAttachmentListResponse,
    RawGraphVisualization,
    RawListVisualization,
    RawMapVisualization,
    RawSearchResult,
    RawSearchSuggestResult,
    RawStyleTemplate,
    RawTableVisualization,
    RawVertex,
    RawVertexAttachment,
    RawVertexChangeHistory,
    RawVisualizationVertexForm,
} from './raws';
import {
    ALL_FILTER,
    EdgeFilter,
    Filter,
    FilterGroup,
    FilterOperation,
    FilterOperationType,
    VertexFilter,
} from 'src/exploration/model/filter';
import { FacetsRequest, FacetsResponse, UniverseFacetsRequest } from './types';
import { CaseId } from '../../../model/folder-case-piece';
import { getDataExplorationApi } from '../../../utils/connectors/api-url';
import { ResourceId } from 'src/model/resource';
import { TargetUserProfile } from '../../model/target-user-profile';
import { createFilterFromObjectId, includeExplorationToFilter } from '../constraints';
import { filterChain, isFilterOperation } from '../../model/filter-chain';
import { FolderId } from 'src/model/folder';
import {
    GraphVisualization,
    ListVisualization,
    ListVisualizationVertex,
    TableVisualization,
} from '../../model/graph-visualization';
import {
    ExplorationMapRequest,
    mapDataSorter,
    SubTableOptions,
    TablePropertiesSelection,
    VertexVisualizationReturnOptions,
} from './exploration-connector';
import { MapVisualization } from '../../model/map-visualization';
import { HighlightResults, SearchOptions } from '../../../model/search-suggestion';
import { Column } from '../../model/table-configuration';
import { FromToDate } from '../../features/exploration/data-view-state';
import { TimelineVisualization } from '../../model/timeline';
import { Visualization } from '../../model/exploration-visualization';
import { UserMetadata } from 'src/model/user-metadata';
import { downloadBlob } from 'src/utils/file';
import { getFileNameFromContentDisposition } from './header-parser';
import { Operation } from 'src/utils/json-patch/json-patch-adapter';

const debug = Debug('argonode:utils:UniverseConnector');

export const FAVORITE_IMAGE_PROPERTY_NAME = VertexSystemPropertyName.FavoriteImage;

export type FilterOperationKind = 'Vertex' | 'Edge';

export const UNKNOWN_VERTEX = Symbol('UnknownVertex');

const ETAG_NOT_CHANGED_OBJECT = Symbol('NOT CHANGED');

interface CsvOutputFilteringBound {
    startingRow: number | null;
    endingRow: number | null;
    startingColumn: number | null;
    endingColumn: number | null;
}

export interface CsvOutputFiltering {
    include?: CsvOutputFilteringBound | null;
    exclude?: CsvOutputFilteringBound | null;
}

export interface ExportCsvOptions {
    universeId: UniverseId;
    explorationId?: ExplorationId;
    folderId?: CaseId;
    filter?: Filter;
    userMetadata?: UserMetadata;
    sorter?: DataSorter;
    filename: string;
    outputFilterings?: CsvOutputFiltering[];
    columns?: Column[];
}

export class UniverseConnector extends BaseConnector {
    private static instance: UniverseConnector;

    static getInstance(): UniverseConnector {
        if (!UniverseConnector.instance) {
            UniverseConnector.instance = new UniverseConnector('exploration.universes', getDataExplorationApi());
        }

        return UniverseConnector.instance;
    }

    async getFullUniverse(id: UniverseId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<UniverseType> {
        const rawUniverse = await this.getUniverse(id, progressMonitor);

        const universe = formatUniverseWithSettings(rawUniverse);

        return universe;
    }

    async getUniverse(
        id: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<UniverseType> {
        const url = `/universes/${encodeURIComponent(id)}`;

        const result = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        debug('getUniverse', 'Universe received from the server:', result);

        return result;
    }

    async getUniverses(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<UniverseDescriptor[]> {
        const result = await this.request('/universes', {
            verifyJSONResponse: true,
        }, progressMonitor);

        debug('getUniverses', 'Universes received from the server: ', result);

        return sortBy(result?.universes, (universe) => universe.name.toLowerCase()) ?? [];
    }

    async search(
        searchTerm: string,
        universeId: UniverseId,
        caseId: CaseId,
        filter?: Filter,
        top?: number,
        progressMonitor = ProgressMonitor.empty(),
    ) {
        const url = `/universes/${encodeURIComponent(universeId)}/search`;
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                searchTerm,
                caseId,
                filter,
                top: top ?? 100,
            },
            verifyJSONResponse: true,
        };
        const result: RawSearchResult = await this.request(url, options, progressMonitor);
        // const searchResults = mapSuggestResult(result);

        // return searchResults;
        // const ret = mapSearchResult(result);

        return {
            hits: {
                ...result.hits,
                hits: result.hits.hits.map(hit => ({
                    ...hit,
                    vertex: mapRawVertexToVertex(hit.vertex),
                })),
            },
        };
    }

    async suggestSearch(
        searchTerm: string,
        universeId: UniverseId,
        caseId?: CaseId,
        filter?: Filter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<SuggestResult> {
        if (!searchTerm?.length) {
            return {
                total: 0,
                vertexSuggestResult: [],
            };
        }

        const url = `/universes/${encodeURIComponent(universeId)}/suggest`;
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                searchTerm,
                caseId,
                filter,
            },
            verifyJSONResponse: true,
        };

        const result: RawSearchSuggestResult = await this.request(url, options, progressMonitor);

        debug('suggest', 'Suggested search result received from the server: ', result);
        if (!result) {
            return {
                total: 0,
                vertexSuggestResult: [],
            };
        }
        const suggestions = mapSuggestResult(result);

        return suggestions;
    }

    async createUniverseFacets(
        universeId: UniverseId,
        request: UniverseFacetsRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<FacetsResponse> {
        const result = await this.request(
            `/universes/${encodeURIComponent(universeId)}/facets`,
            {
                json: request,
            },
            progressMonitor,
        );

        debug('createUniverseFacets', 'Facets search result received from the server: ', result);

        return result;
    }

    async getPossibleValues(
        universeId: UniverseId | undefined,
        requestOptions: UniverseFacetsRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<string[]> {
        if (!universeId) {
            return [];
        }

        const result = await this.createUniverseFacets(universeId, requestOptions, progressMonitor);

        if (result.facets.length === 0) {
            return [];
        }

        const firstFacet = result.facets[0];
        const possibleValues = firstFacet.property.possibleValues
            ?? firstFacet.facets.map((facet: { value: any }) => facet.value);

        return possibleValues;
    }

    async traversalsCount(
        universeId: UniverseId,
        request: TraversalRequest | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<TraversalCountResponse> {
        const result = await this.request(
            `/universes/${encodeURIComponent(universeId)}/traversals/count`,
            {
                json: request || {},
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        debug('traversalsCount', 'TraversalsCount result received from the server=', result);

        return result;
    }

    async changeUniverseVertexProperties(
        universeId: UniverseId,
        vertexId: VertexId,
        changes: Operation[],
        comment?: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const json: JsonPatchOperationRequest = { changes, comment };

        await this.request(
            `/universes/${encodeURIComponent(universeId)}/vertices/${encodeURIComponent(vertexId)}`,
            {
                method: 'PATCH',
                json,
                headers: {
                    'Content-Type': 'application/json-patch+json',
                },
                verifyJSONResponse: true,
            },
            progressMonitor,
        );
    }


    async changeUniverseRelationshipProperties(
        universeId: UniverseId,
        relationshipId: EdgeId,
        changes: Operation[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
        comment?: string,
    ): Promise<void> {
        const json = { changes, comment };

        await this.request(
            `/universes/${encodeURIComponent(universeId)}/edges/${encodeURIComponent(relationshipId)}`,
            {
                method: 'PATCH',
                json,
                headers: {
                    'Content-Type': 'application/json-patch+json',
                },
                verifyJSONResponse: true,
            },
            progressMonitor,
        );
    }

    async changeUniverseVertexPropertyMeta(
        universeId: UniverseId,
        vertexId: VertexId,
        propertyName: string,
        valueIndex: number,
        meta: Record<string, any>,
        comment?: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const changes: Operation[] = [{
            op: 'add',
            path: `/properties/${propertyName}/${valueIndex}/meta`,
            value: meta,
        }];

        const json: JsonPatchOperationRequest = {
            changes: changes,
            comment: comment,
        };

        await this.request(
            `/universes/${encodeURIComponent(universeId)}/vertices/${encodeURIComponent(vertexId)}`,
            {
                method: 'PATCH',
                json,
                headers: {
                    'Content-Type': 'application/json-patch+json',
                },
                verifyJSONResponse: true,
            },
            progressMonitor,
        );
    }

    async setFavoriteUniverseVertex(
        universeId: UniverseId,
        vertexId: VertexId,
        resourceId: ResourceId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const operation: Operation = {
            op: 'add',
            path: `/properties/${FAVORITE_IMAGE_PROPERTY_NAME}`,
            value: resourceId,
        };

        await this.request(
            `/universes/${encodeURIComponent(universeId)}/vertices/${encodeURIComponent(vertexId)}`,
            {
                method: 'PATCH',
                json: [operation],
                headers: {
                    'Content-Type': 'application/json-patch+json',
                },
                verifyJSONResponse: true,
            },
            progressMonitor,
        );
    }

    async fetchUniverseVertexAttachments(
        universeId: UniverseId,
        vertexId: VertexId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<VertexAttachment[]> {
        const result: RawAttachmentListResponse = await this.request(
            `/universes/${encodeURIComponent(universeId)}/vertices/${encodeURIComponent(vertexId)}/attachments`,
            {
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        const ret = result.attachments.map((attachment) => {
            const ret = mapVertexAttachment(attachment, universeId);

            return ret;
        });

        return ret;
    }

    async uploadUniverseVertexAttachment(
        universeId: UniverseId,
        vertexId: VertexId,
        blob: Blob,
        filename: string,
        description: string | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<VertexAttachment> {
        const headers: Record<string, any> = {
            'Content-Type': blob.type || DEFAULT_CONTENT_TYPE,
            'Content-Length': blob.size.toString(),
            'X-Filename': encodeURIComponent(filename),

        };
        if (description) {
            headers['X-Description'] = `"${encodeURIComponent(description)}"`;
        }

        const result: RawVertexAttachment = await this.request(
            `/universes/${encodeURIComponent(universeId)}/vertices/${encodeURIComponent(vertexId)}/attachments`,
            {
                body: blob,
                method: 'PUT',
                headers: headers,
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        const ret = mapVertexAttachment(result, universeId);

        return ret;
    }

    async deleteObjectsFromUniverse(
        universeId: UniverseId,
        filter: VertexFilter,
        comment?: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/universes/${encodeURIComponent(universeId)}/vertices`;
        const options = {
            method: 'DELETE',
            json: {
                filter,
            },
            comment,
        };

        await this.request(url, options, progressMonitor);
    }

    async createVertex(
        vertex: Vertex,
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<{
            id: VertexId;
        }> {
        const url = `/universes/${encodeURIComponent(universeId)}/vertices`;
        const options: ConnectorRequestInit = {
            json: {
                vertices: [vertex],
            },
            method: 'POST',
        };

        const createdVertexIds: CreatedVerticesResult = await this.request(url, options, progressMonitor);

        return { id: createdVertexIds.createdIds[0].id };
    }

    async visualizeVertex(
        universeId: UniverseId,
        vertexId: VertexId,
        caseId?: CaseId,
        returnOptions?: VisualizeVertexQueryOptions,
        targetProfile?: TargetUserProfile,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<VisualizationVertexForm>> {
        const filter = createFilterFromObjectId(vertexId);

        const ret = await this.getVisualizationVertexDetails(
            universeId,
            filter,
            undefined,
            caseId,
            undefined,
            returnOptions,
            targetProfile,
            progressMonitor);

        if (!ret.vertex) {
            const ex = new ArgonosError('Not valid vertex', UNKNOWN_VERTEX);
            throw ex;
        }

        return ret.vertex;
    }

    async getUniverseEdgeDetails(
        universeId: UniverseId,
        filter?: EdgeFilter,
        selection?: EdgeFilter,
        index = 0,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<VisualizationEdgeDetails>> {
        const url = `/universes/${encodeURIComponent(universeId)}/visualizations/edge`;

        const result: any = await this.request(
            url,
            {
                verifyJSONResponse: true,
                json: {
                    filter,
                    edgeSelection: selection,
                    index,
                },
                method: 'POST',
            },
            progressMonitor,
        );

        const edge: ExplorationEdgeDetails | undefined = result.edge ? {
            edge: mapVisualizationEdgeDetailed(result.edge),
            fromVertex: mapVisualizationVertexDetailed(result.fromVertex),
            toVertex: mapVisualizationVertexDetailed(result.toVertex),
        } : undefined;

        const explorationEdgeDetails: VisualizationEdgeDetails = {
            edge,
            count: result.count ?? 0,
            selectedCount: result.selectedCount ?? 0,
        };

        return explorationEdgeDetails;
    }

    async createRelations(
        relations: RelationshipDTO[],
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<EdgeId> {
        const url = `/universes/${encodeURIComponent(universeId)}/edges`;
        const options: ConnectorRequestInit = {
            json: { relations: relations },
            method: 'POST',
            verifyJSONResponse: true,
        };

        const relationshipId: CreatedEdgesResult = await this.request(url, options, progressMonitor);

        const ret: EdgeId = relationshipId.createdIds[0].id;

        return ret;
    }

    async deleteRelationshipsFromUniverse(
        universeId: UniverseId,
        filter: EdgeFilter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/universes/${encodeURIComponent(universeId)}/edges`;
        const options = {
            method: 'DELETE',
            json: {
                filter,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async getVertexEditingHistory(
        universeId: UniverseId,
        vertexId: VertexId,
        startAfterToken?: string,
        property?: string,
        count?: number,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Change[]> {
        const url = `/universes/${encodeURIComponent(universeId)}/vertices/${encodeURIComponent(vertexId)}/history`;
        const vertexChangeHistory: RawVertexChangeHistory = await this.request(
            url,
            {
                params: {
                    startAfterToken,
                    property,
                    count,
                },
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        const ret = vertexChangeHistory.changes.map(mapVertexChange);

        return ret;
    }

    async revertVertexHistory(
        universeId: UniverseId,
        vertexId: VertexId,
        asOf: Date,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/universes/${encodeURIComponent(universeId)}/vertices/${encodeURIComponent(vertexId)}`;

        try {
            await this.request(
                url,
                {
                    params: {
                        'as-of': dateToISOString(asOf),
                    },
                    method: 'PUT',
                },
                progressMonitor,
            );
        } catch (error) {
            console.error(
                'Cannot revert the vertex',
                'universeId=',
                universeId,
                'vertexId=',
                vertexId,
                'asOf=',
                asOf,
                'error=',
                error,
            );
            throw new Error('Cannot revert vertex');
        }
    }

    async getVertex(
        universeId: UniverseId,
        vertexId: VertexId,
        asOf?: Date,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Vertex> {
        const url = `/universes/${encodeURIComponent(universeId)}/vertices/${encodeURIComponent(vertexId)}`;

        const result: RawVertex = await this.request(
            url,
            {
                params: {
                    'as-of': asOf ? dateToISOString(asOf) : undefined,
                },
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        const vertex = mapRawVertexToVertex(result);

        return vertex;
    }

    async getUniverseStatistics(
        universeId: UniverseId,
        _filter?: VertexFilter | FilterOperation,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<UniverseStatistics> {
        const url = `/universe-statistics/${encodeURIComponent(universeId)}`;

        if (isFilterOperation(_filter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            _filter = await filterChain(_filter).value(universeId, 'Vertex', sub1);
        }

        const [filterWithoutExplorationId, explorationId] = splitFilterAndExplorationId(_filter);

        const options: ConnectorRequestInit = {
            json: {
                objectFilter: filterWithoutExplorationId || ALL_FILTER,
                explorationId,
            },
            verifyJSONResponse: true,
        };

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        const response = await this.request(url, options, sub2);

        return response;
    }

    async createStyleTemplate(
        universeId: UniverseId,
        name: string,
        vertexStyles?: Record<UniverseVertexTypeName, GraphStylesInfos>,
        edgeStyles?: Record<UniverseEdgeTypeName, GraphStylesInfos>,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<StyleTemplate> {
        const url = `/universes/${encodeURIComponent(universeId)}/style-templates`;

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                name: name,
                vertexStyles: vertexStyles,
                edgeStyles: edgeStyles,
            },
            verifyJSONResponse: true,
        };

        const result: RawStyleTemplate = await this.request(url, options, progressMonitor);

        const ret = mapStyleTemplate(result);

        return ret;
    }

    async updateStyleTemplate(
        universeId: UniverseId,
        templateId: StyleTemplateId,
        templateName: StyleTemplateName,
        vertexStyles: Record<UniverseVertexTypeName, GraphStylesInfos>,
        edgeStyles: Record<UniverseEdgeTypeName, GraphStylesInfos>,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/universes/${encodeURIComponent(universeId)}/style-templates/${encodeURIComponent(templateId)}`;

        const options: ConnectorRequestInit = {
            method: 'PUT',
            json: {
                name: templateName,
                vertexStyles: vertexStyles,
                edgeStyles: edgeStyles,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async getStyleTemplates(
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<StyleTemplate[]> {
        const url = `/universes/${encodeURIComponent(universeId)}/style-templates`;

        const rawStyleTemplates: Record<StyleTemplateId, RawStyleTemplate> = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const templates = Object.values(rawStyleTemplates.templates).map(mapStyleTemplate);

        return templates;
    }

    async getCapabilities(
        universeId: UniverseId,
        vertexId: VertexId,
        changes: Operation[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<VertexCapabilities> {
        const url = `/universes/${encodeURIComponent(universeId)}/vertices/${encodeURIComponent(vertexId)}/capabilities`;

        const response = await this.request(url, {
            verifyJSONResponse: true,
            json: { changes },
            headers: {
                'Content-Type': 'application/json-patch+json',
            },
        }, progressMonitor);

        return response;
    }

    async deleteStyleTemplate(
        universeId: UniverseId,
        styleTemplateId: StyleTemplateId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/universes/${encodeURIComponent(universeId)}/style-templates/${encodeURIComponent(styleTemplateId)}`;

        const options: ConnectorRequestInit = { method: 'DELETE' };

        await this.request(url, options, progressMonitor);
    }

    async filterOperations(
        universeId: UniverseId,
        operation: FilterOperationType,
        kind: FilterOperationKind,
        filters: Filter[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Filter> {
        const url = `/universes/${encodeURIComponent(universeId)}/filter-operations/${encodeURIComponent(operation)}`;
        // Backend fails with empty filter, hence replace with { all: true }
        filters = filters.map(f => (isEmpty(f) ? ALL_FILTER : f));

        const [, explorationId] = splitFilterAndExplorationId(filters[0]);

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                kind,
                filters,
            },
            verifyJSONResponse: true,
        };

        const response = await this.request(url, options, progressMonitor);

        const ret = includeExplorationToFilter(response.filter, explorationId);

        return ret;
    }

    async createResource(
        universeId: UniverseId,
        blob: Blob,
        filename: string,
        description: string | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<VertexResource> {
        const headers: Record<string, any> = {
            'Content-Type': blob.type || DEFAULT_CONTENT_TYPE,
            'Content-Length': blob.size.toString(),
            'X-Filename': encodeURIComponent(filename),

        };
        if (description) {
            headers['X-Description'] = `"${encodeURIComponent(description)}"`;
        }

        const result: VertexResource = await this.request(
            `/universes/${encodeURIComponent(universeId)}/resources`,
            {
                body: blob,
                method: 'POST',
                headers: headers,
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        return result;
    }

    async getUniverseVertices(
        universeId: UniverseId,
        filter: VertexFilter | FilterOperation,
        topCount?: number,
        folderId?: FolderId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<VisualizationVertexForm[]> {
        if (isFilterOperation(filter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            filter = await filterChain(filter).value(universeId, 'Vertex', sub1);
        }

        if (filter?.filterGroups && isEmpty(filter?.filterGroups) && !filter!.all && !filter!.nothing) {
            filter = ALL_FILTER;
        }

        const url = `/universes/${encodeURIComponent(universeId)}/visualizations/vertices`;

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        const result = await this.request(
            url,
            {
                verifyJSONResponse: true,
                json: {
                    filter,
                    top: topCount,
                    folderId,
                },
                method: 'POST',
            },
            sub2,
        );

        if (!result.vertices) {
            const ex = new ArgonosError('No valid vertices', UNKNOWN_VERTEX);
            throw ex;
        }

        const ret = result.vertices.map((vertex: RawVisualizationVertexForm) => mapVisualizationVertexForm(vertex, universeId));

        return ret;
    }

    async getVisualizationVertexDetails(
        universeId: UniverseId,
        _filter?: Filter | FilterOperation,
        selection?: Filter,
        caseId?: CaseId,
        index = 0,
        returnOptions?: VertexVisualizationReturnOptions,
        targetUserProfile?: TargetUserProfile,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<VisualizationVertexDetails>> {
        const url = `/universes/${encodeURIComponent(universeId)}/visualizations/vertex`;

        if (isFilterOperation(_filter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            _filter = await filterChain(_filter).value(universeId, 'Vertex', sub1);
        }

        const [filterWithoutExplorationId, explorationId] = splitFilterAndExplorationId(_filter);

        let filter: Filter | undefined = filterWithoutExplorationId;
        if (filter?.filterGroups && isEmpty(filter?.filterGroups) && !filter!.all && !filter!.nothing) {
            filter = ALL_FILTER;
        }

        const options: ConnectorRequestInit = {
            json: {
                filter,
                selection,
                index,
                folderId: caseId,
                explorationId,
                returnOptions,
                targetProfiles: targetUserProfile ? [targetUserProfile] : undefined,
            },
            method: 'POST',
            verifyJSONResponse: true,
        };

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        const result: any = await this.request(
            url,
            options,
            sub2,
        );

        const rawVertex = result.vertex;

        /* No results, does not throw an exception
        if (rawVertex === undefined) {
            throw new ArgonodeError('Unknown object', UNKNOWN_OBJECT);
        }
         */

        const visualizationVertexDetails: VisualizationVertexDetails = {
            count: result.count,
            selectedCount: result.selectedCount,
            vertex: rawVertex ? mapVisualizationVertexForm(rawVertex, universeId) : undefined,
        };

        return visualizationVertexDetails;
    }

    async getVisualizationGraph(
        universeId: UniverseId,
        _selection?: Filter,
        _edgeSelection?: Filter,
        _filter?: Filter | FilterOperation,
        caseId?: CaseId,
        targetUserProfile?: TargetUserProfile,
        previousGraph?: GraphVisualization,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<GraphVisualization>> {
        if (isFilterOperation(_filter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            _filter = await filterChain(_filter).value(universeId, 'Vertex', sub1);
        }

        const [filterWithoutExplorationId, explorationId] = splitFilterAndExplorationId(_filter);
        const [selection] = splitFilterAndExplorationId(_selection);
        const [edgeSelection] = splitFilterAndExplorationId(_edgeSelection);

        let filter: Filter | undefined = filterWithoutExplorationId;
        if (filter?.filterGroups && isEmpty(filter?.filterGroups) && !filter!.all && !filter!.nothing) {
            filter = ALL_FILTER;
        }

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                selection,
                edgeSelection,
                filter,
                folderId: caseId,
                explorationId,
                targetProfiles: targetUserProfile ? [targetUserProfile] : undefined,
            },
            verifyJSONResponse: true,
            etag: (previousGraph as ETaggedObject)?.etag,
        };

        let result: RawGraphVisualization;

        try {
            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            result = await this.request(
                `/universes/${encodeURIComponent(universeId)}/visualizations/graph`,
                options,
                sub2,
            );
        } catch (error) {
            if (isNotChangedEtagError(error)) {
                return previousGraph!;
            }

            throw error;
        }

        debug('getVisualizationGraph', 'Exploration received from the server:', result);
        const ret = mapGraphVisualization(result);

        return ret;
    }

    async getVisualizationMap(
        universeId: UniverseId,
        request: ExplorationMapRequest,
        ignoreCounts: boolean | undefined,
        targetUserProfile?: TargetUserProfile,
        previousVisualization?: MapVisualization,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<MapVisualization>> {
        const url = `/universes/${encodeURIComponent(universeId)}/visualizations/map`;

        let _filter = request.filter;
        if (isFilterOperation(_filter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            _filter = await filterChain(_filter).value(universeId, 'Vertex', sub1);
        }

        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        let countsPromise;
        if (!ignoreCounts) {
            countsPromise = this.getVisualizationVertexDetails(
                universeId,
                _filter,
                request.selection,
                undefined,
                -1,
                {
                    listAttachments: false,
                    listRelatedVertices: false,
                },
                undefined,
                sub1,
            );
        } else {
            countsPromise = Promise.resolve({ count: -1, selectedCount: -1 });
        }

        const [filterWithoutExplorationId, explorationId] = splitFilterAndExplorationId(_filter);

        let filter: Filter | undefined = filterWithoutExplorationId;
        if (filter?.filterGroups && isEmpty(filter?.filterGroups) && !filter!.all && !filter!.nothing) {
            filter = ALL_FILTER;
        }

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        const resultPromise: Promise<RawMapVisualization | symbol> = this.request(
            url,
            {
                json: {
                    ...request,
                    filter,
                    explorationId,
                    targetProfiles: targetUserProfile ? [targetUserProfile] : undefined,
                },
                verifyJSONResponse: true,
                etag: (previousVisualization as ETaggedObject)?.etag,
            },
            sub2,
        ).catch((error) => {
            if (isNotChangedEtagError(error)) {
                return ETAG_NOT_CHANGED_OBJECT;
            }

            return Promise.reject(error);
        });

        const [verticesCounts, result] = await Promise.all([countsPromise, resultPromise]);

        debug('getVisualizationMap', 'getVisualizationMap result received from the server: ', result);

        if (result === ETAG_NOT_CHANGED_OBJECT && previousVisualization) {
            if (verticesCounts.count === previousVisualization.verticesCount
                && verticesCounts.selectedCount === previousVisualization.selectedVerticesCount) {
                return previousVisualization;
            }

            const ret: MapVisualization = {
                ...previousVisualization,
                verticesCount: verticesCounts.count,
                selectedVerticesCount: verticesCounts.selectedCount,
            };

            return ret;
        }

        const ret = mapMapVisualization(result as RawMapVisualization, verticesCounts.count, verticesCounts.selectedCount);

        return ret;
    }

    async getVisualizationTable(
        universeId: UniverseId,
        skip: number | undefined,
        pageSize: number,
        sorter: DataSorter | undefined,
        _selection?: VertexFilter,
        _filter?: Filter | FilterOperation,
        searchOptions?: SearchOptions,
        includesColumns?: boolean,
        subTableOptions: Partial<SubTableOptions> | number | boolean = false,
        targetUserProfile?: TargetUserProfile,
        selectedColumns?: Column[],
        progressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<TableVisualization>> {
        const url = `/universes/${encodeURIComponent(universeId)}/visualizations/table`;

        const [selection] = splitFilterAndExplorationId(_selection);

        if (subTableOptions === true) {
            subTableOptions = 10;
        }

        if (isNumber(subTableOptions)) {
            subTableOptions = { top: subTableOptions };
        }

        if (subTableOptions) {
            defaults(subTableOptions, {
                relationFilter: { all: true },
                initialObjectsFilter: { all: true },
                _selection,
                sorting: null,
                skip: 0,
                top: 10,
                includesColumns: true,
                searchOptions: { skipResults: 0 },
            });
        }

        if (isFilterOperation(_filter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            _filter = await filterChain(_filter).value(universeId, 'Vertex', sub1);
        }

        const [filterWithoutExplorationId, explorationId] = splitFilterAndExplorationId(_filter);

        let filter: Filter | undefined = filterWithoutExplorationId;
        if (filter?.filterGroups && isEmpty(filter?.filterGroups) && !filter!.all && !filter!.nothing) {
            filter = ALL_FILTER;
        }

        const options: ConnectorRequestInit = {
            json: {
                skip,
                top: pageSize,
                selection,
                sorting: mapDataSorter(sorter),
                searchOptions: searchOptions,
                filter,
                includesColumns,
                explorationId,
                subTableOptions: subTableOptions || undefined,
                targetProfiles: targetUserProfile ? [targetUserProfile] : undefined,
                columnsConfiguration: selectedColumns ? { selectedProperties: mapColumns(selectedColumns) } : undefined,
            },
            method: 'POST',
            verifyJSONResponse: true,
        };

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        const apiResultPromise: Promise<RawTableVisualization> = this.request(url, options, sub2);

        // If sub tables are visible let's also include them in vertices count, hence let's count all selected vertices and all vertices (for total count) in explo.
        let selectedStatisticsPromise: Promise<UniverseStatistics> | undefined;
        if (subTableOptions && selection) {
            const sub3 = new SubProgressMonitor(progressMonitor, 1);
            selectedStatisticsPromise = this.getUniverseStatistics(universeId, selection, sub3);
        }

        // With sub tables on, we show the count of all objects in the explo.
        const totalCountFilter = subTableOptions ? ALL_FILTER : filterWithoutExplorationId;

        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const statisticsPromise = this.getUniverseStatistics(universeId, totalCountFilter, sub1);
        const [statistics, apiResult] = await Promise.all([statisticsPromise, apiResultPromise]);

        const result = mapTableVisualization(apiResult, statistics.objectsTotalCount);

        if (selectedStatisticsPromise) {
            const statistics = await selectedStatisticsPromise;
            result.selectedVerticesCount = statistics.objectsTotalCount;
        }

        return result;
    }

    async getVisualizationTimeline(
        universeId: UniverseId,
        viewType: string,
        range?: FromToDate,
        count?: number,
        explorationId?: ExplorationId,
        folderId?: FolderId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<TimelineVisualization>> {
        const url = `/universes/${encodeURIComponent(universeId)}/visualizations/timeline`;

        const requestBody: Record<string, unknown> = {
            count,
            viewType,
            explorationId,
            folderId,
        };

        if (range) {
            requestBody.from = range.from.toISOString();
            requestBody.to = range.to.toISOString();
        }

        const result = await this.request(
            url,
            {
                method: 'POST',
                verifyJSONResponse: true,
                json: requestBody,
            },
            progressMonitor,
        );

        return result as TimelineVisualization;
    }

    async getVisualizationsVertices(
        universeId: UniverseId,
        _filter: VertexFilter | FilterOperation | undefined,
        _selection: VertexFilter | undefined,
        folderId: FolderId | undefined,
        skip: number | undefined,
        top: number | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<VisualizationVertexForm[]> {
        const url = `/universes/${encodeURIComponent(universeId)}/visualizations/vertices`;

        if (isFilterOperation(_filter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            _filter = await filterChain(_filter).value(universeId, 'Vertex', sub1);
        }

        const [filterWithoutExplorationId, explorationId] = splitFilterAndExplorationId(_filter);
        const [selection] = splitFilterAndExplorationId(_selection);

        let filter: Filter | undefined = filterWithoutExplorationId;
        if (filter?.filterGroups && isEmpty(filter?.filterGroups) && !filter!.all && !filter!.nothing) {
            filter = ALL_FILTER;
        }

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        const result = await this.request(
            url,
            {
                verifyJSONResponse: true,
                json: {
                    filter: filter || ALL_FILTER,
                    selection,
                    top,
                    skip,
                    folderId,
                    explorationId,
                },
                method: 'POST',
            },
            sub2,
        );

        if (!result.vertices) {
            const ex = new ArgonosError('No valid vertices', UNKNOWN_VERTEX);
            throw ex;
        }

        const ret = result.vertices.map((vertex: RawVisualizationVertexForm) => mapVisualizationVertexForm(vertex, universeId));

        return ret;
    }

    async getVisualizationEdgeDetails(
        universeId: UniverseId,
        _filter?: EdgeFilter | FilterOperation,
        _selection?: EdgeFilter,
        index = 0,
        targetUserProfile?: TargetUserProfile,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<VisualizationEdgeDetails>> {
        const url = `/universes/${encodeURIComponent(universeId)}/visualizations/edge`;

        if (isFilterOperation(_filter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            _filter = await filterChain(_filter).value(universeId, 'Edge', sub1);
        }

        const [filterWithoutExplorationId, explorationId] = splitFilterAndExplorationId(_filter);
        const [selection] = splitFilterAndExplorationId(_selection);

        const options: ConnectorRequestInit = {
            json: {
                filter: filterWithoutExplorationId,
                edgeSelection: selection,
                index,
                targetProfiles: targetUserProfile ? [targetUserProfile] : undefined,
                explorationId,
            },
            method: 'POST',
            verifyJSONResponse: true,
        };

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        const result: any = await this.request(
            url,
            options,
            sub2,
        );

        /*
        if (rawEdge === undefined) {
            throw new ArgonodeError('Unknown object', UNKNOWN_OBJECT);
        }
         */

        const edge: ExplorationEdgeDetails | undefined = result.edge ? {
            edge: mapVisualizationEdgeDetailed(result.edge),
            fromVertex: mapVisualizationVertexDetailed(result.fromVertex),
            toVertex: mapVisualizationVertexDetailed(result.toVertex),
        } : undefined;

        const explorationEdgeDetails: VisualizationEdgeDetails = {
            edge,
            count: result.count ?? 0,
            selectedCount: result.selectedCount ?? 0,
        };

        return explorationEdgeDetails;
    }

    async getVisualizationVertices(
        universeId: UniverseId,
        skip?: number,
        pageSize?: number,
        selection?: VertexFilter,
        _filter?: Filter | FilterOperation,
        caseId?: CaseId,
        sorter?: DataSorter,
        targetUserProfile?: TargetUserProfile,
        progressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<Visualization>> {
        return this.getVisualizationList(universeId, skip, pageSize, selection, _filter, caseId, sorter, undefined, targetUserProfile, undefined, progressMonitor);
    }

    async getVisualizationList(
        universeId: UniverseId,
        skip?: number,
        pageSize?: number,
        _selection?: VertexFilter,
        _filter?: Filter | FilterOperation,
        caseId?: CaseId,
        sorter?: DataSorter,
        searchOptions?: SearchOptions,
        targetUserProfile?: TargetUserProfile,
        highlightResults?: HighlightResults,
        progressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<ListVisualization>> {
        if (isFilterOperation(_filter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            _filter = await filterChain(_filter).value(universeId, 'Vertex', sub1);
        }

        if (_filter?.nothing) {
            return {
                searchCount: 0,
                skip: 0,
                clusterEdges: {},
                clusters: {},
                edges: {},
                //edgesCount: 0,
                selectedEdgesCount: 0,
                selectedVerticesCount: 0,
                verticesCount: 0,
                filteredVerticesCount: 0,
                vertices: {},
                verticesArray: [],
                groups: {},
                selectedFlaggedObjectsCount: 0,
            };
        }

        const [filterWithoutExplorationId, explorationId] = splitFilterAndExplorationId(_filter);
        const [selection] = splitFilterAndExplorationId(_selection);

        const url = `/universes/${encodeURIComponent(universeId)}/visualizations/list`;
        const options: ConnectorRequestInit = {
            json: {
                skip: skip,
                top: pageSize,
                selection,
                sorting: sorter?.propertySorters.map((propertySorter) => {
                    return {
                        order: propertySorter.order,
                        column: propertySorter.propertyName,
                    };
                }),
                searchOptions,
                folderId: caseId,
                highlightFilterResults: highlightResults?.highlightFilterResults,
                highlightSelectionResults: highlightResults?.highlightSelectionResults,
                filter: filterWithoutExplorationId,
                targetProfiles: targetUserProfile ? [targetUserProfile] : undefined,
                explorationId,
            },
            method: 'POST',
        };

        const apiResult: RawListVisualization = await this.request(url, options, progressMonitor);

        const verticesArray: ListVisualizationVertex[] = apiResult.vertices.map(mapListVisualizationVertex);

        const vertices: Record<VertexId, ListVisualizationVertex> = keyBy(verticesArray, (vertex) => vertex.id);

        const result: ListVisualization = {
            clusterEdges: {},
            clusters: {},
            edges: {},
            groups: {},
            //edgesCount: 0,
            selectedEdgesCount: 0,
            verticesCount: apiResult.count,
            filteredVerticesCount: apiResult.count,
            selectedVerticesCount: apiResult.selectedCount ?? 0,
            verticesArray,
            vertices,
            skip: apiResult.skip,
            searchCount: apiResult.searchCount,
            selectedFlaggedObjectsCount: apiResult.selectedFlaggedObjectsCount ?? 0,
        };

        return result;
    }

    async getVisualizationFacets(
        universeId: UniverseId,
        request: FacetsRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<FacetsResponse>> {
        const [filterWithoutExplorationId, explorationId] = splitFilterAndExplorationId(request.filter);

        let filter: Filter | undefined = filterWithoutExplorationId;
        if (filter?.filterGroups && isEmpty(filter?.filterGroups) && !filter!.all && !filter!.nothing) {
            filter = ALL_FILTER;
        }

        const result = await this.request(
            `/universes/${encodeURIComponent(universeId)}/facets`,
            {
                json: {
                    ...request,
                    filter,
                    explorationId,
                },
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        debug('getVisualizationFacets', 'Facets search result received from the server: ', result);

        return result;
    }

    async getVisualizationObjectTypesAndCount(
        universeId: UniverseId,
        targetUserProfile?: TargetUserProfile,
        _filter?: Filter | FilterOperation,
        progressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<ExplorationObjectTypesTypeItem[]>> {
        // Backend fails with empty filter, hence replace with undefined
        const url = `/universes/${encodeURIComponent(universeId)}/facets`;

        if (isFilterOperation(_filter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            _filter = await filterChain(_filter).value(universeId, 'Vertex', sub1);
        }

        const [filterWithoutExplorationId, explorationId] = splitFilterAndExplorationId(_filter);

        let filter: Filter | undefined = filterWithoutExplorationId;
        if (filter?.filterGroups && isEmpty(filter?.filterGroups) && !filter!.all && !filter!.nothing) {
            filter = ALL_FILTER;
        }

        const json = {
            properties: [VertexSystemPropertyName.Type],
            facetType: 'Vertices',
            filter,
            explorationId,
        };

        const result = await this.request(
            url,
            {
                method: 'POST',
                json,
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        const explorationObjectTypes =
            result.facets?.[0]?.facets?.map((f: any) => ({
                label: f.value,
                verticesCount: f.numberOfVertices,
            })) ?? [];

        return explorationObjectTypes;
    }

    async exportCsv(opts: ExportCsvOptions, progressMonitor = ProgressMonitor.empty()): Promise<void> {
        const {
            universeId,
            folderId,
            explorationId,
            filter,
            userMetadata,
            sorter,
            filename,
            columns,
            outputFilterings,
        } = opts;
        const url = `/universes/${encodeURIComponent(universeId)}/export/csv`;

        let selectedProperties: TablePropertiesSelection[] | undefined;
        if (columns) {
            selectedProperties = columns.map(c => ({
                typeName: c.vertexName || null,
                property: c.propertyName,
            }));
        }

        const options: ConnectorRequestInit = {
            method: 'POST',
            forceResponse: true,
            json: {
                explorationId,
                folderId,
                filter,
                targetProfiles: [{
                    profileFields: mapValues(userMetadata, rawValue => (rawValue)),
                }],
                sorting: mapDataSorter(sorter),
                columns: selectedProperties ? { selectedProperties } : undefined,
                outputFilterings: isEmpty(outputFilterings) ? undefined : outputFilterings,
            },
        };
        const sub = new SubProgressMonitor(progressMonitor, 1);

        // TODO: fix encoding, note: already tryed fixing BOM as suggested here https://stackoverflow.com/a/61670918 but without success.
        const ret = await this.request(url, options, sub);
        if (ret) {
            const contentDisposition = ret.headers.get('content-disposition');

            const parsedFileName = getFileNameFromContentDisposition(contentDisposition);
            const newFileName = parsedFileName ?? filename;

            const blob = await ret.blob();
            downloadBlob(newFileName, blob);
        }
    }
}

function mapColumns(columns: Column[]): TablePropertiesSelection[] {
    return columns.map(col => ({
        typeName: col.vertexName || null,
        property: col.propertyName,
    }));
}

function splitFilterAndExplorationId(filter?: Filter): [Filter | undefined, ExplorationId | undefined] {
    if (isEmpty(filter)) {
        return [undefined, undefined];
    }

    if (isEmpty(filter?.filterGroups)) {
        return [filter, undefined];
    }

    let explorationId: ExplorationId | undefined = undefined;

    const filterGroups = chain(filter.filterGroups).filter((filterGroup: FilterGroup) => {
        const exploId = filterGroup.exploration?.[0]?.included?.[0];
        if (!exploId) {
            return true;
        }

        if (!explorationId) {
            explorationId = exploId;
        }

        const filterRequest = omit(filterGroup, 'exploration');

        return !isEmpty(filterRequest);
    }).compact().value();

    if (!explorationId) {
        // Not modified
        return [filter, undefined];
    }

    const ret = {
        ...filter,
        filterGroups,
    };

    return [ret, explorationId];
}
