import { useCallback, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgChangeReason,
    ArgFormLabel,
    ArgInputText,
    ArgModal,
    ClassValue,
    ProgressMonitor,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { UserInputType, UserProfileField } from '../../../model/user-metadata';
import { AddEditUserProfileField } from '../../models/dtoApi';
import {
    ConstraintItem,
    FormatItem,
    INPUT_TYPES,
    InputType,
    TypeItem,
} from '../../universes/ontology/components/property-and-metaproperty-modals/const';
import {
    PropertyInputType,
} from '../../universes/ontology/components/property-and-metaproperty-modals/property-input-type';
import {
    PropertyTypeAndConstraint,
} from '../../universes/ontology/components/property-and-metaproperty-modals/property-type-and-constraints';
import { getBaseType, getCustomDataType } from './utils';
import { getTypeItem } from '../../universes/ontology/components/property-and-metaproperty-modals/utils';
import { UsersConnector } from '../../../utils/connectors/users-connector';

import './edit-property-modal.less';

const CLASSNAME = 'settings-edit-property-modal';
const messages = defineMessages({
    title: {
        id: 'settings.properties.edit-property-modal.title',
        defaultMessage: 'Edit the property',
    },
    fieldName: {
        id: 'settings.properties.edit-property-modal.fieldName',
        defaultMessage: 'Name of the property',
    },
    fieldNameSubtitle: {
        id: 'settings.properties.edit-property-modal.fieldNameSubtitle',
        defaultMessage: 'The name property must be unique',
    },
    editPropertyError: {
        id: 'settings.properties.edit-property-modal.editPropertyError',
        defaultMessage: 'An error occurred while editing the property',
    },
    nameMustBeUnique: {
        id: 'settings.properties.edit-property-modal.nameMustBeUnique',
        defaultMessage: 'Name is already used',
    },
    submitTooltip: {
        id: 'settings.properties.edit-property-modal.submitTooltip',
        defaultMessage: 'Enter the property name',
    },
    cancel: {
        id: 'settings.properties.edit-property-modal.cancel',
        defaultMessage: 'Cancel',
    },
    edit: {
        id: 'settings.properties.edit-property-modal.edit',
        defaultMessage: 'Edit',
    },
});

export interface EditPropertyModalProps {
    className?: ClassValue;
    closeModal: () => void;
    property: UserProfileField;
    properties?: UserProfileField[];
    setProperties: React.Dispatch<React.SetStateAction<UserProfileField[]>>;
}

export function EditPropertyModal(props: EditPropertyModalProps) {
    const { className, property, closeModal, properties, setProperties } = props;
    const classNames = useClassNames(CLASSNAME);

    const [inputType, setInputType] = useState<InputType | UserInputType | undefined>(getPropertyInputType(property));
    const [type, setType] = useState<TypeItem | undefined>(getPropertyBaseType(property) || undefined);
    const [displayPropertyName, setDisplayPropertyName] = useState(property.displayName);
    const [format, setFormat] = useState<FormatItem>();
    const [constraint, setConstraint] = useState<ConstraintItem>(ConstraintItem.none);
    const [characters, setCharacters] = useState<[number, number]>([10, 40]);
    const [continuousNumber, setContinuousNumber] = useState(false);
    const [optionsList, setOptionsList] = useState(
        property.possibleValues?.map((value, id) => {
            return { value, id };
        }) || [
            { value: '', id: 1 },
            { value: '', id: 2 },
        ],
    );
    const [displayFinalOptionOnly, setDisplayFinalOptionOnly] = useState(false);

    const notifications = useArgNotifications();

    const isPropertyNameInvalid = useMemo(() => {
        const sameName = properties?.find((p) => p.displayName === displayPropertyName && p.displayName !== property.displayName);

        return !!sameName;
    }, [properties, displayPropertyName, property.displayName]);

    const [callSubmit, submitProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor, name?: string) => {
        const baseType = getBaseType(type);
        const customDataType = getCustomDataType(type);
        if (!baseType && !customDataType) {
            return;
        }

        const updatedProperty: AddEditUserProfileField = {
            displayName: name ? name : displayPropertyName,
            baseType,
            customDataType,
            possibleValues: inputType === UserInputType.freeInput ? undefined : optionsList.map((option) => option.value),
            isMultivalued: inputType === UserInputType.multiSelect,
        };

        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await UsersConnector.getInstance().editUserProfileField(property.id, updatedProperty, sub1);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            const newProperties = await UsersConnector.getInstance().getUserProfileFields(sub2);
            setProperties(newProperties);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.editPropertyError }, error as Error);
        }
    }, [optionsList, displayPropertyName, inputType, type, notifications, setProperties, property.id]);

    const resetState = useCallback(() => {
        setDisplayPropertyName('');
        setType(TypeItem.boolean);
        setFormat(undefined);
        setConstraint(ConstraintItem.none);
        setContinuousNumber(false);
        setInputType(UserInputType.freeInput);
        setOptionsList([
            { value: '', id: 1 },
            { value: '', id: 2 },
        ]);
        setDisplayFinalOptionOnly(false);
    }, []);

    const okButtonDisabled = !displayPropertyName || isPropertyNameInvalid;

    const propertyNameError = isPropertyNameInvalid
        ? messages.nameMustBeUnique
        : undefined;

    const handleSubmit = useCallback((name?: string) => {
        callSubmit(name);
        resetState();
        closeModal();
    }, [callSubmit, resetState, closeModal]);

    const handleOnChange = useCallback((name: string | null, reason: ArgChangeReason) => {
        setDisplayPropertyName(name ?? '');
        if (reason === 'enter' && name) {
            handleSubmit(name);
        }
    }, [handleSubmit]);

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            loading={submitProgressMonitor?.isRunning}
            className={classNames('&')}
            okText={messages.edit}
            okDisabled={okButtonDisabled}
            cancelText={messages.cancel}
            onCancel={closeModal}
            onOk={() => handleSubmit()}
        >
            <form
                autoComplete='off'
                className={classNames('&', className)}
                onSubmit={() => handleSubmit(displayPropertyName)}
            >
                <ArgFormLabel
                    className={classNames('&-property-name')}
                    propertyName={messages.fieldName}
                    description={propertyNameError ? '' : messages.fieldNameSubtitle}
                    errorMessage={propertyNameError}
                >
                    <ArgInputText
                        value={displayPropertyName}
                        onChange={handleOnChange}
                        autoFocus={true}
                        id='propertyName'
                    />
                </ArgFormLabel>
                <PropertyTypeAndConstraint
                    type={type}
                    setType={setType}
                    setFormat={setFormat}
                    constraint={constraint}
                    setConstraint={setConstraint}
                    characters={characters}
                    setCharacters={setCharacters}
                    continuousNumber={continuousNumber}
                    setContinuousNumber={setContinuousNumber}
                    inEditModal={true}
                />
                <PropertyInputType
                    type={type}
                    inputType={inputType}
                    setInputType={setInputType}
                    inputs={Object.keys(INPUT_TYPES) as UserInputType[]}
                    format={format}
                    setFormat={setFormat}
                    optionsList={optionsList}
                    setOptionsList={setOptionsList}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    setDisplayFinalOptionOnly={setDisplayFinalOptionOnly}
                />

            </form>
        </ArgModal>
    );
}

function getPropertyInputType(property: UserProfileField) {
    if (!property.possibleValues) {
        return UserInputType.freeInput;
    }

    if (property.isMultivalued) {
        return UserInputType.multiSelect;
    }

    return UserInputType.singleSelect;
}

function getPropertyBaseType(property: UserProfileField) {
    if (!property.baseType && !property.customDataType) {
        return undefined;
    }

    if (property.baseType) {
        return getTypeItem(property.baseType);
    }

    if (property.customDataType) {
        return getTypeItem(property.customDataType);
    }
}
