import { useState } from 'react';
import { defineMessages } from 'react-intl';

import { Policy } from 'src/settings/models/dtoApi';
import { ArgButton, ArgModal, useClassNames, useArgNotifications } from 'src/components/basic';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { NotifyBox } from 'src/settings/common-modals/notify-box/notify-box';
import { WARNING_ORANGE } from 'src/settings/models/colors';

import './single-policy-delete-modal.less';

interface DeletePolicyModalProps {
    visible: boolean;
    closeModal: () => void;
    policy: Policy;
    setPolicies: React.Dispatch<React.SetStateAction<Policy[]>>;
}

export const messages = defineMessages({
    title: {
        id: 'settings.delete-policy-modal.title',
        defaultMessage: 'Delete a policy',
    },
    description: {
        id: 'settings.delete-policy-modal.description',
        defaultMessage: 'Are you sure you want to delete the policy?',
    },
    subDescription: {
        id: 'settings.delete-policy-modal.subDescription',
        defaultMessage: 'The policy "{policyName}" will be permanently deleted.',
    },
    cancel: {
        id: 'settings.delete-policy-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.delete-policy-modal.button.delete',
        defaultMessage: 'Delete',
    },
    deletePolicyErrorMsg: {
        id: 'settings.delete-policy-modal.error-message',
        defaultMessage: 'Something went wrong while deleting the policy',
    },
});

export function DeletePolicyModal(props: DeletePolicyModalProps) {
    const {
        visible,
        closeModal,
        policy,
        setPolicies,
    } = props;

    const notifications = useArgNotifications();
    const classNames = useClassNames('delete-policy-modal');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await explorationSettingsConnector.deletePolicy(policy.id);
            setPolicies((currentPolicies) =>
                currentPolicies.filter((existingPolicy) => existingPolicy.id !== policy.id),
            );
            setLoading(false);
            closeModal();
        } catch (error) {
            notifications.snackError({ message: messages.deletePolicyErrorMsg }, error as Error);
            setLoading(false);
        }
    };

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            visible={visible}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                        disabled={loading}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        onClick={() => handleSubmit()}
                        label={messages.delete}
                        loading={loading}
                        disabled={loading}
                        data-testid='delete'
                    />
                </div>
            }
        >
            <NotifyBox
                text={messages.description}
                subtext={messages.subDescription}
                messageValues={{ policyName: policy.name }}
                icon='icon-exclamation-point'
                color={WARNING_ORANGE}
            />
        </ArgModal>
    );
}
