import { computeEntityStateURL, EntityState } from './entity-state';
import { EntityId } from '../../../model/argonos-piece';
import { ENTITY_USER_SETTINGS_EVENTS } from './events';
import { RtApi } from '../rt-api';

export function computeEntityUserSettingsStateURL(entityId: EntityId) {
    const pieceStateURL = computeEntityStateURL(entityId);

    return `${pieceStateURL}/user-settings`;
}

export class EntityUserSettingsState extends EntityState {
    constructor(api: RtApi, url: string, entityId: EntityId) {
        super(api, url, entityId, ENTITY_USER_SETTINGS_EVENTS);
    }

    userSettingsChanged(settings: Record<string, any>) {
        this.change('userSettings', settings);
    }
}
