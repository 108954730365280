import { defineMessages, FormattedMessage } from 'react-intl';
import { useContext, useState } from 'react';

import { ArgButton, ArgMenu, ArgMenuItem, useArgModalContext } from 'src/components/basic';
import { DeleteGroupModal } from './single-group-delete-modal';
import { EditGroupModal } from './edit-group-modal';
import { Group } from 'src/model/user';
import { UsersAndGroupsStateContext } from 'src/settings/users/providers/usersState';

interface GroupActionsMenuProps {
    group: Group;
}

const messages = defineMessages({
    edit: {
        id: 'settings.groups.menu.edit',
        defaultMessage: 'Edit',
    },
    // addRole: {
    //     id: 'settings.groups.menu.add-a-role',
    //     defaultMessage: 'Add a role',
    // },
    // addToGroup: {
    //     id: 'settings.groups.menu.add-to-group',
    //     defaultMessage: 'Add to group',
    // },
    // removeFromGroup: {
    //     id: 'settings.groups.menu.import',
    //     defaultMessage: 'Remove from group',
    // },
    delete: {
        id: 'settings.groups.menu.delete',
        defaultMessage: 'Delete',
    },
    // export: {
    //     id: 'settings.groups.menu.export',
    //     defaultMessage: 'Export',
    // },
});

export function GroupActionsMenu({ group }: GroupActionsMenuProps) {
    const [visible, setVisible] = useState(false);
    const { users, groups, allRoles, setGroups } = useContext(UsersAndGroupsStateContext);
    const modalContext = useArgModalContext();

    const actionsMenu = (
        <ArgMenu>
            <ArgMenuItem
                key={messages.edit.id}
                data-testid='edit'
                onClick={() => {
                    setVisible(false);
                    modalContext.open('settings-edit-group-modal',
                        <EditGroupModal
                            closeModal={() => modalContext.close('settings-edit-group-modal')}
                            group={group}
                            groups={groups}
                            users={users}
                            userRoles={allRoles}
                            setGroups={setGroups}
                        />,
                    );
                }}
            >
                <span>
                    <FormattedMessage {...messages.edit} />
                </span>
            </ArgMenuItem>
            <ArgMenuItem
                key={messages.delete.id}
                data-testid='delete'
                onClick={() => {
                    setVisible(false);
                    modalContext.open('settings-delete-group-modal',
                        <DeleteGroupModal
                            closeModal={() => modalContext.close('settings-delete-group-modal')}
                            group={group}
                        />,
                    );
                }}
            >
                <span>
                    <FormattedMessage {...messages.delete} />
                </span>
            </ArgMenuItem>
        </ArgMenu>
    );

    return (
        <ArgButton
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            data-testid='actions-users-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
        />
    );
}
