import { defineMessages } from 'react-intl';
import React, { lazy, Suspense } from 'react';
import { RouteProps } from 'react-router';
import { Navigate, Route } from 'react-router-dom';

import { ArgonosModulesRegistry } from '../components/application/argonos-modules-registry';
import { Environment } from '../utils/environment';
import { COLLECT_ROOT_URL } from '../components/containers/routes-urls';
import { LoadingSuspense } from '../components/containers/loading-suspense';
import { ArgonosModule } from '../components/application/modules';
import { getDataCollectionApi } from '../utils/connectors/api-url';
import { ArgonosModuleRoutesProps } from 'src/components/containers/argonos-modules-routes';

const LazyCollectRouter = lazy(() => import('./utils/routing/collect-router'));

const FORCE_SUSPENSE_LOADING = false;

const DISABLE_DATA_COLLECT = true;

const messages = defineMessages({
    dataCollectApplication: {
        id: 'common.applications.DataCollect',
        defaultMessage: 'Data Collection',
    },
});

function CollectApp(props: ArgonosModuleRoutesProps) {
    const { className } = props;
    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense />;
    }

    return (
        <Suspense fallback={<LoadingSuspense />}>
            <LazyCollectRouter
                className={className}
            />
        </Suspense>
    );
}

const DATA_COLLECTION_MODULE: ArgonosModule = {
    id: 'chapsVision.DataCollection',
    subType: 'DataCollect',

    routeURL: COLLECT_ROOT_URL,
    enabled: DISABLE_DATA_COLLECT ? false : Environment.isDatCollectEnabled(),
    apiURL: getDataCollectionApi(),

    requiredPermissions: [],
    iconURL: 'icon-shrink',
    name: messages.dataCollectApplication,
    documentationURL: Environment.docServerUrlDataCollect,
    scope: 'collect',

    getRoutes(): React.ReactElement<RouteProps>[] {
        return [
            <Route
                key='collect'
                path='collect'
                element={<Navigate to='./folders' replace={true} />}
            />,
            <Route
                key='collect/*'
                path='collect/*'
                element={(
                    <CollectApp argonosModule={DATA_COLLECTION_MODULE} />
                )}
            />,
        ];
    },
};

ArgonosModulesRegistry.getInstance().register(DATA_COLLECTION_MODULE);
