import { forEach } from 'lodash';
import { MessageDescriptor } from 'react-intl';

import { ArgMessageValues } from '../types';

const PAGE_CONFIG_FIELD_NAMES: (keyof PageConfig)[] = ['pageTitle', 'pageSubTitle', 'messageValues', 'iconURL'];

export interface PageConfig {
    pageTitle?: string | MessageDescriptor;
    pageSubTitle?: string | MessageDescriptor;
    messageValues?: ArgMessageValues;
    subTitlePosition?: 'start' | 'end';
    language?: string;

    iconURL?: string;
}

export function cloneConfig(source: PageConfig) {
    const config: PageConfig = {};
    forEach(PAGE_CONFIG_FIELD_NAMES, (key) => {
        const value = source[key];
        if (value === undefined) {
            return;
        }

        (config as any)[key] = value;
    });

    return config;
}
