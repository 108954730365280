import { ReactNode } from 'react';

import { ArgMessageRenderer, ArgRenderedText, useClassNames } from 'src/components/basic';

import './form-element-with-label.less';

export interface FormElementWithLabelProps {
    name: ArgRenderedText;
    children: ReactNode;
    highlighted?: boolean;
    disableHover?: boolean;
}

export function FormElementWithLabel(props: FormElementWithLabelProps) {
    const { name, children, highlighted, disableHover } = props;

    const classNames = useClassNames('form-element-with-label');

    return (
        <div className={classNames('&', { highlighted, hover: !disableHover })}>
            <ArgMessageRenderer message={name} className={classNames('&-label')} />
            <div className={classNames('&-content')}>
                {children}
            </div>
        </div>
    );
}
