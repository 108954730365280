import Debug from 'debug';

import { BaseConnector } from '../../../utils/connectors/base-connector';
import { CaseId } from '../../../model/folder-case-piece';
import { ProgressMonitor, SubProgressMonitor } from '../../../components/basic';
import { CasePieceType } from '../../../model/case-piece-type';
import { ConnectorRequestInit, ETaggedObject, isNotChangedEtagError } from '../../../utils/connector';
import { getDataExplorationApi } from '../../../utils/connectors/api-url';
import { VertexId, VertexInfo } from '../../model/vertex';
import { UniverseId, UniverseVertexTypeName } from '../../model/universe';
import { FormCasePiece, FormId } from '../../model/form';
import { isFormCasePiece } from '../case-utils';
import { mapDate, registerCasePieceType } from '../../../utils/connectors/mappers';
import { ExplorationPieceConnector } from './exploration-pieces-connector';

const debug = Debug('argonode:exploration:utils:FormsConnector');

export class FormsConnector extends BaseConnector {
    private static instance: FormsConnector;

    static getInstance(): FormsConnector {
        if (!FormsConnector.instance) {
            FormsConnector.instance = new FormsConnector('form.chart', getDataExplorationApi());
        }

        return FormsConnector.instance;
    }


    async createForm(
        caseId: CaseId,
        origin: string,
        vertexInfo: VertexInfo,
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<FormCasePiece> {
        const url = '/forms';
        const json = {
            name: `Form_ObjectId_${vertexInfo.id}` /* Do not set vertex title as name, it could be a sensitive data not readable for everybody */,
            //            origin,
            vertexId: vertexInfo.id,
            vertexType: vertexInfo.type,
            universeId,
        };

        const response = await this.request(
            url,
            {
                method: 'POST',
                json,
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        const form = mapRawFormToForm(response);

        const { id: formId } = form;

        // To be able to open the form even if the form couldn't be added to the folder
        // To make the reader capable of openning a form
        try {
            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await ExplorationPieceConnector.getInstance().addPiece(caseId, formId, CasePieceType.Form, sub2);
        } catch (error) {
            console.error('Failed to add form to folder');
        }


        return form;
    }

    async updateForm(
        formId: FormId,
        name: string,
        origin: string,
        vertexId: VertexId,
        vertexType: UniverseVertexTypeName,
        universeId: UniverseId,
        progressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/forms/${encodeURIComponent(formId)}`;
        const json = {
            name: name || null,
            //            origin,
            vertexId,
            vertexType,
            universeId,
        };

        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        await this.request(
            url,
            {
                method: 'PUT',
                json,
                verifyJSONResponse: true,
            },
            sub1,
        );
    }

    async getFormCasePiece(caseId: CaseId, casePieceId: FormId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<FormCasePiece> {
        const ret = await ExplorationPieceConnector.getInstance().getPiece(caseId, casePieceId, true, true, progressMonitor);

        if (!isFormCasePiece(ret)) {
            throw new Error('Invalid form piece');
        }

        return ret;
    }

    async getForm(formId: FormId, previousForm?: FormCasePiece, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<FormCasePiece> {
        const url = `/forms/${encodeURIComponent(formId)}`;

        const options: ConnectorRequestInit = {
            etag: (previousForm as ETaggedObject)?.etag,
        };

        let response;
        try {
            response = await this.request(url, options, progressMonitor);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            if (isNotChangedEtagError(error)) {
                return previousForm!;
            }
            throw error;
        }

        const form = mapRawFormToForm(response);

        return form;
    }


    async visitCaseForm(
        caseId: CaseId,
        formId: FormId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        await ExplorationPieceConnector.getInstance().visitPiece(caseId, formId, progressMonitor);
    }

    async deleteForm(
        formId: FormId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/forms/${encodeURIComponent(formId)}`;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async deleteForms(
        formIds: FormId[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const sub = new SubProgressMonitor(progressMonitor, formIds.length);
        const ps = formIds.map((formId: FormId) => {
            const sub1 = new SubProgressMonitor(sub, 1);

            const p = this.deleteForm(formId, sub1);

            return p;
        });

        await Promise.all(ps);
    }
}

function mapRawFormToForm(raw: any): FormCasePiece {
    const ret: FormCasePiece = {
        type: CasePieceType.Form,

        id: raw.id,

        displayName: raw.name,
        description: raw.description,

        createdBy: raw.createdBy,
        createdDate: mapDate(raw.createdDate),

        lastUpdatedBy: raw.lastUpdatedBy,
        lastUpdatedDate: mapDate(raw.lastUpdatedDate),

        universeId: raw.universeId,
        vertexInfo: {
            id: raw.vertexId,
            type: raw.vertexType,
        },
    };

    return ret;
}

registerCasePieceType('form', CasePieceType.Form, (ret: FormCasePiece, entityProperties: Record<string, any> | undefined) => {
    ret.universeId = entityProperties?.universeId || entityProperties?.universe?.id;
    if (!ret.universeId) {
        console.warn('No universeId defined for form #', ret.id);
    }

    ret.vertexInfo = {
        id: entityProperties?.vertexId || entityProperties?.vertex?.id,
        type: entityProperties?.vertexType || entityProperties?.vertex?.type,
    };

    if (!ret.vertexInfo.id || !ret.vertexInfo.type) {
        console.warn('No vertexId/vertexType defined for form #', ret.id);
    }
});
