import { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ArgMenu, ArgMenuItem, ArgMenuItemDivider, ProgressMonitor, useArgModalContext, useClassNames } from '../../../../../components/basic';
import { Template } from '../../../../../model/template';
import { TemplateActions } from './template-actions';
import { ConfirmModal } from '../../../../../components/common/modal2/confirm-modal/confirm-modal';
import { NamingModal, messages as namingModalMessages } from '../../../../../components/common/modal2/naming-modal/naming-modal';

const CLASSNAME = 'template-actions-menu-popup';

const messages = defineMessages({
    rename: {
        id: 'settings.templates.actions.rename.title',
        defaultMessage: 'Rename',
    },
    delete: {
        id: 'settings.templates.actions.remove.title',
        defaultMessage: 'Delete',
    },
    templateDeleteConfirmModalTitle: {
        id: 'settings.templates.actions.remove.confirm.modal.title',
        defaultMessage: 'Delete the template',
    },
    templateDeleteConfirmModalMessage: {
        id: 'settings.templates.actions.remove.confirm.modal.message',
        defaultMessage: 'Do you really want to delete template "{templateDisplayName}"?',
    },
    namingModalTitle: {
        id: 'settings.templates.actions.rename.modal.title',
        defaultMessage: 'Rename template',
    },
    namingModalLabel: {
        id: 'settings.templates.actions.rename.modal.label',
        defaultMessage: 'Template name',
    },
});

interface TemplateActionsMenuPopupProps<TContent> {
    template: Template<TContent>;
    setVisible: (visible: boolean) => void;
    onDeleteTemplateConfirm?: (progressMonitor: ProgressMonitor, template: Template<TContent>) => Promise<void>;
    onRenameTemplateConfirm?: (progressMonitor: ProgressMonitor, template: Template<TContent>, newName: string) => Promise<void>;
}

export function TemplateActionsMenuPopup<TContent>(props: TemplateActionsMenuPopupProps<TContent>) {
    const {
        template,
        setVisible,
        onDeleteTemplateConfirm,
        onRenameTemplateConfirm,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const modalContainer = useArgModalContext();

    const _onDeleteTemplateConfirm = useCallback(async (progressMonitor: ProgressMonitor) => {
        return await onDeleteTemplateConfirm?.(progressMonitor, template);
    }, [onDeleteTemplateConfirm, template]);

    const _onRenameTemplateConfirm = useCallback(async (newName: string, progressMonitor: ProgressMonitor) => {
        return await onRenameTemplateConfirm?.(progressMonitor, template, newName);
    }, [onRenameTemplateConfirm, template]);

    const handleItemActionsMenuClick = useCallback(async (key: TemplateActions) => {
        switch (key) {
            case TemplateActions.Delete:
                modalContainer.open('template-confirm-remove', <ConfirmModal
                    type='delete'
                    onClose={() => {
                        modalContainer.close('template-confirm-remove');
                    }}
                    title={messages.templateDeleteConfirmModalTitle}
                    alertMessage={messages.templateDeleteConfirmModalMessage}
                    messageValues={{ templateDisplayName: template.name }}
                    onConfirm={_onDeleteTemplateConfirm}
                />);
                break;
            case TemplateActions.Rename:
                modalContainer.open('template-rename', <NamingModal
                    onClose={() => {
                        modalContainer.close('template-rename');
                    }}
                    title={messages.namingModalTitle}
                    label={messages.namingModalLabel}
                    confirmText={namingModalMessages.rename}
                    initialValue={template.name}
                    onSubmit={_onRenameTemplateConfirm}
                />);

                break;
            default:
                break;
        }

        setVisible(false);
    }, [setVisible, modalContainer, template.name, _onDeleteTemplateConfirm, _onRenameTemplateConfirm]);

    return (
        <ArgMenu
            className={classNames('&-menu')}
            data-testid='actions-button-menu'
            onClick={param => handleItemActionsMenuClick(param.key as TemplateActions)}
        >
            {/* Rename */}
            {onRenameTemplateConfirm &&
                <ArgMenuItem
                    key={TemplateActions.Rename}
                    data-testid='menu-item-rename'
                    className={classNames('&-menu-item', 'rename')}
                    icon='icon-pencil'
                    label={messages.rename}
                />}

            {onDeleteTemplateConfirm &&
                <>
                    {/* Divider */}
                    {onRenameTemplateConfirm && <ArgMenuItemDivider />}
                    {/* Remove */}
                    <ArgMenuItem
                        key={TemplateActions.Delete}
                        data-testid='menu-item-remove'
                        className={classNames('&-menu-item', 'remove')}
                        icon='icon-trash'
                        label={messages.delete} />
                </>}
        </ArgMenu>
    );
}
