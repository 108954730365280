import { getDataExplorationApi } from '../../utils/connectors/api-url';
import { RtApi, RtApiSubType } from '../../utils/rt-states/rt-api';
import { EXPLORATION_ENTITY_ALL_EVENTS, UNIVERSE_ALL_EVENTS } from './events';
import { FOLDERS_EVENTS } from '../../utils/rt-states/folders/events';
import { USER_ALL_EVENTS } from '../../utils/rt-states/events';
import { EXPLORATION_EDITOR_EVENTS } from './editor-state';
import { PROGRESS_MONITORS_EVENT_LIST } from '../../utils/rt-states/progress-monitors/progressMonitor-state';

let dataExplorationEntitiesRtApi: RtApi | undefined = undefined;

export function getDataExplorationEntitiesRtApi(): RtApi {
    if (dataExplorationEntitiesRtApi) {
        return dataExplorationEntitiesRtApi;
    }

    const api = getDataExplorationApi();
    if (!api) {
        throw new Error('DataExplorationAPI is not defined');
    }

    dataExplorationEntitiesRtApi = {
        argonosModuleId: 'chapsVision.DataExploration',
        subType: RtApiSubType.Entities,
        api,
        defaultServiceUrl: '/casePieces',
        events: EXPLORATION_ENTITY_ALL_EVENTS,
    };

    return dataExplorationEntitiesRtApi;
}

let dataExplorationFoldersRtApi: RtApi | undefined = undefined;

export function getDataExplorationFoldersRtApi(): RtApi {
    if (dataExplorationFoldersRtApi) {
        return dataExplorationFoldersRtApi;
    }

    const api = getDataExplorationApi();
    if (!api) {
        throw new Error('DataExplorationAPI is not defined');
    }

    dataExplorationFoldersRtApi = {
        argonosModuleId: 'chapsVision.DataExploration',
        subType: RtApiSubType.Folders,
        api,
        defaultServiceUrl: '/cases',
        events: FOLDERS_EVENTS,
    };

    return dataExplorationFoldersRtApi;
}

let dataExplorationUniversesRtApi: RtApi | undefined = undefined;

export function getDataExplorationUniversesRtApi(): RtApi {
    if (dataExplorationUniversesRtApi) {
        return dataExplorationUniversesRtApi;
    }

    const api = getDataExplorationApi();
    if (!api) {
        throw new Error('DataExplorationAPI is not defined');
    }

    dataExplorationUniversesRtApi = {
        argonosModuleId: 'chapsVision.DataExploration',
        subType: RtApiSubType.Universes,
        api,
        defaultServiceUrl: '/universes/{universeId}',
        events: UNIVERSE_ALL_EVENTS,
    };

    return dataExplorationUniversesRtApi;
}

let dataExplorationUsersRtApi: RtApi | undefined = undefined;

export function getDataExplorationUsersRtApi(): RtApi {
    if (dataExplorationUsersRtApi) {
        return dataExplorationUsersRtApi;
    }

    const api = getDataExplorationApi();
    if (!api) {
        throw new Error('DataExplorationAPI is not defined');
    }

    dataExplorationUsersRtApi = {
        argonosModuleId: 'chapsVision.DataExploration',
        subType: RtApiSubType.Users,
        api,
        defaultServiceUrl: '/users',
        events: USER_ALL_EVENTS,
    };

    return dataExplorationUsersRtApi;
}

let dataExplorationEditorsRtApi: RtApi | undefined = undefined;

export function getDataExplorationEditorsRtApi(): RtApi {
    if (dataExplorationEditorsRtApi) {
        return dataExplorationEditorsRtApi;
    }

    const api = getDataExplorationApi();
    if (!api) {
        throw new Error('DataExplorationAPI is not defined');
    }

    dataExplorationEditorsRtApi = {
        argonosModuleId: 'chapsVision.DataExploration',
        subType: RtApiSubType.Editors,
        api,
        defaultServiceUrl: '/entities/{entityId}/users/{userId}/editors/{editorId}', // Must be customized by state object
        events: EXPLORATION_EDITOR_EVENTS,
    };

    return dataExplorationEditorsRtApi;
}

let dataExplorationProgressMonitorsRtApi: RtApi | undefined = undefined;

export function getDataExplorationProgressMonitorsRtApi(): RtApi {
    if (dataExplorationProgressMonitorsRtApi) {
        return dataExplorationProgressMonitorsRtApi;
    }

    const api = getDataExplorationApi();
    if (!api) {
        throw new Error('DataExplorationAPI is not defined');
    }

    dataExplorationProgressMonitorsRtApi = {
        argonosModuleId: 'chapsVision.DataExploration',
        subType: RtApiSubType.ProgressMonitors,
        api,
        defaultServiceUrl: '/progressMonitors',
        events: PROGRESS_MONITORS_EVENT_LIST,
    };

    return dataExplorationProgressMonitorsRtApi;
}
