import { chain, isFunction } from 'lodash';
import React, { ErrorInfo, KeyboardEvent, MouseEvent, useCallback, useMemo } from 'react';
import { isElement } from 'react-is';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ToolContext, ToolTreeNode } from './tool-context';
import { ArgTabs, OnArgTabsChangeHandler } from '../arg-tabs/arg-tabs';
import { ArgTabsSubLevel } from '../arg-tabs-sub-level/arg-tabs-sub-level';
import { ArgTab, ArgTabKey, ArgTabMenuItem, ArgTabMenuItems } from '../arg-tabs/arg-tabs-types';
import { useToolNodes } from './use-tool-nodes';
import { getToolDescription, getToolIcon, getToolLabel, getToolNavigateTo, isToolDisabled } from './tool';
import { isMessageDescriptor } from '../utils/is-message-descriptor';
import { ArgErrorCatcher } from '../arg-error/arg-error-catcher';
import { ArgonosError, INVALID_SESSION } from '../utils/argonos-error';
import { ErrorPane } from '../../common/panes/error-pane';
import { ArgRenderFunction } from '../types';

import './arg-context-tabs.less';

const messages = defineMessages({
    errorInPane: {
        id: 'basic.arg-context-tabs.ErrorInPane',
        defaultMessage: 'An error occurred, please close the panel.',
    },
});

interface ArgContextTabsProps<T> {
    className?: ClassValue;
    bodyClassName?: ClassValue;

    toolContext: ToolContext<T>;
    environmentContext: T;
    type?: 'primary' | 'subLevel';

    activeTabKey?: ArgTabKey;
    defaultActiveTabKey?: ArgTabKey;

    onChange: OnArgTabsChangeHandler;
    noTabsRender?: ArgRenderFunction;
}

export function ArgContextTabs<T = undefined>(props: ArgContextTabsProps<T>) {
    const {
        className,
        bodyClassName,
        toolContext,
        environmentContext,
        type = 'primary',
        onChange,
        activeTabKey,
        defaultActiveTabKey,
        noTabsRender,
    } = props;

    const [toolTreeNodes, toolTreeContext] = useToolNodes(toolContext, environmentContext);

    const classNames = useClassNames('arg-context-tabs');

    const handleRenderError = useCallback((error: Error, errorInfo: ErrorInfo|undefined, tool: ToolTreeNode<T>) => {
        console.error('----------------------------------');
        console.error('error=', error);
        console.error('errorInfo=', errorInfo);
        console.error('tool=', tool);

        if ((error as ArgonosError)?.code === INVALID_SESSION) {
            return <div className={classNames('&-content-error')}>
                <ErrorPane error={error} />
            </div>;
        }

        return <div className={classNames('&-content-error')} data-testid='error-pane'>
            <ErrorPane error={error}>
                <FormattedMessage {...messages.errorInPane} />
            </ErrorPane>
        </div>;
    }, [classNames]);

    const tabs = useMemo<ArgTab[]>(() => {
        const result = chain(toolTreeNodes).map((toolTreeNode: ToolTreeNode<T>) => {
            if (toolTreeNode.type !== 'panel' || !isFunction(toolTreeNode.panelRender)) {
                console.warn('Ignore tab', toolTreeNode);

                return undefined;
            }

            let menu: ArgTabMenuItems | undefined = undefined;
            if (toolTreeNode.children) {
                menu = chain(toolTreeNode.children).reduce((acc, toolTreeNode) => {
                    if (toolTreeNode.type === 'button') {
                        const tabMenuItem: ArgTabMenuItem = {
                            key: toolTreeNode.path,
                            icon: getToolIcon(toolTreeNode, environmentContext),
                            label: getToolLabel(toolTreeNode, environmentContext),
                            tooltip: (isElement(toolTreeNode.tooltip) || isMessageDescriptor(toolTreeNode.tooltip)) ? toolTreeNode.tooltip : undefined,
                            onClick: (event: MouseEvent | KeyboardEvent) => toolTreeNode.onClick?.(toolTreeNode, environmentContext, event),
                            disabled: isToolDisabled(toolTreeNode, environmentContext),
                        };
                        acc[toolTreeNode.path] = tabMenuItem;
                    }

                    return acc;
                }, {} as Record<string, ArgTabMenuItem>).value();
            }

            const tab: ArgTab = {
                key: toolTreeNode.path,
                children: () => {
                    return <ArgErrorCatcher renderError={(error, errorInfo) => handleRenderError(error, errorInfo, toolTreeNode)}>
                        {toolTreeNode.panelRender!(toolTreeNode, environmentContext)}
                    </ArgErrorCatcher>;
                },
                title: getToolLabel(toolTreeNode, environmentContext),
                description: getToolDescription(toolTreeNode, environmentContext),
                menu,
                activeKeyBinding: toolTreeNode.keyBinding,
                navigateTo: getToolNavigateTo(toolTreeNode, environmentContext),
            };

            return tab;
        }).compact().value();

        return result;
    }, [environmentContext, toolTreeNodes]);

    if (type === 'subLevel') {
        return <ArgTabsSubLevel
            className={className}
            bodyClassName={bodyClassName}
            onChange={onChange}
            activeTabKey={activeTabKey}
            defaultActiveTabKey={defaultActiveTabKey}
            tabs={tabs}
            noTabsRender={noTabsRender}
        />;
    }

    return <ArgTabs
        className={className}
        bodyClassName={bodyClassName}
        onChange={onChange}
        activeTabKey={activeTabKey}
        defaultActiveTabKey={defaultActiveTabKey}
        tabs={tabs}
    />;
}
