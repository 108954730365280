import { computeEntityStateURL, EntityState } from './entity-state';
import { EntityId } from '../../../model/argonos-piece';
import { ENTITY_SETTINGS_EVENTS } from './events';
import { RtApi } from '../rt-api';

export function computeEntitySettingsStateURL(entityId: EntityId) {
    const pieceStateURL = computeEntityStateURL(entityId);

    return `${pieceStateURL}/settings`;
}

export class EntitySettingsState extends EntityState {
    constructor(api: RtApi, url: string, entityId: EntityId) {
        super(api, url, entityId, ENTITY_SETTINGS_EVENTS);
    }

    settingsChanged(settings: Record<string, any>) {
        this.change('settings', settings);
    }
}
