import React, { useCallback, useMemo } from 'react';
import { MessageDescriptor } from 'react-intl';

import { useClassNames } from '../arg-hooks/use-classNames';
import { ToolTreeNode } from '../arg-tools/tool-context';
import { getToolIcon, getToolLabel, getToolTooltip, isToolDisabled, isToolSelected, isToolVisible, Tool } from '../arg-tools/tool';
import { renderText } from '../utils/message-descriptor-formatters';
import {
    ArgActionDropdownButton,
    ArgActionDropdownButtonAction,
    ArgActionKey,
} from '../arg-action-dropdown-button/arg-action-dropdown-button';
import { ArgChangeReason } from '../types';
import { ButtonClickEvent } from '../arg-button/arg-button';

const NO_ACTIONS: ArgActionDropdownButtonAction[] = [];

interface ArgToolbarActionDropdownProps<T> extends ToolTreeNode<T> {
    environmentContext: T;
}

export function ArgToolbarActionDropdown<T>(props: ArgToolbarActionDropdownProps<T>) {
    const {
        className,
        children,
        tooltip,
        keyBinding,
        onClick,
        tooltipPlacement,
        buttonType,
        environmentContext,
    } = props;

    const classNames = useClassNames('arg-toolbar-action-dropdown');

    const computeItemLabel = useCallback((toolItem: Tool<T>) => {
        const { label, keyBinding } = toolItem;
        if (label !== undefined) {
            return renderText(getToolLabel(toolItem, environmentContext));
        }

        if (keyBinding) {
            return keyBinding.name;
        }

        return undefined;
    }, [environmentContext]);

    const handleToolClick = useCallback(async (tool: Tool<T>, reason: ArgChangeReason, event?: ButtonClickEvent): Promise<void> => {
        await tool.onClick?.(tool, environmentContext, event);
    }, [environmentContext]);

    const handleClick = useCallback((event: ButtonClickEvent) => {
        onClick?.(props, environmentContext, event);
    }, [environmentContext, onClick, props]);

    const { defaultActionKey, actions } = useMemo<{
        defaultActionKey: ArgActionKey | undefined;
        actions: ArgActionDropdownButtonAction[];
    }>(() => {
        const _children = children?.filter((child) => {
            if (!isToolVisible(child, environmentContext)) {
                return false;
            }

            return true;
        });

        if (!_children?.length) {
            return { defaultActionKey: undefined, actions: NO_ACTIONS };
        }

        const result: ArgActionDropdownButtonAction[] = [];
        let defaultActionKey: ArgActionKey | undefined = undefined;

        function renderMenuItems(children: ToolTreeNode<T>[]) {
            children.forEach((node: ToolTreeNode<T>) => {
                const { path, type } = node;

                if (type !== 'button') {
                    console.error('*** Unsupported type=', type, 'in menuItem for tool=', node);

                    return;
                }

                if (isToolSelected(node, environmentContext)) {
                    defaultActionKey = path;
                }

                const action: ArgActionDropdownButtonAction = {
                    key: path,
                    label: computeItemLabel(node),
                    isDisabled: isToolDisabled(node, environmentContext),
                    tooltip: getToolTooltip(node, environmentContext),
                    onClick: (event) => handleToolClick(node, 'selection', event),
                };

                result.push(action);
            });
        }

        renderMenuItems(_children);

        return {
            defaultActionKey,
            actions: result,
        };
    }, [children, computeItemLabel, handleToolClick, environmentContext]);

    let _tooltip: MessageDescriptor | React.ReactNode = null;
    if (tooltip === undefined && keyBinding) {
        _tooltip = keyBinding.name;
    } else {
        _tooltip = getToolTooltip(props, environmentContext);
    }

    const _disabled = isToolDisabled(props, environmentContext);

    return <ArgActionDropdownButton
        className={classNames('&', className, 'arg-toolbar-item')}
        icon={getToolIcon(props, environmentContext)}
        label={getToolLabel(props, environmentContext)}
        tooltip={_tooltip}
        tooltipPlacement={tooltipPlacement}
        keyBinding={keyBinding}
        actions={actions}
        defaultActionKey={defaultActionKey}
        disabled={_disabled}
        type={buttonType}
        onClick={handleClick}
    />;
}
