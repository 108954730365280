import { useCallback, useEffect, useRef } from 'react';
import { debounce, DebouncedFunc } from 'lodash';
import { MessageDescriptor } from 'react-intl';

import { useProgressMonitor } from '../progress-monitors/use-progress-monitor';
import { ProgressMonitor, ProgressMonitorOptions } from '../progress-monitors/progress-monitor';
import { isPromise } from '../utils/promise';

export type DebounceEngine = (func: (progressMonitor: ProgressMonitor) => Promise<void> | void) => void;

export type CancelDebounce = () => void;

const DEFAULT_PROGRESSMONITOR_NAME = 'useDebounce';

export function useDebounce(
    waitMs: number,
    progressMonitorName: string | MessageDescriptor = DEFAULT_PROGRESSMONITOR_NAME,
    progressMonitorTaskCount = 1,
    progressMonitorOptions?: ProgressMonitorOptions,
    cancelPrevious = true,
): [DebounceEngine, CancelDebounce] {
    const debounceFunc = useRef<DebouncedFunc<any>>();

    const [progressMonitor, createProgressMonitor] = useProgressMonitor(cancelPrevious);

    useEffect(() => {
        return () => {
            if (debounceFunc.current) {
                debounceFunc.current.cancel();
            }
        };
    }, []);


    const ret = useCallback((func: (progressMonitor: ProgressMonitor) => void) => {
        if (cancelPrevious && debounceFunc.current) {
            debounceFunc.current.cancel();
        }

        const fct = debounce(() => {
            const progressMonitor = createProgressMonitor(progressMonitorName, progressMonitorTaskCount, progressMonitorOptions);

            let ret;
            try {
                ret = func(progressMonitor);
            } catch (error) {
                console.error(error);
                progressMonitor.done();

                return;
            }

            if (isPromise(ret)) {
                ret.finally(() => {
                    progressMonitor.done();
                });

                return;
            }

            progressMonitor.done();
        }, waitMs);
        debounceFunc.current = fct;

        fct();
    }, [cancelPrevious, createProgressMonitor, progressMonitorName, progressMonitorOptions, progressMonitorTaskCount, waitMs]);

    const cancelDebounce = useCallback(() => {
        if (!debounceFunc.current) {
            return;
        }
        debounceFunc.current.cancel();
        debounceFunc.current = undefined;

        progressMonitor?.cancel();
    }, [progressMonitor]);

    return [ret, cancelDebounce];
}
