import { Link } from 'react-router-dom';

import { useArgonosModuleBranding } from 'src/components/application/argonos-modules-branding';
import { ArgonosModule } from 'src/components/application/modules';
import { ArgIcon, ArgMessageRenderer, useClassNames } from 'src/components/basic';

import './application-button.less';


const FORCE_LOADING = false;

export interface ApplicationButtonProps {
    argonosModule: ArgonosModule;
    selected?: boolean;
}

export function ApplicationButton(props: ApplicationButtonProps) {
    const {
        argonosModule,
        selected,
    } = props;

    const classNames = useClassNames('common-application-button');

    const [moduleBranding, loading] = useArgonosModuleBranding(argonosModule.id);

    const label = moduleBranding.brandingName;
    const icon = moduleBranding.brandingLogoURL || moduleBranding.brandingIconURL;

    const _icon = icon && (
        icon.indexOf('/') >= 0
            ? <img src={icon} className={classNames('&-image')} />
            : <ArgIcon name={icon} />
    );

    if (loading?.isRunning || FORCE_LOADING) {
        return (
            <div className={classNames('&', { selected })}>
                <div className={classNames('&-image-skeleton')} />
                <div className={classNames('&-label-skeleton')} />
            </div>
        );
    }

    return (
        <Link
            to={argonosModule.routeURL}
            className={classNames('&', { selected })}
        >
            <>
                <div className={classNames('&-icon')}>
                    {_icon}
                </div>
                <ArgMessageRenderer className={classNames('&-label')} message={label} />
            </>
        </Link>
    );
}
