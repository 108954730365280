import { isEmpty, isString, map } from 'lodash';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';

import {
    ArgButton,
    ArgCheckboxMinus,
    ArgDivider,
    ArgInputSearch,
    ArgUploaderButton,
    ClassValue,
    DndAction,
    Droppable,
    DroppingZone,
    highlightSplit,
    ProgressMonitor,
    renderText,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { KeyBindingEditor } from '../../../../components/features/keybindings-panel/keybinding-editor';
import { useHasPermission } from '../../../../contexts/user-permission';
import { BriefTemplates, DisplayMode } from '../../../../exploration/features/templates/brief-template-list';
import { useBriefTemplateList } from '../../../../exploration/features/templates/use-brief-templates';
import { BriefTemplate, TemplateType } from '../../../../exploration/model/template';
import { TemplateId } from '../../../../model/template';
import { Environment } from '../../../../utils/environment';
import { downloadBlob } from '../../../../utils/file';
import { ConfigurationOption, ImportExportOptions, SynchronizationAction } from '../../../../model/configuration';
import { useKeyBindings } from './hooks/use-key-binding/use-key-bindings';
import { ConfigurationsScope, ConfigurationType } from '../../configuration-type';
import {
    ExpressionLanguageChoice,
} from '../../../../preparation/process/expression-language/expression-language-choice';
import {
    DEFAULT_EXPRESSION_LANGUAGE,
    EXPRESSION_LANGUAGE_CONFIG_PATH,
    ExpressionLanguage,
    SUPPORTED_EXPRESSION_LANGUAGES,
} from 'src/preparation/process/expression-language/constants';
import { useApplicationConfiguration } from '../../../../hooks/use-application-configuration';
import { SettingsPermissions } from '../../../permissions/permissions';
import {
    ConfigurationErrorNotificationDescription,
} from '../configuration-error-notification-description/configuration-error-notification-description';
import { ConfigurationConnector } from '../../../../utils/connectors/configuration-connector';

import './system-configuration.less';

const SUPPORTED_IMPORT_MIME_TYPES = [
    'application/x-zip-compressed', 'application/zip',
];
const FILE_TYPE = 'Files';

const CLASSNAME = 'settings-system-configuration';
const USER_CONFIGURATION_KEYBINDING_KEY = 'ui.KEY_BINDINGS';

const messages = defineMessages({
    searchPlaceholder: {
        id: 'settings.system-configuration.searchPlaceholder',
        defaultMessage: 'Search',
    },
    importLabel: {
        id: 'settings.system-configuration.importLabel',
        defaultMessage: 'Import',
    },
    importSucceed: {
        id: 'settings.system-configuration.ImportSucceed',
        defaultMessage: 'Configurations have successfully been imported',
    },
    importConfigurationsError: {
        id: 'settings.system-configuration.importConfigurationsError',
        defaultMessage: 'An error occurred while importing the configurations',
    },
    importConfigurationDescriptionError: {
        id: 'settings.system-configuration.importConfigurationDescriptionError',
        defaultMessage: '{count, plural, =1 {1 error detected } other {{count} errors detected}}',
    },
    invalidType: {
        id: 'settings.system-configuration.InvalidType',
        defaultMessage: 'Invalid file',
    },
    invalidConfigurationType: {
        id: 'settings.system-configuration.invalidConfigurationType',
        defaultMessage: 'Invalid configuration type (you can only import brief template and key bindings)',
    },
    keyBindingsTitle: {
        id: 'settings.system-configuration.keyBindingsTitle',
        defaultMessage: 'Key bindings',
    },
    dropConfigurationtMessage: {
        id: 'settings.system-configuration.dropConfigurationtMessage',
        defaultMessage: 'Drop here',
    },
    export: {
        id: 'settings.system-configuration.export',
        defaultMessage: 'Export',
    },
    exportSucceed: {
        id: 'settings.system-configuration.exportSucceed',
        defaultMessage: 'Configurations have successfully been exported',
    },
    exportConfigurationsError: {
        id: 'settings.system-configuration.exportConfigurationsError',
        defaultMessage: 'An error occurred while exporting the configurations',
    },
    configurationsFilename: {
        id: 'settings.system-configuration.configurationsFilename',
        defaultMessage: 'configurations',
    },
    briefTemplatesTitle: {
        id: 'settings.system-configuration.briefTemplatesTitle',
        defaultMessage: 'Brief templates',
    },
    programmingLanguageTitle: {
        id: 'settings.system-configuration.ProgrammingLanguageTitle',
        defaultMessage: 'Programming language',
    },
    programmingLanguageDescription: {
        id: 'settings.system-configuration.ProgrammingLanguageDescription',
        defaultMessage: 'Select the programming language you want to use as default',
    },
});

export interface SystemConfigurationProps {
    className?:ClassValue;
    configurationScope:ConfigurationsScope;
    onImportUserConfig?:() => void;
}

interface SystemSection {
    id:SectionId;
    title:MessageDescriptor;
    content:ReactNode;
    handleAllCheckboxValue?:boolean | 'minus';
    onChangeAllCheckbox?:() => void;
}

enum SectionId {
    KeyBindingsSection = 'KeyBindingsSection',
    BriefSection = 'BriefSection',
    ProgrammingLanguageSection = 'ProgrammingLanguageSection'
}

export function SystemConfiguration(props:SystemConfigurationProps) {
    const { className, configurationScope, onImportUserConfig } = props;
    const classNames = useClassNames(CLASSNAME);

    const intl = useIntl();

    const notifications = useArgNotifications();

    const canImportExport = useHasPermission<SettingsPermissions>('admin.import.export.settings');

    const [defaultLanguage, setDefaultLanguage] = useApplicationConfiguration<ExpressionLanguage>(
        EXPRESSION_LANGUAGE_CONFIG_PATH,
        DEFAULT_EXPRESSION_LANGUAGE,
    );

    const [briefTemplateConfigurationsKeys, setBriefTemplateConfigurationsKeys] = useState<TemplateId[]>([]);
    const [keyBindingExport, setKeyBindingExport] = useState(false);
    const [programmingLanguageExport, setProgrammingLanguageExport] = useState(false);
    const [searchedToken, setSearchedToken] = useState<string>('');
    const areTemplatesEnabled = configurationScope === 'data_exploration';

    let {
        briefTemplateListState, // eslint-disable-line prefer-const
        searchStringChanged,
        deleteTemplate: deleteBriefTemplate,
        initialize: initializeBriefTemplates,
        renameTemplate: renameBriefTemplate,
    } = useBriefTemplateList(true, areTemplatesEnabled);

    if (!areTemplatesEnabled) {
        searchStringChanged = deleteBriefTemplate = initializeBriefTemplates = renameBriefTemplate = asyncNoop;
    }

    const {
        userConfig,
        filteredByScopes,
    } = useKeyBindings(searchedToken);

    const handleSearchedTokenChange = useCallback((searchedToken:string) => {
        setSearchedToken(searchedToken);
        searchStringChanged(searchedToken);
    }, [searchStringChanged]);

    const onImportSucceed = useCallback(async () => {
        notifications.snackInfo({ message: messages.importSucceed });
        onImportUserConfig?.();
    }, [notifications, onImportUserConfig]);

    const [loadImportedConfigurations] = useCallbackAsync(async (progressMonitor:ProgressMonitor, file:Blob) => {
        if (!file || !SUPPORTED_IMPORT_MIME_TYPES.includes(file.type)) {
            notifications.snackError({
                message: messages.invalidType,
            });

            throw new Error('Invalid type');
        }

        try {
            const manifest = await ConfigurationConnector.getInstance().importManifest(file, configurationScope, progressMonitor);

            const expectedTypes = [TemplateType.Brief, ConfigurationType.ApplicationSettings];
            const options = new Array<ConfigurationOption>();

            expectedTypes.forEach(expectedType => {
                const confType = manifest.configurations.find((conf) => conf.type === expectedType);
                if (confType) {
                    if (confType.type === ConfigurationType.ApplicationSettings) {
                        options.push({
                            type: ConfigurationType.ApplicationSettings,
                            configurationKeys: [USER_CONFIGURATION_KEYBINDING_KEY],
                            options: {
                                applicationId: Environment.appId,
                            },
                        });

                        return;
                    }
                    const configurationTypeKeys = confType.configurations.map((conf) => {
                        return conf.id;
                    });

                    if (configurationTypeKeys?.length) {
                        options.push({
                            type: expectedType,
                            configurationKeys: configurationTypeKeys,
                            options: {
                                setAsDefault: true,
                                resetDefaultConfiguration: false,
                            },
                        });
                    }
                }
            });

            if (!options.length) {
                notifications.snackError({
                    message: messages.invalidConfigurationType,
                });

                throw new Error();
            }

            const importOptions:ImportExportOptions = {
                options: options,
            };

            const result = await ConfigurationConnector.getInstance().importConfigurations(
                file,
                importOptions,
                SynchronizationAction.RenameConflicts,
                configurationScope,
                progressMonitor,
            );

            if (isEmpty(result) || !result.errors) {
                onImportSucceed();
                await initializeBriefTemplates();

                return;
            }
            const nbImportErrors = result.errors.length;

            notifications.notifError(
                {
                    message: messages.importConfigurationsError,
                    description: messages.importConfigurationDescriptionError,
                    details: (
                        <ConfigurationErrorNotificationDescription
                            errors={ result.errors }
                        />
                    ),
                },
                undefined,
                {
                    count: nbImportErrors,
                });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.importConfigurationsError }, error as Error);
            throw error;
        }
    }, [onImportSucceed, initializeBriefTemplates, notifications, configurationScope]);

    const [handleExportConfigurations] = useCallbackAsync(async (progressMonitor:ProgressMonitor) => {
        try {
            const options = new Array<ConfigurationOption>();

            if (briefTemplateConfigurationsKeys.length) {
                options.push({
                    type: TemplateType.Brief,
                    configurationKeys: briefTemplateConfigurationsKeys,
                    options: {},
                });
            }

            if (keyBindingExport) {
                options.push({
                    type: ConfigurationType.ApplicationSettings,
                    configurationKeys: [USER_CONFIGURATION_KEYBINDING_KEY],
                    options: {
                        applicationId: Environment.appId,
                    },
                });
            }

            if (programmingLanguageExport) {
                options.push({
                    type: ConfigurationType.Preparation,
                    configurationKeys: [EXPRESSION_LANGUAGE_CONFIG_PATH],
                    options: {
                        applicationId: Environment.appId,
                    },
                });
            }

            if (!options.length) {
                return;
            }

            const configurationListToExport:ImportExportOptions = {
                options: options,
            };

            const { blob } = await ConfigurationConnector.getInstance().exportConfigurations(configurationListToExport, configurationScope, progressMonitor);

            downloadBlob(`${intl.formatMessage(messages.configurationsFilename)}-${new Date().getTime()}.zip`, blob);

            notifications.snackInfo({ message: messages.exportSucceed });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.exportConfigurationsError }, error as Error);
            throw error;
        }
    }, [briefTemplateConfigurationsKeys, keyBindingExport, programmingLanguageExport, intl, notifications, configurationScope]);

    const handleBriefTemplateCheck = useCallback((value:boolean, briefTemplate:BriefTemplate) => {
        if (value) {
            setBriefTemplateConfigurationsKeys((prev) => {
                return [...prev, briefTemplate.id];
            });

            return;
        }
        setBriefTemplateConfigurationsKeys((prev) => {
            return prev.filter((briefTemplateId) => briefTemplateId !== briefTemplate.id);
        });
    }, []);

    const isBriefTemplateSelected = useCallback((briefTemplate:BriefTemplate) => {
        return briefTemplateConfigurationsKeys.includes(briefTemplate.id);
    }, [briefTemplateConfigurationsKeys]);

    const allBriefTemplatesCheckboxValue = useMemo(() => {
        if (briefTemplateConfigurationsKeys.length === 0) {
            return false;
        }
        if (briefTemplateConfigurationsKeys.length === briefTemplateListState?.filteredTemplates.length) {
            return true;
        }

        return 'minus';
    }, [briefTemplateConfigurationsKeys.length, briefTemplateListState?.filteredTemplates]);

    const handleAllBriefTemplatesSelection = useCallback(() => {
        if (briefTemplateConfigurationsKeys.length > 0) {
            setBriefTemplateConfigurationsKeys([]);
        } else {
            const briefTemplateConfigurationsKeys = briefTemplateListState?.filteredTemplates.map((briefTemplate) => briefTemplate.id);
            setBriefTemplateConfigurationsKeys(briefTemplateConfigurationsKeys);
        }
    }, [briefTemplateConfigurationsKeys, briefTemplateListState?.filteredTemplates]);

    const _onDeleteBriefTemplateConfirm = useCallback(async (progressMonitor:ProgressMonitor, template:BriefTemplate) => {
        await deleteBriefTemplate(progressMonitor, template);
        handleBriefTemplateCheck(false, template);
    }, [deleteBriefTemplate, handleBriefTemplateCheck]);

    const getDndAction = useMemo(() => {
        const ret:DndAction = {
            dragInfos: (event) => {
                if (!event.types.includes(FILE_TYPE)) {
                    return {
                        supports: false,
                    };
                }

                if (!canImportExport) {
                    return {
                        supports: false,
                    };
                }

                return {
                    supports: true,
                };
            },
            onDrop: (dataTransfer) => {
                const files = [...dataTransfer.files];
                const file = files[0];
                if (SUPPORTED_IMPORT_MIME_TYPES.includes(file.type)) {
                    loadImportedConfigurations(file);
                }
            },
            dropEffect: 'copy',
        };

        return ret;
    }, [loadImportedConfigurations, canImportExport]);

    const sections:SystemSection[] = [];

    if (configurationScope === 'data_exploration') {
        sections.push({
            id: SectionId.BriefSection,
            title: messages.briefTemplatesTitle,
            content: <BriefTemplates
                templates={ briefTemplateListState?.filteredTemplates }
                initialState={ { displayMode: DisplayMode.Tiles } }
                searchString={ searchedToken }
                onDeleteTemplateConfirm={ _onDeleteBriefTemplateConfirm }
                onRenameTemplateConfirm={ renameBriefTemplate }
                onCheckAction={ handleBriefTemplateCheck }
                isBriefTemplateSelected={ isBriefTemplateSelected }
                isActionMenuVisible={ () => true }
            />,
            handleAllCheckboxValue: allBriefTemplatesCheckboxValue,
            onChangeAllCheckbox: handleAllBriefTemplatesSelection,
        });
    }
    sections.push({
        id: SectionId.KeyBindingsSection,
        title: messages.keyBindingsTitle,
        content: map(filteredByScopes, (byScope) => {
            const scope = byScope.scope;
            const userScopeBindings = userConfig?.scopes.find((s) => s.id === scope.id)?.bindings ?? [];

            let label:ReactNode = intl.formatMessage(scope.name);
            if (isString(label) && searchedToken) {
                const refCount = { current: 0 };
                label = highlightSplit(label, searchedToken, undefined, refCount);
            }

            return <div className={ classNames('&-body-scope') } key={ scope.id }>
                <div className={ classNames('&-body-scope-title') }>
                    { label }
                </div>
                { byScope.keys.map((keyBindingDescriptor) => {
                    return <KeyBindingEditor key={ keyBindingDescriptor.id }
                                             keyBindingDescriptor={ keyBindingDescriptor }
                                             scopeDescriptor={ scope }
                                             userKeys={ userScopeBindings }
                                             definedKeys={ byScope.keys }
                                             searchedToken={ searchedToken }
                                             onUserConfigurationChange={ () => {
                                             } }
                                             className={ classNames('&-body-scope-keydescriptor') }
                                             keyBindingSettingsMode={ true }
                    />;
                }) }
            </div>;
        }),
        handleAllCheckboxValue: keyBindingExport,
        onChangeAllCheckbox: () => setKeyBindingExport((prev) => !prev),
    });

    if (configurationScope === 'data_preparation') {
        sections.push({
            id: SectionId.ProgrammingLanguageSection,
            title: messages.programmingLanguageTitle,
            handleAllCheckboxValue: programmingLanguageExport,
            onChangeAllCheckbox: () => setProgrammingLanguageExport((prev) => !prev),
            content: (
                <div className={ classNames('&-body-scope-programming-language') }>
                    <div className={ classNames('&-body-scope-programming-language-description') }>
                        { renderText(messages.programmingLanguageDescription) }
                    </div>
                    <ExpressionLanguageChoice
                        languages={ SUPPORTED_EXPRESSION_LANGUAGES }
                        value={ defaultLanguage }
                        onChange={ setDefaultLanguage }
                    />
                </div>
            ),
        });
    }

    return (
        <DroppingZone className={ classNames('&', className) } data-testid='system-configuration'>
            <div className={ classNames('&-header') }>
                <ArgInputSearch
                    className={ classNames('&-header-search') }
                    autoFocus={ true }
                    onInputChange={ handleSearchedTokenChange }
                    placeholder={ messages.searchPlaceholder }
                />

                { canImportExport && (
                    <>
                        <ArgUploaderButton
                            type='ghost'
                            size='large'
                            className={ classNames('&-header-import') }
                            icon='icon-download'
                            label={ messages.importLabel }
                            acceptedFiles='.zip'
                            method={ (file:Blob) => loadImportedConfigurations(file) }
                            disabled={ keyBindingExport || briefTemplateConfigurationsKeys.length > 0 }
                        />
                        <ArgButton
                            icon='icon-upload'
                            className={ classNames('&-header-export') }
                            label={ messages.export }
                            type='ghost'
                            size='large'
                            disabled={ !programmingLanguageExport && !keyBindingExport && !briefTemplateConfigurationsKeys.length }
                            onClick={ handleExportConfigurations }
                        />
                    </>
                ) }
            </div>
            <div className={ classNames('&-body') }>
                { sections.map((section, index) => {
                    return (
                        <React.Fragment key={ section.id }>
                            <div className={ classNames('&-section') }>
                                <ArgCheckboxMinus className={ classNames('&-checkbox') } size='node'
                                                  label={ section.title }
                                                  value={ section.handleAllCheckboxValue }
                                                  onChange={ section.onChangeAllCheckbox } />
                                <Droppable actions={ getDndAction }
                                           className={ classNames('&-droppable') }
                                >
                                    { (provided, snapshot) => {
                                        const dropMode = snapshot?.supportsDragging && snapshot?.draggingFromThisWith;
                                        const cls = {
                                            '&-drop-zone': true,
                                            '&-drop-mode': dropMode,
                                        };

                                        return <div className={ classNames(cls) }
                                                    data-label={ intl.formatMessage(messages.dropConfigurationtMessage) }>
                                            { section.content }
                                        </div>;
                                    } }
                                </Droppable>
                            </div>
                            { index < sections.length - 1 && <ArgDivider /> }
                        </React.Fragment>
                    );
                }) }
            </div>
        </DroppingZone>
    );
}

async function asyncNoop(...args:any[]) {
    // do nothing
}
