import React, { ReactNode, RefObject, useRef } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import Debug from 'debug';
import { debounce, DebouncedFunc, isString } from 'lodash';

import { PageConfig } from './page-config';
import { $yield } from '../utils/yield';
import { isMessageDescriptor } from '../utils/is-message-descriptor';

const debug = Debug('argonode:components:ArgPageContext');

export interface PageConfigurator {
    push(config: PageConfig): void;

    pop(config: PageConfig): void;
}

class PageConfiguratorImpl implements PageConfigurator {
    #stack: PageConfig[];
    #intl: IntlShape | undefined;
    #elementRef: RefObject<HTMLElement> | undefined;

    #debounce: DebouncedFunc<any>;

    constructor(config: PageConfig, elementRef?: RefObject<HTMLElement>) {
        this.#stack = [config];
        this.#elementRef = elementRef;

        this.#debounce = debounce(() => {
            this._apply();
        }, 300);
    }

    set intl(intl: IntlShape) {
        this.#intl = intl;
    }

    push(config: PageConfig): void {
        this.#stack.unshift(config);

        this.#debounce();
    }

    pop(config: PageConfig): void {
        if (this.#stack.length < 2) {
            throw new Error('Invalid pop');
        }

        const idx = this.#stack.indexOf(config);
        if (idx >= 0) {
            this.#stack.splice(idx, 1);
        }

        this.#debounce();
    }

    _apply() {
        const intl = this.#intl;
        if (!intl) {
            return;
        }

        const doc = this.#elementRef?.current?.ownerDocument || document;
        $yield(() => {
            const mergedConfig = mergeConfig(this.#stack);
            debug('_apply', 'MergedConfig=', mergedConfig);
            applyConfig(mergedConfig, intl, doc);
        });
    }
}

export const PageContext = React.createContext<PageConfigurator>(new PageConfiguratorImpl({}));

interface ArgPageProviderProps extends PageConfig {
    children?: ReactNode;
    elementRef?: RefObject<HTMLElement>;
}

export function ArgPageProvider(props: ArgPageProviderProps) {
    const intl = useIntl();

    const configurator = useRef<PageConfigurator>();
    if (!configurator.current) {
        configurator.current = new PageConfiguratorImpl(props, props.elementRef);
    }
    (configurator.current as PageConfiguratorImpl).intl = intl;

    return (
        <PageContext.Provider value={configurator.current}>
            {props.children}
        </PageContext.Provider>
    );
}

function mergeConfig(configs: PageConfig[]): PageConfig {
    const p: PageConfig = {};

    for (let i = configs.length - 1; i >= 0; i--) {
        const c = configs[i];

        Object.assign(p, c);
    }

    debug('mergeConfig', 'Merged p=', p, configs);

    return p;
}

function applyConfig(config: PageConfig, intl: IntlShape, document: Document) {
    debug('applyConfig', 'config=', config);

    let title = '';
    if (isMessageDescriptor(config.pageTitle)) {
        title = intl.formatMessage(config.pageTitle, config.messageValues);
    } else if (isString(config.pageTitle)) {
        title = config.pageTitle;
    }

    let subtitle;
    if (isMessageDescriptor(config.pageSubTitle)) {
        subtitle = intl.formatMessage(config.pageSubTitle, config.messageValues);
    } else if (isString(config.pageSubTitle)) {
        subtitle = config.pageSubTitle;
    }
    if (subtitle) {
        if (title) {
            if (config.subTitlePosition === 'end') {
                title = `${title} - ${subtitle}`;
            } else {
                title = `${subtitle} - ${title}`;
            }
        } else {
            title = subtitle;
        }
    }

    if (document.title !== title) {
        document.title = title;
    }

    let favicon: HTMLLinkElement | null = document.querySelector('link[rel~=\'icon\']');
    if (config.iconURL) {
        if (!favicon) {
            favicon = document.createElement('link');
            favicon.rel = 'icon';
            favicon.href = config.iconURL;
            document.head.appendChild(favicon);
        } else {
            favicon.href = `${config.iconURL}?v=${new Date().getTime()}`;
        }
    } else if (favicon?.parentElement) {
        favicon.parentElement.removeChild(favicon);
    }

    document.documentElement.lang = config.language || '';
}
