import { DataFilter, getPredicate, SortableArrayDataProvider } from '../../../components/basic';
import { Group, User } from '../../../model/user';

export interface UserDataFilter extends DataFilter {
    search?: string;
    groups?: Group[];
}

export class UsersDataProvider extends SortableArrayDataProvider<User, UserDataFilter> {
    filterData(data: User[], filter?: UserDataFilter): User[] {
        let filteredData = [...data];
        if (filter?.search) {
            filteredData = filteredData.filter((item) =>
                Object.values(item).find(getPredicate(filter.search!)),
            );
        }

        return filteredData;
    }
}
