import { defaultTo } from 'lodash';
import { LatLng, LatLngExpression, LatLngLiteral, Map } from 'leaflet';
import React, { MutableRefObject, SetStateAction, useCallback, useMemo, useState } from 'react';

import '../../../../../utils/leaflet-draw/leaflet-draw';
import { getDataTestIdFromProps } from '../../../utils';
import { ArgMapCircle } from '../../common/map/circle/map-circle';
import { GeoAreaValue } from '../../common/model/geolocation-value';
import { ArgMapPolygon } from '../../common/map/polygon/map-polygon';
import { latLngExpressionToLatLng } from '../../common/helpers/latLngExpressionToLatLng';
import { ClassValue } from '../../../arg-hooks/use-classNames';
import { ArgChangeReason } from '../../../types';
import { ArgMap } from '../../common/map/arg-map';
import { ArgGeoshaopeToolbar } from './arg-geoshape-toolbar';
import { MapMode } from './MapMode';

export const DEFAULT_RADIUS_IN_METTER = 10_000;

export interface ArgGeoshapeMapProps {
    initialZoom?: number;
    initialRadius?: number;
    initialCenter?: LatLngLiteral;
    initialPolygon?: LatLngLiteral[];
    initialMapMode?: MapMode;
    forwardRef?: MutableRefObject<Map | undefined>;
    className?: ClassValue;
    value?: GeoAreaValue | undefined;
    onChange?: (value: SetStateAction<GeoAreaValue | undefined>, reason?: ArgChangeReason) => void;
}

export function ArgGeoshapeMap(props: ArgGeoshapeMapProps) {
    const {
        className,
        onChange,
        forwardRef,
        value: externalValue,

        // Initial values
        initialZoom,
        initialRadius,
        initialCenter,
        initialPolygon,
        initialMapMode,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);

    const useInternalValue = !('value' in props);
    const [internalValue, setInternalValue] = useState<GeoAreaValue | undefined>();
    const [mapMode, setMapMode] = useState<MapMode | undefined>(() => initialMapMode);

    const handleChange = useCallback((geoValue: React.SetStateAction<GeoAreaValue | undefined>) => {
        if (useInternalValue) {
            setInternalValue(geoValue);
        }
        onChange?.(geoValue);
    }, [onChange, useInternalValue]);

    const value = useInternalValue ? internalValue : externalValue;
    const radius = useMemo(() => defaultTo(value?.centeredArea?.radius, initialRadius), [initialRadius, value]);
    const center = useMemo(() => defaultTo(value?.centeredArea?.latLng, initialCenter), [initialCenter, value]);
    const polygon = useMemo(() => defaultTo(value?.polygonArea?.latLng, initialPolygon), [initialPolygon, value]);

    const handleCenterChange = useCallback((center?: LatLngExpression) => {
        if (mapMode === MapMode.Polygon) {
            return;
        }

        const updateMethod = (prev?: GeoAreaValue | undefined) => {
            const newValue: GeoAreaValue = {
                centeredArea: {
                    radius: prev?.centeredArea?.radius ?? DEFAULT_RADIUS_IN_METTER,
                    latLng: latLngExpressionToLatLng(center),
                },
            };

            return newValue;
        };

        handleChange(updateMethod);
    }, [handleChange, mapMode]);

    const startDrawingPolygonHandler = useCallback(() => {
        setMapMode(MapMode.Polygon);
    }, []);

    const onPolygonDrawFinishHandler = useCallback((area: number, polygon: LatLng[]) => {
        setMapMode(MapMode.CenterArea);

        if (!area) {
            return;
        }
        const updateMethod = (): GeoAreaValue => {
            const newValue: GeoAreaValue = {
                polygonArea: {
                    area,
                    latLng: polygon,
                },
            };

            return newValue;
        };

        handleChange(updateMethod);
    }, [handleChange]);

    const handleMapModeChange = useCallback((mapMode: MapMode | undefined) => {
        setMapMode(mapMode);
    }, []);

    return (
        <ArgMap
            className={className}
            forwardRef={forwardRef}
            data-testid={dataTestId}
            initialZoom={initialZoom}
            onCenterChange={handleCenterChange}
            toolbar={
                <ArgGeoshaopeToolbar
                    onChange={handleMapModeChange}
                    onPolygonDrawFinishHandler={onPolygonDrawFinishHandler}
                    startDrawingPolygonHandler={startDrawingPolygonHandler}
                    mapMode={mapMode}
                />
            }
            center={center}
        >
            {!!(center && radius) && (
                <ArgMapCircle
                    center={center}
                    radius={radius}
                    pathOptions={{
                        color: '#2873D6',
                        fillColor: '#2873D6',
                        opacity: 1,
                    }}
                />
            )}
            {!!polygon &&
                <ArgMapPolygon
                    positions={polygon}
                    pathOptions={{
                        color: '#2873D6',
                        fillColor: '#2873D6',
                        opacity: 1,
                    }}
                />}
        </ArgMap>
    );
}
