import { useCallback, useContext } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgButton,
    ArgMenu,
    ArgMenuInfo,
    ArgMenuItem,
    GLOBAL_PM,
    ProgressMonitor,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
} from 'src/components/basic';
import { User } from 'src/model/user';
import { UsersAndGroupsStateContext } from '../../providers/usersState';
import { UsersAdminConnector } from '../../../../utils/connectors/users-admin-connector';

const messages = defineMessages({
    restore: {
        id: 'settings.deleted-users.menu.restore',
        defaultMessage: 'Restore',
    },
    permanentlyDelete: {
        id: 'settings.deleted-users.menu.permanently-delete',
        defaultMessage: 'Permanently delete',
    },
});

export interface UserActionsMenuProp {
    user: User;
}

export function DeletedUserActionsMenu(props: UserActionsMenuProp) {
    const {
        user,
    } = props;

    const notifications = useArgNotifications();

    const { setUsers, setDeletedUsers } = useContext(UsersAndGroupsStateContext);

    const [handleDelete, deleteProgress, deleteError] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await UsersAdminConnector.getInstance().deleteUser(user.id, true, sub1);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            const deletedUsers = await UsersAdminConnector.getInstance().getUsers(true, sub2);

            setDeletedUsers(deletedUsers);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            console.error(error);
            notifications.snackError(undefined, error as Error);
        }
    }, [notifications, setDeletedUsers, user.id], undefined, undefined, GLOBAL_PM);


    const [handleRestore, restoreProgress, restoreError] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await UsersAdminConnector.getInstance().restoreUser(user.id, sub1);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            const deletedUsers = await UsersAdminConnector.getInstance().getUsers(true, sub2);

            const sub3 = new SubProgressMonitor(progressMonitor, 1);
            const users = await UsersAdminConnector.getInstance().getUsers(false, sub3);

            setDeletedUsers(deletedUsers);
            setUsers(users);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            console.error(error);
            notifications.snackError(undefined, error as Error);
        }
    }, [notifications, setDeletedUsers, setUsers, user.id], undefined, undefined, GLOBAL_PM);

    const handleClick = useCallback((menuItem: ArgMenuInfo) => {
        switch (menuItem.key) {
            case 'restore':
                handleRestore();

                return;
            case 'delete':
                handleDelete();

                return;
        }
    }, [handleDelete, handleRestore]);

    const actionsMenu = (
        <ArgMenu data-testid='actions-users-button-menu' onClick={handleClick}>
            <ArgMenuItem
                key='restore'
                data-testid='restore'
                disabled={restoreProgress?.isRunning || deleteProgress?.isRunning}
                label={messages.restore}
            />
            <ArgMenuItem
                key='delete'
                data-testid='permanently-delete'
                disabled={restoreProgress?.isRunning || deleteProgress?.isRunning}
                label={messages.permanentlyDelete}
            />
        </ArgMenu>
    );

    return (
        <ArgButton
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            data-testid='actions-users-menu'
            popoverPlacement='bottomLeft'
        />
    );
}
