import { useCallback, useMemo, useRef } from 'react';
import { keyBy } from 'lodash';

import { FullOntologyLinkType, FullOntologyObjectType } from '../../types';

export function useDesignItemComputation<T extends FullOntologyObjectType | FullOntologyLinkType>(
    items?: T[],
    selectedItem?: T,
) {
    // Store a ref to the old item to still display it on unselection
    const oldSelectedItemName = useRef<string>();
    if (selectedItem) {
        oldSelectedItemName.current = selectedItem.name;
    }

    const item = useMemo(() => {
        if (selectedItem) {
            return selectedItem;
        }

        if (oldSelectedItemName.current) {
            // Item must be retrieve from items cause items references change at each item modification
            const oldFromOntology = items?.find((item) => item.name === oldSelectedItemName.current);

            return oldFromOntology;
        }

        const defaultItem = items?.[0];

        return defaultItem;
    }, [items, selectedItem]);


    const vertexPropertiesByName = useMemo(() => (
        keyBy(item?.properties, property => property.name)
    ), [item?.properties]);

    const getItemPropertyDisplayName = useCallback((propertyName: string) => {
        const baseDisplayName = vertexPropertiesByName[propertyName]?.displayName;
        const displayName = baseDisplayName ?? propertyName;

        return displayName;
    }, [vertexPropertiesByName]);

    return { item, getItemPropertyDisplayName };
}
