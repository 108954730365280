import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { SecretId } from '../secrets/secrets';
import { SecretState } from './secret-state';
import { BasicStates, useBasicStates } from '../../utils/rt-states/use-basic-states';

export function preparationSecretStateFactory(url: string, secretId: SecretId) {
    return new SecretState(url, secretId);
}

export function computeSecretStateURL(secretId: SecretId) {
    return `/secrets/${secretId}`;
}

export function usePreparationSecretState(secretId: SecretId): SecretState;
export function usePreparationSecretState(secretId: SecretId | undefined): SecretState | undefined;

export function usePreparationSecretState(secretId: SecretId | undefined): SecretState | undefined {
    const state = useBasicState<SecretState>(
        (url) => preparationSecretStateFactory(url, secretId!),
        (secretId) ? computeSecretStateURL(secretId) : undefined,
    );

    return state;
}


export function usePreparationSecretStates(processIds: SecretId[]): BasicStates<SecretState>;
export function usePreparationSecretStates(processIds: undefined): undefined;
export function usePreparationSecretStates(processIds: SecretId[] | undefined): BasicStates<SecretState> | undefined;
export function usePreparationSecretStates(processIds: SecretId[] | undefined): BasicStates<SecretState> | undefined {
    const state = useBasicStates(
        (url, processId) => preparationSecretStateFactory(url, processId), processIds,
        (processId) => computeSecretStateURL(processId),
    );

    return state;
}
