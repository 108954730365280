import _, { CollectionChain } from 'lodash';

import { EventEmitter, ProgressMonitor, SubProgressMonitor } from '../components/basic';
import { HistoryEntry, HistoryType } from '../model/history-entry';

export interface HistoryManagerEventTypes {
    Change: (source?: string) => void;
}

export class HistoryManager<C = any> extends EventEmitter<HistoryManagerEventTypes> {
    #entries: HistoryEntry<C, any>[] = [];
    #currentPosition = -1;

    constructor() {
        super();
    }

    public track<K = void>(entry: HistoryEntry<C, K>): void {
        this.#entries.splice(this.#currentPosition + 1, this.#entries.length - this.#currentPosition - 1, entry);
        this.#currentPosition++;
        this.updateStateId();

        this.emit('Change');
    }

    public clear() {
        this.#entries = new Array<HistoryEntry<C, any>>();
        this.#currentPosition = -1;
        this.updateStateId();

        this.emit('Change');
    }

    public async trackAndExecute<K = void>(
        entry: HistoryEntry<C, K>,
        context: C,
        progressMonitor: ProgressMonitor,
    ): Promise<K> {
        this.track(entry);

        return entry.action(this, context, progressMonitor);
    }

    public canGoBackwards(type?: HistoryType): boolean {
        if (type) {
            return this.getBeforeList(type).length > 0;
        }

        return this.#currentPosition >= 0 && !!this.#entries[this.#currentPosition]?.reverseAction;
    }

    public canGoForwards(type?: HistoryType): boolean {
        if (type) {
            return this.getAfterList(type).length > 0;
        }

        return this.#currentPosition < this.#entries.length - 1;
    }

    public navigateWithoutExecution = (entry: HistoryEntry<C>): void => {
        const targetIndex = this.#entries.indexOf(entry);
        if (targetIndex < 0) {
            throw new Error('Entry is not into the list');
        }

        if (targetIndex + 1 === this.#currentPosition) {
            return;
        }

        this.#currentPosition = targetIndex + 1;

        this.updateStateId();

        this.emit('Change');
    };

    public async navigateAndExecute(
        entry: HistoryEntry<C>,
        context: C,
        executeStep: boolean,
        progressMonitor: ProgressMonitor,
    ): Promise<void> {
        const targetIndex = this.#entries.indexOf(entry);
        if (targetIndex < 0) {
            throw new Error('Entry is not into the list');
        }

        const direction = (targetIndex - this.#currentPosition);

        for (; targetIndex !== this.#currentPosition;) {
            const entry = this.#entries[this.#currentPosition];
            const sub = new SubProgressMonitor(progressMonitor, 1);

            if (executeStep && direction < 0) {
                if (entry.reverseAction) {
                    const sub1 = new SubProgressMonitor(sub, 1);
                    try {
                        await entry.reverseAction(this, context, sub1);
                    } finally {
                        sub1.done();
                    }
                }
            }

            this.#currentPosition += ((direction > 0) ? 1 : -1);

            const newEntry = this.#entries[this.#currentPosition];

            if (executeStep && direction > 0) {
                if (newEntry.action) {
                    const sub1 = new SubProgressMonitor(sub, 1);
                    try {
                        await newEntry.action(this, context, sub1);
                    } finally {
                        sub1.done();
                    }
                }
            }

            sub.done();
        }

        if (!executeStep) {
            if (entry.action) {
                await entry.action(this, context, progressMonitor);
            }
        }

        this.updateStateId();

        this.emit('Change');
    }

    public goBackwards = async (context: C, progressMonitor: ProgressMonitor): Promise<void> => {
        if (!this.canGoBackwards()) {
            return;
        }

        this.updateStateId();

        const entry = this.#entries[this.#currentPosition--];
        if (entry.reverseAction) {
            await entry.reverseAction(this, context, progressMonitor);
        }

        this.emit('Change');
    };

    public goForwards = async (context: C, progressMonitor: ProgressMonitor, redo?: boolean): Promise<void> => {
        if (!this.canGoForwards()) {
            return;
        }
        this.updateStateId();

        const entry = this.#entries[++this.#currentPosition];
        await entry.action(this, context, progressMonitor, redo);

        this.emit('Change');
    };

    public getBefore(type?: HistoryType): HistoryEntry<C> | undefined {
        for (let i = this.#currentPosition; i >= 0; i--) {
            const ret = this.#entries[i];
            if (!type || ret.type === type) {
                return ret;
            }
        }
    }

    public getBeforeList(type?: HistoryType): HistoryEntry<C>[] {
        let list = this.#entries.slice(0, this.#currentPosition).reverse();
        if (type) {
            list = list.filter((h) => (h.type === type));
        }

        return list;
    }

    public getAfter(type?: symbol): HistoryEntry<C> | undefined {
        if (this.#currentPosition + 1 >= this.#entries.length) {
            return undefined;
        }

        for (let i = this.#currentPosition + 1; i < this.#entries.length; i++) {
            const ret = this.#entries[i];
            if (!type || ret.type === type) {
                return ret;
            }
        }
    }

    public getAfterList(type?: HistoryType): HistoryEntry<C>[] {
        let list = this.#entries.slice(this.#currentPosition + 1);
        if (type) {
            list = list.filter((h) => (h.type === type));
        }


        return list;
    }

    public get current(): HistoryEntry<C> | undefined {
        if (this.#currentPosition < 0) {
            return undefined;
        }

        return this.#entries[this.#currentPosition];
    }

    public chain(): CollectionChain<HistoryEntry<C>> {
        return _.chain(this.#entries);
    }
}
