import { defineMessages } from 'react-intl';

import { ArgButton, ArgMenu, ArgMenuItem, ArgMessageRenderer, ClassValue, useClassNames } from 'src/components/basic';
import { ArgonosModule } from '../../../components/application/modules';
import { useExtensions } from '../../hooks/use-extensions';
import { Extension } from '../../models/extension';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';

const CLASSNAME = 'settings-extension-actions-button-menu';

const messages = defineMessages({
    actions: {
        id: 'settings-extension-actions-button-menu.actions',
        defaultMessage: 'Actions',
    },
    activate: {
        id: 'settings-extension-actions-button-menu.activate',
        defaultMessage: 'Activate',
    },
    deactivate: {
        id: 'settings-extension-actions-button-menu.deactivate',
        defaultMessage: 'Deactivate',
    },
    delete: {
        id: 'settings-extension-actions-button-menu.delete',
        defaultMessage: 'Delete',
    },
    export: {
        id: 'settings-extension-actions-button-menu.export',
        defaultMessage: 'Export',
    },
});

export interface ExtensionActionsButtonMenuProps {
    selectedExtensions: Extension[];
    argonosModule: ArgonosModule | undefined;
    className?: ClassValue;
    onSuccess?: () => void;
}

export function ExtensionActionsButtonMenu(props: ExtensionActionsButtonMenuProps) {
    const {
        selectedExtensions,
        argonosModule,
        className,
        onSuccess,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const { popoverVisible, setPopoverVisible, handleUpdateStatus, handleDeleteConfirmModal, handleExport } = useExtensions(selectedExtensions, argonosModule, onSuccess);
    const allowDeleteExtension = useHasPermission<SettingsPermissions>('admin.moduleExtensions.delete');
    const allowPublishExtension = useHasPermission<SettingsPermissions>('admin.moduleExtensions.publish');

    const displayActivateMenu = allowPublishExtension && selectedExtensions.findIndex(extension => !extension.state.enabled) > -1;
    const displayDeactivateMenu = allowPublishExtension && selectedExtensions.findIndex(extension => extension.state.enabled) > -1;

    const actionsMenu = (
        <ArgMenu>
            {displayActivateMenu && <ArgMenuItem
                key={messages.activate.id}
                data-testid='activate'
                onClick={() => handleUpdateStatus(true)}
            >
                <ArgMessageRenderer message={messages.activate} />
            </ArgMenuItem>}
            {displayDeactivateMenu && <ArgMenuItem
                key={messages.deactivate.id}
                data-testid='deactivate'
                onClick={() => handleUpdateStatus(false)}
            >
                <ArgMessageRenderer message={messages.deactivate} />
            </ArgMenuItem>}
            {allowDeleteExtension && <ArgMenuItem
                key={messages.delete.id}
                data-testid='delete'
                onClick={handleDeleteConfirmModal}
            >
                <ArgMessageRenderer message={messages.delete} />
            </ArgMenuItem>}
            <ArgMenuItem
                key={messages.export.id}
                data-testid='export'
                onClick={handleExport}
            >
                <ArgMessageRenderer message={messages.export} />
            </ArgMenuItem>
        </ArgMenu>
    );

    return (
        <ArgButton
            className={classNames('&', className)}
            size='medium'
            type='secondary'
            right='dropdown'
            label={messages.actions}
            disabled={selectedExtensions.length === 0}
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={popoverVisible}
            data-testid='actions-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setPopoverVisible}
        />
    );
}
