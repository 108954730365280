import { BuildInPlacements } from 'rc-trigger';

import { ArgButtonType, ArgInputInputType, ArgInputType, ArgSize } from './types';

export const DEFAULT_TOOLTIP_PLACEMENT = 'bottomLeft';
export const DEFAULT_TOOLTIP_DELAY = 0.5;
export const DEFAULT_TOOLTIP_BUTTON_DELAY = 0.3;
export const DEFAULT_POPOVER_TRIGGER = '';
export const DEFAULT_POPOVER_DELAY = 1.5;
export const DEFAULT_POPOVER_PLACEMENT = 'top';

export const DEFAULT_CARDINALITY = 'one';

export const EMPTY_POPOVER_TRIGGER = '';

export const DEFAULT_SIZE: ArgSize = 'medium';
export const DEFAULT_BUTTON_TYPE: ArgButtonType = 'primary';

export const DEFAULT_INPUT_TYPE: ArgInputType = 'normal';

export const DEFAULT_INPUT_INPUT_TYPE: ArgInputInputType = 'text';

export const DEFAULT_AUTOCOMPLETE = false;

export function createBuiltinPlacement(
    horizontalMargin: number,
    verticalMargin: number,
    targetOffset: [number, number] = [0, 0],
    overflow: { adjustX: number; adjustY: number } = {
        adjustX: 1,
        adjustY: 1,
    }) {
    targetOffset = [...targetOffset];
    overflow = { ...overflow };

    return {
        left: {
            points: ['cr', 'cl'],
            overflow: overflow,
            offset: [-horizontalMargin, 0],
            targetOffset,
        },
        right: {
            points: ['cl', 'cr'],
            overflow: overflow,
            offset: [horizontalMargin, 0],
            targetOffset,
        },
        top: {
            points: ['bc', 'tc'],
            overflow: overflow,
            offset: [0, -verticalMargin],
            targetOffset,
        },
        bottom: {
            points: ['tc', 'bc'],
            overflow: overflow,
            offset: [0, verticalMargin],
            targetOffset,
        },
        topLeft: {
            points: ['bl', 'tl'],
            overflow: overflow,
            offset: [0, -verticalMargin],
            targetOffset,
        },
        leftTop: {
            points: ['tr', 'tl'],
            overflow: overflow,
            offset: [-horizontalMargin, 0],
            targetOffset,
        },
        topRight: {
            points: ['br', 'tr'],
            overflow: overflow,
            offset: [0, -verticalMargin],
            targetOffset,
        },
        rightTop: {
            points: ['tl', 'tr'],
            overflow: overflow,
            offset: [horizontalMargin, 0],
            targetOffset,
        },
        bottomRight: {
            points: ['tr', 'br'],
            overflow: overflow,
            offset: [0, verticalMargin],
            targetOffset,
        },
        rightBottom: {
            points: ['bl', 'br'],
            overflow: overflow,
            offset: [horizontalMargin, 0],
            targetOffset,
        },
        bottomLeft: {
            points: ['tl', 'bl'],
            overflow: overflow,
            offset: [0, verticalMargin],
            targetOffset,
        },
        leftBottom: {
            points: ['br', 'bl'],
            overflow: overflow,
            offset: [-horizontalMargin, 0],
            targetOffset,
        },
    };
}

export const defaultBuiltinPlacements: BuildInPlacements = createBuiltinPlacement(0, 4, [0, 0], {
    adjustX: 1,
    adjustY: 1,
});

export const horizontalM4: BuildInPlacements = createBuiltinPlacement(-8, 4, [0, 0], {
    adjustX: -8,
    adjustY: 0,
});
