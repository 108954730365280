import { ClassValue, useClassNames } from 'src/components/basic';
import { ExtensionImportError } from '../../models/extension';

import './extension-error-notification-description.less';

const CLASSNAME = 'settings-extension-error-notification-description';

export interface ExtensionErrorNotificationDescriptionProps {
    errors: ExtensionImportError[];
    className?: ClassValue;
}

export function ExtensionErrorNotificationDescription(props: ExtensionErrorNotificationDescriptionProps) {
    const { className, errors } = props;
    const classNames = useClassNames(CLASSNAME);

    return (
        <div className={classNames('&', className)}>
            {errors.map((error: ExtensionImportError, id) => {
                return (
                    <div key={id} className={classNames('&-import-error-container')}>
                        <span className={classNames('&-import-error-title')}>{error.title}</span>

                        <div className={classNames('&-import-error')}>
                            {error.detail}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
