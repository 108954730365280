import { PreparationPermissions } from './permissions';
import { PermissionConstraints } from '../../components/application/modules';

export const PREPARATION_PERMISSIONS_CONSTRAINTS: PermissionConstraints<PreparationPermissions> = {
    'preparation.folder.export': ['preparation.create.folder'],
    'preparation.process.access': ['preparation.create.folder'],
    'preparation.process.edition': [
        'preparation.create.folder',
        'preparation.process.access',
    ],
    'preparation.process.export': [
        'preparation.create.folder',
        'preparation.process.access',
    ],
    'preparation.process.execution': [
        'preparation.create.folder',
        'preparation.process.access',
    ],
    'preparation.process.prioritization.access': [
        'preparation.create.folder',
        'preparation.process.access',
    ],
    'preparation.process.prioritization.edition': [
        'preparation.create.folder',
        'preparation.process.access',
        'preparation.process.prioritization.access',
    ],
    'preparation.process.execution.management': [
        'preparation.process.execution',
    ],
    'preparation.composite.component.edition': [
        'preparation.create.folder',
        'preparation.composite.component.access',
    ],
    'preparation.composite.component.export': [
        'preparation.create.folder',
        'preparation.composite.component.access',
    ],
    'preparation.remote.component.edition': [
        'preparation.create.folder',
        'preparation.remote.component.access',
    ],
    'preparation.remote.component.export': [
        'preparation.create.folder',
        'preparation.remote.component.access',
    ],
    'preparation.secret.edition': [
        'preparation.create.folder',
        'preparation.secret.access',
    ],
    'preparation.secret.export': [
        'preparation.create.folder',
        'preparation.secret.access',
    ],
    'preparation.code.fragment.edition': [
        'preparation.code.fragment.access',
    ],
    'preparation.code.fragment.export': [
        'preparation.code.fragment.access',
    ],
    'preparation.reference.table.list': [
        'preparation.reference.table.read',
    ],
};
