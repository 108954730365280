import { isArray, isEmpty, isString } from 'lodash';
import { useCallback, useMemo, useReducer } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ProgressMonitor, SortableArrayDataProvider, SubProgressMonitor, useArgNotifications, useCallbackAsync, useEffectAsync } from 'src/components/basic';
import ontologiesConnector from 'src/settings/connectors/ontologies-connector';
import { ExecuteOntologyFeedSourcesRequest, OntologyFeedSource, OntologyFeedSourceExecuteResponse, OntologyFeedSourceParseError, OntologyFeedSources, OntologyFeedSourceValidationResultResponse } from 'src/settings/models/feed-sources';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType } from 'src/settings/universes/ontology/types';
import { downloadFile } from 'src/utils/file';

export const messages = defineMessages({
    feedSources: {
        id: 'settings.use-feed-sources-modal.FeedSources',
        defaultMessage: 'feed-sources',
    },
    importError: {
        id: 'settings.use-feed-sources-modal.ImportError',
        defaultMessage: 'The imported file isn\'t compatible',
    },
    validateQueryError: {
        id: 'settings.use-feed-sources-modal.ValidateQueryError',
        defaultMessage: 'Failed to validate feed source',
    },
    updateFeedSourcesError: {
        id: 'settings.use-feed-sources-modal.UpdateFeedSourcesError',
        defaultMessage: 'Failed to update feed sources',
    },
    fetchFeedSourcesError: {
        id: 'settings.use-feed-sources-modal.FetchFeedSourcesError',
        defaultMessage: 'Failed to get feed sources',
    },
});

interface FeedSourcesState {
    queries: string[];
    selectedSourceIndex: number;
    errors: Record<number, OntologyFeedSourceParseError[]>;
    results: Record<number, OntologyFeedSourceExecuteResponse>;
    activeTab: string | undefined;
    focusedEditorIndex: number;
}

type Action =
  | { type: 'SET_QUERIES'; payload: string[] }
  | { type: 'UPDATE_QUERY'; payload: { index: number; value: string } }
  | { type: 'SET_SELECTED_SOURCE_INDEX'; payload: number }
  | { type: 'SET_ERRORS'; payload: { index: number; errors: OntologyFeedSourceParseError[] } }
  | { type: 'SET_RESULTS'; payload: { index: number; results: OntologyFeedSourceExecuteResponse } }
  | { type: 'SET_ACTIVE_TAB'; payload: string | undefined }
  | { type: 'SET_FOCUSED_EDITOR_INDEX'; payload: number }
  | { type: 'ADD_SOURCE' }
  | { type: 'REMOVE_SOURCE'; payload: number };

function feedSourcesReducer(state: FeedSourcesState, action: Action): FeedSourcesState {
    switch (action.type) {
        case 'SET_QUERIES':
            return { ...state, queries: action.payload };
        case 'UPDATE_QUERY':
            return {
                ...state,
                queries: state.queries.map((q, i) => (i === action.payload.index ? action.payload.value : q)),
                errors: { ...state.errors, [action.payload.index]: [] },
            };
        case 'SET_SELECTED_SOURCE_INDEX':
            return { ...state, selectedSourceIndex: action.payload };
        case 'SET_ERRORS':
            return { ...state, errors: { ...state.errors, [action.payload.index]: action.payload.errors } };
        case 'SET_RESULTS':
            return { ...state, results: { ...state.results, [action.payload.index]: action.payload.results } };
        case 'SET_ACTIVE_TAB':
            return { ...state, activeTab: action.payload };
        case 'SET_FOCUSED_EDITOR_INDEX':
            return { ...state, focusedEditorIndex: action.payload };
        case 'ADD_SOURCE':
            return {
                ...state,
                queries: [...state.queries, ''],
                selectedSourceIndex: state.queries.length,
            };
        case 'REMOVE_SOURCE': {
            if (state.queries.length <= 1) {
                return state; // Don't remove the last query
            }

            const newQueries = state.queries.filter((_, i) => i !== action.payload);
            const newErrors = Object.fromEntries(
                Object.entries(state.errors)
                    .filter(([key]) => Number(key) !== action.payload)
                    .map(([key, value]) => [
                        Number(key) > action.payload ? Number(key) - 1 : Number(key),
                        value,
                    ]),
            );

            // Math.min() is used to ensure that focusedEditorIndex and selectedSourceIndex never exceed the new array length.
            const newFocusedEditorIndex = Math.min(state.focusedEditorIndex, newQueries.length - 1);
            const newSelectedSourceIndex = Math.min(
                state.selectedSourceIndex === action.payload ? action.payload - 1 : state.selectedSourceIndex,
                newQueries.length - 1,
            );

            return {
                ...state,
                queries: newQueries,
                errors: newErrors,
                focusedEditorIndex: newFocusedEditorIndex,
                selectedSourceIndex: newSelectedSourceIndex,
            };
        }
        default:
            return state;
    }
}

const initialState: FeedSourcesState = {
    queries: [''],
    selectedSourceIndex: 0,
    errors: {},
    results: {},
    activeTab: undefined,
    focusedEditorIndex: 0,
};

const EMPTY_QUERY_REGEX = /^\s*$/;
const QUERY_TYPE = 'Kbql';
const DEFAULT_QUERIES = [''];
const RESULT_LIMIT = 100;
const ERROR_TAB_KEY = 'error';
const RESULT_TAB_KEY = 'result';

export const useFeedSources = (ontology: FullOntology, vertexOrEdge: FullOntologyObjectType | FullOntologyLinkType, ontologyType: 'object' | 'relation', onSubmit?: () => void) => {
    const [state, dispatch] = useReducer(feedSourcesReducer, initialState);
    const { queries, selectedSourceIndex, errors, results, activeTab, focusedEditorIndex } = state;
    const intl = useIntl();
    const notifications = useArgNotifications();

    const handleEditorChange = useCallback((value: string, index: number) => {
        dispatch({ type: 'UPDATE_QUERY', payload: { index, value } });
    }, []);

    const handleQueriesChange = useCallback((queries: string[]) => {
        dispatch({ type: 'SET_QUERIES', payload: queries });
    }, []);

    const handleFocus = useCallback((index: number) => {
        dispatch({ type: 'SET_FOCUSED_EDITOR_INDEX', payload: index });
    }, []);

    const handleRemoveEditor = useCallback((index: number) => {
        dispatch({ type: 'REMOVE_SOURCE', payload: index });
    }, []);

    const handleAddSource = useCallback(() => {
        dispatch({ type: 'ADD_SOURCE' });
    }, []);

    const handleTabChange = useCallback((tabKey: string | undefined) => {
        dispatch({ type: 'SET_ACTIVE_TAB', payload: tabKey });
    }, []);
    const handleResultsChange = useCallback((result: OntologyFeedSourceExecuteResponse, index: number) => {
        dispatch({ type: 'SET_RESULTS', payload: { results: result, index } });
    }, []);
    const handleSelectedSourceIndexChange = useCallback((index: number) => {
        dispatch({ type: 'SET_SELECTED_SOURCE_INDEX', payload: index });
    }, []);
    const handleErrorsChange = useCallback((errors: OntologyFeedSourceParseError[], index: number) => {
        dispatch({ type: 'SET_ERRORS', payload: { errors, index } });
    }, []);

    const setQueriesFromFeedSources = useCallback((feedSources: OntologyFeedSources) => {
        const queries = feedSources.feedSources?.map((feedSource) => feedSource.query);
        handleQueriesChange(!queries?.length ? DEFAULT_QUERIES : queries);
    }, [handleQueriesChange]);

    const computeFeedSources = useCallback(() => {
        const feedSources: OntologyFeedSources = {
            feedSources: queries
                .filter((query) => !(EMPTY_QUERY_REGEX.test(query)))
                .map((query) => ({
                    query,
                    type: QUERY_TYPE,
                })),
        };

        return feedSources;
    }, [queries]);

    const exportFeedSource = useCallback(() => {
        const feedSources = computeFeedSources();
        const fileName = `${intl.formatMessage(messages.feedSources)}-${ontology.name}-${vertexOrEdge.displayName}-${new Date().getTime()}.json`;

        downloadFile(fileName, JSON.stringify(feedSources, null, 2), 'application/json');
    }, [computeFeedSources, intl, ontology.name, vertexOrEdge.displayName]);

    const testQuery = useCallback(async (queryIndex: number, progressMonitor: ProgressMonitor): Promise<OntologyFeedSourceValidationResultResponse> => {
        const feedSource: OntologyFeedSource = {
            type: QUERY_TYPE,
            query: queries[queryIndex],
        };

        const validationResult = await ontologiesConnector.validateFeedSource(ontology.id, ontologyType, vertexOrEdge.name, feedSource, progressMonitor);
        if (validationResult.parseErrors) {
            handleErrorsChange(validationResult?.parseErrors ?? [], queryIndex);
        }

        return validationResult;
    }, [handleErrorsChange, ontology.id, ontologyType, queries, vertexOrEdge.name]);

    const importFeedSources = useCallback(async (blob: Blob, progressMonitor: ProgressMonitor) => {
        try {
            const fileData = await blob.text();

            progressMonitor.verifyCancelled();

            const feedSourcesData = JSON.parse(fileData);
            if (!isOntologyFeedSources(feedSourcesData)) {
                notifications.snackError({ message: messages.importError });

                return;
            }

            setQueriesFromFeedSources(feedSourcesData);
        } catch (e) {
            if (progressMonitor.isCancelled) {
                throw e;
            }

            notifications.snackError({ message: messages.importError }, e as Error);
            throw e;
        }
    }, [notifications, setQueriesFromFeedSources]);


    const [feedSourcesProgressMonitor] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const feedSources = await ontologiesConnector.getFeedSources(ontology.id, ontologyType, vertexOrEdge.name, progressMonitor);
            setQueriesFromFeedSources(feedSources);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchFeedSourcesError }, error as Error);
            throw error;
        }
    }, [ontology.id, setQueriesFromFeedSources, vertexOrEdge.name, ontologyType, notifications]);


    const handleExecuteQuery = useCallback(async (queryIndex: number, progressMonitor?: ProgressMonitor) => {
        const feedSourceRequest: ExecuteOntologyFeedSourcesRequest = {
            feedSource: {
                type: QUERY_TYPE,
                query: queries[queryIndex],
            },
            limit: RESULT_LIMIT,
        };
        const result = await ontologiesConnector.executeFeedSources(ontology.id, ontologyType, vertexOrEdge.name, feedSourceRequest, progressMonitor);

        return result;
    }, [ontology.id, ontologyType, queries, vertexOrEdge.name]);

    const [handleTestQuery, testQueryProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const validationResult = await testQuery(focusedEditorIndex, progressMonitor);
            if (!isEmpty(validationResult.parseErrors)) {
                handleTabChange(ERROR_TAB_KEY);

                return;
            }

            const result = await handleExecuteQuery(focusedEditorIndex);
            handleResultsChange(result, focusedEditorIndex);
            handleTabChange(RESULT_TAB_KEY);
        } catch (e) {
            if (progressMonitor.isCancelled) {
                throw e;
            }
            console.error(e);
            notifications.snackError({ message: messages.validateQueryError }, e as Error);
        }
    }, [focusedEditorIndex, handleExecuteQuery, handleResultsChange, handleTabChange, notifications, testQuery]);

    const [handleSubmitFeedSources, submitFeedSourcesProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const ps:Promise<OntologyFeedSourceValidationResultResponse>[] = [];
            queries.forEach((query, index) => {
                if (!(EMPTY_QUERY_REGEX.test(query))) {
                    const sub = new SubProgressMonitor(progressMonitor, 1);

                    const ret = testQuery(index, sub);

                    ps.push(ret);
                }
            });


            const validationResults = await Promise.all(ps);
            if (validationResults.some((validationResult) => {
                const ret = !isEmpty(validationResult.parseErrors);

                return ret;
            })) {
                return;
            }

            const feedSources = computeFeedSources();

            const sub = new SubProgressMonitor(progressMonitor, 1);
            await ontologiesConnector.putFeedSources(ontology.id, ontologyType, vertexOrEdge.name, feedSources, sub);

            onSubmit?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            console.error(error);

            notifications.snackError({ message: messages.updateFeedSourcesError }, error as Error);
            throw error;
        }
    }, [queries, computeFeedSources, ontology.id, ontologyType, vertexOrEdge.name, onSubmit, testQuery, notifications]);


    const isErrorPanelVisible = activeTab === ERROR_TAB_KEY;
    const isResultPanelVisible = activeTab === RESULT_TAB_KEY;

    const resultsDataProvider = useMemo(() => new SortableArrayDataProvider(results[focusedEditorIndex]?.rows ?? [], false), [focusedEditorIndex, results]);

    const isSelected = (index: number) => index === selectedSourceIndex;

    return {

        queries,
        focusedEditorIndex,
        results,
        errors,
        selectedSourceIndex,


        computeFeedSources,
        setQueriesFromFeedSources,
        exportFeedSource,
        importFeedSources,
        handleExecuteQuery,
        handleTestQuery,
        isTestingQuery: testQueryProgressMonitor?.isRunning,

        handleSubmitFeedSources,
        isSubmittingFeedSource: submitFeedSourcesProgressMonitor?.isRunning,

        isErrorPanelVisible,
        isResultPanelVisible,

        isLoadingFeedSources: feedSourcesProgressMonitor?.isRunning,

        resultsDataProvider,

        handleAddSource,
        handleSelectedSourceIndexChange,
        handleRemoveEditor,
        isSelected,

        handleEditorChange,
        handleFocus,

        handleTabChange,

        activeTab,
    };
};


const isOntologyFeedSources = (x: any): x is OntologyFeedSources => {
    if (x?.feedSources !== undefined && !isArray(x?.feedSources)) {
        return false;
    }

    return (x?.feedSources as any[]).every((feedSource) => isString(feedSource.type) && isString(feedSource.query));
};
