import {
    compact,
    forEach,
    isEmpty,
    isEqual,
    isFunction,
    map,
    mean,
    merge,
    size,
    size as lodashSize,
    slice,
    sortedUniq,
} from 'lodash';
import { defineMessages, FormatNumberOptions, IntlShape } from 'react-intl';
import { Donut, Glyph, Halo, Items, Link, LinkEnd, LinkFlowOptions, Location, Node, NodeLabel, NodeLabelContainer } from 'regraph';

import { LineAnimation } from '../../../components/common/graph/customisation/graph-style';
import { VertexId, VertexStyle } from '../../model/vertex';
import { EdgeId } from '../../model/edge';
import { DEFAULT_MAP_CLUSTER_STYLE, VertexUtils } from '../../utils/vertex-utils';
import { UniverseType } from '../../model/universe';
import { BlobImagesProvider } from '../../../utils/blob-images-provider';
import { ImageAlignmentProvider } from '../../../utils/image-alignment-provider';
import { ThumbnailSize } from 'src/exploration/model/thumbnail-size';
import { getDataExplorationApi } from 'src/utils/connectors/api-url';
import { MapVisualization, MapVisualizationCluster, MapVisualizationVertex } from '../../model/map-visualization';
import { GraphVisualization, GraphVisualizationCluster } from '../../model/graph-visualization';
import { clusterSizeRange, verticesSizeRange } from '../../constants/nodes-size-range';
import { edgesSizeRange } from '../../constants/edges-size-range';
import {
    DEFAULT_CLUSTER_STYLE,
    DEFAULT_FLAGGED_OBJECT_BADGE_STYLE,
    DEFAULT_VERTEX_STYLE,
    DEFAULT_EXTRACTED_OBJECT_ICON_STYLE,
} from '../../constants/default-vertex-style';
import { getClusterLabel } from '../../utils/cluster-utils';
import { lightenColor } from '../../../utils/color-utils';
import { DEFAULT_EDGE_STYLE } from '../../constants/default-edge-style';
import { getTitle } from '../../utils/node-utils';
import { CasePermissions } from '../../../model/folder-case-piece';
import { FullOntology } from 'src/settings/universes/ontology/types';
import { ClusterId } from '../../model/cluster';
import {
    EndpointType,
    Visualization,
    VisualizationClusterEdge,
    VisualizationEdge,
    VisualizationVertex,
} from '../../model/exploration-visualization';
import { SelectableObjects } from './selectable-objects';
import { RegraphItemType, ZoomType } from './types';
import { MapDisplayOptions } from './map-widget/use-map-display-options';


export interface ExtendedGlyph extends Glyph {
    type: string;
}

const DARK_BLUE = '#061A34';
const LIGHTER_BLUE = '#10478E';

const EMPTY_VISUALIZATION: Items = {};

const NOTATION_COMPACT: FormatNumberOptions = { notation: 'compact' };
const TRANSPARENT_COLOR = 'rgba(255,255,255)';
const BACKGROUND_COLOR = 'rgba(255,255,255, 0.7)';

const DEFAULT_HALOS: Halo[] = [
    {
        color: '#2873d6',
        radius: 30,
        width: 20,
    },
];

const COMMON_NODE_LABEL_STYLE: NodeLabelContainer = {
    margin: 4,
    padding: 4,
    border: {
        radius: 5,
    },
};

const FLAG_LABEL_ICON_STYLE: NodeLabelContainer = {
    padding: 5.5,
    border: {
        radius: { topLeft: 5, topRight: 0, bottomRight: 0, bottomLeft: 5 },
    },
};

const FLAG_LABEL_TEXT_STYLE: NodeLabelContainer = {
    padding: 4,
    border: {
        radius: { topLeft: 0, topRight: 5, bottomRight: 5, bottomLeft: 0 },
    },
};

const ARROW_TRUE: LinkEnd = { arrow: true };

const DEFAULT_FLAG_GLYPH: ExtendedGlyph = {
    color: DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.backGroundColor,
    fontIcon: {
        text: DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.icon,
        color: DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.fontColor,
    },
    angle: 45,
    size: DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.size,
    type: 'flag',
};

const CREATE_RELATIONSHIP_GLYPH: ExtendedGlyph = {
    color: DARK_BLUE,
    fontIcon: {
        text: 'icon-plus',
        color: '#FFF',
    },
    angle: 3.5 * 90,
    type: 'create-relation',
};

const LINE_MAX_LENGTH = 30;

const messages = defineMessages({
    unknownLabel: {
        id: 'exploration.render-engine.unknownLabel',
        defaultMessage: 'Unknown',
    },
    undefinedLabel: {
        id: 'exploration.render-engine.undefinedLabel',
        defaultMessage: 'Undefined',
    },
});

export interface HoveredObjectInfo {
    type: RegraphItemType;
    itemId: string;
    subItemType?: string;
}

export interface RenderContext {
    universe: UniverseType | FullOntology;
    intl: IntlShape;

    blobImagesProvider: BlobImagesProvider;
    imageAlignmentProvider: ImageAlignmentProvider;

    dragging?: boolean;
    selectableObjects?: SelectableObjects;

    casePermissions?: CasePermissions;
    hoveredObjectInfo?: HoveredObjectInfo;

    allowCreateRelation?: boolean;

    shortestPathItems?: Items;
    thumbnailSize: ThumbnailSize;

    zoomMode: ZoomType;
}

export interface GraphRenderContext extends RenderContext {
    graphVisualization?: GraphVisualization;

    previousItems?: Items;

}

export interface MapRenderContext extends RenderContext {
    mapDisplayOptions: MapDisplayOptions;

    mapVisualization?: MapVisualization;

    previousItems?: Items;
}

function computeMapVertex(
    vertex: MapVisualizationVertex,
    renderContext: MapRenderContext,
): Node {
    const style: VertexStyle = merge({}, DEFAULT_VERTEX_STYLE, vertex.style);
    const {
        iconColor,
        fillColor,
        strokeColor,
        badgeBlink,
        badgeSize,
        badgeColor,
        badgeIcon,
        badgeFontFamily,
        size,
    } = style;
    const {
        mapVisualization,
        hoveredObjectInfo,
        selectableObjects,
        mapDisplayOptions,
    } = renderContext;
    const {
        vertexBadge,
    } = mapDisplayOptions;

    if (!vertex.coordinates) {
        // eslint-disable-next-line no-debugger
        debugger;
    }

    const selected = vertex.isSelected || false;
    const thumbnailSize = ThumbnailSize.Small;
    const minNodeSize = 0.5; // Prevent tiny nodes case

    const getMapBadgeSize = (badeSize?: number) => {
        return badeSize ? badeSize + 0.3 : badgeSize;
    };
    const badgeGlyph: Glyph | undefined = badgeIcon && vertexBadge ? {
        color: badgeColor,
        blink: badgeBlink,
        fontIcon: badgeIcon
            ? {
                text: badgeIcon,
                fontFamily: badgeFontFamily,
                color: 'white',
            }
            : undefined,
        angle: 135,
        size: getMapBadgeSize(badgeSize),
    } : undefined;

    let isSelectable = true;
    if (selectableObjects) {
        const isSelectableVertex = selectableObjects.isSelectableVertex;

        if (isSelectableVertex === false) {
            isSelectable = false;
        } else if (isFunction(isSelectableVertex)) {
            isSelectable = isSelectableVertex(selectableObjects, mapVisualization!, vertex) ?? true;
        }
    }

    const pickMode = selectableObjects?.pickMode;
    const allowWrite = !!renderContext.casePermissions?.allowWrite;
    const showCreateRelationshipGlyph = allowWrite
        && !pickMode
        && (hoveredObjectInfo?.type === 'vertex' && hoveredObjectInfo.itemId === vertex.id)
        && isSelectable;
    const createRelationshipGlyph: Glyph | undefined = showCreateRelationshipGlyph ? {
        ...CREATE_RELATIONSHIP_GLYPH,
        size: 1.5, // ???
    } : undefined;

    const node: Node = {
        fade: (pickMode && !isSelectable) || (renderContext.dragging && selected),
        cutout: true,
        color: selected ? iconColor : fillColor,
        size: Math.max((2 * (size || 1) / 3), minNodeSize), // Calculation to get vertex size independently of graph size config

        coordinates: {
            lat: vertex.coordinates.latitude,
            lng: vertex.coordinates.longitude,
        },
        glyphs: compact([createRelationshipGlyph, badgeGlyph]),
    };
    if (strokeColor && strokeColor !== 'none') {
        node.border = {
            color: strokeColor,
            width: 2,
        };
    } else {
        node.border = {
            color: iconColor,
            width: 2,
        };
    }

    node.label = getMapVertexLabel(renderContext, vertex, style);

    fillMediumNodeIcon(
        node,
        renderContext.universe,
        selected,
        style,
        renderContext.blobImagesProvider,
        renderContext.imageAlignmentProvider,
        thumbnailSize,
    );

    return node;
}

function computeMapCluster(cluster: MapVisualizationCluster, style: VertexStyle, renderContext: MapRenderContext): Node {
    const intl = renderContext.intl;
    const {
        selectableObjects,
        mapVisualization,
        hoveredObjectInfo,
    } = renderContext;

    const pickMode = selectableObjects?.pickMode;

    const isPartiallySelected = !!(cluster.selectedCount && cluster.selectedCount !== cluster.count);
    const isSelected = cluster.selectedCount === cluster.count;

    let clusterCountText: string | undefined;
    let donut: Donut | undefined;

    const coordinates: Location = {
        lat: cluster.coordinates.latitude,
        lng: cluster.coordinates.longitude,
    };

    const digitsCount = Math.log(cluster.value) * Math.LOG10E + 1 | 0;
    const clusterSize = DEFAULT_MAP_CLUSTER_STYLE.minClusterSize + (0.2 * (digitsCount - 1));
    const label: NodeLabel = {
        text: intl.formatNumber(cluster.value, NOTATION_COMPACT),
        bold: true,
        color: isSelected ? style.fillColor : style.iconColor,
        fontSize: Math.max(
            DEFAULT_MAP_CLUSTER_STYLE.labelFontsize - (digitsCount * 6),
            DEFAULT_MAP_CLUSTER_STYLE.minLabelFontSize,
        ),
        backgroundColor: DEFAULT_MAP_CLUSTER_STYLE.labelBackgroundColor,
    };

    const isObjectDonutHovered = hoveredObjectInfo?.type === 'cluster'
        && hoveredObjectInfo.itemId === cluster.id
        && hoveredObjectInfo.subItemType === 'donut';

    const selectedCount = cluster.selectedCount ?? 0;

    clusterCountText = intl.formatNumber(cluster.count, NOTATION_COMPACT);

    if (isPartiallySelected) {
        clusterCountText = `${intl.formatNumber(selectedCount, NOTATION_COMPACT)}/${clusterCountText}`;
        const selectedPercentage = (selectedCount * 100) / cluster.count;
        const restPercentage = 100 - selectedPercentage;

        donut = {
            width: 7,
            segments: [
                {
                    size: selectedPercentage,
                    color: DARK_BLUE,
                },
                {
                    size: restPercentage,
                    color: LIGHTER_BLUE,
                },
            ],
        };
    }

    const selectedCountGlyph: Glyph = {
        color: DARK_BLUE,
        label: {
            text: clusterCountText,
            fontFamily: DEFAULT_VERTEX_STYLE.fontFamily,
            color: 'white',
        },
        size: 2,
        radius: 60,
        angle: 0,
    };

    let isSelectable = true;
    if (selectableObjects) {
        const isSelectableCluster = selectableObjects.isSelectableCluster;

        if (isSelectableCluster === false) {
            isSelectable = false;
        } else if (isFunction(isSelectableCluster)) {
            isSelectable = isSelectableCluster(selectableObjects, mapVisualization!, cluster) ?? true;
        }
    }

    return {
        coordinates,
        fade: (pickMode && !isSelectable) || (renderContext.dragging && isSelected),
        border: {
            color: isSelected ? style.fillColor : style.iconColor,
            width: DEFAULT_MAP_CLUSTER_STYLE.borderWidth,
        },
        color: isSelected ? style.iconColor : style.fillColor,
        donut,
        label,
        size: clusterSize,
        glyphs: isObjectDonutHovered && isPartiallySelected ? [selectedCountGlyph] : [],
    };
}

export function computeMapItems(renderContext: MapRenderContext): Items {
    const {
        mapVisualization,
        previousItems,
        mapDisplayOptions,
    } = renderContext;
    const { relations } = mapDisplayOptions;
    if (!mapVisualization) {
        return EMPTY_VISUALIZATION;
    }

    let largestCluster = 0;
    forEach(mapVisualization.clusters, (clusterItem) => {
        if (clusterItem.value !== undefined && clusterItem.value > largestCluster) {
            largestCluster += clusterItem.value;
        }
    });
    const clusterEdgesContentCounts = map(mapVisualization.clusterEdges, (edge): number => edge.count || 1);

    const items: Items = {};
    let newItems = false;

    forEach(mapVisualization.vertices, (vertex) => {
        const vertexItem = computeMapVertex(vertex, renderContext);

        const key = `vertex:${vertex.id}`;

        const previous = previousItems?.[key];
        if (previous && isEqual(previous, vertexItem)) {
            items[key] = previous;

            return;
        }

        items[key] = vertexItem;
        newItems = true;
    });

    forEach(mapVisualization.clusters, (cluster) => {
        const clusterItem = computeMapCluster(cluster,
            {
                fillColor: DARK_BLUE,
                iconColor: '#fff',
            } as VertexStyle,
            renderContext,
        );

        const key = `cluster:${cluster.id}`;

        const previous = previousItems?.[key];
        if (previous && isEqual(previous, clusterItem)) {
            items[key] = previous;

            return;
        }
        items[key] = clusterItem;
        newItems = true;
    });

    forEach(mapVisualization.edges, (edge) => {
        if (!relations) {
            return;
        }

        const edgeItem = renderGraphVisualizationEdge(
            edge,
            `vertex:${edge.from}`,
            `vertex:${edge.to}`,
            renderContext,
            mapVisualization!,
            edge.isSelected ?? false,
        );

        if (!edgeItem) {
            return;
        }

        const key = `edge:${edge.id}`;

        const previous = previousItems?.[key];
        if (previous && isEqual(previous, edgeItem)) {
            items[key] = previous;

            return;
        }

        items[key] = edgeItem;
        newItems = true;
    });

    forEach(mapVisualization.clusterEdges, (clusterEdge) => {
        const edge: VisualizationEdge = {
            title: clusterEdge.title,
            id: clusterEdge.id,
            groupSize: clusterEdge.count,
            from: clusterEdge.from.type === EndpointType.Cluster ? `cluster:${clusterEdge.from.id}`
                : clusterEdge.from.type === EndpointType.Vertex ? `vertex:${clusterEdge.from.id}` : '',
            to: clusterEdge.to.type === EndpointType.Cluster ? `cluster:${clusterEdge.to.id}`
                : clusterEdge.to.type === EndpointType.Vertex ? `vertex:${clusterEdge.to.id}` : '',
            style: clusterEdge?.style,
            type: clusterEdge?.type,
        };

        const selected = clusterEdge.isSelected ?? false;

        const edgeItem = renderGraphVisualizationEdge(edge, edge.from, edge.to, renderContext, renderContext.mapVisualization!, selected, clusterEdgesContentCounts);

        if (!edgeItem) {
            return;
        }

        const key = `cluster-edge:${edge.id}`;

        const previous = previousItems?.[key];
        if (previous && isEqual(previous, edgeItem)) {
            items[key] = previous;

            return;
        }

        items[key] = edgeItem;
        newItems = true;
    });

    if (!newItems && previousItems) {
        if (size(previousItems) === size(items)) {
            return previousItems;
        }
    }

    return items;
}


export function computeGraphItems(renderContext: GraphRenderContext): Items {
    const {
        graphVisualization,
        previousItems,
    } = renderContext;

    if (!graphVisualization) {
        return {};
    }

    const filteredVertices = graphVisualization.vertices;
    const clusters = graphVisualization.clusters;
    const clusterNodeContentCounts = map(clusters, (cluster) => cluster.count);
    const clusterEdges = graphVisualization.clusterEdges;
    const clusterEdgesContentCounts = map(clusterEdges, (edge) => edge.count || 1);
    const items: Items = {};

    let newItems = false;

    forEach(filteredVertices, (vertex: VisualizationVertex, vertexId: VertexId) => {
        const vertexItem = renderGraphVisualizationVertex(
            vertex,
            !!vertex.isSelected,
            renderContext,
        );

        const key = `vertex:${vertexId}`;

        const prev = previousItems?.[key];
        if (prev && isEqual(prev, vertexItem)) {
            items[key] = prev;

            return;
        }

        items[key] = vertexItem;
        newItems = true;
    });

    forEach(graphVisualization.edges, (edge: VisualizationEdge, edgeId: EdgeId) => {
        const edgeItem = renderGraphVisualizationEdge(
            edge,
            `vertex:${edge.from}`,
            `vertex:${edge.to}`,
            renderContext,
            graphVisualization!,
            edge.isSelected ?? false,
        );

        if (!edgeItem) {
            return;
        }

        const key = `edge:${edgeId}`;

        const prev = previousItems?.[key];
        if (prev && isEqual(prev, edgeItem)) {
            items[key] = prev;

            return;
        }

        items[key] = edgeItem;
        newItems = true;
    });

    forEach(graphVisualization.clusters, (cluster: GraphVisualizationCluster, clusterId: ClusterId) => {
        const selected = cluster.count === cluster.selectedCount;

        const clusterItem = computeCluster(
            cluster,
            selected,
            renderContext,
            clusterNodeContentCounts,
        );

        const key = `cluster:${clusterId}`;

        const prev = previousItems?.[key];
        if (prev && isEqual(prev, clusterItem)) {
            items[key] = prev;

            return;
        }

        items[key] = clusterItem;
        newItems = true;
    });

    forEach(graphVisualization.clusterEdges, (clusterEdge: VisualizationClusterEdge) => {
        const edge: VisualizationEdge = {
            title: clusterEdge.title,
            id: clusterEdge.id,
            groupSize: clusterEdge.count,
            from: clusterEdge.from.type === EndpointType.Cluster ? `cluster:${clusterEdge.from.id}`
                : clusterEdge.from.type === EndpointType.Vertex ? `vertex:${clusterEdge.from.id}` : '',
            to: clusterEdge.to.type === EndpointType.Cluster ? `cluster:${clusterEdge.to.id}`
                : clusterEdge.to.type === EndpointType.Vertex ? `vertex:${clusterEdge.to.id}` : '',
            style: clusterEdge?.style,
            type: clusterEdge?.type,
        };

        const clusterItem = renderGraphVisualizationEdge(edge, edge.from, edge.to, renderContext, graphVisualization!, false, clusterEdgesContentCounts);

        if (!clusterItem) {
            return;
        }

        const key = `cluster-edge:${clusterEdge.id}`;

        const prev = previousItems?.[key];
        if (prev && isEqual(prev, clusterItem)) {
            items[key] = prev;

            return;
        }

        items[key] = clusterItem;
        newItems = true;
    });

    if (!newItems && previousItems) {
        if (size(previousItems) === size(items)) {
            return previousItems;
        }
    }

    return items;
}

export function renderGraphVisualizationVertex(
    vertex: VisualizationVertex,
    selected: boolean,
    renderContext: GraphRenderContext,
): Node<VisualizationVertex> {
    const style: VertexStyle = vertex.style ? (merge({}, DEFAULT_VERTEX_STYLE, vertex.style)) : DEFAULT_VERTEX_STYLE;

    const {
        iconColor,
        fillColor,
        size,
        iconFontFamily,
        iconName,
        strokeColor,
    } = style;

    const {
        graphVisualization,
        selectableObjects,
        dragging,
    } = renderContext;

    const pickMode = selectableObjects?.pickMode;
    const groups = vertex.groupIds;
    let nodeData: Record<string, string> | undefined = undefined;
    if (groups?.length) {
        const groupId = groups[0];
        nodeData = {
            group: groupId,
        };
    }

    let isSelectable = true;
    if (selectableObjects) {
        const isSelectableVertex = selectableObjects.isSelectableVertex;

        if (isSelectableVertex === false) {
            isSelectable = false;
        } else if (isFunction(isSelectableVertex)) {
            isSelectable = isSelectableVertex(selectableObjects, graphVisualization!, vertex) ?? true;
        }
    }

    const node: Node = {
        size,
        fade: (pickMode && !isSelectable) || (dragging && vertex.isSelected),
        halos: getObjectHalos(renderContext, vertex),
        color: selected ? (iconColor || strokeColor) : fillColor,
        cutout: true,
        glyphs: getGraphObjectGlyphs(style, renderContext, vertex, selected),
        // type: vertex.type,
        data: nodeData,
    };
    if (strokeColor && strokeColor !== 'none') {
        node.border = {
            color: strokeColor, //selected ? fillColor : strokeColor,
            width: 3,
        };
    } else {
        node.border = {
            color: iconColor,
            width: 3,
        };
    }

    if (iconName) {
        node.fontIcon = {
            text: iconName,
            fontFamily: iconFontFamily,
            color: selected ? fillColor : iconColor,
        };
    }
    const zoomMode = renderContext.zoomMode;
    const showLabel = (zoomMode === 'big' || zoomMode === 'medium');
    const label: NodeLabelContainer[] = [];
    if (showLabel) {
        label.push({
            ...COMMON_NODE_LABEL_STYLE,
            text: splitLabel(VertexUtils.getTitle(renderContext.universe, vertex.title), LINE_MAX_LENGTH),
            position: 's',
            color: selected ? fillColor : iconColor,
            fontFamily: DEFAULT_VERTEX_STYLE.fontFamily,
            backgroundColor: selected ? iconColor : TRANSPARENT_COLOR,
        });

        const isExtractedFromCluster = vertex.isExtracted;
        if (isExtractedFromCluster) {
            label.push({
                ...COMMON_NODE_LABEL_STYLE,
                position: { vertical: 'inherit' },
                fontIcon: {
                    text: DEFAULT_EXTRACTED_OBJECT_ICON_STYLE.icon,
                    color: selected ? fillColor : iconColor,
                },
                backgroundColor: selected ? iconColor : TRANSPARENT_COLOR,
            });
        }

        const flaggedVertex = !!vertex.flag;
        if (flaggedVertex) {
            label.push({
                ...FLAG_LABEL_ICON_STYLE,
                position: 'ne',
                fontIcon: {
                    text: DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.icon,
                    color: DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.fontColor,
                },
                backgroundColor: BACKGROUND_COLOR,
            });
        }

        const vertexFlagLabel = vertex.flag?.label;
        if (vertexFlagLabel) {
            label.push({
                ...FLAG_LABEL_TEXT_STYLE,
                color: selected ? fillColor : iconColor,
                text: splitLabel(vertexFlagLabel, LINE_MAX_LENGTH),
                position: { vertical: 'inherit' },
                fontFamily: DEFAULT_VERTEX_STYLE.fontFamily,
                backgroundColor: selected ? iconColor : BACKGROUND_COLOR,
            });
        }
    }

    node.label = label;

    fillMediumNodeIcon(
        node,
        renderContext.universe,
        selected,
        style,
        renderContext.blobImagesProvider,
        renderContext.imageAlignmentProvider,
        renderContext.thumbnailSize,
    );

    return node;
}

function computeCluster(
    cluster: GraphVisualizationCluster,
    selected: boolean,
    renderContext: GraphRenderContext,
    counts: number[],
): Node {
    const {
        intl,
        graphVisualization,
        selectableObjects,
    } = renderContext;
    const label = getClusterLabel(cluster.title, intl, messages.undefinedLabel, messages.unknownLabel);
    const style: VertexStyle = (cluster.style) ? merge({}, DEFAULT_CLUSTER_STYLE, cluster.style) : DEFAULT_CLUSTER_STYLE;

    const {
        iconColor,
        fillColor,
        size,
        iconFontFamily,
        iconName,
        strokeColor,
        badgeBlink,
        badgeSize,
        badgeColor,
        badgeIcon,
        badgeFontColor,
        badgeFontFamily,
        iconScale,
        offsetX,
        offsetY,
    } = style;

    const pickMode = renderContext.selectableObjects?.pickMode;

    const groupSize = cluster.count;
    let _size = size;
    let clusterCountText: string | undefined;
    let donut: Donut | undefined;

    if (groupSize) {
        _size = size ?? computeGraphItemsSize(
            counts.length > 1 ? clusterSizeRange : verticesSizeRange,
            counts)[groupSize];

        clusterCountText = renderContext.intl.formatNumber(groupSize, NOTATION_COMPACT);
        if (cluster?.selectedCount && cluster.selectedCount !== groupSize) {
            clusterCountText = `${renderContext.intl.formatNumber(cluster.selectedCount, NOTATION_COMPACT)}/${clusterCountText}`;
            const selectedPercentage = (cluster.selectedCount * 100) / groupSize;
            const restPercentage = 100 - selectedPercentage;
            const lightColor = lightenColor(iconColor!, 20);

            donut = {
                width: 7,
                segments: [
                    {
                        size: selectedPercentage,
                        color: iconColor,
                    },
                    {
                        size: restPercentage,
                        color: lightColor,
                    },
                ],
            };
        }
    }

    const nodeLabel: NodeLabelContainer[] = [];
    nodeLabel.push({
        ...COMMON_NODE_LABEL_STYLE,
        text: splitLabel(`${label}${clusterCountText ? ` (${clusterCountText})` : ''}`, LINE_MAX_LENGTH),
        fontFamily: DEFAULT_CLUSTER_STYLE.fontFamily,
        color: selected ? fillColor : iconColor,
        position: 's',
        backgroundColor: selected ? iconColor : TRANSPARENT_COLOR,
    });

    const badgeGlyph: ExtendedGlyph | undefined = badgeIcon ? {
        color: badgeColor,
        blink: badgeBlink,
        fontIcon: badgeIcon
            ? {
                text: badgeIcon,
                color: badgeFontColor,
                fontFamily: badgeFontFamily,
            }
            : undefined,
        angle: 135,
        size: badgeSize,
        type: 'badge',
    } : undefined;


    let isSelectable = true;
    if (selectableObjects) {
        const isSelectableCluster = selectableObjects.isSelectableCluster;

        if (isSelectableCluster === false) {
            isSelectable = false;
        } else if (isFunction(isSelectableCluster)) {
            isSelectable = isSelectableCluster(selectableObjects, graphVisualization!, cluster) ?? true;
        }
    }

    const node: Node = {
        fade: (pickMode && !isSelectable) || (renderContext.dragging && selected),
        size: _size,
        color: selected ? iconColor : fillColor,
        cutout: true,
        glyphs: compact([badgeGlyph]),
        donut,
        label: nodeLabel,
    };
    if (strokeColor && strokeColor !== 'none') {
        node.border = {
            color: strokeColor,
            width: 3,
        };
    } else {
        node.border = {
            color: iconColor,
            width: 3,
        };
    }

    if (iconName) {
        node.fontIcon = {
            text: iconName,
            fontFamily: iconFontFamily,
            color: selected ? fillColor : iconColor,
        };
    }

    if (iconName && (iconScale || offsetX || offsetY)) {
        renderContext.imageAlignmentProvider?.add(iconName, {
            size: iconScale,
            dx: offsetX,
            dy: offsetY,
        });
    }

    return node;
}

export function renderGraphVisualizationEdge(
    edge: VisualizationEdge,
    from: string,
    to: string,
    renderContext: RenderContext,
    visualization?: Visualization,
    selected?: boolean,
    counts?: number[],
): Link | null {
    const {
        groupSize,
        style,
        id,
        title,
    } = edge;
    const {
        selectableObjects,
        //        graphVisualization,
    } = renderContext;

    const pickMode = selectableObjects?.pickMode;

    const edgeStyle = merge({}, DEFAULT_EDGE_STYLE, style);
    const label = getTitle(title);
    const {
        color,
        size: width,
        lineAnimation,
        lineStyle,
    } = edgeStyle;

    if (lineStyle === 'hidden') {
        return null;
    }

    let flow: boolean | LinkFlowOptions | undefined;
    if (lineAnimation === LineAnimation.Fast) {
        flow = {
            velocity: 7,
        };
    } else if (lineAnimation === LineAnimation.Slow) {
        flow = {
            velocity: 3,
        };
    }

    let _width = width;
    if (groupSize) {
        if (!counts) {
            _width = 1;
        } else {
            const width = computeGraphItemsSize(edgesSizeRange, counts)[groupSize];
            _width = width;
        }
    }

    let isSelectable = true;
    if (selectableObjects && visualization) {
        if ((edge as VisualizationEdge).type) {
            // Not a cluster-edge
            if (selectableObjects.isSelectableEdge === false) {
                isSelectable = false;
            } else {
                isSelectable = selectableObjects.isSelectableEdge?.(selectableObjects, visualization, edge as VisualizationEdge) ?? true;
            }
        } else if (selectableObjects.isSelectableClusterEdge === false) {
            isSelectable = false;
        } else {
            isSelectable = selectableObjects.isSelectableClusterEdge?.(selectableObjects, visualization, edge) ?? true;
        }
    }

    const link: Link = {
        fade: (pickMode && !isSelectable),
        id1: from,
        id2: to,
        end2: ARROW_TRUE,
        color: selected ? '#2873d6' : color,
        width: selected ? 2 * _width : _width,
        lineStyle: lineStyle.toLowerCase() as 'solid' | 'dashed' | 'dotted',
        flow,
        label: {
            text: label + (
                groupSize ?
                    ` (${renderContext.intl.formatNumber(groupSize, NOTATION_COMPACT)})`
                    :
                    ''
            ),
            backgroundColor: 'white',
            fontFamily: 'Roboto',
            fontSize: 12,
            color: selected ? '#2873d6' : '#061a34',
            bold: selected,
        },
    };

    if (renderContext.shortestPathItems?.[id]) {
        link.color = '#2873d6';
        link.width = 10;
    }

    return link;
}

// compute the graph items size (Nodes & Edges)
export function computeGraphItemsSize(
    sizeRange: { maxSize: number; minSize: number },
    items: number[],
): { [count: number]: number } {
    const itemsCount: number[] = sortedUniq(items.sort((a, b) => a - b));
    const {
        maxSize,
        minSize,
    } = sizeRange;
    const itemsSize: { [count: number]: number } = {};
    if (lodashSize(itemsCount) === 1) {
        itemsSize[itemsCount[0]] = mean([maxSize, minSize]);
    } else {
        const maxCount = itemsCount[lodashSize(itemsCount) - 1];
        const minCount = itemsCount[0];
        itemsSize[maxCount] = maxSize;
        itemsSize[minCount] = minSize;
        slice(itemsCount, 1, -1).forEach(count => {
            const vertexSize = minSize + (maxSize - minSize) * (count - minCount) / (maxCount - minCount);
            itemsSize[count] = vertexSize;
        });
    }

    return itemsSize;
}

export function fillMediumNodeIcon(
    node: Node,
    universe: UniverseType | FullOntology,
    selected: boolean,
    style: VertexStyle,
    blobImagesProvider: BlobImagesProvider,
    imageAlignmentProvider: ImageAlignmentProvider,
    thumbnailSize?: ThumbnailSize,
): void {
    const {
        imageUrl,
        iconName,
        iconFontFamily,
        iconColor, // = DEFAULT_VERTEX_STYLE.iconColor,
        fillColor, // = DEFAULT_VERTEX_STYLE.fillColor,
        strokeColor,
        iconScale,
        offsetX,
        offsetY,
    } = style;

    if (imageUrl) {
        const url = VertexUtils.computeVertexImageURL(universe, imageUrl, thumbnailSize);

        let blobImageUrl;
        if (url) {
            blobImageUrl = blobImagesProvider.getBlobURI(url, getDataExplorationApi()!);
        }
        if (!blobImageUrl) {
            return;
        }

        imageAlignmentProvider?.add(blobImageUrl, {
            size: 0.9,
            dx: offsetX,
            dy: offsetY,
        });
        // if (iconScale || offsetX || offsetY) {
        //     imageAlignmentProvider?.add(blobImageUrl, {
        //         size: iconScale,
        //         dx: offsetX,
        //         dy: offsetY,
        //     });
        // }
        node.image = blobImageUrl;

        return;
    }

    if (iconName) {
        node.fontIcon = {
            text: iconName,
            fontFamily: iconFontFamily,
            color: selected ? fillColor : iconColor,
        };


        //if (isNumber(iconScale) || isNumber(offsetX) || isNumber(offsetY)) {
        imageAlignmentProvider.add(iconName, {
            size: iconScale,
            dx: offsetX,
            dy: offsetY,
        });
        //}

        return;
    }
}

function getGraphObjectGlyphs(objectStyle: VertexStyle, renderContext: GraphRenderContext, vertex: VisualizationVertex, selected: boolean): Glyph[] | undefined {
    const {
        hoveredObjectInfo,
        selectableObjects,
        graphVisualization,
    } = renderContext;
    const pickMode = selectableObjects?.pickMode;
    const {
        badgeBlink,
        badgeSize,
        badgeColor,
        badgeIcon,
        badgeFontColor,
        badgeFontFamily,
    } = objectStyle;

    const glyphs: ExtendedGlyph[] = [];

    if (badgeIcon) {
        glyphs.push({
            color: badgeColor,
            blink: badgeBlink,
            fontIcon: badgeIcon
                ? {
                    text: badgeIcon,
                    fontFamily: badgeFontFamily,
                    color: badgeFontColor,
                }
                : undefined,
            angle: 135,
            size: badgeSize,
            type: 'badge',
        });
    }

    let isSelectable = true;
    if (selectableObjects) {
        const isSelectableVertex = selectableObjects.isSelectableVertex;

        if (isSelectableVertex === false) {
            isSelectable = false;
        } else if (isFunction(isSelectableVertex)) {
            isSelectable = isSelectableVertex(selectableObjects, graphVisualization!, vertex) ?? true;
        }
    }

    const allowWrite = !!renderContext.casePermissions?.allowWrite;
    const showCreateRelationshipGlyph = renderContext.allowCreateRelation
        && allowWrite
        && !pickMode
        && (hoveredObjectInfo?.type === 'vertex' && hoveredObjectInfo.itemId === vertex.id)
        && isSelectable;

    if (showCreateRelationshipGlyph) {
        glyphs.push(CREATE_RELATIONSHIP_GLYPH);
    }

    if (isEmpty(glyphs)) {
        return undefined;
    }

    return glyphs;
}

function getObjectHalos(renderContext: GraphRenderContext, vertex: VisualizationVertex): Halo[] | undefined {
    if (!renderContext.shortestPathItems?.[vertex.id]) {
        return;
    }

    const halos = DEFAULT_HALOS;

    return halos;
}

function splitLabel(text: string | undefined, lineMaxLength = 25, totalMaxLength = -1): string | undefined {
    if (!text) {
        return text;
    }

    const lines: string[] = [];

    let line = '';
    const sp = text.split(/(\s+)(\w+)/);
    for (const token of sp) {
        if (line.length + token.length <= lineMaxLength) {
            line += token;
            continue;
        }

        if (line.length) {
            lines.push(line.trim());
        }

        let i = 0;
        for (; i + lineMaxLength < token.length;) {
            lines.push(token.substring(i, i + lineMaxLength));
            i += lineMaxLength;
        }

        line = token.substring(i);
    }

    if (line) {
        lines.push(line.trim());
    }

    let ret = lines.join('\n');

    if (totalMaxLength > 0 && ret.length > totalMaxLength) {
        ret = `${ret.substring(0, totalMaxLength - 1)}…`;
    }

    return ret;
}

function getMapVertexLabel(renderContext: MapRenderContext, vertex: MapVisualizationVertex, style: VertexStyle) {
    const { mapDisplayOptions, zoomMode } = renderContext;
    const { vertexName, flaggedVertex: displayFlag } = mapDisplayOptions;
    const { fillColor, iconColor } = style;
    const label: NodeLabelContainer[] = [];
    const showLabel = (zoomMode === 'big' || zoomMode === 'medium');
    const selected = vertex.isSelected || false;

    if (showLabel) {
        if (vertexName) {
            label.push({
                ...COMMON_NODE_LABEL_STYLE,
                text: splitLabel(VertexUtils.getTitle(renderContext.universe, vertex.title), LINE_MAX_LENGTH),
                position: 's',
                color: selected ? fillColor : iconColor,
                fontFamily: DEFAULT_VERTEX_STYLE.fontFamily,
                backgroundColor: selected ? iconColor : BACKGROUND_COLOR,
            });
        }

        const vertexFlag = !!vertex.flag;
        const vertexFlagLabel = vertex.flag?.label;

        if (displayFlag && vertexFlag) {
            label.push({
                ...FLAG_LABEL_ICON_STYLE,
                position: 'ne',
                fontIcon: {
                    text: DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.icon,
                    color: DEFAULT_FLAGGED_OBJECT_BADGE_STYLE.fontColor,
                },
                backgroundColor: BACKGROUND_COLOR,
            });
        }
        if (displayFlag && vertexFlagLabel) {
            label.push({
                ...FLAG_LABEL_TEXT_STYLE,
                color: selected ? fillColor : iconColor,
                text: splitLabel(vertexFlagLabel, LINE_MAX_LENGTH),
                position: { vertical: 'inherit' },
                fontFamily: DEFAULT_VERTEX_STYLE.fontFamily,
                backgroundColor: selected ? iconColor : BACKGROUND_COLOR,
            });
        }
    }

    return label;
}
