import { useEffect } from 'react';
import Debug from 'debug';
import { first } from 'lodash';

import { ArgonosModule } from '../application/modules';
import { ArgonosModulesRegistry } from '../application/argonos-modules-registry';
import { useCurrentArgonosModule } from '../application/argonos-current-module';
import { UserPermissions } from 'src/model/user';
import { computeOAuthLoginURL } from 'src/utils/connector';


const debug = Debug('common:caches:PagesAccess');

const LAST_EXECUTED_MODULE_ID = 'ARG_LAST_EXECUTED_MODULE_ID';
const CURRENT_MODULE_ID = 'ARG_CURRENT_MODULE_ID';

export function useRecordPageAccess() {
    const argonosModule = useCurrentArgonosModule();

    useEffect(() => {
        debug('useRecordPageAccess', 'Record page', argonosModule);
        if (!argonosModule) {
            window.localStorage?.removeItem(CURRENT_MODULE_ID);

            return;
        }
        window.localStorage?.setItem(LAST_EXECUTED_MODULE_ID, argonosModule.id);
        window.localStorage?.setItem(CURRENT_MODULE_ID, argonosModule.id);
    }, [argonosModule]);
}

export function getLastExecutedModule(): ArgonosModule | undefined {
    const path = window.localStorage.getItem(LAST_EXECUTED_MODULE_ID);
    if (path === null) {
        return undefined;
    }

    const argonosModule = ArgonosModulesRegistry.getInstance().getById(path);
    if (argonosModule?.enabled === false) {
        return undefined;
    }

    return argonosModule;
}

export function redirectToOAuthLogin() {
    const afterLoginRedirect = `${window.location.origin}`;

    const lastModule = getLastExecutedModule();

    const loginUrl = computeOAuthLoginURL(afterLoginRedirect, lastModule?.id);

    document.location.replace(loginUrl);
}

export function unauthorizedPageAccessed() {
    debug('unauthorizedPageAccessed');
    // The page is no longer accessible
    window.localStorage.removeItem(CURRENT_MODULE_ID);
}

/**
 * Return the most revelant module to redirect the user,
 * i.e. the last executed module or the available module with the highest loginOrder
 */
export function computePageAccess(availableModules: Readonly<ArgonosModule<any>[]>, permissions: UserPermissions): ArgonosModule | undefined {
    debug('computePageAccess', 'argonosModules=', availableModules);

    const lastModule = getLastExecutedModule();
    const isLastModuleAvailable = lastModule && availableModules.some((module) => module.id === lastModule.id);
    if (lastModule?.routeURL && isLastModuleAvailable) {
        debug('computePageAccess', 'using last module id=', lastModule.id);

        return lastModule;
    }

    const modulesSortedByLoginOrder: ArgonosModule[] = [...availableModules].sort((m1, m2) => {
        const order1 = m1.loginOrder ?? 0;
        const order2 = m2.loginOrder ?? 0;

        return order2 - order1;
    });

    return first(modulesSortedByLoginOrder);
}
