import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { ProcessId } from '../model/process';
import { ProcessStatusState } from './process-status-state';
import { BasicStates, useBasicStates } from '../../utils/rt-states/use-basic-states';

export function preparationProcessStatusStateFactory(url: string, processId: ProcessId) {
    return new ProcessStatusState(url, processId);
}

export function computeProcessStatusStateURL(processId: ProcessId) {
    return `/processes/${processId}/status`;
}

export function usePreparationProcessStatusState(processId: ProcessId): ProcessStatusState;
export function usePreparationProcessStatusState(processId: ProcessId | undefined): ProcessStatusState | undefined;

export function usePreparationProcessStatusState(processId: ProcessId | undefined): ProcessStatusState | undefined {
    const state = useBasicState<ProcessStatusState>(
        (url) => preparationProcessStatusStateFactory(url, processId!),
        (processId) ? computeProcessStatusStateURL(processId) : undefined,
    );

    return state;
}

export function usePreparationProcessStatusStates(processIds: Readonly<ProcessId[]>): BasicStates<ProcessStatusState>;
export function usePreparationProcessStatusStates(processIds: undefined): undefined;
export function usePreparationProcessStatusStates(processIds: Readonly<ProcessId[]> | undefined): BasicStates<ProcessStatusState> | undefined;
export function usePreparationProcessStatusStates(processIds: Readonly<ProcessId[]> | undefined): BasicStates<ProcessStatusState> | undefined {
    const state = useBasicStates(
        (url, processId) => preparationProcessStatusStateFactory(url, processId), processIds,
        (processId) => computeProcessStatusStateURL(processId),
    );

    return state;
}
