import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { isFunction } from 'lodash';
import { NumberSize, Resizable, ResizeDirection } from 're-resizable';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ToolContext } from '../arg-tools/tool-context';
import { useToolNodes } from '../arg-tools/use-tool-nodes';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgTooltipPlacement } from '../types';
import { getToolDescription, getToolIcon, getToolLabel, Tool } from '../arg-tools/tool';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { ArgIcon } from '../arg-icon/arg-icon';
import { countVisibleChildren } from '../arg-tools/utils';
import { useUserConfiguration } from '../../../hooks/use-user-configuration';
import { LEFT_ENABLE } from '../utils/resizable';

import './arg-sidebar-menu.less';

const CLASSNAME = 'arg-sidebar-menu';
const DEFAULT_LEFT_WIDTH = 250;
const DEFAULT_MIN_WIDTH = 150;
const DEFAULT_MAX_WIDTH = 512;

export interface ArgSidebarMenuProps<T> {
    toolbarContext: ToolContext<T>;
    children?: ReactNode;
    className?: ClassValue;
    bodyClassName?: ClassValue;
    prefix?: string;
    defaultSelectedKey?: string;
    defaultOpenedKeys?: string[];

    leftConfigurationName?: string;
    leftMinWidth?: number | string;
    leftMaxWidth?: number | string;
    leftDefaultWidth?: number | string;

    environmentContext: T;
}

export function ArgSidebarMenu<T = undefined>(props: ArgSidebarMenuProps<T>) {
    const {
        prefix,
        children,
        className,
        toolbarContext,
        defaultSelectedKey,
        defaultOpenedKeys,
        bodyClassName,
        leftConfigurationName,
        leftMinWidth = DEFAULT_MIN_WIDTH,
        leftMaxWidth = DEFAULT_MAX_WIDTH,
        leftDefaultWidth,
        environmentContext,
    } = props;

    const classNames = useClassNames(CLASSNAME);
    const [selectedKey, setSelectedKey] = useState<string | undefined>(defaultSelectedKey);
    const [openedKeys, setOpenedKeys] = useState<string[] | undefined>(defaultOpenedKeys);

    const [leftPanelWidth, setLeftPanelWidth] = useUserConfiguration<string | number>(
        leftConfigurationName || 'admin.ui.environments.left-panel.width',
        leftDefaultWidth || DEFAULT_LEFT_WIDTH,
    );

    const handleLeftPanelResized = useCallback((event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        const bounds = elementRef.getBoundingClientRect();
        setLeftPanelWidth(bounds.width);
    }, [setLeftPanelWidth]);

    const [toolNodes] = useToolNodes(toolbarContext, environmentContext, prefix);

    const visibleToolNodes = useMemo(() => {
        return toolNodes.filter((tool) => {
            const toolChildren = countVisibleChildren(tool, environmentContext);

            return toolChildren.length > 0;
        });
    }, [environmentContext, toolNodes]);

    const handleMenuItemClick = useCallback((tool: Tool<T>, event: ButtonClickEvent) => {
        setSelectedKey(tool.path);
        tool.onClick?.(tool, environmentContext, event);
    }, []);

    const handleOpenSubMenu = useCallback((tool: Tool<T>, event: ButtonClickEvent) => {
        setOpenedKeys((prev) => {
            if (prev?.includes(tool.path)) {
                return prev?.filter((p) => p !== tool.path);
            }

            return [...(prev || []), tool.path];
        });
    }, []);

    return (
        <div className={classNames('&', className)} data-testid='arg-sidebar-menu'>

            <Resizable
                key='left-panel'
                className={classNames('&-resizable')}
                defaultSize={{
                    width: leftPanelWidth,
                    height: 'auto',
                }}
                onResizeStop={handleLeftPanelResized}
                minWidth={leftMinWidth}
                maxWidth={leftMaxWidth}
                enable={LEFT_ENABLE}
            >

                <aside className={classNames('&-nav')}>
                    {visibleToolNodes.map((node) => {
                        const isSubMenuTitle = !!node.children?.find((c) => c.children);
                        const visibleNodeChildren = node.children ? countVisibleChildren(node, environmentContext) : [];

                        return (
                            <div
                                key={node.path}
                                className={classNames('&-nav-item')}
                            >
                                <div className={classNames('&-nav-item-title')}>
                                    <div className={classNames('&-nav-item-title-label')}>
                                        {renderText(getToolLabel(node, environmentContext))}
                                    </div>
                                    {node.description &&
                                        <div className={classNames('&-nav-item-title-description')} key='description'>
                                            {renderText(getToolDescription(node, environmentContext))}
                                        </div>}
                                </div>

                                {visibleNodeChildren.length > 0 && (
                                    <div className={classNames('&-nav-item-child')}>
                                        {visibleNodeChildren.map((child) => {
                                            const isChildSelected = selectedKey === child.path;
                                            const visibleChildren = child ? countVisibleChildren(child, environmentContext) : [];

                                            const isSubSubMenuTitle = !!child.children;

                                            const childCls = {
                                                selected: isChildSelected,
                                            };

                                            if (isSubSubMenuTitle) {
                                                const isSubMenuOpened = openedKeys?.includes(child.path);

                                                if (!visibleChildren.length) {
                                                    return undefined;
                                                }

                                                return <div
                                                    key={child.key}
                                                    className={classNames('&-nav-item-child', 'depth-1')}
                                                >
                                                    <ArgButton
                                                        type='ghost'
                                                        label={getToolLabel(child, environmentContext)}
                                                        onClick={(event) => handleOpenSubMenu(child, event)}
                                                        right={(
                                                            <ArgIcon
                                                                name={isSubMenuOpened ? 'icon-triangle-up' : 'icon-triangle-down'} />
                                                        )}
                                                        className={classNames('&-nav-item-child-title', 'depth-1')}
                                                    />

                                                    {isSubMenuOpened && visibleChildren.map((c) => {
                                                        childCls.selected = selectedKey === c.path;

                                                        return (
                                                            <ArgButton
                                                                type='ghost'
                                                                key={c.path}
                                                                icon={getToolIcon(c, environmentContext)}
                                                                label={getToolLabel(c, environmentContext)}
                                                                tooltipPlacement={c.tooltipPlacement as ArgTooltipPlacement}
                                                                tooltip={isFunction(c.tooltip) ? renderText(c.tooltip(child, environmentContext)) : renderText(c.tooltip)}
                                                                className={classNames('&-nav-item-child-label', 'depth-1', childCls)}
                                                                onClick={(event) => handleMenuItemClick(c, event)}
                                                            />
                                                        );
                                                    })}
                                                </div>;
                                            }

                                            return (
                                                <ArgButton
                                                    type='ghost'
                                                    key={child.path}
                                                    icon={getToolIcon(child, environmentContext)}
                                                    label={getToolLabel(child, environmentContext)}
                                                    tooltipPlacement={child.tooltipPlacement as ArgTooltipPlacement}
                                                    onClick={(event) => handleMenuItemClick(child, event)}
                                                    tooltip={isFunction(child.tooltip) ? renderText(child.tooltip(child, environmentContext)) : renderText(child.tooltip)}
                                                    className={classNames('&-nav-item-child-title', 'depth-0', childCls)}
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </aside>
            </Resizable>
            <div className={classNames('&-body', bodyClassName)}>
                {children}
            </div>
        </div>
    );
}
