import { LatitudeAndLongitude } from '../common/model/geolocation-json';

export function TextToGeo(text: string): LatitudeAndLongitude | undefined {
    // Define a regex pattern to match latitude and longitude
    const regex = /^([-+]?\d{1,2}\.?\d*)\s+([-+]?\d{0,3}\.?\d*)$/;
    const match = text.match(regex);

    if (!match) {
        return undefined;
    }

    // WORKAROUND : When user writing 0. or 0.00 or empty coordinate,
    // we're considering is not a correct value, it returns undefined
    function isWritingCoordinate(coordinate: string): boolean {
        return (
            !coordinate ||
            coordinate.trim().length === 0 ||
            coordinate.trim().endsWith('.') ||
            isTrailingDecimalZero(coordinate)
        );
    }

    function isTrailingDecimalZero(str: string): boolean {
        const parts = str.split('.');
        if (parts.length !== 2) {
            return false;
        }
        const decimalPart = parts[1];

        return '0' === decimalPart.slice(-1);
    }

    const latitudeMatch = match[1];
    if (isWritingCoordinate(latitudeMatch)) {
        return undefined;
    }
    const longitudeMatch = match[2];
    if (isWritingCoordinate(longitudeMatch)) {
        return undefined;
    }

    const latitude = Number.parseFloat(latitudeMatch);
    const longitude = Number.parseFloat(longitudeMatch);
    if (
        latitude === undefined ||
        isNaN(latitude) ||
        longitude === undefined ||
        isNaN(longitude)
    ) {
        return undefined;
    }

    return { latitude, longitude };
}
