import { defineMessages, useIntl } from 'react-intl';
import React, { useCallback, useMemo } from 'react';
import Debug from 'debug';

import { computeLocale } from '../locales-mapping';
import { RangeDataModel } from './range';
import {
    ArgChangeReason,
    ArgInputDate,
    ArgInputDndConfig,
    ArgInputDroppedItem,
    ArgSliderRange,
    ClassValue,
    dayjs,
    useClassNames,
} from '../../../basic';
import { internalisationDateFormat } from '../../../../utils/dates/internalisation-date-format';
import { ControlParameter } from '../control-parameter';
import { DataType } from '../../data-types';
import { DroppedParameter } from '../dropped-parameter';

import './date-range-picker-with-histogram.less';


interface DateRangePickerWithHistogramProps {
    className?: ClassValue;
    value: RangeDataModel<dayjs.Dayjs>;
    minValue?: dayjs.Dayjs;
    maxValue?: dayjs.Dayjs;
    index: number;
    onChange: (index: number, setState: (prev: RangeDataModel<dayjs.Dayjs>) => RangeDataModel<dayjs.Dayjs>, reason: ArgChangeReason) => void;
    onDelete: (index: number) => void;
    onReset: () => void;
    canDelete?: boolean;
    acceptsParameters?: (type: string) => string[];
    readOnly?: boolean;
}

const debug = Debug('argonode:components:DateRangePickerWithHistogram');

const messages = defineMessages({
    startDatePlaceholder: {
        id: 'common.controls.date-range-picker.StartDate',
        defaultMessage: 'Start date',
    },
    endDatePlaceholder: {
        id: 'common.controls.date-range-picker.EndData',
        defaultMessage: 'End date',
    },
    dropParameter: {
        id: 'common.controls.date-range-picker.DropParameter',
        defaultMessage: 'Drop the parameter here',
    },
});

const COMPONENT_CLASSNAME = 'arg-DateRangePickerWithHistogram';

export function DateRangePickerWithHistogram(props: DateRangePickerWithHistogramProps) {
    const {
        className,
        value,
        minValue,
        maxValue,
        index,
        onChange,
        onDelete,
        onReset,
        canDelete,
        acceptsParameters,
        readOnly,
    } = props;

    const classNames = useClassNames(COMPONENT_CLASSNAME);

    const intl = useIntl();

    const [locale, dateFormat] = useMemo(() => {
        const locale = computeLocale(intl);
        const dateFormat = internalisationDateFormat(intl);

        return [locale, dateFormat];
    }, [intl]);

    const sliderValue = useMemo<[number, number] | undefined>(() => {
        if (!minValue || !maxValue) {
            return undefined;
        }

        let mmin: dayjs.Dayjs | undefined = value.lowerBound?.value && dayjs(value.lowerBound?.value);
        let mmax: dayjs.Dayjs | undefined = value.upperBound?.value && dayjs(value.upperBound?.value);

        if (!mmin || mmin.isBefore(minValue) || value.lowerBound?.parameter) {
            mmin = minValue;
        }
        if (!mmax || mmax.isAfter(maxValue) || value.upperBound?.parameter) {
            mmax = maxValue;
        }

        const sliderValue = [mmin.valueOf(), mmax.valueOf()];

        return sliderValue as [number, number];
    }, [minValue, maxValue, value]);

    const handleBeginDateChange = useCallback((date: dayjs.Dayjs | null, reason: ArgChangeReason) => {
        debug('handleBeginDateChange', 'New begin date=', date);
        console.log('handleBeginDateChange', 'New begin date=', date, reason);
        if (!date) {
            onChange(index, (prev) => {
                if (!prev.lowerBound?.value) {
                    return prev;
                }

                return {
                    ...prev,
                    lowerBound: {
                        included: true,
                        value: undefined,
                    },
                };
            }, reason);

            return;
        }

        onChange(index, (prev) => {
            const m = prev.upperBound?.value;
            if (dayjs.isDayjs(m)) {
                if (m.isBefore(date)) {
                    debug('handleBeginDateChange', 'Change lower and upper');

                    return {
                        lowerBound: {
                            included: true,
                            value: date,
                        },
                        upperBound: {
                            included: true,
                            value: date,
                        },
                    };
                }
            }

            return {
                ...prev,
                lowerBound: {
                    included: true,
                    value: date,
                },
            };
        }, reason);
    }, [index, onChange]);

    const handleEndDateChange = useCallback((date: dayjs.Dayjs | null, reason: ArgChangeReason) => {
        if (!date) {
            onChange(index, (prev) => {
                if (!prev.upperBound?.value) {
                    return prev;
                }

                return {
                    ...prev,
                    upperBound: {
                        included: true,
                        value: undefined,
                    },
                };
            }, reason);

            return;
        }

        onChange(index, (prev) => {
            const m = prev.lowerBound?.value;

            if (dayjs.isDayjs(m)) {
                if (m.isAfter(date)) {
                    return {
                        lowerBound: {
                            included: true,
                            value: date,
                        },
                        upperBound: {
                            included: true,
                            value: date,
                        },
                    };
                }
            }

            return {
                ...prev,
                upperBound: {
                    included: true,
                    value: date,
                },
            };
        }, reason);
    }, [index, onChange]);

    const handleBeginDateParameter = useCallback((type: string, parameter: ControlParameter | undefined) => {
        onChange(index, (prev) => {
            const newValue: RangeDataModel<dayjs.Dayjs> = {
                ...prev,
                lowerBound: {
                    included: true,
                    parameter,
                    value: undefined,
                },
            };

            return newValue;
        }, 'keypress');
    }, [index, onChange]);

    const handleEndDateParameter = useCallback((type: string, parameter: ControlParameter | undefined) => {
        onChange(index, (prev) => {
            const newValue: RangeDataModel<dayjs.Dayjs> = {
                ...prev,
                upperBound: {
                    included: true,
                    parameter,
                    value: undefined,
                },
            };

            return newValue;
        }, 'keypress');
    }, [index, onChange]);

    const handleSliderChange = useCallback((range: [number, number]) => {
        debug('handleSliderChange', 'range=', range);

        // TODO  fix range dragging       console.log('Range=', range[0], range[1]);

        onChange(index, (prev) => {
            return {
                lowerBound: {
                    included: true,
                    parameter: prev.lowerBound?.parameter,
                    value: !prev.lowerBound?.parameter ? dayjs.utc(range[0]) : undefined,
                },
                upperBound: {
                    included: true,
                    parameter: prev.upperBound?.parameter,
                    value: !prev.upperBound?.parameter ? dayjs.utc(range[1]) : undefined,
                },
            };
        }, 'selection');
    }, [onChange, index]);


    const sliderToolTipFormatter = useMemo(() => {
        const formatter = (value?: number) => (
            <span>
                {dayjs.utc(value).format(dateFormat)}
            </span>
        );

        return {
            formatter,
        };
    }, [dateFormat]);

    const cls = {
        'has-trash': canDelete,
    };

    const lowerBoundDndConfig = useMemo<ArgInputDndConfig>(() => ({
        allowedDndTypes: acceptsParameters ? acceptsParameters(DataType.Date) : [],
        allowDrop: acceptsParameters && !value.lowerBound?.parameter && !readOnly,
        renderDroppedItem: ({ type, data }: ArgInputDroppedItem) =>
            <DroppedParameter
                item={data}
                onRemove={() => handleBeginDateParameter(type, undefined)}
                readonly={readOnly}
            />,
        onDrop: handleBeginDateParameter,
        droppedItem: value.lowerBound?.parameter && {
            data: value.lowerBound?.parameter,
            type: DataType.Date,
        },
        dropPlaceholder: messages.dropParameter,
    }), [acceptsParameters, handleBeginDateParameter, value.lowerBound?.parameter, readOnly]);

    const upperBoundDndConfig = useMemo<ArgInputDndConfig>(() => ({
        allowedDndTypes: acceptsParameters ? acceptsParameters(DataType.Date) : [],
        allowDrop: acceptsParameters && !value.upperBound?.parameter && !readOnly,
        renderDroppedItem: ({ type, data }: ArgInputDroppedItem) =>
            <DroppedParameter
                item={data}
                onRemove={() => handleEndDateParameter(type, undefined)}
                readonly={readOnly}
            />,
        onDrop: handleEndDateParameter,
        droppedItem: value.upperBound?.parameter && {
            data: value.upperBound?.parameter,
            type: DataType.Date,
        },
        dropPlaceholder: messages.dropParameter,
    }), [acceptsParameters, handleEndDateParameter, value.upperBound?.parameter, readOnly]);

    return (
        <div
            className={classNames('&', className, cls)}
            data-index={index}
            data-testid='date-range-picker'>
            {minValue && maxValue &&
                <div className={classNames('&-slider-container')}>
                    <ArgSliderRange
                        className={classNames('&-slider-container-slider')}
                        step={1000 * 60 * 60}
                        min={minValue.valueOf()}
                        max={maxValue.valueOf()}
                        value={sliderValue}
                        onChange={handleSliderChange}
                        tooltip={sliderToolTipFormatter}
                        disabled={readOnly}
                        draggableTrack={true}
                    />
                </div>}
            <div className={classNames('&-range', `&-range-${index}`)}>
                <div className={classNames('&-range-dates')}>
                    <ArgInputDate
                        value={value.lowerBound?.value}
                        data-testid='date-range-picker-start'
                        debounce={false}
                        format={dateFormat}
                        locale={locale}
                        onInputChange={onReset}
                        onChange={handleBeginDateChange}
                        placeholder={messages.startDatePlaceholder}
                        className={classNames('&-range-dates-begin')}
                        autoFocus={value.lowerBound?.value === undefined}
                        dndConfig={lowerBoundDndConfig}
                        onClear={() => handleBeginDateChange(null, 'clear')}
                        disabled={readOnly}
                    />
                    <ArgInputDate
                        value={value.upperBound?.value}
                        data-testid='date-range-picker-end'
                        debounce={false}
                        format={dateFormat}
                        locale={locale}
                        onInputChange={onReset}
                        onChange={handleEndDateChange}
                        placeholder={messages.endDatePlaceholder}
                        className={classNames('&-range-dates-end')}
                        dndConfig={upperBoundDndConfig}
                        onClear={() => handleEndDateChange(null, 'clear')}
                        disabled={readOnly}
                    />
                </div>

                {canDelete && !readOnly && (
                    <div className={classNames('&-range-trash')}>
                        <button
                            className={classNames('&-range-trash-button', 'arg-form-label-trash-button')}
                            type='button'
                            data-testid='date-range-picker-trash-button'
                            onClick={() => onDelete(index)}
                        >
                            <i className='icon-trash icon arg-form-label-trash-button-icon' />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
