import { RtApi } from '../rt-api';
import { EntityId } from '../../../model/argonos-piece';
import { computeEntitySettingsStateURL, EntitySettingsState } from './entity-settings-state';
import { useBasicState } from '../use-basic-state';

export function entitySettingsStateFactory(url: string, api: RtApi, entityId: EntityId): EntitySettingsState {
    const ret = new EntitySettingsState(api, url, entityId);

    return ret;
}

export function useEntitySettingsState(api: RtApi, entityId: EntityId | undefined): EntitySettingsState | undefined {
    const state = useBasicState<EntitySettingsState>(
        (url: string) => entitySettingsStateFactory(url, api, entityId!),
        (entityId) ? computeEntitySettingsStateURL(entityId) : undefined,
    );

    return state;
}
