import { Key, ReactNode, useCallback, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { ArgFormLabel } from '../arg-form/arg-form-label';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgInputText } from '../arg-input/arg-input-text';
import { ArgInputTextArea } from '../arg-input/arg-input-text-area';
import { ProgressMonitor, ProgressMonitorOptions } from '../progress-monitors/progress-monitor';
import { ArgChangeReason, ArgMessageValues, ArgPlaceholderText, ArgRenderedText } from '../types';
import { ArgModal } from './arg-modal';
import { useCallbackAsync } from '../arg-hooks/use-callback-async';
import { renderText } from '../utils/message-descriptor-formatters';

import './arg-create-modal.less';

const CLASSNAME = 'arg-create-modal';
const messages = defineMessages({
    disabledCreateButtonTooltip: {
        id: 'basic.arg-create-modal.DisabledCreateButtonTooltip',
        defaultMessage: 'Name field is required',
    },
    createButtonLabel: {
        id: 'basic.arg-create-modal.CreateButtonLabel',
        defaultMessage: 'Create',
    },
    editButtonLabel: {
        id: 'basic.arg-create-modal.EditButtonLabel',
        defaultMessage: 'Edit',
    },
    cancelButtonLabel: {
        id: 'basic.arg-create-modal.CancelButtonLabel',
        defaultMessage: 'Cancel',
    },
    nameFieldLabel: {
        id: 'basic.arg-create-modal.NameFieldLabel',
        defaultMessage: 'Name',
    },
    descriptionFieldLabel: {
        id: 'basic.arg-create-modal.DescriptionFieldLabel',
        defaultMessage: 'Description',
    },
});

export interface ArgCreateModalProps {
    className?: ClassValue;
    onClose?: () => void;
    onConfirm: (name: string, description: string, progressMonitor: ProgressMonitor) => Promise<void>;
    progressMonitorName?: string | MessageDescriptor;
    progressMonitorOptions?: ProgressMonitorOptions;
    messageValues?: ArgMessageValues;
    title?: ArgRenderedText;
    initialName?: string;
    nameMaxLength?: number;
    initialDescription?: string;
    descriptionMaxLength?: number;
    nameFieldLabel?: ArgRenderedText;
    nameFieldPlaceholder?: ArgPlaceholderText;
    descriptionFieldLabel?: ArgRenderedText;
    descriptionFieldPlaceholder?: ArgPlaceholderText;
    customizeFields?: (fields: JSX.Element[], renderField: (key: Key, content: ReactNode) => ReactNode) => ReactNode;
    nameMandatory?: boolean;
}

export function ArgCreateModal(props: ArgCreateModalProps) {
    const {
        className,
        onClose,
        onConfirm,
        progressMonitorName,
        progressMonitorOptions,
        title,
        nameFieldLabel = messages.nameFieldLabel,
        nameFieldPlaceholder = messages.nameFieldLabel,
        descriptionFieldLabel = messages.descriptionFieldLabel,
        descriptionFieldPlaceholder = messages.descriptionFieldLabel,
        initialName,
        initialDescription,
        nameMaxLength,
        descriptionMaxLength,
        messageValues,
        customizeFields = (fields) => fields,
        nameMandatory = true,
    } = props;
    const classNames = useClassNames(CLASSNAME);
    const [name, setName] = useState<string>(initialName || '');
    const [description, setDescription] = useState<string>(initialDescription || '');

    //DERIVED CALCULATIONS
    const isConfirmButtonDisabled = nameMandatory && (!name || (name === initialName && description === initialDescription));

    const [handleConfirm, progressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        await onConfirm(name, description, progressMonitor);
        onClose?.();
    }, [description, name, onClose, onConfirm], progressMonitorName, 1, progressMonitorOptions);

    const handleChange = useCallback(async (value: string | null, reason: ArgChangeReason) => {
        if (reason === 'enter') {
            if (isConfirmButtonDisabled) {
                return;
            }
            await handleConfirm();

            return;
        }
    }, [handleConfirm, isConfirmButtonDisabled]);

    const disabledTooltip = isConfirmButtonDisabled ? messages.disabledCreateButtonTooltip : undefined;
    const isEditing = !!initialName || !!initialDescription;

    const renderField = (key: Key, content: ReactNode) => {
        return (
            <div key={key} className={classNames('&-field')}>
                {content}
            </div>
        );
    };

    const divName = renderField('name', (
        <ArgFormLabel size='medium' propertyName={renderText(nameFieldLabel)} required={nameMandatory} messageValues={messageValues}>
            <ArgInputText
                onInputChange={setName}
                placeholder={nameFieldPlaceholder}
                initialValue={initialName}
                messageValues={messageValues}
                autoFocus={true}
                maxLength={nameMaxLength}
                onChange={handleChange}
            />
        </ArgFormLabel>
    ));

    const divDescription = renderField('description', (
        <div className={classNames('&-field')}>
            <ArgFormLabel size='medium' propertyName={renderText(descriptionFieldLabel)} messageValues={messageValues}>
                <ArgInputTextArea
                    rows={5}
                    initialValue={initialDescription}
                    maxLength={descriptionMaxLength}
                    placeholder={descriptionFieldPlaceholder}
                    onChange={(value) => setDescription(value || '')}
                    messageValues={messageValues}
                />
            </ArgFormLabel>
        </div>
    ));

    return (
        <ArgModal
            className={classNames('&', className)}
            title={title}
            okText={isEditing ? messages.editButtonLabel : messages.createButtonLabel}
            cancelText={messages.cancelButtonLabel}
            okDisabled={isConfirmButtonDisabled}
            okButtonTooltip={disabledTooltip}
            progressMonitor={progressMonitor}
            onClose={onClose}
            onOk={handleConfirm}
            size='large'
            messageValues={messageValues}
        >
            <div className={classNames('&-content')}>
                {customizeFields([divName, divDescription], renderField)}
            </div>
        </ArgModal>
    );
}
