import { SetStateAction } from 'react';
import { isEqual, isFunction } from 'lodash';
import Debug from 'debug';

import { SelectionFilters } from '../model/selection-filters';
import { Filter } from '../model/filter';
import { ExplorationBasicState } from './exploration-basic-state';
import { WebSocketChannel } from '../../components/ws/websocket-connector';
import { ShortestPath } from '../model/graph-visualization';
import { RtStateMessage } from '../../utils/rt-states/rt-basic-state';
import { EditorId } from '../features/editors/editor';
import { RtMessageEventList } from '../../utils/rt-states/events';
import { RtApi } from '../../utils/rt-states/rt-api';
import { EntityId } from '../model/entity';
import { getDataExplorationEditorsRtApi } from './rt-apis';
import { ArgUserId } from '../../components/basic';
import { VisualizationVertex } from '../model/exploration-visualization';

const debug = Debug('argonode:exploration:states:EditorState');

export const EXPLORATION_EDITOR_EVENTS: RtMessageEventList = {
    'SelectionChanged': true,
    'ViewFilterChanged': true,
    'ShortestPathChanged': true,
    'RealtimeUpdateChanged': true,
    'PerformEditorUpdate': true,
    'FitTargetChanged': true,
};

type EditorStateMessage = RtStateMessage

export interface FitTarget {
    target: any;
    timestamp: number;
}

export interface EditorUpdateEvent {
    source: string;
    timestamp: number;
}

//
export class EditorState extends ExplorationBasicState<EditorStateMessage> {
    readonly #entityId: EntityId;
    readonly #editorId: EditorId;
    readonly #userId: ArgUserId;

    #selectionFilters: SelectionFilters | undefined;
    #viewFilter: Filter | undefined;
    #shortestPath: ShortestPath | undefined;
    #realtimeUpdates: boolean | undefined;
    #fitTarget: FitTarget | undefined;
    #updateEvent: EditorUpdateEvent | undefined;
    #modalSelectedVertices: VisualizationVertex[] | undefined;

    constructor(url: string, entityId: EntityId, editorId: EditorId, userId: ArgUserId) {
        super(url);

        this.#userId = userId;
        this.#editorId = editorId;
        this.#entityId = entityId;
    }

    protected getRtApi(): RtApi {
        return getDataExplorationEditorsRtApi();
    }

    protected get serviceUrl(): string {
        return `/entities/${encodeURIComponent(this.#entityId)}/users/${encodeURIComponent(this.#userId)}/editors/${encodeURIComponent(this.#editorId)}`;
    }

    get editorId(): EditorId | undefined {
        return this.#editorId;
    }

    performEditorUpdate(source: string) {
        if (this.#realtimeUpdates) {
            return;
        }

        const event: EditorUpdateEvent = {
            source,
            timestamp: Date.now(),
        };

        /*
                const connection = this.connection;
                if (connection) {
                    connection.send('BroadcastMessage', 'PerformEditorUpdate', event).catch((error) => {
                        console.error(error);
                    });

                    return;
                }
        */
        this.#updateEvent = event;
        this.eventChange('updateEvent', event, 'local');
    }

    get editorUpdateEvent(): EditorUpdateEvent | undefined {
        return this.#updateEvent;
    }

    changeFitTarget(fitTarget: SetStateAction<any | undefined>) {
        if (isFunction(fitTarget)) {
            fitTarget = fitTarget(this.#fitTarget?.target);
        }

        const event: FitTarget = {
            target: fitTarget,
            timestamp: Date.now(),
        };

        debug('changeFitTarget', 'Fit target=', event, 'realtimeUpdates=', this.#realtimeUpdates);

        if (this.#realtimeUpdates) {
            const connection = this.connection;
            if (connection) {
                connection.send('BroadcastMessage', 'FitTargetChanged', event).catch((error) => {
                    console.error(error);
                });

                return;
            }
        }

        this.#fitTarget = event;
        this.eventChange('fitTarget', event, 'local');
    }

    get fitTarget(): FitTarget | undefined {
        return this.#fitTarget;
    }

    changeModalSelectedVertices(modalSelectedVertices: VisualizationVertex[] | undefined) {
        this.#modalSelectedVertices = modalSelectedVertices;
        this.eventChange('modalSelectedVertices', modalSelectedVertices, 'local');
    }

    get modalSelectedVertices(): VisualizationVertex[] | undefined {
        return this.#modalSelectedVertices;
    }

    changeRealtimeUpdates(update: SetStateAction<boolean | undefined>) {
        if (isFunction(update)) {
            update = update(!!this.#realtimeUpdates);
        }

        debug('changeRealtimeUpdates', 'update=', update, 'oldUpdate=', this.#realtimeUpdates);

        if (!!update === !!this.#realtimeUpdates) {
            return;
        }

        /*
        const connection = this.connection;
        if (connection) {
            console.log('Set change=', update);
            connection.send('BroadcastMessage', 'RealtimeUpdateChanged', update).catch((error) => {
                console.error(error);
            });

            return;
        }*/

        this.#realtimeUpdates = update;
        this.eventChange('realtimeUpdates', update, 'local');
    }

    get realtimeUpdates(): boolean | undefined {
        return this.#realtimeUpdates;
    }

    changeSelection(selection: SetStateAction<SelectionFilters | undefined>) {
        if (isFunction(selection)) {
            selection = selection(this.#selectionFilters);
        }

        if (isEqual(selection, this.#selectionFilters)) {
            return;
        }

        debug('changeSelection', 'selection=', selection);

        if (this.#realtimeUpdates) {
            const connection = this.connection;
            if (connection) {
                connection.send('BroadcastMessage', 'SelectionChanged', selection).catch((error) => {
                    console.error(error);
                });

                return;
            }
        }

        this.#selectionFilters = selection;
        this.eventChange('selection', selection, 'local');
    }

    get selection(): SelectionFilters | undefined {
        return this.#selectionFilters;
    }

    changeViewFilter(viewFilter: SetStateAction<Filter | undefined>) {
        if (isFunction(viewFilter)) {
            viewFilter = viewFilter(this.#viewFilter);
        }

        if (isEqual(viewFilter, this.#viewFilter)) {
            return;
        }

        if (this.#realtimeUpdates) {
            const connection = this.connection;
            if (connection) {
                connection.send('BroadcastMessage', 'ViewFilterChanged', viewFilter).catch((error) => {
                    console.error(error);
                });

                return;
            }
        }

        this.#viewFilter = viewFilter;
        this.eventChange('viewFilter', viewFilter, 'local');
    }

    get viewFilter(): Filter | undefined {
        return this.#viewFilter;
    }

    changeShortestPath(shortestPath: SetStateAction<ShortestPath | undefined>) {
        if (isFunction(shortestPath)) {
            shortestPath = shortestPath(this.#shortestPath);
        }

        if (isEqual(shortestPath, this.#shortestPath)) {
            return;
        }

        if (this.#realtimeUpdates) {
            const connection = this.connection;
            if (connection) {
                connection.send('BroadcastMessage', 'ShortestPathChanged', shortestPath).catch((error) => {
                    console.error(error);
                });

                return;
            }
        }

        this.#shortestPath = shortestPath;
        this.eventChange('shortestPath', shortestPath, 'local');
    }

    get shortestPath(): ShortestPath | undefined {
        return this.#shortestPath;
    }

    processMessage = async (channel: WebSocketChannel<EditorStateMessage>, type: string, message: EditorStateMessage): Promise<boolean | undefined> => {
        debug('processMessage', 'type=', type, 'content=', message.messageContent);

        switch (type) {
            case 'PerformEditorUpdate': {
                const updateEvent = message.messageContent;
                this.#updateEvent = updateEvent;
                this.eventChange('updateEvent', updateEvent, 'remote');

                return true;
            }

            case 'FitTargetChanged': {
                if (!this.#realtimeUpdates) {
                    return;
                }
                const fitTarget = message.messageContent;
                this.#fitTarget = fitTarget;
                this.eventChange('fitTarget', fitTarget, 'remote');

                return true;
            }

            case 'RealtimeUpdateChanged': {
                const realtimeUpdates = message.messageContent;
                this.#realtimeUpdates = realtimeUpdates;
                this.eventChange('realtimeUpdates', realtimeUpdates, 'remote');

                return true;
            }

            case 'SelectionChanged': {
                if (!this.#realtimeUpdates) {
                    return;
                }

                const selection = message.messageContent;
                this.#selectionFilters = selection;
                this.eventChange('selection', selection, 'remote');

                return true;
            }

            case 'ViewFilterChanged': {
                if (!this.#realtimeUpdates) {
                    return;
                }

                const viewFilter = message.messageContent;
                this.#viewFilter = viewFilter;
                this.eventChange('viewFilter', viewFilter, 'remote');

                return true;
            }

            case 'ShortestPathChanged': {
                if (!this.#realtimeUpdates) {
                    return;
                }

                const shortestPath = message.messageContent;
                this.#shortestPath = shortestPath;
                this.eventChange('shortestPath', shortestPath, 'local');

                return true;
            }
        }

        console.error('Unsupported event=', message);
    };
}
