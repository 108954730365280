import React, { useCallback } from 'react';
import { isEqual } from 'lodash';

import { ControlProps } from './controls-type';
import {
    ArgChangeReason,
    GeolocationData,
    GeoAreaValue,
    useClassNames,
    getDataTestIdFromProps,
} from '../../basic';
import { UndefinedButton } from './undefined-button';
import { ArgGeoshapePicker } from 'src/components/basic/arg-geo-picker/arg-geoshape-picker/arg-geoshape-picker';
// TODO: use a common api that doesnt use explo for address
import { ExplorationConnector } from '../../../exploration/utils/connector/exploration-connector';

import './geoshape-picker-control.less';


export function GeoshapePickerControl(props: ControlProps<GeolocationData>) {
    const {
        className,
        value,
        onChange,
        showUndefined,
        propertyInfo,
        readOnly,
    } = props;

    const classNames = useClassNames('geoshape-picker-control');
    const dataTestId = getDataTestIdFromProps(props);

    const handleChange = useCallback((geoValue: GeoAreaValue | ((prev: GeoAreaValue | undefined) => GeoAreaValue | undefined) | undefined, reason?: ArgChangeReason) => {
        if (typeof (geoValue) === 'function') {
            onChange((prev) => {
                const prevGeo = prev?.geo || undefined;
                const newGeo = geoValue(prevGeo) || undefined;

                if (isEqual(newGeo, prevGeo)) {
                    return prev;
                }

                return {
                    ...prev,
                    geo: newGeo,
                };
            }, reason);

            return;
        }

        onChange({ ...value, geo: geoValue || undefined }, reason);
    }, [onChange, value]);

    const handleUndefinedButtonChange = useCallback((includeUndefined: boolean) => {
        onChange((prev) => {
            return {
                ...prev,
                includeUndefined,
            };
        }, 'selection');
    }, [onChange]);

    return (
        <div className={classNames('&', className, { 'hide-undefined': showUndefined !== false })}>
            <ArgGeoshapePicker
                data-testid={dataTestId}
                className={classNames('&-picker')}
                onChange={handleChange}
                value={value?.geo}
                getAddressCoordinates={ExplorationConnector.getInstance().getAddressCoordinates}
                readOnly={readOnly}
            />
            {(showUndefined || (propertyInfo?.numberOfMissing ?? 0) > 0) &&
                <UndefinedButton
                    className={classNames('&-undefinedButton')}
                    onChange={handleUndefinedButtonChange}
                    value={value?.includeUndefined ?? false}
                    count={propertyInfo?.numberOfMissing}
                    disabled={readOnly} />}
        </div>
    );
}
