import React, { useCallback, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { Form, Slider } from 'antd';
import { isNumber } from 'lodash';

import { RangeDataModel } from './range';
import { ArgChangeReason, ArgIcon, ArgInputDndConfig, ArgInputNumber, useClassNames } from '../../../basic';
import { DroppedParameter } from '../dropped-parameter';
import { ControlParameter } from 'src/components/common/controls/control-parameter';
import { DataType } from '../../data-types';

import './single-range-picker-control.less';


const messages: { [key: string]: MessageDescriptor } = defineMessages({
    minPlaceholder: {
        id: 'common.controls.single-range-picker.Min',
        defaultMessage: 'min',
    },
    maxPlaceholder: {
        id: 'common.controls.single-range-picker.Max',
        defaultMessage: 'max',
    },
    errorOverlap: {
        id: 'common.controls.single-range-picker.validation.overlap',
        defaultMessage: 'The intervals overlap. You must enter new number.',
    },
    dropParameter: {
        id: 'common.controls.single-range-picker.DropParameter',
        defaultMessage: 'Drop the parameter here',
    },
});

interface SingleRangePickerControlProps {
    rangeLowerBound: number;
    rangeUpperBound: number;
    onChange: (setValue: (prev: RangeDataModel<number>) => RangeDataModel<number>, reason: ArgChangeReason) => void;
    value: RangeDataModel<number>;
    onDelete: () => void;
    canDelete?: boolean;
    onReset: () => void;
    forceRange?: boolean;
    acceptsParameters?: (type: string) => string[];
    readOnly?: boolean;
}

type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];

export function SingleRangePicker(props: SingleRangePickerControlProps) {
    const {
        rangeLowerBound,
        rangeUpperBound,
        onChange,
        value,
        onDelete,
        onReset,
        canDelete,
        forceRange,
        acceptsParameters,
        readOnly,
    } = props;

    const classNames = useClassNames('arg-SingleRangePickerControl');

    const intl = useIntl();

    const [rangeError, setRangeError] = useState<{
        validateStatus?: ValidateStatus;
        errorMsg?: string | null;
    }>({});

    const slideValues: [number, number] = useMemo<[number, number]>(() => {
        const slideValues: [number, number] = [rangeLowerBound, rangeUpperBound];
        if (isNumber(value.lowerBound?.value) && !value.lowerBound?.parameter) {
            slideValues[0] = value.lowerBound?.value!;
        }
        if (isNumber(value.upperBound?.value) && !value.upperBound?.parameter) {
            slideValues[1] = value.upperBound?.value!;
        }

        return slideValues;
    }, [rangeLowerBound, rangeUpperBound, value]);


    const handleMinInputValue = (minInputValue: number | null, reason: ArgChangeReason) => {
        if (minInputValue === null) {
            onChange((prev) => {
                const newValue: RangeDataModel<number> = {
                    ...prev,
                    lowerBound: {
                        included: true,
                        value: undefined,
                    },
                };

                return newValue;
            }, reason);

            return;
        }

        let minInputNumber = minInputValue as number;

        onChange((prev) => {
            setRangeError({});
            if (forceRange) {
                minInputNumber = Math.max(rangeLowerBound, minInputNumber);
            }

            if (prev.upperBound) {
                if (typeof (prev.upperBound.value) === 'number' && prev.upperBound.value < minInputNumber) {
                    if (prev.lowerBound?.included && prev.lowerBound?.value === undefined) {
                        return prev;
                    }
                    setRangeError({
                        validateStatus: 'error',
                        errorMsg: intl.formatMessage(messages.errorOverlap),
                    });

                    //todo: can keep this code if(forceValidation)
                    // const newValue = {
                    //     lowerBound: {
                    //         included: prev.lowerBound.included,
                    //         value: minInputNumber,
                    //     },
                    //     upperBound: {
                    //         included: prev.lowerBound.included,
                    //         value: minInputNumber,
                    //     },
                    // };
                    //
                    // return newValue;
                }
            }

            return {
                ...prev,
                lowerBound: {
                    included: prev.lowerBound?.included ?? false,
                    value: minInputNumber,
                },
            };
        }, reason);
    };

    const handleMinInputParameter = useCallback((type: string, parameter: ControlParameter | undefined) => {
        onChange((prev) => {
            const newValue: RangeDataModel<number> = {
                ...prev,
                lowerBound: {
                    included: true,
                    parameter,
                    value: undefined,
                },
            };

            return newValue;
        }, 'keypress');
    }, [onChange]);

    const handleMaxInputParameter = useCallback((type: string, parameter: ControlParameter | undefined) => {
        onChange((prev) => {
            const newValue: RangeDataModel<number> = {
                ...prev,
                upperBound: {
                    included: true,
                    parameter,
                    value: undefined,
                },
            };

            return newValue;
        }, 'keypress');
    }, [onChange]);
    const handleMaxInputValue = (maxInputValue: number | null, reason: ArgChangeReason) => {
        if (maxInputValue === null) {
            onChange((prev) => {
                return {
                    ...prev,
                    upperBound: {
                        included: true,
                        value: undefined,
                    },
                };
            }, reason);

            return;
        }

        let maxInputNumber = maxInputValue as number;

        onChange((prev) => {
            setRangeError({});
            if (forceRange) {
                maxInputNumber = Math.min(rangeUpperBound, maxInputNumber);
            }


            if (prev.lowerBound) {
                if (typeof (prev.lowerBound.value) === 'number' && prev.lowerBound.value > maxInputNumber) {
                    setRangeError({
                        validateStatus: 'error',
                        errorMsg: intl.formatMessage(messages.errorOverlap),
                    });
                    //todo: can keep this code if(forceValidation)
                    // return {
                    //     lowerBound: {
                    //         included: prev.upperBound.included,
                    //         value: maxInputNumber,
                    //     },
                    //     upperBound: {
                    //         included: prev.upperBound.included,
                    //         value: maxInputNumber,
                    //     },
                    // };
                }
            }


            return {
                ...prev,
                upperBound: {
                    included: prev.upperBound?.included ?? false,
                    value: maxInputNumber,
                },
            };
        }, reason);
    };

    const handleSliderChange = (range: [number, number]) => {
        if (!Array.isArray(range)) {
            return;
        }

        let [lower, upper] = range;

        onChange((prev) => {
            if (prev.upperBound?.value !== undefined) {
                if (lower > prev.upperBound?.value) {
                    lower = prev.upperBound?.value;
                }
            }
            if (prev.lowerBound?.value !== undefined) {
                if (upper < prev.lowerBound?.value) {
                    upper = prev.lowerBound?.value;
                }
            }

            return {
                lowerBound: {
                    included: prev.lowerBound?.included ?? false,
                    parameter: prev.lowerBound?.parameter,
                    value: !prev.lowerBound?.parameter ? lower : undefined,
                },
                upperBound: {
                    included: prev.upperBound?.included ?? false,
                    parameter: prev.upperBound?.parameter,
                    value: !prev.upperBound?.parameter ? upper : undefined,
                },
            };
        }, 'selection');
    };

    const handleLowerBoundClick = () => {
        onChange((prev) => {
            return {
                ...prev,
                lowerBound: {
                    ...prev.lowerBound,
                    included: !prev.lowerBound?.included,
                },
            };
        }, 'selection');
    };
    const handleUpperBoundClick = () => {
        onChange((prev) => {
            return {
                ...prev,
                upperBound: {
                    ...prev.upperBound,
                    included: !prev.upperBound?.included,
                },
            };
        }, 'selection');
    };


    const cls = {
        'has-trash': canDelete,
    };

    const bounds = !isNaN(rangeLowerBound) && !isNaN(rangeUpperBound);

    const lowerBoundDndConfig = useMemo<ArgInputDndConfig>(() => ({
        allowedDndTypes: acceptsParameters ? acceptsParameters(DataType.Int) : [],
        allowDrop: acceptsParameters && !value.lowerBound?.parameter && !readOnly,
        renderDroppedItem:
            ({ type, data }: { type: string; data: any }) =>
                <DroppedParameter
                    item={data}
                    onRemove={() => handleMinInputParameter(type, undefined)}
                    readonly={readOnly}
                />,
        onDrop: handleMinInputParameter,
        droppedItem: value.lowerBound?.parameter && {
            data: value.lowerBound?.parameter,
            type: DataType.Int,
        },
        dropPlaceholder: messages.dropParameter,
    }), [acceptsParameters, handleMinInputParameter, value.lowerBound?.parameter, readOnly]);

    const upperBoundDndConfig = useMemo<ArgInputDndConfig>(() => ({
        allowedDndTypes: acceptsParameters ? acceptsParameters(DataType.Int) : [],
        allowDrop: acceptsParameters && !value.upperBound?.parameter && !readOnly,
        renderDroppedItem:
            ({ type, data }: { type: string; data: any }) =>
                <DroppedParameter
                    item={data}
                    onRemove={() => handleMaxInputParameter(type, undefined)}
                    readonly={readOnly}
                />,
        onDrop: handleMaxInputParameter,
        droppedItem: value.upperBound?.parameter && {
            data: value.upperBound?.parameter,
            type: DataType.Int,
        },
        dropPlaceholder: messages.dropParameter,
    }), [acceptsParameters, handleMaxInputParameter, value.upperBound?.parameter, readOnly]);

    return (
        <div className={classNames('&', cls)} data-testid='single-range-picker'>
            {bounds && (
                <div key='bounds' className={classNames('&-range-bounds')}>
                    <div className={classNames('&-range-bounds-lower')}>
                        {!isNaN(rangeLowerBound) && intl.formatNumber(rangeLowerBound)}
                    </div>
                    <div className={classNames('&-range-bounds-upper')}>
                        {!isNaN(rangeUpperBound) && intl.formatNumber(rangeUpperBound)}
                    </div>
                </div>
            )}

            {bounds && (
                <div key='slider' className={classNames('&-slider')}>
                    <Slider
                        value={slideValues}
                        min={rangeLowerBound}
                        max={rangeUpperBound}
                        onChange={handleSliderChange}
                        range={{ draggableTrack: true }}
                        className={classNames('&-slider-slide')}
                        data-testid='single-range-picker-slider'
                        tipFormatter={(value) => intl.formatNumber(value || 0)}
                        disabled={readOnly}
                    />
                </div>
            )}

            <Form>
                <Form.Item validateStatus={rangeError.validateStatus} help={rangeError.errorMsg}>
                    <div key='input' className={classNames('&-inputs')}>
                        <div className={classNames('&-inputs-line')}>

                            <ArgInputNumber left={
                                <button
                                    className={classNames('&-inputs-lower-button')}
                                    type='button'
                                    tabIndex={-1}
                                    data-testid='single-range-picker-lower-button'
                                    onClick={handleLowerBoundClick}
                                    disabled={readOnly}
                                >
                                    {value.lowerBound?.included ? '≥' : '>'}
                                </button>
                            }
                                            className={classNames('&-inputs-lower')}
                                            data-testid='single-range-picker-lower-input'
                                            clearable={false}
                                            placeholder={messages.minPlaceholder}
                                            initialValue={value.lowerBound?.value}
                                            autoFocus={value.lowerBound?.value === undefined}
                                            onInputChange={onReset}
                                            onChange={handleMinInputValue}
                                            debounce={true}
                                            dndConfig={lowerBoundDndConfig}
                                            disabled={readOnly}
                            />

                            <ArgInputNumber left={
                                <button
                                    className={classNames('&-inputs-lower-button')}
                                    type='button'
                                    tabIndex={-1}
                                    data-testid='single-range-picker-upper-button'
                                    onClick={handleUpperBoundClick}
                                    disabled={readOnly}
                                >
                                    {value.upperBound?.included ? '≤' : '<'}
                                </button>
                            }
                                            className={classNames('&-inputs-upper')}
                                            data-testid='single-range-picker-upper-input'
                                            clearable={false}
                                            placeholder={messages.maxPlaceholder}
                                            initialValue={value.upperBound?.value}
                                            onInputChange={onReset}
                                            onChange={handleMaxInputValue}
                                            debounce={true}
                                            dndConfig={upperBoundDndConfig}
                                            disabled={readOnly}
                            />

                        </div>

                        {canDelete && onDelete && (
                            <div className={classNames('&-trash')}>
                                <button className={classNames('&-range-trash-button', 'arg-form-label-trash-button')}
                                        data-testid='single-range-picker-trash-button'
                                        type='button'
                                        onClick={onDelete}
                                        disabled={readOnly}
                                >
                                    <ArgIcon name='icon-trash' className='arg-form-label-trash-button-icon' />
                                </button>
                            </div>
                        )}
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}
