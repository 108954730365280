import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { ReferenceTablesState } from './reference-tables-state';

export function computeReferenceTablesStateURL() {
    return '/reference-tables';
}

export function referenceTablesFactory(url: string):ReferenceTablesState {
    return new ReferenceTablesState(url);
}

export function useReferenceTablesState(): ReferenceTablesState {
    const state = useBasicState<ReferenceTablesState>(
        (url) => referenceTablesFactory(url),
        computeReferenceTablesStateURL(),
    )!;

    return state;
}
