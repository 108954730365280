import { defineMessages } from 'react-intl';
import { useCallback } from 'react';

import { ArgonosModule } from 'src/components/application/modules';
import { ArgCollapse, ArgImage, ArgMessageRenderer, ClassValue, useArgModalContext, useClassNames, useMemoAsync } from 'src/components/basic';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { ErrorPane } from 'src/components/common/panes/error-pane';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import ExtensionConnector from 'src/settings/connectors/extensions-connector';
import { ExtensionImage } from 'src/settings/models/dtoApi';
import { MediaViewerModal } from 'src/components/common/modal2/media-viewer-modal/media-viewer-modal';
import { ImageViewerCard } from 'src/components/common/image-viewer-card';

import './extension-preview-images.less';

const messages = defineMessages({
    emptyExtension: {
        id: 'settings.extension-information-panel.extension-preview-images.EmptyExtension',
        defaultMessage: 'No images found!',
    },
    extensionImages: {
        id: 'settings.extension-information-panel.extension-preview-images.ExtensionComponents',
        defaultMessage: 'Preview {isError, select, false {<grey>({count})</grey>} other {}} ',
    },
    loadingExtensionImages: {
        id: 'settings.extension-information-panel.extension-preview-images.LoadingExtensionComponents',
        defaultMessage: 'Loading extension images',
    },
    extensionImagesError: {
        id: 'settings.extension-information-panel.extension-preview-images.ExtensionComponentsError',
        defaultMessage: 'Failed to load extension images',
    },
    threeDotsLoadingImages: {
        id: 'settings.extension-information-panel.extension-preview-images.ThreeDotsLoadingComponents',
        defaultMessage: 'Preview <grey>({threeDotsLoading})</grey>',
    },
});

const CLASSNAME = 'settings-extension-preview-images';
const FROCE_LOADING = false;
const FROCE_ERROR = false;
const MODAL_PREVIEW_NAME = 'settings-extension-preview-images-modal';

type ExtensionComponentsProps = {
	className?: ClassValue;
	argonosModule?: ArgonosModule;
	extensionName?: string;
}


export function ExtensionPreviewImages(props: ExtensionComponentsProps) {
    const { extensionName, argonosModule, className } = props;
    const classNames = useClassNames(CLASSNAME);
    const modalContext = useArgModalContext();

    const handleSeeAction = useCallback((image: ExtensionImage) => {
        const mediaViewerModal = (
            <MediaViewerModal
                title={image.title}
                onClose={() => modalContext.close(MODAL_PREVIEW_NAME)}
                renderMedia={(fitContainClassName) => (
                    <ArgImage
                        className={classNames('&-modal-image', fitContainClassName)}
                        alt={image.description}
                        title={image.description}
                        src={image.content}
                    />
                )}
            />
        );
        modalContext.open(MODAL_PREVIEW_NAME, mediaViewerModal);
    }, [classNames, modalContext]);

    const [extensionPreviewImages, progressMonitor, error] = useMemoAsync<ExtensionImage[]>(async (progressMonitor) => {
        if (!extensionName) {
            return;
        }

        try {
            const extension = await ExtensionConnector.getInstance().getExtension(extensionName, true, argonosModule, progressMonitor);

            return extension.images || [];
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }

            throw error;
        }
    }, [extensionName, argonosModule]);


    const isError = FROCE_ERROR || !!error;
    const isLoading = !isError && (FROCE_LOADING || !extensionPreviewImages || progressMonitor?.isRunning);
    const extensionHasNoImages = !isLoading && !isError && extensionPreviewImages && extensionPreviewImages.length === 0;
    const extensionHasImages = !isLoading && !isError && extensionPreviewImages && extensionPreviewImages.length > 0;

    const headerMessage = isLoading ? messages.threeDotsLoadingImages : messages.extensionImages;

    return (
        <div className={classNames('&', className)}>
            <ArgCollapse
                title={() => (
                    <ArgMessageRenderer
                        className={classNames('&-header')}
                        message={headerMessage}
                        messageValues={{ count: extensionPreviewImages?.length, isError: isError.toString() }}
                    />
                )}

            >
                {isLoading && (
                    <LoadingPane size='small' message={messages.loadingExtensionImages} />
                )}

                {isError && (
                    <ErrorPane size='small' message={messages.extensionImagesError} />
                )}

                {extensionHasNoImages && (
                    <EmptyPane
                        size='small'
                        message={messages.emptyExtension}
                        backgroundAnimation='wave'
                    />
                )}
                {extensionHasImages && (
                    <div className={classNames('&-list')}>
                        {extensionPreviewImages.map((image, idx) => {
                            return (
                                <ImageViewerCard
                                key={idx}
                                caption={image.title}
                                tooltip={image.description}
                                onSeeAction={() => handleSeeAction(image)}
                                >
                                    <ArgImage src={image.content} />
                                </ImageViewerCard>
                            );
                        })}
                    </div>
                )}
            </ArgCollapse>
        </div>
    );
}
