import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { ProcessId } from '../model/process';
import { ProcessStatisticsState } from './process-statistics-state';
import { BasicStates, useBasicStates } from '../../utils/rt-states/use-basic-states';

export function preparationProcessStatisticsStateFactory(url: string, processId: ProcessId) {
    return new ProcessStatisticsState(url, processId);
}

export function computeProcessStatisticsStateURL(processId: ProcessId) {
    return `/processes/${processId}/statistics`;
}

export function usePreparationProcessStatisticsState(processId: ProcessId): ProcessStatisticsState;
export function usePreparationProcessStatisticsState(processId: ProcessId | undefined): ProcessStatisticsState | undefined;

export function usePreparationProcessStatisticsState(processId: ProcessId | undefined): ProcessStatisticsState | undefined {
    const state = useBasicState<ProcessStatisticsState>(
        (url) => preparationProcessStatisticsStateFactory(url, processId!),
        (processId) ? computeProcessStatisticsStateURL(processId) : undefined,
    );

    return state;
}

export function usePreparationProcessStatisticsStates(processIds: Readonly<ProcessId[]>): BasicStates<ProcessStatisticsState>;
export function usePreparationProcessStatisticsStates(processIds: undefined): undefined;
export function usePreparationProcessStatisticsStates(processIds: Readonly<ProcessId[]> | undefined): BasicStates<ProcessStatisticsState> | undefined;
export function usePreparationProcessStatisticsStates(processIds: Readonly<ProcessId[]> | undefined): BasicStates<ProcessStatisticsState> | undefined {
    const state = useBasicStates(
        (url, processId) => preparationProcessStatisticsStateFactory(url, processId), processIds,
        (processId) => computeProcessStatisticsStateURL(processId),
    );

    return state;
}
