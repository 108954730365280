import { useEffect } from 'react';

import { AppRoutes } from './app-routes';
import { useClassNames } from './components/basic';
import { AppGlobalProvider } from './providers/app-global-provider';
import { BuildVersionWrapper } from './components/containers/build-version-wrapper';
// Déclaration des applications (Plugins) -> A améliorer quand l'architecture des plugins est figée
import './administration';
import './collect';
import './exploration';
import './preparation';
// import './proceo'; // Commented specially for release where proceo is not expected.
import './settings';
import './knowledge-base';

import './styles/application.less';

export function Application() {
    const classNames = useClassNames('arg-application');

    useEffect(() => {
        if (window.argonodeConfig || process.env.NODE_ENV !== 'production') {
            return;
        }
        alert('PANIC: The configuration is not loaded ! Verify the config.js file content.');
    }, []);

    return (
        <BuildVersionWrapper className={classNames('&-version')}>
            <AppGlobalProvider>
                <AppRoutes />
            </AppGlobalProvider>
        </BuildVersionWrapper>
    );
}

