import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { ProcessId } from '../model/process';
import { ProcessState } from './process-state';
import { BasicStates, useBasicStates } from '../../utils/rt-states/use-basic-states';

export function preparationProcessStateFactory(url:string, processId:ProcessId) {
    return new ProcessState(url, processId);
}

export function computeProcessStateURL(processId:ProcessId) {
    return `/processes/${processId}`;
}

export function usePreparationProcessState(processId:ProcessId):ProcessState;
export function usePreparationProcessState(processId:ProcessId | undefined):ProcessState | undefined;

export function usePreparationProcessState(processId:ProcessId | undefined):ProcessState | undefined {
    const state = useBasicState<ProcessState>(
        (url) => preparationProcessStateFactory(url, processId!),
        (processId) ? computeProcessStateURL(processId) : undefined,
    );

    return state;
}


export function usePreparationProcessStates(processIds:ProcessId[]):BasicStates<ProcessState>;
export function usePreparationProcessStates(processIds:undefined):undefined;
export function usePreparationProcessStates(processIds:ProcessId[] | undefined):BasicStates<ProcessState> | undefined;
export function usePreparationProcessStates(processIds:ProcessId[] | undefined):BasicStates<ProcessState> | undefined {
    const state = useBasicStates(
        (url, processId) => preparationProcessStateFactory(url, processId), processIds,
        (processId) => computeProcessStateURL(processId),
    );

    return state;
}

