import { KeyboardEvent, useCallback, useEffect } from 'react';
import { isFunction } from 'lodash';

import { Tool, ToolChanges, ToolItemWithPropsGenerator, ToolPath, ToolPropsGenerator } from './tool';
import { ToolContext } from './tool-context';
import { useKeyBinding } from '../keybindings/use-keybinding';

type ToolWithOptionalPath<T> = Omit<Tool<T>, 'path'> & { path?: ToolPath };

const EMPTY_CHANGES: ToolChanges<any> = {};

//export function useToolItem<undefined>(toolContext: ToolContext<undefined> | undefined, item: Tool<undefined>, changes: ToolChanges<undefined> | ToolPropsGenerator<undefined>): void;
//export function useToolItem<T>(toolContext: ToolContext<T> | undefined, item: Tool<T>): void;

export function useToolItem<T = undefined>(
    toolContext: ToolContext<T> | undefined,
    item: ToolWithOptionalPath<T>,
    changes: ToolChanges<T> | ToolPropsGenerator<T> = EMPTY_CHANGES,
): void {
    useEffect(() => {
        if (!toolContext || item.path === undefined) {
            return;
        }

        let _item: Tool<T> | ToolItemWithPropsGenerator<T> = item as Tool<T>;
        if (isFunction(changes)) {
            _item = {
                ...item,
                __internal_propsGenerator: changes,
            } as Tool<T>;
        }

        toolContext.addItem(_item, (changes !== EMPTY_CHANGES) ? changes : undefined);

        return () => {
            toolContext.removeItem(_item);
        };
    }, [toolContext]);

    useEffect(() => {
        if (changes === EMPTY_CHANGES || !toolContext || isFunction(changes) || item.path === undefined) {
            return;
        }

        toolContext.updateItem(item as Tool<T>, changes);
    }, (changes === EMPTY_CHANGES) ? [] : Object.values(changes));

    const onClick = useCallback((event: KeyboardEvent<Element>) => {
        toolContext?.handleKeyBinding(item as Tool<T>, event);
    }, [toolContext, item]);

    const updatedItem = (changes === EMPTY_CHANGES) ? item : { ...item, ...changes };

    useKeyBinding(
        (item.path && toolContext) ? updatedItem.keyBinding : undefined,
        onClick,
    ); // @TODO OO: , isToolVisible(updatedItem, environmentContext) && !isToolDisabled(updatedItem, environmentContext));
}
