import { defineMessages, useIntl } from 'react-intl';
import { cloneDeep, set } from 'lodash';
import { Dayjs } from 'dayjs';

import {
    ArgCombo,
    ArgIcon,
    ArgInputDate,
    ArgInputInteger,
    ArgInputNumber,
    ArgInputText,
    ArgSwitch,
    dayjs,
    useClassNames,
} from 'src/components/basic';
import { ContextualVariable } from 'src/exploration/model/contextual-variable';
import { ScopeFilter } from '../../../../models/policy';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';
import {
    deleteScopeRow,
    getScopeValues,
    SCOPE_OPERATORS,
    SCOPE_OPERATORS_TEXT_BOOL,
    ScopeOperator,
    TargetOption,
    useScopeTargetDropdown,
} from './utils';
import { useScopeStateContext } from '../../providers/policy-rules-provider';
import { ROW_HEIGHT } from '../../policy-utils';
import { UserProfileField } from '../../../../../model/user-metadata';
import { internalisationDateFormat } from '../../../../../utils/dates/internalisation-date-format';
import { HourInput } from './hour-input';

import './application-condition-expression-of-logic.less';


const messages = defineMessages({
    if: {
        id: 'settings.application-condition-expression-of-logic.filter.if',
        defaultMessage: 'If',
    },
    select: {
        id: 'settings.application-condition-expression-of-logic.filter.select',
        defaultMessage: 'Select',
    },
});

interface FilterProps {
    editable: boolean;
    filter: ScopeFilter;
    currentPath: string;
    contextualVariables: ContextualVariable[];
    userProfileFields: UserProfileField[];
    search?: string;
}

export function Filter(props: FilterProps) {
    const {
        editable,
        filter,
        currentPath,
        contextualVariables,
        userProfileFields,
        search,
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('settings-application-condition-expression-of-logic');
    const { setScope, scope } = useScopeStateContext();
    const targetsOptions = useScopeTargetDropdown(contextualVariables, userProfileFields);

    const scopeValues = getScopeValues(filter.Data, editable);
    const { dataOperator, dataValue } = scopeValues;

    const targetOption = targetsOptions.find((o) => {
        if (filter.Type === 'always' && o.type === 'always') {
            return true;
        }
        if (o.key !== scopeValues.dataKey || o.type !== filter.Type) {
            return false;
        }

        return true;
    });
    if (!targetOption) {
        console.error('Can not find target option=', filter, scopeValues, targetsOptions);
    }

    const changeScopeKeyDropdown = (targetOption: TargetOption) => {
        setScope((currentScope) => {
            if (targetOption.key === 'always') {
                if (currentPath === '') {
                    return {
                        Type: 'always',
                        Data: {},
                        id: targetOption.id,
                    };
                }

                return set(cloneDeep(currentScope), currentPath, {
                    Type: 'always',
                    Data: {},
                });
            }

            if (currentPath === '') {
                return {
                    Type: targetOption.type,
                    Data: targetOption!.key ? { [targetOption.key]: undefined } : {},
                    id: targetOption.id,
                };
            }

            return set(cloneDeep(currentScope), currentPath, {
                Type: targetOption.type,
                Data: targetOption!.key ? { [targetOption.key]: undefined } : {},
            });
        });
    };

    const changeOperatorDropdown = (scopeOperator: ScopeOperator) => {
        let newValue = dataValue;
        if (typeof (newValue) === 'number') {
            newValue = String(newValue);
        } else if (typeof (newValue) === 'string') {
            newValue = `\"${newValue}\"`;
        } else {
            newValue = '\"\"';
        }
        setScope((currentScope) => {
            if (currentPath === '') {
                return {
                    Type: targetOption!.type,
                    Data: targetOption!.key ? { [targetOption!.key]: `${scopeOperator.key}${newValue}` } : {},
                    id: targetOption!.id,
                };
            }

            return set(cloneDeep(currentScope), currentPath, {
                Type: targetOption!.type,
                Data: targetOption!.key ? { [targetOption!.key]: `${scopeOperator.key}${newValue}` } : {},
            });
        });
    };

    const changeScopeValue = (newValue: string | number | boolean | Dayjs | null) => {
        if (typeof (newValue) === 'number' || typeof (newValue) === 'boolean') {
            newValue = String(newValue);
        } else if (typeof (newValue) === 'string') {
            newValue = `\"${newValue}\"`;
        } else if (typeof (newValue) === 'object') {
            newValue = `\"${newValue?.format() || ''}\"`;
        } else {
            newValue = '\"\"';
        }

        const targetKey = `${dataOperator?.key}${newValue}`;

        setScope((currentScope) => {
            if (currentPath === '') {
                return {
                    Type: targetOption!.type,
                    Data: targetOption!.key ? {
                        [targetOption!.key]: targetKey,
                    } : {},
                    id: targetOption!.id,
                };
            }

            return set(cloneDeep(currentScope), currentPath, {
                Type: targetOption!.type,
                Data: targetOption!.key ? {
                    [targetOption!.key]: targetKey,
                } : {},
            });
        });
    };

    return (
        <div style={{ display: 'flex' }}>
            <div className={classNames('&-container')} style={{ height: ROW_HEIGHT }}>
                <div className={classNames('&-if-text')}>{intl.formatMessage(messages.if)}</div>
                {editable ? (
                    <>
                        <ArgCombo<TargetOption>
                            items={targetsOptions}
                            value={targetOption}
                            className={classNames('&-scope-key')}
                            placeholder={intl.formatMessage(messages.select)}
                            popoverClassName='arg-input-popover-no-max-width'
                            cardinality='one'
                            size='small'
                            getItemLabel='text'
                            getItemKey='id'
                            onChange={(item) => changeScopeKeyDropdown(item)}
                        />
                        {targetOption && targetOption.type !== 'always' && targetOption.variableType !== 'string' && targetOption.variableType !== 'bool' && (
                            <>
                                <ArgCombo<ScopeOperator>
                                    items={SCOPE_OPERATORS}
                                    getItemKey='key'
                                    getItemLabel='label'
                                    value={dataOperator}
                                    className={classNames('&-scope-operator')}
                                    placeholder={intl.formatMessage(messages.select)}
                                    popoverClassName='arg-input-popover-no-max-width'
                                    cardinality='one'
                                    size='small'
                                    onChange={changeOperatorDropdown}
                                />
                                {targetOption.variableType === 'int' &&
                                    <ArgInputInteger
                                        className={classNames('&-scope-value')}
                                        value={dataValue}
                                        size='small'
                                        onChange={changeScopeValue}
                                    />}
                                {targetOption.variableType === 'hour' &&
                                    <HourInput
                                        className={classNames('&-scope-value')}
                                        value={dataValue}
                                        size='small'
                                        onChange={changeScopeValue}
                                    />}
                                {targetOption.variableType === 'decimal' &&
                                    <ArgInputNumber
                                        className={classNames('&-scope-value')}
                                        value={dataValue}
                                        size='small'
                                        onChange={changeScopeValue}
                                    />}
                            </>
                        )}
                        {targetOption && targetOption.type !== 'always' && (targetOption.variableType === 'string' || targetOption.variableType === 'bool') && (
                            <>
                                <ArgCombo<ScopeOperator>
                                    items={SCOPE_OPERATORS_TEXT_BOOL}
                                    getItemKey='key'
                                    getItemLabel='label'
                                    value={dataOperator}
                                    className={classNames('&-scope-operator')}
                                    placeholder={intl.formatMessage(messages.select)}
                                    popoverClassName='arg-input-popover-no-max-width'
                                    cardinality='one'
                                    size='small'
                                    onChange={changeOperatorDropdown}
                                />
                                {targetOption.variableType === 'string' && targetOption.key !== 'system.CurrentDate' &&
                                    <ArgInputText
                                        className={classNames('&-scope-value')}
                                        value={dataValue}
                                        size='small'
                                        onChange={changeScopeValue}
                                    />}
                                {targetOption.key === 'system.CurrentDate' &&
                                    <ArgInputDate
                                        className={classNames('&-scope-value')}
                                        value={dataValue}
                                        size='small'
                                        onChange={changeScopeValue}
                                    />}
                                {targetOption.variableType === 'bool' &&
                                    <ArgSwitch
                                        className={classNames('&-scope-value')}
                                        value={dataValue}
                                        size='small'
                                        onChange={changeScopeValue}
                                    />}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <ReadOnlyFilterInput
                            className={classNames('&-scope-key')}
                            value={targetOption?.text}
                            search={search}
                        />
                        {targetOption && targetOption.type !== 'always' && dataOperator && (
                            <>
                                <ReadOnlyFilterInput
                                    className={classNames('&-scope-operator')}
                                    value={dataOperator?.label}
                                    search={search}
                                />
                                {targetOption.variableType !== 'bool' && <ReadOnlyFilterInput
                                    className={classNames('&-scope-value')}
                                    value={targetOption.key !== 'system.CurrentDate' ? dataValue : dayjs(dataValue).format(internalisationDateFormat(intl))}
                                    search={search}
                                />}
                                {targetOption.variableType === 'bool' &&
                                    <ArgSwitch
                                        className={classNames('&-scope-value')}
                                        value={dataValue}
                                        size='small'
                                        readOnly={true}
                                    />}
                            </>
                        )}
                    </>
                )}
                {editable && currentPath !== '' && (
                    <div
                        className={classNames('&-delete-icon')}
                        onClick={() => setScope(deleteScopeRow(currentPath, scope))}
                    >
                        <ArgIcon name='icon-trash' />
                    </div>
                )}
                {editable && currentPath === '' && targetOption && (
                    <div
                        className={classNames('&-delete-icon')}
                        onClick={() => setScope({ Type: undefined, Data: {}, id: scope.id })}
                    >
                        <ArgIcon name='icon-trash' />
                    </div>
                )}
            </div>
        </div>
    );
}

