import {
    folderStateFactory,
    useFolderContentState,
    useFolderContentStates,
    useFolderInfoState,
    useFolderState,
    useFolderStates,
} from 'src/utils/rt-states/folders/use-folder-state';
import { FolderId } from '../../model/folder';
import { FolderState } from 'src/utils/rt-states/folders/folder-state';
import { BasicStates } from '../../utils/rt-states/use-basic-states';
import { getDataPreparationFoldersRtApi } from './rt-apis';
import { FoldersState } from '../../utils/rt-states/folders/folders-state';
import { useFoldersState } from '../../utils/rt-states/folders/use-folders-state';
import { RtMessageEventList } from 'src/utils/rt-states/events';

export function preparationFolderStateFactory(url: string, caseId: FolderId, acceptedEvents?: RtMessageEventList) {
    const ret = folderStateFactory(url, getDataPreparationFoldersRtApi(), caseId, acceptedEvents);

    return ret;
}

export function usePreparationFolderState(folderId: undefined): undefined;
export function usePreparationFolderState(folderId: FolderId): FolderState;
export function usePreparationFolderState(folderId: FolderId | undefined): FolderState | undefined;
export function usePreparationFolderState(folderId: FolderId | undefined): FolderState | undefined {
    const ret = useFolderState(getDataPreparationFoldersRtApi(), folderId);

    return ret;
}

export function usePreparationFolderInfoState(folderId: undefined): undefined;
export function usePreparationFolderInfoState(folderId: FolderId): FolderState;
export function usePreparationFolderInfoState(folderId: FolderId | undefined): FolderState | undefined;
export function usePreparationFolderInfoState(folderId: FolderId | undefined): FolderState | undefined {
    const ret = useFolderInfoState(getDataPreparationFoldersRtApi(), folderId);

    return ret;
}

export function usePreparationFolderContentState(folderId: undefined): undefined;
export function usePreparationFolderContentState(folderId: FolderId): FolderState;
export function usePreparationFolderContentState(folderId: FolderId | undefined): FolderState | undefined;
export function usePreparationFolderContentState(folderId: FolderId | undefined): FolderState | undefined {
    const ret = useFolderContentState(getDataPreparationFoldersRtApi(), folderId);

    return ret;
}

export function usePreparationFolderStates(folderIds: FolderId[]): BasicStates<FolderState>;
export function usePreparationFolderStates(folderIds: undefined): undefined;
export function usePreparationFolderStates(folderIds: FolderId[] | undefined): BasicStates<FolderState> | undefined;
export function usePreparationFolderStates(folderIds: FolderId[] | undefined): BasicStates<FolderState> | undefined {
    const ret = useFolderStates(getDataPreparationFoldersRtApi(), folderIds);

    return ret;
}

export function usePreparationFoldersState(): FoldersState | undefined {
    const ret = useFoldersState(getDataPreparationFoldersRtApi());

    return ret;
}

export function usePreparationFolderContentStates(folderIds: FolderId[]): BasicStates<FolderState>;
export function usePreparationFolderContentStates(folderIds: undefined): undefined;
export function usePreparationFolderContentStates(folderIds: FolderId[] | undefined): BasicStates<FolderState> | undefined;
export function usePreparationFolderContentStates(folderIds: FolderId[] | undefined): BasicStates<FolderState> | undefined {
    const ret = useFolderContentStates(getDataPreparationFoldersRtApi(), folderIds);

    return ret;
}
