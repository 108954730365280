import { IntlShape } from 'react-intl';
import { Dictionary } from 'lodash';

import { CaseId, FolderCasePiece, CasePath, ExpandableCaseInfo, FolderCustomFields } from '../model/folder-case-piece';
import { User } from '../model/user';
import { formatUser } from './formatter';
import { stringSorter } from './sorter';
import { FolderCustomFieldName } from '../model/folder-custom-field-definition';

const CASE_FULLPATH_SEPARATOR = '$$/$$ ';

function getCaseParentsTree(caseInfo: FolderCasePiece, cases: FolderCasePiece[]) {
    const ret = new Array<FolderCasePiece>();

    if (caseInfo.parentFolderId) {
        const parentCase = cases.find(_case => _case.id === caseInfo.parentFolderId);
        if (parentCase) {
            ret.push(...getCaseParentsTree(parentCase, cases));
        }
    }

    ret.push(caseInfo);

    return ret;
}

function getExpandableCase(caseInfo: FolderCasePiece, cases: FolderCasePiece[]): ExpandableCaseInfo {
    const caseParentsTree = getCaseParentsTree(caseInfo, cases);
    const caseFullPath = `${caseParentsTree.map(_caseInfo => _caseInfo.displayName).join(CASE_FULLPATH_SEPARATOR)}${CASE_FULLPATH_SEPARATOR}`;
    const ancestorIds = caseParentsTree.length > 1 ?
        caseParentsTree.filter(_caseInfo => _caseInfo.id !== caseInfo.id).map(_caseInfo => _caseInfo.id)
        : undefined;

    const expandableCase: ExpandableCaseInfo = {
        ...caseInfo,
        fullPath: caseFullPath,
        hasAnyChild: cases.findIndex(_caseInfo => _caseInfo.parentFolderId === caseInfo.id) > -1,
        level: ancestorIds?.length || 0,
        ancestorIds: ancestorIds,
    };

    return expandableCase;
}

export function getExpandableCases(cases: FolderCasePiece[]) {
    const expandableCases = cases.map(caseInfo => getExpandableCase(caseInfo, cases));

    return expandableCases.sort((a, b) => stringSorter<ExpandableCaseInfo>(a, b, (item) => item.fullPath));
}

export const getCaseDisplayedOwners = (caseItem: FolderCasePiece, intl: IntlShape, me: User) => {
    let owners = caseItem.userPermissions
        ?.filter(permission => permission.permissions.hasOwnership)
        .map(permission => formatUser(intl, permission.user, me)) || [];

    if (caseItem.userGroupPermissions) {
        owners = owners.concat(caseItem.userGroupPermissions
            .filter(permission => permission.permissions.hasOwnership)
            .map(userGroupPermission => userGroupPermission.userGroup.name));
    }

    const stringOwners = owners.join(', ');

    return stringOwners;
};

export function buildDisplayedCaseFullPath(caseFullPath: string) {
    const casePaths = caseFullPath.split(CASE_FULLPATH_SEPARATOR);

    return casePaths.slice(0, casePaths.length - 1).join(' > ');
}

export function buildCasePaths(caseId: CaseId, expandableCasesById: Dictionary<ExpandableCaseInfo>) {
    const ret: CasePath[] = [];
    const expandableCaseInfo = expandableCasesById[caseId];
    if (expandableCaseInfo.ancestorIds) {
        expandableCaseInfo.ancestorIds.forEach(ancestorId => {
            const ancestor = expandableCasesById[ancestorId];
            const casePath: CasePath = {
                id: ancestorId,
                name: ancestor.displayName,
            };

            ret.push(casePath);
        });
    }

    ret.push({ id: caseId, name: expandableCaseInfo.displayName });

    return ret;
}


export function isParentFolderReadable(caseInfo: FolderCasePiece) {
    return !caseInfo.parentFolderId && caseInfo.isParentFolderReadable === false;
}

export function computeDisplayName(name: string, existingNames: string[]): string {
    const recursiveComputeName = (name: string, existingNames: string[], depth: number): string => {
        const newName = depth === 1 ? name : `${name} (${depth})`;
        if (!existingNames.includes(newName)) {
            return newName;
        }

        return recursiveComputeName(name, existingNames, depth + 1);
    };

    return recursiveComputeName(name, existingNames, 1);
}

export const getCaseCustomFieldValue = <T>(customFields: FolderCustomFields | undefined, columnName: FolderCustomFieldName) => {
    const value = customFields?.[columnName];

    return value as T | undefined;
};
