import { useCallback, useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useNavigate } from 'react-router';

import {
    ArgButton,
    ArgFormattedMessage,
    ArgImage,
    ArgMessageRenderer,
    ArgPageConfig,
    isMessageDescriptor,
    ThreeDotsLoading,
    useClassNames,
} from '../../basic';
import { Environment } from '../../../utils/environment';
import { LoginForm } from './login-form';
import { useApplicationBranding } from '../../application/argonos-application-branding';
import { redirectToOAuthLogin } from 'src/components/caches/pages-access';
import { useCurrentUserWitRetry } from 'src/hooks/use-current-user-with-retry';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { ErrorPane } from 'src/components/common/panes/error-pane';

import argonosImage from 'src/assets/argonos-by-chaps.png';
import './login.less';

const FORCE_ERROR = false;
const FORCE_LOADING = false;

const messages = defineMessages({
    continueButton: {
        id: 'common.login.ContinueButton',
        defaultMessage: 'Continue',
    },
    welcomeLabel: {
        id: 'common.login.welcomeLabel',
        defaultMessage: 'Welcome',
    },
    pageWelcomeTitle: {
        id: 'common.login.pageWelcomeTitle',
        defaultMessage: 'Welcome',
    },
    pageDescription: {
        id: 'common.login.PageDescription',
        defaultMessage: 'Access the sovereign data operating system to help you prepare, structure and analyze your data',
    },
    errorLoadingUserProfile: {
        id: 'common.login.ErrorLoadingUserProfile',
        defaultMessage: '<p><b>Internal server error</b></p><p>Error while loading user profile.</p><p>Please retry in few minutes.</p>',
    },
});

export function Login() {
    const classNames = useClassNames('common-login-page');
    const applicationBranding = useApplicationBranding();
    const navigate = useNavigate();

    const [loadingUser, setLoadingUser] = useState(true);

    const onUnauthorized = useCallback(() => {
        if (Environment.apiOAuth) {
            redirectToOAuthLogin();
        }

        // If no aAuth do nothing and let the user login use legacy form
        setLoadingUser(false);
    }, []);

    const [currentUser, progressMonitor, error] = useCurrentUserWitRetry(onUnauthorized);

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        navigate('/redirect', { replace: true, state: { userMe: currentUser } });
    }, [currentUser, navigate]);

    const handleRedirect = useCallback(() => {
        navigate('/redirect', { replace: true });
    }, [navigate]);


    if (FORCE_ERROR || error) {
        return (
            <div className={classNames('&-error')}>
                <ErrorPane
                    message={messages.errorLoadingUserProfile}
                    error={error}
                    size='large'
                />
            </div>
        );
    }

    if (FORCE_LOADING || loadingUser || progressMonitor?.isRunning) {
        return (
            <div className={classNames('&-loading')}>
                <LoadingPane
                    progressMonitor={progressMonitor}
                    size='large'
                />
            </div>
        );
    }

    let body;
    if (Environment.apiOAuth) {
        body = <>
            {/* Api login with OAuth */}
            <div className={classNames('&-oauth')}>
                <ArgFormattedMessage message={messages.welcomeLabel} />

                <ThreeDotsLoading />
            </div>
            <ArgButton
                hidden={true}
                data-testid='login-button'
                size='large'
                htmlType='submit'
                className={classNames('&-form-item', '&-form-submit-button')}
                label={messages.continueButton}
                onClick={handleRedirect}
                autoFocus={true}
            />
        </>;
    } else {
        body = <LoginForm onContinue={handleRedirect} />;
    }

    const applicationName: string = isMessageDescriptor(applicationBranding?.brandingName)
        ? String(applicationBranding?.brandingName.defaultMessage)
        : (applicationBranding?.brandingName as string);

    return (
        <ArgPageConfig pageSubTitle={messages.pageWelcomeTitle}>
            <div className={classNames('&')}>
                <div className={classNames('&-left')}>
                    <div className={classNames('&-left-content')}>
                        {!localStorage.ANONYMIZE && <ArgImage
                            src={applicationBranding?.brandingLogoURL}
                            alt={applicationName}
                            placeholder={false}
                            className={classNames('&-left-logo')}
                        />}
                        {body}
                    </div>
                </div>

                <div className={classNames('&-right')}>
                    <div className={classNames('&-right-background')}>
                        <div className={classNames('&-right-background-light-diamond')} />
                        <div className={classNames('&-right-background-dark-diamond')} />
                        <div className={classNames('&-right-background-cut-diamond')} />
                    </div>
                    <div className={classNames('&-right-content')}>
                        <img src={argonosImage} className={classNames('&-right-content-image')} />
                        <ArgMessageRenderer
                            className={classNames('&-right-content-text')}
                            message={messages.pageDescription}
                        />
                    </div>
                </div>
            </div>
        </ArgPageConfig>
    );
}
