import React from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton } from '../arg-button/arg-button';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { TooltipPlacement } from '../arg-tooltip/arg-tooltip2';
import { KeyBindingDescriptor } from '../keybindings/keybinding';

import './arg-zoom-toolbox.less';

const messages = defineMessages({
    zoomIn: {
        id: 'basic.arg-zoom-toolbox.ZoomInTooltip',
        defaultMessage: 'Zoom in',
    },
    zoomOut: {
        id: 'basic.arg-zoom-toolbox.ZoomOutTooltip',
        defaultMessage: 'Zoom out',
    },
});

interface ArgZoomToolboxProps {
    className?: ClassValue;
    onZoom: (reason: 'in' | 'out') => void;
    zoom?: number;
    minZoom?: number;
    maxZoom?: number;
    tooltipPlacement?: TooltipPlacement;
    zoomInKeyBinding?: KeyBindingDescriptor;
    zoomOutKeyBinding?: KeyBindingDescriptor;
}

export function ArgZoomToolbox({
    className,
    onZoom,
    zoom,
    minZoom,
    maxZoom,
    tooltipPlacement,
    zoomInKeyBinding,
    zoomOutKeyBinding,
}: ArgZoomToolboxProps) {
    const classNames = useClassNames('arg-zoom-toolbox');

    return (
        <div className={classNames('&', className)}>
            <ArgButton
                className={classNames('&-zoom-in')}
                data-testid='zoom-in'
                onClick={() => onZoom('in')}
                disabled={zoom === maxZoom}
                icon='icon-plus'
                tooltip={messages.zoomIn}
                tooltipPlacement={tooltipPlacement}
                keyBinding={zoomInKeyBinding}
            />
            <ArgButton
                className={classNames('&-zoom-out')}
                data-testid='zoom-out'
                onClick={() => onZoom('out')}
                icon='icon-minus'
                disabled={zoom === minZoom}
                tooltip={messages.zoomOut}
                tooltipPlacement={tooltipPlacement}
                keyBinding={zoomOutKeyBinding}
            />
        </div>
    );
}
