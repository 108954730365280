import { isEmpty, isNil, isObject, pickBy, uniqBy } from 'lodash';

import { Operator, OPERATORS } from '../common/rules/universe-property-filter/universe-property-filter';
import { MetaPropertyObject, PropertyObject, RuleEffect, RuleTarget, SchemaObject } from '../../models/policy';
import { splitMetapropertyPredicate } from './rule-effect-utils';
import { ValuationRuleEffect } from 'src/settings/models/valuation-policy';
import { FormRuleEffect } from 'src/settings/models/form-policy';
import { OntologyBaseSchema, OntologyProperty } from '../ontology/types';
import { PropertyValue } from '../types';

export const ROW_HEIGHT = 32;
export const TREE_BLOCK_HEIGHT = (ROW_HEIGHT + 10) / 2;

// This is an object with _kind and _type (an object to define the vertex/edge to target)
export const isUniverseItemObject = (ruleTarget: RuleTarget) => {
    return !!ruleTarget.object && ('_kind' in ruleTarget.object || '_type' in ruleTarget.object);
};

export const isValidUniverseItemObject = (ruleTarget: RuleTarget) => {
    return !!ruleTarget.object; // Can be any && !!ruleTarget.object._type;
};

// This is an empty object (the default when a new rule is added)
export const isEmptyObject = (ruleTarget: RuleTarget) => {
    return !!ruleTarget.object && isEmpty(ruleTarget.object);
};

// This is an object that represents a property filter (applied to a vertex/edge)
export const isUniversePropertyObject = (ruleTarget: RuleTarget) => {
    return !!ruleTarget.object && !isUniverseItemObject(ruleTarget);
};

export const isMetaPropertyObject = (x: any): x is MetaPropertyObject => {
    return !!x?.meta && isObject(x.meta);
};

export const isSchemaObject = (x: any): x is SchemaObject => {
    return !!x?._kind || !!x?._type;
};

export const isValidUniversePropertyObject = (ruleTarget: RuleTarget) => {
    if (!ruleTarget.object || isUniverseItemObject(ruleTarget)) return false;
    const singleEntry = Object.entries(ruleTarget.object).length === 1;
    if (!singleEntry) return false;
    const [objKey, objVal] = Object.entries(ruleTarget.object)[0];
    const operation = isMetaPropertyObject(objVal) ? Object.entries(objVal.meta)[0]?.[1] : objVal;
    const { operator, value } = getObjectPropertyOperatorAndValue(operation);

    return !!objKey && !!operator && !!value;
};

// This is an AND that has a schema object and a property object within it. It represents a full filter in the FE.
// It returns both the schema and the property objects, as well as their index in the AND (to know which is 0 and which is 1)
export const isUniverseTypeAndPropertyFilter = (ruleTarget: RuleTarget) => {
    if (!ruleTarget.and) return false;
    if (ruleTarget.and.length !== 2) return false;

    if (isUniversePropertyObject(ruleTarget.and[0]) && isUniversePropertyObject(ruleTarget.and[1])) {
        return {
            schema: { val: ruleTarget.and[0].object, idx: 0 },
            property: { val: ruleTarget.and[1].object, idx: 1 },
        } as {
            schema: { val: SchemaObject; idx: number };
            property: { val: PropertyObject; idx: number };
        };
    }

    if (isUniverseItemObject(ruleTarget.and[0]) && isUniversePropertyObject(ruleTarget.and[1])) {
        return {
            schema: { val: ruleTarget.and[0].object, idx: 0 },
            property: { val: ruleTarget.and[1].object, idx: 1 },
        } as {
            schema: { val: SchemaObject; idx: number };
            property: { val: PropertyObject; idx: number };
        };
    }
    if (isUniversePropertyObject(ruleTarget.and[0]) && isUniverseItemObject(ruleTarget.and[1])) {
        return {
            property: { val: ruleTarget.and[0].object, idx: 0 },
            schema: { val: ruleTarget.and[1].object, idx: 1 },
        } as {
            schema: { val: SchemaObject; idx: number };
            property: { val: PropertyObject; idx: number };
        };
    }

    return false;
};

export const isUniversePropertyFilter = (ruleTarget: RuleTarget) => {
    if (!ruleTarget.object) {
        return undefined;
    }

    const property = Object.entries(ruleTarget.object).filter(([key]) => key !== '_kind' && key !== '_type').map(([key, value]) => {
        return { key, value };
    });

    return property;
};

export const isValidUniverseTypeAndPropertyFilter = (ruleTarget: RuleTarget) => {
    if (!ruleTarget.and) return false;
    if (ruleTarget.and.length !== 2) return false;

    if (
        isValidUniverseItemObject(ruleTarget.and[0]) &&
        isValidUniversePropertyObject(ruleTarget.and[1])
    ) {
        return true;
    }
    if (
        isValidUniversePropertyObject(ruleTarget.and[0]) &&
        isValidUniverseItemObject(ruleTarget.and[1])
    ) {
        return true;
    }

    return false;
};

export const isFilterRow = (ruleTarget: RuleTarget) => {
    return (
        isUniverseItemObject(ruleTarget) ||
        isEmptyObject(ruleTarget) ||
        !!isUniverseTypeAndPropertyFilter(ruleTarget)
    );
};

export const objectKindUserToSchema = (kind: string) => {
    if (kind === 'Object' || kind === 'Objet') return 'Vertex';

    return 'Edge';
};

export const getObjectPropertyOperatorAndValue = (input: string | undefined): {
    operator?: Operator; value?: PropertyValue; from?: string;
} => {
    const match = input?.match(/^([a-z]+:)?(=|!=|>=|<=|>|<)(.*)$/i);

    if (!match) {
        return {
            from: undefined,
            operator: undefined,
            value: undefined,
        };
    }

    let parsedValue;
    try {
        // Value can be not stringified if it have a from or is a fixed value
        parsedValue = JSON.parse(match[3]);
    } catch {
        parsedValue = match[3];
    }

    return {
        operator: OPERATORS.find((o) => (o.key === match[2])),
        value: parsedValue === '' ? undefined : parsedValue,
        from: match[1]?.slice(0, -1) || undefined,
    };
};

export const isValidTarget = (ruleTarget: RuleTarget): boolean => {
    if (isValidUniverseItemObject(ruleTarget) || isValidUniverseTypeAndPropertyFilter(ruleTarget)) {
        return true;
    }
    if (ruleTarget.and) {
        return ruleTarget.and.every((expression) => isValidTarget(expression));
    }
    if (ruleTarget.or) {
        return ruleTarget.or.every((expression) => isValidTarget(expression));
    }

    return false;
};

export const isValidEffect = (ruleEffect: RuleEffect) => {
    if (ruleEffect.object) {
        return (
            ruleEffect.object.Permissions.length > 0 &&
            ruleEffect.object.Permissions.every((p) => p.length > 1)
        );
    }
    if (ruleEffect.property) {
        if (ruleEffect.property.targets?.[0]?.meta) {
            const meta = ruleEffect.property.targets[0].meta;
            const metaKeys = Object.keys(meta);
            const metaSplit = metaKeys[0] && splitMetapropertyPredicate(meta[metaKeys[0]]);

            return (
                metaKeys.length === 1 &&
                metaSplit &&
                metaSplit.operator &&
                metaSplit.value
            );
        } else {
            return (
                ruleEffect.property.match &&
                ruleEffect.property.match.length > 0 &&
                ruleEffect.property.Permissions.length > 0 &&
                ruleEffect.property.Permissions.every((p) => p.length > 1)
            );
        }
    }

    return false;
};

export const isValidValuationEffect = (ruleEffect: ValuationRuleEffect) => {
    if (ruleEffect.object) {
        return (
            !!(
                ruleEffect.object.setProperty
                && ruleEffect.object.setProperty.name
                && !isNil(ruleEffect.object.setProperty.value)
                && ruleEffect.object.setProperty.onEvent
            )
        );
    }
    if (ruleEffect.property) {
        return (
            !!(
                ruleEffect.property.setMetadata
                && ruleEffect.property.setMetadata.name
                && !isNil(ruleEffect.property.setMetadata.value)
                && ruleEffect.property.setMetadata.onEvent
                && ruleEffect.property.match?.length
            )
        );
    }

    return false;
};

export const isValidFormEffect = (ruleEffect: FormRuleEffect) => {
    return !!ruleEffect.template?.key;
};

export const getFirstRuleSchema = (ruleTarget: RuleTarget): SchemaObject | undefined => {
    if (isUniverseItemObject(ruleTarget)) {
        return ruleTarget.object as SchemaObject;
    }
    const typeAndPropertyFilter = isUniverseTypeAndPropertyFilter(ruleTarget);
    if (typeAndPropertyFilter) {
        return typeAndPropertyFilter.schema.val;
    }
    if (ruleTarget.and?.length) {
        return getFirstRuleSchema(ruleTarget.and[0]);
    }
    if (ruleTarget.or?.length) {
        return getFirstRuleSchema(ruleTarget.or[0]);
    }

    return undefined;
};

export const setEveryRuleSchema = (ruleTarget: RuleTarget, schema: SchemaObject): RuleTarget => {
    const ret = {
        ...ruleTarget,
        object: isUniverseItemObject(ruleTarget) ? schema : ruleTarget.object,
        and: ruleTarget.and?.map((r) => setEveryRuleSchema(r, schema)),
        or: ruleTarget.or?.map((r) => setEveryRuleSchema(r, schema)),
    };

    return pickBy(ret, (v) => v !== undefined);
};


/**
 *
 * The target rules we get from the backend could not match the pattern required for the design. The following functions are used to try to fix those instances (if the schema is invalid)
 *
 * For example we cannot display an {and: [schemaObject, or:[propertyObject1, propertyObject2]]} because the design only takes a single 'dont' after the schemaObject, not multiples.
 * This case, for instance should actually be coded as {or: [{and: [schemaObject, propertyObject1], {and:[schemaObject, propertyObject2]}]}
 *
 * The `isSchemaValid` function will check whether the schema is valid. It is valid if every ruleTarget is a `isUniverseItemObject`, a `isUniverseTypeAndPropertyFilter`, or and and/or with valid rules withing them\
 */

export const isSchemaValid = (ruleTarget: RuleTarget): boolean => {
    if (
        isUniverseItemObject(ruleTarget) ||
        !!isUniverseTypeAndPropertyFilter(ruleTarget) ||
        isEmptyObject(ruleTarget)
    ) {
        return true;
    }
    if (ruleTarget.and) {
        return ruleTarget.and.every((expression) => isSchemaValid(expression));
    }
    if (ruleTarget.or) {
        return ruleTarget.or.every((expression) => isSchemaValid(expression));
    }

    return false;
};

export function getObjectsTypes(kind: 'Vertex' | 'Edge' | undefined, schema: OntologyBaseSchema) {
    if (kind === undefined) {
        return [...schema.objectTypes, ...schema.linkTypes];
    }

    const objectTypes = (kind === 'Vertex') ? schema.objectTypes : schema.linkTypes;

    return objectTypes;
}


export function getObjectProperties(object: SchemaObject, schema: OntologyBaseSchema) {
    const objectTypes = getObjectsTypes(object._kind, schema);

    const filteredObjectTypes = (object._type !== undefined)
        ? objectTypes.filter((type) => type.name === object._type)
        : objectTypes;

    const properties = filteredObjectTypes.flatMap((objType) => objType.properties);
    const uniqProperties = uniqBy(properties, (prop) => prop.name);

    return uniqProperties;
}

export function getTargetsUniqProperties(schema: OntologyBaseSchema, targets: RuleTarget[]): OntologyProperty[] {
    const propertiesWithObject = targets.reduce((acc, target) => {
        const objects = getRuleTargetsObjects(target);

        objects.forEach((object) => {
            if (isEmpty(object) || '_kind' in object) {
                acc.push(...getObjectProperties(object, schema));
            }
        });

        return acc;
    }, [] as OntologyProperty[]);

    const uniqProperties = uniqBy(propertiesWithObject, (propWithObj) => propWithObj.name);

    return uniqProperties;
}

export function getRuleTargetsObjects(target: RuleTarget): (SchemaObject | PropertyObject)[] {
    const objects = [] as (SchemaObject | PropertyObject)[];

    if (target.object) {
        objects.push(target.object);
    }

    if (target.and) {
        const andObjects = target.and.flatMap(getRuleTargetsObjects);
        objects.push(...andObjects);
    }

    if (target.or) {
        const orObjects = target.or.flatMap(getRuleTargetsObjects);
        objects.push(...orObjects);
    }

    return objects;
}
