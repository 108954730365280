import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { ExplorationId } from '../model/exploration';
import { ExplorationState } from './exploration-state';

export function explorationStateFactory(url: string, explorationId: ExplorationId) {
    return new ExplorationState(url, explorationId);
}

export function computeExplorationStateURL(explorationId: ExplorationId) {
    return `/explorations/${explorationId}`;
}

export function useExplorationState(explorationId: ExplorationId): ExplorationState;
export function useExplorationState(explorationId: ExplorationId | undefined): ExplorationState | undefined;

export function useExplorationState(explorationId: ExplorationId | undefined): ExplorationState | undefined {
    const state = useBasicState<ExplorationState>(
        (url) => explorationStateFactory(url, explorationId!),
        (explorationId) ? computeExplorationStateURL(explorationId) : undefined,
    );

    return state;
}
