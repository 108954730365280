import { defineMessages } from 'react-intl';

import { registerToolItem, Tool } from '../../components/basic';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../envrionment-contexts/context-names';
import { SettingsEnvironmentContext } from '../envrionment-contexts/settings-environment-context';
import { SettingsPermissions } from '../permissions/permissions';

export const USERS_AND_GROUPS_ROUTES = '/settings/users-and-groups';
export const GROUPS_ROUTES = '/settings/groups';
export const GROUPS_ACTIVE_ROUTE = `${GROUPS_ROUTES}`;
export const GROUP_ROUTE = `${GROUPS_ROUTES}/:groupId`;

const messages = defineMessages({
    usersAndGroups: {
        id: 'settings.users.menu.title',
        defaultMessage: 'Users and Groups',
    },
});

export function setupUsersSettings() {
    registerToolItem<SettingsEnvironmentContext>(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path: 'global/users-and-groups',
        label: messages.usersAndGroups,
        icon: 'icon-users-and-groups',
        visible: (environmentContext: SettingsEnvironmentContext) => {
            const hasUsersAndGroupsAccess = environmentContext.hasAnyPermissions<SettingsPermissions>(
                'admin.user.access',
                'admin.user.group.access',
            );

            return hasUsersAndGroupsAccess;
        },
        onClick: (tool: Tool<SettingsEnvironmentContext>, environmentContext: SettingsEnvironmentContext) => {
            environmentContext.navigate(USERS_AND_GROUPS_ROUTES);
        },
    });
}
