import { FolderState } from './folder-state';
import { useBasicState } from '../use-basic-state';
import { BasicStates, useBasicStates } from '../use-basic-states';
import { RtMessageEventList } from '../events';
import { FOLDER_ENTITY_CONTENT_EVENT_NAMES, FOLDERS_INFO_EVENT_NAMES } from './events';
import { FolderId } from '../../../model/folder';
import { RtApi } from '../rt-api';

export function folderStateFactory(url: string, api: RtApi, folderId: FolderId, acceptedEvents?: RtMessageEventList) {
    return new FolderState(api, url, folderId, acceptedEvents);
}

export function computeFolderStateURL(folderId: FolderId) {
    return `/folders/${folderId}`;
}

export function computeFolderInfoStateURL(folderId: FolderId) {
    return `/folders/${folderId}/info`;
}

export function computeFolderContentStateURL(folderId: FolderId) {
    return `/folders/${folderId}/content`;
}

export function useFolderState(api: RtApi, folderId: FolderId | undefined): FolderState | undefined {
    const state = useBasicState<FolderState>(
        (url) => folderStateFactory(url, api, folderId!),
        (folderId) ? computeFolderStateURL(folderId) : undefined,
    );

    return state;
}

export function useFolderInfoState(api: RtApi, folderId: FolderId | undefined): FolderState | undefined {
    const state = useBasicState<FolderState>(
        (url) => folderStateFactory(url, api, folderId!, FOLDERS_INFO_EVENT_NAMES),
        (folderId) ? computeFolderInfoStateURL(folderId) : undefined,
    );

    return state;
}

export function useFolderContentState(api: RtApi, folderId: FolderId | undefined): FolderState | undefined {
    const state = useBasicState<FolderState>(
        (url) => folderStateFactory(url, api, folderId!, FOLDER_ENTITY_CONTENT_EVENT_NAMES),
        (folderId) ? computeFolderContentStateURL(folderId) : undefined,
    );

    return state;
}

export function useFolderStates(api: RtApi, folderIds: FolderId[] | undefined): BasicStates<FolderState> | undefined {
    const state = useBasicStates(
        (url, folderId) => folderStateFactory(url, api, folderId), folderIds,
        (folderId) => computeFolderStateURL(folderId),
    );

    return state;
}

export function useFolderContentStates(api: RtApi, folderIds: FolderId[] | undefined): BasicStates<FolderState> | undefined {
    const state = useBasicStates(
        (url, folderId) => folderStateFactory(url, api, folderId, FOLDER_ENTITY_CONTENT_EVENT_NAMES), folderIds,
        (folderId) => computeFolderContentStateURL(folderId),
    );

    return state;
}
