import { useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';

import { ProgressMonitor, SubProgressMonitor, useArgModalContext, useArgNotifications, useCallbackAsync } from '../../components/basic';
import { ConfirmModal } from '../../components/common/modal2/confirm-modal/confirm-modal';
import ExtensionConnector from '../connectors/extensions-connector';
import { Extension } from '../models/extension';
import { ArgonosModule } from '../../components/application/modules';
import { downloadBlob } from '../../utils/file';

const DELETE_EXTENSIONS_MODAL = 'DELETE_EXTENSIONS_MODAL';

const messages = defineMessages({
    updateStateExtensionErrorMsg: {
        id: 'settings-extensions.updateStateExtensionErrorMsg',
        defaultMessage: 'An error occurred while update the extensions state',
    },
    deleteExtensionErrorMsg: {
        id: 'settings-extensions.deleteExtensionErrorMsg',
        defaultMessage: 'An error occurred while deleting the extensions',
    },
    deleteConfirmTitle: {
        id: 'settings-extensions.deleteConfirmTitle',
        defaultMessage: '{count, plural, =1 {Delete extension} other {Delete extensions}}',
    },
    deleteConfirmMessage: {
        id: 'settings-extensions.deleteConfirmMessage',
        defaultMessage: '{count, plural, =1 {Are you sure you want to delete \"{name}\" ?} other {Are you sure you want to delete these {count} extensions ?}}',
    },
    exportExtensionErrorMsg: {
        id: 'settings-extensions.exportExtensionErrorMsg',
        defaultMessage: 'An error occurred while exporting the extensions',
    },
});

export function useExtensions(
    extensions: Extension[],
    argonosModule: ArgonosModule | undefined,
    onSuccess?: () => void,
) {
    const [popoverVisible, setPopoverVisible] = useState<boolean>();

    const notifications = useArgNotifications();

    const modalContext = useArgModalContext();

    const [handleUpdateStatus] = useCallbackAsync(async (progressMonitor: ProgressMonitor, enabled: boolean) => {
        setPopoverVisible(false);
        const promises = extensions
            .filter(extension => extension.state.enabled !== enabled)
            .map(async (extension) => {
                const sub = new SubProgressMonitor(progressMonitor, 1);

                const p = ExtensionConnector.getInstance().updateState(extension.name, enabled, argonosModule, sub);

                return p;
            });

        try {
            await Promise.all(promises);
            onSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.updateStateExtensionErrorMsg }, error as Error);
            throw error;
        }
    }, [notifications, onSuccess, extensions, argonosModule]);

    const [handleDeleteExtensions] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const promises = extensions.map(async (extension) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);

            const p = ExtensionConnector.getInstance().deleteExtension(extension.name, argonosModule, sub);

            return p;
        });

        try {
            await Promise.all(promises);
            onSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.deleteExtensionErrorMsg }, error as Error);
            throw error;
        }
    }, [notifications, onSuccess, extensions, argonosModule]);

    const extensionsDeletedName = extensions.length === 1 ? extensions[0].name : undefined;

    const handleDeleteConfirmModal = useCallback(() => {
        setPopoverVisible(false);

        modalContext.open(DELETE_EXTENSIONS_MODAL, (
            <ConfirmModal
                type='delete'
                title={messages.deleteConfirmTitle}
                alertMessage={messages.deleteConfirmMessage}
                messageValues={{ count: extensions.length, name: extensionsDeletedName }}
                onClose={() => modalContext.close(DELETE_EXTENSIONS_MODAL)}
                onConfirm={handleDeleteExtensions}
            />
        ));
    }, [modalContext, extensions, handleDeleteExtensions, extensionsDeletedName]);

    const [handleExport] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        setPopoverVisible(false);
        const promises = extensions.map(async extension => {
            const sub = new SubProgressMonitor(progressMonitor, 1);

            const blob = await ExtensionConnector.getInstance().exportExtension(extension.name, argonosModule, sub);
            downloadBlob(`${extension.name}-${new Date().getTime()}.zip`, blob);
        });

        try {
            await Promise.all(promises);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.exportExtensionErrorMsg }, error as Error);
            throw error;
        }
    }, [argonosModule, extensions, notifications]);

    return {
        handleUpdateStatus,
        handleDeleteConfirmModal,
        handleExport,
        popoverVisible,
        setPopoverVisible,
    };
}
