import { BasicEnvironmentContext } from '../../framework/environment-contexts/basic-environment-context';
import { StatesRegistry } from '../../utils/rt-states/states-registry';


export const KB_TABS_CONTEXT_NAME = 'kb.tabs';

export const KB_ENTITIES_TABLE_ROW_MENU_CONTEXT = 'kb.entities:RowMenuContext';

export const KB_ENTITIES_TABLE_LIST_MENU_CONTEXT = 'kb.entities.entityType';

export const KB_ENTITY_PAGE_MENU_CONTEXT = 'kb.entityPage:MenuContext';

export const KB_ENTITY_TYPE_MIME_TYPE = 'application/arg-knowledge-base-entity-type';
export const KB_ENTITY_IDENTIFIER_MIME_TYPE = 'application/arg-knowledge-base-entity-identifier';

export interface KBEnvironmentContext extends BasicEnvironmentContext {
    statesRegistry: StatesRegistry;
}
