import { ReactNode, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { chain } from 'lodash';

import {
    ClassValue,
} from '../basic';
import { useGetMyPermissions } from 'src/contexts/user-permissions-context';
import { ArgonosModule } from '../application/modules';
import { useAvailableArgonosModules } from '../application/argonos-modules-registry';
import { CurrentArgonosModuleLayout } from '../application/argonos-current-module';


export interface ArgonosModuleRoutesProps {
    className?: ClassValue;
    argonosModule: ArgonosModule;
}

/**
 * Dynamically render the main routes of the available modules (specified in each module index).
 * Available modules are those enabled in config and those for which the user has permissions
 */
export function ArgonosModulesRoutes() {
    const { permissions } = useGetMyPermissions();

    const availableArgonosModules = useAvailableArgonosModules(permissions);

    const routes = useMemo(() => {
        const routes: ReactNode[] = chain(availableArgonosModules)
            .filter((module:ArgonosModule) => (module.enabled !== false))
            .map((module: ArgonosModule) => (
                <Route
                    key={module.id}
                    element={<CurrentArgonosModuleLayout module={module} />}
                >
                    {module.getRoutes?.()}
                </Route>
            ))
            .compact()
            .flatten()
            .value();

        return routes;
    }, [availableArgonosModules]);

    return (
        <Routes>
            {routes}
        </Routes>
    );
}
