import { isNumber } from 'lodash';
import { defineMessages, IntlShape } from 'react-intl';
import Debug from 'debug';

import { ExplorationId } from '../model/exploration';
import {
    UniverseEdgeType,
    UniverseId,
    UniversePropertyType,
    UniverseVertexType,
    UniverseVertexTypeName,
} from '../model/universe';
import {
    computeBriefURL,
    computeChartURL,
    computeDashboardURL,
    computeExplorationURL,
    computeFolderURL,
    computeSearchURL,
    computeVertexFormURL,
} from '../utils/compute-url';
import {
    BRIEF_VIEW_DND_TYPE,
    BriefViewDndDataTransfer,
    CHART_DND_TYPE,
    ChartDndDataTransfer,
    DASHBOARD_DND_TYPE,
    DashboardDndDataTransfer,
    EDGE_TYPE_DND_TYPE,
    EdgeTypeDndDataTransfer,
    EXPLORATION_DND_TYPE,
    ExplorationDndDataTransfer,
    RESOURCE_DND_TYPE,
    ResourceDndDataTransfer,
    SEARCH_DND_TYPE,
    SearchDndDataTransfer,
    UNIVERSE_PROPERTY_TYPE_DND_TYPE,
    UniversePropertyTypeDndDataTransfer,
    VERTEX_TYPE_DND_TYPE,
    VertexTypeDndDataTransfer,
    VERTICES_DND_TYPE,
    VerticesDndDataTransfer,
} from '../model/exploration-dnd-types';
import { getVertexIds } from 'src/exploration/utils/constraints';
import { CaseId } from '../../model/folder-case-piece';
import { fillUrlInDataTransfer } from '../../components/common/data-transfer';
import { Filter, FilterOperation } from '../model/filter';
import { FOLDER_DND_TYPE, FolderDndDataTransfer } from 'src/model/dnd-types';
import { ScreenshotImageMetadata } from '../model/resource';
import { ResourceId } from 'src/model/resource';
import { VertexId } from '../model/vertex';


const debug = Debug('exploration:common:DataTransfer');


const messages = defineMessages({
    objects: {
        id: 'exploration.common.data-transfer.Object',
        defaultMessage: '{count, plural, =1 {One object} other {{count} objects}}',
    },
    argonosObjects: {
        id: 'exploration.common.data-transfer.ArgonosObjects',
        defaultMessage: 'Objects',
    },
});

// I will deny having made this code
let lastIntl: IntlShape;

export function fillFromFilter(dataTransfer: DataTransfer, intl: IntlShape | undefined, filter: Filter | FilterOperation, universeId: UniverseId, fromExplorationId?: ExplorationId, fromCaseId?: CaseId, count?: number) {
    if (intl) {
        // I will deny having made this code
        lastIntl = intl;
    }

    const verticesParams: VerticesDndDataTransfer = {
        filter,
        universeId,
        fromExplorationId: fromExplorationId,
        fromCaseId,
        count,
    };

    debug('fillFromFilter', 'params=', verticesParams);

    const params = JSON.stringify(verticesParams);

    dataTransfer.setData(VERTICES_DND_TYPE, params);
    dataTransfer.setData('text/plain', 'Argonos objects');

    const vertexIds = getVertexIds(filter);
    if (vertexIds?.length && fromCaseId) {
        // Fill URL
        let mozUrl = '';
        let uriList = '';
        let htmlList = '<ul>\r\n';
        for (let i = 0; i < vertexIds.length; i++) {
            const vertexId = vertexIds[i];

            const vertexUrl = computeVertexFormURL(fromCaseId, universeId, vertexId, true);
            const url = `${document.location.origin}${vertexUrl}`;

            uriList += `${url}\r\n`;
            mozUrl += `${url}\r\nObject\r\n`;
            htmlList += `<li><a href='${url}'>${url}</a></li>\r\n`;
        }
        htmlList += '</ul>';

        if (mozUrl) {
            dataTransfer.setData('text/x-moz-url', mozUrl);
        }
        if (uriList) {
            dataTransfer.setData('text/uri-list', uriList);
        }

        dataTransfer.setData('text/html', htmlList);

        if (vertexIds.length === 1) {
            const uri = uriList.trim();
            dataTransfer.setData('text/plain', uri);
            dataTransfer.setData('text/html', `<a href='${uri}'>${uri}</a>`);
        }
    } else if (isNumber(count) && lastIntl) {
        dataTransfer.setData('text/plain', lastIntl.formatMessage(messages.objects, { count }));
    } else if (lastIntl) {
        dataTransfer.setData('text/plain', lastIntl.formatMessage(messages.argonosObjects));
    }

    return params;
}

export function fillFromExploration(dataTransfer: DataTransfer, explorationParams: ExplorationDndDataTransfer) {
    const params = JSON.stringify(explorationParams);
    dataTransfer.setData(EXPLORATION_DND_TYPE, params);

    const url = `${document.location.origin}${computeExplorationURL(explorationParams.caseId, explorationParams.explorationId, undefined, undefined, true)}`;
    fillUrlInDataTransfer(dataTransfer, url, explorationParams.explorationName || 'Exploration');

    return params;
}

export function fillFromSearch(dataTransfer: DataTransfer, searchParams: SearchDndDataTransfer) {
    const params = JSON.stringify(searchParams);
    dataTransfer.setData(SEARCH_DND_TYPE, params);

    const url = `${document.location.origin}${computeSearchURL(searchParams.caseId, searchParams.explorationId, searchParams.searchMode, true)}`;
    fillUrlInDataTransfer(dataTransfer, url, searchParams.explorationName || 'Search');

    return params;
}

export function fillFromBrief(dataTransfer: DataTransfer, params: BriefViewDndDataTransfer) {
    const jsonParams = JSON.stringify(params);
    dataTransfer.setData(BRIEF_VIEW_DND_TYPE, jsonParams);

    const url = `${document.location.origin}${computeBriefURL(params.caseId, params.briefId, true)}`;
    fillUrlInDataTransfer(dataTransfer, url, params.briefName || 'Brief');

    return jsonParams;
}

export function fillFromFolder(dataTransfer: DataTransfer, params: FolderDndDataTransfer) {
    const jsonParams = JSON.stringify(params);
    dataTransfer.setData(FOLDER_DND_TYPE, jsonParams);

    const url = `${document.location.origin}${computeFolderURL(params.folderId)}`;
    fillUrlInDataTransfer(dataTransfer, url, params.folderName || 'Folder');

    return jsonParams;
}

export function fillFromDashboard(dataTransfer: DataTransfer, params: DashboardDndDataTransfer) {
    const jsonParams = JSON.stringify(params);
    dataTransfer.setData(DASHBOARD_DND_TYPE, jsonParams);

    const url = `${document.location.origin}${computeDashboardURL(params.caseId, params.dashboardId, true)}`;
    fillUrlInDataTransfer(dataTransfer, url, params.dashboardName || 'Dashboard');

    return jsonParams;
}

export function fillFromChart(dataTransfer: DataTransfer, params: ChartDndDataTransfer) {
    const jsonParams = JSON.stringify(params);
    dataTransfer.setData(CHART_DND_TYPE, jsonParams);

    const url = `${document.location.origin}${computeChartURL(params.caseId, params.chartId, undefined, true)}`;
    fillUrlInDataTransfer(dataTransfer, url, params.chartName || 'Chart');

    return jsonParams;
}

export function fillFromVertexType(dataTransfer: DataTransfer, universeId: UniverseId, vertexType: UniverseVertexType, traversalObjectIndex?: number) {
    const vertexTypeParams: VertexTypeDndDataTransfer = {
        universeId,
        vertexType: vertexType.name,
        displayName: vertexType.displayName,
        traversalObjectIndex,
    };
    const params = JSON.stringify(vertexTypeParams);
    dataTransfer.setData(VERTEX_TYPE_DND_TYPE, params);

    /*
    if (fromCaseId && universeId && vertexId) {
        const url = `${document.location.origin}${CASE_URL_SEPARATOR}${encodeURIComponent(fromCaseId)}/u/${encodeURIComponent(universeId)}/v/${encodeURIComponent(vertexId)}`;
        fillUrlInDataTransfer(dataTransfer, url, 'Vertex');
    }
     */

    return params;
}

export function fillFromEdgeType(dataTransfer: DataTransfer, universeId: UniverseId, edgeType: UniverseEdgeType, traversalObjectIndex?: number) {
    const edgeTypeParams: EdgeTypeDndDataTransfer = {
        universeId,
        edgeType: edgeType.name,
        displayName: edgeType.displayName,
        traversalObjectIndex,
    };
    const params = JSON.stringify(edgeTypeParams);
    dataTransfer.setData(EDGE_TYPE_DND_TYPE, params);

    /*
    if (fromCaseId && universeId && vertexId) {
        const url = `${document.location.origin}${CASE_URL_SEPARATOR}${encodeURIComponent(fromCaseId)}/u/${encodeURIComponent(universeId)}/v/${encodeURIComponent(vertexId)}`;
        fillUrlInDataTransfer(dataTransfer, url, 'Vertex');
    }
     */

    return params;
}

export function fillFromUniversePropertyType(dataTransfer: DataTransfer, universeId: UniverseId, type: 'vertex' | 'edge', objectType: UniverseEdgeType | UniverseVertexType, property: UniversePropertyType, traversalObjectIndex?: number) {
    const propertyTypeParams: UniversePropertyTypeDndDataTransfer = {
        universeId,
        objectType: type,
        objectName: objectType.name,
        propertyName: property.name,
        displayName: property.displayName,
        traversalObjectIndex,
    };
    const params = JSON.stringify(propertyTypeParams);
    dataTransfer.setData(UNIVERSE_PROPERTY_TYPE_DND_TYPE, params);

    /*
    if (fromCaseId && universeId && vertexId) {
        const url = `${document.location.origin}${CASE_URL_SEPARATOR}${encodeURIComponent(fromCaseId)}/u/${encodeURIComponent(universeId)}/v/${encodeURIComponent(vertexId)}`;
        fillUrlInDataTransfer(dataTransfer, url, 'Vertex');
    }
     */

    return params;
}

export function fillFromResource(
    dataTransfer: DataTransfer,
    caseId: CaseId,
    resourceId: ResourceId,
    resourceName?: string,
    resourceContentType?: string,
    resourceContentLength?: number,
    isScreenshot?: boolean,
    screenshotImageMetadata?: ScreenshotImageMetadata,
    universeId?: UniverseId,
    vertexId?: VertexId,
): string {
    const screenShotParams: ResourceDndDataTransfer = {
        resourceId,
        resourceName,
        caseId,
        contentType: resourceContentType,
        contentLength: resourceContentLength,

        explorationId: screenshotImageMetadata?.explorationId,
        explorationLayout: screenshotImageMetadata?.explorationLayout,
        screenshotWidth: screenshotImageMetadata?.width,
        screenshotHeight: screenshotImageMetadata?.height,
        isScreenshot,

        universeId: universeId || screenshotImageMetadata?.universeId,
        vertexId,
    };

    const params = JSON.stringify(screenShotParams);

    dataTransfer.setData(RESOURCE_DND_TYPE, params);

    const url = `${document.location.origin}${computeFolderURL(caseId)}/r/${encodeURIComponent(resourceId)}`;
    fillUrlInDataTransfer(dataTransfer, url, resourceName || 'Resource');

    return params;
}
