import { defineMessages } from 'react-intl';

import { SETTINGS_ROOT_URL } from '../../components/containers/routes-urls';
import { NavItem } from '../models/navigation';
import { ArgonosModule } from '../../components/application/modules';

export const EXTENSIONS_PATH = `${SETTINGS_ROOT_URL}/extensions`;
export const EXTENSIONS_ROUTE = '/settings/extensions/:extensionsScope';

const messages = defineMessages({
    extensions: {
        id: 'settings.users.menu.extensions',
        defaultMessage: 'Extensions',
    },
});

export function extensionsNavItems(argonosModule?: ArgonosModule) {
    if (!argonosModule) {
        return [];
    }

    const navItem: NavItem = {
        path: `/settings/extensions/${encodeURIComponent(argonosModule.id)}`,
        label: messages.extensions,
        icon: 'icon-file-table-box-outline',
        iconSize: 20,
        children: [],
    };

    return [navItem];
}
