import { useCallback, useEffect, useState } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';

import {
    ArgButton,
    ArgIcon,
    ArgMessageRenderer,
    ArgMessageValues,
    ArgTooltip2,
    BasicTag,
    ClassValue,
    renderText,
    useClassNames,
} from 'src/components/basic';
import { EmptyPane } from 'src/components/common/panes/empty-pane';

import './properties-panel.less';

const CLASSNAME = 'settings-properties-panel';
const messages = defineMessages({
    propertiesPanelTitle: {
        id: 'settings.retention-rules.properties-panel.PropertiesPanelTitle',
        defaultMessage: 'Informations',
    },
    propertiesSubSectionTitle: {
        id: 'settings.retention-rules.properties-panel.PropertiesSubSectionTitle',
        defaultMessage: 'Properties',
    },
    emptyPanelMessage: {
        id: 'settings.retention-rules.properties-panel.EmptyPanelMessage',
        defaultMessage: 'No auto deletion rules selected',
    },
    noPropertiesMessage: {
        id: 'settings.retention-rules.properties-panel.NoPropertiesMessage',
        defaultMessage: 'No properties',
    },
    autoDeletionLabel: {
        id: 'settings.retention-rules.properties-panel.AutoDeletionLabel',
        defaultMessage: 'Auto deletion',
    },
});
export interface SelectedRetentionPolicyObject {
    label: string | undefined;
    icon: string | undefined;
    iconColor: string;
    backgroundColor: string | undefined;
    retentionPolicyLabel: { message: MessageDescriptor; values?: ArgMessageValues };
}
export interface SelectedRetentionPolicyProperty {
    label: string | undefined;
    icon: string;
    retentionPolicyLabel: { message: MessageDescriptor; values?: ArgMessageValues };
}
export interface SelectedRetentionPolicy {
    object: SelectedRetentionPolicyObject;
    properties: SelectedRetentionPolicyProperty[];
}
export interface PropertiesPanelProps {
    className?: ClassValue;
    selectedRetentionPolicies: SelectedRetentionPolicy[];
}

export function PropertiesPanel(props: PropertiesPanelProps) {
    const { className, selectedRetentionPolicies } = props;
    const classNames = useClassNames(CLASSNAME);
    const [currentPage, setCurrentPage] = useState(1);
    const selectedRetentionPoliciesCount = selectedRetentionPolicies.length;

    useEffect(() => {
        if (selectedRetentionPoliciesCount === 0) {
            return;
        }
        setCurrentPage(prev => {
            return prev > selectedRetentionPoliciesCount ? selectedRetentionPoliciesCount : prev;
        });
    }, [selectedRetentionPoliciesCount]);

    const currentRetentionPolicy = selectedRetentionPolicies[currentPage - 1];

    if (selectedRetentionPoliciesCount === 0 || !currentRetentionPolicy) {
        return (
            <EmptyPane
                key='no-selected-rules'
                icon='icon-trash-open'
                message={messages.emptyPanelMessage}
                size='medium'
                className={classNames('&-empty-pane')}
            />
        );
    }

    const currentRetentionPolicyProperties = currentRetentionPolicy.properties;
    const currentRetentionPolicyPropertiesCount = currentRetentionPolicyProperties.length;

    const {
        label,
        icon,
        iconColor,
        backgroundColor,
        retentionPolicyLabel: {
            message: retentionPolicyLabelMessage,
            values: retentionPolicyLabelValues,
        },
    } = currentRetentionPolicy.object;

    return (
        <div className={classNames('&', className)}>
            <ArgMessageRenderer message={messages.propertiesPanelTitle} size='large' className={classNames('&-header')} />
            <div className={classNames('&-object-retention-rule')}>
                <div className={classNames('&-object-retention-rule-row-1')}>
                    <BasicTag
                        size='large'
                        label={label}
                        icon={icon}
                        iconColor={iconColor}
                        backgroundColor={backgroundColor}
                        tooltip={label}
                    />

                    {selectedRetentionPoliciesCount > 1 && <Pager currentPage={currentPage} totalPages={selectedRetentionPoliciesCount} onChange={setCurrentPage} />}
                </div>
                <div className={classNames('&-object-retention-rule-row-2')}>
                    <ArgMessageRenderer message={messages.autoDeletionLabel} size='small' />
                    <ArgTooltip2 title={renderText(retentionPolicyLabelMessage, retentionPolicyLabelValues)}>
                        <ArgMessageRenderer
                            message={retentionPolicyLabelMessage}
                            messageValues={retentionPolicyLabelValues}
                            size='small'
                        />
                    </ArgTooltip2>
                </div>
            </div>

            <ArgMessageRenderer message={messages.propertiesSubSectionTitle} size='large' className={classNames('&-object-properties-retention-rules-title')} />
            <div className={classNames('&-object-properties-retention-rules-body')}>
                {currentRetentionPolicyPropertiesCount > 0 && (
                    currentRetentionPolicyProperties.map(({ label, icon, retentionPolicyLabel }) => (
                        <div key={label} className={classNames('&-object-properties-retention-rules-body-row')}>
                            <ArgTooltip2 title={label}>
                                <div className={classNames('&-object-properties-retention-rules-body-row-property-name')}>
                                    <ArgIcon name={icon} />
                                    {label}
                                </div>
                            </ArgTooltip2>
                            <ArgTooltip2
                                title={retentionPolicyLabel.message}
                                messageValues={retentionPolicyLabel.values}
                            >
                                <div>
                                    <ArgMessageRenderer
                                        size='small'
                                        message={retentionPolicyLabel.message}
                                        messageValues={retentionPolicyLabel.values}
                                        className={classNames('&-object-properties-retention-rules-body-row-property-rule')}
                                    />
                                </div>
                            </ArgTooltip2>
                        </div>
                    ))
                )}
                {
                    currentRetentionPolicyPropertiesCount === 0 && (
                        <EmptyPane
                            key='no-properties'
                            icon='icon-info'
                            message={messages.noPropertiesMessage}
                            size='small'
                        />
                    )
                }
            </div>
        </div>
    );
}

interface PagerProps {
    currentPage: number;
    totalPages: number;
    onChange: React.Dispatch<React.SetStateAction<number>>;
}
function Pager(props: PagerProps) {
    const { currentPage, totalPages, onChange } = props;
    const classNames = useClassNames(`${CLASSNAME}-pager`);

    const hanlePreviousButtonClick = useCallback(() => {
        onChange((prevPage) => {
            if (prevPage === 1) {
                return totalPages;
            }

            return prevPage - 1;
        });
    }, [onChange, totalPages]);

    const hanleNextButtonClick = useCallback(() => {
        onChange((prevPage) => {
            if (prevPage === totalPages) {
                return 1;
            }

            return prevPage + 1;
        });
    }, [onChange, totalPages]);


    return (
        <div className={classNames('&')}>
            <ArgButton
                type='ghost'
                size='small'
                icon='icon-previous'
                onClick={hanlePreviousButtonClick}

            />
            {currentPage}
            {'/'}
            {totalPages}
            <ArgButton
                type='ghost'
                size='small'
                icon='icon-next'
                onClick={hanleNextButtonClick}
            />
        </div>
    );
}
