import React, { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgButton,
    ArgInputSearch,
    SelectionProvider,
    SelectionState,
    useArgModalContext,
    useClassNames,
} from 'src/components/basic';
import { User } from 'src/model/user';
import { UserDataFilter } from '../../providers/users-data-provider';
import { CreateUserModal } from '../create-user-modal/create-user-modal';
import { SETTINGS_TABLE_SELECTION_SOURCE } from '../users-table/users-table';
import { UserProfileField } from '../../../../model/user-metadata';
import { useHasAnyPermissions } from '../../../../contexts/user-permission';
import { SettingsPermissions } from '../../../permissions/permissions';
import { UsersActionsButtonMenu } from '../users-actions-button-menu/users-actions-button-menu';

import './table-toolbar.less';


const messages = defineMessages({
    newUser: {
        id: 'settings.users-page.active.toolbar.new',
        defaultMessage: 'New',
    },
    import: {
        id: 'settings.users-page.toolbar.import',
        defaultMessage: 'Import',
    },
    searchPlaceHolder: {
        id: 'settings.users.active.table.search',
        defaultMessage: 'Search...',
    },
});

interface TableToolbarProps {
    setFilter: React.Dispatch<React.SetStateAction<UserDataFilter>>;
    usersSelectionProvider: SelectionProvider<User, SelectionState>;
    selectedUsers: User[];
    userProfilesFields?: UserProfileField[];
}

export function TableToolbar(props: TableToolbarProps) {
    const {
        usersSelectionProvider,
        setFilter,
        userProfilesFields,
        selectedUsers,
    } = props;

    const classNames = useClassNames('table-toolbar');

    const modalContext = useArgModalContext();
    const canEditUsers = useHasAnyPermissions<SettingsPermissions>('admin.user.edition', 'admin.user.management');

    const handleOpenModal = useCallback(() => {
        modalContext.open('create-user-modal',
            <CreateUserModal
                userProfilesFields={userProfilesFields}
                closeModal={() => modalContext.close('create-user-modal')}
            />,
        );
    }, [modalContext, userProfilesFields]);

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-left')}>
                <ArgInputSearch
                    onInputChange={(search) => {
                        usersSelectionProvider.clear(SETTINGS_TABLE_SELECTION_SOURCE);

                        setFilter((prevState) => ({
                            ...prevState,
                            search,
                        }));
                    }}
                    placeholder={messages.searchPlaceHolder}
                    className={classNames('&-search')}
                />
                <UsersActionsButtonMenu
                    selectedUsers={selectedUsers}
                />
            </div>
            {canEditUsers && (
                <ArgButton
                    size='medium'
                    type='primary'
                    icon='icon-plus'
                    className='toolbar-item'
                    label={messages.newUser}
                    onClick={() => handleOpenModal()}
                />
            )}
            {/* TODO: descoped for MVP
            <CommonActionButton
                selectedUsersId={selectedUsersId}
                setDeleteUsersModalOpen={setDeleteUsersModalOpen}
            />
             <ArgButton
                onClick={() => window.alert('import user(s)')}
                size='medium'
                type='ghost'
                icon='icon-download'
                label={messages.import}
            /> */}
        </div>
    );
}
