import { cloneDeep, set, uniqBy } from 'lodash';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { ArgCombo, ArgIcon, useClassNames } from 'src/components/basic';
import { useOntologyContext, useValuationRuleStateContext } from 'src/settings/universes/common/providers/policy-rules-provider';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';
import { ValuationEffectFilter } from 'src/settings/models/valuation-policy';
import { ControlType, getControlType, getMetaPropertyControlType } from 'src/components/common/controls/controls';
import { UserProfileField } from 'src/model/user-metadata';
import { getPropertyCompatibilityCode, ontologyPropertyToControlProperty } from 'src/settings/universes/utils';

import './valuation-rule-effect-block.less';

const messages = defineMessages({
    select: {
        id: 'settings.valuation-dropdown.select',
        defaultMessage: 'Select',
    },
    enter: {
        id: 'settings.valuation-dropdown.enter',
        defaultMessage: 'Value',
    },
    profileVariable: {
        id: 'settings.valuation-dropdown.profileVariable',
        defaultMessage: 'Profile variable',
    },
    apply: {
        id: 'settings.valuation-dropdown.apply',
        defaultMessage: 'Apply',
    },
});


enum ValuationType {
    Manual = 'manual',
    ProfileVariable = 'profileVariable',
}

interface ValuationItem {
    key: string;
    label: MessageDescriptor;
}

export interface Permission {
    key: string;
    label: ReactNode;
}

const VALUATIONS_ITEMS: ValuationItem[] = [
    {
        key: ValuationType.Manual,
        label: messages.enter,
    },
    {
        key: ValuationType.ProfileVariable,
        label: messages.profileVariable,
    },
];

interface ValuationDropdownProps {
    targetType: 'object' | 'property';
    effectValue: ValuationEffectFilter;
    index: number;
}

export function ValuationDropdown({
    targetType,
    effectValue: effectFilter,
    index,
}: ValuationDropdownProps) {
    const classNames = useClassNames('settings-valuation-rule-effect-block');

    const intl = useIntl();
    const { userProfileFields, ontologySchema } = useOntologyContext();
    const { setRule, editable } = useValuationRuleStateContext();

    const properties = useMemo(() => {
        return uniqBy(
            ontologySchema.objectTypes.flatMap(vertexSchema => vertexSchema.properties),
            property => property.name,
        );
    }, [ontologySchema.objectTypes]);

    const metaProperty = useMemo(() => {
        if (!effectFilter.setMetadata?.name) {
            return undefined;
        }

        return ontologySchema.metaProperties?.find((metaProperty) => metaProperty.name === effectFilter.setMetadata?.name);
    }, [effectFilter, ontologySchema.metaProperties]);

    const controlMetaProperty = useMemo(() => {
        if (!metaProperty) {
            return undefined;
        }

        return ontologyPropertyToControlProperty(metaProperty);
    }, [metaProperty]);

    const property = useMemo(() => {
        if (!effectFilter.setProperty?.name) {
            return undefined;
        }

        return properties?.find((property) => property.name === effectFilter.setProperty?.name);
    }, [effectFilter, properties]);

    const controlProperty = useMemo(() => {
        if (!property) {
            return undefined;
        }

        return ontologyPropertyToControlProperty(property);
    }, [property]);

    const typeCompatibilityCode = useMemo(() => {
        return effectFilter?.setMetadata
            ? getPropertyCompatibilityCode(metaProperty)
            : getPropertyCompatibilityCode(property);
    }, [effectFilter?.setMetadata, metaProperty, property]);

    const validUserFields: UserProfileField[] = useMemo(() => {
        return userProfileFields
            .filter(
                (field) =>
                    field.typeCompatibilityCode === typeCompatibilityCode ||
                    !field.typeCompatibilityCode || !typeCompatibilityCode,
            ).map((field) => ({
                ...field,
                id: `profile:${field.id}`,
            }));
    }, [typeCompatibilityCode, userProfileFields]);

    const currentControlType = useMemo(() => {
        if (targetType === 'property') {
            if (!controlMetaProperty) {
                return undefined;
            }

            return getMetaPropertyControlType(controlMetaProperty);
        }
        if (!controlProperty) {
            return undefined;
        }

        return getControlType(controlProperty);
    }, [controlMetaProperty, controlProperty, targetType]);

    const value = useMemo(() => {
        if (effectFilter.setMetadata?.value !== undefined) {
            return parseValuationEffectValue(effectFilter.setMetadata.value, currentControlType);
        }

        if (effectFilter.setProperty?.value !== undefined) {
            return parseValuationEffectValue(effectFilter.setProperty.value, currentControlType);
        }

        return undefined;
    }, [effectFilter, currentControlType]);

    const userProfile = validUserFields.find((field) => field.id === value);

    const initialValuationType = useMemo(() => {
        if (value === undefined) {
            return undefined;
        }
        if (validUserFields.find((field) => field.id === value)) { // TODO VC check
            return ValuationType.ProfileVariable;
        }

        return ValuationType.Manual;
    }, [validUserFields, value]);

    const [valuationType, setValuationType] = useState<ValuationType | undefined>(initialValuationType);

    const handleEffectValueChange = useCallback((newValue: any, type: 'setProperty' | 'setMetadata') => {
        setRule((currentRule) => {
            const newEffectFilter: ValuationEffectFilter = {
                ...effectFilter,
                [type]: {
                    ...effectFilter[type],
                    value: newValue,
                },
            };


            return set(cloneDeep(currentRule), `Effects[${index}]`, {
                [targetType]: newEffectFilter,
            });
        });
    }, [setRule, effectFilter, index, targetType]);

    const valueItem = VALUATIONS_ITEMS.find((item) => item.key === valuationType);

    const renderEditor = useCallback(() => {
        if (targetType === 'property') {
            if (!controlMetaProperty) {
                return null;
            }
            const controlType = getMetaPropertyControlType(controlMetaProperty);
            const EditorType = controlType?.editorType;
            if (!EditorType) {
                return null;
            }

            return <EditorType
                className={classNames('&-editor')}
                propertyName={controlMetaProperty.name}
                propertyDisplayName={controlMetaProperty.displayName}
                onChange={(newValue) => handleEffectValueChange(newValue, 'setMetadata')}
                pickerOptions={{ fixedValues: controlMetaProperty.fixedValues }}
                value={value}
                cardinality='optional'
            />;
        }
        if (!controlProperty) {
            return null;
        }
        const controlType = getControlType(controlProperty);
        const EditorType = controlType?.editorType;
        if (!EditorType) {
            return null;
        }

        return <EditorType
            className={classNames('&-editor')}
            propertyName={controlProperty.name}
            propertyDisplayName={controlProperty.displayName}
            onChange={(newValue) => handleEffectValueChange(newValue, 'setProperty')}
            pickerOptions={{ fixedValues: controlProperty.fixedValues }}
            value={value}
            cardinality='optional'
        />;
    }, [targetType, controlProperty, classNames, value, controlMetaProperty, handleEffectValueChange]);

    return (
        <>
            <ArgIcon name='icon-arrow-right' className={classNames('&-arrow-icon')} />
            <div className={classNames('&-sentence-text')}><FormattedMessage {...messages.apply} /></div>
            {editable ? (
                <>
                    <ArgCombo<ValuationItem>
                        className={classNames('&-universe-items-filters')}
                        hideTags={true}
                        placeholder={messages.select}
                        items={VALUATIONS_ITEMS}
                        getItemKey='key'
                        getItemLabel='label'
                        value={valueItem}
                        size='small'
                        popoverClassName='arg-input-popover-no-max-width'
                        onChange={(value) => {
                            setValuationType(value.key);
                        }}
                    />
                    {valuationType === ValuationType.ProfileVariable && (
                        <ArgCombo<UserProfileField>
                            className={classNames('&-universe-items-filters')}
                            hideTags={true}
                            placeholder={messages.select}
                            items={validUserFields}
                            getItemKey='id'
                            getItemLabel='displayName'
                            value={userProfile}
                            size='small'
                            popoverClassName='arg-input-popover-no-max-width'
                            onChange={(value: UserProfileField) => {
                                setRule((currentRule) => {
                                    const newEffectFilter: ValuationEffectFilter = targetType === 'object' ? {
                                        ...effectFilter,
                                        setProperty: {
                                            ...effectFilter.setProperty,
                                            value: value.id,
                                        },
                                    } : {
                                        ...effectFilter,
                                        setMetadata: {
                                            ...effectFilter.setMetadata,
                                            value: value.id,
                                        },
                                    };


                                    return set(cloneDeep(currentRule), `Effects[${index}]`, {
                                        [targetType]: newEffectFilter,
                                    });
                                });
                            }}
                        />
                    )}
                    {valuationType === ValuationType.Manual && renderEditor()}
                </>
            ) : (
                <>
                    <ReadOnlyFilterInput
                        className={classNames('&-universe-items-filters')}
                        value={valueItem?.label}
                    />
                    <ReadOnlyFilterInput
                        className={classNames('&-universe-items-filters')}
                        value={valuationType === ValuationType.ProfileVariable ? userProfile?.displayName : currentControlType?.toText(value, intl)}
                    />
                </>
            )}

        </>
    );
}

// Handle legacy policy where value is not JSON
function parseValuationEffectValue(value: any, controlType: ControlType | undefined): any {
    if (typeof value !== 'string') {
        return value;
    }

    if (value.startsWith('"') && value.endsWith('"')) {
        return JSON.parse(value);
    }

    return controlType?.parseString(value);
}
