import React from 'react';

import { ArgButtonType } from '../types';
import { getDataTestIdFromProps } from '../utils';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgButton, ArgButtonProps } from '../arg-button/arg-button';
import { useFilterDataProps } from '../utils/use-filter-data-props';

import './arg-button-group.less';

export interface ArgButtonGroupProps {
    className?: ClassValue;
    type?: ArgButtonType;
    buttons: ArgButtonProps[];
}

export function ArgButtonGroup(props: ArgButtonGroupProps) {
    const {
        buttons,
        type,
        className,
        ...otherProps
    } = props;

    const classNames = useClassNames('arg-button-group');

    const dataTestId = getDataTestIdFromProps(props);

    const dataProps = useFilterDataProps(otherProps);

    return (
        <div className={classNames('&', className)} {...dataProps}>
            {buttons.map((button, buttonIndex) => (
                <ArgButton
                    {...button}
                    type={type || button.type}
                    data-testid={dataTestId}
                    key={`arg-button-group-${buttonIndex}`}
                    className={classNames('&-item', button.className)}
                />
            ))}
        </div>
    );
}
