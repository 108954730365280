import { Dispatch, SetStateAction } from 'react';
import { defineMessages } from 'react-intl';
import * as uuid from 'uuid';
import { first } from 'lodash';

import {
    ArgButton,
    ArgInputSearch,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames,
    useArgNotifications,
} from 'src/components/basic';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { FormPolicyProcessed, FormRuleProcessed } from 'src/settings/models/form-policy';
import { useOntologyContext } from 'src/settings/universes/common/providers/policy-rules-provider';

import './form-rules-page-toolbar.less';

const messages = defineMessages({
    newRule: {
        id: 'settings.form-policy-rules-page.toolbar.new',
        defaultMessage: 'New',
    },
    import: {
        id: 'settings.form-policy-rules-page.toolbar.import',
        defaultMessage: 'Import',
    },
    searchPlaceholder: {
        id: 'settings.form-policy-rules-page.toolbar.search-bar.placeholder',
        defaultMessage: 'Search...',
    },
    savingPolicyError: {
        id: 'settings.form-policy-rules-page.toolbar.savingPolicyError',
        defaultMessage: 'An error occurred while saving the policy',
    },
});

interface RulesPageToolbarProps {
    search: string | null;
    policy: FormPolicyProcessed;
    setSearch: Dispatch<SetStateAction<string | null>>;
    setPolicy: Dispatch<SetStateAction<FormPolicyProcessed | undefined>>;
    editableRuleIds: Set<string>;
}

export function FormRulesPageToolbar({
    search,
    setSearch,
    setPolicy,
    policy,
    editableRuleIds,
}: RulesPageToolbarProps) {
    const notifications = useArgNotifications();
    const classNames = useClassNames('settings-form-rules-page-toolbar');
    const { ontologySchema } = useOntologyContext();

    const [handleAddRule, addRuleProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const newRule: FormRuleProcessed = {
                InferenceDetection: false,
                Targets: [{
                    object: {
                        _kind: 'Vertex',
                        _type: first(ontologySchema.objectTypes)?.name,
                    },
                }],
                Effects: [],
                id: uuid.v4(),
            };
            const newPolicy = {
                ...policy,
                statement: { ...policy.statement, Actions: [...policy.statement.Actions, newRule] },
            };

            await explorationSettingsConnector.editFormPolicy(policy.id, newPolicy, progressMonitor);

            setPolicy(newPolicy);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.savingPolicyError }, error as Error);
            throw error;
        }
    }, [ontologySchema.objectTypes, policy, setPolicy, notifications]);

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-buttons-group')}>
                <div className={classNames('&-search-bar-container')}>
                    <ArgInputSearch
                        className={classNames('&-search-bar')}
                        placeholder={messages.searchPlaceholder}
                        value={search || undefined}
                        onChange={(value) => setSearch(value)}
                    />
                </div>
            </div>
            <div className={classNames('&-buttons-group')}>
                <ArgButton
                    size='medium'
                    type='primary'
                    icon='icon-plus'
                    label={messages.newRule}
                    onClick={handleAddRule}
                    disabled={addRuleProgressMonitor?.isRunning || editableRuleIds.size > 0}
                    loading={addRuleProgressMonitor?.isRunning}
                />
            </div>
        </div>
    );
}
