import React, { ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { defineMessages, IntlContext, useIntl } from 'react-intl';

import { ArgMessageValues, ArgRenderedText } from '../types';
import { ArgNotificationsContext, DEFAULT_NOTIFICATIONS } from './notifications';
import { renderText } from '../utils/message-descriptor-formatters';
import { getProblemDetailsStatus, getProblemDetailsType, isResponseError } from '../utils/response-error';
import {
    ErrorNotificationFunction,
    MessageNotificationFunction,
    GlobalNotificationDescription,
    NotificationKey, NotificationType,
} from './types';
import { ArgErrorsMap } from './arg-errors-map';
import { handle403Error, handle404Error } from 'src/utils/notify-error';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgNotification, ArgNotificationProps } from './arg-notification';

import './use-arg-notifications.less';

const messages = defineMessages({
    undefinedMessage: {
        id: 'basic.use-arg-notifications.UndefinedMessage',
        defaultMessage: 'Unknown error',
    },
    ignoreAll: {
        id: 'basic.use-arg-notifications.IgnoreAll',
        defaultMessage: 'Ignore all',
    },
});

let notificationId = 0;


export interface ArgNotificationDescription extends Omit<GlobalNotificationDescription, 'duration'> {
    onClick?: () => void;
    buttonLabel?: ArgRenderedText;
    details?: ArgRenderedText;
}

export interface ArgNotificationsType {
    notifInfo: MessageNotificationFunction<ArgNotificationDescription>;
    notifError: ErrorNotificationFunction<ArgNotificationDescription>;
    notifWarning: MessageNotificationFunction<ArgNotificationDescription>;
    notifSuccess: MessageNotificationFunction<ArgNotificationDescription>;
    notifOpen: MessageNotificationFunction<ArgNotificationDescription>;

    notifClose: (key: NotificationKey) => void;
}

export type ArgNotificationParams = Omit<ArgNotificationProps, 'type'>

export type ArgNotificationsReturnType = [ArgNotificationsType, ReactNode]

export function useArgNotifications(errorMap?: ArgErrorsMap): ArgNotificationsReturnType {
    const intl = useIntl();

    /*
    * Use a ref instead of passing intl state as a useMeme dependency to avoid homepage unwanted reload and request duplication
    * It's not required in the dependency cause the intl value is used each time a snack/notif is created
    */
    const intlRef = useRef(intl);
    intlRef.current = intl;

    const classNames = useClassNames('arg-notifications-container');
    const parent = useContext(ArgNotificationsContext);

    const [notifications, setNotifications] = useState<ArgNotificationProps[]>([]);

    const handleClearNotifications = useCallback(() => {
        setNotifications([]);
    }, []);

    const handleCloseNotification = useCallback((notificationKey: NotificationKey) => {
        setNotifications((prev) => {
            return prev.filter(p => p.key !== notificationKey);
        });
    }, []);

    const argNotifications = useMemo<ArgNotificationsType>(() => {
        if (parent && parent !== DEFAULT_NOTIFICATIONS) {
            if (!errorMap) {
                return parent;
            }

            const error = (description?: ArgNotificationDescription, error?: Error, messageValues?: ArgMessageValues): NotificationKey => {
                const problemDetailType = getProblemDetailsType(error);

                if (problemDetailType) {
                    const errorByType = errorMap[problemDetailType];
                    if (errorByType) {
                        description = {
                            ...description,
                            message: errorByType.title,
                            onClick: () => {},
                            description: errorByType.description,
                        };
                    }

                    if (isResponseError(error)) {
                        messageValues = {
                            ...error.json,
                            ...messageValues,
                        };
                    }
                }

                const ret = parent.notifError(description, error, messageValues);

                return ret;
            };

            const ret: ArgNotificationsType = {
                ...parent,
                notifError: error,
            };

            return ret;
        }

        function createParams(notificationDescription: ArgNotificationDescription, messageValues?: ArgMessageValues): ArgNotificationParams {
            const {
                message,
                description,
                icon,
                key,
                className,
                onClick,
                buttonLabel,
                details,
            } = notificationDescription;

            const _key = key || `useNotifications-${notificationId++}`;

            const ret: ArgNotificationParams = {
                key: _key,
                message: (message) ? <IntlContext.Provider value={intlRef.current}>
                    {renderText(message, messageValues)}
                </IntlContext.Provider> : undefined,
                description: (description) ? <IntlContext.Provider value={intlRef.current}>
                    {renderText(description, messageValues)}
                </IntlContext.Provider> : undefined,
                icon,
                messageValues,
                className,
                details,
                buttonLabel,
                onClick,
                onClose: () => handleCloseNotification(_key),
            };

            return ret;
        }

        const ret: ArgNotificationsType = {
            notifInfo(description: ArgNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
                const args = createParams(description, messageValues);
                const notificationKey: NotificationKey = args.key!.toString();

                const newNotification: ArgNotificationProps = { ...args, type: 'info' };

                setNotifications((prev) => {
                    return [...prev, newNotification];
                });

                return notificationKey;
            },
            notifError(description?: ArgNotificationDescription, error?: Error, messageValues?: ArgMessageValues): NotificationKey {
                const errorStatus = getProblemDetailsStatus(error);

                if (!description) {
                    description = {
                        onClick: () => {},
                        message: error?.name || messages.undefinedMessage,
                    };
                }
                const args = createParams(description, messageValues);
                const notificationKey: NotificationKey = args.key!.toString();

                if (errorStatus === 403) {
                    handle403Error();

                    return notificationKey;
                }

                if (errorStatus === 404) {
                    handle404Error();

                    return notificationKey;
                }

                const newNotification: ArgNotificationProps = { ...args, type: 'error' };

                setNotifications((prev) => {
                    return [...prev, newNotification];
                });

                return notificationKey;
            },
            notifWarning(description: ArgNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
                const args = createParams(description, messageValues);
                const notificationKey: NotificationKey = args.key!.toString();

                const newNotification: ArgNotificationProps = { ...args, type: 'warning' };

                setNotifications((prev) => {
                    return [...prev, newNotification];
                });

                return notificationKey;
            },
            notifSuccess(description: ArgNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
                const args = createParams(description, messageValues);
                const notificationKey: NotificationKey = args.key!.toString();

                const newNotification: ArgNotificationProps = ({ ...args, type: 'success' });

                setNotifications((prev) => {
                    return [...prev, newNotification];
                });

                return notificationKey;
            },
            notifOpen(description: ArgNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
                const args = createParams(description, messageValues);
                const notificationKey: NotificationKey = args.key!.toString();

                const newNotification: ArgNotificationProps = { ...args, type: 'info' };

                setNotifications((prev) => {
                    return [...prev, newNotification];
                });

                return notificationKey;
            },

            notifClose(notificationKey: NotificationKey) {
                handleCloseNotification(notificationKey);
            },
        };

        return ret;
    }, [errorMap, handleCloseNotification, parent]);

    const globalType = useMemo<NotificationType | undefined>(() => {
        return undefined;
    }, []);

    let container: ReactNode;

    if (notifications.length > 0) {
        const cls: ClassValue = {
            error: globalType === 'error',
            warning: globalType === 'warning',
            success: globalType === 'success',
            info: globalType === 'info',
        };

        container = (
            <div className={classNames('&', cls)}>
                <div className={classNames('&-header')}>
                    <span className={classNames('&-header-ignore-all')} onClick={handleClearNotifications}>
                        {renderText(messages.ignoreAll)}
                    </span>
                </div>

                <div className={classNames('&-notifications')}>
                    {notifications.map((notification) => {
                        return <ArgNotification {...notification} key={notification.key} />;
                    })}
                </div>
            </div>
        );
    }

    return [argNotifications, container];
}
