import React from 'react';
import classNames from 'classnames';

import { EditorProps } from '../controls/controls-type';
import { ArgInputText, ClassValue } from '../../basic';
import { usePropertyPicker } from '../controls/use-property-picker';

interface InputTextEditorProps extends EditorProps<any> {
    className?: ClassValue;
    maxLength?: number;
}

type InputCharEditorProps = Omit<InputTextEditorProps, 'maxLength'>

export function InputTextEditor(props: InputTextEditorProps) {
    const {
        className,
        readOnly,
        value,
        onChange,
        autoFocus,
        state,
        propertyDisplayName,
        size = 'medium',
        placeholder,
        clearable,
        dndConfig,
        id,
        maxLength,
        pickerOptions: pickerProps,
        popoverProps,
    } = props;

    const popoverPickerProps = usePropertyPicker(pickerProps, onChange);

    return (
        <ArgInputText
            readOnly={readOnly}
            className={classNames(className)}
            placeholder={placeholder || propertyDisplayName}
            value={value}
            type='ghost'
            autoFocus={autoFocus}
            clearable={!readOnly && clearable}
            onChange={onChange}
            state={state}
            size={size}
            dndConfig={dndConfig}
            id={id}
            maxLength={maxLength}
            {...popoverPickerProps}
            {...popoverProps}
        />
    );
}

export function InputCharEditor(props: InputCharEditorProps) {
    return <InputTextEditor {...props} maxLength={1} />;
}

export function TextToText(value: any): string | undefined {
    if (Array.isArray(value)) {
        return (value as Array<any>)
            .map(_value => TextToText(_value))
            .filter(_value => !!_value)
            .join(', ');
    }
    if (typeof (value) !== 'string') {
        return undefined;
    }

    return value;
}
