import { useState } from 'react';
import { defineMessages } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ArgModalContainer, useClassNames } from 'src/components/basic';
import { PageHeader } from 'src/settings/common-components/page-header';
import { Role, RolesScope } from 'src/settings/models/dtoApi';
import { RoleInformationsPanel } from './role-informations-panel';
import { RolesManager } from './roles-manager';
import { ArgonosModuleId } from '../../../components/application/modules';
import { ArgonosModulesRegistry } from '../../../components/application/argonos-modules-registry';

import './roles-view.less';


const messages = defineMessages({
    title: {
        id: 'settings.roles.title',
        defaultMessage: 'Roles',
    },
});

export enum PageHeadersButtons {
    INFO = 'info',
    HISTORY = 'history',
}

export function RolesView() {
    const { argonosModule: argonosModuleId } = useParams<{ argonosModule: ArgonosModuleId }>();

    // A FAIRE avec les TOOLBARs ! Surtout pas comme ça !
    const [headerButtonSelected, setHeaderButtonSelected] = useState<string>('');
    const [selectedRole, setSelectedRole] = useState<Role>();

    const classNames = useClassNames('settings-roles-view');

    const argonosModule = argonosModuleId && ArgonosModulesRegistry.getInstance().getById(argonosModuleId);

    if (!argonosModule) {
        return <div className={classNames('&-wrapper')}>
            <PageHeader
                title={messages.title}
            />
            <ArgModalContainer>
                Unknown plugin {argonosModuleId}
            </ArgModalContainer>
        </div>;
    }

    let roleScope:RolesScope = argonosModule.scope!;
    if (argonosModule.id === 'chapsVision.Settings') {
        roleScope = 'admin';
    }

    return <div className={classNames('&-wrapper')}>
        <PageHeader
            title={messages.title}
        />
        <ArgModalContainer>
            <div className={classNames('&-container')}>
                <div className={classNames('&-container-body')}>
                    <RolesManager
                        rolesScope={roleScope}
                        selectedRole={selectedRole}
                        setSelectedRole={setSelectedRole}
                        permissionConstraints={argonosModule.permissionConstrains}
                    />
                </div>
                {headerButtonSelected === PageHeadersButtons.INFO && (
                    <div className={classNames('&-aside')}>
                        <RoleInformationsPanel selectedRole={selectedRole} />
                    </div>
                )}
            </div>
        </ArgModalContainer>
    </div>;
}
