import { ProgressMonitor } from '../../components/basic';
import { JsonPatchOperationRequest } from '../../exploration/model/types';
import { ConnectorRequestInit, RangeResponse } from '../../utils/connector';
import { BaseConnector } from '../../utils/connectors/base-connector';
import { ExtensionDTO } from '../models/dtoApi';
import { Extension, ExtensionComponent, ExtensionCreationOptions, ExtensionImportResponseError, ExtensionName } from '../models/extension';
import { mapExtension } from './mappers';
import { ArgonosModule } from '../../components/application/modules';
import { getSettingsApi } from '../../utils/connectors/api-url';
import { Operation } from 'src/utils/json-patch/json-patch-adapter';

export default class ExtensionConnector extends BaseConnector {
    private static instance: ExtensionConnector;

    static getInstance(): ExtensionConnector {
        if (!ExtensionConnector.instance) {
            ExtensionConnector.instance = new ExtensionConnector('extension', getSettingsApi());
        }

        return ExtensionConnector.instance;
    }

    async getExtensions(
        search: string| undefined,
        skip: number,
        top: number,
        sorting: string | undefined,
        sortingDirection: string | undefined,
        argonosModule: ArgonosModule | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<RangeResponse<Extension>> {
        const url = '/extensions';
        const apiUrl = argonosModule?.apiURL;

        const options: ConnectorRequestInit = {
            method: 'GET',
            verifyJSONResponse: true,
            api: apiUrl,
            params: {
                search,
                skip,
                top,
                sorting,
                sortingDirection,
            },
        };

        const response = await this.requestResults<ExtensionDTO>(url, 'moduleExtensions', options, progressMonitor);

        const ret: RangeResponse<Extension> = {
            ...response,
            data: response.data.map(mapExtension),
        };

        return ret;
    }

    async getExtension(extensionName: ExtensionName, withImages: boolean, argonosModule: ArgonosModule | undefined, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = `/extensions/${encodeURIComponent(extensionName)}`;
        const apiUrl = argonosModule?.apiURL;

        const options: ConnectorRequestInit = {
            api: apiUrl,
            params: {
                withImages: withImages,
            },
        };

        const rawExtension: ExtensionDTO = await this.request(url, options, progressMonitor);
        const ret = mapExtension(rawExtension);

        return ret;
    }

    async getExtensionComponents(extensionName: ExtensionName, contentHash?: string, argonosModule?: ArgonosModule, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ExtensionComponent[]> {
        const manifestPath = contentHash
            ? `static/${encodeURIComponent(contentHash)}/manifest.json`
            : 'public/manifest.json';

        const url = `/extensions/${encodeURIComponent(extensionName)}/${manifestPath}`;

        const apiUrl = argonosModule?.apiURL;
        const options: ConnectorRequestInit = {
            api: apiUrl,
        };

        const manifest = await this.request(url, options, progressMonitor);
        const extensionComponents: ExtensionComponent[] = manifest?.components || [];

        return extensionComponents;
    }

    async importExtension(
        file: Blob,
        argonosModule: ArgonosModule | undefined,
        extensionCreationOptions?: ExtensionCreationOptions,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<ExtensionImportResponseError> {
        const url = '/extensions';
        const apiUrl = argonosModule?.apiURL;
        const headers: Record<string, string> = {
            'Content-Length': String(file.size),
        };
        const data = new FormData();
        data.append('formFile', new Blob([file], { type: 'application/zip' }));

        const options: ConnectorRequestInit = {
            method: 'POST',
            headers,
            body: data,
            verifyJSONResponse: true,
            api: apiUrl,
            params: {
                keepstate: !!extensionCreationOptions?.keepState,
                overwrite: !!extensionCreationOptions?.overwrite,
            },
        };

        return await this.request(url, options, progressMonitor);
    }

    async deleteExtension(
        extensionName: ExtensionName,
        argonosModule: ArgonosModule | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/extensions/${encodeURIComponent(extensionName)}`;
        const apiUrl = argonosModule?.apiURL;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
            api: apiUrl,
        };

        await this.request(url, options, progressMonitor);
    }

    async exportExtension(
        extensionName: ExtensionName,
        argonosModule: ArgonosModule | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Blob> {
        const url = `/extensions/${encodeURIComponent(extensionName)}/package`;
        const apiUrl = argonosModule?.apiURL;

        const options: ConnectorRequestInit = {
            api: apiUrl,
        };

        const ret: Blob = await this.request(url, options, progressMonitor);

        return ret;
    }

    async updateState(
        extensionName: ExtensionName,
        enabled: boolean,
        argonosModule: ArgonosModule | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const changes: Operation[] = [{
            op: 'replace',
            path: '/state/enabled',
            value: enabled,
        }];

        const json: JsonPatchOperationRequest = {
            changes,
        };

        const url = `/extensions/${encodeURIComponent(extensionName)}`;
        const apiUrl = argonosModule?.apiURL;

        const options: ConnectorRequestInit = {
            method: 'PATCH',
            api: apiUrl,
            json,
            headers: {
                'Content-Type': 'application/json-patch+json',
            },
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }
}
