import { omit } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgIcon, ThreeDotsLoading, useClassNames, useArgNotifications } from 'src/components/basic';
import { ContextualVariable } from 'src/exploration/model/contextual-variable';
import { ScopeStateContext } from '../../providers/policy-rules-provider';
import { Scope, ScopeProcessed } from '../../../../models/policy';
import { isValidScope } from './utils';
import { UserProfileField } from '../../../../../model/user-metadata';
import { ApplicationConditionExpressionOfLogic } from '../application-condition-expression-of-logic/application-condition-expression-of-logic';

import './application-condition-block.less';

interface ApplicationConditionBlockProps {
    scope: ScopeProcessed;
    index: number;
    scopes: ScopeProcessed[];
    setScopes: Dispatch<SetStateAction<ScopeProcessed[]>>;
    editable: boolean;
    setEditable: (value: boolean) => void;
    onSaveScopes: (scopes: Scope[]) => Promise<void>;
    contextualVariables: ContextualVariable[];
    userProfileFields: UserProfileField[];
    saved: boolean;
    setUnsavedScopeIds: Dispatch<SetStateAction<Set<string>>>;
    search?: string;
}

const messages = defineMessages({
    condition: {
        id: 'settings.application-condition-page.condition',
        defaultMessage: 'Condition',
    },
    logicalExpressions: {
        id: 'settings.application-condition-page.logicalExpressions',
        defaultMessage: 'Expression of logic',
    },
    savingPolicyError: {
        id: 'settings.application-condition-page.savingPolicyError',
        defaultMessage: 'An error occurred while saving the policy',
    },
    deletingPolicyError: {
        id: 'settings.application-condition-page.deletingPolicyError',
        defaultMessage: 'An error occurred while deleting the policy',
    },
    invalidRuleTitle: {
        id: 'settings.application-condition-page.invalidRuleTitle',
        defaultMessage: 'Error in the logical expression',
    },
    invalidRuleDescription: {
        id: 'settings.application-condition-page.invalidRuleDescription',
        defaultMessage: 'Unable to validate the rule',
    },
});

export function ApplicationConditionBlock(props: ApplicationConditionBlockProps) {
    const {
        scope,
        index,
        scopes,
        setScopes,
        onSaveScopes,
        editable,
        setEditable,
        contextualVariables,
        userProfileFields,
        saved,
        setUnsavedScopeIds,
        search,
    } = props;

    const intl = useIntl();
    const notifications = useArgNotifications();
    const classNames = useClassNames('application-condition-block');
    const [expanded, setExpanded] = useState(true);
    const [scopeState, setScopeState] = useState<ScopeProcessed>(scope);
    const [loading, setLoading] = useState(false);

    const saveScope = useCallback(async () => {
        const canBeSaved = isValidScope(scopeState);
        if (!canBeSaved) {
            notifications.snackError({
                message: messages.invalidRuleTitle,
                description: messages.invalidRuleDescription,
            });

            return;
        }

        try {
            setLoading(true);
            const newProcessedScopes = scopes.map((scope, i) => {
                const newScope = i === index ? scopeState : scope;

                return newScope;
            });
            const newScopes = newProcessedScopes.map((scope, i) => {
                return omit(scope, ['id']) as Scope;
            });
            await onSaveScopes(newScopes);
            setScopes(newProcessedScopes);
            setUnsavedScopeIds((currentScopeIds) => {
                const newSet = new Set(currentScopeIds);
                newSet.delete(scope.id);

                return newSet;
            });
            setLoading(false);
            setEditable(false);
        } catch (error) {
            notifications.snackError({ message: messages.savingPolicyError }, error as Error);
            setLoading(false);
        }
    }, [index, notifications, onSaveScopes, scope.id, scopeState, scopes, setEditable, setScopes, setUnsavedScopeIds]);

    const resetScope = useCallback(() => {
        setScopeState(scope);
        setEditable(false);
    }, [scope, setEditable]);

    const deleteScope = useCallback(async () => {
        const newProcessedScopes = scopes
            .filter((existingScope) => existingScope.id !== scope.id);
        const newScopes = newProcessedScopes.map((scope) => omit(scope, ['id']) as Scope);

        if (saved) {
            try {
                setLoading(true);
                await onSaveScopes(newScopes);
                setScopes(newProcessedScopes);
                setLoading(false);
                setEditable(false);
            } catch (error) {
                notifications.snackError({ message: messages.deletingPolicyError }, error as Error);
                setLoading(false);
            }
        } else {
            setScopes(newProcessedScopes);
            setEditable(false);
        }
    }, [onSaveScopes, saved, scope.id, scopes, setEditable, setScopes]);

    return (
        <ScopeStateContext.Provider
            value={{ scope: scopeState, setScope: setScopeState, editable }}
        >
            <div className={classNames('&-container')}>
                <div className={classNames('&-header')}>
                    <div className={classNames('&-header-left')}>
                        <div
                            className={classNames(
                                '&-condition-title',
                                editable && '&-color-editable',
                            )}
                        >{`${intl.formatMessage(messages.condition)} ${index + 1}`}</div>
                        <div
                            onClick={() => setExpanded(!expanded)}
                            className={classNames('&-icon')}
                        >
                            <ArgIcon
                                name={expanded ? 'icon-cheveron-up' : 'icon-cheveron-down'}
                                size={20}
                            />
                        </div>
                    </div>
                    <div className={classNames('&-header-right')}>
                        {editable && loading && <ThreeDotsLoading />}
                        {editable && !loading && (
                            <>
                                <div className={classNames('&-icon')} onClick={() => saveScope()}>
                                    <ArgIcon name='icon-checkmark1' size={16} />
                                </div>
                                {saved && (
                                    <div
                                        onClick={() => resetScope()}
                                        className={classNames('&-icon')}
                                    >
                                        <ArgIcon name='icon-cross' size={14} />
                                    </div>
                                )}
                                <div className={classNames('&-icon')} onClick={() => deleteScope()}>
                                    <ArgIcon name='icon-trash' size={14} />
                                </div>
                            </>
                        )}
                        {!editable && (
                            <div onClick={() => setEditable(true)} className={classNames('&-icon')}>
                                <ArgIcon name='icon-edit-pencil' size={14} />
                            </div>
                        )}
                    </div>
                </div>
                {expanded && (
                    <div
                        className={classNames(
                            '&-target-effect-container',
                            editable && '&-editable',
                        )}
                    >
                        <div className={classNames('&-target-container', editable && '&-editable')}>
                            <div className={classNames(editable ? '&-title-editable' : '&-title')}>
                                {intl.formatMessage(messages.logicalExpressions)}
                            </div>
                            <ApplicationConditionExpressionOfLogic
                                contextualVariables={contextualVariables}
                                userProfileFields={userProfileFields}
                                search={search}
                            />
                        </div>
                    </div>
                )}
            </div>
        </ScopeStateContext.Provider>
    );
}
