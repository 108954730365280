import { chain, find, forEach, intersectionBy, isDate, isString, keyBy, cloneDeep } from 'lodash';
import Debug from 'debug';

import {
    EditorContent,
    ProcessBody,
    ProcessComponent,
    ProcessComponentId,
    ProcessComponentVisiblePins,
    ProcessConnection,
    ProcessConnectionId,
    ProcessId,
    ProcessStatus,
} from '../model/process';
import {
    Component,
    ComponentLibrary,
    ComponentPin,
    ComponentPinKey,
    ComponentPinType,
    ComponentType,
} from '../model/component';
import { ProcessMonitoring, ProcessMonitoringData } from '../model/process-monitoring';
import { BLACK_BOX_NODE_ID, computeConnectionId, computePinConnectionId } from '../utils/connectors/mapper';
import { Composite } from '../model/composite';
import { PinConnection } from '../actions/delete-pin-connection';
import { getDataPreparationApi } from '../../utils/connectors/api-url';
import { OptionType } from '../model/option';

const debug = Debug('preparation:process:Utils');

const REFERENCE_TABLE_NAME_OPTION_KEY: OptionType = 'ReferencesTableName';

const ENTITY_REFERENCE_TABLE_COMPONENT_KEY = 'EntityReferenceTableModule';
const FILE_REFERENCE_TABLE_COMPONENT_KEY = 'FileReferenceTableModule';

const CONNECTIONS_EMPTY_LIST: ProcessConnection[] = [];
const PINS_EMPTY_LIST: ComponentPin[] = [];

export function getComponent(processBody: ProcessBody | undefined, componentId: ProcessComponentId): ProcessComponent | undefined {
    if (!processBody) {
        return undefined;
    }

    return processBody.definition.components.find((c) => c.uniqueId === componentId);
}

export function getPinDetails(process: ProcessBody, componentLibrary: Readonly<ComponentLibrary> | undefined, componentId: ProcessComponentId, pinKey: ComponentPinKey): [ComponentPin, ComponentPinType] | undefined {
    if (!componentLibrary) {
        return;
    }

    const processComponent = getComponent(process, componentId);

    const component = find(componentLibrary, { key: processComponent?.componentKey });

    let pin: ComponentPin | undefined;

    // Try to find the pin in in pins
    pin = find(component?.inPins, { key: pinKey });

    if (pin) {
        return [pin, ComponentPinType.In];
    }

    // Try to find the pin in out pins
    pin = find(component?.outPins, { key: pinKey });

    if (pin) {
        return [pin, ComponentPinType.Out];
    }

    // Try to find the pin in error pins
    pin = find(component?.errorPins, { key: pinKey });

    if (pin) {
        return [pin, ComponentPinType.Error];
    }
}

export function getPin(process: ProcessBody, componentLibrary: Readonly<ComponentLibrary> | undefined, componentId: ProcessComponentId, pinKey: ComponentPinKey): ComponentPin | undefined {
    return getPinDetails(process, componentLibrary, componentId, pinKey)?.[0];
}

export function getPinType(process: ProcessBody, componentLibrary: Readonly<ComponentLibrary> | undefined, componentId: ProcessComponentId, pinKey: ComponentPinKey): ComponentPinType | undefined {
    return getPinDetails(process, componentLibrary, componentId, pinKey)?.[1];
}

export function getComponents(process: ProcessBody, componentIds: ProcessComponentId[]): ProcessComponent[] {
    return (
        chain(componentIds)
            .map((componentId) => getComponent(process, componentId))
            .compact()
            .value()
    );
}

export function getConnection(processBody: ProcessBody | undefined, connectionId: ProcessConnectionId): ProcessConnection | undefined {
    if (!processBody) {
        return undefined;
    }

    const ret = processBody.definition.connections.find((processConnection) => {
        const uniqueId = computeConnectionId(processConnection);

        return uniqueId === connectionId;
    });

    return ret;
}

export function getPinConnection(processBody: Composite | undefined, connectionId: ProcessConnectionId): PinConnection | undefined {
    if (!processBody) {
        return undefined;
    }

    if (processBody.definition.inPins) {
        for (const pin of processBody.definition.inPins) {
            for (const target of pin.targets) {
                const cnxId = computePinConnectionId(BLACK_BOX_NODE_ID, pin.key, target.componentUniqueId, target.pinKey);

                if (cnxId !== connectionId) {
                    continue;
                }

                const ret: PinConnection = {
                    compositePin: pin,
                    compositePinTarget: target,
                };

                return ret;
            }
        }
    }

    const outputPins = [...(processBody.definition.outPins || []), ...(processBody.definition.errorPins || [])];
    for (const pin of outputPins) {
        for (const target of pin.targets) {
            const cnxId = computePinConnectionId(target.componentUniqueId, target.pinKey, BLACK_BOX_NODE_ID, pin.key);

            if (cnxId !== connectionId) {
                continue;
            }

            const ret: PinConnection = {
                compositePin: pin,
                compositePinTarget: target,
            };

            return ret;
        }
    }

    return undefined;
}

export function getCommonConnections(process: ProcessBody, componentId: ProcessComponentId[]): ProcessConnection[] {
    const fromConnections = getConnectionsFrom(process, componentId);
    const toConnections = getConnectionsTo(process, componentId);

    const ret = intersectionBy(fromConnections, toConnections, (connection) => {
        return computeConnectionId(connection);
    });

    return ret;
}

export function getVisiblePins(editorContent: EditorContent | undefined, id: ProcessComponentId): ProcessComponentVisiblePins | undefined {
    const visiblePins = editorContent?.componentsVisiblePins?.[id];

    return visiblePins;
}

export function getConnectionsFrom(process: ProcessBody, componentId: ProcessComponentId[]): ProcessConnection[] {
    const keys = keyBy(componentId);

    return process.definition.connections.filter((c) => {
        return !!keys[c.from.componentUniqueId];
    }) || CONNECTIONS_EMPTY_LIST;
}

export function getConnectionsTo(process: ProcessBody, componentId: ProcessComponentId[]): ProcessConnection[] {
    const keys = keyBy(componentId);

    return process.definition.connections.filter((c) => {
        return !!keys[c.to.componentUniqueId];
    }) || CONNECTIONS_EMPTY_LIST;
}

export function getConnections(processBody: ProcessBody | undefined, componentId: ProcessComponentId[]): ProcessConnection[] {
    const keys = keyBy(componentId);

    const ret = processBody?.definition.connections.filter((c) => {
        return (
            !!keys[c.to.componentUniqueId] ||
            !!keys[c.from.componentUniqueId]
        );
    });

    return ret || CONNECTIONS_EMPTY_LIST;
}

export function getConnectedPins(process: ProcessBody, componentId: ProcessComponentId): ComponentPinKey[] {
    return (
        chain(getConnections(process, [componentId]))
            .map(({
                from,
                to,
            }) => (from.componentUniqueId === componentId ? from.pinKey : to.pinKey))
            .uniq()
            .value()
    );
}

export function getLibComponent(component: ProcessComponent, library: Readonly<ComponentLibrary>): Component | undefined {
    const libComponent = library.find((lib) => lib.key === component.componentKey);

    return libComponent;
}

export function getLibComponents(components: ProcessComponent[], library: Readonly<ComponentLibrary>): (Component | undefined)[] {
    return components.map(component => library.find(lib => lib.key === component.componentKey));
}

export function getInputPins(component: ProcessComponent, library: Readonly<ComponentLibrary>): ComponentPin[] {
    const libComponent = getLibComponent(component, library);
    if (!libComponent) {
        return PINS_EMPTY_LIST;
    }

    return libComponent.inPins || PINS_EMPTY_LIST;
}

export function getOutputPins(component: ProcessComponent, library: Readonly<ComponentLibrary>): ComponentPin[] {
    const libComponent = getLibComponent(component, library);
    if (!libComponent) {
        return [];
    }

    return libComponent.outPins || [];
}

export function getErrorPins(component: ProcessComponent, library: Readonly<ComponentLibrary>): ComponentPin[] {
    const libComponent = getLibComponent(component, library);
    if (!libComponent) {
        return [];
    }

    return libComponent.errorPins || [];
}

export function isPinConnected(connections: ProcessConnection[], pin: ComponentPin) {
    const ret = !!connections.find((connection) => (
        pin.key === connection.from.pinKey || pin.key === connection.to.pinKey
    ));

    return ret;
}

export function getConnectorsComponents(
    components: ProcessComponent[],
    componentLibrary?: Readonly<ComponentLibrary>,
): ProcessComponent[] {
    if (!componentLibrary) {
        return [];
    }

    const connectorComponents = components.filter((componentId) => {
        const libComponent = getLibComponent(componentId, componentLibrary);

        return libComponent?.type === ComponentType.Connector;
    });

    return connectorComponents;
}

export function normalizeProcessMonitoringMessage(processMonitoring: ProcessMonitoring): ProcessMonitoringData {
    forEach(processMonitoring?.components, (component) => {
        forEach([...component.inputPins, ...component.outputPins], (pin) => {
            if (isString(pin.firstProcessing)) {
                pin.firstProcessing = new Date(pin.firstProcessing);
            }
            if (isString(pin.lastProcessing)) {
                pin.lastProcessing = new Date(pin.lastProcessing);
            }
        });
    });

    const ret = processMonitoring as ProcessMonitoringData;
    ret.statusDate = new Date();

    return ret;
}


export function getProcessActionUrl(processId: ProcessId, status: ProcessStatus): (string | undefined)[] {
    const baseApiUrl = `${getDataPreparationApi()}/processes/${encodeURIComponent(processId)}`;

    switch (status) {
        case ProcessStatus.Initializing:
        case ProcessStatus.Suspending:
            return [undefined, `${baseApiUrl}/stop`];
        case ProcessStatus.Debugging:
        case ProcessStatus.Testing:
        case ProcessStatus.Running:
            return [`${baseApiUrl}/suspend`, `${baseApiUrl}/stop`];
        case ProcessStatus.Suspended:
            return [`${baseApiUrl}/resume`, `${baseApiUrl}/stop`];
        case ProcessStatus.Stopped:
        case ProcessStatus.Stopping:
            return [`${baseApiUrl}/start`, undefined];
        default:
            return [undefined, undefined];
    }
}

export function getReferenceTableName(componentsLibrary: Readonly<ComponentLibrary>, component?: ProcessComponent): string|undefined {
    if (!component) {
        return undefined;
    }

    const componentKey = component.componentKey;

    const componentLib = componentsLibrary.find((c) => c.key === componentKey);
    if (!componentLib) {
        return undefined;
    }

    const option = componentLib.options.find((o) => o.typeInformation.type === REFERENCE_TABLE_NAME_OPTION_KEY);
    if (option) {
        const value = component.options?.[option.key] || '';

        return value;
    }

    if (componentKey === ENTITY_REFERENCE_TABLE_COMPONENT_KEY
        || componentKey === FILE_REFERENCE_TABLE_COMPONENT_KEY) {
        const value = component.options?.TableName || '';

        return value;
    }

    return undefined;
}

export function replaceUndefinedWithNull(obj: any) {
    function _replaceUndefinedWithNull(obj: any) {
        if (Array.isArray(obj)) {
            for (let i = 0; i < obj.length; ++i) {
                const va = obj[i];
                if (va === undefined) {
                    obj[i] = null;
                } else if (va && typeof (va) === 'object' && !isDate(va)) {
                    _replaceUndefinedWithNull(va);
                }
            }

            return obj;
        }

        if (!obj || typeof (obj) !== 'object') {
            return obj;
        }

        for (const key of Object.keys(obj)) {
            const v = obj[key];
            if (v === undefined) {
                obj[key] = null;
            } else if (v && typeof (v) === 'object' && !isDate(v)) {
                _replaceUndefinedWithNull(v);
            }
        }

        return obj;
    }

    const cloned = cloneDeep(obj);
    const result = _replaceUndefinedWithNull(cloned);

    debug('_replaceUndefinedWithNull', 'From', obj, '=>', result);

    return result;
}
