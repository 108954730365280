import { ReactNode, useMemo } from 'react';
import { isFunction } from 'lodash';

import { isToolDisabled } from './tool';
import { walkNode } from './utils';
import { ToolContext, ToolContextId } from './tool-context';
import { useToolContext } from './use-tool-context';
import { useToolNodes } from './use-tool-nodes';

import './arg-tool-page.less';

interface ArgToolPageProps<T> {
    toolContextId: ToolContextId;
    overriddenToolContext?: ToolContext<T>;
    environmentContext: T;
    children?: ReactNode;
}

export function ArgToolPage<T=undefined>(props: ArgToolPageProps<T>) {
    const {
        toolContextId,
        overriddenToolContext,
        environmentContext,
        children,
    } = props;

    const toolContext = useToolContext<T>(toolContextId, overriddenToolContext);

    const [toolTreeNodes] = useToolNodes(toolContext, environmentContext);

    const selectedNode = useMemo(() => {
        const result = walkNode(toolTreeNodes, (node) => {
            if (isToolDisabled(node, environmentContext)) {
                return;
            }

            if (node.type !== 'panel') {
                console.warn('Unsupported type', node.type, 'node=', node, 'in ArgToolPage');

                return;
            }

            if (!isFunction(node.panelRender)) {
                return;
            }

            return node;
        }, environmentContext);

        return result;
    }, [toolTreeNodes, environmentContext]);

    if (!selectedNode) {
        return <>
            {children}
        </>;
    }

    const body = selectedNode.panelRender!(selectedNode, environmentContext);
    if (!body) {
        return <>
            {children}
        </>;
    }

    return <>
        {body}
    </>;
}
