import { defineMessages } from 'react-intl';
import React, { lazy, Suspense } from 'react';
import { RouteProps } from 'react-router';
import { Navigate, Route } from 'react-router-dom';

import { ArgonosModulesRegistry } from '../components/application/argonos-modules-registry';
import { PREPARATION_ROOT_URL } from '../components/containers/routes-urls';
import { Environment } from '../utils/environment';
import { PREPARATION_PERMISSIONS_REQUIRED, PreparationPermissions } from './permissions/permissions';
import { LoadingSuspense } from '../components/containers/loading-suspense';
import { ArgonosModule } from '../components/application/modules';
import { getDataPreparationApi } from '../utils/connectors/api-url';
import { setupSettings } from './settings';
import './knowledge-base/index';
import { UserPermissions } from '../model/user';
import { hasAnyPermissions } from '../contexts/user-permission';
import { PREPARATION_PERMISSIONS_CONSTRAINTS } from './permissions/permissions-constraints';
import { ArgonosModuleRoutesProps } from 'src/components/containers/argonos-modules-routes';

const FORCE_SUSPENSE_LOADING = false;

const LazyPreparationRouter = lazy(() => import('./preparation-router'));

const messages = defineMessages({
    dataPreparationApplication: {
        id: 'common.applications.DataPreparation',
        defaultMessage: 'Data Preparation',
    },
    loadingPreparation: {
        id: 'common.applications.LoadingDataPreparation',
        defaultMessage: 'Loading Data Preparation {threeDotsLoading}',
    },
    loginTitle: {
        id: 'preparation.login.Title',
        defaultMessage: 'Data Preparation Module',
    },
    loginMessage: {
        id: 'preparation.login.Explanation',
        defaultMessage: 'Log into the platform to define how your data will be extracted, transformed and enriched',
    },
});


function PreparationApp(props: ArgonosModuleRoutesProps) {
    const { className } = props;

    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense message={messages.loadingPreparation} />;
    }

    return (
        <Suspense fallback={<LoadingSuspense message={messages.loadingPreparation} />}>
            <LazyPreparationRouter
                className={className}
            />
        </Suspense>
    );
}

const PREPARATION_MODULE: ArgonosModule<PreparationPermissions> = {
    id: 'chapsVision.DataPreparation',
    subType: 'DataPreparation',

    dependencies: [
        'chapsVision.Settings',
        'chapsVision.KnowledgeBase',
        //        'chapsVision.Administration',
    ],

    enabled: Environment.isDataPreparationEnabled(),

    routeURL: PREPARATION_ROOT_URL,
    loginOrder: 10,
    loginTitle: messages.loginTitle,
    loginMessage: messages.loginMessage,

    requiredPermissions: PREPARATION_PERMISSIONS_REQUIRED,
    permissionConstrains: PREPARATION_PERMISSIONS_CONSTRAINTS,

    apiURL: getDataPreparationApi(),

    iconURL: Environment.dataPreparationIcon,
    name: messages.dataPreparationApplication,
    color: '#36646C',
    documentationURL: Environment.docServerUrlDataPrep,

    getRoutes(): React.ReactElement<RouteProps>[] {
        return [
            <Route
                key='preparation'
                path={PREPARATION_ROOT_URL}
                element={<Navigate to='./folders' replace={true} />}
            />,
            <Route
                key='preparation/*'
                path={`${PREPARATION_ROOT_URL}/*`}
                element={(
                    <PreparationApp argonosModule={PREPARATION_MODULE} />
                )}
            />,
        ];
    },

    hasRoles: true,
    hasUserRoles: true,
    hasGroupRoles: true,
    hasUserPermissions: true,
    scope: 'data_preparation',
    hasEnvironments: true,

    startup(permissions: UserPermissions) {
        if (hasAnyPermissions<PreparationPermissions>(permissions,
            'preparation.reference.table.read',
            'preparation.reference.table.list',
        )) {
            ArgonosModulesRegistry.getInstance().enable('chapsVision.KnowledgeBase');
        }
        //if (hasAnyPermissions<PreparationPermissions>(permissions, 'preparation.reference.table.read')) {
        //ArgonosModulesRegistry.getInstance().enable('chapsVision.Settings');
        //}
    },

    hasWebhook: true,
};

ArgonosModulesRegistry.getInstance().register(PREPARATION_MODULE);

setupSettings(PREPARATION_MODULE);
