import { filter, isEmpty, keyBy } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ArgCombo, ArgIcon, ArgInputNumber, useClassNames } from 'src/components/basic';
import { ArgComboLine } from 'src/components/basic/arg-combo/arg-combo-line';
import { ArgListButtonSelector, ListItemsType } from 'src/components/basic/arg-list-button-selector/arg-list-button-selector';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { ButtonWithBadge } from 'src/components/common/graph/customisation/common/button-with-badge';
import { CustomColorAndIconPicker } from 'src/components/common/graph/customisation/common/custom-color-and-icon-picker';
import { DEFAULT_OBJECT_TYPE_COLORS } from 'src/components/common/graph/constants';
import { GRAPH_NODE_MIN_SIZE } from 'src/components/common/graph/constants';
import {
    advancedStyleLabelMessageMapper,
    messages as commonMessages,
} from 'src/components/common/graph/customisation/messages';
import { AdvancedStyleType, LineAnimation } from 'src/components/common/graph/customisation/graph-style';
import { useEdgeStyleCustomisation } from './use-edge-style-customisation';
import { FullOntology, FullOntologyLinkType } from '../../types';
import { AdvancedStyle } from 'src/components/common/graph/customisation/advanced-style';
import { ADVANCED_STYLE_LABELS } from 'src/exploration/reducers/graph-style-customisation-reducer';
import { ontologyPropertyToControlProperty } from 'src/settings/universes/utils';
import { getRulesSetsWithDefaultValue } from './utils';

import 'src/exploration/common/graph-customiser/graph-customiser.less';
import './design-properties-panel.less';

interface EdgePanelProps {
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    edgeSelected?: FullOntologyLinkType;
    setEdgeSelected: Dispatch<SetStateAction<FullOntologyLinkType | undefined>>;
}

const messages = defineMessages({
    styleDefinition: {
        id: 'settings.edge-styling-panel.styleDefinition',
        defaultMessage: 'Style definition',
    },
});

export function EdgePanel(props: EdgePanelProps) {
    const {
        ontology,
        setOntology,
        edgeSelected,
        setEdgeSelected,
    } = props;

    const classNames = useClassNames('graph-customiser');
    const intl = useIntl();

    const graphStyleCustomisation = useEdgeStyleCustomisation(
        ontology,
        setOntology,
        edgeSelected,
        setEdgeSelected,
    );

    const {
        state,
        objectTypeList,
        properties: advancedStylePropertiesName,
        canBeDefinedAsTitleProperties,
        setFillColor,
        setIconAndColorVisibility,
        setAdvancedStyleProperty,
        setAdvancedStyleRangeValue,
        setAdvancedStyleRangeIntervalType,
        setAdvancedStyle,
        addNewAdvancedStyleRule,
        removeAdvancedStyleRule,
        onVertexLabelChange,
        onDisplayPropertyChange,
        isSomeProgressMonitorsRunning,
        onSizeChange,
        toggleGradientInterval,
        setGradientPropertyRangeValue,
        onResetStyles,
        addUndefinedValueStylesRule,
        removeUndefinedValueStylesRule,
        setLineStyle,
        setLineAnimation,
        setAdvancedStyleDiscretePropertyValue,
    } = graphStyleCustomisation;

    const {
        label,
        titleProperty,
        colorAndIconEditorVisible,
        ruleSets,
        userDefinedContent: {
            fillColor,
            strokeColor,
            iconName,
            size,
            lineStyle,
            lineAnimation,
        },
    } = state;

    const colorAndIconEditor = (
        <CustomColorAndIconPicker
            className={classNames('&-color-and-icon-picker')}
            fillColor={fillColor}
            defaultColors={DEFAULT_OBJECT_TYPE_COLORS}
            onFillColorChange={setFillColor}
            onResetStyles={async () => {
                label && (await onResetStyles(label));
            }}
            onPopoverClose={() => setIconAndColorVisibility(false)}
            onlyOneColor='fill'
            asColorPicker={true}
        />
    );

    const linkStyle = () => (
        <div className={classNames('&-main-section-content-line-display')}>
            <div className={classNames('&-main-section-content-line-display-style')}>
                <span className={classNames('&-main-section-content-line-display-style-label')}>
                    <FormattedMessage {...commonMessages.edgeStyle} />
                </span>
                <ArgComboLine value={lineStyle ?? 'solid'} onChange={setLineStyle} />
            </div>

            <div className={classNames('&-main-section-content-line-display-animation')}>
                <span className={classNames('&-main-section-content-line-display-animation-label')}>
                    <FormattedMessage {...commonMessages.edgeAnimation} />
                </span>
                <ArgCombo<string>
                    className={classNames('&-main-section-content-line-display-animation-input')}
                    items={['No Animation', 'Slow', 'Fast']}
                    size='medium'
                    cardinality='optional'
                    clearable={false}
                    value={lineAnimation ?? LineAnimation.NoAnimation}
                    onChange={setLineAnimation}
                    popoverClassName={classNames(
                        '&-main-section-content-line-display-animation-input-popover',
                    )}
                />
            </div>
        </div>
    );

    const linkTypesByName = keyBy(ontology.linkTypes, linkType => linkType.name);
    const linkTypesItems = objectTypeList.map((linkLabel) => {
        const listItem: ListItemsType = {
            key: linkLabel,
            value: linkTypesByName[linkLabel]?.displayName || linkLabel,
        };

        return listItem;
    });

    const properties = useMemo(() => {
        if (!label) {
            return [];
        }

        const element = ontology.linkTypes.find((v) => v.name === label);

        if (!element) {
            console.warn(`${label} is not in ontology schema linkTypes`);

            return [];
        }

        return element.properties.map(ontologyPropertyToControlProperty);
    }, [ontology, label]);

    const propertiesByName = useMemo(() => (
        keyBy(properties, property => property.name)
    ), [properties]);

    const advancedStyleProperties = useMemo(() => {
        return properties.filter((property) => advancedStylePropertiesName.includes(property.name));
    }, [properties, advancedStylePropertiesName]);

    const handleDisplayPropertyChange = useCallback((displayProperty: string | []) => {
        // ArgCombo return an empty array on clear
        const newDisplayProperty = Array.isArray(displayProperty) ? undefined : displayProperty;
        onDisplayPropertyChange(newDisplayProperty);
    }, [onDisplayPropertyChange]);

    if (isSomeProgressMonitorsRunning || !label || isEmpty(objectTypeList)) {
        if (isSomeProgressMonitorsRunning) {
            return (
                <EmptyPane
                    key='loading'
                    className={classNames('&-loading')}
                    message={commonMessages.loadingMessage}
                    backgroundAnimation='wave'
                    icon='icon-dot-arrow-right'
                />
            );
        } else if (!label || isEmpty(objectTypeList)) {
            return (
                <EmptyPane
                    key='empty'
                    className={classNames('&-no-edges')}
                    message={commonMessages.noEdges}
                    icon='icon-dot-arrow-right'
                />
            );
        }
    }

    return (
        <>
            <div className={classNames('&-object-type-container')}>
                {objectTypeList.length > 1 ? (
                    <div className='design-properties-panel-edge-icon'>
                        <ArgIcon name='icon-dot-arrow-right' size={24} />
                        <ArgListButtonSelector
                            className={classNames('&-object-type-selector')}
                            popoverClassName={classNames('&-object-type-selector-popover')}
                            list={linkTypesItems}
                            selectedListItem={label}
                            onChangeSelectedItem={onVertexLabelChange}
                        />
                    </div>
                ) : (
                    <span className={classNames('&-object-type-label')}>{label}</span>
                )}
            </div>
            <div className={classNames('&-main')}>
                <div className={classNames('design-properties-panel-default-properties-text')}>
                    {intl.formatMessage(messages.styleDefinition)}
                </div>
                <div className={classNames('&-main-section-content')}>
                    <div className={classNames('&-main-section-content-style')}>
                        <ButtonWithBadge
                            className={classNames('&-main-section-content-style-object-type')}
                            popover={colorAndIconEditor}
                            popoverVisible={colorAndIconEditorVisible}
                            onPopoverVisibleChange={setIconAndColorVisibility}
                            popoverPlacement='left'
                            popoverClassName={classNames('&-popover')}
                            badgeIconName='icon-pencil'
                            iconName={iconName || 'unknown'}
                            backgroundColor={fillColor}
                            borderColor={(!strokeColor || strokeColor === 'none') ? undefined : strokeColor}
                            iconColor='#FFFFFF'
                        />

                        <div className={classNames('&-main-section-content-style-display-property')}>
                            <span className={classNames('&-main-section-content-style-display-property-label')}>
                                <FormattedMessage {...commonMessages.displayProperty} />
                            </span>
                            <ArgCombo<string>
                                className={classNames('&-main-section-content-style-display-property-combo')}
                                items={canBeDefinedAsTitleProperties}
                                getItemLabel={(propertyName) => propertiesByName[propertyName]?.displayName || propertyName}
                                size='medium'
                                cardinality='optional'
                                value={titleProperty}
                                disabled={canBeDefinedAsTitleProperties.length < 2 && !!titleProperty}
                                onChange={handleDisplayPropertyChange}
                                clearable={true}
                                enableFilter={true}
                                popoverClassName={classNames(
                                    '&-main-section-content-style-display-property-combo-popover',
                                )}
                            />
                        </div>

                        <div className={classNames('&-main-section-content-style-object-type-size')}>
                            <span
                                className={classNames(
                                    '&-main-section-content-style-object-type-size-label',
                                )}
                            >
                                <FormattedMessage {...commonMessages.size} />
                            </span>
                            <ArgInputNumber
                                className={classNames(
                                    '&-main-section-content-style-object-type-size-input',
                                )}
                                value={size}
                                clearable={false}
                                onChange={onSizeChange}
                                displayRightControl={true}
                                step={GRAPH_NODE_MIN_SIZE}
                                min={GRAPH_NODE_MIN_SIZE}
                            />
                        </div>
                    </div>
                    {linkStyle()}
                </div>
            </div>
            <div className={classNames('&-advanced')}>
                {(
                    filter(
                        ADVANCED_STYLE_LABELS,
                        (el) => !['badges', 'icon'].includes(el),
                    ) as string[]
                ).map((advancedStyleLabel) => {
                    const advancedStyleSectionLabel =
                        commonMessages[
                            advancedStyleLabelMessageMapper[advancedStyleLabel as AdvancedStyleType]
                        ];

                    const propertyName = ruleSets[advancedStyleLabel]?.[0]?.property;
                    const rulesSetsWithDefaultValue = getRulesSetsWithDefaultValue(state.userDefinedContent, ruleSets[advancedStyleLabel]);

                    return (
                        <AdvancedStyle
                            key={`$_advanced-${advancedStyleLabel}`}
                            label={advancedStyleSectionLabel}
                            styleType={advancedStyleLabel as AdvancedStyleType}
                            properties={advancedStyleProperties}
                            property={propertiesByName[propertyName]}
                            ruleSets={rulesSetsWithDefaultValue}
                            onAdvancedStylePropertyNameChange={setAdvancedStyleProperty}
                            onAdvancedStyleChange={setAdvancedStyle}
                            onAdvancedStylePropertyValueChange={setAdvancedStyleDiscretePropertyValue}
                            onAdvancedStylePropertyRangeValueChange={setAdvancedStyleRangeValue}
                            onAdvancedStylePropertyRangeIntervalTypeChange={setAdvancedStyleRangeIntervalType}
                            onAddNewStyleRule={addNewAdvancedStyleRule}
                            onRemoveStyleRule={removeAdvancedStyleRule}
                            toggleGradientInterval={toggleGradientInterval}
                            onGradientPropertyRangeValueChange={setGradientPropertyRangeValue}
                            addUndefinedValueStylesRule={addUndefinedValueStylesRule}
                            removeUndefinedValueStylesRule={removeUndefinedValueStylesRule}
                        />
                    );
                })}
            </div>
        </>
    );
}
