import { omit, pick } from 'lodash';
import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { AdvancedStyleType, DiscreteValue, Gradient, Interval, RuleSet } from './graph-style';
import { DiscreteTextControl } from './controls/discrete-text-control';
import { DiscreteNumberControl } from './controls/discrete-number-control';
import { getPropertyInfo, getSpecificProps, PropertyTypes } from './controls/utils';
import { RangeNumberControl } from './controls/range-number-control';
import { RangeDateControl } from './controls/range-date-control';
import { GradientOrInterval, Side } from './advanced-style';
import { BooleanControl } from './controls/boolean-control';
import { isPicklistControl } from '../../controls/controls';
import { ArgPlaceholderText, ProgressMonitor, dayjs } from '../../../basic';
import { Property } from '../../controls/controls-type';
import { GetGradientBounds } from './use-get-property-bounds';

interface Messages {
    gradientLabel: MessageDescriptor;
    intervalLabel: MessageDescriptor;
    startValuePlaceholder: ArgPlaceholderText;
    endValuePlaceholder: ArgPlaceholderText;
    startDatePlaceholder: ArgPlaceholderText;
    endDatePlaceholder: ArgPlaceholderText;
    maxPlaceholder: ArgPlaceholderText;
    minPlaceholder: ArgPlaceholderText;
    inputPlaceholder: ArgPlaceholderText;
    undefinedStyleRulePlaceholder: ArgPlaceholderText;
    errorOverlap: MessageDescriptor;
    comboPlaceholder: ArgPlaceholderText;
}

const messages = defineMessages({
    gradientLabel: {
        id: 'common.graph.customisation.range.control.gradientLabel',
        defaultMessage: 'Gradient',
    },
    intervalLabel: {
        id: 'common.graph.customisation.range.control.intervalLabel',
        defaultMessage: 'Interval',
    },
    startValuePlaceholder: {
        id: 'common.graph.customisation.range.number.control.StartValue',
        defaultMessage: 'Start value',
    },
    endValuePlaceholder: {
        id: 'common.graph.customisation.range.number.control.EndValue',
        defaultMessage: 'End value',
    },
    startDatePlaceholder: {
        id: 'common.graph.customisation.range.date.control.StartDate',
        defaultMessage: 'Start date',
    },
    endDatePlaceholder: {
        id: 'common.graph.customisation.range.date.control.EndDate',
        defaultMessage: 'End date',
    },
    maxPlaceholder: {
        id: 'common.graph.customisation.range.number.control.max.input',
        defaultMessage: 'max',
    },
    minPlaceholder: {
        id: 'common.graph.customisation.range.number.control.min.input',
        defaultMessage: 'min',
    },
    inputPlaceholder: {
        id: 'common.graph.customisation.discrete.control.value',
        defaultMessage: 'Type a value',
    },
    comboPlaceholder: {
        id: 'common.graph.customisation.boolean-control.comboPlaceholder',
        defaultMessage: 'Select a value',
    },
    undefinedStyleRulePlaceholder: {
        id: 'common.graph.customisation.undefined-style-type.control.value',
        defaultMessage: 'Undefined values rule',
    },
    errorOverlap: {
        id: 'common.graph.customisation.range.number.validation.overlap',
        defaultMessage: 'The intervals overlap. You must enter new number.',
    },
});

export type DiscreteControlMessages = Pick<Messages, 'inputPlaceholder' | 'comboPlaceholder' | 'undefinedStyleRulePlaceholder'>;
export type BooleanControlMessages = Pick<Messages, 'comboPlaceholder' | 'undefinedStyleRulePlaceholder'>;
export type RangeNumberControlMessages = Omit<Messages,
    'startDatePlaceholder' | 'endDatePlaceholder' | 'inputPlaceholder'>;
export type RangeDateControlMessages = Omit<Messages,
    'startValuePlaceholder' | 'endValuePlaceholder' | 'inputPlaceholder'>;

const discreteControlMessages = pick(messages, [
    'inputPlaceholder',
    'comboPlaceholder',
    'undefinedStyleRulePlaceholder',
]) as DiscreteControlMessages;
const booleanControlMessages = pick(messages, [
    'comboPlaceholder',
    'undefinedStyleRulePlaceholder',
]) as BooleanControlMessages;
const rangeNumberControlMessages = omit(messages, [
    'startDatePlaceholder',
    'endDatePlaceholder',
    'inputPlaceholder',
]) as RangeNumberControlMessages;
const rangeDateControlMessages = omit(messages, [
    'startValuePlaceholder',
    'endValuePlaceholder',
    'inputPlaceholder',
]) as RangeDateControlMessages;

export interface RangeLabels {
    gradientLabel: MessageDescriptor;
    intervalLabel: MessageDescriptor;
}

interface AdvancedBodyProps {
    ruleSets?: RuleSet[];
    property?: Property;
    styleType: AdvancedStyleType;
    getPossibleValues?: (progressMonitor: ProgressMonitor) => Promise<string[]>;
    getGradientBounds?: GetGradientBounds;
    onAdvancedStyleDiscreteValueChange?: (
        advancedStyleType: AdvancedStyleType,
        value: string | number | boolean | null,
        index: number,
        isBool?: boolean,
        isMultiString?: boolean,
    ) => Promise<void>; //discrete rule value change

    onAdvancedStyleChange?: (
        advancedStyleType: AdvancedStyleType,
        style: Record<string, any>,
        index: number
    ) => Promise<void>; //Style value change (color, size, icon, badges)

    onAdvancedStyleNumberRangeValueChange?: (
        advancedStyleType: AdvancedStyleType,
        side: Side,
        value: number | null,
        index: number
    ) => Promise<void>; //Number range rule value change
    onAdvancedStyleRangeIntervalTypeChange?: (
        advancedStyleType: AdvancedStyleType,
        side: Side,
        index: number
    ) => Promise<void>; //Interval type change (Closed, Open, LeftOpen, RightOpen)
    onAdvancedStyleDateRangeValueChange?: (
        advancedStyleType: AdvancedStyleType,
        side: Side,
        value: number | dayjs.Dayjs | null,
        index: number
    ) => Promise<void>; //Date range rule value change

    onRemoveStyleRule?: (advancedStyleType: AdvancedStyleType, index: number) => Promise<void>;
    toggleGradientInterval: (
        advancedStyleType: AdvancedStyleType,
        gradientOrInterval: GradientOrInterval,
        property: string
    ) => Promise<void>;
    onGradientPropertyRangeValueChange: (
        type: 'property' | 'size',
        side: Side,
        value: number | dayjs.Dayjs | null
    ) => Promise<void>;
}

export function AdvancedBody(props: AdvancedBodyProps) {
    const {
        ruleSets,
        property,
        styleType,

        onAdvancedStyleDiscreteValueChange,
        onAdvancedStyleChange,
        onAdvancedStyleNumberRangeValueChange,
        onAdvancedStyleRangeIntervalTypeChange,
        onAdvancedStyleDateRangeValueChange,

        getGradientBounds,
        getPossibleValues,
        onRemoveStyleRule,
        toggleGradientInterval,
        onGradientPropertyRangeValueChange,
    } = props;

    if (!property || ruleSets === undefined) {
        return null;
    }

    const propertyNature = getPropertyInfo(property);
    const { type: propertyType, isContinuous: isPropertyContinuous } = propertyNature;

    const getDiscreteValue = (condition?: Interval | Gradient | DiscreteValue) => {
        return (condition as DiscreteValue | undefined)?.value;
    };

    const isPickList = !!((isPicklistControl(property) && getPossibleValues) || property.fixedValues);

    switch (propertyType) {
        case PropertyTypes.Bool: {
            return (
                <>
                    {ruleSets?.map((ruleSet, index) => {
                        const isUndefinedRuleSet = ruleSet?.isUndefinedRuleSet;

                        return (
                            <BooleanControl
                                property={property}
                                key={ruleSet.userDefinedContent.id}
                                type={styleType}
                                index={index}
                                fieldValue={getDiscreteValue(ruleSet.condition)}
                                onFieldValueChange={(value: boolean | undefined) => {
                                    const _value = (Array.isArray(value) && value.length === 0) ? null : (value ?? null);
                                    onAdvancedStyleDiscreteValueChange?.(styleType, _value, index);
                                }}
                                onStyleChange={onAdvancedStyleChange}
                                onRemoveComponent={onRemoveStyleRule}
                                canBeRemoved={ruleSets.length > 1 && !isUndefinedRuleSet}
                                isUndefinedStyleRule={isUndefinedRuleSet}
                                messages={booleanControlMessages}
                                {...getSpecificProps(styleType, ruleSet?.userDefinedContent)}
                            />
                        );
                    })}
                </>
            );
        }
        case PropertyTypes.String:
        case PropertyTypes.MultiString: {
            return (
                <>
                    {ruleSets?.map((ruleSet, index) => {
                        const isUndefinedRuleSet = ruleSet?.isUndefinedRuleSet;

                        return (
                            <DiscreteTextControl
                                key={ruleSet.userDefinedContent.id}
                                type={styleType}
                                index={index}
                                fieldValue={getDiscreteValue(ruleSet.condition)}
                                onFieldValueChange={(value: string | null) => {
                                    const _value = value?.trim() === '' ? null : value;
                                    onAdvancedStyleDiscreteValueChange?.(styleType, _value, index, undefined, propertyType === PropertyTypes.MultiString);
                                }}
                                onStyleChange={onAdvancedStyleChange}
                                onRemoveComponent={onRemoveStyleRule}
                                canBeRemoved={ruleSets.length > 1 && !isUndefinedRuleSet}
                                isUndefinedStyleRule={isUndefinedRuleSet}
                                inputDebounce={true}
                                messages={discreteControlMessages}
                                property={property}
                                pickerOptions={{
                                    getPossibleValues,
                                    pickList: isPickList,
                                }}
                                {...getSpecificProps(styleType, ruleSet?.userDefinedContent)}
                            />
                        );
                    })}
                </>
            );
        }

        case PropertyTypes.Number:
            if (isPropertyContinuous) {
                return (
                    <RangeNumberControl
                        ruleSets={ruleSets}
                        type={styleType}
                        property={property}
                        getGradientBounds={getGradientBounds}
                        onStyleChange={onAdvancedStyleChange}
                        onRangeValueChange={onAdvancedStyleNumberRangeValueChange}
                        onRangeIntervalTypeChange={onAdvancedStyleRangeIntervalTypeChange}
                        onRemoveComponent={onRemoveStyleRule}
                        toggleGradientInterval={toggleGradientInterval}
                        onGradientPropertyRangeValueChange={onGradientPropertyRangeValueChange}
                        messages={rangeNumberControlMessages}
                    />
                );
            }

            return (
                <>
                    {ruleSets?.map((ruleSet, index) => {
                        return (
                            <DiscreteNumberControl
                                property={property}
                                key={ruleSet.userDefinedContent.id}
                                type={styleType}
                                index={index}
                                fieldValue={getDiscreteValue(ruleSet.condition)}
                                onFieldValueChange={(value: number | null) => {
                                    onAdvancedStyleDiscreteValueChange?.(styleType, value, index);
                                }}
                                onStyleChange={onAdvancedStyleChange}
                                onRemoveComponent={onRemoveStyleRule}
                                canBeRemoved={ruleSets.length > 1 && !ruleSet?.isUndefinedRuleSet}
                                messages={discreteControlMessages}
                                {...getSpecificProps(styleType, ruleSet?.userDefinedContent)}
                                isUndefinedStyleRule={ruleSet?.isUndefinedRuleSet}
                            />
                        );
                    })}
                </>
            );

        case PropertyTypes.Date:
            if (isPropertyContinuous) {
                return (
                    <RangeDateControl
                        ruleSets={ruleSets}
                        property={property}
                        type={styleType}
                        getGradientBounds={getGradientBounds}
                        onStyleChange={onAdvancedStyleChange}
                        onRangeValueChange={onAdvancedStyleDateRangeValueChange}
                        onRemoveComponent={onRemoveStyleRule}
                        toggleGradientInterval={toggleGradientInterval}
                        onGradientPropertyRangeValueChange={onGradientPropertyRangeValueChange}
                        messages={rangeDateControlMessages}
                    />
                );
            }

            return null;
        case PropertyTypes.Unknown:
        default:
            return null;
    }
}
