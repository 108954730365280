export const INVALID_SESSION = Symbol('InvalidSession');
export const INVALID_RETURN_TYPE = Symbol('InvalidReturnType');

export class ArgonosError extends Error {
    readonly #reason: Error | undefined;

    readonly #code: symbol;

    constructor(message: string, code: symbol, reason?: Error) {
        super(message);
        this.#code = code;
        this.#reason = reason;
    }

    get code(): symbol {
        return this.#code;
    }

    get reason(): Error | undefined {
        return this.#reason;
    }
}

export function isArgonosError(error: any): error is ArgonosError {
    if (!(error instanceof ArgonosError)) {
        return false;
    }

    return true;
}

export function getArgonosErrorCode(error: any): symbol | undefined {
    if (!isArgonosError(error)) {
        return undefined;
    }

    return error.code;
}

export function getArgonosErrorReason(error: any): Error | undefined {
    if (!isArgonosError(error)) {
        return undefined;
    }

    return error.reason;
}
