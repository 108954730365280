import { Location } from 'history';
import { isEmpty, isString } from 'lodash';

import { SearchId } from 'src/exploration/model/search';
import { VertexId } from '../model/vertex';
import { DataViewMode } from '../model/view-mode';
import { CasePieceId } from 'src/model/basic-case-piece';
import { CaseId } from '../../model/folder-case-piece';
import { CasePieceType } from '../../model/case-piece-type';
import { ExplorationId } from '../model/exploration';
import { FormId } from '../model/form';
import { BriefId } from '../model/brief';
import { UniverseId } from '../model/universe';
import { EditorId } from '../features/editors/editor';
import { DashboardId } from '../features/dashboards/dashboards';
import { ChartId } from '../features/charts/charts';
import { FORCE_EDITOR_ID } from '../features/exploration/force-editor-id';
import { EXPLORATION_ROOT_URL } from '../../components/containers/routes-urls';
import { SearchMode, SearchMode2 } from '../features/searches/types';
import { hasMinimalHash, MINIMAL_HASH } from '../../utils/compute-url';
import { FolderId } from '../../model/folder';

export const CASE_URL_SEPARATOR = `${EXPLORATION_ROOT_URL}/folders/`;
export const CASE_URL = `${CASE_URL_SEPARATOR}{caseId}`;

export const ANONYMOUS_CASE_ID = '-';

export const UNKNOWN_UNIVERSE = '-';

export function computeURL(
    currentLocation: Location | undefined,
    caseId: CaseId,
    casePieceId: CasePieceId | undefined,
    casePieceType: CasePieceType | undefined,
    viewMode?: DataViewMode,
    caseUrlFallback = true,
    forceMinimal?: boolean,
    editorId?: EditorId,
    command?: string,
    associationVertexId?: VertexId,
): string {
    const minimal = forceMinimal || (currentLocation && hasMinimalHash(currentLocation));

    const vertexId = viewMode?.form?.vertexId;

    let url: string;
    if (!casePieceId) {
        url = computeFolderURL(caseId);
    } else {
        switch (casePieceType) {
            case CasePieceType.Exploration:
                url = computeExplorationURL(caseId, casePieceId, viewMode, editorId);
                break;

            case CasePieceType.Brief:
                url = computeBriefURL(caseId, casePieceId);
                break;

            case CasePieceType.Dashboard:
                url = computeDashboardURL(caseId, casePieceId);
                break;

            case CasePieceType.Chart:
                url = computeChartURL(caseId, casePieceId, viewMode);
                break;

            case CasePieceType.FastSearch: {
                url = computeSearchURL(caseId, casePieceId, SearchMode.AdvancedSearch);
                break;
            }
            case CasePieceType.AdvancedSearch: {
                url = computeSearchURL(caseId, casePieceId, SearchMode.StructuredSearch);
                break;
            }
            case CasePieceType.MassiveSearch: {
                url = computeSearchURL(caseId, casePieceId, SearchMode.MassiveSearch);
                break;
            }
            case CasePieceType.Search: { // quick view search tab
                url = computeSearchURL(caseId, casePieceId);
                break;
            }
            case CasePieceType.Form: {
                if (!vertexId) {
                    url = `${computeFolderURL(caseId)}/f/${encodeURIComponent(casePieceId)}`;
                } else {
                    url = _computeFormURL(caseId, casePieceId, vertexId);
                }
                break;
            }
            case CasePieceType.Resource: {
                url = computeResourceURL(caseId, casePieceId, undefined, undefined, undefined, associationVertexId);
                break;
            }

            default:
                throw new Error('Unsupported entityType');
        }
    }

    if (minimal) {
        url += `#${MINIMAL_HASH}`;
    }

    return url;
}

export function computeExplorationURL(caseId: CaseId, explorationId: ExplorationId, toViewMode?: DataViewMode, editorId?: EditorId, minimal?: boolean): string {
    let base = `${computeFolderURL(caseId)}/e/${encodeURIComponent(explorationId)}`;
    if (editorId && editorId !== FORCE_EDITOR_ID) {
        base = `${base}/d/${encodeURIComponent(editorId)}`;
    }
    if (toViewMode?.graph) {
        if (!toViewMode.graph.layout) {
            const url = computeHash(
                `${base}/graph`,
                minimal && MINIMAL_HASH,
            );

            return url;
        }

        const url = computeHash(
            `${base}/graph/${encodeURIComponent(toViewMode.graph.layout)}`,
            minimal && MINIMAL_HASH,
        );

        return url;
    }
    if (toViewMode?.map) {
        const url = computeHash(
            `${base}/map${toViewMode.map.heatmap ? '/heatmap' : ''}`,
            minimal && MINIMAL_HASH,
        );

        return url;
    }

    if (toViewMode?.table) {
        const url = computeHash(
            `${base}/table`,
            minimal && MINIMAL_HASH,
        );

        return url;
    }

    if (toViewMode?.list) {
        const url = computeHash(
            `${base}/list`,
            minimal && MINIMAL_HASH,
        );

        return url;
    }

    if (toViewMode?.chronogram) {
        const url = computeHash(
            `${base}/chronogram`,
            minimal && MINIMAL_HASH,
        );

        return url;
    }

    const url = computeHash(
        `${base}`,
        minimal && MINIMAL_HASH,
    );

    return url;
}

export function computeFormURL(currentLocation: Location, caseId: CaseId, formId: FormId, vertexId: VertexId): string {
    const minimal = hasMinimalHash(currentLocation);

    let url = _computeFormURL(caseId, formId, vertexId);

    url = computeHash(url, minimal && MINIMAL_HASH);

    return url;
}

function _computeFormURL(caseId: CaseId, formId: FormId, vertexId: VertexId): string {
    const url = `${computeFolderURL(caseId)}/f/${encodeURIComponent(formId)}/v/${encodeURIComponent(vertexId)}`;

    return url;
}

export function computeHash(url: string, ...hashs: (string | false | undefined)[]): string {
    const filtredHash = hashs.filter((h) => (h && isString(h))) as string[];

    if (isEmpty(filtredHash)) {
        return url;
    }

    const ret = `${url}#${filtredHash.map((e) => encodeURIComponent(e)).join(',')}`;

    return ret;
}

export function computeFolderURL(caseId: FolderId | undefined, minimal?: boolean, openPanel?: string): string {
    let url = CASE_URL.replace('{caseId}', caseId ? encodeURIComponent(caseId) : ANONYMOUS_CASE_ID);

    url = computeHash(url, minimal && MINIMAL_HASH, openPanel);

    return url;
}

export function computeBriefURL(caseId: CaseId, briefId: BriefId, minimal?: boolean): string {
    let url = `${computeFolderURL(caseId)}/b/${encodeURIComponent(briefId)}`;

    url = computeHash(url, minimal && MINIMAL_HASH);

    return url;
}

export function computeScreenshotURL(caseId: CaseId, screenshotId: CasePieceId, minimal?: boolean): string {
    let url = `${computeFolderURL(caseId)}/s/${encodeURIComponent(screenshotId)}`;

    url = computeHash(url, minimal && MINIMAL_HASH);

    return url;
}

export function computeResourceURL(caseId: CaseId | undefined, resourceId: CasePieceId, minimal?: boolean, universeId?: UniverseId, vertexId?: VertexId, associationVertexId?: VertexId): string {
    let base = `${computeFolderURL(caseId)}`;
    if (universeId) {
        base = `${base}/u/${encodeURIComponent(universeId)}`;
    }
    if (vertexId) {
        base = `${base}/v/${encodeURIComponent(vertexId)}`;
    }
    if (associationVertexId) {
        base = `${base}/av/${encodeURIComponent(associationVertexId)}`;
    }
    let url = `${base}/r/${encodeURIComponent(resourceId)}`;

    url = computeHash(url, minimal && MINIMAL_HASH);

    return url;
}

export function computeVertexFormURL(caseId: CaseId | undefined, universeId: UniverseId, vertexId: VertexId, minimal?: boolean): string {
    let url = `${computeFolderURL(caseId)}/u/${encodeURIComponent(universeId)}/v/${encodeURIComponent(vertexId)}`;

    url = computeHash(url, minimal && MINIMAL_HASH);

    return url;
}

export function computeChartURL(caseId: CaseId, chartId: ChartId, viewMode?: DataViewMode, minimal?: boolean): string {
    let base = `${computeFolderURL(caseId)}/g/${encodeURIComponent(chartId)}`;

    if (viewMode?.table) {
        base = `${base}/table`;
    } else if (viewMode?.chart) {
        base = `${base}/chart/${encodeURIComponent(viewMode.chart.type)}`;
    }

    const url = computeHash(base, minimal && MINIMAL_HASH);

    return url;
}

export function computeExplorationUrl(currentLocation: Location | undefined,
    caseId: CaseId,
    casePieceId: CasePieceId | undefined,
    casePieceType: CasePieceType | undefined,
    viewMode?: DataViewMode): string | undefined {
    const url = computeURL(currentLocation, caseId, casePieceId, casePieceType, viewMode);

    return url;
}

// export function computeSearchURL(caseId: CaseId, searchId?: CasePieceId): string {
//     let url = `${computeFolderURL(caseId)}/ss`;
//     if (searchId) {
//         url += `/${encodeURIComponent(searchId)}`;
//     }

//     return url;
// }

export function computeSearchURL(caseId: CaseId, searchId: CasePieceId, searchMode?: SearchMode, minimal?: boolean): string {
    let url = `${computeFolderURL(caseId)}/ss/${encodeURIComponent(searchId)}`;
    if (searchMode) {
        url = `${computeFolderURL(caseId)}/ss/${encodeURIComponent(searchId)}/${encodeURIComponent(searchMode)}`;
    }

    url = computeHash(url, minimal && MINIMAL_HASH);

    return url;
}

export function computeSearchURL2(caseId: CaseId, searchId: SearchId | undefined, searchMode: SearchMode2): string {
    if (!searchId) {
        const url = computeFolderURL(caseId);

        return url;
    }
    const url = `${computeFolderURL(caseId)}/ss/${encodeURIComponent(searchId)}/${encodeURIComponent(searchMode)}`;

    return url;
}


export function computeDashboardURL(caseId: CaseId, dashboardId: DashboardId, minimal?: boolean): string {
    let url = `${computeFolderURL(caseId)}/h/${encodeURIComponent(dashboardId)}`;

    url = computeHash(url, minimal && MINIMAL_HASH);

    return url;
}
