import Debug from 'debug';

import { RtBasicState, RtStateMessage } from '../../utils/rt-states/rt-basic-state';
import { WebSocketConnectionChannel } from '../../components/ws/websocket-connector';
import { WatchCommands } from '../../utils/rt-states/folders/folder-basic-state';

const debug = Debug('argonode:exploration:states:RtBasicState');

export abstract class ExplorationBasicState<T extends RtStateMessage> extends RtBasicState<T> {
    constructor(url: string) {
        super(url);
    }

    protected get watchCommands(): WatchCommands | undefined {
        return undefined;
    }

    protected get watchKey(): string | undefined {
        return undefined;
    }

    async connected(channel: WebSocketConnectionChannel<T>): Promise<void> {
        await super.connected(channel);

        const commands = this.watchCommands;
        if (!commands) {
            return;
        }

        if (!channel.link(`${commands.linkName}:${this.watchKey}`)) {
            return;
        }

        await channel.connection.invoke(commands.watch, this.watchKey);
    }

    async disconnecting(channel: WebSocketConnectionChannel<T>): Promise<void> {
        const commands = this.watchCommands;
        if (commands) {
            if (channel.unlink(`${commands.linkName}:${this.watchKey}`)) {
                await channel.connection.invoke(commands.unwatch, this.watchKey);
            }
        }

        await super.disconnecting(channel);
    }
}
