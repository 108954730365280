import { useEffect, useState } from 'react';

import { ProgressMonitor, useProgressMonitor } from '../../../basic';

export interface PropertyBounds<T> {
    leftBound: T | undefined;
    rightBound: T | undefined;
}

export interface UseGetPropertyBoundsReturnType<T> {
    propertyBounds: PropertyBounds<T>;
    progressMonitor?: ProgressMonitor;
}

export type GetGradientBounds = (
    property: string,
    type: 'date' | 'number',
    progressMonitor: ProgressMonitor,
) => Promise<PropertyBounds<Date | number> | undefined>;

export function useGetPropertyBounds<T>(
    property: string,
    type: 'date' | 'number',
    getGradientBounds?: GetGradientBounds,
    callBackWhenNoBoundsFound?: () => void,
): UseGetPropertyBoundsReturnType<T> {
    const [getGradientBoundsProgressMonitor, createGetGradientBoundsProgressMonitor] = useProgressMonitor();
    const [propertyBounds, setPropertyBounds] = useState<PropertyBounds<T>>({
        leftBound: undefined,
        rightBound: undefined,
    });

    useEffect(() => {
        if (!getGradientBounds) {
            return;
        }

        const progressMonitor = createGetGradientBoundsProgressMonitor('Get Gradient Bounds', 1);

        getGradientBounds(property, type, progressMonitor)
            .then((newPropertyBounds) => {
                if (!newPropertyBounds) {
                    callBackWhenNoBoundsFound?.();

                    return;
                }

                setPropertyBounds(newPropertyBounds as PropertyBounds<T>);
            })
            .catch((error) => {
                console.error(error);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property, type, getGradientBounds]);

    return {
        progressMonitor: getGradientBoundsProgressMonitor,
        propertyBounds,
    };
}
