import { ArgumentArray } from 'classnames';

import { PropertiesListPanel } from 'src/settings/universes/ontology/components/feed-sources-modal/properties-list-panel';
import { FullOntologyLinkType, FullOntologyObjectType } from 'src/settings/universes/ontology/types';

const PANEL_ENTRIES = ['object-or-relation-properties'] as const;

interface PanelProps {
    selectedPanel: typeof PANEL_ENTRIES[number];
    classNames: (...args: ArgumentArray) => string;
    vertexOrEdge: FullOntologyObjectType | FullOntologyLinkType;
}

export function Panel(props: PanelProps) {
    const { selectedPanel, classNames, vertexOrEdge } = props;
    let body: JSX.Element | null = null;

    switch (selectedPanel) {
        case 'object-or-relation-properties':
            body = <PropertiesListPanel vertexOrEdge={vertexOrEdge} classNames={classNames} />;
            break;

        default:
            break;
    }

    return (
        <div className={classNames('&-editors-content-panel')}>
            {body}
        </div>
    );
}
