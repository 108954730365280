import { isNumber, sortBy } from 'lodash';

import type { ArgTable4AdditionalRow } from 'src/components/basic';

export interface KeyAndLine {
    key: number;
    row: number;
    style: {
        top: string;
        height: string;
    };
}

export type AdditionalRow<T> = ArgTable4AdditionalRow<T>;

export interface AdditionalRowWithStyle<T> extends AdditionalRow<T> {
      style: {
        top: string;
        height: string;
    };
}

export class ScrollDisplayManager<T> {
    #visibleNodeCount = 0;
    #startNodeIndex = 0;
    #elements: KeyAndLine[] = [];
    #freeElements: KeyAndLine[] = [];
    #rows: Record<number, number> = {};
    #rowHeight: number;
    #rowHeightPx: string;
    #additionalRows: AdditionalRow<T>[] = [];

    constructor(rowHeight: number) {
        this.#rowHeight = rowHeight;
        this.#rowHeightPx = `${rowHeight}px`;
    }

    #updateElementsStyle(additionalRows: AdditionalRow<T>[]) {
        this.#elements.forEach((element) => {
            if (element.row === -1) {
                return;
            }
            const additionalRowsHeight = computeAdditionalRowsTotalHeight(additionalRows, element.row);
            element.style = {
                top: `${element.row * this.#rowHeight + additionalRowsHeight}px`,
                height: this.#rowHeightPx,
            };
        });
    }

    getViewPortContent(startNode: number, visibleNodeCount: number, additionalRows: AdditionalRow<T>[] = []): KeyAndLine[] {
        if (startNode === this.#startNodeIndex && visibleNodeCount === this.#visibleNodeCount && areAdditionalRowsEqualTo(this.#additionalRows, additionalRows, startNode + visibleNodeCount)) {
            return this.#elements;
        }

        //scroll up
        if (startNode < this.#startNodeIndex) {
            for (let i = startNode + visibleNodeCount; i < this.#startNodeIndex + this.#visibleNodeCount; i++) {
                const key = this.#rows[i];
                if (key === undefined) {
                    continue;
                }
                this.#freeElements.unshift(this.#elements[key]);
                this.#elements[key].row = -1;
                delete this.#rows[i];
            }

            if (this.#startNodeIndex > startNode + visibleNodeCount) {
                for (let i = startNode; i < visibleNodeCount + startNode; i++) {
                    let element = this.#freeElements.shift();
                    if (!element) {
                        element = {
                            key: this.#elements.length,
                            row: i,
                            style: { top: 'unset', height: this.#rowHeightPx },
                        };
                        this.#elements.push(element);
                        this.#rows[i] = element.key;

                        continue;
                    }
                    element.row = i;
                    this.#rows[i] = element.key;
                }

                this.#startNodeIndex = startNode;
                this.#visibleNodeCount = visibleNodeCount;

                this.#updateElementsStyle(additionalRows);

                return this.#elements;
            }

            for (let i = startNode; i < this.#startNodeIndex; i++) {
                let element = this.#freeElements.shift();
                if (!element) {
                    element = {
                        key: this.#elements.length,
                        row: i,
                        style: { top: 'unset', height: this.#rowHeightPx },
                    };
                    this.#elements.push(element);
                    this.#rows[i] = element.key;

                    continue;
                }
                element.row = i;
                this.#rows[i] = element.key;
            }

            this.#startNodeIndex = startNode;
            this.#visibleNodeCount = visibleNodeCount;

            this.#updateElementsStyle(additionalRows);

            return this.#elements;
        }
        if (startNode === this.#startNodeIndex && this.#elements.length && visibleNodeCount < this.#visibleNodeCount) {
            const firstEl = startNode + visibleNodeCount;
            const lastEl = startNode + this.#visibleNodeCount;

            for (let i = firstEl; i < lastEl; i++) {
                const key = this.#rows[i];
                if (key === undefined) {
                    continue;
                }
                this.#freeElements.push(this.#elements[key]);
                this.#elements[key].row = -1;
                delete this.#rows[i];
            }

            this.#visibleNodeCount = visibleNodeCount;

            return this.#elements;
        }

        //scroll down
        for (let i = this.#startNodeIndex; i < startNode; i++) {
            const key = this.#rows[i];
            if (key === undefined) {
                continue;
            }
            this.#freeElements.unshift(this.#elements[key]);
            this.#elements[key].row = -1;
            delete this.#rows[i];
        }

        if (startNode > this.#startNodeIndex + this.#visibleNodeCount) {
            for (let i = startNode; i < visibleNodeCount + startNode; i++) {
                let element = this.#freeElements.shift();
                if (!element) {
                    element = {
                        key: this.#elements.length,
                        row: i,
                        style: { top: 'unset', height: this.#rowHeightPx },
                    };
                    this.#elements.push(element);
                    this.#rows[i] = element.key;

                    continue;
                }
                element.row = i;
                this.#rows[i] = element.key;
            }

            this.#startNodeIndex = startNode;
            this.#visibleNodeCount = visibleNodeCount;

            this.#updateElementsStyle(additionalRows);

            return this.#elements;
        }

        for (let i = this.#startNodeIndex + this.#visibleNodeCount; i < startNode + visibleNodeCount; i++) {
            let element = this.#freeElements.shift();
            if (!element) {
                element = {
                    key: this.#elements.length,
                    row: i,
                    style: { top: 'unset', height: this.#rowHeightPx },
                };
                this.#elements.push(element);
                this.#rows[i] = element.key;

                continue;
            }
            element.row = i;
            this.#rows[i] = element.key;
        }

        this.#startNodeIndex = startNode;
        this.#visibleNodeCount = visibleNodeCount;
        this.#additionalRows = additionalRows;

        this.#updateElementsStyle(additionalRows);

        return this.#elements;
    }

    getVisibleAdditionalRows(startNode: number, visibleNodeCount: number, additionalRows: AdditionalRow<T>[]): AdditionalRowWithStyle<T>[] {
        return additionalRows
            .filter((item) => {
                if (item.index + 1 < startNode || item.index > startNode + visibleNodeCount) {
                    return false;
                }

                return true;
            })
            .map((item) => {
                const additionalRowsHeight = computeAdditionalRowsTotalHeight(additionalRows, item);

                return {
                    ...item,
                    style: {
                        top: `${(item.index + 1) * this.#rowHeight + additionalRowsHeight}px`,
                        height: `${item.height}px`,
                    },
                };
            });
    }
}

export function computeAdditionalRowsTotalHeight<T> (additionalRows: AdditionalRow<T>[], beforeItem: ArgTable4AdditionalRow<T> | number) {
    let height = 0;
    const sortedAdditionalRows = sortBy(additionalRows, 'index');
    for (const item of sortedAdditionalRows) {
        if ((isNumber(beforeItem) && item.index >= beforeItem) || (!isNumber(beforeItem) && item.key === beforeItem.key)) {
            break;
        }
        height += item.height;
    }

    return height;
}

export function areAdditionalRowsEqualTo<T>(a: AdditionalRow<T>[], b: AdditionalRow<T>[], toIndex = -1) {
    const filteredA = toIndex > -1 ? a.filter((item) => item.index < toIndex) : a;
    const filteredB = toIndex > -1 ? b.filter((item) => item.index < toIndex) : b;

    if (filteredA.length !== filteredB.length) {
        return false;
    }
    for (let i = 0; i < filteredA.length; i++) {
        if (filteredA[i].index !== filteredB[i].index || filteredA[i].height !== filteredB[i].height || filteredA[i].key !== filteredB[i].key) {
            return false;
        }
    }

    return true;
}
