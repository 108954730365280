import React, { useContext } from 'react';

import { GetOntologySchemaDTO } from 'src/settings/models/dtoApi';
import { Rule, RuleProcessed, ScopeProcessed } from '../../../models/policy';
import { UserProfileField } from '../../../../model/user-metadata';
import { ValuationRule, ValuationRuleProcessed } from 'src/settings/models/valuation-policy';

interface UniverseStateContextType {
    ontologySchema: GetOntologySchemaDTO;
    userProfileFields: UserProfileField[];
}

export const OntologyContext = React.createContext<UniverseStateContextType | undefined>(
    undefined,
);

export const useOntologyContext = (): UniverseStateContextType => {
    const context = useContext(OntologyContext);

    if (!context) {
        throw new Error(
            'useUniverseStateContext hook must be used within a UniverseStateContext.Provider component',
        );
    }

    return context;
};

interface RuleStateContextType<R, RP> {
    rule: R;
    setRule: React.Dispatch<React.SetStateAction<RP>>;
    editable: boolean;
}

const useRuleStateContext = <T>(Context: React.Context<T>) => {
    const context = useContext(Context);

    if (!context) {
        throw new Error(
            'useRuleStateContext hook must be used within a RuleStateContext.Provider component',
        );
    }

    return context;
};

type AccessRuleStateContextType = RuleStateContextType<Rule, RuleProcessed>;
export const AccessRuleStateContext = React.createContext<AccessRuleStateContextType | undefined>(undefined);
export const useAccessRuleStateContext = () => useRuleStateContext(AccessRuleStateContext);

type ValuationRuleStateContextType = RuleStateContextType<ValuationRule, ValuationRuleProcessed>;
export const ValuationRuleStateContext = React.createContext<ValuationRuleStateContextType | undefined>(undefined);
export const useValuationRuleStateContext = () => useRuleStateContext(ValuationRuleStateContext);

interface ScopeStateContextType {
    scope: ScopeProcessed;
    setScope: React.Dispatch<React.SetStateAction<ScopeProcessed>>;
    editable: boolean;
}

export const ScopeStateContext = React.createContext<ScopeStateContextType | undefined>(undefined);

export const useScopeStateContext = (): ScopeStateContextType => {
    const context = useContext(ScopeStateContext);

    if (!context) {
        throw new Error(
            'useScopeStateContext hook must be used within a ScopeStateContext.Provider component',
        );
    }

    return context;
};
