import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ListOntologies, Ontology, UniverseKBFeedingStatus, UniverseKBLastFeedingStatus } from 'src/settings/models/dtoApi';
import { ArgTable2, ClassValue, useClassNames } from 'src/components/basic';
import { UITableOntology, useTableColumns } from './columns';
import { useOntologiesContext } from '../../providers/universes';
import { computeOntologyUrl } from 'src/settings/utils/compute-url';

interface UniverseTableProps {
    search?: string;
    className?: ClassValue;
    universesIdSelected: Set<string>;
    setUniversesIdSelected: Dispatch<SetStateAction<Set<string>>>;
}

export function UniverseTable(props: UniverseTableProps) {
    const { search, className, universesIdSelected, setUniversesIdSelected } = props;
    const { listOntologies } = useOntologiesContext();
    const navigate = useNavigate();
    const classNames = useClassNames('settings-universe-table');

    const uiTableOntologies = useMemo<UITableOntology[]>(() => mapUITableOntologies(listOntologies), [listOntologies]);

    const columns = useTableColumns(uiTableOntologies, universesIdSelected, setUniversesIdSelected, classNames);

    const onTableRowDoubleClick = useCallback((ontology: Ontology) => {
        const url = computeOntologyUrl(ontology.id);
        navigate(url);
    }, [navigate]);

    return (
        <ArgTable2<UITableOntology>
            className={classNames('&', className)}
            headerClassName={classNames('&-header')}
            rowKey={(item) => item.id}
            onRowDoubleClick={onTableRowDoubleClick}
            columns={columns}
            dataSource={uiTableOntologies}
            bordered={true}
            highlightedRowCondition={(policy) => universesIdSelected.has(policy.id)}
            search={search}
        />
    );
}

function mapUITableOntologies(listOntologies: ListOntologies) {
    const { ontologies, kbFeedingDataByOntologyId } = listOntologies;

    const ret = ontologies.map(ontology => {
        const ontologyKbFeedingData = kbFeedingDataByOntologyId?.[ontology.id];
        const lastSynchronizationStatus = ontologyKbFeedingData?.lastSynchronizationStatus;
        const currentSynchronizationStatus = ontologyKbFeedingData?.synchronizationStatus || UniverseKBFeedingStatus.Pending;
        const uiTableOntology: UITableOntology = {
            ...ontology,
            order: ontologyKbFeedingData?.order[0] || 0,
            synchronizationStatus: currentSynchronizationStatus,
            lastSynchronizationStatus: lastSynchronizationStatus,
            lastSynchronizationDate: currentSynchronizationStatus !== UniverseKBFeedingStatus.Running ? ontologyKbFeedingData?.lastSynchronizationDate : undefined,
            lastSynchronizationUpdatedDate: ontologyKbFeedingData?.lastUpdatedDate,
        };

        return uiTableOntology;
    });


    return ret;
}
