import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgRelativeTime, ArgTableColumn3, ArgTooltip2 } from 'src/components/basic';
import { Group } from 'src/model/user';
import { GroupActionsMenu } from './single-group-action-menu';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';

import './groups-table.less';

const messages = defineMessages({
    by: {
        id: 'settings.groups.groups-table-column.creation-date-by',
        defaultMessage: 'By ',
    },
    name: {
        id: 'settings.groups.groups-table-column.name',
        defaultMessage: 'Name',
    },
    description: {
        id: 'settings.groups.groups-table-column.description',
        defaultMessage: 'Description',
    },
    creationDate: {
        id: 'settings.groups.groups-table-column.creation-date',
        defaultMessage: 'Creation date',
    },
});

export const useTableColumns = (): ArgTableColumn3<Group>[] => {
    const intl = useIntl();
    const canEditGroups = useHasPermission<SettingsPermissions>('admin.user.group.edition');

    const cols = useMemo<ArgTableColumn3<Group>[]>(() => {
        const ret: ArgTableColumn3<Group>[] = [];

        ret.push(
            {
                key: 'name',
                sortable: true,
                columnName: 'Name',
                title: messages.name,
                dataIndex: 'name',
                minWidth: 200,
            },
            {
                key: 'description',
                columnName: 'description',
                title: messages.description,
                dataIndex: 'description',
                sortable: true,
                minWidth: 400,
                render: function display(description: string) {
                    return (
                        <ArgTooltip2
                            placement='right'
                            title={description.length > 50 ? description : ''}
                        >
                            <div className='groups-table-description'>{description}</div>
                        </ArgTooltip2>
                    );
                },
            },
            {
                key: 'creationDate',
                sortable: true,
                columnName: 'Creation date',
                title: messages.creationDate,
                dataIndex: 'creationDate',
                minWidth: 400,
                render: function display(creationDate, group) {
                    return group.createdDate ? (
                        <div className='groups-table-last-modified'>
                            <ArgRelativeTime date={group.createdDate} numeric='auto' />
                            <span className='groups-table-last-modified-user'>
                                {intl.formatMessage(messages.by)}
                                {group.createdBy?.displayName}
                            </span>
                        </div>
                    ) : (
                        <div />
                    );
                },
                defaultSortOrder: 'ascend',
                sorter: (a, b) => {
                    if (a.createdDate && b.createdDate) {
                        const first = a.createdDate;
                        const second = b.createdDate;

                        return second.getTime() - first.getTime();
                    }

                    return -1;
                },
            },
        );

        if (canEditGroups) {
            ret.push(
                {
                    key: 'actions',
                    columnName: ' ',
                    title: ' ',
                    dataIndex: 'id',
                    sortable: false,
                    render: function display(id, group) {
                        return <GroupActionsMenu group={group} />;
                    },
                },

            );
        }

        return ret;
    }, [canEditGroups, intl]);

    return cols;
};
