import { useState } from 'react';
import { defineMessages } from 'react-intl';
import { useParams } from 'react-router-dom';

import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import {
    ArgButton,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames,
    useEffectAsync,
    useArgNotifications,
} from 'src/components/basic';
import { FormRulesPageToolbar } from '../components/rules/form-rules-page-toolbar/form-rules-page-toolbar';
import { FormPolicyRuleBlock } from '../components/rules/form-policy-rule-block/form-policy-rule-block';
import { getFormPolicyWithActionsIds } from './utils';
import { FormPolicyId, FormPolicyProcessed } from 'src/settings/models/form-policy';
import { GetOntologySchemaDTO } from 'src/settings/models/dtoApi';
import { UserProfileField } from 'src/model/user-metadata';
import ontologiesConnector from '../../../connectors/ontologies-connector';
import { OntologyContext } from '../../common/providers/policy-rules-provider';

import './form-policy-rules-view.less';


const messages = defineMessages({
    publish: {
        id: 'settings.form-policy-rules.publish-button',
        defaultMessage: 'Publish',
    },
    fetchData: {
        id: 'settings.form-policy-rules.fetching-policy',
        defaultMessage: 'Loading policy',
    },
    fetchDataError: {
        id: 'settings.form-policy-rules.fetching-policy.error',
        defaultMessage: 'An error occurred while fetching the policy',
    },
    publishPolicyError: {
        id: 'settings.form-policy-rules.fetching-policy.publishingPolicy',
        defaultMessage: 'An error occurred while publishing the policy',
    },
    publishPolicySuccess: {
        id: 'settings.form-policy-rules.fetching-policy.publishingPolicy.success',
        defaultMessage: 'Form policy published',
    },
    publishPolicySuccessDescription: {
        id: 'settings.form-policy-rules.fetching-policy.publishingPolicy.success.description',
        defaultMessage: 'The form policy has successfully been published',
    },
});

export function FormPolicyRulesView() {
    const classNames = useClassNames('settings-form-policy-rules-view');
    const [search, setSearch] = useState<string | null>(null);
    const [ontologySchema, setOntologySchema] = useState<GetOntologySchemaDTO>();
    const [policy, setPolicy] = useState<FormPolicyProcessed>();
    const [editableRuleIds, setEditableRuleIds] = useState<Set<string>>(new Set());
    const [userProfileFields, setUserProfileFields] = useState<UserProfileField[]>([]);
    const { policyId, ontologyId } =
        useParams<{ ontologyId: string; policyId: string }>();

    const notifications = useArgNotifications();

    const [fetchPolicyAndUniverseMonitor] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        if (!ontologyId || !policyId) {
            return;
        }

        try {
            const [ontology, policy, profileFields] = await Promise.all([
                ontologiesConnector.getOntologySchema(ontologyId),
                explorationSettingsConnector.getFormPolicy(policyId),
                explorationSettingsConnector.getUserProfileFields(),
            ]);

            setOntologySchema(ontology);
            setPolicy(getFormPolicyWithActionsIds(policy));
            setUserProfileFields(profileFields);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchDataError }, error as Error);
            throw error;
        }
    }, [ontologyId, policyId, notifications], messages.fetchData);


    const unpublishable = policy?.statement.Actions.some((action) =>
        editableRuleIds.has(action.id),
    );

    const editPolicyEditableState = (editable: boolean, id: string) => {
        const newSet = new Set(editableRuleIds);
        if (editable) {
            newSet.add(id);
            setEditableRuleIds(newSet);
        } else {
            newSet.delete(id);
            setEditableRuleIds(newSet);
        }
    };

    const [publishPolicy, publishProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor, id: FormPolicyId) => {
        try {
            await explorationSettingsConnector.publishFormPolicy(id, 'Publish');
            notifications.snackSuccess({
                message: messages.publishPolicySuccess,
                description: messages.publishPolicySuccessDescription,
            });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.publishPolicyError }, error as Error);
            throw error;
        }
    }, [notifications]);

    if (fetchPolicyAndUniverseMonitor?.isRunning || !ontologySchema || !ontologyId || !policy) {
        return (
            <div className='arg-layout'>
                <LoadingPane progressMonitor={fetchPolicyAndUniverseMonitor} />
            </div>
        );
    }

    return (
        <OntologyContext.Provider
            value={{
                ontologySchema: ontologySchema,
                userProfileFields: userProfileFields,
            }}
        >
            <div className={classNames('&')}>
                <ArgButton
                    size='medium'
                    type='primary'
                    className={classNames('&-publish-button')}
                    label={messages.publish}
                    onClick={() => publishPolicy(policy.id)}
                    disabled={unpublishable || publishProgressMonitor?.isRunning}
                    loading={publishProgressMonitor?.isRunning}
                />
                <FormRulesPageToolbar
                    search={search}
                    setSearch={setSearch}
                    policy={policy}
                    setPolicy={setPolicy}
                    editableRuleIds={editableRuleIds}
                />
                <div className={classNames('&-rules-container')}>
                    {policy?.statement.Actions.map((action, idx) => {
                        return (
                            <FormPolicyRuleBlock
                                key={action.id}
                                rule={action}
                                index={idx}
                                setPolicy={setPolicy}
                                policy={policy}
                                editable={editableRuleIds.has(action.id)}
                                setEditable={(editable) =>
                                    editPolicyEditableState(editable, action.id)}
                                userProfileFields={userProfileFields}
                                ontologyId={ontologyId}
                            />
                        );
                    })}
                </div>
            </div>
        </OntologyContext.Provider>
    );
}

