import Debug from 'debug';

import { RtBasicState, RtStateMessage } from '../rt-basic-state';
import { ProcessId } from '../../../preparation/model/process';
import { ProgressMonitor } from '../../../components/basic';
import { WebSocketChannel, WebSocketConnectionChannel } from '../../../components/ws/websocket-connector';
import { WSProgressMonitor } from '../../../components/basic/progress-monitors/ws-progress-monitor';
import { RtMessageEventList } from '../events';
import { RtApi, RtApiSubType } from '../rt-api';

const debug = Debug('argonode:utils:states:ProgressMonitorState');

export const PROGRESS_MONITORS_EVENT_LIST: RtMessageEventList = {
    'ProcessRatioChanged': true,
    'ProcessFinished': true,
    'ProcessFailed': true,
};

type ProgressMonitorMessage = RtStateMessage

export class ProgressMonitorState extends RtBasicState<ProgressMonitorMessage> {
    #api: RtApi;
    #processId: ProcessId;

    constructor(url: string, api: RtApi, processId: ProcessId) {
        super(url);

        if (api.subType !== RtApiSubType.ProgressMonitors) {
            throw new Error(`Invalid subType: ${api.subType}`);
        }

        this.#processId = processId;
        this.#api = api;
    }

    protected getRtApi(): RtApi {
        return this.#api;
    }

    async connected(channel: WebSocketConnectionChannel<ProgressMonitorMessage>): Promise<void> {
        await super.connected(channel);

        await channel.connection.invoke('Watch', this.#processId);
    }

    async disconnecting(channel: WebSocketConnectionChannel<ProgressMonitorMessage>): Promise<void> {
        await channel.connection.invoke('Unwatch', this.#processId);

        await super.disconnecting(channel);
    }

    processMessage = async (channel: WebSocketChannel<ProgressMonitorMessage>, type: string, message: ProgressMonitorMessage): Promise<boolean | undefined> => {
        const { processId, ratio, message: reason, result } = message.messageContent;

        switch (type) {
            case 'ProcessRatioChanged':
                if (processId !== this.#processId) {
                    // Ignore message
                    return true;
                }

                this.emit(type, ratio, reason?.key, reason?.parameter);

                return false;

            case 'ProcessFinished':
                if (processId !== this.#processId) {
                    // Ignore message
                    return true;
                }

                this.emit(type, result);

                return false;

            case 'ProcessFailed':
                if (processId !== this.#processId) {
                    // Ignore message
                    return true;
                }

                this.emit(type, reason);

                return false;
        }
    };

    async waitForResult(progressMonitor: ProgressMonitor): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            let prevCount = 0;

            const processing = (ratio: number, messageKey?: string, parameter?: any) => {
                if (prevCount === undefined) {
                    progressMonitor.beginTask('', 1);
                }
                if (messageKey) {
                    if (progressMonitor instanceof WSProgressMonitor) {
                        progressMonitor.processMessage(messageKey, parameter);
                    }
                }

                if (ratio && ratio > prevCount) {
                    progressMonitor.worked(ratio - prevCount);
                    prevCount = ratio;
                }
            };

            const onFinished = () => {
                if (progressMonitor instanceof WSProgressMonitor) {
                    progressMonitor.onProcessFinished();
                }
            };

            const onFailed = () => {
                if (progressMonitor instanceof WSProgressMonitor) {
                    progressMonitor.onProcessFailed();
                }
            };

            const done = (result: any) => {
                this.off('ProcessRatioChanged', processing);
                this.off('ProcessFinished', done);
                this.off('ProcessFailed', failed);
                onFinished();
                resolve(result);
            };

            const failed = (reason: any) => {
                this.off('ProcessRatioChanged', processing);
                this.off('ProcessFinished', done);
                this.off('ProcessFailed', failed);

                onFailed();

                const error = new Error('Async processus error');
                (error as any).reason = reason;

                reject(error);
            };

            this.on('ProcessRatioChanged', processing);
            this.on('ProcessFinished', done);
            this.on('ProcessFailed', failed);
        });

        return promise;
    }
}
