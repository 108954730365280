import { isArray } from 'lodash';

import { ReferenceTable, ReferenceTableEntityId, ReferenceTableEntry, ReferenceTableId } from './reference-tables';
import { BaseConnector } from '../../../../utils/connectors/base-connector';
import { getDataPreparationApi } from '../../../../utils/connectors/api-url';
import { ProgressMonitor } from '../../../../components/basic';
import { mapReferenceTable, mapReferenceTableEntry } from './mappers';


export interface ReferenceTableEntriesPageResponse {
    entries: ReferenceTableEntry[];

    continuationToken?: string;
}

export class ReferenceTablesConnector extends BaseConnector {
    private static instance: ReferenceTablesConnector;

    static getInstance(): ReferenceTablesConnector {
        if (!ReferenceTablesConnector.instance) {
            ReferenceTablesConnector.instance = new ReferenceTablesConnector('preparation.ReferenceTables', getDataPreparationApi());
        }

        return ReferenceTablesConnector.instance;
    }

    async listTableReferenceEntities(
        tableName: ReferenceTableId,
        pageSize: number,
        continuationToken?: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ):Promise<ReferenceTableEntriesPageResponse> {
        const url = `/reference-tables/${encodeURIComponent(tableName)}`;

        const result = await this.request(url, {
            verifyJSONResponse: true,
            params: {
                continuationToken,
                pageSize,
            },
        }, progressMonitor);

        const ret:ReferenceTableEntriesPageResponse = {
            entries: result.entries.map(mapReferenceTableEntry),
            continuationToken: result.continuationToken,
        };

        return ret;
    }

    async listReferenceTables(progressMonitor: ProgressMonitor): Promise<ReferenceTable[]> {
        const url = '/reference-tables';

        const raw = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const entityTypes = isArray(raw) ? raw : raw.referenceTables;

        const result = entityTypes.map((entityTypeRaw: any) => mapReferenceTable(entityTypeRaw));

        return result;
    }

    async deleteReferenceTable(table: ReferenceTableId, progressMonitor: ProgressMonitor): Promise<void> {
        const url = `/reference-tables/${encodeURIComponent(table)}`;

        await this.request(url, {
            verifyJSONResponse: true,
            method: 'DELETE',
        }, progressMonitor);
    }

    async getReferenceTableEntry(
        referenceTableId: ReferenceTableId,
        referenceTableEntryId: ReferenceTableEntityId,
        progressMonitor: ProgressMonitor,
    ): Promise<ReferenceTableEntry> {
        throw new Error('Not implemented');
    }
}
