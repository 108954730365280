import { filter, isEmpty, keyBy } from 'lodash';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ArgInputNumber, useClassNames } from 'src/components/basic';
import { ArgListButtonSelector, ListItemsType } from 'src/components/basic/arg-list-button-selector/arg-list-button-selector';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { ButtonWithBadge } from 'src/components/common/graph/customisation/common/button-with-badge';
import { CustomColorAndIconPicker } from 'src/components/common/graph/customisation/common/custom-color-and-icon-picker';
import { DEFAULT_OBJECT_TYPE_COLORS } from 'src/components/common/graph/constants';
import { GRAPH_NODE_MIN_SIZE } from 'src/components/common/graph/constants';
import { advancedStyleLabelMessageMapper, messages as commonMessages } from 'src/components/common/graph/customisation/messages';
import { useVertexStyleCustomisation } from './use-vertex-style-customisation';
import { FullOntology, FullOntologyObjectType } from '../../types';
import { ADVANCED_STYLE_LABELS } from 'src/exploration/reducers/graph-style-customisation-reducer';
import { AdvancedStyle } from 'src/components/common/graph/customisation/advanced-style';
import { AdvancedStyleType } from 'src/components/common/graph/customisation/graph-style';
import { ontologyPropertyToControlProperty } from 'src/settings/universes/utils';
import { getRulesSetsWithDefaultValue } from './utils';

import 'src/exploration/common/graph-customiser/graph-customiser.less';
import './design-properties-panel.less';

const messages = defineMessages({
    styleDefinition: {
        id: 'settings.object-styling-panel.styleDefinition',
        defaultMessage: 'Style definition',
    },
    iconColor: {
        id: 'settings.object-styling-panel.IconColor',
        defaultMessage: 'Primary color',
    },
});

interface VertexPanelProps {
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    vertexSelected?: FullOntologyObjectType;
    setVertexSelected: Dispatch<SetStateAction<FullOntologyObjectType | undefined>>;
}

export function VertexPanel(props: VertexPanelProps) {
    const {
        ontology,
        setOntology,
        vertexSelected,
        setVertexSelected,
    } = props;

    const classNames = useClassNames('graph-customiser');

    const graphStyleCustomisation = useVertexStyleCustomisation(
        ontology,
        setOntology,
        vertexSelected,
        setVertexSelected,
    );

    const {
        state,
        objectTypeList,
        properties: advancedStylePropertiesName,
        setFillColor,
        setIconColor,
        setStrokeColor,
        setIcon,
        setIconAndColorVisibility,
        setAdvancedStyleProperty,
        setAdvancedStyleRangeValue,
        setAdvancedStyleRangeIntervalType,
        setAdvancedStyle,
        addNewAdvancedStyleRule,
        removeAdvancedStyleRule,
        onVertexLabelChange,
        isSomeProgressMonitorsRunning,
        onSizeChange,
        toggleGradientInterval,
        setGradientPropertyRangeValue,
        onResetStyles,
        addUndefinedValueStylesRule,
        removeUndefinedValueStylesRule,
        setAdvancedStyleDiscretePropertyValue,
    } = graphStyleCustomisation;

    const {
        label,
        colorAndIconEditorVisible,
        ruleSets,
        userDefinedContent: {
            //fillColor,
            iconColor,
            //strokeColor,
            iconName,
            iconFontFamily,
            size,
        },
    } = state;
    const fillColor = 'white';
    const strokeColor = 'none';

    const colorAndIconEditor = (
        <CustomColorAndIconPicker
            className={classNames('&-color-and-icon-picker')}
            fillColor={fillColor}
            strokeColor={strokeColor}
            iconColor={iconColor}
            iconName={iconName}
            onlyOneColor='icon'
            onlyOneTitle={messages.iconColor}
            iconFontFamily={iconFontFamily}
            defaultColors={DEFAULT_OBJECT_TYPE_COLORS}
            onFillColorChange={setFillColor}
            onIconColorChange={setIconColor}
            onStrokeColorChange={setStrokeColor}
            onIconChange={setIcon}
            onResetStyles={async () => {
                label && (await onResetStyles(label));
            }}
            onPopoverClose={() => setIconAndColorVisibility(false)}
        />
    );

    const vertexTypesByName = keyBy(ontology.objectTypes, vertexType => vertexType.name);
    const vertexTypesItems = objectTypeList.map((vertexLabel) => {
        const listItem: ListItemsType = {
            key: vertexLabel,
            value: vertexTypesByName[vertexLabel]?.displayName || vertexLabel,
        };

        return listItem;
    });

    const properties = useMemo(() => {
        if (!label) {
            return [];
        }

        const element = ontology.objectTypes.find((v) => v.name === label);

        if (!element) {
            console.warn(`${label} is not in ontology schema objectTypes`);

            return [];
        }

        return element.properties.map(ontologyPropertyToControlProperty);
    }, [ontology, label]);

    const propertiesByName = useMemo(() => (
        keyBy(properties, property => property.name)
    ), [properties]);

    const advancedStyleProperties = useMemo(() => {
        return properties.filter((property) => advancedStylePropertiesName.includes(property.name));
    }, [properties, advancedStylePropertiesName]);

    if (isSomeProgressMonitorsRunning || !label || isEmpty(objectTypeList)) {
        if (isSomeProgressMonitorsRunning) {
            return (
                <EmptyPane
                    key='loading'
                    className={classNames('&-loading')}
                    message={commonMessages.loadingMessage}
                    backgroundAnimation='wave'
                    icon='icon-circle'
                    size='extra-large'
                />
            );
        } else if (!label || isEmpty(objectTypeList)) {
            return (
                <EmptyPane
                    key='loading'
                    className={classNames('&-no-vertices')}
                    message={commonMessages.noVertices}
                    backgroundAnimation='wave'
                    icon='icon-circle'
                    size='extra-large'
                />
            );
        }
    }

    return (
        <>
            <div className={classNames('&-object-type-container')}>
                {objectTypeList.length > 1 ? (
                    <ArgListButtonSelector
                        className={classNames('&-object-type-selector')}
                        popoverClassName={classNames('&-object-type-selector-popover')}
                        list={vertexTypesItems}
                        selectedListItem={label}
                        onChangeSelectedItem={onVertexLabelChange}
                    />
                ) : (
                    <span className={classNames('&-object-type-label')}>{label}</span>
                )}
            </div>
            <div className={classNames('&-main')}>
                <div className={classNames('design-properties-panel-default-properties-text')}>
                    <FormattedMessage {...messages.styleDefinition} />
                </div>
                <div className={classNames('&-main-section-content')}>
                    <div className={classNames('&-main-section-content-style')}>
                        <ButtonWithBadge
                            className={classNames('&-main-section-content-style-object-type')}
                            popover={colorAndIconEditor}
                            popoverVisible={colorAndIconEditorVisible}
                            onPopoverVisibleChange={setIconAndColorVisibility}
                            popoverPlacement='left'
                            popoverClassName={classNames('&-popover')}
                            badgeIconName='icon-pencil'
                            iconName={iconName || 'unknown'}
                            backgroundColor={fillColor}
                            borderColor={(strokeColor && strokeColor !== 'none') ? strokeColor : iconColor}
                            iconColor={iconColor}
                        />
                        <div
                            className={classNames('&-main-section-content-style-object-type-size')}
                        >
                            <span
                                className={classNames(
                                    '&-main-section-content-style-object-type-size-label',
                                )}
                            >
                                <FormattedMessage {...commonMessages.size} />
                            </span>
                            <ArgInputNumber
                                className={classNames(
                                    '&-main-section-content-style-object-type-size-input',
                                )}
                                value={size}
                                clearable={false}
                                onChange={onSizeChange}
                                displayRightControl={true}
                                step={GRAPH_NODE_MIN_SIZE}
                                min={GRAPH_NODE_MIN_SIZE}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classNames('&-advanced')}>
                {(filter(ADVANCED_STYLE_LABELS, (el) => !['style'].includes(el)) as string[]).map(
                    (advancedStyleLabel) => {
                        const advancedStyleSectionLabel =
                            commonMessages[
                                advancedStyleLabelMessageMapper[
                                    advancedStyleLabel as AdvancedStyleType
                                ]
                            ];

                        const propertyName = ruleSets[advancedStyleLabel]?.[0]?.property;
                        const rulesSetsWithDefaultValue = getRulesSetsWithDefaultValue(state.userDefinedContent, ruleSets[advancedStyleLabel]);

                        return (
                            <AdvancedStyle
                                key={`$_advanced-${advancedStyleLabel}`}
                                label={advancedStyleSectionLabel}
                                styleType={advancedStyleLabel as AdvancedStyleType}
                                properties={advancedStyleProperties}
                                property={propertiesByName[propertyName]}
                                ruleSets={rulesSetsWithDefaultValue}
                                onAdvancedStylePropertyNameChange={setAdvancedStyleProperty}
                                onAdvancedStyleChange={setAdvancedStyle}
                                onAdvancedStylePropertyValueChange={setAdvancedStyleDiscretePropertyValue}
                                onAdvancedStylePropertyRangeValueChange={setAdvancedStyleRangeValue}
                                onAdvancedStylePropertyRangeIntervalTypeChange={setAdvancedStyleRangeIntervalType}
                                onAddNewStyleRule={addNewAdvancedStyleRule}
                                onRemoveStyleRule={removeAdvancedStyleRule}
                                toggleGradientInterval={toggleGradientInterval}
                                onGradientPropertyRangeValueChange={setGradientPropertyRangeValue}
                                addUndefinedValueStylesRule={addUndefinedValueStylesRule}
                                removeUndefinedValueStylesRule={removeUndefinedValueStylesRule}
                            />
                        );
                    },
                )}
            </div>
        </>
    );
}
