import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isFunction, isRegExp, isString } from 'lodash';
import Debug from 'debug';

const debug = Debug('basic:router:ArgRoute');

interface ArgRouteProps {
    path?: RegExp | string;
    exactPath?: string | string[];
    replace?: string | ((regexpResult: RegExpExecArray | null) => string);
    push?: string | ((regexpResult: RegExpExecArray | null) => string);
    render?: (regexpResult: RegExpExecArray | null) => ReactNode;
}

export function ArgRoute(props: ArgRouteProps) {
    const {
        path,
        replace,
        push,
        render,
        exactPath,
    } = props;

    const navigate = useNavigate();
    const location = useLocation(); // this component will disappear later (once refacto done) and is not used
    let pathName = location.pathname;
    if (!pathName) {
        pathName = '/';
    }

    let result: RegExpExecArray | null = null;
    if (exactPath) {
        if (Array.isArray(exactPath)) {
            if (!exactPath.includes(pathName)) {
                debug('NOT EQUAL: PathName=', pathName, 'exactPath=', exactPath);

                return null;
            }
        } else if (pathName !== exactPath) {
            debug('NOT EQUAL: PathName=', pathName, 'exactPath=', exactPath);

            return null;
        }
    } else if (isString(path)) {
        if (pathName.indexOf(path) !== 0) {
            debug('NO START: PathName=', pathName, 'path=', path);

            return null;
        }
    } else if (isRegExp(path)) {
        path.lastIndex = 0;
        result = path.exec(pathName);
        if (!result) {
            debug('No REGEXP pathName=', pathName, 'path=', path);

            return null;
        }
    } else {
        return null;
    }

    if (replace) {
        const path: string = isFunction(replace) ? replace(result) : String(replace);

        debug('REPLACE path=', path);
        navigate(path, { replace: true });

        return null;
    }

    if (push) {
        const path: string = isFunction(push) ? push(result) : String(push);

        debug('PUSH path=', path);
        navigate(path);

        return null;
    }

    if (render) {
        const component = render(result);

        return <>{component}</>;
    }

    return <div>INVALID ROUTE</div>;
}
