import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useRef } from 'react';

import { getInputSelection, isInputFocused, setInputSelection } from './input';
import { isDOMElement } from './utils';
import { cancelDefer, defer } from './defer';
import { NO_SELECTION, TextSelection, TextState } from './types';

export function useInputElement(inputRef: MutableRefObject<HTMLInputElement|null>) {
    return useCallback(() => {
        let input = inputRef.current;
        const isDOMNode = typeof window !== 'undefined' && isDOMElement(input as HTMLElement);

        // workaround for react-test-renderer
        // https://github.com/sanniassin/react-input-mask/issues/147
        if (!input || !isDOMNode) {
            return null;
        }

        if (input.nodeName !== 'INPUT') {
            input = input.querySelector('input');
        }

        if (!input) {
            throw new Error(
                "react-input-mask: inputComponent doesn't contain input node",
            );
        }

        return input;
    }, [inputRef]);
}

function useDeferLoop(callback: ()=>void):[()=>void, ()=>void] {
    const deferIdRef = useRef<number>();

    const runLoop = useCallback(() => {
        // If there are simulated focus events, runLoop could be
        // called multiple times without blur or re-render
        if (deferIdRef.current !== null) {
            return;
        }

        function loop() {
            callback();
            deferIdRef.current = defer(loop);
        }

        loop();
    }, [callback]);

    const stopLoop = useCallback(() => {
        if (!deferIdRef.current) {
            return;
        }

        cancelDefer(deferIdRef.current);
        deferIdRef.current = undefined;
    }, []);

    useEffect(() => {
        if (deferIdRef.current) {
            stopLoop();
            runLoop();
        }
    }, [runLoop, stopLoop]);

    //useEffect(cancelDefer, []);

    return [runLoop, stopLoop];
}

function useSelection(inputRef: MutableRefObject<HTMLInputElement|null>, isMasked: boolean) {
    const selectionRef = useRef<TextSelection>(NO_SELECTION);
    const getInputElement = useInputElement(inputRef);

    const getSelection = useCallback(():TextSelection => {
        const input = getInputElement();
        if (!input) {
            return NO_SELECTION;
        }

        const result = getInputSelection(input);

        return result;
    }, [getInputElement]);

    const getLastSelection = useCallback(() => selectionRef.current, []);

    const setSelection = useCallback((selection?: TextSelection) => {
        const input = getInputElement();

        // Don't change selection on unfocused input
        // because Safari sets focus on selection change (#154)
        if (!input || !isInputFocused(input)) {
            return;
        }

        if (!selection) {
            return;
        }

        setInputSelection(input, selection.start, selection.end);

        // Use actual selection in case the requested one was out of range
        selectionRef.current = getSelection();
    },
    [getInputElement, getSelection],
    );

    const selectionLoop = useCallback(() => {
        selectionRef.current = getSelection();
    }, [getSelection]);
    const [runSelectionLoop, stopSelectionLoop] = useDeferLoop(selectionLoop);

    useLayoutEffect(() => {
        if (!isMasked) {
            return;
        }

        const input = getInputElement();
        if (!input) {
            return;
        }

        input.addEventListener('focus', runSelectionLoop);
        input.addEventListener('blur', stopSelectionLoop);

        if (isInputFocused(input)) {
            runSelectionLoop();
        }

        return () => {
            input.removeEventListener('focus', runSelectionLoop);
            input.removeEventListener('blur', stopSelectionLoop);

            stopSelectionLoop();
        };
    });

    return { getSelection, getLastSelection, setSelection };
}

function useValue(inputRef: MutableRefObject<HTMLInputElement|null>, initialValue: string) {
    const getInputElement = useInputElement(inputRef);
    const valueRef = useRef(initialValue);

    const getValue = useCallback(() => {
        const input = getInputElement();

        return input?.value || '';
    }, [getInputElement]);

    const getLastValue = useCallback(() => valueRef.current, []);

    const setValue = useCallback(
        (newValue: string) => {
            valueRef.current = newValue;

            const input = getInputElement();

            if (input) {
                input.value = newValue;
            }
        },
        [getInputElement],
    );

    return {
        getValue,
        getLastValue,
        setValue,
    };
}

export function useInputState(initialValue: string, isMasked: boolean) {
    const inputRef = useRef<HTMLInputElement|null>(null);
    const { getSelection, getLastSelection, setSelection } = useSelection(
        inputRef,
        isMasked,
    );
    const { getValue, getLastValue, setValue } = useValue(inputRef, initialValue);

    function getLastInputState():TextState {
        const result:TextState = {
            value: getLastValue(),
            selection: getLastSelection(),
        };

        return result;
    }

    function getInputState():TextState {
        const result:TextState = {
            value: getValue(),
            selection: getSelection(),
        };

        return result;
    }

    function setInputState({ value, selection }:TextState) {
        setValue(value || '');
        setSelection(selection);
    }

    return {
        inputRef,
        getInputState,
        getLastInputState,
        setInputState,
    };
}

export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}
