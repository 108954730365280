import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgRelativeTime, ArgTableColumn3, ArgUser } from 'src/components/basic';
import { User } from 'src/model/user';
import { DeletedUserActionsMenu } from '../deleted-user-action-menu/deleted-user-action-menu';

import './deleted-users-table.less';

const messages = defineMessages({
    by: {
        id: 'settings.users.deleted-users-table-last-modified-by',
        defaultMessage: 'By ',
    },
    fullName: {
        id: 'settings.users.deleted-users-table-column.name',
        defaultMessage: 'Full name',
    },
    username: {
        id: 'settings.users.deleted-users-table-column.username',
        defaultMessage: 'Username',
    },
    identityIssuer: {
        id: 'settings.users.deleted-users-table-column.identityIssuer',
        defaultMessage: 'Identity Issuer',
    },
    lastUpdatedDate: {
        id: 'settings.users.deleted-users-table-column.lastUpdatedDate',
        defaultMessage: 'Last Modified',
    },
});

export const useTableColumns = (): ArgTableColumn3<User>[] => {
    const intl = useIntl();

    return useMemo(
        () => [
            {
                key: 'icon',
                sortable: false,
                columnName: ' ',
                title: ' ',
                dataIndex: 'id',
                render: function display(id, user) {
                    return <ArgUser user={user} label={false} />;
                },
            },
            {
                key: 'fullName',
                sortable: true,
                columnName: 'Full name',
                title: messages.fullName,
                dataIndex: 'displayName',
                render: function display(displayName) {
                    return <span>{displayName}</span>;
                },
            },
            {
                key: 'userName',
                sortable: true,
                columnName: 'Username',
                title: intl.formatMessage(messages.username),
                dataIndex: 'userName',
                minWidth: 200,
                render: function display(userName) {
                    return <span>{userName}</span>;
                },
            },
            {
                key: 'identityIssuer',
                sortable: true,
                columnName: 'Identity Issuer',
                title: messages.identityIssuer,
                dataIndex: 'identityIssuer',
                render: function display(identityIssuer) {
                    return <span>{identityIssuer || 'Local'}</span>;
                },
            },
            {
                key: 'lastModified',
                sortable: true,
                columnName: 'Last Modified',
                title: messages.lastUpdatedDate,
                dataIndex: 'lastUpdatedDate',
                minWidth: 200,
                render: function display(data, user) {
                    return user.lastUpdatedDate ? (
                        <div className='deleted-users-table-last-modified'>
                            <ArgRelativeTime date={new Date(user.lastUpdatedDate)} numeric='auto' />
                            <span className='deleted-users-table-last-modified-user'>
                                {intl.formatMessage(messages.by)}
                                {user.lastUpdatedBy?.displayName}
                            </span>
                        </div>
                    ) : (
                        <div />
                    );
                },
                defaultSortOrder: 'ascend',
                sorter: (a, b) => {
                    if (a.lastUpdatedDate && b.lastUpdatedDate) {
                        const first = new Date(a.lastUpdatedDate.toString());
                        const second = new Date(b.lastUpdatedDate.toString());

                        return second.getTime() - first.getTime();
                    }

                    return -1;
                },
            },
            {
                key: 'actions',
                columnName: ' ',
                title: ' ',
                dataIndex: 'actions',
                sortable: false,
                render: function display(data: string, user: User) {
                    return <DeletedUserActionsMenu user={user} />;
                },
            },
        ],
        [intl],
    );
};
