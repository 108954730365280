import * as htmlToImage from 'html-to-image';

interface ScreenshotOptions {
    copyPropertiesCallback?: ((element: HTMLElement, clone: HTMLElement) => void) | undefined;
}

export class ScreenshotComponentSpecific {
    copyPropertiesCallback?: ((element: HTMLElement, clone: HTMLElement) => void) | undefined;
    width?: number | undefined;
}

export interface ScreenshotImage {
    toDataURL: (type?: string, quality?: number) => Promise<string>;
    toBlob: (type?: string, quality?: number) => Promise<Blob>;

    width: number;
    height: number;
}

export async function screenshot(rootElement: HTMLElement, options?: ScreenshotOptions): Promise<ScreenshotImage> {
    const canvas = await htmlToImage.toCanvas(rootElement, {});

    return {
        toDataURL: async (type?: string, quality?: number): Promise<string> => {
            const p = canvas.toDataURL(type, quality);

            if (!p) {
                throw new Error('Can not get Data URL');
            }

            return p;
        },
        toBlob: async (type?: string, quality?: number): Promise<Blob> => {
            const p = new Promise<Blob>((resolve, reject) => {
                canvas.toBlob((blob: Blob | null): void => {
                    if (!blob) {
                        reject();

                        return;
                    }
                    resolve(blob);
                },
                type,
                quality,
                );
            });

            return p;
        },
        width: canvas.width,
        height: canvas.height,
    };
}
