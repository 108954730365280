import React, { useCallback, useContext, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgButton,
    ArgMenu,
    ArgMenuItem,
    ArgModalContainerContext,
    ClassValue,
    ProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
} from 'src/components/basic';
import { EditValuationPolicyModal } from '../edit-policy-modal/edit-valuation-policy-modal';
import { ValuationPolicy } from 'src/settings/models/valuation-policy';
import { ValuationPoliciesDeletionModal } from '../valuation-policies-deletion-modal/valuation-policies-deletion-modal';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

const messages = defineMessages({
    edit: {
        id: 'settings.valuation-policy.kebab-menu.edit',
        defaultMessage: 'Edit',
    },
    enable: {
        id: 'settings.valuation-policy.kebab-menu.activate',
        defaultMessage: 'Activate',
    },
    disable: {
        id: 'settings.valuation-policy.kebab-menu.deactivate',
        defaultMessage: 'Deactivate',
    },
    delete: {
        id: 'settings.valuation-policy.kebab-menu.delete',
        defaultMessage: 'Delete',
    },
    changeStatusErrorMessage: {
        id: 'settings.valuation-policy.menu.change-status-error-message',
        defaultMessage: 'Unable to {statusChange, select, activate {activate} other {deactivate}} valuation policy',
    },
});

export interface ValuationPolicyActionsProps {
    valuationPolicy: ValuationPolicy;
    onActionSuccess: () => void;
    className?: ClassValue;
}

export function ValuationPolicyActions({
    valuationPolicy,
    onActionSuccess,
    className,
}: ValuationPolicyActionsProps) {
    const notifications = useArgNotifications();

    const [visible, setVisible] = useState(false);

    const modalContainer = useContext(ArgModalContainerContext);

    const handleEdition = useCallback(() => {
        modalContainer.open('edit-valuation-policy-modal', <EditValuationPolicyModal
            closeModal={() => modalContainer.close('edit-valuation-policy-modal')}
            valuationPolicy={valuationPolicy}
            onSuccess={onActionSuccess}
        />);
    }, [modalContainer, valuationPolicy, onActionSuccess]);

    const handleDeletion = useCallback(() => {
        modalContainer.open('delete-valuation-policy-modal', <ValuationPoliciesDeletionModal
            onClose={() => modalContainer.close('delete-valuation-policy-modal')}
            valuationPolicies={[valuationPolicy]}
            onSuccess={onActionSuccess}
        />);
    }, [modalContainer, valuationPolicy, onActionSuccess]);

    const [handleToggleActivation] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            await explorationSettingsConnector.changeValuationPolicyActivationStatus(!!valuationPolicy.enabled, valuationPolicy.id, progressMonitor);
            onActionSuccess();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.changeStatusErrorMessage }, error as Error, {
                statusChange: valuationPolicy.enabled ? 'deactivate' : 'activate',
            });
            throw error;
        }
    }, [onActionSuccess, valuationPolicy.enabled, valuationPolicy.id]);


    const actionsMenu = (
        <ArgMenu>
            <ArgMenuItem
                key='edit'
                data-testid='edit'
                onClick={handleEdition}
                label={messages.edit}
            />
            <ArgMenuItem
                key='toggle'
                data-testid='toggle'
                onClick={handleToggleActivation}
                label={valuationPolicy.enabled ? messages.disable : messages.enable}
            />
            <ArgMenuItem
                key='delete'
                data-testid='delete'
                onClick={handleDeletion}
                label={messages.delete}
            />
        </ArgMenu>
    );

    return (
        <>
            <ArgButton
                className={className}
                type='ghost'
                icon='icon-options'
                popover={actionsMenu}
                popoverTrigger='click'
                popoverVisible={visible}
                popoverPlacement='bottomLeft'
                onPopoverVisibleChange={setVisible}
            />
        </>
    );
}
