import React, { useCallback } from 'react';
import Slider from 'antd/lib/slider';

import { ArgInputNumber, useClassNames } from '../../../../basic';
import { GRAPH_NODE_MIN_SIZE } from '../../constants';
import { RangeNumberControlMessages } from '../advanced-body';
import { formatSize, handleIntervalOverlapValues } from './utils';

import './gradient-number-control.less';

interface GradientNumberControlProps {
    sliderLeftBound?: number;
    sliderRightBound?: number;
    minPropertyValue?: number;
    maxPropertyValue?: number;
    minSizeValue?: number;
    maxSizeValue?: number;
    onGradientPropertyRangeValueChange: (
        type: 'property' | 'size',
        side: 'left' | 'right',
        value: number | null
    ) => Promise<void>;
    messages: RangeNumberControlMessages;
}

export function GradientNumberControl({
    sliderLeftBound,
    sliderRightBound,
    minPropertyValue,
    maxPropertyValue,
    minSizeValue,
    maxSizeValue,
    onGradientPropertyRangeValueChange,
    messages,
}: GradientNumberControlProps) {
    const classNames = useClassNames('gradient-number-control');

    const onLeftPropertyValueChange = useCallback(
        (value: number | null) => {
            const _value = handleIntervalOverlapValues('left', value, maxPropertyValue, 'number', [
                sliderLeftBound,
                sliderRightBound,
            ]) as number | null;
            onGradientPropertyRangeValueChange('property', 'left', _value);
        },
        [onGradientPropertyRangeValueChange, maxPropertyValue, maxPropertyValue, sliderLeftBound, sliderRightBound],
    );

    const onRightPropertyValueChange = useCallback(
        (value: number | null) => {
            const _value = handleIntervalOverlapValues('right', value, minPropertyValue, 'number', [
                sliderLeftBound,
                sliderRightBound,
            ]) as number | null;

            onGradientPropertyRangeValueChange('property', 'right', _value);
        },
        [onGradientPropertyRangeValueChange, maxPropertyValue, minPropertyValue, sliderLeftBound, sliderRightBound],
    );
    const onLeftSizeChange = useCallback(
        (value: number | null) => {
            const formattedSize = formatSize(value);

            onGradientPropertyRangeValueChange('size', 'left', formattedSize);
        },
        [onGradientPropertyRangeValueChange],
    );
    const onRightSizeChange = useCallback(
        (value: number | null) => {
            const formattedSize = formatSize(value);

            onGradientPropertyRangeValueChange('size', 'right', formattedSize);
        },
        [onGradientPropertyRangeValueChange],
    );

    if (sliderLeftBound === undefined || sliderRightBound === undefined || sliderLeftBound === sliderRightBound) {
        return null;
    }

    return (
        <div className={classNames('&')}>
            <Slider
                className={classNames('&-slider')}
                range={{ draggableTrack: true }}
                value={[minPropertyValue ?? sliderLeftBound, maxPropertyValue ?? sliderRightBound]}
                min={sliderLeftBound}
                max={sliderRightBound}
                onChange={(value: [number, number]) => {
                    const left = value?.[0];
                    const right = value?.[1];
                    if (left !== minPropertyValue) {
                        onLeftPropertyValueChange(left);
                    }
                    if (right !== maxPropertyValue) {
                        onRightPropertyValueChange(right);
                    }
                }}
            />
            <div className={classNames('&-property')}>
                <ArgInputNumber
                    className={classNames('&-property-min')}
                    value={minPropertyValue}
                    min={sliderLeftBound}
                    max={sliderRightBound}
                    onChange={onLeftPropertyValueChange}
                    placeholder={messages?.startValuePlaceholder}
                    debounce={false}
                />
                <ArgInputNumber
                    className={classNames('&-property-max')}
                    value={maxPropertyValue}
                    min={sliderLeftBound}
                    max={sliderRightBound}
                    onChange={onRightPropertyValueChange}
                    placeholder={messages?.endValuePlaceholder}
                    debounce={false}
                />
            </div>

            <div className={classNames('&-size')}>
                <ArgInputNumber
                    className={classNames('&-size-min')}
                    step={GRAPH_NODE_MIN_SIZE}
                    min={GRAPH_NODE_MIN_SIZE}
                    value={minSizeValue}
                    displayRightControl={true}
                    onChange={onLeftSizeChange}
                    placeholder={messages?.minPlaceholder}
                />
                <ArgInputNumber
                    className={classNames('&-size-max')}
                    step={GRAPH_NODE_MIN_SIZE}
                    min={GRAPH_NODE_MIN_SIZE}
                    value={maxSizeValue}
                    displayRightControl={true}
                    onChange={onRightSizeChange}
                    placeholder={messages?.maxPlaceholder}
                />
            </div>
        </div>
    );
}
