import { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { useLocation } from 'react-router';

import { ProgressMonitor, useArgNotifications, useMemoAsync, useSetTimeout } from 'src/components/basic';
import { isResponse401 } from 'src/components/basic/utils/response-error';
import { UseGetMeReturnType } from 'src/contexts/user-context';
import { UsersConnector } from 'src/utils/connectors/users-connector';

const RETRY_TIMER = 1000 * 30 + Math.floor(Math.random() * 10000);

const messages = defineMessages({
    loadingUserError: {
        id: 'settings.settings-router.LoadingUserError',
        defaultMessage: 'Failed to load user',
    },
    verifyingAuthentication: {
        id: 'settings.settings-router.VerifyingAuthentication',
        defaultMessage: 'Verifying authentication {threeDotsLoading}',
    },
});

export function useCurrentUserWitRetry(
    onUnauthorized?: () => void,
): [UseGetMeReturnType | undefined, ProgressMonitor | undefined, Error | undefined] {
    const notifications = useArgNotifications();
    const { state } = useLocation();

    const [currentUser, progressMonitor, error] = useMemoAsync<UseGetMeReturnType>(async (progressMonitor: ProgressMonitor) => {
        // If user is already athenticated, there is no need to refetch it
        if (state?.userMe) {
            return state.userMe as UseGetMeReturnType;
        }

        try {
            const user = await UsersConnector.getInstance().myUserDetails(progressMonitor, !!onUnauthorized);

            const ret: UseGetMeReturnType = {
                me: user,
            };

            return ret;
        } catch (error: any) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            if (!isResponse401(error)) {
                notifications.snackError({ message: messages.loadingUserError }, error as Error);
                throw error;
            }

            onUnauthorized?.();
        } finally {
            progressMonitor.done();
        }
    }, [], messages.verifyingAuthentication);

    const startTimer = useSetTimeout(RETRY_TIMER);

    useEffect(() => {
        if (!error) {
            return;
        }

        console.log('Waiting for', RETRY_TIMER, 'ms');

        async function test() {
            try {
                await UsersConnector.getInstance().myUserDetails(progressMonitor);
            } catch (x) {
                console.log('Retry for', RETRY_TIMER, 'ms');
                startTimer(test);

                return;
            }

            console.log('Ready !!!');

            document.location.reload();
        }

        startTimer(test);
    }, [error]);

    return [currentUser, progressMonitor, error];
}
