import { ArgTableRowState, DataFilter, DataProvider } from './data-provider';

export class ArrayDataProvider<T, F extends DataFilter = any> extends DataProvider<T, F> {
    #data: T[];

    constructor(data: T[]) {
        super('ArrayDataProvider');
        this.#data = data;
    }

    getRow(rowIndex: number): T | ArgTableRowState {
        if (rowIndex < 0 || rowIndex >= this.#data.length) {
            return ArgTableRowState.Error;
        }

        return this.#data[rowIndex];
    }

    getData(): T[] {
        return this.#data;
    }

    get rowCount() {
        return this.#data.length;
    }

    get hasAllRows(): boolean {
        return true;
    }
}
