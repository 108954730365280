import { RelativeTimeFormatSingularUnit } from '@formatjs/ecma402-abstract';

export const computeRelativeTime = (
    firstDate?: Date,
    secondDate: Date = new Date(), // For tests !
    skipDateTime = false,
): [number, RelativeTimeFormatSingularUnit] | undefined => {
    if (!firstDate) {
        return undefined;
    }

    const numberOfSeconds = Math.round((secondDate.getTime() - firstDate.getTime()) / 1000);

    let unit: RelativeTimeFormatSingularUnit;

    let value = Math.floor(numberOfSeconds / 31536000);

    if (value >= 1) {
        unit = 'year';
    } else {
        value = Math.floor(numberOfSeconds / 2592000);
        if (value >= 1) {
            unit = 'month';
        } else {
            value = Math.floor(numberOfSeconds / 86400);
            if (value >= 1) {
                unit = 'day';
            } else if (skipDateTime) {
                unit = 'day';
                value = 0;
            } else {
                value = Math.floor(numberOfSeconds / 3600);
                if (value >= 1) {
                    unit = 'hour';
                } else {
                    value = Math.floor(numberOfSeconds / 60);
                    if (value >= 1) {
                        unit = 'minute';
                    } else if (numberOfSeconds > 0) {
                        value = numberOfSeconds;
                        unit = 'second';
                    } else {
                        value = 1;
                        unit = 'second';
                    }
                }
            }
        }
    }

    return [-value, unit];
};
