import L from 'leaflet';
import { Polygon, PolygonProps, useMap } from 'react-leaflet';
import React, { useCallback, useEffect, useRef } from 'react';

import { useClassNames } from '../../../../arg-hooks/use-classNames';

export function ArgMapPolygon({ pathOptions, positions }: PolygonProps) {
    const classNames = useClassNames('arg-map-polygon');

    const polygonRef = useRef<L.Polygon>(null);
    const map = useMap();

    const fitBounds = useCallback(() => {
        if (polygonRef?.current) {
            map.fitBounds(polygonRef.current.getBounds());
        }
    }, [map, polygonRef]);

    useEffect(() => {
        fitBounds();
    }, [positions]);

    useEffect(() => {
        map.on('resize', fitBounds);

        return () => {
            map.off('resize', fitBounds);
        };
    }, []);

    return (
        <Polygon
            ref={polygonRef}
            positions={positions}
            pathOptions={pathOptions}
            className={classNames('&')}
        />
    );
}
