import { cloneDeep, isNil, omit, set, sortBy, uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';

import { ArgChangeReason, ArgCombo, immutableSet, useClassNames } from 'src/components/basic';
import { getControlType, getMetaPropertyControlType, isPicklistControl } from 'src/components/common/controls/controls';
import { MetaPropertyObject, RuleTarget } from '../../../../models/policy';
import { getObjectPropertyOperatorAndValue, isMetaPropertyObject, ROW_HEIGHT } from '../../policy-utils';
import { UserProfileField } from 'src/model/user-metadata';
import { OntologyBaseSchema, OntologyMetaProperty, OntologyProperty } from 'src/settings/universes/ontology/types';
import { getPropertyCompatibilityCode, ontologyPropertyToControlProperty } from 'src/settings/universes/utils';
import { ReadOnlyFilterInput } from '../read-only-filter-input/read-only-filter-input';
import { PropertyValue } from 'src/exploration/model/universe';
import { DataType } from 'src/components/common/data-types';

import './universe-property-filter.less';


const HAS_META_PROPERTY_KEY = 'has-meta-property';

const messages = defineMessages({
    where: {
        id: 'settings.target-property-filter.text.where',
        defaultMessage: 'where',
    },
    select: {
        id: 'settings.target-property-filter.text.select',
        defaultMessage: 'Select',
    },
    isEqualTo: {
        id: 'settings.target-property-filter.text.isEqualTo',
        defaultMessage: 'is equal to',
    },
    isDifferentThan: {
        id: 'settings.target-property-filter.text.isDifferentThan',
        defaultMessage: 'is different than',
    },
    isGreaterThan: {
        id: 'settings.target-property-filter.text.isGreaterThan',
        defaultMessage: 'is greater than',
    },
    isSmallerThan: {
        id: 'settings.target-property-filter.text.isSmallerThan',
        defaultMessage: 'is smaller than',
    },
    isGreaterOrEqualTo: {
        id: 'settings.target-property-filter.text.isGreaterOrEqualTo',
        defaultMessage: 'is greater or equal to',
    },
    isSmallerOrEqualTo: {
        id: 'settings.target-property-filter.text.isSmallerOrEqualTo',
        defaultMessage: 'is smaller or equal to',
    },
    universeContext: {
        id: 'settings.target-property-filter.text.universeContext',
        defaultMessage: 'Property:',
    },
    userContext: {
        id: 'settings.target-property-filter.text.userContext',
        defaultMessage: 'User:',
    },
    hasMetaProperty: {
        id: 'settings.target-property-filter.text.hasMetaProperty',
        defaultMessage: 'Has metaproperty',
    },
    that: {
        id: 'settings.target-property-filter.text.that',
        defaultMessage: 'that',
    },
});

export interface Operator {
    key: string;
    label: MessageDescriptor;
}

export const OPERATORS: Operator[] = [
    {
        key: '=',
        label: messages.isEqualTo,
    },
    {
        key: '!=',
        label: messages.isDifferentThan,
    },
    {
        key: '>',
        label: messages.isGreaterThan,
    },
    {
        key: '<',
        label: messages.isSmallerThan,
    },
    {
        key: '>=',
        label: messages.isGreaterOrEqualTo,
    },
    {
        key: '<=',
        label: messages.isSmallerOrEqualTo,
    },
];

const META_OPERATOR: Operator = {
    key: HAS_META_PROPERTY_KEY,
    label: messages.hasMetaProperty,
};

const OPERATORS_WITH_META: Operator[] = [
    META_OPERATOR,
    ...OPERATORS,
];

interface PropertyValueInfo {
    key: string;
    from?: 'profile' | undefined;
    label: string;
    userProfileField?: UserProfileField;
    value: string;
}

interface UniversePropertyFilterProps<T> {
    objKey: string | undefined;
    objVal: string | MetaPropertyObject | undefined;
    currentPath: string;
    onChange: React.Dispatch<React.SetStateAction<T>>;
    editable: boolean;
    userProfileFields: UserProfileField[];
    schema: OntologyBaseSchema;
    properties: OntologyProperty[];
    search?: string;
}

export function UniversePropertyFilter<T extends { Targets: RuleTarget[] }>(props: UniversePropertyFilterProps<T>) {
    const {
        objKey,
        objVal,
        currentPath,
        onChange,
        editable,
        userProfileFields,
        schema,
        properties,
        search,
    } = props;

    const intl = useIntl();
    const isMeta = isMetaPropertyObject(objVal);
    const operation = isMeta ? Object.entries(objVal.meta)[0]?.[1] : objVal;
    const { operator, value, from } = getObjectPropertyOperatorAndValue(operation);
    const propertyOperator = isMeta ? META_OPERATOR : operator;

    const [metaPropertyKey] = isMeta ? Object.entries(objVal?.meta || {})[0] ?? [] : [];

    const selectedProperty = useMemo(() => (
        properties.find((property) => (property.name === objKey))
    ), [properties, objKey]);

    const metaPropertyList = schema.metaProperties || [];

    const selectedMetaProperty = metaPropertyList.find((metaProperty) => metaProperty.name === metaPropertyKey);

    const classNames = useClassNames('settings-universe-property-filter');

    const dropdownOptions = useMemo<PropertyValueInfo[]>(() => {
        const universePrefix = intl.formatMessage(messages.universeContext);
        const userPrefix = intl.formatMessage(messages.userContext);

        const compatibilityCode = isMeta && selectedMetaProperty
            ? getPropertyCompatibilityCode(selectedMetaProperty)
            : getPropertyCompatibilityCode(selectedProperty);

        const filteredUserProfileFields = userProfileFields.filter((field) => {
            return !compatibilityCode || field.typeCompatibilityCode === compatibilityCode;
        });

        const userProfileValues = filteredUserProfileFields.map((field): PropertyValueInfo => {
            return {
                key: `user:${field.id}`,
                from: 'profile',
                label: userPrefix + field.displayName,
                userProfileField: field,
                value: field.id,
            };
        });

        if (isMeta && selectedMetaProperty) {
            return [
                ...propertyValuesFromMetaProperty(selectedMetaProperty, universePrefix),
                ...userProfileValues,
            ];
        }

        if (!objKey) {
            const fixedValues: PropertyValueInfo[] = properties.flatMap((property) => (
                propertyValuesFromProperty(property, universePrefix)
            ));

            const list = [...userProfileValues, ...fixedValues];
            const uniqList = uniqBy(list, (item) => item.key);

            sortBy(uniqList, (a) => a.label);

            return uniqList;
        }
        if (!selectedProperty) {
            return [];
        }

        const fixedValues = propertyValuesFromProperty(selectedProperty, universePrefix);

        return [...fixedValues, ...userProfileValues];
    }, [intl, isMeta, selectedMetaProperty, selectedProperty, userProfileFields, objKey, properties]);

    const handlePropertyValueChange = useCallback((newValue: PropertyValueInfo | PropertyValue | null, change: ArgChangeReason | undefined) => {
        if (change && change === 'blur') {
            return;
        }

        let parseOperation: string | undefined = operator?.key;

        if (!isNil(newValue)) {
            if ((typeof newValue === 'object') && ('value' in newValue)) {
                parseOperation = newValue.from
                    ? `${newValue.from}:${operator?.key}${newValue.value}`
                    : `${operator?.key}${newValue.value}`;
            } else {
                parseOperation = `${operator?.key}${JSON.stringify(newValue)}`;
            }
        }

        const operationValue = (isMeta && metaPropertyKey)
            ? { meta: { [metaPropertyKey]: parseOperation } }
            : parseOperation;

        onChange((currentRule) => {
            return immutableSet(currentRule,
                getPath(currentPath, objKey),
                operationValue,
            );
        });
    }, [currentPath, isMeta, metaPropertyKey, objKey, onChange, operator?.key]);

    const selectedDropdownItem = dropdownOptions.find((o) => o.value === value && o.from === from);

    const [editor, textValue] = useMemo(() => {
        if (!selectedProperty) {
            return [null, ''];
        }
        const controlProperty = ontologyPropertyToControlProperty(selectedMetaProperty ?? selectedProperty);
        const controlType = selectedMetaProperty ? getMetaPropertyControlType(controlProperty) : getControlType(controlProperty);

        if (controlProperty.type === DataType.Geoshape) {
            console.warn('Geoshape not yet supported as property policy filter');

            return [null, ''];
        }

        const EditorType = controlType?.editorType;
        if (!EditorType) {
            return [null, ''];
        }

        let editorValue: PropertyValue | PropertyValueInfo | undefined = value;
        if (selectedDropdownItem) {
            editorValue = controlProperty.fixedValues ? selectedDropdownItem : selectedDropdownItem.label;
        }

        const Editor = (
            <EditorType
                className={classNames('&-property-value', '&-property-editor')}
                propertyName={controlProperty.name}
                propertyDisplayName={controlProperty.displayName}
                onChange={handlePropertyValueChange}
                value={editorValue}
                cardinality='optional'
                pickerOptions={{
                    ...(controlProperty.fixedValues && { fixedValues: dropdownOptions }),
                    pickList: isPicklistControl(controlProperty),
                    getPossibleValues: () => Promise.resolve(dropdownOptions),
                    getItemLabel: 'label',
                    getItemKey: 'key',
                }}
            />
        );

        const toText = controlType.toText(value, intl);

        return [Editor, toText];
    }, [selectedProperty, selectedMetaProperty, value, selectedDropdownItem, classNames,
        handlePropertyValueChange, dropdownOptions, intl]);

    return (
        <div
            key={currentPath}
            className={classNames('&-object-form-container')}
            style={{ height: ROW_HEIGHT }}
        >
            <div className={classNames('&-where')}>{intl.formatMessage(messages.where)}</div>
            {editable ? (
                <ArgCombo<OntologyProperty>
                    className={classNames('&-property-key')}
                    placeholder={intl.formatMessage(messages.select)}
                    getItemKey={(property) => property.name}
                    getItemLabel={(property) => property.displayName}
                    items={properties}
                    value={selectedProperty}
                    cardinality='one'
                    size='small'
                    popoverClassName='arg-input-popover-no-max-width'
                    onChange={(value: OntologyProperty) => {
                        onChange((currentRule) => {
                            const newPolicy = cloneDeep(currentRule);
                            omit(newPolicy, getPath(currentPath, objKey));
                            set(newPolicy, `${currentPath}.object.["${value.name}"]`, operator?.key);

                            return newPolicy;
                        });
                    }}
                    enableFilter={true}
                />
            ) : (
                <ReadOnlyFilterInput
                    className={classNames('&-property-key')}
                    value={selectedProperty?.displayName}
                    search={search}
                />
            )}
            {objKey &&
                (editable ? (
                    <>
                        <ArgCombo<Operator>
                            className={classNames('&-property-operator')}
                            placeholder={messages.select}
                            items={OPERATORS_WITH_META}
                            getItemKey='key'
                            getItemLabel='label'
                            popoverClassName='arg-input-popover-no-max-width'
                            value={propertyOperator}
                            cardinality='one'
                            size='small'
                            onChange={(newOperator: Operator) => {
                                onChange((currentRule) => {
                                    const newValue: string | MetaPropertyObject = (newOperator.key === HAS_META_PROPERTY_KEY) ? {
                                        meta: {},
                                    } : `${newOperator.key}"${value || ''}"`;

                                    return immutableSet(currentRule,
                                        `${currentPath}.object.${objKey}`,
                                        newValue,
                                    );
                                });
                            }}
                        />
                        {propertyOperator?.key === HAS_META_PROPERTY_KEY && (
                            <ArgCombo<OntologyMetaProperty>
                                className={classNames('&-metaproperty-selection')}
                                placeholder={intl.formatMessage(messages.select)}
                                items={metaPropertyList}
                                getItemKey={(metaProperty) => metaProperty.name}
                                getItemLabel={(metaProperty) => metaProperty.displayName}
                                value={selectedMetaProperty}
                                popoverClassName='arg-input-popover-no-max-width'
                                cardinality='one'
                                size='small'
                                onChange={(newMetaProperty: OntologyMetaProperty) => {
                                    onChange((currentRule) => {
                                        const newValue: MetaPropertyObject = {
                                            meta: {
                                                [newMetaProperty.name]: '',
                                            },
                                        };

                                        return immutableSet(currentRule,
                                            getPath(currentPath, objKey),
                                            newValue,
                                        );
                                    });
                                }}
                            />
                        )}
                        {((propertyOperator?.key === HAS_META_PROPERTY_KEY) && selectedMetaProperty) && (
                            <>
                                <div className={classNames('&-that')}>{intl.formatMessage(messages.that)}</div>
                                <ArgCombo<Operator>
                                    className={classNames('&-property-operator')}
                                    placeholder={messages.select}
                                    items={OPERATORS}
                                    getItemKey='key'
                                    getItemLabel='label'
                                    popoverClassName='arg-input-popover-no-max-width'
                                    value={operator}
                                    cardinality='one'
                                    size='small'
                                    onChange={(newOperator: Operator) => {
                                        onChange((currentRule) => {
                                            const newValue: MetaPropertyObject = {
                                                meta: {
                                                    [selectedMetaProperty.name]: `${newOperator.key}"${value || ''}"`,
                                                },
                                            };

                                            return immutableSet(currentRule,
                                                getPath(currentPath, objKey),
                                                newValue,
                                            );
                                        });
                                    }}
                                />
                            </>
                        )}
                        {operator && editor}
                    </>
                ) : (
                    <>
                        <ReadOnlyFilterInput
                            className={classNames('&-property-operator')}
                            value={operator?.label}
                            search={search}
                        />
                        {propertyOperator?.key === HAS_META_PROPERTY_KEY && (
                            <>
                                <ReadOnlyFilterInput
                                    className={classNames('&-meta-property')}
                                    value={selectedMetaProperty?.displayName}
                                    search={search}
                                />
                                <div className={classNames('&-that')}>{intl.formatMessage(messages.that)}</div>
                                <ReadOnlyFilterInput
                                    className={classNames('&-meta-property-operator')}
                                    value={operator?.label}
                                    search={search}
                                />
                            </>
                        )}
                        {selectedProperty && (
                            <ReadOnlyFilterInput
                                className={classNames('&-property-value')}
                                value={selectedDropdownItem?.label ?? textValue}
                                search={search}
                            />
                        )}
                    </>
                ))}
        </div>
    );
}

function propertyValuesFromProperty(property: OntologyProperty, universePrefix: string): PropertyValueInfo[] {
    const propertyValues = (property.constraint?.fixedValues || []).map((p) => ({
        key: `universe:${p.value}`,
        label: universePrefix + p.value,
        value: p.value,
    }));

    return propertyValues;
}

function propertyValuesFromMetaProperty(metaProperty: OntologyMetaProperty, universePrefix: string): PropertyValueInfo[] {
    const propertyValues = (metaProperty.constraint?.fixedValues || []).map((p) => ({
        key: `${p.value}`,
        label: universePrefix + p.value,
        value: p.value,
    }));

    return propertyValues;
}

function getPath(currentPath: string, objKey?: string): string {
    return `${currentPath}.object.${objKey}`;
}

