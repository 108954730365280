import { defineMessages } from 'react-intl';

import {
    ArgModal,
    ProgressMonitor,
    useCallbackAsync,
    useArgNotifications,
} from 'src/components/basic';
import { NotifyBox } from 'src/settings/common-modals/notify-box/notify-box';
import { WARNING_ORANGE } from 'src/settings/models/colors';
import ontologiesConnector from 'src/settings/connectors/ontologies-connector';
import { UniverseId } from 'src/exploration/model/universe';

export const messages = defineMessages({
    title: {
        id: 'settings.reset-sync-modal.title',
        defaultMessage: 'Reset the synchronization',
    },
    description: {
        id: 'settings.reset-sync-modal.description',
        defaultMessage: 'This action will trigger a complete rereading of the knowledge base for the universe.',
    },
    cancel: {
        id: 'settings.reset-sync-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.reset-sync-modal.button.reset',
        defaultMessage: 'Reset',
    },
    deleteSyncErrorMsg: {
        id: 'settings.reset-sync-modal.error-message',
        defaultMessage: 'Something went wrong while resetting the synchronization',
    },
    syncResetSuccess: {
        id: 'settings.reset-sync-modal.UniverseResetSuccess',
        defaultMessage: 'The synchronization has been successfully reset',
    },
});


interface ResetSyncModalProps {
    onClose: () => void;
    ontologyName: string;
    universeId: UniverseId;
}

export function ResetSyncModal(props: ResetSyncModalProps) {
    const {
        onClose,
        universeId,
        ontologyName,
    } = props;

    const notifications = useArgNotifications();

    const [handleSubmit, submitting] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            if (!universeId) {
                return;
            }
            await ontologiesConnector.resetSync(universeId, progressMonitor);

            notifications.snackSuccess({ message: messages.syncResetSuccess });

            onClose();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.deleteSyncErrorMsg }, error as Error);
            throw error;
        }
    }, [universeId, notifications, onClose]);

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={onClose}
            onOk={handleSubmit}
            onCancel={onClose}
            loading={submitting?.isRunning}
        >
            <NotifyBox
                icon='icon-exclamation-point'
                text={messages.description}
                subtext={ontologyName}
                color={WARNING_ORANGE}
            />
        </ArgModal>
    );
}
