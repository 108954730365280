import Debug from 'debug';
import { isString } from 'lodash';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { EventEmitter, IEventReceiver, StateId } from '../utils/event-emitter';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { IDisposable } from './disposable';
import { ArgMessageValues } from '../types';

const debug = Debug('basic:cache-repositories:DataCacheRepository');

const messages = defineMessages({
    loadingData: {
        id: 'basic.cache-repositories.LoadingData',
        defaultMessage: 'Loading data {threeDotsLoading}',
    },
});

const DEFAULT_TTL_MS = 0;

const NULL_DATA_INFO: [any, Error | undefined, ProgressMonitor | undefined] = [null, undefined, undefined];

type CacheLifecycleHandler = (key: string, phase: 'init' | 'destroyed') => void;

interface DataWithTTL<T extends object> {
    data?: T | null;
    dataWeakRef?: WeakRef<T>;
    error?: Error | null;
    timestamp: number;
    links: number;
    progressMonitor?: ProgressMonitor;
    lifecycleHandler?: CacheLifecycleHandler;
    stateId?: StateId;

    debugStack?: Error;
}

export type DataLoader<T, K> = (key: string, infos: K, previousValue: T | undefined, progressMonitor: ProgressMonitor) => Promise<T | null>;

let uniqueId = 1;


export interface CacheOptions {
    loadingMessage?: MessageDescriptor;
    messageValues?: ArgMessageValues;
}

export interface IDataCacheRepository<T extends object, K = any> extends IEventReceiver {
    loadPromise(key: string | undefined, infos: K, stateId: StateId | undefined, cacheOptions?: CacheOptions, progressMonitor?: ProgressMonitor): Promise<T | null>;

    dispose(): void;

    load(key: string | undefined, infos: K, stateId: StateId | undefined, cacheOptions?: CacheOptions): [(T | null | undefined), (Error | null | undefined), (ProgressMonitor | undefined)];

    link(key: string): boolean;

    unlink(key: string): boolean;
}

export interface DataCacheRepositoryOptions {
    ttlMs?: number;
    /**
     * Set false allows to keep data even when target is destroyed from garbage collector.
     * @default true
     */
    usesWeakRef?: boolean;
}

export abstract class AbstractDataCacheRepository<T extends object, K = any> extends EventEmitter implements IDisposable, IDataCacheRepository<T, K> {
    protected readonly id: string;
    protected readonly name: string;
    protected readonly options?: DataCacheRepositoryOptions;
    protected readonly ttlMs: number;
    protected readonly dataLoader: DataLoader<T, K>;
    protected disposed = false;

    protected constructor(name: string, dataLoader: DataLoader<T, K>, options?: DataCacheRepositoryOptions) {
        super();
        this.id = `${uniqueId++}`;
        this.name = name;
        this.options = options;
        this.ttlMs = options?.ttlMs || DEFAULT_TTL_MS;
        this.dataLoader = dataLoader;
    }

    loadPromise(key: string | undefined, infos: K, stateId: StateId | undefined, cacheOptions?: CacheOptions, progressMonitor?: ProgressMonitor): Promise<T | null> {
        if (key === undefined) {
            if (isString(infos)) {
                key = infos;
            } else {
                key = JSON.stringify(infos);
            }
        }

        return new Promise((resolve, reject) => {
            if (this.disposed) {
                return reject(new Error('Already disposed'));
            }

            const ret = this.load(key, infos, stateId, cacheOptions);
            if (!ret) {
                return reject(new Error('No data'));
            }

            try {
                progressMonitor?.verifyCancelled();
            } catch (x) {
                return reject(x);
            }

            if (ret[0]) {
                resolve(ret[0]);

                return;
            }

            if (ret[1]) {
                reject(ret[1]);

                return;
            }

            this.once(`loaded:${key}`, (value, error) => {
                if (this.disposed) {
                    return reject(new Error('Already disposed'));
                }

                try {
                    progressMonitor?.verifyCancelled();
                } catch (x) {
                    return reject(x);
                }

                if (error) {
                    return reject(error);
                }

                if (value !== undefined) {
                    return resolve(value);
                }

                reject(new Error('No data'));
            });
        });
    }

    dispose(): void {
        this.disposed = true;
    }

    abstract link(key: string): boolean;

    abstract unlink(key: string): boolean;

    abstract load(key: string | undefined, infos: K, stateId: StateId | undefined, cacheOptions?: CacheOptions): [(T | null | undefined), (Error | null | undefined), (ProgressMonitor | undefined)] ;
}

export class DataCacheRepository<T extends object, K = any> extends AbstractDataCacheRepository<T, K> {
    readonly #intervalId?: any;
    readonly #debugger?: Debug.Debugger;

    #cache: Record<string, DataWithTTL<T>> = {};

    #get = 0;
    #hit = 0;
    #missed = 0;
    #fetching = 0;
    #fetched = 0;
    #errors = 0;
    #canceled = 0;
    #links = 0;
    #unlinks = 0;

    constructor(name: string, dataLoader: DataLoader<T, K>, options?: DataCacheRepositoryOptions) {
        super(name, dataLoader, options);

        if (localStorage.DEBUG_CACHE_REPOSITORY) {
            this.#debugger = localStorage.DEBUG_CACHE_REPOSITORY === '*'
                || localStorage.DEBUG_CACHE_REPOSITORY.split(',').includes(name);
            if (this.#debugger) {
                (window as any).arg_caches = (window as any).arg_caches || {};
                (window as any).arg_caches[`${name}_${this.id}`] = this;
            }
        }

        if (this.ttlMs > 0) {
            this.#intervalId = setInterval(this._handleInterval, this.ttlMs);
        }
    }

    _handleInterval = () => {
        if (this.disposed) {
            return;
        }

        const keys = Object.keys(this.#cache);

        debug('handleInterval', 'keys=', keys);

        const now = Date.now();
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const data = this.#cache[key];

            debug('handleInterval', 'key=', key, 'data=', data);

            if (!data) {
                continue;
            }

            if (data.links > 0) {
                continue;
            }

            if (data.data !== undefined || data.dataWeakRef?.deref() !== undefined) {
                if (now - data.timestamp < this.ttlMs) {
                    continue;
                }
            }

            if (data.progressMonitor) {
                data.progressMonitor.cancel();
            }

            delete this.#cache[key];

            data.lifecycleHandler?.(key, 'destroyed');
        }

        debug('handleInterval', 'processed=', this.#cache);
    };

    dispose() {
        if (this.disposed) {
            return;
        }

        super.dispose();

        if (this.#intervalId) {
            clearInterval(this.#intervalId);
        }

        debug('dispose', 'intervalId=,', this.#intervalId);

        const cache = this.#cache;
        this.#cache = {};

        let destroyedObjectCount = 0;
        if (cache) {
            Object.entries(cache).forEach(([key, info]) => {
                info.lifecycleHandler?.(key, 'destroyed');
                destroyedObjectCount++;
            });
        }

        debug('dispose', 'destroyedObjectCount=,', destroyedObjectCount);
    }

    load(key: string | undefined, infos: K, stateId: StateId | undefined, cacheOptions?: CacheOptions): [(T | null | undefined), (Error | null | undefined), (ProgressMonitor | undefined)] {
        if (this.disposed) {
            throw new Error('Already disposed');
        }
        this.#get++;

        if (key === undefined) {
            if (isString(infos)) {
                key = infos;
            } else {
                key = JSON.stringify(infos);
            }
        }

        let info: DataWithTTL<T> | undefined = this.#cache[key];

        let previousValue: T | undefined = undefined;
        if (info) {
            previousValue = info.data || undefined;
            if (!previousValue && info.dataWeakRef) {
                previousValue = info.dataWeakRef?.deref();
            }

            if (info.stateId !== stateId) {
                //info?.lifecycleHandler?.(key, 'destroyed');
                info = undefined;
            }
        }

        let weakData: T | undefined = undefined;

        if (info) {
            if (info.error) {
                return [undefined, info.error, undefined];
            }
            if (info.progressMonitor?.isRunning) {
                return [undefined, undefined, info.progressMonitor];
            }
            if (info.data === null) {
                return NULL_DATA_INFO;
            }

            if (info.dataWeakRef) {
                weakData = info.dataWeakRef.deref();
            }

            if (info.data === undefined && weakData === undefined) {
                //info?.lifecycleHandler?.(key, 'destroyed');

                info = undefined;
            }
        }

        if (info) {
            info.timestamp = Date.now();

            debug('load', 'load key=', key, 'info=', info);

            let data = info.data;
            if (data === undefined) {
                data = weakData;
            }

            if (data) {
                this.#hit++;
            } else {
                this.#missed++;
            }

            return [data, info.error, info.progressMonitor];
        }

        this.#fetching++;

        const progressMonitor = new ProgressMonitor(
            cacheOptions?.loadingMessage || messages.loadingData,
            1,
            { messageValues: cacheOptions?.messageValues },
        );
        this.emit(`loading:${key}`, progressMonitor);

        info = {
            progressMonitor,
            links: 1,
            timestamp: Date.now(),
            stateId,
            data: undefined,
        };
        this.#cache[key] = info;

        info?.lifecycleHandler?.(key, 'init');

        if (this.#debugger) {
            info.debugStack = new Error();
        }

        debug('load', 'create key=', key, 'info=', info);

        this.dataLoader(key, infos, previousValue, progressMonitor).then((data) => {
            this.#fetched++;
            if (data === null || data === undefined) {
                info!.data = null;
            } else {
                if (this.options?.usesWeakRef !== false) {
                    info!.dataWeakRef = new WeakRef<T>(data);
                }
                info!.data = data;
            }
            info!.error = null;
            info!.progressMonitor = undefined;

            this.emit(`loaded:${key}`, data, null);
        }).catch((error) => {
            if (progressMonitor.isCancelled) {
                this.#canceled++;

                return;
            }

            this.#errors++;
            info!.data = null;
            info!.error = error;
            info!.progressMonitor = undefined;

            this.emit(`loaded:${key}`, null, error);
        }).finally(() => {
            progressMonitor.done();
            delete info!.progressMonitor;
            info!.links--;
            this.#fetching--;

            debug('load', 'loaded key=', key, 'info=', info);
        });

        return [undefined, undefined, progressMonitor];
    }

    unlink(key: string): boolean {
        if (this.disposed) {
            return false;
        }

        this.#unlinks++;

        const info = this.#cache[key];

        debug('unlink', 'key=', key, 'beforeUnlink=', info.links, 'info=', info);

        if (!info) {
            console.error('Unknown data key=', key);

            return false;
        }
        if (info.links < 1) {
            console.error('No linked data key=', key, 'linkCount=', info.links);

            return false;
        }
        info.timestamp = Date.now();
        info.links--;

        if (info.links > 0) {
            return true;
        }

        if (info.dataWeakRef && info.data !== null) {
            // Clear strong reference, keep weak reference
            info.data = undefined;
        }

        return true;
    }

    link(key: string): boolean {
        if (this.disposed) {
            throw new Error('Already disposed');
        }

        this.#links++;

        const info = this.#cache[key];

        debug('link', 'key=', key, 'beforeLink=', info.links, 'info=', info);

        if (!info) {
            console.error('Unknown data key=', key);

            return false;
        }

        info.links++;
        info.timestamp = Date.now();
        if (info.data === undefined && info.dataWeakRef) {
            // Fill strong reference with weak reference
            info.data = info.dataWeakRef.deref();
            if (info.data === undefined) {
                console.error('**** DATA is LOST !');

                return false;
            }
        }

        return true;
    }

    get stats() {
        const ret = {
            id: this.id,
            name: this.name,
            ttlMs: this.ttlMs,
            disposed: this.disposed,

            date: new Date(),

            get: this.#get,
            hit: this.#hit,
            missed: this.#missed,
            fetching: this.#fetching,
            fetched: this.#fetched,
            errors: this.#errors,
            canceled: this.#canceled,
            links: this.#links,
            unlinks: this.#unlinks,
        };

        return ret;
    }
}
