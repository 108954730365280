import { RtMessageEventList } from '../events';

export const FOLDERS_INFO_EVENT_NAMES: RtMessageEventList = {
    'CaseDeleted': true,
    'CaseCreated': true,
    'CasePermissionsChanged': true,
    'CaseUpdated': true,
};

export const FOLDERS_EVENT_NAMES: RtMessageEventList = {
    ...FOLDERS_INFO_EVENT_NAMES,
    'CaseVisited': true,
};

export const FOLDER_ENTITY_CONTENT_EVENT_NAMES: RtMessageEventList = {
    'ArgonosPieceCreated': true,
    'ArgonosPieceMetadataUpdated': true, // Name + Description
    'ArgonosPieceDeleted': true,
};

export const FOLDER_OBJECT_EVENT_NAMES: RtMessageEventList = {
    'CasePermissionsChanged': true,
    'CaseDeleted': true,
    'CaseUpdated': true,
    ...FOLDER_ENTITY_CONTENT_EVENT_NAMES,
    //    'CasePieceVisited': true,
};

export const FOLDER_ENTITY_VISITED_EVENT_NAMES: RtMessageEventList = {
    'ArgonosPieceVisited': true, // Date de visite
};

export const FOLDER_CONNECTIONS_EVENT_NAMES: RtMessageEventList = {
    'CaseUsersConnected': true,
};

export const FOLDER_FLAGS_EVENT_NAMES: RtMessageEventList = {
    'FlaggedObjectsUpdated': true,
};

export const FOLDER_ACTIVITIES_EVENT_NAMES: RtMessageEventList = {
    'CaseActivityUpdated': true,
};

export const FOLDER_SETTINGS_EVENTS: RtMessageEventList = {
    'CaseSettingsUpdated': true,
    'CaseSettingsDeleted': true,
};

export const FOLDER_USER_SETTINGS_EVENTS: RtMessageEventList = {
    'CaseUserSettingsUpdated': true,
    'CaseUserSettingsDeleted': true,
};

export const FOLDERS_EVENTS = {
    ...FOLDERS_EVENT_NAMES,
    ...FOLDER_OBJECT_EVENT_NAMES,
    ...FOLDER_CONNECTIONS_EVENT_NAMES,
    ...FOLDER_FLAGS_EVENT_NAMES,
    ...FOLDER_ENTITY_VISITED_EVENT_NAMES,
    ...FOLDER_ACTIVITIES_EVENT_NAMES,
    ...FOLDER_SETTINGS_EVENTS,
    ...FOLDER_USER_SETTINGS_EVENTS,
};

export const ENTITY_SETTINGS_EVENTS: RtMessageEventList = {
    'ArgonosPieceSettingsUpdated': true,
    'ArgonosPieceSettingsDeleted': true,
};

export const ENTITY_PROPERTIES_EVENTS: RtMessageEventList = {
    'ArgonosPieceEntityUpdated': true,
};

export const ENTITY_EVENTS: RtMessageEventList = {
    'ArgonosPieceCreated': true,
    'ArgonosPieceDeleted': true,
    'ArgonosPieceMetadataUpdated': true,
    ...ENTITY_PROPERTIES_EVENTS,
};

export const ENTITY_USER_SETTINGS_EVENTS: RtMessageEventList = {
    'ArgonosPieceUserSettingsUpdated': true,
    'ArgonosPieceUserSettingsDeleted': true,
};

export const ENTITY_CONNECTIONS_EVENTS: RtMessageEventList = {
    'ArgonosPieceUsersConnected': true,
};

export const ENTITY_ALL_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
    ...ENTITY_SETTINGS_EVENTS,
    ...ENTITY_USER_SETTINGS_EVENTS,
    ...ENTITY_CONNECTIONS_EVENTS,
    ...ENTITY_PROPERTIES_EVENTS,
};
