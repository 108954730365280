import { flatMap, groupBy, keyBy } from 'lodash';

import { getDataExplorationApi } from '../../../utils/connectors/api-url';
import { ArgonosPieceConnector } from '../../../utils/connectors/argonos-casepiece-connector';
import { FormCasePiece } from '../../model/form';
import { ProgressMonitor, SubProgressMonitor } from '../../../components/basic';
import { createFilterFromObjectId } from '../constraints';
import { UniverseConnector } from './universe-connector';
import { VertexUtils } from '../vertex-utils';
import { CasePieceType } from '../../../model/case-piece-type';
import { EntityId } from '../../../model/argonos-piece';
import { FolderId } from '../../../model/folder';
import { BasicCasePiece } from '../../../model/basic-case-piece';

export class ExplorationPieceConnector extends ArgonosPieceConnector {
    private static instance: ExplorationPieceConnector;

    static getInstance(): ExplorationPieceConnector {
        if (!ExplorationPieceConnector.instance) {
            ExplorationPieceConnector.instance = new ExplorationPieceConnector('exploration.pieced', getDataExplorationApi());
        }

        return ExplorationPieceConnector.instance;
    }

    async applyFormCasePiecePolicies(formCasePieces: FormCasePiece[], progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const formCasePiecesByUniverseId = groupBy(formCasePieces, formCasePiece => formCasePiece.universeId);
        const promises = Object.entries(formCasePiecesByUniverseId).map(async ([universeId, universeFormCasePieces]) => {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            const vertexIds = universeFormCasePieces.map(formCasePiece => formCasePiece.vertexInfo.id);
            const filter = createFilterFromObjectId(vertexIds);

            const universeVertices = await UniverseConnector.getInstance().getUniverseVertices(universeId, filter, vertexIds.length, undefined, sub1);
            const universeVertexById = keyBy(universeVertices, universeVertex => universeVertex.id);
            universeFormCasePieces.forEach(universeFormCasePiece => {
                universeFormCasePiece.displayName = VertexUtils.getTitle(undefined, universeVertexById[universeFormCasePiece.vertexInfo.id]?.title);
            });
        });

        await Promise.allSettled(promises);
    }

    override async getAllPieces(pieceTypes: CasePieceType[], withUserSettings?: boolean, withSettings?: boolean, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const result = await super.getAllPieces(pieceTypes, withUserSettings, withSettings, sub1);

        const formCasePieces = flatMap(Object.values(result))
            .filter(casePiece => casePiece.type === CasePieceType.Form) as FormCasePiece[];
        if (formCasePieces.length) {
            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await this.applyFormCasePiecePolicies(formCasePieces, sub2);
        }

        return result;
    }

    override async getPiece(folderId: FolderId, entityId: EntityId, withUserSettings?: boolean, withSettings?: boolean, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const result = await super.getPiece(folderId, entityId, withUserSettings, withSettings, sub1);

        if (result.type === CasePieceType.Form) {
            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            const formCasePiece = result as FormCasePiece;
            await this.applyFormCasePiecePolicies([formCasePiece], sub2);
        }

        return result;
    }

    override async getArgonosPiecesById(
        piecesId: EntityId[],
        withUserSettings: true,
        withSettings: true,
        progressMonitor?: ProgressMonitor,
    ): Promise<BasicCasePiece[]>;

    override async getArgonosPiecesById(
        piecesId: EntityId[],
        withUserSettings?: boolean,
        withSettings?: boolean,
        progressMonitor?: ProgressMonitor,
    ): Promise<BasicCasePiece[]>;

    override async getArgonosPiecesById(
        piecesId: EntityId[],
        withUserSettings?: boolean,
        withSettings?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<BasicCasePiece[]> {
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const result = await super.getArgonosPiecesById(piecesId, withUserSettings, withSettings, sub1);
        const formCasePieces = result.filter(casePiece => casePiece.type === CasePieceType.Form) as FormCasePiece[];
        if (formCasePieces.length) {
            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await this.applyFormCasePiecePolicies(formCasePieces, sub2);
        }

        return result;
    }
}
