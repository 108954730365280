import { defineMessages } from 'react-intl';

import { RolesScope } from '../models/dtoApi';
import { ArgonosModulesRegistry } from '../../components/application/argonos-modules-registry';
import { ArgonosModule } from '../../components/application/modules';

const messages = defineMessages({
    settingsApplication: {
        id: 'settings.roles.scopes.Settings',
        defaultMessage: 'Settings',
    },
});

export function getTagIcon(rolesScope: RolesScope) {
    const argonosModule = ArgonosModulesRegistry.getInstance().list().find((m: ArgonosModule) => m.scope === rolesScope).value();

    return argonosModule?.iconURL || '';
}

export function getTagBackgroundColor(rolesScope: RolesScope) {
    const argonosModule = ArgonosModulesRegistry.getInstance().list().find((m: ArgonosModule) => m.scope === rolesScope).value();

    if (argonosModule?.color) {
        return argonosModule.color;
    }

    const result = ArgonosModulesRegistry.getInstance().getById('chapsVision.Settings')?.color;

    return result;
}

export function getScopeDisplayName(rolesScope: RolesScope) {
    const argonosModule = ArgonosModulesRegistry.getInstance().list().find((m: ArgonosModule) => m.scope === rolesScope).value();

    if (argonosModule?.name) {
        return argonosModule.name;
    }

    return messages.settingsApplication;
}
