import React, { SetStateAction, useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ListControlProps, ListControlValue, PropertyInfo } from './controls-type';
import { PickListControl } from './picklist-control';
import { ArgChangeReason } from '../../basic';

type BooleanFieldControlProps = ListControlProps<boolean>;

const messages = defineMessages({
    falseLabel: {
        id: 'common.controls.boolean-field.False',
        defaultMessage: 'False',
    },
    trueLabel: {
        id: 'common.controls.boolean-field.True',
        defaultMessage: 'True',
    },
    unknownLabel: {
        id: 'common.controls.boolean-field.Undefined',
        defaultMessage: 'Unknown',
    },
});

export function BooleanFieldControl2(props: BooleanFieldControlProps) {
    const {
        className,
        propertyName,
        propertyDisplayName,
        value,
        onChange,
        propertyInfo,
        propertyInfoLoading,
        onReset,
        acceptsParameters,
        readOnly,
    } = props;

    const intl = useIntl();

    const falseLabel = intl.formatMessage(messages.falseLabel);
    const trueLabel = intl.formatMessage(messages.trueLabel);
    const unknownLabel = intl.formatMessage(messages.unknownLabel);

    const transformStringToBoolean = useCallback((v: string | null): boolean | null => {
        switch (v) {
            case falseLabel:
                return false;

            case trueLabel:
                return true;

            default:
                return null;
        }
    }, [falseLabel, trueLabel]);

    const transformBooleanToString = useCallback((b: boolean | null): string | null => {
        switch (b) {
            case true:
                return trueLabel;

            case false:
                return falseLabel;

            default:
                return null;
        }
    }, [trueLabel, falseLabel]);

    const sortPriorities = useMemo(() => {
        return {
            [trueLabel]: 10,
            [falseLabel]: 8,
            [unknownLabel]: 0,
        };
    }, [trueLabel, falseLabel, unknownLabel]);


    const stringValue = useMemo<ListControlValue<string> | null>(() => {
        const newValue: (string | null)[] | undefined = value?.fixed?.map((v) => transformBooleanToString(v));

        return { ...value, fixed: newValue };
    }, [value, transformBooleanToString]);

    const stringOnChange = useCallback((newValue: (ListControlValue<string> | null | SetStateAction<ListControlValue<string> | null>), reason?: ArgChangeReason) => {
        if (!newValue) {
            onChange(newValue, reason);

            return;
        }

        if (typeof (newValue) === 'function') {
            onChange((prev) => {
                let prevStringList: (string | null)[] | undefined;
                if (prev) {
                    prevStringList = prev.fixed?.map((v) => transformBooleanToString(v));
                } else {
                    prevStringList = undefined;
                }

                const newStringList = newValue({ ...prev, fixed: prevStringList });

                if (!newStringList) {
                    return { ...prev, fixed: [] };
                }

                const newBooleanList = newStringList.fixed?.map((v) => transformStringToBoolean(v));

                return { ...prev, parameter: newStringList.parameter, fixed: newBooleanList };
            }, reason);

            return;
        }

        const nv = newValue.fixed?.map((v) => transformStringToBoolean(v));
        onChange({ ...newValue, fixed: nv }, reason);
    }, [onChange, transformStringToBoolean, transformBooleanToString]);

    const stringPropertyInfo = useMemo<PropertyInfo<string> | undefined>(() => {
        if (!propertyInfo) {
            return undefined;
        }

        const np: PropertyInfo<string> = {
            numberOfMissing: propertyInfo.numberOfMissing,
            property: propertyInfo.property,
            numberOfValues: propertyInfo.numberOfValues,
        };

        if (propertyInfo.facets) {
            np.facets = [{
                value: trueLabel,
                numberOfVertices: propertyInfo.facets.find((f) => (f.value === true))?.numberOfVertices ?? 0,
            }, {
                value: falseLabel,
                numberOfVertices: propertyInfo.facets.find((f) => (f.value === false))?.numberOfVertices ?? 0,
            }];
        }

        return np;
    }, [propertyInfo, falseLabel, trueLabel]);

    return (
        <PickListControl
            className={className}
            propertyName={propertyName}
            propertyDisplayName={propertyDisplayName}
            value={stringValue}
            onChange={stringOnChange}
            undefinedLabel={unknownLabel}
            sortPriorities={sortPriorities}
            onReset={onReset}
            propertyInfo={stringPropertyInfo}
            propertyInfoLoading={propertyInfoLoading}
            acceptsParameters={acceptsParameters}
            readOnly={readOnly}
        />
    );
}
