import { defineMessages } from 'react-intl';

import { useCallbackAsync, useClassNames, useArgNotifications } from 'src/components/basic';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import WebhookConnector from 'src/settings/connectors/webhook-connector';
import { DetailedWebHook } from 'src/settings/models/detailed-webhooks';
import { ArgonosModuleId } from 'src/components/application/modules';

import './webhooks-deletion-modal.less';

const messages = defineMessages({
    deleteTitle: {
        id: 'settings.webhooks.deletion-modal.deleteTitle',
        defaultMessage: 'Delete this webhook',
    },
    deleteMessage: {
        id: 'settings.webhooks.deletion-modal.deleteMessage',
        defaultMessage: 'Are you sure you want to delete the webhook "{name}"?',
    },
    deleteMultipleTitle: {
        id: 'settings.webhooks.deletion-modal.deleteMultipleTitle',
        defaultMessage: 'Delete {count} webhooks',
    },
    deleteMultipleMessage: {
        id: 'settings.webhooks.deletion-modal.deleteMultipleMessage',
        defaultMessage: 'Are you sure you want to delete these {count} webhooks?',
    },
    deleteError: {
        id: 'settings.webhooks.deletion-modal.deleteError',
        defaultMessage: 'An error occurred while deleting the {count, plural, one {webhook} other {webhooks}}',
    },
    delete: {
        id: 'settings.webhooks.deletion-modal.delete',
        defaultMessage: 'Delete',
    },
});

interface WebhooksDeletionModalProps {
    webhooks: DetailedWebHook[];
    onSuccess?: () => void;
    onClose: () => void;
    argonosModule: ArgonosModuleId;
}

export function WebhooksDeletionModal(props: WebhooksDeletionModalProps) {
    const { webhooks, onClose, onSuccess, argonosModule } = props;

    const classNames = useClassNames('settings-webhooks-deletion-modal');
    const notifications = useArgNotifications();

    const [onDelete] = useCallbackAsync(async (progressMonitor) => {
        try {
            if (webhooks.length === 1) {
                const webhookId = webhooks[0].id;
                await WebhookConnector.getInstance().deleteWebhook(argonosModule, webhookId, progressMonitor);
            } else {
                const webhookIds = webhooks.map((webhook) => webhook.id);
                await WebhookConnector.getInstance().deleteWebhooks(argonosModule, webhookIds, progressMonitor);
            }
            onSuccess?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.deleteError }, error as Error, { count: webhooks.length });
            throw error;
        }
    }, [onSuccess, webhooks, argonosModule]);

    const title = props.webhooks.length > 1 ? messages.deleteMultipleTitle : messages.deleteTitle;
    const message = props.webhooks.length > 1 ? messages.deleteMultipleMessage : messages.deleteMessage;

    const messageValues = {
        count: props.webhooks.length,
        name: props.webhooks.length === 1 ? props.webhooks[0].name : undefined,
    };

    return (
        <ConfirmModal
            type='delete'
            title={title}
            alertMessage={message}
            messageValues={messageValues}
            onConfirm={onDelete}
            onClose={onClose}
            confirmText={messages.delete}
        />
    );
}
