import { SECRET_EVENTS } from './events';
import { RtMessageEventList } from '../../utils/rt-states/events';
import { EntityState } from '../../utils/rt-states/folders/entity-state';
import { getDataPreparationEntitiesRtApi } from './rt-apis';
import { SecretId } from '../secrets/secrets';

export class SecretState extends EntityState {
    constructor(url: string, secretId: SecretId, events: RtMessageEventList = SECRET_EVENTS) {
        super(getDataPreparationEntitiesRtApi(), url, secretId, events);
    }
}
