import L from 'leaflet';
import { defaultTo } from 'lodash';
import { useMap } from 'react-leaflet';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { DEFAULT_MAP_ZOOM } from '../arg-map';
import { ClassValue, useClassNames } from '../../../../arg-hooks/use-classNames';
import { ArgZoomToolbox } from '../../../../arg-zoom-toolbox/arg-zoom-toolbox';
import { KeyBindingDescriptor } from '../../../../keybindings/keybinding';

import './arg-map-zoom-toolbox.less';

interface ArgMapZoomToolboxProps {
    className?: ClassValue;
    zoom?: number;
    onChange: (zoom?: number) => void;
    zoomInKeyBinding?: KeyBindingDescriptor;
    zoomOutKeyBinding?: KeyBindingDescriptor;
}

export function ArgMapZoomToolbox(props: ArgMapZoomToolboxProps) {
    const {
        className,
        onChange,
        zoom,
        zoomInKeyBinding,
        zoomOutKeyBinding,
    } = props;

    const classNames = useClassNames('arg-map-zoom-toolbox');
    const map = useMap();

    const mapZoomToolBoxContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (mapZoomToolBoxContainer.current) {
            L.DomEvent.disableClickPropagation(mapZoomToolBoxContainer.current);
        }
    }, []);

    const mapMinZoom = useMemo(() => map.getMinZoom(), [map]);
    const mapMaxZoom = useMemo(() => map.getMaxZoom(), [map]);

    const currentZoom: number = useMemo(() => (
        defaultTo(zoom, defaultTo(map.getZoom(), DEFAULT_MAP_ZOOM))
    ), [zoom, map]);

    const onZoom = useCallback(() => {
        onChange(map.getZoom());
    }, [map, onChange]);

    useEffect(() => {
        map.on('zoom', onZoom);

        return () => {
            map.off('zoom', onZoom);
        };
    }, [map, onZoom]);

    const handleZoomIn = () => {
        if (currentZoom < mapMaxZoom) {
            map.setZoom(currentZoom + 1);
        }
    };

    const handleZoomOut = () => {
        if (currentZoom > mapMinZoom) {
            map.setZoom(currentZoom - 1);
        }
    };

    const onZoomChange = (reason: 'in' | 'out') => {
        if (reason === 'in') {
            handleZoomIn();

            return;
        }

        if (reason === 'out') {
            handleZoomOut();

            return;
        }

        throw new Error('Reason of zoom should be "in" or "out"');
    };

    return (
        <div ref={mapZoomToolBoxContainer}
             className={classNames('&', className)}
        >
            <ArgZoomToolbox
                onZoom={onZoomChange}
                zoom={zoom}
                minZoom={mapMinZoom}
                maxZoom={mapMaxZoom}
                zoomInKeyBinding={zoomInKeyBinding}
                zoomOutKeyBinding={zoomOutKeyBinding}
                tooltipPlacement='right'
            />
        </div>
    );
}
