import { useCallback } from 'react';
import { cloneDeep, set } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';

import { ArgIcon, useClassNames } from 'src/components/basic';
import { RuleTarget, SchemaObject } from '../../../../models/policy';
import { isEmptyObject } from '../../policy-utils';

import './targets-add-filter-buttons.less';

const messages = defineMessages({
    addCriteria: {
        id: 'settings.policy-rule-block-target.add-criteria',
        defaultMessage: 'Criteria',
    },
    addGroup: {
        id: 'settings.policy-rule-block-target.add-group',
        defaultMessage: 'Group',
    },
});

const DEFAULT_SCHEMA_OBJECT: SchemaObject = { _kind: undefined, _type: undefined };

interface TargetsAddFilterButtonsProps<T> {
    currentPath: string;
    target?: RuleTarget;
    blockTargets?: RuleTarget[];
    operator?: 'and' | 'or';
    onChange: React.Dispatch<React.SetStateAction<T>>;
    schemaObject?: SchemaObject;
}

export function TargetsAddFilterButtons<T extends { Targets: RuleTarget[] }>({
    currentPath,
    target,
    blockTargets,
    operator,
    onChange,
    schemaObject = DEFAULT_SCHEMA_OBJECT,
}: TargetsAddFilterButtonsProps<T>) {
    const intl = useIntl();
    const classNames = useClassNames('settings-targets-add-filter-buttons');

    const addCriteria = useCallback(() => {
        onChange((currentRule) => {
            if (blockTargets) {
                return set(cloneDeep(currentRule), currentPath, [
                    ...blockTargets,
                    { object: schemaObject },
                ]);
            }

            if (target) {
                return set(cloneDeep(currentRule), currentPath, {
                    and: [
                        isEmptyObject(target)
                            ? { object: schemaObject }
                            : target,
                        { object: schemaObject },
                    ],
                });
            }

            return currentRule;
        });
    }, [blockTargets, currentPath, onChange, target, schemaObject]);

    const addGroup = useCallback(() => {
        onChange((currentRule) => {
            if (!blockTargets) return currentRule;

            const newOperator = operator === 'and' ? 'or' : 'and';

            return set(cloneDeep(currentRule), currentPath, [
                ...blockTargets,
                { [newOperator]: [{ object: schemaObject }] },
            ]);
        });
    }, [blockTargets, currentPath, onChange, operator, schemaObject]);

    return (
        <div className={classNames('&-container')}>
            <div className={classNames('&-icon')} onClick={() => addCriteria()}>
                <ArgIcon name='icon-add-outline' />
                <div>{intl.formatMessage(messages.addCriteria)}</div>
            </div>
            {blockTargets && (
                <div className={classNames('&-icon')} onClick={() => addGroup()}>
                    <ArgIcon name='icon-add-outline' />
                    <div>{intl.formatMessage(messages.addGroup)}</div>
                </div>
            )}
        </div>
    );
}
