import { ReactNode, useCallback, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { compact, isNil, isString, lowerCase, size, values } from 'lodash';

import {
    ArgButton,
    ArgGetItemLabel,
    ArgInputSearch,
    ArgInputSearchType,
    ArgMenu,
    ArgMenuItem,
    ArgPlaceholderText,
    ArgRenderedText,
    ArgSubMenu,
    ArgTable2,
    ArgTable2Column,
    ArgUploaderButton,
    ClassValue,
    DraggableAction,
    filterItems,
    getTableCellValue,
    isPromise,
    KeyBindingDescriptor,
    normalizeText,
    ProgressMonitor,
    renderText,
    SelectionProvider,
    SelectionSource,
    UploadMethod,
    useClassNames,
} from '../../basic';
import { LoadingPane } from '../../common/panes/loading-pane';
import { EmptyPane } from '../../common/panes/empty-pane';
import { useArgTable2Configuration } from '../../../hooks/use-arg-table2-configuration';
import { formatPartialCount } from 'src/utils/formatter';

import './overview-section.less';

const FORCE_LOADING = false;
const FORCE_EMPTY = false;

export const OVERVIEW_SECTION_TABLE_ROW_HEIGHT = 48;

const messages = defineMessages({
    magnifierPlaceholder: {
        id: 'common.overview.sections.columns.SearchPlaceholder',
        defaultMessage: 'Search ...',
    },
    createButton: {
        id: 'common.overview.sections.columns.CreateButton',
        defaultMessage: 'New',
    },
    importButton: {
        id: 'common.overview.sections.columns.ImportButton',
        defaultMessage: 'Import',
    },
    emptyMessage: {
        id: 'common.overview.sections.columns.EmptyMessage',
        defaultMessage: 'No Folder',
    },
    maximize: {
        id: 'common.overview-section.action.MaximizeLabel',
        defaultMessage: 'Maximize',
    },
    minimize: {
        id: 'common.overview-section.action.MinimizeLabel',
        defaultMessage: 'Minimize',
    },
});

export interface ActionItem {
    key: string;
    label: ArgRenderedText;
    isDisabled?: boolean;
    children?: ActionItem[];
    tooltip?: ArgRenderedText;
}

export interface OverviewSectionProps<T> {
    id?: string;
    emptyMessage?: MessageDescriptor;
    title?: ReactNode | MessageDescriptor;
    hideTitle?: boolean;
    className?: ClassValue;
    headerCls?: ClassValue;
    tableHeaderCls?: ClassValue;
    allowCreate?: boolean;
    disableCreate?: boolean;
    allowImport?: boolean;
    disableImport?: boolean;
    minimizedNbRows?: number;
    onPressCreate?: () => any;
    onPressImport?: UploadMethod;
    items?: T[];
    columns: Record<string, ArgTable2Column<T> | undefined> | null;
    creationProgressMonitor?: ProgressMonitor;
    importProgressMonitor?: ProgressMonitor;
    onRowClick?: (row: T) => void;
    onRowDoubleClick?: (row: T) => void;
    rowKey?: string | ((casePiece: T) => string);
    selectionProvider?: SelectionProvider<T>;
    selectionSource?: SelectionSource;
    icon?: 'vertexType' | 'origin';
    type?: 'list' | 'grid';
    emptyPane?: ReactNode;
    additionalTitle?: ReactNode;
    loadingData?: boolean;
    loadingMessage?: MessageDescriptor;
    searchKeyBinding?: KeyBindingDescriptor;
    createKeyBinding?: KeyBindingDescriptor;
    createButtonLabel?: ArgRenderedText;
    createButtonTooltip?: MessageDescriptor;
    importKeyBinding?: KeyBindingDescriptor;
    importButtonLabel?: ArgRenderedText;
    importButtonTooltip?: MessageDescriptor;
    getItemLabel?: ArgGetItemLabel<T>;
    hideSearchBar?: boolean;
    externalSearch?: string;
    userConfigurationPath?: string;
    onSearchInputChange?: (value?: string) => void;
    renderGridItem?: (item: T) => ReactNode;
    rowSelected?: ((row: T) => boolean);
    action?: {
        actionsList: ActionItem[];
        onAction: (action: string) => void|Promise<void>;
        isDisabled?: boolean;
        disableMessage?: ArgRenderedText;
    };
    searchPlaceholder?: ArgPlaceholderText;
    searchType?: ArgInputSearchType;
    rowDraggableAction?: (item: T) => DraggableAction[] | DraggableAction | undefined;
    rowHeight?: number;
    headerHeight?: number;
}

export function OverviewSection<T>(props: OverviewSectionProps<T>) {
    const {
        title,
        hideTitle,
        allowCreate,
        disableCreate,
        allowImport,
        disableImport,
        onPressImport,
        type = 'list',
        id,
        selectionProvider,
        selectionSource,
        items,
        columns,
        minimizedNbRows,
        emptyMessage,
        onPressCreate,
        creationProgressMonitor,
        importProgressMonitor,
        onRowClick,
        onRowDoubleClick,
        loadingData,
        rowKey,
        className,
        headerCls,
        tableHeaderCls,
        emptyPane,
        additionalTitle,
        loadingMessage,
        searchKeyBinding,
        createKeyBinding,
        createButtonTooltip,
        createButtonLabel,
        importKeyBinding,
        importButtonTooltip,
        importButtonLabel,
        getItemLabel,
        renderGridItem,
        hideSearchBar = false,
        externalSearch,
        userConfigurationPath,
        rowSelected,
        action,
        searchPlaceholder,
        searchType,
        onSearchInputChange,
        rowDraggableAction,
        rowHeight,
        headerHeight,
    } = props;

    const classNames = useClassNames('common-overview-section');

    const intl = useIntl();

    const [search, _setSearch] = useState<string | undefined>();
    const setSearch = useCallback((value?: string) => {
        _setSearch(value);
        onSearchInputChange?.(value);
    }, [onSearchInputChange]);

    const [isMaximized, setIsMaximized] = useState<boolean>();

    const toggleIsMaximized = useCallback(() => {
        setIsMaximized((prevState => !prevState));
    }, []);

    const myColumns = useMemo<ArgTable2Column<T>[]>(() => {
        let cols = compact(values(columns));

        if (!minimizedNbRows) {
            return cols;
        }

        cols = cols.map((column: ArgTable2Column<T>) => {
            if (column.key === 'actions' && items && items.length > minimizedNbRows) {
                column = {
                    ...column,
                    title: <ArgButton
                        type='link'
                        onClick={toggleIsMaximized}
                        label={isMaximized ? messages.minimize : messages.maximize}
                    />,
                };
            }

            return column;
        });

        return cols;
    }, [columns, intl, isMaximized, items, minimizedNbRows, toggleIsMaximized]);

    const getCellValue = useCallback((column: ArgTable2Column<T>, row: T, rowIndex: number, search?: string) => {
        return getTableCellValue(column, row, rowIndex, intl, search);
    }, [intl]);

    const { handleSortColumnChange, userColumns } = useArgTable2Configuration<T>(userConfigurationPath || '', myColumns);

    const filteredItems = useMemo(() => {
        let filteredDataSource = items || [];

        if (search && items) {
            const normalizedSearch = normalizeText(search);

            let internalGetItemLabel = getItemLabel;
            if (!internalGetItemLabel) {
                // Create a getItemLabel for all columns returning a string
                internalGetItemLabel = (item: T) => {
                    const rowIndex = items.indexOf(item);

                    return userColumns.map(column => {
                        const value = column.getCellStringValue?.(item) || getCellValue(column, item, rowIndex, normalizedSearch);

                        if (isString(value)) {
                            return value;
                        }

                        return '';
                    }).join(' ');
                };
            }

            filteredDataSource = filterItems<T>(filteredDataSource, normalizedSearch, internalGetItemLabel, intl);
        }

        return filteredDataSource;
    }, [getCellValue, getItemLabel, intl, items, search, userColumns]);

    const isNotEmptyList = (items?.length ?? 0) > 0;

    const cls: ClassValue = {};
    let body;
    if (!FORCE_EMPTY && (FORCE_LOADING || loadingData || items === undefined)) {
        cls.loading = true;
        body = <div className={classNames('&-body-loading')}>
            <LoadingPane
                className={classNames('&-body-loading-pane', 'fill')}
                message={loadingMessage}
                size='medium'
            />
        </div>;
    } else if (!FORCE_EMPTY && items?.length && columns && (filteredItems.length > 0 || !emptyMessage)) {
        body = <ArgTable2<T>
            key='table'
            search={externalSearch ?? search}
            rowKey={rowKey}
            selectionSource={selectionSource}
            highlightedRowCondition={rowSelected}
            selectionProvider={selectionProvider}
            rowHeight={rowHeight ?? OVERVIEW_SECTION_TABLE_ROW_HEIGHT}
            headerHeight={headerHeight}
            getItemLabel={getItemLabel}
            dataSource={items}
            minimizedNbRows={!isMaximized ? minimizedNbRows : undefined}
            className={classNames('&-body-table')}
            columns={userColumns}
            onRowClick={onRowClick}
            onRowDoubleClick={onRowDoubleClick}
            onSortColumnChange={handleSortColumnChange}
            headerClassName={tableHeaderCls}
            rowDraggableAction={rowDraggableAction}
        />;
    } else if (!columns && items && type === 'grid' && renderGridItem) {
        const grid = items.map(item => renderGridItem(item));

        body = <div className={classNames('&-grid-container')}>{grid}</div>;
    } else {
        cls.empty = true;
        body = emptyPane ? emptyPane
            : <EmptyPane
                key='empty'
                className={classNames('&-body-empty', 'fill')}
                icon='folder'
                message={emptyMessage || messages.emptyMessage}
                size='medium'
            />;
    }
    let count: ReactNode = null;
    if (search) {
        // @TODO  compute with search parameter !
        count = formatPartialCount(size(items), !isMaximized, minimizedNbRows);
    } else {
        count = formatPartialCount(size(items), !isMaximized, minimizedNbRows);
    }

    const _title = renderText(title, {
        count,
        grey: function SectionTitleCount(content: ReactNode) {
            if (loadingData) {
                return null;
            }

            return (
                <span className={classNames('&-header-count')}>
                    {content}
                </span>
            );
        },
    });

    const _headerCls = {
        'is-header-empty': hideTitle && (!isNotEmptyList || hideSearchBar),
    };

    const [actionsPopoverVisible, setActionsPopoverVisible] = useState(false);

    const _searchPlaceholder = useMemo(() => {
        if (searchPlaceholder) {
            return searchPlaceholder;
        }

        if (searchType === 'filter') {
            return undefined;
        }

        return messages.magnifierPlaceholder;
    }, [searchPlaceholder, searchType]);

    const renderActionMenu = useCallback(() => {
        return (
            <ArgMenu
                data-testid='actions-menu'
                onClick={(info) => {
                    const p = action!.onAction(info.key);
                    if (isPromise(p)) {
                        p.catch((error) => {
                            console.error(error);
                        });
                    }

                    setActionsPopoverVisible(false);
                }}
            >
                {action!.actionsList.map((actionItem) => {
                    if (!actionItem.children) {
                        return <ArgMenuItem
                            key={actionItem.key}
                            disabled={actionItem.isDisabled}
                            data-testid={`menu-item-${actionItem}`}
                            label={actionItem.label}
                            tooltip={actionItem.tooltip}
                        />;
                    }

                    return <ArgSubMenu
                        key={actionItem.key}
                        label={actionItem.label}
                        disabled={actionItem.isDisabled}
                        tooltip={actionItem.tooltip}>
                        {actionItem.children.map((childItem) => {
                            return (
                                <ArgMenuItem
                                    key={childItem.key}
                                    disabled={childItem.isDisabled}
                                    data-testid={`menu-item-${childItem.key}`}
                                >
                                    {renderText(childItem.label)}
                                </ArgMenuItem>
                            );
                        })}
                    </ArgSubMenu>;
                })}
            </ArgMenu>
        );
    }, [action]);

    return (
        <div key={id} className={classNames('&', className, cls)}>
            {!cls.loading &&
                <div className={classNames('&-header', _headerCls, headerCls)}>
                    {!hideTitle && (
                        <h2 className={classNames('&-header-title-container')}>
                            <span className={classNames('&-header-title')}>
                                {_title}
                            </span>
                        </h2>
                    )}

                    {isNotEmptyList && !hideSearchBar && (
                        <ArgInputSearch
                            onInputChange={setSearch}
                            value={!isNil(search) ? search : undefined}
                            placeholder={_searchPlaceholder}
                            className={classNames('&-header-search', 'secondary')}
                            focusKeyBinding={searchKeyBinding}
                            searchType={searchType}
                        />
                    )}

                    {isNotEmptyList && action && (
                        <ArgButton
                            type='secondary'
                            right='dropdown'
                            label='Actions'
                            disabled={action.isDisabled}
                            tooltip={action.disableMessage}
                            popover={renderActionMenu}
                            popoverTrigger='click'
                            popoverVisible={actionsPopoverVisible}
                            data-testid='actions-menu-container'
                            popoverPlacement='bottomLeft'
                            onPopoverVisibleChange={setActionsPopoverVisible}
                            popoverClassName={classNames('&-popover')}
                        />
                    )}

                    <div className={classNames('&-header-spacer')} />

                    {additionalTitle}

                    {allowImport && (
                        <ArgUploaderButton
                            size='medium'
                            icon='icon-upload'
                            acceptedFiles='.zip'
                            type='secondary'
                            loading={importProgressMonitor?.isRunning}
                            disabled={disableImport || importProgressMonitor?.isRunning}
                            method={onPressImport}
                            className={classNames('&-header-right-import')}
                            label={importButtonLabel || messages.importButton}
                            messageValues={{
                                name: lowerCase(id),
                            }}
                            keyBinding={importKeyBinding}
                            tooltip={importButtonTooltip}
                        />
                    )}

                    {allowCreate && (
                        <ArgButton
                            size='medium'
                            type='primary'
                            icon='icon-plus'
                            loading={creationProgressMonitor?.isRunning}
                            disabled={disableCreate || creationProgressMonitor?.isRunning}
                            onClick={onPressCreate}
                            className={classNames('&-header-right-create')}
                            label={createButtonLabel || messages.createButton}
                            messageValues={{
                                name: lowerCase(id),
                            }}
                            keyBinding={createKeyBinding}
                            tooltip={createButtonTooltip}
                        />
                    )}
                </div>}

            <div className={classNames('&-body')}>
                {body}
            </div>
        </div>
    );
}
