import { max } from 'lodash';
import { DependencyList, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { useLatestCallback } from '../arg-hooks/use-latest-callback';
import { useClassNames } from '../arg-hooks/use-classNames';

import './use-horizontal-scroll.less';

const CLASS_NAME = 'arg-table4-horizontal-scroll';

interface ScrollInfo {
    maxScrollWidth?: number;
    hasScrollbar: boolean;
}

export function useHorizontalScroll(
    scrollables: Array<Element | null>,
    computeWidth: ()=>number,
    deps: DependencyList,
): [ReactNode, ()=>void] {
    const classNames = useClassNames(CLASS_NAME);

    const [redraw, setRedraw] = useState<number>(0);
    const scrollRef = useRef<HTMLDivElement>(null);
    const scrollInfoRef = useRef<ScrollInfo>();

    const update = useLatestCallback(() => {
        if (scrollRef.current) {
            const scrollLeft = scrollRef.current?.scrollLeft;
            // TODO: there is perf issue leading to unpleasant trembling while scrolling caused by the below line. Carefully using requestAnimationFrame and throttling clearing is not enough.
            scrollables.forEach(el => {
                if (el) {
                    const style = (el as HTMLElement).style;
                    if (style.left) {
                        style.left = `${-scrollLeft}px`;

                        return;
                    }
                    el.scrollLeft = scrollLeft;
                }
            });
        }
    });

    useEffect(() => {
        update();
    }, [...scrollables]);

    useEffect(() => {
        let ticking = false;
        const onScroll = () => {
            if (!ticking) {
                requestAnimationFrame(() => {
                    ticking = false;

                    update();
                });
                ticking = true;
            }
        };

        scrollRef.current?.addEventListener('scroll', onScroll);

        return () => {
            scrollRef.current?.removeEventListener('scroll', onScroll);
        };
    }, []);

    const computeScrollInfo = useCallback(() => {
        const maxScrollWidth =
            computeWidth
                ? computeWidth()
                : max(scrollables.map(el => el?.scrollWidth));
        const hasScrollbar = scrollables.some(el => {
            if (!el || !el.scrollWidth) {
                return false;
            }

            return el.scrollWidth > el.clientWidth;
        });
        scrollInfoRef.current = { maxScrollWidth, hasScrollbar };
    }, [deps, ...scrollables]);

    useEffect(computeScrollInfo, [computeScrollInfo]);

    const updateScrollbar = useCallback(() => {
        computeScrollInfo();

        setRedraw((prev) => prev + 1);
    }, [computeScrollInfo]);

    const style = {
        width: scrollInfoRef.current?.maxScrollWidth,
    };

    const cls = {
        hide: !scrollInfoRef.current?.hasScrollbar,
    };

    const component = (
        <div
            className={classNames('&', cls)}
            ref={scrollRef}
        >
            <div style={style} />
        </div>
    );

    return [component, updateScrollbar];
}
