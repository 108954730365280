import { defineMessages } from 'react-intl';
import React, { lazy, ReactElement, Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { ArgonosModulesRegistry } from '../components/application/argonos-modules-registry';
import { Environment } from '../utils/environment';
import { SETTINGS_PERMISSIONS_REQUIRED, SettingsPermissions } from './permissions/permissions';
import { LoadingSuspense } from '../components/containers/loading-suspense';
import { useHasAnyPermissions, useHasPermission } from '../contexts/user-permission';
import { setupUsersSettings, USERS_AND_GROUPS_ROUTES } from './users';
import { CONTEXTUAL_VARIABLES_PATH } from './contextual-variables';
import { setupVisualIdentity, VISUAL_IDENTITY_PATH } from './visual-identity';
import { getSettingsApi } from '../utils/connectors/api-url';
import { ArgonosModule } from '../components/application/modules';
import { SETTINGS_ROOT_URL } from '../components/containers/routes-urls';
import { setupRoles } from './roles';
import { setupExtensions } from './webhooks-external-components';
import { setupGlobalExport } from './configuration/components/global-export-modal/global-export-modal';
import { setupGlobalImport } from './configuration/components/global-import-modal/global-import-modal';
import { setupApplicationVersion } from './home-page';
import { SETTINGS_PERMISSION_CONSTRAINTS } from './permissions/permission-constraints';
import { ArgonosModuleRoutesProps } from 'src/components/containers/argonos-modules-routes';

const LazySettingsRouter = lazy(() => import('./settings-router'));

const FORCE_SUSPENSE_LOADING = false;

const messages = defineMessages({
    settingsApplication: {
        id: 'common.applications.Settings',
        defaultMessage: 'Settings',
    },
    loadingSettings: {
        id: 'common.applications.LoadingSettings',
        defaultMessage: 'Loading Settings {threeDotsLoading}',
    },
    loginTitle: {
        id: 'settings.login.Title',
        defaultMessage: 'Your admin platform',
    },
    loginMessage: {
        id: 'settings.login.Explanation',
        defaultMessage: 'Log into the platform',
    },
});


function SettingsApp(props: ArgonosModuleRoutesProps) {
    const { className } = props;

    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense message={messages.loadingSettings} />;
    }

    return (
        <Suspense fallback={<LoadingSuspense message={messages.loadingSettings} />}>
            <LazySettingsRouter
                className={className}
            />
        </Suspense>
    );
}


function AutomaticRedirection() {
    const hasUsersAndGroupsAccess = useHasAnyPermissions<SettingsPermissions>('admin.user.access', 'admin.user.group.access');
    const hasVisualIdentityAccess = useHasPermission<SettingsPermissions>('admin.visual.identity.management');
    const hasWebhookAccess = useHasAnyPermissions<SettingsPermissions>('admin.webhook.access');
    const hasRolesAccess = useHasPermission<SettingsPermissions>('admin.user.role.access');
    const hasContextualVariableAccess = useHasAnyPermissions<SettingsPermissions>('admin.contextual.variable.management', 'admin.contextual.variable.edition');

    if (hasUsersAndGroupsAccess) {
        return <Navigate to={USERS_AND_GROUPS_ROUTES} />;
    }
    if (ArgonosModulesRegistry.getInstance().getById('chapsVision.DataExploration')?.enabled && hasContextualVariableAccess) {
        return <Navigate to={CONTEXTUAL_VARIABLES_PATH} />;
    }
    if (hasVisualIdentityAccess) {
        return <Navigate to={VISUAL_IDENTITY_PATH} />;
    }
    if (hasWebhookAccess) {
        return <Navigate to='/settings/extensions/webhooks' />;
    }
    if (hasRolesAccess) {
        return <Navigate to='/settings/roles/admin' />;
    }

    return null;

    // TODO: Take into account la section ##Modules##
}

const SETTINGS_MODULE: ArgonosModule<SettingsPermissions> = {
    id: 'chapsVision.Settings',
    subType: 'Settings',
    enabled: Environment.isSettingsEnabled(),

    loginOrder: 6,
    loginTitle: messages.loginTitle,
    loginMessage: messages.loginMessage,

    routeURL: SETTINGS_ROOT_URL,

    requiredPermissions: SETTINGS_PERMISSIONS_REQUIRED,
    permissionConstrains: SETTINGS_PERMISSION_CONSTRAINTS,

    hasUserPermissions: true,
    hasWebhook: true,
    apiURL: getSettingsApi(),

    iconURL: 'icon-cog',
    name: messages.settingsApplication,
    color: '#CB9B54',
    documentationURL: Environment.docServerUrlSettings,
    scope: 'settings',

    getRoutes(): ReactElement[] {
        return [
            <Route
                key='settings'
                path={SETTINGS_ROOT_URL}
                element={<AutomaticRedirection />}
            />,
            <Route
                key='settings/*'
                path={`${SETTINGS_ROOT_URL}/*`}
                element={
                    <SettingsApp argonosModule={SETTINGS_MODULE} />
                }
            />,
        ];
    },
};

ArgonosModulesRegistry.getInstance().register(SETTINGS_MODULE);

setupUsersSettings();
setupRoles(SETTINGS_MODULE);
setupExtensions(SETTINGS_MODULE);
setupGlobalExport();
setupGlobalImport();
setupApplicationVersion();
setupVisualIdentity();
