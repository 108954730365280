import { SettingsPermissions } from './permissions';
import { PermissionConstraints } from '../../components/application/modules';

export const SETTINGS_PERMISSION_CONSTRAINTS: PermissionConstraints<SettingsPermissions> = {
    'admin.user.access': ['admin.user.role.access'],
    'admin.user.edition': ['admin.user.access'],
    'admin.user.management': ['admin.user.access'],
    'admin.user.group.edition': ['admin.user.group.access'],
    'admin.user.role.edition': ['admin.user.role.access'],
    'admin.user.fields.management': ['admin.user.fields.access'],
    'admin.webhook.edition': ['admin.webhook.access'],
    'admin.webhook.export': ['admin.webhook.access'],
    'admin.moduleExtensions.edition': ['admin.moduleExtensions.access'],
    'admin.moduleExtensions.delete': ['admin.moduleExtensions.access'],
    'admin.moduleExtensions.publish': ['admin.moduleExtensions.access'],
    'admin.contextual.variable.management': ['admin.contextual.variable.edition'],
};
