import { cloneDeep, isArray, isEmpty, isNil, omit, omitBy, slice, uniqueId, values } from 'lodash';

import { GraphCustomizerState } from '../hooks/use-graph-style-customisation';
import {
    AdvancedStyleType,
    ContinuousValueIntervaleType,
    DiscreteValue,
    Gradient,
    Interval,
    RuleSet,
    UserDefinedContent,
} from '../../components/common/graph/customisation/graph-style';
import { getInvalidFields, getSpecificProps } from '../../components/common/graph/customisation/controls/utils';
import { DEFAULT_UNDEFINED_VALUES_STYLE } from '../constants/default-vertex-style';
import { DEFAULT_BADGE_STYLE } from 'src/components/common/graph/constants';
import { getIconFontFamily } from 'src/styles/fonts/icon-utils';

export enum TYPES {
    SET = 'SET',
    RESET = 'RESET',
    SAVE_STYLES = 'SAVE_STYLES',
    LOAD_STYLES = 'LOAD_STYLES',
    RESET_STYLES = 'RESET_STYLES',
    UPDATE_DISPLAY_PROPERTY = 'UPDATE_DISPLAY_PROPERTY',
    SET_VERTEX_LABEL = 'SET_VERTEX_LABEL',
    UPDATE_SIZE = 'UPDATE_SIZE',
    SET_FILL_COLOR = 'SET_FILL_COLOR',
    SET_STROKE_COLOR = 'SET_STROKE_COLOR',
    SET_ICON_COLOR = 'SET_ICON_COLOR',
    SET_STROKE_TRANSPARENT_COLOR = 'SET_STROKE_TRANSPARENT_COLOR',
    SET_ICON = 'SET_ICON',
    UPDATE_OBJECT_TYPE = 'UPDATE_OBJECT_TYPE',
    TOGGLE_DEFAULT_PROP = 'EXPAND_DEFAULT_PROP',
    TOGGLE_COLOR_AND_ICON_VISIBILITY = 'TOGGLE_COLOR_AND_ICON_VISIBILITY',
    SET_COLOR_AND_ICON_VISIBILITY = 'SET_COLOR_AND_ICON_VISIBILITY',
    SET_CLUSTERING_PROPERTY = 'SET_CLUSTERING_PROPERTY',
    SET_CHRONOGRAM_GROUPING_PROPERTY = 'SET_CHRONOGRAM_GROUPING_PROPERTY',
    SET_CHRONOGRAM_TIMELINE_PROPERTY = 'SET_CHRONOGRAM_TIMELINE_PROPERTY',
    SET_DEFAULT_GEOGRAPHY_PROPERTY = 'SET_DEFAULT_GEOGRAPHY_PROPERTY',
    SET_ADVANCED_STYLE_PROPERTY = 'SET_ADVANCED_STYLE_PROPERTY',
    SET_ADVANCED_DISCRETE_PROPERTY_VALUE = 'SET_ADVANCED_DISCRETE_PROPERTY_VALUE',
    SET_RANGE_VALUE = 'SET_RANGE_VALUE',
    SET_ADVANCED_STYLE = 'SET_ADVANCED_STYLE',
    ADD_NEW_ADVANCED_STYLE_RULE = 'ADD_NEW_ADVANCED_STYLE_RULE',
    REMOVE_ADVANCED_STYLE_RULE = 'REMOVE_ADVANCED_STYLE_RULE',
    SET_ADVANCED_STYLE_INTERVAL_TYPE = 'SET_ADVANCED_STYLE_INTERVAL_TYPE',
    TOGGLE_GRADIENT_INTERVAL = 'TOGGLE_GRADIENT_INTERVAL',
    SET_GRADIENT_PROPERTY_RANGE_INFOS = 'SET_GRADIENT_PROPERTY_RANGE_INFOS',
    ADD_UNDEFINED_VALUE_STYLES_RULE = 'ADD_UNDEFINED_VALUE_STYLES_RULE',
    REMOVE_UNDEFINED_VALUE_STYLES_RULE = 'REMOVE_UNDEFINED_VALUE_STYLES_RULE',

    SET_ADVANCED_STYLE_LINE_STYLE = 'SET_ADVANCED_STYLE_LINE_STYLE',
    SET_ADVANCED_STYLE_LINE_ANIMATION = 'SET_ADVANCED_STYLE_LINE_ANIMATION',
}

export const ADVANCED_STYLE_LABELS = ['color', 'style', 'icon', 'size', 'badges'];

export const isEmptyField = (rule: RuleSet) => {
    return !(
        !isNil(rule?.isUndefinedRuleSet) ||
        !isNil((rule?.condition as DiscreteValue)?.value) ||
        !isNil((rule?.condition as Interval)?.interval?.left) ||
        !isNil((rule?.condition as Interval)?.interval?.right) ||
        (!isNil(rule?.userDefinedContent?.gradientSize?.[0]) && !isNil(rule?.userDefinedContent?.gradientSize?.[1]))
    );
};

export interface GraphStyleCustomiserAction {
    type: string;
    undoRedo?: (newState: GraphCustomizerState) => void;
    payload: any;
}

export function filterIdFromAdvancedUserDefinedContentStyle(
    graphStyles: GraphCustomizerState,
): GraphCustomizerState {
    let graphStylesClone: GraphCustomizerState = cloneDeep(graphStyles);
    ADVANCED_STYLE_LABELS.forEach((label) => {
        if (graphStylesClone.ruleSets?.[label]?.length > 0) {
            graphStylesClone = {
                ...graphStylesClone,
                ruleSets: {
                    ...graphStylesClone.ruleSets,
                    [label]: graphStylesClone.ruleSets[label]
                        .filter((rule) => {
                            //If a field is empty, ignore it
                            return !isEmptyField(rule);
                        })
                        .map((rule) => {
                            //Clean id from rules
                            const newRule = {
                                ...rule,
                                userDefinedContent: omit(rule.userDefinedContent, ['id']),
                            };

                            const cleanNewRule = omit(newRule, ['isUndefinedRuleSet']);

                            return cleanNewRule as RuleSet;
                        }),
                },
            };
        }
    });

    const cleanedRuleSets = omitBy(cloneDeep(graphStylesClone.ruleSets), (styleLabel) => styleLabel.length === 0);

    return {
        ...graphStylesClone,
        ruleSets: cleanedRuleSets,
    };
}

export function graphStyleCustomisationReducer(
    state: GraphCustomizerState,
    action: GraphStyleCustomiserAction,
): GraphCustomizerState {
    const { type, payload, undoRedo } = action;
    let newState: GraphCustomizerState;

    switch (type) {
        case TYPES.SET: {
            newState = { ...state, ...payload };
            const newStateRuleSets = newState.ruleSets;

            ADVANCED_STYLE_LABELS.forEach(styleRule => {
                const styleRuleDetails = newStateRuleSets[styleRule];
                if (styleRuleDetails) {
                    newState = {
                        ...newState,
                        ruleSets: {
                            ...newState.ruleSets,
                            [styleRule]: styleRuleDetails.map((rule) => {
                                return {
                                    ...rule,
                                    isUndefinedRuleSet: isEmpty(rule?.condition),
                                    userDefinedContent: {
                                        ...rule.userDefinedContent,
                                        id: uniqueId(),
                                    },
                                };
                            }),
                        },
                    };
                }
            });

            return newState;
        }

        case TYPES.LOAD_STYLES:
        case TYPES.RESET_STYLES: {
            const newState = {
                ...payload,
                defaultPropertiesExpanded: true,
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }
        case TYPES.RESET: {
            newState = { ...state, ...payload };
            const newStateRuleSets = newState.ruleSets;

            ADVANCED_STYLE_LABELS.forEach(styleRule => {
                const styleRuleDetails = newStateRuleSets[styleRule];
                if (styleRuleDetails) {
                    newState = {
                        ...newState,
                        ruleSets: {
                            ...newState.ruleSets,
                            [styleRule]: styleRuleDetails.map((rule) => {
                                return {
                                    ...rule,
                                    isUndefinedRuleSet: !(rule?.condition as DiscreteValue)?.value,
                                    userDefinedContent: {
                                        ...rule.userDefinedContent,
                                        id: uniqueId(),
                                    },
                                };
                            }),
                        },
                    };
                }
            });

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_VERTEX_LABEL: {
            newState = { ...state, ...payload };
            const newStateRuleSets = newState.ruleSets;

            ADVANCED_STYLE_LABELS.forEach(styleRule => {
                const styleRuleDetails = newStateRuleSets[styleRule];
                if (styleRuleDetails) {
                    newState = {
                        ...newState,
                        ruleSets: {
                            ...newState.ruleSets,
                            [styleRule]: styleRuleDetails.map((rule) => {
                                return {
                                    ...rule,
                                    isUndefinedRuleSet: isEmptyField(rule),
                                    userDefinedContent: {
                                        ...rule.userDefinedContent,
                                        id: uniqueId(),
                                    },
                                };
                            }),
                        },
                    };
                }
            });


            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }


        case TYPES.TOGGLE_DEFAULT_PROP:
            return {
                ...state,
                defaultPropertiesExpanded: !state.defaultPropertiesExpanded,
            };
        case TYPES.UPDATE_DISPLAY_PROPERTY: {
            newState = {
                ...state,

                titleProperty: payload,
            };
            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.UPDATE_SIZE: {
            newState = {
                ...state,
                userDefinedContent: {
                    ...state.userDefinedContent,
                    size: payload,
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_ADVANCED_STYLE_LINE_STYLE: {
            const nextLineStyle = payload;
            const currentLineStyle = state.userDefinedContent?.lineStyle;
            if (nextLineStyle !== currentLineStyle) {
                newState = {
                    ...state,
                    userDefinedContent: {
                        ...state.userDefinedContent,
                        lineStyle: nextLineStyle,
                    },
                };

                const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
                undoRedo?.(validState);

                return newState;
            }

            return state;
        }

        case TYPES.SET_ADVANCED_STYLE_LINE_ANIMATION: {
            const nextLineAnimation = payload;
            const currentLineAnimation = state.userDefinedContent?.lineAnimation;


            if (currentLineAnimation !== nextLineAnimation) {
                newState = {
                    ...state,
                    userDefinedContent: {
                        ...state.userDefinedContent,
                        lineAnimation: nextLineAnimation,
                    },
                };

                const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
                undoRedo?.(validState);

                return newState;
            }

            return state;
        }

        case TYPES.TOGGLE_COLOR_AND_ICON_VISIBILITY:
            return {
                ...state,
                colorAndIconEditorVisible: !state.colorAndIconEditorVisible,
            };

        case TYPES.SET_COLOR_AND_ICON_VISIBILITY:
            return {
                ...state,
                colorAndIconEditorVisible: payload,
            };

        case TYPES.SET_FILL_COLOR: {
            newState = {
                ...state,
                userDefinedContent: {
                    ...state.userDefinedContent,
                    fillColor: payload,
                },
            };
            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_STROKE_COLOR: {
            newState = {
                ...state,
                userDefinedContent: {
                    ...state.userDefinedContent,
                    strokeColor: payload,
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_ICON_COLOR: {
            newState = {
                ...state,
                userDefinedContent: {
                    ...state.userDefinedContent,
                    iconColor: payload,
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_ICON: {
            newState = {
                ...state,
                userDefinedContent: {
                    ...state.userDefinedContent,
                    iconName: payload,
                    iconFontFamily: getIconFontFamily(payload),
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_STROKE_TRANSPARENT_COLOR: {
            newState = {
                ...state,
                userDefinedContent: {
                    ...state.userDefinedContent,
                    strokeColor: (payload) ? 'none' : undefined,
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_CLUSTERING_PROPERTY: {
            newState = {
                ...state,
                clusteringProperty: isArray(payload) ? undefined : payload,
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_CHRONOGRAM_GROUPING_PROPERTY: {
            newState = {
                ...state,
                groupbyProperty: isArray(payload) ? undefined : payload,
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_CHRONOGRAM_TIMELINE_PROPERTY: {
            newState = {
                ...state,
                timelineProperty: isArray(payload) ? undefined : payload,
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_DEFAULT_GEOGRAPHY_PROPERTY: {
            newState = {
                ...state,
                geographicalProperty: isArray(payload) ? undefined : payload,
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_ADVANCED_STYLE_PROPERTY: {
            const { value, property, controlType } = payload;
            const advancedStyleType: AdvancedStyleType = payload.advancedStyleType;
            const _rulesSets: Record<string, RuleSet[]> = {
                ...state.ruleSets,
                [advancedStyleType]:
                    property === undefined
                        ? []
                        : [
                            {
                                condition: {
                                    ...value,
                                },
                                property: property,
                                userDefinedContent: {
                                    ...getSpecificProps(advancedStyleType,
                                        advancedStyleType === 'badges' ? {
                                            ...state?.userDefinedContent,
                                            size: DEFAULT_BADGE_STYLE?.defaultSize,
                                        } : state?.userDefinedContent,
                                        controlType),
                                    id: uniqueId(),
                                },
                            },
                        ],
            };

            newState = {
                ...state,
                ruleSets: _rulesSets,
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_ADVANCED_DISCRETE_PROPERTY_VALUE: {
            const { advancedStyleType, value, index, state } = payload;

            const styleList = cloneDeep(state.ruleSets[advancedStyleType]);
            styleList[index] = {
                ...styleList[index],
                condition: {
                    value: value,
                },
            };

            newState = {
                ...state,
                ruleSets: {
                    ...state.ruleSets,
                    [advancedStyleType]: styleList,
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            value !== null && undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_RANGE_VALUE: {
            const { advancedStyleType, side, value, index } = payload;
            const styleList = cloneDeep(state.ruleSets[advancedStyleType]);


            let _value;
            if (!isNil(value)) {
                if (side === 'left') {
                    const leftValue = value;
                    const rightValue = (styleList[index]?.condition as Interval)?.interval?.right;

                    if (!isNil(rightValue)) {
                        if (leftValue > rightValue) {
                            _value = rightValue;
                        } else {
                            _value = value;
                        }
                    } else {
                        _value = value;
                    }
                } else { // right
                    const leftValue = (styleList[index]?.condition as Interval)?.interval?.left;
                    const rightValue = value;
                    if (!isNil(leftValue)) {
                        if (leftValue > rightValue) {
                            _value = leftValue;
                        } else {
                            _value = value;
                        }
                    } else {
                        _value = value;
                    }
                }
            } else {
                _value = value;
            }

            styleList[index] = {
                ...styleList[index],
                condition: {
                    interval: {
                        ...(styleList[index].condition as Interval)?.interval,
                        [side]: _value,
                    },
                },
            };

            const invalidFields = getInvalidFields(styleList);

            const doesOverlap = values(invalidFields).some(
                (invalidField) => invalidField.invalidLeft || invalidField.invalidRight,
            );

            newState = {
                ...state,
                ruleSets: {
                    ...state.ruleSets,
                    [advancedStyleType]: styleList,
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            !doesOverlap && undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_ADVANCED_STYLE_INTERVAL_TYPE: {
            const { advancedStyleType, clickSide, position } = payload;
            const styleList = cloneDeep(state.ruleSets[advancedStyleType]);

            function updateIntervalType(
                clickSide: 'left' | 'right',
                currentIntervalType: ContinuousValueIntervaleType,
            ): ContinuousValueIntervaleType {
                const isLeftSide = clickSide === 'left';
                switch (currentIntervalType) {
                    case 'Closed':
                        return isLeftSide ? 'LeftOpen' : 'RightOpen';
                    case 'Open':
                        return isLeftSide ? 'RightOpen' : 'LeftOpen';
                    case 'RightOpen':
                        return isLeftSide ? 'Open' : 'Closed';
                    case 'LeftOpen':
                        return isLeftSide ? 'Closed' : 'Open';

                    default:
                        return 'Closed';
                }
            }

            styleList[position] = {
                ...styleList[position],
                condition: {
                    interval: {
                        ...(styleList[position].condition as Interval)?.interval,
                        type: updateIntervalType(clickSide, (styleList[position].condition as Interval)?.interval.type),
                    },
                },
            };
            newState = {
                ...state,
                ruleSets: {
                    ...state.ruleSets,
                    [advancedStyleType]: styleList,
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_ADVANCED_STYLE: {
            const { advancedStyleType, style, index: idx } = payload;
            newState = {
                ...state,
                ruleSets: {
                    ...state.ruleSets,
                    [advancedStyleType]: state.ruleSets[advancedStyleType].map((ruleSet, index) => {
                        if (idx === index) {
                            return {
                                ...ruleSet,
                                userDefinedContent: {
                                    ...ruleSet.userDefinedContent,
                                    ...style,
                                },
                            };
                        }

                        return ruleSet;
                    }),
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.ADD_NEW_ADVANCED_STYLE_RULE: {
            const { advancedStyleType, value } = payload;

            const advancedStyleTypeRleSets = state.ruleSets[advancedStyleType];
            let ruleSets: RuleSet[];
            const length = advancedStyleTypeRleSets.length;
            const lastRuleSet = advancedStyleTypeRleSets[advancedStyleTypeRleSets.length - 1];
            const isLastRuleSetUndefined = lastRuleSet?.isUndefinedRuleSet;

            let ruleSetToAdd: RuleSet;
            const property = state.ruleSets[advancedStyleType][0].property;
            if (!isLastRuleSetUndefined) {
                ruleSetToAdd = {
                    condition: {
                        ...value,
                    },
                    property,
                    userDefinedContent: {
                        ...advancedStyleTypeRleSets[length - 1].userDefinedContent,
                        id: uniqueId(),
                    },
                };
                ruleSets = [...advancedStyleTypeRleSets, ruleSetToAdd];
            } else {
                ruleSetToAdd = {
                    condition: {
                        ...value,
                    },
                    property,
                    userDefinedContent: length > 1 ? {
                        ...advancedStyleTypeRleSets[length - 2].userDefinedContent,
                        id: uniqueId(),
                    } : {
                        ...getSpecificProps(advancedStyleType, state?.userDefinedContent),
                        id: uniqueId(),

                    },
                };

                ruleSets = [
                    ...slice(advancedStyleTypeRleSets, 0, length - 1),
                    ruleSetToAdd,
                    advancedStyleTypeRleSets[length - 1],
                ];
            }

            newState = {
                ...state,
                ruleSets: {
                    ...state.ruleSets,
                    [advancedStyleType]: ruleSets,
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.REMOVE_ADVANCED_STYLE_RULE: {
            const { advancedStyleType, position } = payload;

            newState = {
                ...state,
                ruleSets: {
                    ...state.ruleSets,
                    [advancedStyleType]: state.ruleSets[advancedStyleType].filter((_, idx) => idx !== position),
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.TOGGLE_GRADIENT_INTERVAL: {
            const { advancedStyleType, gradientOrInterval, property } = payload;

            newState = {
                ...state,
                ruleSets: {
                    ...state.ruleSets,
                    [advancedStyleType]: [{
                        condition: {
                            [gradientOrInterval]: {
                                left: null,
                                right: null,
                                type: gradientOrInterval === 'gradient' ? 'Closed' : 'Open',
                            },
                        },
                        property,
                        userDefinedContent: {
                            size: gradientOrInterval === 'gradient' ? undefined : state?.userDefinedContent?.size,
                            gradientSize: !(gradientOrInterval === 'gradient') ? undefined : state?.userDefinedContent?.gradientSize,
                        },
                    }],
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.SET_GRADIENT_PROPERTY_RANGE_INFOS: {
            const { type, side, value } = payload;

            if (type === 'property') {
                const newGradient = {
                    ...(state.ruleSets?.size[0]?.condition as Gradient)?.gradient,
                    [side]: value,
                };

                newState = {
                    ...state,
                    ruleSets: {
                        ...state.ruleSets,
                        size: [{
                            ...state.ruleSets.size[0],
                            condition: {
                                gradient: newGradient,
                            },
                        }],
                    },
                };
            } else if (type === 'size') {
                const userDefinedContent = {
                    gradientSize:
                        side === 'left'
                            ? [value, state?.ruleSets?.size[0]?.userDefinedContent?.gradientSize?.[1]]
                            : [state?.ruleSets?.size[0]?.userDefinedContent?.gradientSize?.[0], value],
                } as UserDefinedContent;

                newState = {
                    ...state,
                    ruleSets: {
                        ...state.ruleSets,
                        size: [{
                            ...state.ruleSets.size[0],
                            userDefinedContent: userDefinedContent,
                        }],
                    },
                };
            } else {
                newState = state;
            }

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.ADD_UNDEFINED_VALUE_STYLES_RULE: {
            const advancedStyleType: AdvancedStyleType = payload.advancedStyleType;
            newState = {
                ...state,
                ruleSets: {
                    ...state.ruleSets,
                    [advancedStyleType]: [
                        ...state.ruleSets[advancedStyleType],
                        {
                            isUndefinedRuleSet: true,
                            condition: undefined,
                            property:
                            state.ruleSets[advancedStyleType][state.ruleSets[advancedStyleType].length - 1]
                                .property,
                            userDefinedContent: {
                                ...DEFAULT_UNDEFINED_VALUES_STYLE,
                                size: (advancedStyleType === 'badges' ? DEFAULT_BADGE_STYLE.defaultSize : DEFAULT_UNDEFINED_VALUES_STYLE.size),
                                id: uniqueId(),
                            },
                        },
                    ],
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }

        case TYPES.REMOVE_UNDEFINED_VALUE_STYLES_RULE: {
            const { advancedStyleType } = payload;
            newState = {
                ...state,
                ruleSets: {
                    ...state.ruleSets,
                    [advancedStyleType]: state.ruleSets[advancedStyleType].filter(
                        (ruleSet) => !ruleSet.isUndefinedRuleSet,
                    ),
                },
            };

            const validState = filterIdFromAdvancedUserDefinedContentStyle(newState);
            undoRedo?.(validState);

            return newState;
        }


        default:
            return state;
    }
}
