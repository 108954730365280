import { defineMessages } from 'react-intl';

import { registerToolItemForMimeType } from '../../../../components/basic';
import {
    PROCESS_COMPONENT_MIME_TYPE,
    ProcessEnvironmentContext,
} from '../../../environment-contexts/process-environment-context';
import { PreparationEnvironmentContext } from '../../../environment-contexts/preparation-environment-context';
import { ProcessComponent } from '../../../model/process';
import { getReferenceTableName } from '../../../process/utils';
import { PreparationPermissions } from '../../../permissions/permissions';
import { computeReferenceTableURL } from '../utils/compute-urls';
import { openInNewTab } from '../../../../utils/window';


const messages = defineMessages({
    viewReferenceTable: {
        id: 'preparation.reference-tables.actions.view.ReferenceTable',
        defaultMessage: 'View references table',
    },
});

registerToolItemForMimeType<PreparationEnvironmentContext, ProcessComponent>(PROCESS_COMPONENT_MIME_TYPE, {
    path: 'actions/reference-tables',
    type: 'group',
});

registerToolItemForMimeType<ProcessEnvironmentContext, ProcessComponent>(PROCESS_COMPONENT_MIME_TYPE, {
    path: 'actions/reference-tables/view-reference',
    type: 'button',
    label: messages.viewReferenceTable,
    visible: (environmentContext) => {
        if (!environmentContext.hasAnyPermissions<PreparationPermissions>('preparation.reference.table.read')) {
            return false;
        }

        const component = environmentContext.firstItem();
        if (!component) {
            return false;
        }

        if (getReferenceTableName(environmentContext.componentLibrary, component) === undefined) {
            return false;
        }

        return true;
    },
    disabled: (environmentContext) => {
        const component = environmentContext.firstItem();
        if (!component) {
            return true;
        }

        if (!getReferenceTableName(environmentContext.componentLibrary, component)) {
            return true;
        }

        return false;
    },
    onClick: (props, environmentContext) => {
        const processComponent = environmentContext.firstItem()!;

        const referenceTable = processComponent.options?.['TableName'];
        if (!referenceTable) {
            return;
        }

        const url = computeReferenceTableURL(referenceTable);

        openInNewTab(url);

        //environmentContext.navigate(url);
    },
});
