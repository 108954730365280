import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { ProcessId } from '../model/process';
import { ProcessComponentsPrioritiesState } from './process-components-priorities-state';

export function preparationProcessComponentsPrioritiesStateFactory(url: string, processId: ProcessId) {
    return new ProcessComponentsPrioritiesState(url, processId);
}

export function computeProcessComponentsPrioritiesStateURL(processId: ProcessId) {
    return `/processes/${processId}/components-priorities`;
}

export function usePreparationProcessComponentsPrioritiesState(processId: ProcessId): ProcessComponentsPrioritiesState;
export function usePreparationProcessComponentsPrioritiesState(processId: ProcessId | undefined): ProcessComponentsPrioritiesState | undefined;

export function usePreparationProcessComponentsPrioritiesState(processId: ProcessId | undefined): ProcessComponentsPrioritiesState | undefined {
    const state = useBasicState<ProcessComponentsPrioritiesState>(
        (url) => preparationProcessComponentsPrioritiesStateFactory(url, processId!),
        (processId) ? computeProcessComponentsPrioritiesStateURL(processId) : undefined,
    );

    return state;
}
