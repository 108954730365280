import { useCallback } from 'react';
import { Slider } from 'antd';

import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgSliderCommonProps } from './types';
import { ArgChangeReason } from '../types';

import './arg-slider.less';

export interface ArgSliderProps extends Omit<ArgSliderCommonProps<number>, 'onChange'> {
    onChange?: (value: number, reason?: ArgChangeReason) => void;
    onAfterChange?: (value: number, reason?: ArgChangeReason) => void;
};

export function ArgSlider(props: ArgSliderProps) {
    const {
        min,
        max,
        value,
        onChange,
        onAfterChange,
        disabled,
        tooltip,
        step,
        defaultValue,
        className,
    } = props;

    const onSliderChange = useCallback((value: number) => {
        onChange?.(value, 'selection');
    }, [onChange]);

    const onSliderAfterChange = useCallback((value: number) => {
        onAfterChange?.(value, 'selection');
    }, [onAfterChange]);

    const classNames = useClassNames('arg-slider');

    return (
        <Slider
            className={classNames('&-slider', className)}
            value={value}
            defaultValue={defaultValue}
            min={min}
            max={max}
            onChange={onSliderChange}
            onAfterChange={onSliderAfterChange}
            tooltip={tooltip}
            step={step}
            disabled={disabled}
        />
    );
}
