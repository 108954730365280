import React, { DragEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isFunction } from 'lodash';
import Debug from 'debug';

import { renderText } from '../utils/message-descriptor-formatters';
import { $yield } from '../utils/yield';

const debug = Debug('basic:dnd:Draggable');

const CAPTURE_TRUE = { capture: true };

const EMULATE_DRAG_END = false;

export interface DraggableAction<T = any> {
    supports?: () => boolean;
    onDragStart: (event: DragEvent, context: T) => void;
    createDragContext?: () => T;
    onDragEnd?: (event: DragEvent | undefined, context: T) => void;
}

export interface DragHandleProps {
    // html5 drag and drop
    draggable: boolean;
    onDragStart: (event: DragEvent) => void;
    onDragEnd: (event: DragEvent) => void;
}

export interface Provided {
    dragHandleProps?: DragHandleProps;
    dragging?: boolean;
    draggable?: boolean;
}

export interface DraggableProps<T> {
    children: ((provided: Provided) => ReactNode | null) | ReactNode | null;
    actions?: DraggableAction<T>[] | DraggableAction<T>;
}

export function Draggable<T = any>(props: DraggableProps<T>) {
    const {
        children,
        actions,
    } = props;

    const [enabledAction, setEnabledAction] = useState<DraggableAction<T>>();

    const [dragging, setDragging] = useState<boolean>(false);

    useEffect(() => {
        setEnabledAction(Array.isArray(actions) ? (actions.length > 0 ? actions[0] : undefined) : actions);
    }, [actions]);

    const onDropCallbackRef = useRef<(event?: any) => void>();

    const dragStart = useCallback((dragStartEvent: DragEvent) => {
        const _enabledAction = enabledAction;
        try {
            debug('dragStart', 'enabledAction=', _enabledAction);
            if (!_enabledAction) {
                dragStartEvent.preventDefault();

                return;
            }

            const context: T = _enabledAction.createDragContext?.() ?? ({} as T);

            $yield(() => {
                setDragging(true);
            });

            //onDropCallbackRef.current?.();

            if (EMULATE_DRAG_END) {
                const func = (dragEndEvent: any) => {
                    onDropCallbackRef.current = undefined;

                    document.removeEventListener('drop', func, CAPTURE_TRUE);

                    debug('dragStart', 'Release DRAG end.  dragEventType=', dragEndEvent?.type);

                    if (dragEndEvent?.type === 'dragend') {
                        dragEndEvent.stopPropagation();
                    }

                    $yield(() => {
                        setDragging(false);
                    });

                    if (dragEndEvent?.type === 'dragend') {
                        _enabledAction.onDragEnd?.(dragEndEvent!, context);
                    } else {
                        _enabledAction.onDragEnd?.(undefined, context);
                    }
                };

                onDropCallbackRef.current = func;

                document.addEventListener('drop', func, CAPTURE_TRUE);
            } else {
                onDropCallbackRef.current = (dragEndEvent: DragEvent) => {
                    onDropCallbackRef.current = undefined;

                    debug('onDragEnd');
                    $yield(() => {
                        setDragging(false);
                    });

                    if (dragEndEvent?.type === 'dragend') {
                        _enabledAction.onDragEnd?.(dragEndEvent!, context);
                    } else {
                        _enabledAction.onDragEnd?.(undefined, context);
                    }
                };
            }

            try {
                _enabledAction.onDragStart(dragStartEvent, context);
            } catch (error) {
                console.error(error);
            }

            if (dragStartEvent.defaultPrevented) {
                console.error('**** DEFAULT PREVENTED');
            }

            if (!dragStartEvent.dataTransfer.effectAllowed) {
                console.error('*** You must specify an effectAllowed for DND');
            }

            if (debug.enabled) {
                debug('dragStart.End',
                    'effectAllowed=', dragStartEvent.dataTransfer.effectAllowed,
                    'dropEffect=', dragStartEvent.dataTransfer.dropEffect,
                    'types=', dragStartEvent.dataTransfer.types,
                    'stopped=', dragStartEvent.isPropagationStopped(),
                    'prevented=', dragStartEvent.isDefaultPrevented(),
                );
            }
        } catch (error) {
            console.error(error);
        }
    }, [enabledAction]);

    const dragEnd = useCallback((event: DragEvent) => {
        try {
            debug('dragEnd');
            onDropCallbackRef.current?.(event);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        return () => {
            onDropCallbackRef.current?.('unmount');
        };
    }, []);

    const dragHandleProps: DragHandleProps | undefined = useMemo(() => {
        if (!enabledAction) {
            return;
        }

        const ret: DragHandleProps = {
            draggable: true,
            onDragStart: dragStart,
            onDragEnd: dragEnd,
        };
        debug('dragHandleProps', 'props=', ret);

        return ret;
    }, [dragEnd, dragStart, enabledAction]);

    const provided: Provided = useMemo(() => {
        const result: Provided = {
            dragHandleProps,
            dragging,
            draggable: !!dragHandleProps,
        };

        return result;
    }, [dragHandleProps, dragging]);

    let label: ReactNode;
    if (isFunction(children)) {
        label = children(provided);
    }
    label = renderText(label);

    return (
        <>
            {label}
        </>
    );
}
