import { CaseId } from '../../model/folder-case-piece';
import { ExplorationId } from './exploration';
import { UniversePropertyName, UniverseVertexTypeName } from './universe';

export interface IdFilter {
    included?: string[];
    excluded?: string[];
}

export interface TypeFilter {
    included?: UniverseVertexTypeName[];
    excluded?: UniverseVertexTypeName[];
}

export interface PropertySpecificValueFilter {
    propertyName?: UniversePropertyName;
    included?: any[];
    excluded?: any[];
}

export interface Boundary {
    value?: any;
    isIncluded?: boolean;
}

export interface Range {
    lowerBound?: Boundary;
    upperBound?: Boundary;

    lowerBoundParameter?: string;
    upperBoundParameter?: string;
}

export interface PropertyRangeFilter {
    propertyName?: UniversePropertyName;
    included?: (Range | null)[];
    excluded?: (Range | null)[];
}

export interface CoordinatesItem {
    latitude: number;
    longitude: number;
}

export interface GeographicAreaFilter {
    center?: CoordinatesItem;
    radius?: number;
    vertices?: CoordinatesItem[];
}

export interface GeographicPropertyFilter {
    propertyName?: UniversePropertyName;
    included?: (GeographicAreaFilter | null)[];
    excluded?: (GeographicAreaFilter | null)[];
}

export enum TextConstraintPredicateItem {
    StartsWith = 'StartsWith',
    Contains = 'Contains',
    EndsWith = 'EndsWith',
    DoesNotStartWith = 'DoesNotStartWith',
    DoesNotContain = 'DoesNotContain',
    DoesNotEndWith = 'DoesNotEndWith',
    Undefined = 'Undefined',
}

export interface TextualPredicateSearch {
    value?: string;
    predicate?: TextConstraintPredicateItem;
}

export interface TextPropertyFilter {
    propertyName?: UniversePropertyName;
    included?: TextualPredicateSearch[];
    excluded?: TextualPredicateSearch[];
}

export interface GlobalTextualSearchFilter {
    included?: string[];
    excluded?: string[];
    folderIds?: CaseId[];
}

// Caution: To search all flagged vertices,  use excluded=[null]
export interface FlagsFilter {
    caseId: CaseId;

    // Labels of flags
    included?: (string | null)[];

    // Labels of flags
    excluded?: (string | null)[];
}

export interface ExplorationFilter {
    included?: ExplorationId[];
    excluded?: ExplorationId[];
}

export interface FilterGroup {
    id?: IdFilter;
    type?: TypeFilter;
    specificValues?: PropertySpecificValueFilter[];
    ranges?: PropertyRangeFilter[];
    geographic?: GeographicPropertyFilter[];
    texts?: TextPropertyFilter[];
    textualSearch?: GlobalTextualSearchFilter;
    flags?: FlagsFilter[];
    exploration?: ExplorationFilter[];
}

export interface Filter {
    nothing?: boolean;
    all?: boolean;
    filterGroups?: FilterGroup[];
}

export type EdgeFilter = Filter;
export type VertexFilter = Filter;

export const NOTHING_FILTER: Filter = {
    nothing: true,
};

export const ALL_FILTER: Filter = {
    all: true,
};

export type FilterOperationType = 'intersect' | 'subtract' | 'union';

export interface FilterOperation {
    operationType?: FilterOperationType;
    operations?: FilterOperation[];

    filter?: Filter;
}
