import { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ArgButton, ArgInputTextArea, ArgModal, useClassNames, useArgNotifications } from 'src/components/basic';
import { FullOntology } from '../../types';
import ontologiesConnector from '../../../../connectors/ontologies-connector';
import { ArgFormLabel } from '../../../../../components/basic';

import './publish-ontology-modal.less';

const messages = defineMessages({
    publishOntology: {
        id: 'settings.universes.ontology.publish-ontology-modal.publishOntology',
        defaultMessage: 'Publish Ontology',
    },
    cancel: {
        id: 'settings.universes.ontology.publish-ontology-modal.cancel',
        defaultMessage: 'Cancel',
    },
    publish: {
        id: 'settings.universes.ontology.publish-ontology-modal.publish',
        defaultMessage: 'Publish',
    },
    comment: {
        id: 'settings.universes.ontology.publish-ontology-modal.comment',
        defaultMessage: 'Your comment',
    },
    placeHolder: {
        id: 'settings.universes.ontology.publish-ontology-modal.commentPlaceHolder',
        defaultMessage: 'Enter a comment',
    },
    publishingWarning: {
        id: 'settings.universes.ontology.publish-ontology-modal.publishingWarning',
        defaultMessage:
            'Do you wish to publish the modifications for the ontology for universe "{universeName}"? Publishing will automatically save a new version in the ontology history.',
    },
    publishingError: {
        id: 'settings.universes.ontology.publish-ontology-modal.publishingError',
        defaultMessage: 'Something went wrong while trying to publish the ontology.',
    },
});

interface PublishOntologyModalProps {
    closeModal: () => void;
    ontology: FullOntology;
}

export function PublishOntologyModal({
    closeModal,
    ontology,
}: PublishOntologyModalProps) {
    const classNames = useClassNames('publish-ontology-modal');
    const notifications = useArgNotifications();

    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await ontologiesConnector.publishOntology(ontology.id, 'Publish', comment, false);
            setLoading(false);
            closeModal();
        } catch (e) {
            notifications.snackError({ message: messages.publishingError }, e as Error);
            setLoading(false);
        }
    };

    return (
        <ArgModal
            size='medium'
            title={messages.publishOntology}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                        disabled={loading}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        onClick={() => handleSubmit()}
                        disabled={loading}
                        loading={loading}
                        label={messages.publish}
                        data-testid='delete'
                    />
                </div>
            }
        >
            <FormattedMessage
                {...messages.publishingWarning}
                values={{ universeName: ontology.name }}
            />
            <ArgFormLabel propertyName={messages.comment} addedRow={true}>
                <ArgInputTextArea
                    autoFocus={true}
                    data-testid='description'
                    placeholder={messages.placeHolder}
                    value={comment}
                    onChange={(newValue) => newValue && setComment(newValue)}
                />
            </ArgFormLabel>
        </ArgModal>
    );
}
