import dayjs from 'dayjs';
import { useCallback, useMemo, useRef } from 'react';
import { isDate } from 'lodash';


export function useDayjs(date?: Date): dayjs.Dayjs|undefined {
    const result = useMemo<dayjs.Dayjs|undefined>(() => {
        if (!date) {
            return undefined;
        }

        const result = dayjs(date);

        return result;
    }, [date?.getTime()]);

    return result;
}

export function useCacheDayjs(): (dayjs: dayjs.Dayjs|undefined|Date)=>(dayjs.Dayjs|undefined) {
    const dayjsRef = useRef<dayjs.Dayjs|undefined>(undefined);

    const cacheDayjs = useCallback((d?: dayjs.Dayjs|Date):dayjs.Dayjs|undefined => {
        if (!d) {
            return undefined;
        }
        if (isDate(d)) {
            d = dayjs(d);
        }

        if (d.isSame(dayjsRef.current)) {
            return dayjsRef.current;
        }

        dayjsRef.current = d;

        return d;
    }, []);

    return cacheDayjs;
}
