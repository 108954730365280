import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { defaultTo, isNumber } from 'lodash';
import { MapContainer, TileLayer } from 'react-leaflet';
import L, { LatLngLiteral, Map } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import '../../../../../utils/leaflet-draw/leaflet-draw';
import { getDataTestIdFromProps } from '../../../utils';
import { ArgMapZoomToolbox } from './zoom/arg-map-zoom-toolbox';
import { ArgMapCenterButton } from './center-button/arg-map-center-button';
import { ClassValue, useClassNames } from '../../../arg-hooks/use-classNames';
import { KeyBindingDescriptor } from '../../../keybindings/keybinding';
import { useComponentArgMapTiles } from '../context/arg-map-tiles-provider';
import { ArgMapMarker } from './marker/map-marker';
import { MarkerIcon } from '../common';

import './arg-map.less';

export interface ArgMapProps {
    center?: LatLngLiteral;
    onCenterChange?: ((center: L.LatLngExpression) => void) | undefined;
    initialZoom?: number;
    forwardRef?: MutableRefObject<Map | undefined>;
    className?: ClassValue;
    toolbar?: React.ReactNode;
    children?: React.ReactNode;
    zoomInKeyBinding?: KeyBindingDescriptor;
    zoomOutKeyBinding?: KeyBindingDescriptor;
}

export const DEFAULT_MAP_CENTER = [45, 2.230230] as unknown as L.LatLng;
export const DEFAULT_MAP_ZOOM = 1;
export const MAP_MAX_ZOOM = 18;
export const MAP_MIN_ZOOM = 0;

export function ArgMap(props: ArgMapProps) {
    const {
        className,
        forwardRef,
        initialZoom,
        center,
        onCenterChange,
        toolbar,
        children,
        zoomInKeyBinding,
        zoomOutKeyBinding,
    } = props;

    const classNames = useClassNames('arg-map');
    const dataTestId = getDataTestIdFromProps(props);
    const [mapTiles] = useComponentArgMapTiles();
    const [zoom, setZoom] = useState(initialZoom);

    const handleZoomChange = useCallback((zoom?: number) => {
        setZoom(zoom);
    }, []);

    // Use Leaflet DomUtils to manage cursor cause leaflet dinamically modify container class order
    const changeMapCursor = useCallback(() => {
        const mapContainer = forwardRef?.current?.getContainer();
        if (!mapContainer) {
            return;
        }
        const addOrRemoveClass = onCenterChange === undefined ? L.DomUtil.removeClass : L.DomUtil.addClass;
        addOrRemoveClass(mapContainer, 'edit-cursor');
    }, [onCenterChange, forwardRef]);

    useEffect(() => {
        changeMapCursor();
    }, [changeMapCursor]);

    return (
        <MapContainer
            whenCreated={(map: Map) => {
                if (forwardRef) {
                    forwardRef.current = map;
                    changeMapCursor();
                }
            }}
            data-testid={dataTestId}
            scrollWheelZoom={true}
            zoomControl={false}
            preferCanvas={true}
            minZoom={isNumber(mapTiles?.minZoom) ? mapTiles!.minZoom : MAP_MIN_ZOOM}
            maxZoom={isNumber(mapTiles?.maxZoom) ? mapTiles!.maxZoom : MAP_MAX_ZOOM}
            zoom={defaultTo(zoom, DEFAULT_MAP_ZOOM)}
            center={defaultTo(center, DEFAULT_MAP_CENTER)}
            className={classNames(className, '&')}
        >
            {mapTiles && (
                <TileLayer
                    attribution={mapTiles.attribution}
                    url={mapTiles.url}
                    maxZoom={mapTiles.maxZoom}
                    minZoom={mapTiles.minZoom}
                // @ts-ignore url param => https://leafletjs.com/reference-1.6.0.html#tilelayer
                //mode={Environment.defaultMapViewMode}
                />
            )}
            <ArgMapMarker
                icon={MarkerIcon}
                position={center}
                onChange={onCenterChange}
            />
            {/* Extra markers */}
            {children}
            <div className={classNames('&-right-controls')}>
                {toolbar}
                <ArgMapZoomToolbox
                    className={classNames('&-right-controls-zoom-toolbox')}
                    zoom={zoom}
                    onChange={handleZoomChange}
                    zoomInKeyBinding={zoomInKeyBinding}
                    zoomOutKeyBinding={zoomOutKeyBinding}
                />
            </div>
            <ArgMapCenterButton center={center} />
        </MapContainer>
    );
}
