import { ActionsEngine } from './actions-engine';
import { Action, ActionType } from './action';
import { ProgressMonitor } from '../../components/basic';

export interface DelegatedAction<A> {
    processDo: (action: A, progressMonitor: ProgressMonitor) => void;
    processUndo: (action: A, progressMonitor: ProgressMonitor) => void;
}

export class DelegatedActionRepository<T> {
    #delegatedActionsByType: Map<ActionType, DelegatedAction<any>[]> = new Map();

    registerDelegatedAction(actionType: ActionType, delegatedAction: DelegatedAction<any>) {
        let list = this.#delegatedActionsByType.get(actionType);
        if (!list) {
            list = [];
            this.#delegatedActionsByType.set(actionType, list);
        }

        list.unshift(delegatedAction);
    }

    unregisterDelegatedAction(actionType: ActionType) {
        const list = this.#delegatedActionsByType.get(actionType);
        if (!list) {
            return;
        }
        list.shift();

        if (list.length) {
            return;
        }

        this.#delegatedActionsByType.delete(actionType);
    }

    actionSupported(actionType: ActionType): boolean {
        const actions = this.#delegatedActionsByType.get(actionType);

        if (!actions || !actions.length) {
            return false;
        }

        return true;
    }

    executeDo(action: Action<T>, actionsEngine: ActionsEngine<T>, progressMonitor: ProgressMonitor): void {
        const delegatedActions = this.#delegatedActionsByType.get(action.type);

        if (!delegatedActions || !delegatedActions.length) {
            // throw new Error('This delegated action is not supported in the current context');

            return;
        }

        const delegatedAction = delegatedActions[0];

        delegatedAction.processDo(action, progressMonitor);
    }

    executeUndo(action: Action<T>, actionsEngine: ActionsEngine<T>, progressMonitor: ProgressMonitor): void {
        const delegatedActions = this.#delegatedActionsByType.get(action.type);

        if (!delegatedActions || !delegatedActions.length) {
            // throw new Error('This delegated action is not supported in the current context');

            return;
        }

        const delegatedAction = delegatedActions[0];

        delegatedAction.processUndo(action, progressMonitor);
    }
}
