import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { defineMessages } from 'react-intl';

import { ArgSize, ArgTag, computeItemBackgroundColor, computeItemIcon, useClassNames } from 'src/components/basic';
import { Role, RolesScope } from 'src/settings/models/dtoApi';
import { getTagBackgroundColor, getTagIcon } from '../utils';

import './tags-roles.less';

const messages = defineMessages({
    moreRolesCount: {
        id: 'settings.tags-roles.moreRolesCount',
        defaultMessage: '+{count}',
    },
});

const MAX_TAG_COUNT = 15;

export interface TagsRolesProps {
    roles?: Role[];
    tagSize?: ArgSize;
    tagMaxWidth?: number;
    maxTagShown?: number;
}

export function TagsRoles(props: TagsRolesProps) {
    const {
        roles,
        tagSize,
        tagMaxWidth,
        maxTagShown = MAX_TAG_COUNT,
    } = props;

    const classNames = useClassNames('settings-tags-roles');
    const [showTagPopover, setShowTagPopover] = useState<boolean>(false);

    const handleTagPopover = useCallback((): ReactNode => {
        if (!roles?.length) {
            return;
        }

        const _roles = roles.slice(maxTagShown - 1);

        return <div className={classNames('&-tagPopover')}>
            {_roles.map((role) => {
                const icon = computeItemIcon(role, (role: Role) => getTagIcon(role.scope as RolesScope));
                const backgroundColor = computeItemBackgroundColor(role, (role: Role) => getTagBackgroundColor(role.scope as RolesScope));

                return <ArgTag
                    key={role.id}
                    size={tagSize || 'medium'}
                    maxWidth={tagMaxWidth}
                    label={role.displayName}
                    tooltip={role.displayName}
                    closable={false}
                    icon={icon}
                    backgroundColor={backgroundColor}
                    className={classNames('&-tagPopover-tag')}
                />;
            })}
        </div>;
    }, [roles, maxTagShown, classNames, tagSize, tagMaxWidth]);


    const tagsComponent = useMemo<ReactNode>(() => {
        if (!roles?.length) {
            return null;
        }

        let moreTags = null;
        let _roles = roles;
        if (roles.length > maxTagShown) {
            _roles = roles.slice(0, maxTagShown - 1);

            moreTags = <ArgTag
                key='more-tags'
                size={tagSize || 'medium'}
                popoverVisible={showTagPopover}
                dropdown={true}
                onClick={() => setShowTagPopover(true)}
                data-testid='more-tags-dropdown'
                popover={handleTagPopover}
                onPopoverVisibleChange={setShowTagPopover}
                popoverClassName={classNames('&-more-tags-popover')}
                label={messages.moreRolesCount}
                messageValues={{ count: roles.length - maxTagShown + 1 }}
                className={classNames('&-tag', '&-more-tags', '&-tag-popover-trigger')}
            />;
        }

        const tagsComponent = <>
            {_roles.map((role) => {
                return <ArgTag
                    key={role.id}
                    size={tagSize || 'medium'}
                    maxWidth={tagMaxWidth}
                    closable={false}
                    className={classNames('&-tag', '&-tags-item')}
                    label={role.displayName}
                    icon={computeItemIcon(role, (role: Role) => getTagIcon(role.scope as RolesScope))}
                    backgroundColor={computeItemBackgroundColor(role, (role: Role) => getTagBackgroundColor(role.scope as RolesScope))}
                    tooltip={role.displayName}
                />;
            })}
            {moreTags}
        </>;

        return tagsComponent;
    }, [roles, maxTagShown, tagSize, showTagPopover, handleTagPopover, classNames, tagMaxWidth]);

    return <div className={classNames('&')}>
        { tagsComponent }
    </div>;
}
