import Debug from 'debug';

import { ExplorationBasicState } from './exploration-basic-state';
import { RtStateMessage } from '../../utils/rt-states/rt-basic-state';
import { UNIVERSE_ALL_EVENTS } from './events';
import { RtMessageEventList } from '../../utils/rt-states/events';
import { WebSocketChannel } from '../../components/ws/websocket-connector';
import { RtApi } from '../../utils/rt-states/rt-api';
import { getDataExplorationUniversesRtApi } from './rt-apis';
import { UniverseId } from '../model/universe';

const debug = Debug('argonode:exploration:states:UniverseState');

type UniverseStateActivitiesMessage = RtStateMessage;

export class UniverseState extends ExplorationBasicState<UniverseStateActivitiesMessage> {
    readonly #universeId: UniverseId;
    readonly #acceptedEvents: RtMessageEventList;

    constructor(url: string, universeId: UniverseId, acceptedEvents: RtMessageEventList = UNIVERSE_ALL_EVENTS) {
        super(url);

        this.#universeId = universeId;
        this.#acceptedEvents = acceptedEvents;
    }

    protected getRtApi(): RtApi {
        return getDataExplorationUniversesRtApi();
    }

    protected get serviceUrl(): string {
        return `/universes/${encodeURIComponent(this.#universeId)}`;
    }

    protected processMessage = async (channel: WebSocketChannel<UniverseStateActivitiesMessage>, type: string, message: UniverseStateActivitiesMessage): Promise<boolean | undefined> => {
        if (!this.#acceptedEvents[message.type]) {
            // Ignore message
            return true;
        }

        const ret = false;

        return ret;
    };
}
