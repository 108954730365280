import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { omit } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ToolContext, ToolTreeNode } from '../arg-tools/tool-context';
import { useToolNodes } from '../arg-tools/use-tool-nodes';
import { ArgToolbarItem } from '../arg-toolbar/arg-toolbar-item';
import { ArgToolbarDivider } from '../arg-toolbar/arg-toolbar-divider';
import { ArgMessageValues, ArgRenderedText, ArgSize } from '../types';
import { TooltipPlacement } from '../arg-tooltip/utils';
import { ArgButton } from '../arg-button/arg-button';
import { useUserConfiguration } from '../../../hooks/use-user-configuration';
import { useDebounce } from '../arg-hooks/use-debounce';
import { useToolItem } from '../arg-tools/use-tool-item';
import { renderText } from '../utils/message-descriptor-formatters';
import { walkNode } from '../arg-tools/utils';
import { getToolIcon, getToolLabel, getToolNavigateTo, getToolTooltip } from '../arg-tools/tool';

import './arg-floating-toolbar.less';


const DEFAULT_SIZE: ArgSize = 'small';
const DEFAULT_TOOLTIP_PLACEMENT: TooltipPlacement = 'top';

export interface ArgFloatingToolbarProps<T> {
    toolbarContext: ToolContext<T>;
    title?: ArgRenderedText;
    messageValues?: ArgMessageValues;
    prefix?: string;
    className?: ClassValue;
    environmentContext: T;
}

export interface ArgFloatingToolbarPosition {
    top: string;
    left: string;
}

const DRAGGING_TIME_OUT_MS = 1000;

export function ArgFloatingToolbar<T = undefined>(props: ArgFloatingToolbarProps<T>) {
    const {
        toolbarContext,
        title,
        prefix,
        messageValues,
        className,
        environmentContext,
    } = props;

    const classNames = useClassNames('arg-floating-toolbar');

    const [toolNodes] = useToolNodes(toolbarContext, environmentContext, prefix);

    const [movingDebounce, cancelMovingDebounce] = useDebounce(DRAGGING_TIME_OUT_MS);
    const [floatingBarPosition, setFloatingBarPosition] = useUserConfiguration<ArgFloatingToolbarPosition | undefined>(
        'ui.floating-bar.position',
        undefined,
    );

    const containerRef = useRef<HTMLDivElement | null>(null);
    const containerDeltaPositionRef = useRef<{
        top: number;
        left: number;
    } | undefined>(undefined);
    const cleanupEventsListenerRef = useRef<() => void>();

    const [menuToolPathSelected, setMenuToolPathSelected] = useState<string>();

    const menuToolNodeSelected = walkNode<ToolTreeNode<T> | undefined, T>(toolNodes, (node) => {
        if (node.path === menuToolPathSelected) {
            return node;
        }

        return undefined;
    }, environmentContext);


    const renderToolNode = useCallback((node: ToolTreeNode<T>, shouldRenderDivider: boolean): ReactNode => {
        const {
            path,
            type,
            children,
        } = node;

        const _restProps = omit(node, 'children');
        const _tooltip = getToolTooltip(node, environmentContext);
        const _navigateTo = getToolNavigateTo(node, environmentContext);

        const cls = {
            button: type === 'button',
        };

        switch (type) {
            case 'separator':
                shouldRenderDivider = false;

                return <ArgToolbarDivider
                    key={path}
                    className={classNames('&-divider')}
                />;

            case 'group': {
                if (!children || !children.length) {
                    return null;
                }

                return (
                    <React.Fragment key={path}>
                        {shouldRenderDivider &&
                            <ArgToolbarDivider
                                key={`${path}::divider`}
                                className={classNames('&-divider')}
                            />}

                        {children.map((child, childIndex) => {
                            return renderToolNode(child, childIndex > 0);
                        })}
                    </React.Fragment>
                );
            }
            case 'combo':
            case 'menu':
                shouldRenderDivider = true;

                return (
                    <ArgToolbarItem<T>
                        {..._restProps}
                        key={path}
                        type='button'
                        size={DEFAULT_SIZE}
                        tooltipPlacement={DEFAULT_TOOLTIP_PLACEMENT}
                        tooltip={_tooltip}
                        navigateTo={_navigateTo}
                        onClick={() => {
                            setMenuToolPathSelected(node.path);
                        }}
                        className={classNames('&-item', node.className, { button: true })}
                        environmentContext={environmentContext}
                        label={getToolLabel(node, environmentContext)}
                        icon={getToolIcon(node, environmentContext)}
                    />
                );
            default:
                shouldRenderDivider = true;

                return (
                    <ArgToolbarItem<T>
                        {..._restProps}
                        key={path}
                        size={DEFAULT_SIZE}
                        tooltipPlacement={DEFAULT_TOOLTIP_PLACEMENT}
                        tooltip={_tooltip}
                        navigateTo={_navigateTo}
                        className={classNames('&-item', node.className, cls)}
                        environmentContext={environmentContext}
                        label={getToolLabel(node, environmentContext)}
                        icon={getToolIcon(node, environmentContext)}
                    />
                );
        }
    }, [classNames, environmentContext]);

    const computeDeltaPosition = useCallback((mouseLeft: number, mouseTop: number) => {
        let parentTop = 0;
        let parentLeft = 0;

        if (containerRef.current) {
            let element: HTMLElement | null | undefined = containerRef.current as HTMLElement;

            do {
                parentTop += element?.offsetTop || 0;
                parentLeft += element?.offsetLeft || 0;

                element = element?.offsetParent as HTMLElement;
            } while (element);
        }

        const left = mouseLeft - parentLeft;
        const top = mouseTop - parentTop;

        return { left, top };
    }, []);

    const handleChangeFloatingBarPosition = useCallback(() => {
        if (!containerRef.current) {
            return;
        }

        setFloatingBarPosition({
            left: containerRef.current.style.left,
            top: containerRef.current.style.top,
        });
    }, [setFloatingBarPosition]);

    const handleChangeInternalPosition = useCallback((mouseLeft: number, mouseTop: number) => {
        const containerDeltaLeft = containerDeltaPositionRef.current?.left;
        const containerDeltaTop = containerDeltaPositionRef.current?.top;

        if (containerDeltaLeft === undefined || containerDeltaTop === undefined) {
            return;
        }

        const parentHeight = containerRef.current?.parentElement?.offsetHeight;
        const parentWidth = containerRef.current?.parentElement?.offsetWidth;

        const floatingToolbarHeight = containerRef.current?.offsetHeight;
        const floatingToolbarWidth = containerRef.current?.offsetWidth;

        if (!parentHeight || !parentWidth ||
            !floatingToolbarHeight || !floatingToolbarWidth
        ) {
            return;
        }

        if (containerRef.current?.offsetParent) {
            let element: HTMLElement | null | undefined = containerRef.current?.offsetParent as HTMLElement;

            do {
                mouseTop -= element?.offsetTop || 0;
                mouseLeft -= element?.offsetLeft || 0;

                element = element?.offsetParent as HTMLElement;
            } while (element);
        }
        let left = mouseLeft - containerDeltaLeft;
        let top = mouseTop - containerDeltaTop;

        if (left < 0) {
            left = 0;
        } else if (left > (parentWidth - floatingToolbarWidth)) {
            left = parentWidth - floatingToolbarWidth;
        }

        if (top < 0) {
            top = 0;
        } else if (top > (parentHeight - floatingToolbarHeight)) {
            top = parentHeight - floatingToolbarHeight;
        }

        const leftPercentage = (left / (parentWidth || 0) * 100).toFixed(2);
        const topPercentage = (top / (parentHeight || 0) * 100).toFixed(2);

        if (!containerRef.current) {
            return;
        }

        containerRef.current.style.top = `${topPercentage}%`;
        containerRef.current.style.left = `${leftPercentage}%`;
        containerRef.current.style.right = 'unset';
        containerRef.current.style.bottom = 'unset';

        cancelMovingDebounce();
        movingDebounce(handleChangeFloatingBarPosition);
    }, [cancelMovingDebounce, handleChangeFloatingBarPosition, movingDebounce]);

    const handleMouseUp = useCallback(() => {
        if (!cleanupEventsListenerRef.current) {
            return;
        }

        cleanupEventsListenerRef.current();
        cleanupEventsListenerRef.current = undefined;
        containerDeltaPositionRef.current = undefined;
    }, []);

    const handleMouseMove = useCallback((event: MouseEvent) => {
        handleChangeInternalPosition(event.clientX, event.clientY);
    }, [handleChangeInternalPosition]);

    const handleMouseDown = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        containerDeltaPositionRef.current = computeDeltaPosition(event.clientX, event.clientY);

        cleanupEventsListenerRef.current = () => {
            document.body.removeEventListener('mouseup', handleMouseUp, { capture: true });
            document.body.removeEventListener('mousemove', handleMouseMove, { capture: true });
        };

        document.body.addEventListener('mouseup', handleMouseUp, { capture: true });
        document.body.addEventListener('mousemove', handleMouseMove, { capture: true });
    }, [computeDeltaPosition, handleMouseMove, handleMouseUp]);

    const renderTitle = useCallback(() => {
        const cls = {
            empty: !title,
        };

        return (
            <div className={classNames('&-item-title', cls)}>
                {renderText(title, messageValues)}
            </div>
        );
    }, [classNames, messageValues, title]);

    const handleContainerRef = useCallback((container: HTMLDivElement | null) => {
        if (!container) {
            return;
        }

        containerRef.current = container;

        if (!floatingBarPosition) {
            return;
        }

        container.style.top = floatingBarPosition.top;
        container.style.left = floatingBarPosition.left;
        container.style.right = 'unset';
        container.style.bottom = 'unset';
    }, [floatingBarPosition]);

    useToolItem(toolbarContext, {
        path: 'begin/title',
        type: 'custom',
        order: 50,
    }, {
        customRender: renderTitle,
    });

    useEffect(() => {
        return () => {
            cleanupEventsListenerRef.current?.();
        };
    }, []);

    let body: ReactNode;

    if (menuToolNodeSelected) {
        body = (
            <React.Fragment key='sub'>
                <ArgButton
                    key='sub-button'
                    size={DEFAULT_SIZE}
                    type='ghost'
                    icon='icon-triangle-left'
                    className={classNames('&-back')}
                    onClick={() => {
                        setMenuToolPathSelected(undefined);
                    }}
                />

                <ArgToolbarDivider key='sub-divider' className={classNames('&-divider')} />

                {menuToolNodeSelected.children?.map((child, childIndex) => {
                    return renderToolNode(child, childIndex > 0);
                })}
            </React.Fragment>
        );
    } else {
        body = toolNodes.map((node, nodeIndex) => {
            return renderToolNode(node, nodeIndex > 0);
        });
    }

    return (
        <div
            ref={handleContainerRef}
            className={classNames('&', className)}
            onMouseDownCapture={handleMouseDown}
        >
            {body}
        </div>
    );
}
