import { useMemo } from 'react';
import { Saturation } from 'react-color/lib/components/common';
import { SaturationProps } from 'react-color/lib/components/common/Saturation';
import { ColorFormats } from 'tinycolor2';

import { useClassNames } from '../../../../basic';
import { CustomSaturationPointer } from './custom-saturation-pointer';

import './color-palette-picker-saturation.less';

interface ColorPalettePickerSaturationProps extends Omit<SaturationProps, 'color'> {
    hsv?: ColorFormats.HSVA;
    hsl?: ColorFormats.HSLA;
}

export function ColorPalettePickerSaturation(props: ColorPalettePickerSaturationProps) {
    const classNames = useClassNames('color-palette-picker-saturation');
    const cleanProps = { ...props, className: undefined };

    // The type transformation is due to React Color types issue. See (https://github.com/casesandberg/react-color/blob/master/examples/custom-pointer/src/MyPicker.js)
    const customPointer = useMemo<React.ReactNode>(() => (cleanProps?.pointer ?? CustomSaturationPointer as unknown as React.ReactNode), [cleanProps?.pointer]);

    return (
        <div className={classNames('&', props.className)}>
            <Saturation {...cleanProps} pointer={customPointer} />
        </div>
    );
}
