import {
    CSSProperties,
    DragEvent,
    DragEventHandler,
    MouseEvent,
    MutableRefObject,
    ReactNode,
    RefObject,
    useRef,
} from 'react';
import { get, isFunction, isString, sortBy } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgTooltip2 } from '../arg-tooltip/arg-tooltip2';
import { KeyAndLine } from '../../../utils/scroll-display-manager';
import {
    ArgTable4AdditionalRow,
    ArgTable4Column,
    ArgTable4OnDragStartHandler,
    ArgTable4RowStateInfo,
} from './arg-table4';
import { computeItemKey } from '../utils';
import { ArgTable4CellIndex, ArgTable4Cursor } from './types';
import { ArgTable4SelectionManager } from './arg-table4-selection-manager';
import { isArgTable4CellIndex, isArgTableRowState } from './utils';
import { useStateId } from '../utils/use-stateId';
import { ArgTableRowState } from '../arg-providers/data-provider';

import './virtual-column.less';

export const STYLE_DISPLAY_NONE = {
    display: 'none',
};

interface KeyAndLineWithSpan extends KeyAndLine {
    span?:number;
}

interface VirtualColumnProps<T> {
    column:ArgTable4Column<T>;
    first?:boolean;
    last?:boolean;
    totalHeight:number;
    elements:KeyAndLine[];
    left:number;
    columnWidth:number;
    scrollTop?:number;
    rowsCache:Map<number, ArgTable4RowStateInfo<T>>;
    itemsCount:number;
    className?:ClassValue;
    renderLoadingCell?:(column:ArgTable4Column<T>, index?:number) => ReactNode;
    renderErrorCell?:(column:ArgTable4Column<T>, index?:number, error?:Error) => ReactNode;
    dragTransform?:string;
    dragged?:boolean;
    onDragStart?:ArgTable4OnDragStartHandler<T>;
    onDragEnd?:(event:DragEvent) => void;
    onCursorChange?:(cursor:ArgTable4Cursor) => void;
    columnIndex:number;
    cursor?:ArgTable4Cursor;
    selectionManager?:ArgTable4SelectionManager;
    hovered?:boolean;
    rowHeight:number;
    additionalRowsByRowIndex:Record<number, ArgTable4AdditionalRow<T>[]>;
    search?: string;
    columnRef: MutableRefObject<HTMLElement|null>;
}

export function VirtualColumn<T>(props:VirtualColumnProps<T>) {
    const {
        elements,
        first,
        last,
        totalHeight,
        left,
        columnWidth,
        scrollTop,
        rowsCache,
        column,
        itemsCount,
        className,
        renderLoadingCell,
        renderErrorCell,
        dragTransform,
        dragged,
        onDragStart,
        onDragEnd,
        onCursorChange,
        columnIndex,
        cursor,
        selectionManager,
        hovered,
        rowHeight,
        additionalRowsByRowIndex,
        search,
        columnRef,
    } = props;

    const classNames = useClassNames('arg-table4-virtual-column');
    const elementRef = useRef<HTMLDivElement>(null);
    useStateId(selectionManager);

    let elementsWithSpan:KeyAndLineWithSpan[] = elements;
    if (column.compareCells && column.mergeSimilarCells) {
        let remaining = 0;
        const sorted = sortBy(elements, 'row');
        const elementByRow:Record<number, KeyAndLineWithSpan> = {};
        sorted.forEach((element, i) => {
            const rowData = rowsCache.get(element.row)?.data;
            const row = element.row;
            if (element.row === -1 || isArgTableRowState(rowData) || !rowData) {
                elementByRow[row] = element;

                return;
            }
            if (remaining > 0) {
                remaining--;

                elementByRow[row] = {
                    ...element,
                    row: -1,
                };

                return;
            }
            let span = 1;

            if (selectionManager?.isSelected({ rowIndex: element.row, columnIndex })) {
                return;
            }

            for (let j = i + 1; j < sorted.length; j++) {
                const nextRowData = rowsCache.get(sorted[j].row)?.data;
                if (isArgTableRowState(nextRowData) || !nextRowData || selectionManager?.isSelected({
                    rowIndex: sorted[j].row,
                    columnIndex,
                })) {
                    break;
                }
                const previousRow = sorted[j - 1].row;
                if (column.compareCells?.(rowData, nextRowData) === 0 && !additionalRowsByRowIndex[previousRow]?.length) {
                    remaining++;
                    if (previousRow !== sorted[j].row) {
                        span++;
                    }
                } else {
                    break;
                }
            }

            elementByRow[row] = {
                ...element,
                span,
                style: {
                    ...element.style,
                    height: `${span * rowHeight}px`,
                },
            };
        });
        elementsWithSpan = sorted.map((element) => {
            return elementByRow[element.row] || element;
        });
    }

    const visibleChildren = elementsWithSpan.map((element) => {
        if (element.row < 0) {
            return (
                <div
                    key={ element.key }
                    data-rowindex=''
                    style={ STYLE_DISPLAY_NONE }
                />
            );
        }
        if (element.row >= itemsCount) {
            return (
                <div className='outside' key={ element.key } style={ element.style } data-rowindex={ element.row } />
            );
        }

        const rowCache = rowsCache.get(element.row);
        if (!rowCache) {
            return null;
        }

        const { data: rowData, className: rowClassName, draggable: draggableRow } = rowCache;
        const isDraggable = !!draggableRow;

        let cellComponent;

        const cellProps:Record<string, any> = {};
        if (isDraggable && !isArgTableRowState(rowData) && onDragStart) {
            const handleOnDragStart:DragEventHandler = (event) => {
                //event.stopPropagation();
                onDragStart(event, rowData as T);
            };

            cellProps.draggable = true;
            cellProps.onDragStart = handleOnDragStart;
            cellProps.onDragEnd = onDragEnd;
        }

        let cls = rowClassName;
        let cellData;
        let pureRowData:T | undefined;
        let rowKey:string | undefined;
        if (rowData === ArgTableRowState.Loading) {
            cls = 'loading';
            if (renderLoadingCell) {
                cellComponent = renderLoadingCell(column, element.row);
            } else {
                cellComponent = <div className='default-render arg-skeleton' />;
            }
        } else if (rowData === ArgTableRowState.Error || rowData === ArgTableRowState.NotFound) {
            cls = 'error';
            if (renderErrorCell) {
                cellComponent = renderErrorCell(column, element.row);
            } else {
                cellComponent = <div className='default-render' />;
            }
        } else if (rowData) {
            pureRowData = rowData;

            if (column.dataIndex) {
                cellData = get(rowData, column.dataIndex);
            }

            rowKey = computeItemKey(rowData, column.getRowKey, '');
            const onClick = (event:MouseEvent) => {
                if (!rowKey || column.selectable === false) {
                    return;
                }
                const newCursor:ArgTable4Cursor & ArgTable4CellIndex = {
                    columnIndex,
                    columnKey: column.key,
                    rowIndex: element.row,
                    rowKey,
                };
                const newCellIndexTo:ArgTable4CellIndex = {
                    columnIndex,
                    rowIndex: element.row + (element.span || 1) - 1,
                };
                if (!selectionManager) {
                    return;
                }

                if (event.shiftKey && isArgTable4CellIndex(cursor)) {
                    // clear the text selection: shift+click creates an annoying text selection. Current solution is not great as there is a glitch: selection appears then disappear (despite of
                    // useCapture). A better solution would to use `user-select: none` but it would require to enable copying selected cells (TODO).
                    window.getSelection()?.empty();
                    if (!selectionManager.isSelected(cursor)) {
                        selectionManager.removeRange(cursor, newCursor);
                    } else {
                        selectionManager.addCellRange(cursor, [newCursor, newCellIndexTo]);
                    }
                } else if ((event.ctrlKey || event.metaKey)) {
                    onCursorChange?.(newCursor);
                    if (selectionManager.isSelected(newCursor)) {
                        selectionManager.removeCell(newCursor);
                    } else {
                        selectionManager.addCellRange(newCursor, newCellIndexTo);
                    }
                } else {
                    onCursorChange?.(newCursor);
                    if (selectionManager.isSelected(newCursor)) {
                        selectionManager.removeRange(newCursor, newCellIndexTo);
                    } else {
                        selectionManager.addCellRange(newCursor, newCellIndexTo, true);
                    }
                }
            };

            cellProps.onClickCapture = onClick;

            if (column.render) {
                cellComponent = column.render(cellData, rowData, element.row, search);
                if ((isString(cellComponent) && cellComponent && column.cellTooltip !== false) || column.cellTooltip) {
                    // TODO: Fix showTooltip, currently elementRef reference the last cell of the columns so its the same for all rows. Refactor into CellComponent to use useRef.
                    // const enableTooltip = elementRef.current && (elementRef.current?.offsetWidth < elementRef.current?.scrollWidth
                    //     || cellData.length > 2);
                    const tooltipTitle = isFunction(column.cellTooltip) ? column.cellTooltip(cellData, rowData) : cellComponent;
                    const tooltipClassname = column.cellTooltipClassName;
                    cellComponent = (
                        <ArgTooltip2
                            className={ classNames('&-tooltip', tooltipClassname) }
                            title={ tooltipTitle }
                            // open={enableTooltip ? undefined : false}
                            placement='bottomLeft'
                        >
                            <div className={ classNames('clamp-2', 'string-render') } ref={ elementRef }>
                                { cellComponent }
                            </div>
                        </ArgTooltip2>
                    );
                }
            } else {
                cellComponent = <div className='cell-render'>{ cellData }</div>;
            }
        }

        let cellClassName = column.cellClassName;
        if (isFunction(cellClassName)) {
            cellClassName = cellClassName(cellData, pureRowData, element.row);
        }

        const isCursorOnCell = cursor?.columnKey === column.key && cursor?.rowKey === rowKey;
        const isSelected = selectionManager?.isSelected({ rowIndex: element.row, columnIndex });
        const topBorder = element.row > 0 && !!additionalRowsByRowIndex[element.row - 1]?.length;
        const cellCls = {
            '&-cursor': isCursorOnCell,
            '&-selected': isSelected,
            '&-merged': element.span && element.span > 1,
            '&-top-border': topBorder,
        };

        const cellStyle = { ...element.style, ...column.cellStyle };

        return (
            <div { ...cellProps }
                 className={ classNames(cls, cellClassName, cellCls) }
                 key={ element.key }
                 style={ cellStyle }
                 data-rowindex={ element.row }
            >
                { cellComponent }
            </div>
        );
    });

    const bodyStyle:CSSProperties = {
        height: `${totalHeight}px`,
        left: `${left}px`,
        width: `${columnWidth}px`,
        transform: dragTransform,
    };
    if (scrollTop) {
        bodyStyle.top = `${-scrollTop}px`;
    }

    const cls = {
        first,
        last,
        dragged,
        'drag-transform': dragTransform,
        '&-hovered': hovered,
    };

    return (
        <div
            className={ classNames('&', cls, column.className, className) }
            style={ bodyStyle }
            data-column={ column.key }
            ref={columnRef as RefObject<HTMLDivElement>}
        >
            { visibleChildren }
        </div>
    );
}
