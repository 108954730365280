import { createContext, useContext } from 'react';

import { ListOntologies } from 'src/settings/models/dtoApi';
import { ProgressMonitor } from '../../../../components/basic';

export interface OntologiesStateContextType {
    listOntologies: ListOntologies;
    updateOntologies: (progressMonitor: ProgressMonitor) => Promise<void>;
}

export const OntologiesStateContext = createContext<OntologiesStateContextType | undefined>(undefined);

export function useOntologiesContext(): OntologiesStateContextType {
    const ontologiesContext = useContext(OntologiesStateContext);

    if (ontologiesContext === undefined) {
        throw new Error('useOntologiesContext must be used within a OntologiesStateContext');
    }

    return ontologiesContext;
}
