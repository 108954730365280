import { ClassValue, useClassNames } from 'src/components/basic';

import './custom-hue-slider.less';

interface CustomHueSliderProps {
    className?: ClassValue;
}

export function CustomHueSlider({ className }: CustomHueSliderProps) {
    const classNames = useClassNames('custom-hue-slider');

    return (
        <div className={classNames('&', className)} />
    );
}
