import { ArgumentArray } from 'classnames';
import { useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ArgButton, ArgInputSearch, ArgTooltip2, highlightSplit, MESSAGE_DESCRIPTOR_FORMATTERS, normalizeText, useArgNotifications } from 'src/components/basic';
import { FullOntologyLinkType, FullOntologyObjectType } from 'src/settings/universes/ontology/types';

const messages = defineMessages({
    objectTypeProperties: {
        id: 'settings.feed-sources-properties-list.ObjectTypeProperties',
        defaultMessage: 'Object properties list.',
    },
    searchProperties: {
        id: 'settings.feed-sources-properties-list.SearchProperties',
        defaultMessage: 'Search',
    },
    propertyCopiedToClipboard: {
        id: 'settings.feed-sources-properties-list.PropertyCopiedToClipboard',
        defaultMessage: 'The Property <grey>"{propertyName}"</grey> is copied to the clipboard.',
    },
});


interface PropertiesListPanelProps {
    vertexOrEdge: FullOntologyObjectType | FullOntologyLinkType;
    classNames: (...args: ArgumentArray) => string;
}

export function PropertiesListPanel(props: PropertiesListPanelProps) {
    const { vertexOrEdge, classNames } = props;

    const [searchText, setSearchText] = useState<string>('');
    const propertyList = useMemo(() => {
        const allProperties = vertexOrEdge.properties;
        const filteredProperties = allProperties.filter(property => {
            return normalizeText(property.name).includes(normalizeText(searchText)) || normalizeText(property.displayName).includes(normalizeText(searchText));
        });

        return filteredProperties;
    }, [vertexOrEdge, searchText]);
    const notifications = useArgNotifications();

    return (
        <div className={classNames('&-editors-content-panel-properties-container')}>
            <FormattedMessage {...messages.objectTypeProperties} />
            <ArgInputSearch
                placeholder={messages.searchProperties}
                onChange={value => setSearchText(value || '')}
                debounce={300}
            />
            <div className={classNames('&-editors-content-panel-properties-list')}>
                {
                    propertyList.map(property => (
                        <ArgTooltip2
                            key={property.name}
                            title={`${property.name} (${property.displayName})`}
                            placement='left'
                        >
                            <div className={classNames('&-editors-content-panel-properties-list-item')}>
                                <span>{highlightSplit(property.name, searchText)}</span>
                                <span className={classNames('&-editors-content-panel-properties-list-item-displayName')}>({highlightSplit(property.displayName, searchText)})</span>
                                <CopyToClipboard
                                    key='copy'
                                    text={property.name}
                                    onCopy={(text) => notifications.snackInfo({ message: messages.propertyCopiedToClipboard }, { propertyName: text, ...MESSAGE_DESCRIPTOR_FORMATTERS })}
                                >
                                    <ArgButton
                                        type='ghost'
                                        icon='icon-copy'
                                        className={classNames('&-editors-content-panel-properties-list-item-copy')}
                                    />
                                </CopyToClipboard>
                            </div>
                        </ArgTooltip2>
                    ))
                }
            </div>
        </div>
    );
}
