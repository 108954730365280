import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
    ArgButton,
    ArgChangeReason,
    ArgInputKey,
    ArgPopover,
    ClassValue,
    highlightSplit,
    KeyBindingDescriptor,
    KeyBindingKeys,
    KeyBindingScopeDescriptor,
    KeyCommand,
    useClassNames,
} from '../../basic';

import './keybinding-editor.less';

const messages = defineMessages({
    editLockedTooltip: {
        id: 'common.keybindings-editor.EditLocked',
        defaultMessage: 'This shortcut is not editable',
    },
    canNotResetTooltip: {
        id: 'common.keybindings-editor.CanNotResetTooltip',
        defaultMessage: 'You cannot reset because it is the default shortcut',
    },
    useKeyboardPlaceholder: {
        id: 'common.keybindings-editor.UseKeyboardPlaceholder',
        defaultMessage: 'Use keyboard',
    },
    editKeyboardShortcut: {
        id: 'common.keybindings-editor.EditKeyboardShortcut',
        defaultMessage: 'Edit keyboard shortcut',
    },
    resetKeyboardShortcut: {
        id: 'common.keybindings-editor.ResetKeyboardShortcut',
        defaultMessage: 'Reset keyboard shortcut',
    },
    validKeyboardShortcut: {
        id: 'common.keybindings-editor.ValidKeyboardShortcut',
        defaultMessage: 'Valid keyboard shortcut change',
    },
    cancelKeyboardShortcut: {
        id: 'common.keybindings-editor.CancelKeyboardShortcut',
        defaultMessage: 'Cancel keyboard shortcut change',
    },
    alreadyDefined: {
        id: 'common.keybindings-editor.AlreadyDefined',
        defaultMessage: 'This shortcut is already used by "{name}". The modification will result in its deletion.',
    },
    invalid: {
        id: 'common.keybindings-editor.InvalidKeyboardShortcut',
        defaultMessage: 'This shortcut is not allowed',
    },
});

interface ErrorDescriptor {
    level: 'caution' | 'invalid';
    message: string;
}

interface KeyBindingEditorProps {
    className?: ClassValue;
    keyBindingDescriptor: KeyBindingDescriptor;
    scopeDescriptor: KeyBindingScopeDescriptor;
    userKeys?: { id: string; keys: KeyCommand }[];
    searchedToken?: string;
    onUserConfigurationChange: (newConfig: KeyCommand | undefined) => void;
    definedKeys: KeyBindingDescriptor[];
    keyBindingSettingsMode?: boolean;
}

export function KeyBindingEditor(props: KeyBindingEditorProps) {
    const {
        className,
        keyBindingDescriptor,
        userKeys,
        searchedToken,
        onUserConfigurationChange,
        definedKeys,
        scopeDescriptor,
        keyBindingSettingsMode,
    } = props;


    const intl = useIntl();

    const classNames = useClassNames('keybindings-editor');

    const [warnMessage, setWarnMessage] = useState<ErrorDescriptor>();

    const userKeyCommand = userKeys?.find((b) => b.id === keyBindingDescriptor.id);

    const [editMode, setEditMode] = useState<boolean>(false);

    const editedKeyCommandRef = useRef<KeyCommand>();

    const handleEditClick = useCallback(() => {
        editedKeyCommandRef.current = '';
        setEditMode(true);
    }, []);

    const handleResetClick = useCallback(() => {
        onUserConfigurationChange && onUserConfigurationChange(undefined);
    }, [onUserConfigurationChange]);

    const handleCancelClick = useCallback(() => {
        setEditMode(false);
        setWarnMessage(undefined);
    }, []);

    const handleValidClick = useCallback(() => {
        setEditMode(false);
        setWarnMessage(undefined);

        const newKeyCommand = editedKeyCommandRef.current;
        onUserConfigurationChange(newKeyCommand);
    }, [onUserConfigurationChange]);

    const handleKeyCommandChange = useCallback((value: KeyCommand, reason: ArgChangeReason) => {
        editedKeyCommandRef.current = value;

        if (!value) {
            return;
        }

        const alreadyDefined = definedKeys.find((k) => {
            if (k.id === keyBindingDescriptor.id) {
                return undefined;
            }
            const u = userKeys?.find((u) => u.id === k.id);
            if (u) {
                if (u?.keys === value) {
                    return k;
                }

                return undefined;
            }

            if (k.defaultKeys === value) {
                return k;
            }

            return undefined;
        });

        if (!alreadyDefined) {
            setWarnMessage(undefined);

            return;
        }

        const name = intl.formatMessage(alreadyDefined.name);

        if (alreadyDefined.locked) {
            setWarnMessage({
                level: 'invalid',
                message: intl.formatMessage(messages.invalid, { name }),
            });

            return;
        }

        setWarnMessage({
            level: 'caution',
            message: intl.formatMessage(messages.alreadyDefined, { name }),
        });
    }, [definedKeys, userKeys, keyBindingDescriptor, intl]);

    const handleKeyCommandBlur = useCallback(() => {
        setEditMode(false);
        setWarnMessage(undefined);
    }, []);

    let label: ReactNode = intl.formatMessage(keyBindingDescriptor.name);
    if (typeof (label) === 'string' && searchedToken) {
        label = highlightSplit(label, searchedToken);
    }

    const cls = {
        edited: editMode,
    };

    let component = <div className={classNames('&', className, cls)}>
        <KeyBindingKeys
            className={classNames('&-keys')}
            keyBindingDescriptor={keyBindingDescriptor}
            scope={scopeDescriptor}
        />
        <div className={classNames('&-title')}>
            {label}
        </div>
        {!keyBindingSettingsMode && <div className={classNames('&-editor')}>
            {!editMode && !keyBindingDescriptor.locked &&
                <ArgButton
                    key='reset'
                    className={classNames('&-editor-edit')}
                    type='ghost'
                    size='medium'
                    icon='icon-reset'
                    disabled={userKeyCommand === undefined}
                    tooltipPlacement='left'
                    tooltip={userKeyCommand === undefined ? messages.canNotResetTooltip : messages.resetKeyboardShortcut}
                    onClick={handleResetClick}
                />}
            {!editMode && <ArgButton
                key='edit'
                className={classNames('&-editor-reset')}
                type='ghost'
                size='medium'
                icon='icon-edit-pencil'
                disabled={keyBindingDescriptor.locked}
                tooltipPlacement='right'
                tooltip={keyBindingDescriptor.locked ? messages.editLockedTooltip : messages.editKeyboardShortcut}
                onClick={handleEditClick}
            />}

            {editMode && <ArgInputKey
                key='keyCommand'
                size='medium'
                placeholder={messages.useKeyboardPlaceholder}
                autoFocus={true}
                state={warnMessage?.level}
                onChange={handleKeyCommandChange}
                onBlur={handleKeyCommandBlur}
                className={classNames('&-editor-keyCommand')}
            />}
            {editMode && <ArgButton
                key='valid'
                className={classNames('&-editor-valid')}
                type='ghost'
                size='medium'
                icon='icon-validate'
                disabled={warnMessage?.level === 'invalid'}
                tooltipPlacement='bottom'
                onClick={handleValidClick}
                tooltip={messages.validKeyboardShortcut}
                clickOnMouseDown={true}
            />}
            {editMode && <ArgButton
                key='cancel'
                className={classNames('&-editor-cancel')}
                type='ghost'
                size='medium'
                icon='icon-cross'
                tooltipPlacement='bottom'
                tooltip={messages.cancelKeyboardShortcut}
                onClick={handleCancelClick}
            />}
        </div>}
    </div>;

    component = (
        <ArgPopover
            open={!!warnMessage}
            content={warnMessage?.message}
            trigger={undefined}
            placement='bottomRight'>
            {component}
        </ArgPopover>
    );

    return component;
}
