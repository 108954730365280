import React, {
    MouseEvent,
    ReactElement,
    ReactNode,
    Ref,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import { ArgMessageValues, ArgRenderedText, ArgSize } from '../types';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { getDataTestIdFromProps } from '../utils';
import { DEFAULT_SIZE, DEFAULT_TOOLTIP_BUTTON_DELAY, DEFAULT_TOOLTIP_PLACEMENT } from '../defaults';
import { ArgIconCheckbox, ArgIconCheckboxStates } from './arg-icon-checkbox';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgTooltip2, TooltipPlacement } from '../arg-tooltip/arg-tooltip2';

import './arg-checkbox.less';

export const TwoStates = [false, true];
export const ThreeStates = [false, true, undefined];
export const MinusStates = [false, true, 'minus'] as Array<boolean | 'minus'>;

export interface ArgCheckboxProps<T> {
    id?: string;
    size?: ArgSize | 'node';
    label?: ArgRenderedText;
    messageValues?: ArgMessageValues;
    className?: ClassValue;
    labelClassName?: ClassValue;
    value?: T;
    states: T[];

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;

    initialValue?: T;
    onChange?: (value: T) => void;
    children?: ReactNode;
    disabled?: boolean;
    buttonRef?: Ref<HTMLElement>;
    icon?: ReactNode;

    readOnly?: boolean;
}

export function ArgCheckboxStates<T>(props: ArgCheckboxProps<T>) {
    const {
        size = DEFAULT_SIZE,
        className,
        id,
        label,
        messageValues,
        initialValue,
        value,
        states,
        children,
        buttonRef: externalButtonRef,
        disabled,
        onChange,
        icon,
        readOnly,

        tooltip,
        tooltipPlacement,
        tooltipClassName,
        labelClassName,
    } = props;

    const classNames = useClassNames('arg-checkbox');

    const useInternalValue = !('value' in props);

    const _initialValue = ('initialValue' in props) ? initialValue : states[0];

    const dataTestId = getDataTestIdFromProps(props);

    const [internalValue, setInternalValue] = useState<T | undefined>(_initialValue);

    const buttonRef = useRef<HTMLButtonElement>(null);

    useImperativeHandle(externalButtonRef, () => buttonRef.current!);

    const handleOnClick = useCallback((event: MouseEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();

        let _value = value;
        if (useInternalValue) {
            _value = internalValue;
        }

        let nextIndex = 0;
        if (_value !== undefined) {
            const idx = states.indexOf(_value);
            if (idx >= 0) {
                nextIndex = (idx + 1) % states.length;
            }
        }

        const newValue = states[nextIndex];

        if (useInternalValue) {
            setInternalValue(newValue);
        }

        onChange?.(newValue);
    }, [onChange, states, value, useInternalValue, internalValue]);

    const labelNode = label || children;

    const cls = {
        disabled,
        [`size-${size}`]: true,
    };

    let _state = value;
    if (useInternalValue) {
        _state = internalValue;
    }

    let checkButton = (
        <button
            className={classNames('&', className, cls)}
            ref={buttonRef}
            id={id}
            data-testid={dataTestId}
            type='button'
            onClick={handleOnClick}
            disabled={disabled || readOnly}
        >
            {icon
                ? icon
                : (
                    <ArgIconCheckbox
                        size={size} state={_state as ArgIconCheckboxStates}
                        className={classNames('&-icon')}
                    />
                )}
            {labelNode && (
                <span className={classNames('&-label', labelClassName)}>
                    {renderText(labelNode, messageValues)}
                </span>
            )}
        </button>
    );

    if (tooltip) {
        checkButton =
            <ArgTooltip2
                key='tooltip'
                title={tooltip}
                messageValues={messageValues}
                data-testid='tooltip'
                className={classNames('&-tooltip', tooltipClassName)}
                placement={tooltipPlacement || DEFAULT_TOOLTIP_PLACEMENT}
                mouseEnterDelay={DEFAULT_TOOLTIP_BUTTON_DELAY}
            >
                {checkButton}
            </ArgTooltip2>;
    }

    return checkButton;
}

export function ArgCheckbox(props: Omit<ArgCheckboxProps<boolean>, 'states'>): ReactElement {
    return <ArgCheckboxStates {...props} states={TwoStates} />;
}

export function ArgCheckboxUndefined(props: Omit<ArgCheckboxProps<boolean | undefined>, 'states'>): ReactElement {
    return <ArgCheckboxStates {...props} states={ThreeStates} />;
}

export function ArgCheckboxMinus(props: Omit<ArgCheckboxProps<boolean | 'minus'>, 'states'>): ReactElement {
    return <ArgCheckboxStates {...props} states={MinusStates} />;
}
