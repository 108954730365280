import { useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ArgIcon, ArgMessageRenderer, ArgRelativeTime, ArgTableColumn3 } from 'src/components/basic';
import { DataType, getDataTypesIconClass, getDataTypesMessageDescriptor } from '../../../components/common/data-types';
import { UserInputType, UserProfileField } from '../../../model/user-metadata';
import { PropertyActionMenu } from './property-action-menu';
import { dateSorter, stringSorter } from '../../../utils/sorter';
import { INPUT_TYPES } from '../../universes/ontology/components/property-and-metaproperty-modals/const';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';

import './properties-table.less';

const messages = defineMessages({
    by: {
        id: 'settings.properties.properties-table-column.creation-date-by',
        defaultMessage: 'By ',
    },
    name: {
        id: 'settings.properties.properties-table-column.name',
        defaultMessage: 'Name',
    },
    type: {
        id: 'settings.properties.properties-table-column.type',
        defaultMessage: 'Type',
    },
    mode: {
        id: 'settings.properties.properties-table-column.mode',
        defaultMessage: 'Input mode',
    },
    creationDate: {
        id: 'settings.properties.properties-table-column.creation-date',
        defaultMessage: 'Creation date',
    },
    lastUpdatedDate: {
        id: 'settings.properties.properties-table-column.lastUpdatedDate',
        defaultMessage: 'Last Modified',
    },
});

export const useTableColumns = (): ArgTableColumn3<UserProfileField>[] => {
    const canEditProperties = useHasPermission<SettingsPermissions>('admin.user.fields.management');
    const intl = useIntl();

    const cols = useMemo<ArgTableColumn3<UserProfileField>[]>(() => {
        const ret: ArgTableColumn3<UserProfileField>[] = [];

        ret.push(
            {
                key: 'displayName',
                sortable: true,
                columnName: 'Name',
                title: messages.name,
                dataIndex: 'displayName',
                minWidth: 200,
            },
            {
                key: 'type',
                sortable: true,
                columnName: 'Type',
                title: messages.type,
                dataIndex: 'type',
                minWidth: 200,
                render: function display(type: DataType) {
                    const typeIcon = getDataTypesIconClass(type);
                    const typeMessageDescriptor = getDataTypesMessageDescriptor(type);

                    return (
                        <div className='settings-properties-table-type' key={type}>
                            {typeIcon && <ArgIcon name={typeIcon} size='large' />}
                            <span className='settings-properties-table-type-label'>
                                <FormattedMessage {...typeMessageDescriptor} />
                            </span>
                        </div>
                    );
                },
                sorter: (a, b) => (
                    stringSorter<UserProfileField>(a, b, (profile) => {
                        if (!profile.type) {
                            return;
                        }

                        return intl.formatMessage(getDataTypesMessageDescriptor(profile.type));
                    })
                ),
            },
            {
                key: 'inputMode',
                sortable: true,
                columnName: 'Mode',
                title: messages.mode,
                dataIndex: 'inputMode',
                minWidth: 200,
                render: function display(type: UserInputType) {
                    const mode = INPUT_TYPES[type];

                    return (
                        <div className='settings-properties-table-mode'>
                            <ArgMessageRenderer size='small' message={mode.label} />
                        </div>
                    );
                },
            },
            {
                key: 'creationDate',
                sortable: true,
                columnName: 'Creation date',
                title: messages.creationDate,
                dataIndex: 'creationDate',
                minWidth: 400,
                render: function display(creationDate, property) {
                    if (!property.createdDate) {
                        return null;
                    }

                    return (
                        <div className='settings-properties-table-last-modified'>
                            <ArgRelativeTime date={property.createdDate} numeric='auto' />
                            <span className='settings-properties-table-last-modified-user'>
                                <FormattedMessage {...messages.by} />
                                {property.createdBy?.displayName}
                            </span>
                        </div>
                    );
                },
                defaultSortOrder: 'ascend',
                sorter: (a, b) => (
                    dateSorter<UserProfileField>(a, b, (item) => item?.createdDate)
                ),
            },
            {
                key: 'lastModified',
                sortable: true,
                columnName: 'Last Modified',
                title: messages.lastUpdatedDate,
                dataIndex: 'lastUpdatedDate',
                render: function display(data, property) {
                    return property.lastUpdatedDate ? (
                        <div className='settings-properties-table-last-modified'>
                            <ArgRelativeTime date={new Date(property.lastUpdatedDate)} numeric='auto' />
                            <span className='settings-properties-table-last-modified-user'>
                                <FormattedMessage {...messages.by} />
                                {property.lastUpdatedBy?.displayName}
                            </span>
                        </div>
                    ) : (
                        <div />
                    );
                },
                defaultSortOrder: 'ascend',
                sorter: (a, b) => (
                    dateSorter<UserProfileField>(a, b, (item) => item?.lastUpdatedDate)
                ),
            },
        );

        if (canEditProperties) {
            ret.push(
                {
                    key: 'actions',
                    title: ' ',
                    columnName: '',
                    dataIndex: 'id',
                    sortable: false,
                    render: function display(id, property) {
                        return <PropertyActionMenu property={property} />;
                    },
                },
            );
        }

        return ret;
    }, [canEditProperties]);

    return cols;
};
