import { Dispatch, SetStateAction, useState } from 'react';
import { defineMessages } from 'react-intl';

import { FullOntology } from '../../types';
import ontologiesConnector from '../../../../connectors/ontologies-connector';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';

interface DeleteMetapropertyProps {
    closeModal: () => void;
    metaproperty: any;
    ontologyId: string;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
}

export const messages = defineMessages({
    title: {
        id: 'settings.delete-metaproperty-modal.title',
        defaultMessage: 'Delete a metaproperty',
    },
    description: {
        id: 'settings.delete-metaproperty-modal.description',
        defaultMessage: 'Are you sure you want to delete this metaproperty?',
    },
    warning: {
        id: 'settings.delete-metaproperty-modal.warning',
        defaultMessage:
            'The metaproperty "{metapropertyName}" will be permanently deleted from all the properties of every object in this universe',
    },
});

export function DeleteMetapropertyModal(props: DeleteMetapropertyProps) {
    const {
        closeModal,
        metaproperty,
        ontologyId,
        setOntology,
    } = props;

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        await ontologiesConnector.deleteMetaProperty(metaproperty.name, ontologyId);
        setOntology(await ontologiesConnector.getFullOntology(ontologyId));
        setLoading(false);
    };

    return (
        <ConfirmModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            confirmDisabled={loading}
            confirmLoading={loading}
            type='delete'
            onConfirm={handleSubmit}
            alertDescription={messages.warning}
            alertMessage={messages.description}
            messageValues={{
                metapropertyName: metaproperty.displayName,
            }}
        />
    );
}
