import { defineMessages } from 'react-intl';
import React, { useCallback, useState } from 'react';

import { ArgInputType, ArgPlaceholderText, ArgRenderedText } from '../../types';
import { getDataTestIdFromProps } from '../../utils';
import { ArgInput } from '../../arg-input/arg-input';
import { ArgButton } from '../../arg-button/arg-button';
import { ClassValue, useClassNames } from '../../arg-hooks/use-classNames';
import { ProgressMonitor } from '../../progress-monitors/progress-monitor';
import { LatitudeAndLongitudeResponse } from '../common/model/geolocation-json';
import { TooltipPlacement } from '../../arg-tooltip/utils';
import { ArgGeolocationForm } from './components/arg-geolocation-form';
import { GeoLocationValue } from '../common/model/geolocation-value';
import { TextToGeo } from './text-to-geo';

import './arg-geolocation-picker.less';

export interface ArgGeolocationPickerProps {
    className?: ClassValue;
    readOnly?: boolean;
    tooltip?: ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    disabled?: boolean;
    type?: ArgInputType;
    value?: GeoLocationValue;
    autoFocus?: boolean;
    onChange?: (value: (GeoLocationValue | undefined)) => void;
    getAddressCoordinates: (address: string, progressMonitor: ProgressMonitor) => Promise<LatitudeAndLongitudeResponse | undefined>;
    placeholder?: ArgPlaceholderText | null;
    clearable?: boolean;
}

const messages = defineMessages({
    placeholder: {
        id: 'basic.arg-geolocation-picker.popover.addressPlaceholder',
        defaultMessage: 'Define a place',
    },
});

export function ArgGeolocationPicker(props: ArgGeolocationPickerProps) {
    const {
        onChange,
        className,
        value: externalValue,
        readOnly,
        disabled,
        type,
        autoFocus,
        getAddressCoordinates,
        placeholder,
        tooltip,
        tooltipPlacement,
        clearable,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);
    const classNames = useClassNames('arg-geolocation-picker');

    const useInternalValue = !('value' in props);

    const [internalValue, setInternalValue] = useState<GeoLocationValue>();
    const [popoverVisible, setPopoverVisible] = useState<boolean>();

    const value = useInternalValue ? internalValue : externalValue;

    const handleClear = useCallback(() => {
        // Clear the internal value
        setInternalValue(undefined);

        // Close the popover
        setPopoverVisible(false);

        // Clear the form values
        onChange?.(undefined);
    }, [onChange]);

    const handleInputChange = (value: string | null) => {
        if (!value) {
            onChange?.(undefined);

            return;
        }

        const latLong = TextToGeo(value);
        if (!latLong || isNaN(latLong.latitude) || isNaN(latLong.longitude)) {
            return undefined;
        }
        onChange?.({ coordinates: { lat: latLong.latitude, lng: latLong.longitude } });
    };

    const handlePopoverChange = useCallback((value: GeoLocationValue | undefined) => {
        setInternalValue(value);
        onChange?.(value);
    }, [onChange]);

    const formatValue = useCallback((value: GeoLocationValue | null) => {
        if (!value?.coordinates) {
            return '';
        }

        return `${value?.coordinates.lat} ${value?.coordinates.lng}`;
    }, []);

    const cls = {
        editable: !readOnly && !disabled,
        disabled: readOnly || disabled,
    };

    const onPopoverVisibleChange = useCallback((value: React.SetStateAction<boolean | undefined>) => {
        if (disabled || readOnly) {
            return;
        }

        setPopoverVisible(value);
    }, [disabled, readOnly]);

    return (
        <ArgInput<GeoLocationValue>
            tooltip={tooltip}
            tooltipPlacement={tooltipPlacement}
            autoFocus={autoFocus}
            value={value || undefined}
            disabled={disabled}
            type={type}
            clearable={!readOnly && clearable}
            onInputChange={handleInputChange}
            onClear={handleClear}
            data-testid={dataTestId}
            parseValue={() => (value || null)}
            formatValue={formatValue}
            className={classNames('&', className, cls)}
            placeholder={placeholder ?? messages.placeholder}
            right={!readOnly && (
                <ArgButton
                    key='marker'
                    type='ghost'
                    icon='icon-map-marker'
                    data-testid={`${dataTestId}-open-popover`}
                    className={classNames('&-right')}
                    disabled={disabled}
                    popoverVisible={popoverVisible}
                    onPopoverVisibleChange={onPopoverVisibleChange}
                    popoverFitWidth={false}
                    popoverClassName={classNames('&-popover')}
                    popoverPlacement='bottomRight'
                    popover={(!readOnly && !disabled) && (() => (
                        <ArgGeolocationForm
                            onChange={handlePopoverChange}
                            value={value}
                            getAddressCoordinates={getAddressCoordinates}
                        />
                    ))}
                />
            )}
        />
    );
}
