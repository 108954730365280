import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { IntlShape, useIntl } from 'react-intl';
import { useMemo } from 'react';

import {
    ArgGlobalNotificationType,
    ArgModalContext,
    useArgModalContext,
    useArgNotifications,
} from '../../components/basic';
import { ArgonosModule } from '../../components/application/modules';
import { useCurrentArgonosModule } from '../../components/application/argonos-current-module';
import { useGetMyPermissions } from '../../contexts/user-permissions-context';
import { ApplicationSessionId, useApplicationSessionId } from '../../contexts/application-session-context';
import { hasAllPermissions, hasAnyPermissions } from '../../contexts/user-permission';

export interface BasicEnvironmentContext {
    argonosModule: ArgonosModule;

    applicationSessionId: ApplicationSessionId;

    location: Location;
    navigate: NavigateFunction;
    notifications: ArgGlobalNotificationType;
    modalContext: ArgModalContext;
    intl: IntlShape;

    hasAnyPermissions<T>(...name: (keyof T)[]): boolean;
    hasAllPermissions<T>(...name: (keyof T)[]): boolean;
}


export function useBasicEnvironmentContext():BasicEnvironmentContext {
    const argonosModule = useCurrentArgonosModule();

    const intl = useIntl();

    const navigate = useNavigate();
    const location = useLocation();

    const { permissions } = useGetMyPermissions();

    const applicationSessionId = useApplicationSessionId();

    const notifications = useArgNotifications();

    const modalContext = useArgModalContext();

    const environmentContext = useMemo<BasicEnvironmentContext>(() => {
        const result: BasicEnvironmentContext = {
            argonosModule,

            navigate,
            location,
            notifications,

            applicationSessionId,
            intl,

            modalContext,

            hasAnyPermissions<T>(...names: (keyof T)[]): boolean {
                const result = hasAnyPermissions<T>(permissions, ...names);

                return result;
            },

            hasAllPermissions<T>(...names: (keyof T)[]): boolean {
                const result = hasAllPermissions<T>(permissions, ...names);

                return result;
            },
        };

        return result;
    }, [argonosModule, navigate, location, notifications, applicationSessionId, intl, modalContext, permissions]);

    return environmentContext;
}
