import { NO_SELECTION, TextSelection } from './types';

export function setInputSelection(input:HTMLInputElement, start: number, end?: number) {
    if (end === undefined) {
        end = start;
    }
    input.setSelectionRange(start, end);
}

export function getInputSelection(input: HTMLInputElement): TextSelection {
    const start = input.selectionStart;
    const end = input.selectionEnd;

    if (start === null || end === null) {
        return NO_SELECTION;
    }

    const result: TextSelection = {
        start,
        end,
    };

    return result;
}

export function isInputFocused(input: HTMLInputElement):boolean {
    const inputDocument = input.ownerDocument;

    const result = inputDocument.hasFocus() && inputDocument.activeElement === input;

    return result;
}
