import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Action } from '../../../../utils/actions/action';
import { HistoryManager } from '../../../../utils/history-manager';
import { ActionsEngine } from '../../../../utils/actions/actions-engine';
import { ProgressMonitor, SelectionProvider } from '../../../../components/basic';
import { FormContainer, FormDocument, FormElement, FormTab } from '../../../../components/common/forms/model';
import { FormRepository } from './form-actions-engine';

export const MOVE_FORM_PAGE_ELEMENTS_ACTION = Symbol('Form:MoveFormPageElements');

export const messages = defineMessages({
    moveFormPageElement: {
        id: 'exploration.forms.actions.move-form-page-elements.TaskName',
        defaultMessage: 'Move a page',
    },
    undoMoveFormPageElement: {
        id: 'exploration.forms.actions.move-form-page-elements.Undo',
        defaultMessage: 'Cancel move a page',
    },
});

export function createFormMovePageElement(
    container: FormContainer,
    formElement: FormTab,
    index: number,
    selectionProvider: SelectionProvider<FormElement> | undefined,
): Action<FormRepository, void> {
    let previousValue: FormDocument;

    return {
        type: MOVE_FORM_PAGE_ELEMENTS_ACTION,

        renderEntry: function ChangeStepActionTitle(undo: boolean) {
            return (
                <FormattedMessage
                    {...(undo) ? messages.undoMoveFormPageElement : messages.moveFormPageElement}
                />
            );
        },
        action: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor,
        ) => {
            progressMonitor.beginTask(messages.moveFormPageElement, 1);

            previousValue = actionsEngine.repository.formDocument;
            actionsEngine.repository.movePage(container, formElement, index);
            selectionProvider?.set(formElement, 'form-move-page-elements-action');
        },
        reverseAction: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor,
        ) => {
            progressMonitor.beginTask(messages.undoMoveFormPageElement, 1);

            actionsEngine.repository.setDocument(previousValue);

            selectionProvider?.clear('form-move-page-elements-action');
        },
    };
}
