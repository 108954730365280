import { PROCESS_COMPONENTS_PRIORITY_EVENTS } from './events';
import { RtMessageEventList } from '../../utils/rt-states/events';
import { EntityState } from '../../utils/rt-states/folders/entity-state';
import { getDataPreparationEntitiesRtApi } from './rt-apis';
import { ProcessId } from '../model/process';

export class ProcessComponentsPrioritiesState extends EntityState {
    constructor(url: string, processId: ProcessId, events: RtMessageEventList = PROCESS_COMPONENTS_PRIORITY_EVENTS) {
        super(getDataPreparationEntitiesRtApi(), url, processId, events);
    }
}
