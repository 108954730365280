import React, {
    CSSProperties,
    DragEvent,
    ExoticComponent,
    ForwardedRef,
    MouseEvent,
    ReactNode,
    useImperativeHandle,
    useRef,
} from 'react';
import { isArray, isObject, isString } from 'lodash';
import { isElement, isFragment } from 'react-is';
import { Link } from 'react-router-dom';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { Draggable, DraggableAction, Provided } from '../arg-dnd/draggable';
import { ArgBadges } from '../arg-badge/arg-badge';
import { getDataTestIdFromProps } from '../utils';
import { ArgIcon, renderIcon } from '../arg-icon/arg-icon';
import { BasicTagIcon } from './basic-tag-icon';
import { ArgMessageValues, ArgRenderedIcon, ArgRenderedText, ArgSize } from '../types';
import { getMessageValuesWithFormatters, renderText } from '../utils/message-descriptor-formatters';
import { ArgTooltip2, TooltipPlacement } from '../arg-tooltip/arg-tooltip2';
import { ArgNavigateToType } from '../arg-navigation/arg-navigate-to';
import { resolveNavigateTo } from '../arg-navigation/utils';

import './basic-tag.less';


export interface BasicTagProps {
    className?: ClassValue;

    size?: ArgSize;
    icon?: ArgRenderedIcon | 'error' | 'unknown' | false;

    iconSize?: string | number;
    iconColor?: string;
    backgroundColor?: string | false;
    strokeColor?: string;
    label: ArgRenderedText;
    avatarDigram?: ReactNode;
    badges?: ArgBadges;

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;

    messageValues?: ArgMessageValues;
    onClick?: (event: MouseEvent) => void;
    onDoubleClick?: (event: MouseEvent) => void;

    draggable?: boolean;
    onDragStart?: (event: DragEvent) => void;
    onDragEnd?: (event: DragEvent) => void;
    dragAction?: DraggableAction;

    iconBadges?: ArgBadges;
    iconClassName?: ClassValue;
    iconStyle?: CSSProperties;

    loading?: boolean;

    search?: string;
    renderDragIcon?: boolean;

    noBorder?: boolean;

    right?: ArgRenderedText;

    navigateTo?: ArgNavigateToType;
}

function _BasicTag(props: BasicTagProps, ref: ForwardedRef<HTMLElement>) {
    const {
        icon,
        iconSize,
        iconColor,
        iconClassName,
        backgroundColor,
        strokeColor,
        tooltip,
        tooltipPlacement,
        tooltipClassName,
        className,
        label,
        messageValues,
        size = 'medium',
        onClick,
        onDoubleClick,
        draggable,
        onDragStart,
        onDragEnd,
        dragAction,
        iconBadges,
        loading,
        iconStyle,
        avatarDigram,
        badges,
        search,
        renderDragIcon = false,
        noBorder = false,
        right,
        navigateTo,
    } = props;

    const classNames = useClassNames('basic-tag');
    const dataTestId = getDataTestIdFromProps(props);

    const containerRef = useRef<HTMLElement>(null);

    const messageValuesWithFormatters = getMessageValuesWithFormatters(messageValues);

    useImperativeHandle(ref, () => containerRef.current!);

    let _tooltip = tooltip;
    let iconComponent: ReactNode = null;
    if (icon === 'error' || icon === 'unknown') {
        iconComponent = icon;
        _tooltip = label;
    } else if (isString(icon)) {
        iconComponent = (
            <ArgIcon
                color={iconColor}
                name={icon}
                data-testid='basic-tag-icon'
                size={iconSize}
                className={classNames('&-icon', iconClassName)}
                style={iconStyle}
            />
        );
    } else if (icon === false) {
        iconComponent = false;
    } else {
        iconComponent = renderIcon(icon, classNames('&-icon', iconClassName));
    }


    let _label: ReactNode = null;
    if (loading && ((!label && label !== false) || label === true)) {
        _label = ' ';
    } else {
        _label = renderText(label, messageValues, search);
    }

    const hasLabel = (label !== undefined && label !== false);

    const cls = {
        [`size-${size}`]: true,
        clickable: !!onClick,
        loading,
        'has-label': hasLabel,
        'no-border': !!noBorder,
    };

    const customProperties:Record<string, any> = {};

    let TagName:ExoticComponent<any>|string = 'div';
    const tagProps: any = {
        onDoubleClick,
    };
    if (!loading && navigateTo) {
        TagName = Link;

        const n = resolveNavigateTo(navigateTo);
        Object.assign(customProperties, n);

        cls['arg-hyperlink'] = true;
        cls['&-link'] = true;
    } else if (!loading && onClick) {
        TagName = 'button';
        tagProps.onClick = onClick;
    }

    let _backgroundColor = backgroundColor;
    if (loading) {
        _backgroundColor = false;
    }

    if (_label && !isElement(_label) && !isFragment(_label) && isObject(_label) && !isArray(_label)) {
        console.error('Label is not a valid object=', _label);
        try {
            _label = `***${JSON.stringify(_label)}`;
        } catch (x) {
            _label = '*** CIRCULAR JSON';
        }
    }

    const tagName = (provided?: Provided) => {
        let tagNameComponent = <TagName
            className={classNames('&', className, cls)}
            ref={containerRef}
            data-testid={dataTestId}
            draggable={!dragAction && draggable}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            {...provided?.dragHandleProps}
            {...tagProps}
            {...customProperties}
        >
            {draggable && renderDragIcon &&
                <ArgIcon
                    name='icon-6dots'
                    className={classNames('&-drag-icon')}
                />}
            {iconComponent !== false && <BasicTagIcon
                backgroundColor={_backgroundColor}
                borderColor={strokeColor}
                className={classNames('&-avatar', { loading, 'has-label': hasLabel })}
                data-testid='basic-tag-icon'
                badges={iconBadges}
                icon={iconComponent ? iconComponent : undefined}
                digram={avatarDigram}
                digramColor={iconColor}
                size={size}
            />}
            {_label && <span
                className={classNames('&-title', { loading: loading, 'has-label': hasLabel })}
                data-testid='basic-tag-label'
            >
                {_label}
            </span>}
            {badges}
            {renderText(right, messageValues)}
        </TagName>;

        if (_tooltip && !provided?.dragging) {
            tagNameComponent = (
                <ArgTooltip2
                    className={classNames('&-tooltip', tooltipClassName)}
                    title={_tooltip}
                    messageValues={messageValuesWithFormatters}
                    placement={tooltipPlacement}
                >
                    {tagNameComponent}
                </ArgTooltip2>
            );
        }

        return tagNameComponent;
    };

    let component;
    if (dragAction) {
        component = <Draggable actions={dragAction}>
            {tagName}
        </Draggable>;
    } else {
        component = tagName();
    }


    return component;
}

export const BasicTag = React.forwardRef(_BasicTag);
