import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Action } from '../../../../utils/actions/action';
import { HistoryManager } from '../../../../utils/history-manager';
import { ActionsEngine } from '../../../../utils/actions/actions-engine';
import { ProgressMonitor } from '../../../../components/basic';
import { FormElement } from '../../../../components/common/forms/model';
import { FormRepository } from './form-actions-engine';

export const CHANGE_PROPERTY_ACTION = Symbol('Form:ChangeProperty');

export const messages = defineMessages({
    changeProperty: {
        id: 'exploration.forms.actions.change-property.TaskName',
        defaultMessage: 'Change property {propertyName}',
    },
    undoChangeProperty: {
        id: 'exploration.forms.actions.change-property.Undo',
        defaultMessage: 'Cancel change property',
    },
});

export function createFormChangeProperty(formElement: FormElement, propertyName: string, propertyValue: any): Action<FormRepository, void> {
    let previousValue: any;

    return {
        type: CHANGE_PROPERTY_ACTION,

        renderEntry: function ChangeStepActionTitle(undo: boolean) {
            return <FormattedMessage
                {...(undo) ? messages.undoChangeProperty : messages.changeProperty}
                values={{ propertyName, propertyValue }}
            />;
        },
        action: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor,
        ) => {
            progressMonitor.beginTask(messages.changeProperty, 1, {
                propertyName,
                propertyValue,
            });

            previousValue = (formElement as any)[propertyName];

            actionsEngine.repository.change(formElement, propertyName, propertyValue);
        },
        reverseAction: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor,
        ) => {
            progressMonitor.beginTask(messages.undoChangeProperty, 1, {
                propertyName,
                propertyValue,
            });

            actionsEngine.repository.change(formElement, propertyName, previousValue);
        },
    };
}
