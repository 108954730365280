import { ArgIcon, useClassNames } from 'src/components/basic';

import './delete-filter-button.less';

interface DeleteFilterButtonProps {
  onDeleteRule: () => void;
}

export function DeleteFilterButton({ onDeleteRule }: DeleteFilterButtonProps) {
    const classNames = useClassNames('settings-delete-filter-button');

    return (
        <div>
            <div
                onClick={onDeleteRule}
                className={classNames('&-trashcan')}
            >
                <ArgIcon name='icon-trash' />
            </div>
        </div>
    );
}
