import { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton, ArgInputSearch, useArgModalContext, useClassNames } from 'src/components/basic';
import { ContextualVariable } from 'src/exploration/model/contextual-variable';
import { CreateVariableModal } from './create-variable-modal';

const messages = defineMessages({
    import: {
        id: 'settings.contextual-variables.action-buttons.import',
        defaultMessage: 'Import',
    },
    export: {
        id: 'settings.contextual-variables.action-buttons.export',
        defaultMessage: 'Export',
    },
    searchPlaceholder: {
        id: 'settings.contextual-variables.table.search.placeholder',
        defaultMessage: 'Search for a variable',
    },
    newVar: {
        id: 'settings.contextual-variables.action-buttons.new-var',
        defaultMessage: 'New variable',
    },
    newGroup: {
        id: 'settings.contextual-variables.action-buttons.newGroup',
        defaultMessage: 'New group',
    },
});

export interface ActionButtonsProps {
    handleSearch: (value: string) => void;
    canCreateContextualVariables: boolean;
    setContextualVariables: React.Dispatch<React.SetStateAction<ContextualVariable[]>>;
}

export function ActionButtons({ handleSearch, canCreateContextualVariables, setContextualVariables }: ActionButtonsProps) {
    const classNames = useClassNames('contextual-variables');

    const modalContext = useArgModalContext();

    const handleOpenCreateVariable = useCallback(() => {
        modalContext.open('create-variable',
            <CreateVariableModal
                setContextualVariables={setContextualVariables}
                closeModal={() => modalContext.close('create-variable')}
            />,
        );
    }, [modalContext, setContextualVariables]);

    return (
        <div className={classNames('&-buttons')}>
            <ArgInputSearch
                className={classNames('&-body-table-header-search')}
                placeholder={messages.searchPlaceholder}
                onInputChange={handleSearch}
            />
            { canCreateContextualVariables &&
                <ArgButton
                    size='medium'
                    type='primary'
                    icon='icon-plus'
                    className={classNames('&-buttons-item')}
                    label={messages.newVar}
                    onClick={handleOpenCreateVariable}
                />}
        </div>
    );
}
