import { cloneDeep, first, set } from 'lodash';
import { Dispatch, useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
    ArgButton,
    ArgIcon,
    ProgressMonitor,
    ThreeDotsLoading,
    useCallbackAsync,
    useClassNames,
    useMemoAsync,
    useArgNotifications,
} from 'src/components/basic';
import {
    getFirstRuleSchema,
    isEmptyObject,
    isValidFormEffect,
    isValidTarget,
    setEveryRuleSchema,
} from '../../../../common/policy-utils';
import { FormPolicyProcessed, FormRuleProcessed } from 'src/settings/models/form-policy';
import { FormPolicyRuleBlockEffect } from '../form-policy-rule-block-effect/form-policy-rule-block-effect';
import { OntologyId } from 'src/settings/universes/ontology/types';
import { UserProfileField } from 'src/model/user-metadata';
import { SchemaObject } from 'src/settings/models/policy';
import { FormDisplayTemplates } from 'src/exploration/model/form-display-template';
import ontologiesConnector from '../../../../../connectors/ontologies-connector';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { useOntologyContext } from 'src/settings/universes/common/providers/policy-rules-provider';
import { PolicyRuleBlockTarget } from 'src/settings/universes/common/rules/policy-rule/policy-rule-block-target';

import './form-policy-rule-block.less';

const messages = defineMessages({
    rule: {
        id: 'settings.form-policy-rule-page.rule',
        defaultMessage: 'Rule',
    },
    invalidRule: {
        id: 'settings.form-policy-rule-block-target.invalidRule',
        defaultMessage:
            'The rule target has been created on another system and its details cannot be displayed through this interface',
    },
    savingPolicyError: {
        id: 'settings.form-policy-rule-block-target.savingPolicyError',
        defaultMessage: 'An error occurred while saving the policy',
    },
    invalidRuleTitle: {
        id: 'settings.form-policy-rule-block-target.invalidRuleTitle',
        defaultMessage: 'Error in the logical expression',
    },
    invalidRuleDescription: {
        id: 'settings.form-policy-rule-block-target.invalidRuleDescription',
        defaultMessage: 'Unable to validate the rule',
    },
    cancel: {
        id: 'settings.form-policy-rule-block-target.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.form-policy-rule-block-target.delete',
        defaultMessage: 'Delete',
    },
    confirm: {
        id: 'settings.form-policy-rule-block-target.confirm',
        defaultMessage: 'Validate',
    },
    fetchForms: {
        id: 'settings.form-policy-rule-block-target.fetchForms',
        defaultMessage: 'Loading forms',
    },
});

interface FormPolicyRuleBlockProps {
    rule: FormRuleProcessed;
    index: number;
    setPolicy: Dispatch<FormPolicyProcessed>;
    editable: boolean;
    setEditable: (value: boolean) => void;
    policy: FormPolicyProcessed;
    ontologyId: OntologyId;
    userProfileFields: UserProfileField[];
}

export function FormPolicyRuleBlock(props: FormPolicyRuleBlockProps) {
    const {
        rule,
        index,
        setPolicy,
        editable,
        setEditable,
        policy,
        ontologyId,
        userProfileFields,
    } = props;

    const intl = useIntl();
    const notifications = useArgNotifications();
    const { ontologySchema } = useOntologyContext();

    const classNames = useClassNames('settings-form-policy-rule-block');
    const [expanded, setExpanded] = useState(true);
    const [ruleState, setRuleState] = useState(rule);

    const [schemaObject, setSchemaObject] = useState<SchemaObject>(() => {
        const ruleTarget = first(rule.Targets);
        const effectType = ruleTarget ? getFirstRuleSchema(ruleTarget)?._type : undefined;

        return {
            _kind: 'Vertex',
            _type: effectType ?? first(ontologySchema.objectTypes)?.name,
        };
    });

    const invalidRule = ruleState.Targets.length !== 1;

    const [forms] = useMemoAsync<FormDisplayTemplates | undefined>(async (progressMonitor: ProgressMonitor) => {
        if (!schemaObject?._kind || !schemaObject?._type) {
            return;
        }
        try {
            const itemKind = schemaObject._kind === 'Vertex' ? 'object' : 'relation';
            const ret = await ontologiesConnector.getForms(ontologyId, itemKind, schemaObject._type, progressMonitor);

            return ret;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchForms }, error as Error);
            throw error;
        }
    }, [ontologyId, schemaObject?._kind, schemaObject?._type, notifications]);

    const [handleSaveRule, saveRuleProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const canBeSaved =
            ruleState.Targets.every((target) => isValidTarget(target) || isEmptyObject(target))
            && ruleState.Effects.every((effect) => isValidFormEffect(effect));

        if (!canBeSaved) {
            notifications.snackError({
                message: messages.invalidRuleTitle,
                description: messages.invalidRuleDescription,
            });
        } else {
            try {
                const newPolicy = set(cloneDeep(policy), `statement.Actions[${index}]`, ruleState);
                await explorationSettingsConnector.editFormPolicy(policy.id, newPolicy, progressMonitor);
                setPolicy(newPolicy);
                setEditable(false);
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    throw error;
                }
                notifications.snackError({ message: messages.savingPolicyError }, error as Error);
                throw error;
            }
        }
    }, [ruleState, notifications, policy, index, setPolicy, setEditable]);

    const handleResetRule = useCallback(() => {
        setRuleState(rule);
        setEditable(false);
    }, [rule, setEditable]);

    const [handleRuleDeletion, deleteRuleProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const newPolicy = set(
                cloneDeep(policy),
                'statement.Actions',
                policy.statement.Actions.filter((act, idx) => idx !== index),
            );
            await explorationSettingsConnector.editFormPolicy(policy.id, newPolicy, progressMonitor);
            setPolicy(newPolicy);
            setEditable(false);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.savingPolicyError }, error as Error);
            throw error;
        }
    }, [policy, index, setPolicy, setEditable, notifications]);

    const handleChangeFilterItem = useCallback((schemaObject: SchemaObject) => {
        setRuleState((rule) => {
            console.log('schemaObject', schemaObject);
            console.log('rule', rule);
            const newTargets = rule.Targets.map((target) => setEveryRuleSchema(target, schemaObject));
            console.log('newTargets', newTargets);

            return { ...rule, Targets: newTargets };
        });
        setSchemaObject(schemaObject);
    }, []);

    const loading = saveRuleProgressMonitor?.isRunning || deleteRuleProgressMonitor?.isRunning;

    return (
        <div className={classNames('&-container')}>
            <div className={classNames('&-header')}>
                <div className={classNames('&-header-left')}>
                    <div
                        className={classNames('&-rule-title', editable && '&-color-editable')}
                    >{`${intl.formatMessage(messages.rule)} ${index + 1}`}</div>
                    <div
                        onClick={() => setExpanded(!expanded)}
                        className={classNames('&-icon')}
                    >
                        <ArgIcon
                            name={expanded ? 'icon-cheveron-up' : 'icon-cheveron-down'}
                            size={20}
                        />
                    </div>
                </div>
                <div className={classNames('&-header-right')}>
                    {editable && loading && <ThreeDotsLoading />}
                    {editable && !loading && (
                        <>
                            <ArgButton size='medium'
                                       type='ghost'
                                       tooltip={messages.confirm}
                                       icon='icon-checkmark1'
                                       onClick={handleSaveRule}
                            />
                            <ArgButton size='medium'
                                       type='ghost'
                                       tooltip={messages.cancel}
                                       icon='icon-cross'
                                       onClick={handleResetRule}
                            />
                            <ArgButton size='medium'
                                       type='ghost'
                                       tooltip={messages.delete}
                                       icon='icon-trash'
                                       onClick={handleRuleDeletion}
                            />
                        </>
                    )}
                    {!editable && (
                        <div onClick={() => setEditable(true)} className={classNames('&-icon')}>
                            <ArgIcon name='icon-edit-pencil' size={14} />
                        </div>
                    )}
                </div>
            </div>
            {expanded && !invalidRule && (
                <div
                    className={classNames(
                        '&-target-effect-container',
                        editable && '&-editable',
                    )}
                >
                    {ruleState.Targets.map((target, policyIndex) => {
                        return <div key={policyIndex}
                                    className={classNames('&-target-container', editable && '&-editable')}>
                            <PolicyRuleBlockTarget
                                editable={editable}
                                policyTargetsPath='Targets'
                                target={target}
                                policyIndex={policyIndex}
                                onRuleChange={setRuleState}
                                schema={ontologySchema}
                                userProfileFields={userProfileFields}
                                onChangeItemFilter={handleChangeFilterItem}
                                externalSchemaObject={schemaObject}
                            />
                        </div>;
                    })}
                    <div className={classNames('&-effect-container', editable && '&-editable')}>
                        <FormPolicyRuleBlockEffect
                            effects={ruleState.Effects}
                            editable={editable}
                            onRuleChange={setRuleState}
                            forms={forms}
                        />
                    </div>
                </div>
            )}
            {expanded && invalidRule && (
                <div className={classNames('&-invalidRule')}>
                    <div>{intl.formatMessage(messages.invalidRule)}</div>
                </div>
            )}
        </div>
    );
}
