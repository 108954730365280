import { useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

import { PageHeader } from 'src/settings/common-components/page-header';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';
import { ArgIcon, ProgressMonitor, useClassNames, useMemoAsync, useArgNotifications, ArgTabsSubLevel } from 'src/components/basic';
import { FormPolicyRulesView } from './form-policy-rules-view';
import { FormPolicyApplicationConditionsView } from './form-policy-application-conditions-view';
import { FormPolicyId } from 'src/settings/models/form-policy';
import { computeFormPoliciesUrl } from 'src/settings/utils/compute-url';

import './form-policy-details-view.less';

const messages = defineMessages({
    rules: {
        id: 'settings.form-policies.policy.rules',
        defaultMessage: 'Rules',
    },
    conditions: {
        id: 'settings.form-policies.policy.conditions',
        defaultMessage: 'Application Conditions',
    },
    security: {
        id: 'settings.form-policies.policy.security',
        defaultMessage: 'Security',
    },
    fetchFormPolicyError: {
        id: 'settings.form-policies.policy.fetchFormPolicyError',
        defaultMessage: 'An error occurred while fetching form policy',
    },
});

type PolicyParams = {
    policyId: FormPolicyId;
    ontologyId: string;
    universeId: string;
}

export function FormPolicyDetailsView() {
    const { ontologyId, policyId, universeId } = useParams<PolicyParams>();
    const notifications = useArgNotifications();

    const [activeTab, setActiveTab] = useState<string>('rules');

    const [buttonSelected, setButtonSelected] = useState<string>('');
    const classNames = useClassNames('settings-form-policy-entry-view');

    const [formPolicy] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        if (!policyId) {
            return;
        }
        try {
            const policy = await explorationSettingsConnector.getFormPolicy(policyId, progressMonitor);

            return policy;
        } catch (error) {
            if (!progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchFormPolicyError }, error as Error);
            throw error;
        }
    }, [policyId]);

    const tabs = useMemo(() => {
        return [
            {
                key: 'rules',
                title: messages.rules,
            },
            {
                key: 'application-conditions',
                title: messages.conditions,
            },
        ];
    }, []);

    const title = (
        <h1 className={classNames('&-title')}>
            {ontologyId && universeId && (
                <Link
                    className={classNames('&-back-link')}
                    to={computeFormPoliciesUrl(ontologyId, universeId)}
                >
                    <ArgIcon name='icon-arrow-thin-left' size='medium' />
                </Link>
            )}
            {formPolicy?.name}
        </h1>
    );

    return (
        <div className={classNames('&')}>
            <PageHeader
                title={title}
                selectedItem={buttonSelected}
                setSelectedItem={setButtonSelected}
                buttons={[]}
            />
            <div className={classNames('&-page-content')}>
                <ArgTabsSubLevel
                    tabs={tabs}
                    activeTabKey={activeTab}
                    onChange={(tabKey) => tabKey && setActiveTab(tabKey)}
                />
                {activeTab === 'rules' && <FormPolicyRulesView />}
                {activeTab === 'application-conditions' && <FormPolicyApplicationConditionsView />}
            </div>
        </div>
    );
}
