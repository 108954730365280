import React, { useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ArgButton, ArgMenu, useArgModalContext } from 'src/components/basic';
import { DeleteUserModal } from '../single-user-delete-modal/single-user-delete-modal';
import { User } from 'src/model/user';
import { EditUserModal } from '../edit-user-modal/edit-user-modal';
import { ResetPasswordModal } from '../reset-user-password-modal/reset-user-password-modal';
import { Environment } from 'src/utils/environment';
import { useGetMe } from 'src/contexts/user-context';
import { ArgMenuItem } from '../../../../components/basic';
import { useUsers } from '../../../hooks/use-users';
import { UserProfileField } from '../../../../model/user-metadata';

const messages = defineMessages({
    edit: {
        id: 'settings.users.menu.edit',
        defaultMessage: 'Edit',
    },
    resetPassword: {
        id: 'settings.users.menu.reset-password',
        defaultMessage: 'Reset Password',
    },
    activate: {
        id: 'settings.users.menu.activate',
        defaultMessage: 'Activate',
    },
    deactivate: {
        id: 'settings.users.menu.deactivate',
        defaultMessage: 'Deactivate',
    },
    delete: {
        id: 'settings.users.menu.delete',
        defaultMessage: 'Delete',
    },
});

export interface UserActionsMenuProp {
    user: User;
    userProfilesFields?: UserProfileField[];
}

export function UserActionsMenu(props: UserActionsMenuProp) {
    const {
        user,
        userProfilesFields,
    } = props;

    const [visible, setVisible] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const modalContext = useArgModalContext();

    const changeStatus = useUsers();
    const { me: currentUser } = useGetMe();


    const handleChangeStatus = useCallback(() => {
        setDisabled(true);
        changeStatus(user);
        setVisible(false);
        setDisabled(false);
    }, [changeStatus, user]);

    const actionsMenu = useCallback(() => {
        return <ArgMenu data-testid='actions-users-button-menu'>
            <ArgMenuItem
                key={messages.edit.id}
                disabled={disabled}
                data-testid='edit'
                onClick={() => {
                    setVisible(false);
                    modalContext.open('settings-user-edit-modal',
                        <EditUserModal
                            closeModal={() => {
                                modalContext.close('settings-user-edit-modal');
                            }}
                            user={user}
                            isCurrentUser={user.id === currentUser?.id}
                            userProfilesFields={userProfilesFields}
                        />,
                    );
                }}
            >
                <span>
                    <FormattedMessage {...messages.edit} />
                </span>
            </ArgMenuItem>

            {Environment.apiOAuth && !user.identityIssuer && (
                <ArgMenuItem
                    key={messages.resetPassword.id}
                    disabled={disabled}
                    data-testid='reset-password'
                    onClick={() => {
                        setVisible(false);
                        modalContext.open('settings-user-reset-password-modal',
                            <ResetPasswordModal
                                closeModal={() => {
                                    modalContext.close('settings-user-reset-password-modal');
                                }}
                                user={user}
                            />,
                        );
                    }}
                    label={messages.resetPassword}
                />
            )}
            {user.id != currentUser?.id && (
                <ArgMenuItem
                    key={messages.deactivate.id}
                    disabled={disabled}
                    data-testid='deactivate'
                    onClick={handleChangeStatus}
                    label={user.isActive ? messages.deactivate : messages.activate}
                />
            )}
            {user.id != currentUser?.id && (
                <ArgMenuItem
                    key={messages.delete.id}
                    disabled={disabled}
                    data-testid='delete'
                    onClick={() => {
                        setVisible(false);
                        modalContext.open('settings-user-delete-modal',
                            <DeleteUserModal
                                closeModal={() => {
                                    modalContext.close('settings-user-delete-modal');
                                }}
                                user={user}
                            />,
                        );
                    }}
                    label={messages.delete}
                />
            )}
        </ArgMenu>;
    }, [currentUser?.id, disabled, handleChangeStatus, modalContext, user, userProfilesFields]);

    return (
        <>
            <ArgButton
                type='ghost'
                icon='icon-options'
                popover={actionsMenu}
                popoverTrigger='click'
                popoverVisible={visible}
                data-testid='actions-users-menu'
                popoverPlacement='bottomLeft'
                onPopoverVisibleChange={setVisible}
            />
        </>
    );
}
