import { get, isArray, isFunction, isNil, isString, template } from 'lodash';
import Debug from 'debug';

import type { LoginResult } from '../model/login-result';
import { Environment } from './environment';
import { ArgonosError } from '../components/basic/utils/argonos-error';
import { ProgressMonitor, SubProgressMonitor } from '../components/basic/progress-monitors/progress-monitor';
import {
    redirectToOAuthLogin,
    unauthorizedPageAccessed,
} from 'src/components/caches/pages-access';
import { getAdministrationApi, getSettingsApi } from './connectors/api-url';
import { downloadBlob } from './file';
import { ResponseError } from '../components/basic/utils/response-error';
import { RtApi } from './rt-states/rt-api';
import { Operation } from './json-patch/json-patch-adapter';

const debug = Debug('common:utils:Connector');

export const ETAG_NOT_MODIFIED = Symbol('Etag not modified');

export type UnauthorizedCallback = (api: string, options: APIConnectorRequestInit, progressMonitor: ProgressMonitor) => Promise<any | null>;
export type ProcessId = string;

export const DEFAULT_CONTENT_TYPE = 'application/octet-stream';
export const ARG_LOCALE_KEY = 'arg-locale';
export const ARG_AUTHENTICATED_KEY = 'arg-authenticated';
export const ARG_BEARER_KEY = 'arg-bearer';
export const ARG_TOKEN_INFO_KEY = 'arg-token-info';

const REDIRECT_URL_TEMPLATE = {
    interpolate: /{([\s\S]+?)}/g,
};

export interface RangeResponse<T> {
    start?: number;
    end?: number;
    total?: number;
    rangeType?: string;

    data: T[];
}

export interface ETaggedObject {
    etag?: string;
}

export interface APIConnectorRequestInit extends Omit<ConnectorRequestInit, 'api'> {
    api: string;
    rtApi?: RtApi;
    noRedirectIfUnauthorized?: boolean;
}

export type ResponseType = 'blob' | 'buffer' | 'text';

export interface ConnectorRequestInit extends RequestInit {
    api?: string; // You can force the API
    progressMonitorRtApi?: RtApi; //

    json?: any;
    params?: any;
    forceResponse?: boolean;
    forceResponseContentType?: string;
    verifyJSONResponse?: boolean;
    etag?: string;
    loadLocationContentOn201?: boolean;
    forceURL?: boolean;
    noCache?: boolean;

    waitForProgressMonitor?: (api: RtApi, processId: ProcessId, progressMonitor: ProgressMonitor) => Promise<any>;
    getProcessId?: ((responseJson: any) => ProcessId | ProcessId[]) | string;

    responseType?: ResponseType;
}

export class Connector {
    private static instance: Connector;

    #bearer?: string;
    #tokenInformation?: LoginResult;
    #language?: string;
    #unauthorizedCallBack?: UnauthorizedCallback;

    constructor() {
        this.#bearer = localStorage.getItem(ARG_BEARER_KEY) || undefined;
        const tokenInfo = localStorage.getItem(ARG_TOKEN_INFO_KEY);
        this.#tokenInformation = tokenInfo ? JSON.parse(tokenInfo) : undefined;
    }

    static getInstance(): Connector {
        if (!Connector.instance) {
            Connector.instance = new Connector();
        }

        return Connector.instance;
    }

    setLanguage(language: string | undefined) {
        this.#language = language;
    }

    setUnauthorizedCallBack(callBack?: UnauthorizedCallback) {
        this.#unauthorizedCallBack = callBack;
    }

    setBearer(bearer: string) {
        localStorage.setItem(ARG_BEARER_KEY, bearer);
        this.#bearer = bearer;
    }

    clearBearer() {
        localStorage.removeItem(ARG_BEARER_KEY);
        this.#bearer = undefined;
    }

    fillAuthentication(headers: Record<string, any>) {
        if (this.#bearer) {
            headers['Authorization'] = `Bearer ${this.#bearer}`;
        }

        if (this.#tokenInformation) {
            headers[this.#tokenInformation.headerName] = this.#tokenInformation.token;
        }
    }

    getAccessTokenFactory(): (() => string) {
        return () => {
            if (this.#tokenInformation) {
                return this.#tokenInformation.token;
            }

            if (this.#bearer) {
                return this.#bearer;
            }

            return '';
        };
    }

    async requestResults<T>(
        requestURL: string,
        arrayPath: string,
        optionsParam: APIConnectorRequestInit,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<RangeResponse<T>> {
        const options = {
            ...optionsParam,
            forceResponse: true,
        };

        const response: Response = await this.request(requestURL, options, progressMonitor);

        const contentType = options.forceResponseContentType || response.headers.get('content-type')?.toLowerCase() || '';

        if (!contentType.startsWith('application/json')) {
            throw new ResponseError('Bad content type', requestURL, undefined, response);
        }

        const rangeResponse: RangeResponse<T> = {
            data: [],
        };

        const contentRange = response.headers.get('content-range');
        if (!contentRange) {
            console.warn('Caution, content-range Header can not be read verify CORS parameters (Access-Control-Expose-Headers)');
        }
        if (contentRange) {
            const reg = /^(\S+)\s*(\d+)-(\d+)\s*\/\s*(\d+)\s*$/.exec(contentRange);
            if (reg) {
                rangeResponse.rangeType = reg[1];
                rangeResponse.start = parseInt(reg[2]);
                rangeResponse.end = parseInt(reg[3]);
                rangeResponse.total = parseInt(reg[4]);
            } else {
                const reg2 = /^(\S+)\s*(\d+)-(\d+)\s*\/\s*\*\s*$/.exec(contentRange);
                if (reg2) {
                    rangeResponse.rangeType = reg2[1];
                    rangeResponse.start = parseInt(reg2[2]);
                    rangeResponse.end = parseInt(reg2[3]);
                }
            }
        }

        const json = await response.json();
        const array = get(json, arrayPath);
        if (!isArray(array)) {
            throw new ResponseError('Invalid data', requestURL, undefined, response);
        }

        rangeResponse.data = array;

        return rangeResponse;
    }

    async rawRequest(
        url: string,
        options: ConnectorRequestInit,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ) {
        const controller = new AbortController();
        const abortSignal = () => {
            controller.abort();
        };

        progressMonitor.once('Cancel', abortSignal);

        let response: Response;

        try {
            response = await fetch(url, {
                ...options,
                signal: controller.signal,
            });
        } catch (error) {
            progressMonitor.verifyCancelled();

            console.error('Fetch url=', url, 'error=', error);
            throw new ResponseError('Fetch error', url, error as Error);
        } finally {
            progressMonitor.off('Cancel', abortSignal);
        }

        progressMonitor.verifyCancelled();

        const responseJSON = await response.json();

        return responseJSON;
    }

    async request(
        requestURL: string,
        initialOptions: APIConnectorRequestInit,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<any | null> {
        progressMonitor.verifyCancelled();

        const headers: Record<string, string> = {
            'Strict-Transport-Security': 'max-age=31536000',
            'X-Frame-Options': 'SAMEORIGIN',
            'X-Content-Type-Options': 'nosniff',
            'X-CSRF-TOKEN': 'Argonos',
        };

        if (Environment.appId) {
            headers['Argonos-App-Id'] = Environment.appId;
        }

        if (Environment.appBuildId) {
            headers['Argonos-App-Build-Id'] = Environment.appBuildId;
        }

        if (this.#language && localStorage.DISABLE_CULTURE !== 'true') {
            headers['Accept-Language'] = this.#language;
        }

        if (initialOptions.etag) {
            headers['If-None-Match'] = `"${initialOptions.etag}"`;
        }

        this.fillAuthentication(headers);

        let options: APIConnectorRequestInit = {
            ...initialOptions,
            cache: (initialOptions.noCache !== false && !initialOptions.etag) ? 'no-cache' : undefined,
            credentials: initialOptions?.credentials === 'omit' ? 'omit' : 'include',
            headers: {
                ...initialOptions.headers,
                ...headers,
            },
        };

        let url = requestURL;
        if (options.forceURL !== true) {
            const apiServerUrl = options.api;

            if (apiServerUrl && !requestURL.startsWith(`${apiServerUrl}/`)) {
                url = `${apiServerUrl}${requestURL}`;
            }
        }

        const {
            json,
            params,
        } = options;

        if (json) {
            options = {
                method: options.method || 'POST', // You can force method !
                ...options,
                headers: {
                    'Content-Type': 'application/json',
                    ...options.headers,
                },
                body: JSON.stringify(json),
            };
        }

        if (params) {
            const formattedParams = new URLSearchParams({});
            for (const [key, value] of Object.entries(params as Record<string, any>)) {
                if (isNil(value)) {
                    continue;
                }
                if (Array.isArray(value)) {
                    for (const _valueItem of value) {
                        formattedParams.append(key, _valueItem);
                    }
                    continue;
                }
                formattedParams.append(key, String(value));
            }
            const searchParams = formattedParams.toString();
            if (searchParams.length) {
                url += `?${searchParams}`;
            }
        }

        if (options.method === 'PUT' || options.method === 'POST' || options.method === 'DELETE') {
            options = {
                ...options,
                headers: {
                    accept: '*/*',
                    ...options.headers,
                },
            };
        }

        let response: Response;

        const controller = new AbortController();
        const abortSignal = () => {
            controller.abort();
        };

        progressMonitor.once('Cancel', abortSignal);

        try {
            response = await fetch(url, {
                ...options,
                signal: controller.signal,
            });
        } catch (error) {
            progressMonitor.verifyCancelled();

            console.error('Fetch url=', url, 'error=', error);
            throw new ResponseError('Fetch error', url, error as Error);
        } finally {
            progressMonitor.off('Cancel', abortSignal);
        }

        progressMonitor.verifyCancelled();

        if (!response) {
            throw new ResponseError('No response object', url);
        }

        if (response.status === 204) {
            // No response
            return null;
        }

        if (response.status === 201 && options.loadLocationContentOn201 !== false) {
            const location = response.headers.get('location');
            if (location) {
                const resourceURL = new URL(location, url).toString();
                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                const response2: any = await this.request(resourceURL, {
                    api: options.api,
                    loadLocationContentOn201: false,
                    forceURL: true,
                    waitForProgressMonitor: options.waitForProgressMonitor,
                }, sub2);

                return response2;
            }
        }

        if (response.status === 304) {
            // Unchanged
            throw new ArgonosError('Not modified', ETAG_NOT_MODIFIED);
        }

        if (response.status === 401) {
            localStorage.removeItem(ARG_AUTHENTICATED_KEY);
            unauthorizedPageAccessed();

            if (!options.noRedirectIfUnauthorized) {
                if (Environment.apiOAuth) {
                    redirectToOAuthLogin();
                } else {
                    document.location.replace(window.location.origin);
                }
            }

            throw new ResponseError('Need authentication', url, undefined, response);
        }

        if (response.status === 403) {
            throw new ResponseError(
                response.statusText || 'Forbidden',
                url,
                undefined,
                response,
                {
                    type: 'Forbidden',
                    title: 'No Permission',
                    status: 403,
                    detail: 'You don\'t have the required permissions to perform this action',
                },
            );
        }

        const contentType = options.forceResponseContentType || response.headers.get('content-type')?.toLowerCase();
        const cl = response.headers.get('content-length');
        let contentLength: number | undefined = undefined;
        if (isString(cl)) {
            contentLength = parseInt(cl);
        }

        if (contentLength === 0 && response.status === 200) {
            if (process.env.NODE_ENV === 'development') {
                console.warn('Lame WS', url, 'returns empty response with 200 status');
            }

            // Like 204
            return null;
        }

        let responseJson: any | undefined = undefined;
        if (
            !options.forceResponse &&
            contentType &&
            // THERE IS A BUG WITH 404 with old previous API
            response.status != 404 &&
            (contentType.startsWith('application/json') || contentType.startsWith('application/problem+json'))
        ) {
            try {
                responseJson = await response.json();
            } catch (error) {
                throw new ResponseError('Invalid JSON', url, error as Error, response, responseJson);
            }

            if ('etag' in options) {
                const etag = response.headers.get('etag') || undefined;
                (responseJson as ETaggedObject).etag = etag;
            }

            progressMonitor.verifyCancelled();
        }

        if (response.status === 202) {
            // ProgressMonitor
            const waitForProgressMonitor = options.waitForProgressMonitor;
            if (waitForProgressMonitor) {
                const getProcessId = options.getProcessId || 'processId';

                const _processId: ProcessId | ProcessId[] | undefined = !!responseJson && isFunction(getProcessId) ? getProcessId(responseJson) : responseJson?.[getProcessId as string];

                debug('ProgressMonitor', _processId, responseJson);

                if (!_processId) {
                    throw new ResponseError('ProcessId is not returned', url);
                }

                if (!options.progressMonitorRtApi) {
                    throw new ResponseError('ProgressMonitorRtApi must be defined', url);
                }

                if (isArray(_processId)) {
                    const ps = _processId.map(async (processId) => {
                        const sub = new SubProgressMonitor(progressMonitor, 1);

                        await waitForProgressMonitor(options.progressMonitorRtApi!, processId, sub);
                    });

                    await Promise.all(ps);

                    progressMonitor.verifyCancelled();

                    const sub2 = new SubProgressMonitor(progressMonitor, 1);
                    const response = await this.request(url, {
                        ...initialOptions,
                        waitForProgressMonitor: undefined,
                    }, sub2);

                    return response;
                }

                await waitForProgressMonitor(options.progressMonitorRtApi, _processId, progressMonitor);

                progressMonitor.verifyCancelled();

                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                const response = await this.request(url, {
                    ...initialOptions,
                    waitForProgressMonitor: undefined,
                }, sub2);

                return response;
            }
        }

        if (response.status < 200 || response.status >= 300) {
            const error = new ResponseError('Web service error', url, undefined, response, responseJson);
            throw error;
        }

        if (options.verifyJSONResponse && (
            !contentType
            || !contentType.startsWith('application/json')
            || contentLength === 0
        )) {
            const error = new ResponseError('Web service does not return JSON', url, undefined, response);
            throw error;
        }

        localStorage.setItem(ARG_AUTHENTICATED_KEY, 'true');

        // Force the response callback
        if (options.forceResponse) {
            return response;
        }

        if (!contentType) {
            // There is no interesting content
            return;
        }

        if (contentType.startsWith('application/json')) {
            progressMonitor.verifyCancelled();

            return responseJson;
        }

        let responseType: ResponseType | undefined = options.responseType;

        if (!responseType) {
            if (contentType.startsWith('text/') ||
                contentType.startsWith('application/javascript')) {
                responseType = 'text';
            } else if (contentType.startsWith('image/')
                || contentType.startsWith('video/')
                || contentType.startsWith('application/zip')
            ) {
                responseType = 'blob';
            } else {
                responseType = 'buffer';
            }
        }

        if (responseType === 'text') {
            const text = await response.text();

            progressMonitor.verifyCancelled();

            return text;
        }

        if (responseType === 'blob') {
            const blob = await response.blob();

            progressMonitor.verifyCancelled();

            return blob;
        }

        if (responseType === 'buffer') {
            const body = await response.arrayBuffer();

            progressMonitor.verifyCancelled();

            return body;
        }

        // NO contentType ... ignored case
    }

    downloadBlob(fileName: string, blob: Blob): true {
        downloadBlob(fileName, blob);

        return true;
    }

    async base64ToBlob(base64Content: string) {
        const response = await fetch(base64Content);
        const blob = await response.blob();

        return blob;
    }

    async downloadRequest(
        fileName: string,
        url: string,
        options: APIConnectorRequestInit,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<true> {
        options.forceResponse = true;

        const response = await this.request(url, options, progressMonitor);

        const blob = await response.blob();

        this.downloadBlob(fileName, blob);

        return true;
    }

    downloadObject(
        fileName: string,
        object: any,
    ): void {
        const a = document.createElement('a');
        const href = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(object))}`;
        a.href = href;
        a.download = fileName;
        a.click();

        a.remove();
    }

    logout() {
        if (!Environment.apiOAuth) {
            this.logout__credentials__legacy().catch((error) => {
                console.error(error);
            }).finally(() => {
                localStorage.removeItem(ARG_AUTHENTICATED_KEY);

                document.location.replace('/');
            });

            return;
        }

        localStorage.removeItem(ARG_AUTHENTICATED_KEY);

        let url = Environment.authLogoutUri;
        if (!url) {
            url = `${getSettingsApi()}/authentication/logout-oauth`;
        }
        document.location.replace(url);
    }

    openChangePasswordWindow() {
        if (!Environment.apiOAuth) {
            console.error('Cannot change password without oAuth api');

            return;
        }

        const queryParams = new URLSearchParams({ redirectUrl: window.location.origin });
        const changePasswordUrl = `${getAdministrationApi()}/users/password?${queryParams}`;

        window.location.replace(changePasswordUrl);
    }

    async login__credentials__legacy(username: string, password: string, progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        const url = '/authentication/login';
        try {
            const result = (await this.request(
                url,
                {
                    json: {
                        username,
                        password,
                    },
                    api: getAdministrationApi()!,
                },
                progressMonitor,
            )) as LoginResult;

            this.#tokenInformation = result;
            localStorage.setItem(ARG_TOKEN_INFO_KEY, JSON.stringify(result));

            return true;
        } catch (x) {
            console.error('Can not login', x);

            return false;
        }
    }

    async logout__credentials__legacy(progressMonitor: ProgressMonitor = ProgressMonitor.empty()) {
        localStorage.removeItem(ARG_TOKEN_INFO_KEY);
        await this.request(
            '/authentication/logout',
            {
                method: 'POST',
                api: getAdministrationApi()!,
            },
            progressMonitor,
        );
    }
}

export function computeOAuthLoginURL(redirectUrl: string, module = '') {
    const locale = localStorage.getItem(ARG_LOCALE_KEY) ?? Environment.defaultLocale;
    let url = Environment.authRedirectUri;
    url = template(url, REDIRECT_URL_TEMPLATE)({
        redirectUrl,
        locale,
        api: getAdministrationApi(),
        module,
    });

    return url;
}

export function isNotChangedEtagError(error: any) {
    if (!(error instanceof ArgonosError)) {
        return false;
    }

    if (error.code !== ETAG_NOT_MODIFIED) {
        return false;
    }

    return true;
}

export type JsonChangeOperation = 'replace' | 'add' | 'remove';

export interface JsonChange {
    op?: JsonChangeOperation;
    path: string;
    value: any;
}

export function constructPatchJson(comment: string, changes: JsonChange[]): any {
    return {
        comment,
        changes: changes.map((c) => ({
            op: c.op || 'replace',
            path: (c.path.charAt(0) === '/' ? c.path : `/${c.path}`),
            value: c.value,
        })),
    };
}

export function createJsonPathOptions(): ConnectorRequestInit {
    return {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json-patch+json',
        },
    };
}

export function createPatchRequest(comment: string, ...changes: JsonChange[]): ConnectorRequestInit {
    const options = {
        ...createJsonPathOptions(),
        json: constructPatchJson(comment, changes),
    };

    return options;
}

export function createPatchRequestFromOperation(comment: string, ...operations: Operation[]): ConnectorRequestInit {
    const options = {
        ...createJsonPathOptions(),
        json: {
            changes: operations.map((c) => ({
                op: c.op || 'replace',
                path: (c.path.charAt(0) === '/' ? c.path : `/${c.path}`),
                value: ('value' in c) ? c.value : undefined,
            })),
        },
    };

    return options;
}

export interface JsonChangesBuilder {
    replace(path: string, value?: any): JsonChangesBuilder;

    add(path: string, value?: any): JsonChangesBuilder;

    remove(path: string): JsonChangesBuilder;

    build(): JsonChange[];

    isEmpty(): boolean;
}
export function updateJsonChanges(path: string, newValue: any, oldValue: any, jsonChangesBuilder: JsonChangesBuilder): JsonChangesBuilder {
    let operation: JsonChangeOperation | undefined = undefined;
    if (isNil(oldValue) && !isNil(newValue)) {
        operation = 'add';
    } else if (!isNil(oldValue) && newValue !== oldValue) {
        operation = 'replace';
    } else if (isNil(newValue) && !isNil(oldValue)) {
        operation = 'remove';
    }
    const changes: JsonChange[] = jsonChangesBuilder.build();
    const found = changes.find(change => change.path === path);
    if (!found && operation) {
        switch (operation) {
            case 'add':
                jsonChangesBuilder.add(path, newValue);
                break;
            case 'remove':
                jsonChangesBuilder.remove(path);
                break;
            case 'replace':
                jsonChangesBuilder.replace(path, newValue);
                break;
        }
    }

    return jsonChangesBuilder;
}

export function createJsonChangesBuilder(): JsonChangesBuilder {
    const list: JsonChange[] = [];

    function appendChange(operation: JsonChangeOperation, path: string, value?: any) {
        const jsonChange: JsonChange = {
            op: operation,
            path,
            value,
        };
        list.push(jsonChange);
    }

    const ret: JsonChangesBuilder = {
        replace(this: JsonChangesBuilder, path: string, value?: any): JsonChangesBuilder {
            appendChange('replace', path, value);

            return this;
        },

        add(this: JsonChangesBuilder, path: string, value?: any): JsonChangesBuilder {
            appendChange('add', path, value);

            return this;
        },

        remove(this: JsonChangesBuilder, path: string): JsonChangesBuilder {
            appendChange('remove', path);

            return this;
        },

        build(): JsonChange[] {
            return list;
        },

        isEmpty(): boolean {
            return !list.length;
        },
    };

    return ret;
}

export function isAuthenticated(): boolean {
    const ret = localStorage.getItem(ARG_AUTHENTICATED_KEY) === 'true';

    return ret;
}
