import { LatLngLiteral } from 'leaflet';

export enum RadiusUnit {
    Kilometer = 'kilometer',
    Meter = 'meter',
}

export const DEFAULT_RADIUS_UNIT = RadiusUnit.Kilometer;

export interface GeoAreaValue {
    centeredArea?: {
        latLng?: LatLngLiteral;
        radius?: number;
        address?: string;
    };
    polygonArea?: {
        latLng: LatLngLiteral[];
        area?: number;
    };
}

export interface GeoLocationValue {
    coordinates?: LatLngLiteral;
    address?: string;
}
