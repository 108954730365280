import { useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Ontology, UniverseKBFeedingStatus, UniverseKBLastFeedingStatus } from 'src/settings/models/dtoApi';
import { ArgCheckbox, ArgCheckboxMinus, ArgTable2Column, ClassNamesFn } from 'src/components/basic';
import { UniverseTableKebabMenu } from '../universe-table-kebab-menu/universe-table-kebab-menu';
import { dateSorter, numberSorter, stringSorter } from '../../../../../utils/sorter';
import { DateByUser } from '../../../../../components/common/date-by-user';
import { ResultTag } from '../../../../../components/common/tags/result-tag';

import './universe-table.less';

export const messages = defineMessages({
    nameColumn: {
        id: 'settings.universe-table.name',
        defaultMessage: 'Name',
    },
    description: {
        id: 'settings.universe-table.description',
        defaultMessage: 'Description',
    },
    creationDate: {
        id: 'settings.universe-table.creation-date',
        defaultMessage: 'Creation date',
    },
    order: {
        id: 'settings.universe-table.order',
        defaultMessage: 'Order',
    },
    lastSynchronization: {
        id: 'settings.universe-table.lastSynchronization',
        defaultMessage: 'Last synchronization',
    },
    lastSynchronizationUpdatedDate: {
        id: 'settings.universe-table.lastSynchronizationUpdatedDate',
        defaultMessage: 'Date of last changes loaded',
    },
    running: {
        id: 'settings.universe-table.running',
        defaultMessage: 'Running...',
    },
    synchronizationStatus: {
        id: 'settings.universe-table.synchronizationStatus',
        defaultMessage: 'Synchronization result',
    },
});

export interface UITableOntology extends Ontology {
    order: number;
    lastSynchronizationDate?: Date;
    lastSynchronizationUpdatedDate?: Date;
    lastSynchronizationStatus?: UniverseKBLastFeedingStatus;
    synchronizationStatus: UniverseKBFeedingStatus;
}

export function useTableColumns(
    ontologies: UITableOntology[],
    universesIdSelected: Set<string>,
    setUniversesIdSelected: (ids: Set<string>) => void,
    classNames: ClassNamesFn,
): ArgTable2Column<UITableOntology>[] {
    const handleUniverseSelection = useCallback((universeId: string) => {
        const newSet = new Set(universesIdSelected);
        if (universesIdSelected.has(universeId)) {
            newSet.delete(universeId);
            setUniversesIdSelected(newSet);

            return;
        }

        newSet.add(universeId);
        setUniversesIdSelected(newSet);
    }, [universesIdSelected, setUniversesIdSelected]);

    const handleAllUniversesSelection = useCallback(() => {
        if (universesIdSelected.size > 0) {
            setUniversesIdSelected(new Set());

            return;
        }

        const universesId = ontologies.map((universe) => universe.id);
        setUniversesIdSelected(new Set(universesId));
    }, [ontologies, universesIdSelected, setUniversesIdSelected]);

    const getAllUniversesCheckboxValue = useCallback(() => {
        if (universesIdSelected.size === 0) {
            return false;
        }

        if (universesIdSelected.size === ontologies.length) {
            return true;
        }

        return 'minus';
    }, [universesIdSelected, ontologies]);

    const ret = useMemo<ArgTable2Column<UITableOntology>[]>(
        () => [
            {
                key: 'check',
                title: function header() {
                    return (
                        <ArgCheckboxMinus
                            size='node'
                            value={getAllUniversesCheckboxValue()}
                            onChange={() => handleAllUniversesSelection()}
                        />
                    );
                },
                dataIndex: 'id',
                width: 40,
                render: function checkboxCell(id) {
                    return (
                        <ArgCheckbox
                            size='node'
                            value={universesIdSelected.has(id)}
                            onChange={() => handleUniverseSelection(id)}
                        />
                    );
                },
            },
            {
                key: 'order',
                title: messages.order,
                dataIndex: 'order',
                width: 60,
                sorter: (a, b) => numberSorter<UITableOntology>(a, b, item => item.order),
                defaultSortOrder: 'ascend',
                render: function orderCell(_order: number, item) {
                    return item.order;
                },
            },
            {
                key: 'name',
                title: messages.nameColumn,
                dataIndex: 'name',
                sorter: (a, b) => stringSorter<UITableOntology>(a, b, item => item.name),
                render: function nameCell(name) {
                    return name;
                },
            },
            {
                key: 'description',
                columnName: 'description',
                title: messages.description,
                dataIndex: 'description',
                sorter: (a, b) => stringSorter<UITableOntology>(a, b, item => item.description),
            },
            {
                key: 'creationDate',
                sortable: true,
                columnName: 'Creation date',
                title: messages.creationDate,
                dataIndex: 'creationDate',
                sorter: (a, b) => dateSorter<UITableOntology>(a, b, item => item.createdDate),
                render: function creationDateCell(creationDate, universe) {
                    return (
                        <DateByUser date={universe.createdDate} user={universe.createdBy} relative={true} />
                    );
                },
            },
            {
                key: 'lastSynchronizationDate',
                title: messages.lastSynchronization,
                dataIndex: 'lastSynchronizationDate',
                sorter: (a, b) => dateSorter<UITableOntology>(a, b, item => item.lastSynchronizationDate),
                render: function lastSyncDateCell(_lastSyncDate, item) {
                    if (item.synchronizationStatus !== UniverseKBFeedingStatus.Running) {
                        return item.lastSynchronizationDate && (<DateByUser date={item.lastSynchronizationDate} relative={true} />);
                    }

                    return <FormattedMessage {...messages.running} />;
                },
            },
            {
                key: 'lastSynchronizationUpdatedDate',
                title: messages.lastSynchronizationUpdatedDate,
                dataIndex: 'lastSynchronizationUpdatedDate',
                sorter: (a, b) => dateSorter<UITableOntology>(a, b, item => item.lastSynchronizationUpdatedDate),
                render: function lastSyncUpdatedDateCell(_lastSyncUpdatedDate, item) {
                    return item.lastSynchronizationUpdatedDate && (<DateByUser date={item.lastSynchronizationUpdatedDate} relative={false} />);
                },
            },
            {
                key: 'synchronizationStatus',
                className: classNames('&-flex-row-cell'),
                title: messages.synchronizationStatus,
                dataIndex: 'synchronizationStatus',
                sorter: (a, b) => stringSorter<UITableOntology>(a, b, item => item.lastSynchronizationStatus),
                render: function synchronizationStatusCell(_synchronizationStatus, item) {
                    switch (item.lastSynchronizationStatus) {
                        case UniverseKBLastFeedingStatus.Failed:
                            return <ResultTag hasFailed={true} />;
                        case UniverseKBLastFeedingStatus.Success:
                            return <ResultTag hasFailed={false} />;
                        default:
                            break;
                    }
                },
            },
            {
                key: 'kebab-menu',
                title: '',
                width: 50,
                dataIndex: 'id',
                render: function menuCell(id, ontology) {
                    return <UniverseTableKebabMenu ontologyId={ontology.id} ontologyName={ontology.name} universeId={ontology.universeIds[0]} />;
                },
            },
        ],
        [
            getAllUniversesCheckboxValue,
            handleAllUniversesSelection,
            handleUniverseSelection,
            universesIdSelected,
        ],
    );

    return ret;
}
