import React, { ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';
import { isBoolean, isFunction, isString } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

import { ArgToolItemRenderFunction, ArgToolItemRenderWithContextFunction } from '../arg-toolbar/arg-toolbar-item';
import { KeyBindingDescriptor } from '../keybindings/keybinding';
import { ButtonClickEvent } from '../arg-button/arg-button';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ClassValue } from '../arg-hooks/use-classNames';
import { ArgButtonType, ArgRenderedIcon, ArgRenderedText } from '../types';
import { TooltipPlacement } from '../arg-tooltip/utils';
import { ArgNavigateTo } from '../arg-navigation/arg-navigate-to';

export type ToolType =
    | 'label'
    | 'button'
    | 'combo'
    | 'group'
    | 'custom'
    | 'panel'
    | 'editor'
    | 'marker'
    | 'separator'
    | 'menu'
    | 'actionDropdown';
export type ToolPath=string;

export interface ToolLinkNavigation {
    to: string;
}

export interface Tool<T = undefined> {
    key?: React.Key;
    path: ToolPath; // Unique
    type?: ToolType;
    loading?: boolean;
    order?: number;
    testid?: string;
    override?: number; // Tools with higher override number takes precedence

    selected?: boolean | ((environmentContext: T) => boolean);
    visible?: boolean | ((environmentContext: T) => boolean);
    disabled?: boolean | ((environmentContext: T) => boolean);

    computeChildren?: (parentTool: Tool<T>, environmentContext: T, progressMonitor: ProgressMonitor) => Tool<T>[]|Promise<Tool<T>[]|undefined>;

    tooltip?: ReactNode | MessageDescriptor | ((props: Tool<T>, environmentContext: T) => ReactNode | MessageDescriptor);
    tooltipPlacement?: TooltipPlacement;

    icon?: ArgRenderedIcon | ((environmentContext: T) => ArgRenderedIcon);
    label?: ArgRenderedText | ((environmentContext: T) => ArgRenderedText);
    // messageValues?: ArgMessageValues;
    description?: ArgRenderedText | ((environmentContext: T) => ArgRenderedText);
    //componentType?: ComponentType;
    buttonType?: ArgButtonType;

    navigateTo?: string|ArgNavigateTo|((tool: Tool<T>, environmentContext: T) => string|ArgNavigateTo);

    customRender?: ArgToolItemRenderWithContextFunction<T>;
    menuItemCustomRender?: boolean;

    preventCloseMenuOnClick?: boolean;

    panelRender?: ArgToolItemRenderFunction<T>;

    onClick?: (props: Tool<T>, environmentContext: T, event?: ButtonClickEvent) => (Promise<void> | void);
    onShiftClick?: (props: Tool<T>, environmentContext: T, event: ButtonClickEvent) => (Promise<void> | void);
    onCtrlClick?: (props: Tool<T>, environmentContext: T, event: ButtonClickEvent) => (Promise<void> | void);
    onAltClick?: (props: Tool<T>, environmentContext: T, event: ButtonClickEvent) => (Promise<void> | void);
    onMouseOver?: (event: ButtonClickEvent) => void;

    keyBinding?: KeyBindingDescriptor;
    shiftKeyBinding?: KeyBindingDescriptor;
    altKeyBinding?: KeyBindingDescriptor;
    ctrlKeyBinding?: KeyBindingDescriptor;

    className?: ClassValue;
    menuClassName?: ClassValue;

    onUnmount?: () => void;
}

export type ToolPropsGenerator<T> = (data: T) => Partial<Tool<T>>;

export interface ToolItemWithPropsGenerator<T> extends Tool<T> {
    __internal_propsGenerator: ToolPropsGenerator<T>;
}

export type ToolChanges<T = undefined> = Omit<Tool<T>, 'path'>;

export function isToolDisabled(tool: Tool<undefined>, environmentContext?: undefined): boolean;
export function isToolDisabled<T>(tool: Tool<T>, environmentContext: T): boolean;

export function isToolDisabled<T>(tool: Tool<T>, environmentContext: T): boolean {
    const { disabled } = tool;
    if (isBoolean(disabled)) {
        return disabled;
    }
    if (isFunction(disabled)) {
        const result = disabled(environmentContext);

        return result;
    }

    return false;
}

export function isToolVisible(tool: Tool<undefined>, environmentContext?: undefined): boolean;
export function isToolVisible<T>(tool: Tool<T>, environmentContext: T): boolean;

export function isToolVisible<T>(tool: Tool<T>, environmentContext: T): boolean {
    const { visible } = tool;
    if (isBoolean(visible)) {
        return visible;
    }
    if (isFunction(visible)) {
        const result = visible(environmentContext);

        return result;
    }

    return true;
}

export function isToolSelected(tool: Tool<undefined>, environmentContext?: undefined): boolean;
export function isToolSelected<T>(tool: Tool<T>, environmentContext: T): boolean;

export function isToolSelected<T>(tool: Tool<T>, environmentContext: T): boolean {
    const { selected } = tool;
    if (isBoolean(selected)) {
        return selected;
    }
    if (isFunction(selected)) {
        const result = selected(environmentContext);

        return result;
    }

    return false;
}

export function getToolLabel<T>(tool: Tool<T>, environmentContext: T): ArgRenderedText {
    const { label } = tool;
    if (isFunction(label)) {
        const result = label(environmentContext);

        return result;
    }

    return label;
}

export function getToolIcon<T>(tool: Tool<T>, environmentContext: T): ArgRenderedIcon {
    const { icon } = tool;
    if (isFunction(icon)) {
        const result: ArgRenderedIcon = icon(environmentContext) as ArgRenderedIcon; // TODO OO: remove cast

        return result;
    }

    return icon;
}

export function getToolDescription<T>(tool: Tool<T>, environmentContext: T): ArgRenderedText {
    const { description } = tool;
    if (isFunction(description)) {
        const result = description(environmentContext);

        return result;
    }

    return description;
}

export function getToolTooltip<T>(tool: Tool<T>, environmentContext: T): ReactNode | MessageDescriptor {
    const { tooltip } = tool;
    if (isFunction(tooltip)) {
        const result = tooltip(tool, environmentContext);

        return result;
    }

    return tooltip;
}

export function getToolNavigateTo<T>(tool: Tool<T>, environmentContext: T): ArgNavigateTo|string|undefined {
    let { navigateTo } = tool;
    if (isFunction(navigateTo)) {
        navigateTo = navigateTo(tool, environmentContext);
    }

    return navigateTo;
}

export function toolNavigateTo<T>(tool: Tool<T>, environmentContext: T, navigate: NavigateFunction): boolean {
    let navigateTo = getToolNavigateTo(tool, environmentContext);

    if (isString(navigateTo)) {
        navigateTo = {
            to: navigateTo,
        };
    }

    if (!navigateTo) {
        return false;
    }

    navigate(
        navigateTo.to, {
            replace: navigateTo.replace,
            preventScrollReset: navigateTo.preventScrollReset,
        },
    );

    return true;
}
