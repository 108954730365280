import { CasePieceId } from 'src/model/basic-case-piece';
import { computeEntityStateURL, EntityState } from '../../utils/rt-states/folders/entity-state';
import { BasicStates, useBasicStates } from 'src/utils/rt-states/use-basic-states';
import { CasePieceType } from '../../model/case-piece-type';
import { getDataPreparationEntitiesRtApi } from './rt-apis';
import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { EntityId } from '../../model/argonos-piece';
import { PREPARATION_ENTITY_EVENTS } from './events';

export interface EntityStateInfo {
    entityId: EntityId;
    casePieceType: CasePieceType;
}

export function preparationEntityStateFactory(url: string, casePieceId: CasePieceId) {
    return new EntityState(getDataPreparationEntitiesRtApi(), url, casePieceId, PREPARATION_ENTITY_EVENTS);
}

export function usePreparationEntityState(casePieceId: CasePieceId): EntityState;
export function usePreparationEntityState(casePieceId: CasePieceId | undefined): EntityState | undefined;

export function usePreparationEntityState(casePieceId: CasePieceId | undefined): EntityState | undefined {
    const state = useBasicState<EntityState>((url) => {
        return preparationEntityStateFactory(url, casePieceId!);
    }, (casePieceId) ? computeEntityStateURL(casePieceId) : undefined);

    return state;
}


// WIP
export function usePreparationEntityStates(casePieceIds: (EntityId | undefined)[] | undefined): BasicStates<EntityState> | undefined {
    const state = useBasicStates(
        (url, key: CasePieceId) => preparationEntityStateFactory(url, key),
        casePieceIds,
        (key: CasePieceId) => computeEntityStateURL(key),
    );

    return state;
}
