import { cloneDeep, set } from 'lodash';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

import { ArgCombo, useClassNames } from 'src/components/basic';
import { DropdownTooltipField } from '../../../../common/dropdown-tooltip-field/dropdown-tooltip-field';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';
import { ValuationEffectEvent, ValuationEffectFilter } from 'src/settings/models/valuation-policy';
import { useValuationRuleStateContext } from 'src/settings/universes/common/providers/policy-rules-provider';

import './valuation-rule-effect-block.less';

const messages = defineMessages({
    select: {
        id: 'settings.valuation-rule-effect.event.item.select',
        defaultMessage: 'Select',
    },
    create: {
        id: 'settings.valuation-rule-effect.event.creation',
        defaultMessage: 'Creation',
    },
    update: {
        id: 'settings.valuation-rule-effect.event.update',
        defaultMessage: 'Update',
    },
    all: {
        id: 'settings.valuation-rule-effect.event.all',
        defaultMessage: 'All events',
    },
    onEvent: {
        id: 'settings.valuation-rule-effect.event.on-event',
        defaultMessage: 'on',
    },
});

interface EventItem {
    key: string;
    label: MessageDescriptor;
    value: ValuationEffectEvent;
}

const ITEMS: EventItem[] = [
    {
        key: 'all',
        label: messages.all,
        value: ValuationEffectEvent.All,
    },
    {
        key: 'creation',
        label: messages.create,
        value: ValuationEffectEvent.Create,
    },
    {
        key: 'update',
        label: messages.update,
        value: ValuationEffectEvent.Update,
    },
];

interface EventDropdownProps {
    targetType: 'object' | 'property';
    effectValue: ValuationEffectFilter;
    index: number;
}

export function EventDropdown({
    effectValue,
    targetType,
    index,
}: EventDropdownProps) {
    const classNames = useClassNames('settings-valuation-rule-effect-block');
    const { setRule, editable } = useValuationRuleStateContext();

    const valueName = targetType === 'property' ? effectValue.setMetadata?.onEvent : effectValue.setProperty?.onEvent;
    const value = ITEMS.find((item) => item.value === valueName);

    return <>
        <div className={classNames('&-sentence-text')}>
            <FormattedMessage {...messages.onEvent} />
        </div>
        {editable ? (
            <ArgCombo<EventItem>
                className={classNames('&-universe-items-filters')}
                hideTags={true}
                placeholder={messages.select}
                items={ITEMS}
                getItemKey={(option) => option.key}
                getItemLabel={(option) => option.label}
                value={value}
                cardinality='one'
                size='small'
                popoverClassName='arg-input-popover-no-max-width'
                renderInput={() => (
                    <DropdownTooltipField
                        className={classNames('&-universe-items-filters-input-text')}
                        value={value?.label && <FormattedMessage {...value.label} />}
                    />
                )}
                onChange={({ value }) => {
                    setRule((currentRule) => {
                        const newEffectFilter: ValuationEffectFilter = targetType === 'property' ? {
                            ...effectValue,
                            setMetadata: {
                                ...effectValue.setMetadata,
                                onEvent: value,
                            },
                        } : {
                            ...effectValue,
                            setProperty: {
                                ...effectValue.setProperty,
                                onEvent: value,
                            },
                        };

                        return set(cloneDeep(currentRule), `Effects[${index}]`, {
                            [targetType]: newEffectFilter,
                        });
                    });
                }}
            />
        ) : (
            <ReadOnlyFilterInput
                className={classNames('&-universe-items-filters')}
                value={value?.label}
            />
        )}
    </>;
}
