import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgInputSearch,
    ArgTable3,
    ArgTableColumn3,
    ClassValue,
    ProgressMonitor,
    SelectionProvider,
    ToolContext,
    useClassNames,
    useSelectionList,
} from 'src/components/basic';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { UserDataFilter, UsersDataProvider } from '../../providers/users-data-provider';
import { User } from 'src/model/user';
import { useTableColumns } from './columns';
import { DeletedUsersActionsButtonMenu } from '../deleted-users-actions-button-menu/deleted-users-actions-button-menu';

import './deleted-users-table.less';


export interface DeletedUserTableProps {
    users: UsersDataProvider;
    selectionProvider: SelectionProvider<User>;
    toolbarContext?: ToolContext;
    className?: ClassValue;
    progressMonitor?: ProgressMonitor;
    setActiveTab: Dispatch<SetStateAction<string>>;
}

const SETTINGS_TABLE_SELECTION_SOURCE = 'settings-table';

const messages = defineMessages({
    selectAll: {
        id: 'settings.deleted-users.active.table.select',
        defaultMessage: 'Select all',
    },
    unselectAll: {
        id: 'settings.deleted-users.active.table.unselect',
        defaultMessage: 'Unselect all',
    },
    searchPlaceHolder: {
        id: 'settings.deleted-users.active.table.search',
        defaultMessage: 'Search...',
    },
    openFilter: {
        id: 'settings.deleted-users.active.table.filer',
        defaultMessage: 'Open Filter',
    },
    removeFilter: {
        id: 'settings.deleted-users.active.table.filer.remove',
        defaultMessage: 'Remove filter',
    },
});

export function DeletedUsersTable({
    className,
    users,
    selectionProvider,
    progressMonitor,
    setActiveTab,
}: DeletedUserTableProps) {
    const classNames = useClassNames('deleted-users-table');

    const [filter, setFilter] = useState<UserDataFilter>({});

    const columns: ArgTableColumn3<User>[] = useTableColumns();

    useEffect(() => {
        if (users.getData().length == 0) {
            setActiveTab('users');
        }
    }, [users]);

    const [selectedUsersIds] = useSelectionList(selectionProvider);

    const selectedUsers = useMemo(
        () => users.getData().filter((user) => selectedUsersIds.includes(user.id.toString())),
        [selectedUsersIds, users],
    );

    if (progressMonitor?.isRunning) {
        return (
            <div className='arg-layout'>
                <LoadingPane progressMonitor={progressMonitor} />
            </div>
        );
    }

    return (
        <>
            <div className={classNames('&-actions')}>
                <ArgInputSearch
                    onInputChange={(search) => {
                        selectionProvider.clear(SETTINGS_TABLE_SELECTION_SOURCE);

                        setFilter((prevState) => ({
                            ...prevState,
                            search,
                        }));
                    }}
                    placeholder={messages.searchPlaceHolder}
                    className={classNames('&-actions-search')}
                />
                <DeletedUsersActionsButtonMenu selectedUsers={selectedUsers} />
            </div>
            <ArgTable3<User>
                className={classNames('&', className)}
                columns={columns}
                initialItemsCount={users.rowCount}
                rowHeight={45}
                headerHeight={45}
                dataProvider={users}
                filter={filter}
                selectionProvider={selectionProvider}
                adjustColumns={true}
                showSelectAllButton={true}
            />
        </>
    );
}
