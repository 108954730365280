import { compact, find, isNil, size, toNumber, uniq } from 'lodash';

interface FileType {
    extension?: string;
    kind?: string;
    mimeType?: string;
}

const fileTypes: FileType[] = [
    {
        extension: '.aac',
        kind: 'AAC audio',
        mimeType: 'audio/aac',
    },
    {
        extension: '.abw',
        kind: 'AbiWord document',
        mimeType: 'application/x-abiword',
    },
    {
        extension: '.arc',
        kind: 'Archive document (multiple files embedded)',
        mimeType: 'application/x-freearc',
    },
    {
        extension: '.avi',
        kind: 'AVI: Audio Video Interleave',
        mimeType: 'video/x-msvideo',
    },
    {
        extension: '.azw',
        kind: 'Amazon Kindle eBook format',
        mimeType: 'application/vnd.amazon.ebook',
    },
    {
        extension: '.bin',
        kind: 'Any kind of binary data',
        mimeType: 'application/octet-stream',
    },
    {
        extension: '.bmp',
        kind: 'Windows OS/2 Bitmap Graphics',
        mimeType: 'image/bmp',
    },
    {
        extension: '.bz',
        kind: 'BZip archive',
        mimeType: 'application/x-bzip',
    },
    {
        extension: '.bz2',
        kind: 'BZip2 archive',
        mimeType: 'application/x-bzip2',
    },
    {
        extension: '.csh',
        kind: 'C-Shell script',
        mimeType: 'application/x-csh',
    },
    {
        extension: '.css',
        kind: 'Cascading Style Sheets (CSS)',
        mimeType: 'text/css',
    },
    {
        extension: '.csv',
        kind: 'Comma-separated values (CSV)',
        mimeType: 'text/csv',
    },
    {
        extension: '.doc',
        kind: 'Microsoft Word',
        mimeType: 'application/msword',
    },
    {
        extension: '.docx',
        kind: 'Microsoft Word (OpenXML)',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    {
        extension: '.eot',
        kind: 'MS Embedded OpenType font',
        mimeType: 'application/vnd.ms-fontobject',
    },
    {
        extension: '.epub',
        kind: 'Electronic publication (EPUB)',
        mimeType: 'application/epub+zip',
    },
    {
        extension: '.gz',
        kind: 'GZip Compressed Archive',
        mimeType: 'application/gzip',
    },
    {
        extension: '.gif',
        kind: 'Graphics Interchange Format (GIF)',
        mimeType: 'image/gif',
    },
    {
        extension: '.html',
        kind: 'HyperText Markup Language (HTML)',
        mimeType: 'text/html',
    },
    {
        extension: '.htm',
        kind: 'HyperText Markup Language (HTML)',
        mimeType: 'text/html',
    },
    {
        extension: '.ico',
        kind: 'Icon format',
        mimeType: 'image/vnd.microsoft.icon',
    },
    {
        extension: '.ics',
        kind: 'iCalendar format',
        mimeType: 'text/calendar',
    },
    {
        extension: '.jar',
        kind: 'Java Archive (JAR)',
        mimeType: 'application/java-archive',
    },
    {
        extension: '.jpg',
        kind: 'JPEG image',
        mimeType: 'image/jpeg',
    },
    {
        extension: '.jpeg',
        kind: 'JPEG image',
        mimeType: 'image/jpeg',
    },
    {
        extension: '.js',
        kind: 'JavaScript',
        mimeType: 'text/javascript',
    },
    {
        extension: '.json',
        kind: 'JSON format',
        mimeType: 'application/json',
    },
    {
        extension: '.jsonld',
        kind: 'JSON-LD format',
        mimeType: 'application/ld+json',
    },
    {
        extension: '.mid',
        kind: 'Musical Instrument Digital Interface (MIDI)',
        mimeType: 'audio/midi audio/x-midi',
    },
    {
        extension: '.midi',
        kind: 'Musical Instrument Digital Interface (MIDI)',
        mimeType: 'audio/midi audio/x-midi',
    },
    {
        extension: '.mjs',
        kind: 'JavaScript module',
        mimeType: 'text/javascript',
    },
    {
        extension: '.mp3',
        kind: 'MP3 audio',
        mimeType: 'audio/mpeg',
    },
    {
        extension: '.mpeg',
        kind: 'MPEG Video',
        mimeType: 'video/mpeg',
    },
    {
        extension: '.mpkg',
        kind: 'Apple Installer Package',
        mimeType: 'application/vnd.apple.installer+xml',
    },
    {
        extension: '.odp',
        kind: 'OpenDocument presentation document',
        mimeType: 'application/vnd.oasis.opendocument.presentation',
    },
    {
        extension: '.ods',
        kind: 'OpenDocument spreadsheet document',
        mimeType: 'application/vnd.oasis.opendocument.spreadsheet',
    },
    {
        extension: '.odt',
        kind: 'OpenDocument text document',
        mimeType: 'application/vnd.oasis.opendocument.text',
    },
    {
        extension: '.oga',
        kind: 'OGG audio',
        mimeType: 'audio/ogg',
    },
    {
        extension: '.ogv',
        kind: 'OGG video',
        mimeType: 'video/ogg',
    },
    {
        extension: '.ogx',
        kind: 'OGG',
        mimeType: 'application/ogg',
    },
    {
        extension: '.opus',
        kind: 'Opus audio',
        mimeType: 'audio/opus',
    },
    {
        extension: '.otf',
        kind: 'OpenType font',
        mimeType: 'font/otf',
    },
    {
        extension: '.png',
        kind: 'Portable Network Graphics',
        mimeType: 'image/png',
    },
    {
        extension: '.pdf',
        kind: 'Adobe Portable Document Format (PDF)',
        mimeType: 'application/pdf',
    },
    {
        extension: '.php',
        kind: 'Hypertext Preprocessor (Personal Home Page)',
        mimeType: 'application/x-httpd-php',
    },
    {
        extension: '.ppt',
        kind: 'Microsoft PowerPoint',
        mimeType: 'application/vnd.ms-powerpoint',
    },
    {
        extension: '.pptx',
        kind: 'Microsoft PowerPoint (OpenXML)',
        mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    },
    {
        extension: '.rar',
        kind: 'RAR archive',
        mimeType: 'application/vnd.rar',
    },
    {
        extension: '.rtf',
        kind: 'Rich Text Format (RTF)',
        mimeType: 'application/rtf',
    },
    {
        extension: '.sh',
        kind: 'Bourne shell script',
        mimeType: 'application/x-sh',
    },
    {
        extension: '.svg',
        kind: 'Scalable Vector Graphics (SVG)',
        mimeType: 'image/svg+xml',
    },
    {
        extension: '.swf',
        kind: 'Adobe Flash document',
        mimeType: 'application/x-shockwave-flash',
    },
    {
        extension: '.tar',
        kind: 'Tape Archive (TAR)',
        mimeType: 'application/x-tar',
    },
    {
        extension: '.tif',
        kind: 'Tagged Image File Format (TIFF)',
        mimeType: 'image/tiff',
    },
    {
        extension: '.tiff',
        kind: 'Tagged Image File Format (TIFF)',
        mimeType: 'image/tiff',
    },
    {
        extension: '.ts',
        kind: 'MPEG transport stream',
        mimeType: 'video/mp2t',
    },
    {
        extension: '.ttf',
        kind: 'TrueType Font',
        mimeType: 'font/ttf',
    },
    {
        extension: '.txt',
        kind: 'Text',
        mimeType: 'text/plain',
    },
    {
        extension: '.vsd',
        kind: 'Microsoft Visio',
        mimeType: 'application/vnd.visio',
    },
    {
        extension: '.wav',
        kind: 'Waveform Audio Format',
        mimeType: 'audio/wav',
    },
    {
        extension: '.weba',
        kind: 'WEBM audio',
        mimeType: 'audio/webm',
    },
    {
        extension: '.webm',
        kind: 'WEBM video',
        mimeType: 'video/webm',
    },
    {
        extension: '.webp',
        kind: 'WEBP image',
        mimeType: 'image/webp',
    },
    {
        extension: '.woff',
        kind: 'Web Open Font Format (WOFF)',
        mimeType: 'font/woff',
    },
    {
        extension: '.woff2',
        kind: 'Web Open Font Format (WOFF)',
        mimeType: 'font/woff2',
    },
    {
        extension: '.xhtml',
        kind: 'XHTML',
        mimeType: 'application/xhtml+xml',
    },
    {
        extension: '.xls',
        kind: 'Microsoft Excel',
        mimeType: 'application/vnd.ms-excel',
    },
    {
        extension: '.xlsx',
        kind: 'Microsoft Excel (OpenXML)',
        mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    {
        extension: '.xml',
        kind: 'XML',
        mimeType: 'text/xml',
    },
    {
        extension: '.xml',
        kind: 'XML',
        mimeType: 'application/xml',
    },
    {
        extension: '.xul',
        kind: 'XUL',
        mimeType: 'application/vnd.mozilla.xul+xml',
    },
    {
        extension: '.zip',
        kind: 'ZIP archive',
        mimeType: 'application/zip',
    },
    {
        extension: '.3gp',
        kind: '3GPP video container',
        mimeType: 'video/3gpp',
    },
    {
        extension: '.3gp',
        kind: '3GPP audio container',
        mimeType: 'audio/3gpp',
    },
    {
        extension: '.3g2',
        kind: '3GPP2 audio container',
        mimeType: 'audio/3gpp2',
    },
    {
        extension: '.3g2',
        kind: '3GPP2 video container',
        mimeType: 'video/3gpp2',
    },
    {
        extension: '.7z',
        kind: '7-zip archive',
        mimeType: 'application/x-7z-compressed',
    },
];

const UNITS = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte'];

// Get the kind of file from mime type
export function getKindFromMimeType(mimeType: string): string | undefined {
    const fileType = find(fileTypes, { mimeType });

    return fileType?.kind;
}

export function getExtension(fileName: string, withDot?: boolean): string {
    // Parse the file extension
    const ext = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);

    if (withDot) {
        return `.${ext}`;
    }

    return ext;
}

export function getKindFromFileName(fileName: string): string | undefined {
    return getKindFromExtension(getExtension(fileName));
}

// Get the kind of file from extension
export function getKindFromExtension(extension: string): string | undefined {
    extension = extension.startsWith('.') ? extension : `.${extension}`;

    const fileType = find(fileTypes, { extension });

    return fileType?.kind;
}


export function getKind(file: Omit<FileType, 'kind'>): string | undefined {
    let kind;

    // Try to get the kind from mimeType
    if (file.mimeType) {
        kind = getKindFromMimeType(file.mimeType);
    }

    // Try to get the kind from extension
    if (file.extension && isNil(kind)) {
        kind = getKindFromExtension(file.extension);
    }

    return kind;
}

function getMimeTypeFromExtension(extension: string) {
    const ret = fileTypes.find(fileType => fileType.extension === extension);

    return ret?.mimeType;
}

export function getMimeTypesFromExtensions(extensions: string[]) {
    const mimeTypes = compact(extensions.map(getMimeTypeFromExtension));

    const ret = uniq(mimeTypes);

    return ret;
}

export function downloadFile(fileName: string, content: string, mimeType: string) {
    const blob = new Blob([content], { type: mimeType });

    downloadBlob(fileName, blob);
}

export function downloadBlob(fileName: string, blob: Blob) {
    const a = document.createElement('a');

    const href = URL.createObjectURL(blob);

    a.href = href;
    a.setAttribute('download', fileName);
    a.click();

    URL.revokeObjectURL(href);

    a.remove();
}

export function getFileSize(bytes: number) {
    if (bytes === 0) {
        return { unit: UNITS[0], value: 0 };
    }

    // Compute the byte pow
    const pow = Math.max(Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString()), size(UNITS) - 1), 0);

    // Get the unit
    const unit = UNITS[pow];

    // Get the byte value according to the unit
    const convertedValue = toNumber((bytes / Math.pow(1024, pow)).toFixed(1));
    const ret = { unit, value: convertedValue };

    return ret;
}

export default {
    fileTypes,
    getKindFromMimeType,
    getKindFromExtension,
    getKindFromFileName,
    getExtension,
    getKind,
    downloadFile,
};
