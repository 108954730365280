import { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton, ArgInputSearch, ClassValue, useArgModalContext, useClassNames } from 'src/components/basic';
import { CreateUniverseModal } from '../create-universe-modal/create-universe-modal';
import { useOntologiesContext } from '../../providers/universes';
import { ResetSyncsModal } from '../reset-syncs-modal/reset-syncs-modal';
import { ListOntologies } from 'src/settings/models/dtoApi';

import './table-toolbar.less';

const messages = defineMessages({
    newUniverse: {
        id: 'settings.universes-management-page.toolbar.new',
        defaultMessage: 'New',
    },
    import: {
        id: 'settings.universes-management-page.toolbar.import',
        defaultMessage: 'Import',
    },
    searchPlaceholder: {
        id: 'settings.universes-management-page.search.placeholder',
        defaultMessage: 'Search for a universe',
    },
    resetSyncs: {
        id: 'settings.universe.resetSyncsTooltip',
        defaultMessage: 'Reset all selected universes synchronizations',
    },
});

interface TableToolbarProps {
    className?: ClassValue;
    handleSearch: (value: string) => void;
    universesIdSelected: Set<string>;
    listOntologies: ListOntologies | undefined;
}

export function TableToolbar(props: TableToolbarProps) {
    const { className, handleSearch, universesIdSelected, listOntologies } = props;

    const classNames = useClassNames('settings-table-toolbar');
    const modalContext = useArgModalContext();
    const { updateOntologies } = useOntologiesContext();

    const handleCreateUniverse = useCallback(() => {
        modalContext.open('create-universe',
            <CreateUniverseModal
                onUniverseCreation={updateOntologies}
                onClose={() => modalContext.close('create-universe')}
            />,
        );
    }, [modalContext, updateOntologies]);

    const handleResetSyncsModal = useCallback(() => {
        if (universesIdSelected.size == 0 || !listOntologies) {
            return;
        }

        modalContext.open('reset-syncs',
            <ResetSyncsModal
                onClose={() => modalContext.close('reset-syncs')}
                listOntologies={listOntologies.ontologies.filter(ontology => universesIdSelected.has(ontology.id))}
            />);
    }, [listOntologies, modalContext, universesIdSelected]);

    return (
        <div className={classNames('&', className)}>
            <ArgInputSearch
                className={classNames('&-search')}
                placeholder={messages.searchPlaceholder}
                onInputChange={handleSearch}
            />
            <ArgButton
                icon='icon-reset-feeder'
                className={classNames('&-reset-sync-button')}
                disabled={universesIdSelected.size == 0}
                type='ghost'
                onClick={handleResetSyncsModal}
                tooltip={messages.resetSyncs}
            />
            <ArgButton
                size='medium'
                type='primary'
                icon='icon-plus'
                className={classNames('&-item')}
                label={messages.newUniverse}
                onClick={handleCreateUniverse}
            />
        </div>
    );
}
