import { RtApi } from '../rt-api';
import { EntityId } from '../../../model/argonos-piece';
import { computeEntityUserSettingsStateURL, EntityUserSettingsState } from './entity-user-settings-state';
import { useBasicState } from '../use-basic-state';
import { BasicStates, useBasicStates } from '../use-basic-states';


export function entityUserSettingsStateFactory(url: string, api: RtApi, entityId: EntityId): EntityUserSettingsState {
    const ret = new EntityUserSettingsState(api, url, entityId);

    return ret;
}

export function useEntityUserSettingsState(api: RtApi, entityId: EntityId | undefined): EntityUserSettingsState | undefined {
    const state = useBasicState<EntityUserSettingsState>(
        (url: string) => entityUserSettingsStateFactory(url, api, entityId!),
        (entityId) ? computeEntityUserSettingsStateURL(entityId) : undefined,
    );

    return state;
}


export function useEntityUserSettingsStates(api: RtApi, entityIds: EntityId[] | undefined): BasicStates<EntityUserSettingsState> | undefined {
    const states = useBasicStates(
        (url, entityId) => entityUserSettingsStateFactory(url, api, entityId), entityIds,
        (entityId) => computeEntityUserSettingsStateURL(entityId),
    );

    return states;
}
