import { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Role, RoleId, RolesScope } from 'src/settings/models/dtoApi';
import { ArgButton, ArgMenu, ArgMenuItem, ProgressMonitor, useArgModalContext, useClassNames } from 'src/components/basic';
import { EditRoleModal } from './edit-role-modal';
import { DeleteUserModal } from './delete-role-modal';

import './role-actions-menu.less';

const messages = defineMessages({
    edit: {
        id: 'settings.roles-list.edit',
        defaultMessage: 'Edit',
    },
    editTooltip: {
        id: 'settings.roles-list.editTooltip',
        defaultMessage: 'The role must be published before it can be edited',
    },
    delete: {
        id: 'settings.roles-list.delete',
        defaultMessage: 'Delete',
    },
});

export interface RolesActionsMenuProp {
    role: Role;
    rolesScope: RolesScope;
    handleOnRoleChange: (progressMonitor: ProgressMonitor, roleId?: RoleId) => void;
}

export function RoleActionsMenu(props: RolesActionsMenuProp) {
    const { role, rolesScope, handleOnRoleChange } = props;
    const [visible, setVisible] = useState(false);
    const modalContext = useArgModalContext();
    const classNames = useClassNames('settings-role-actions-menu');

    const actionsMenu = (
        <ArgMenu data-testid='role-actions-button-menu'>
            <ArgMenuItem
                key={messages.edit.id}
                className={classNames('&-rename')}
                data-testid='rename'
                disabled={role?.readOnly || role.hasDraft}
                tooltip={role.hasDraft ? messages.editTooltip : ''}
                onClick={() => {
                    modalContext.open('settings-role-rename-modal',
                        <EditRoleModal
                            closeModal={() => {
                                modalContext.close('settings-role-rename-modal');
                            }}
                            role={role}
                            rolesScope={rolesScope}
                            handleOnRoleEdited={handleOnRoleChange}
                        />,
                    );
                    setVisible(!visible);
                }}
            >
                <span>
                    <FormattedMessage {...messages.edit} />
                </span>
            </ArgMenuItem>
            <ArgMenuItem
                key={messages.delete.id}
                className={classNames('&-delete')}
                data-testid='delete'
                disabled={role?.readOnly}
                onClick={() => {
                    modalContext.open('settings-role-delete-modal',
                        <DeleteUserModal
                            closeModal={() => {
                                modalContext.close('settings-role-delete-modal');
                            }}
                            role={role}
                            rolesScope={rolesScope}
                            handleOnRoleDeleted={handleOnRoleChange}
                        />,
                    );
                    setVisible(!visible);
                }}
                label={messages.delete}
            />
        </ArgMenu>
    );

    return (
        <ArgButton
            type='ghost'
            icon='icon-options'
            className={classNames('&')}
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            data-testid='role-actions-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
        />
    );
}
