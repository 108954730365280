import { isFunction } from 'lodash';
import { defineMessages } from 'react-intl';
import { ReactNode } from 'react';

import {
    DashboardRenderFunction,
    DashboardRenderProperties,
    DashboardWidget,
    DashboardWidgetExtensionDescriptor,
    DashboardWidgetTypeId,
} from './models';
import { UniverseId } from 'src/exploration/model/universe';
import { Filter } from 'src/exploration/model/filter';
import { DataSourceId, ParameterId } from 'src/exploration/features/datasources/datasources';
import { DashboardWidgetId } from 'src/exploration/features/dashboards/dashboards';
import { ArgFormattedMessage, ClassValue, EventEmitter, ProgressMonitor, useClassNames, useMemoAsync } from 'src/components/basic';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { ErrorPane } from 'src/components/common/panes/error-pane';
import { ExtensionsRegistry } from 'src/framework/extensions/extensions-registry';
import { ExtensionComponent, ExtensionDescriptor } from 'src/framework/extensions/models';
import { ArgonosDashboardComponentTypes } from 'src/exploration/extensions/types';

import './dashboard-widget-registry.less';

const messages = defineMessages({
    unknownDashboard: {
        id: 'exploration.extensions.dashboardwidgetregistry.unknownDashboard',
        defaultMessage: 'Unknown dashboard widget id',
    },
    explorationDashboardTypeLabel: {
        id: 'exploration.extensions.dashboard-widget-registry.ExplorationDashboardTypeLabel',
        defaultMessage: 'Dashboard',
    },
});

export const DASHBOARD_WIDGET_EXTENSION_TYPE: ArgonosDashboardComponentTypes = 'exploration-dashboard-widget';


export interface DashboardRepositoryEventNames {
    onNewDashboardWidget(dashboardWidget: DashboardWidget):void;
}

interface DashboardComponent extends ExtensionComponent<ArgonosDashboardComponentTypes> {
    render: (props: any) => ReactNode;
}

export class DashboardWidgetExtensionRepository extends EventEmitter<DashboardRepositoryEventNames> {
    private static instance: DashboardWidgetExtensionRepository;

    #dashboardWidgetsByTypeId: Record<string, DashboardWidget> = {};

    constructor() {
        super();

        ExtensionsRegistry.getInstance().onManifestExtensionPointDeclaration((extensionDescriptor, extensionPointDescriptor) => {
            if (extensionPointDescriptor.type !== DASHBOARD_WIDGET_EXTENSION_TYPE) {
                return;
            }
            this.registerDashboardWidgetDescriptor(extensionDescriptor, extensionPointDescriptor as DashboardWidgetExtensionDescriptor);
        });

        ExtensionsRegistry.getInstance().onExtensionPointSetupRegister<DashboardComponent>([DASHBOARD_WIDGET_EXTENSION_TYPE], (event) => {
            const { component } = event;

            if (component.type !== DASHBOARD_WIDGET_EXTENSION_TYPE) {
                return;
            }

            if (!isFunction(component.render)) {
                throw new Error('Dashboard widget must declare a render() function');
            }

            this.setDashboardWidgetRender(component.id, component.render);

            event.setProcessed();
        });
    }

    static getInstance(): DashboardWidgetExtensionRepository {
        if (!DashboardWidgetExtensionRepository.instance) {
            DashboardWidgetExtensionRepository.instance = new DashboardWidgetExtensionRepository();
        }

        return DashboardWidgetExtensionRepository.instance;
    }

    /**
     * Retrieves an array of DashboardWidget objects.
     *
     * @returns {DashboardWidget[]} - An array of DashboardWidget objects.
     */
    listWidgets(): DashboardWidget[] {
        return Object.values(this.#dashboardWidgetsByTypeId);
    }

    getWidgetDescriptorById(id: DashboardWidgetId):DashboardWidgetExtensionDescriptor|undefined {
        const result = this.#dashboardWidgetsByTypeId[id]?.dashboardWidgetDescriptor;

        return result;
    }

    /**
     * Used by Internals
     *
     * @param extensionDescriptor
     * @param dashboardWidgetDescriptor
     */
    registerDashboardWidgetDescriptor(extensionDescriptor: ExtensionDescriptor, dashboardWidgetDescriptor: DashboardWidgetExtensionDescriptor) {
        const dashboardWidget:DashboardWidget = {
            dashboardWidgetDescriptor,
            extensionDescriptor,
            status: 'registered',
        };

        this.#dashboardWidgetsByTypeId[dashboardWidgetDescriptor.id] = dashboardWidget;

        this.emit('onNewDashboardWidget', dashboardWidget);
    }

    setDashboardWidgetRender(widgetTypeId: DashboardWidgetTypeId, render: DashboardRenderFunction) {
        const dashboardWidget = this.#dashboardWidgetsByTypeId[widgetTypeId];
        if (!dashboardWidget) {
            throw new Error(`Unknown dashboard widget name=${widgetTypeId}`);
        }
        if (dashboardWidget.render) {
            throw new Error('Render function is already setted !');
        }
        dashboardWidget.render = render;
    }

    getDashboardWidgetTypeById(widgetTypeId: DashboardWidgetTypeId): DashboardWidget|undefined {
        const result = this.#dashboardWidgetsByTypeId[widgetTypeId];

        return result;
    }
}

interface ExtensionDashboardWidgetProps extends DashboardRenderProperties {
    className?: ClassValue;
    dashboardWidgetTypeId: DashboardWidgetId;
    enabled?: boolean;
    universesFilters?: Record<UniverseId, Filter>;
    chartParameters?: Record<DataSourceId, {paramterId: ParameterId; value: any}[]>;
}

export function ExtensionDashboardWidget(props: ExtensionDashboardWidgetProps) {
    const {
        dashboardWidgetTypeId,
    } = props;

    const classNames = useClassNames('exploration-dashboard-extension-widget');

    const descriptor = DashboardWidgetExtensionRepository.getInstance().getDashboardWidgetTypeById(dashboardWidgetTypeId);

    const [status, loading, error] = useMemoAsync<DashboardWidget['status']>(async (progressMonitor: ProgressMonitor) => {
        if (!descriptor) {
            return undefined;
        }

        await loadExtension(descriptor, progressMonitor);

        return descriptor.status;
    }, []);

    if (!descriptor) {
        return <div className={classNames('&', 'unknown')}>
            <ArgFormattedMessage message={messages.unknownDashboard} />
        </div>;
    }

    if (!status || loading?.isRunning) {
        return <div className={classNames('&', 'loading')}>
            <LoadingPane progressMonitor={loading} />;
        </div>;
    }
    if (descriptor.status === 'error') {
        return <div className={classNames('&', 'error')}>
            <ErrorPane error={descriptor.error} />
        </div>;
    }

    if (!descriptor.render) {
        return <div className={classNames('&', 'no-render')}>
            <ErrorPane message='This extension did not define the widget render function' />
        </div>;
    }

    return <>
        {descriptor.render(props)}
    </>;
}

async function loadExtension(descriptor: DashboardWidget, progressMonitor: ProgressMonitor) {
    descriptor.status = 'initializing';

    try {
        await ExtensionsRegistry.getInstance().loadExtension(descriptor.extensionDescriptor);
    } catch (error) {
        descriptor.status = 'error';
        descriptor.error = error as Error;

        return;
    }

    descriptor.status = 'ready';
}

export const registerDashoardExtensionComponentTypes = () => {
    ExtensionsRegistry.getInstance().addArgonosExtensionComponentType(DASHBOARD_WIDGET_EXTENSION_TYPE, { label: messages.explorationDashboardTypeLabel });
};
