import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic/keybindings/keybinding';
import { SCOPE as CASE_SCOPE } from './case';

const messages = defineMessages({
    explorationScope: {
        id: 'exploration.keybindings.ExplorationScope',
        defaultMessage: 'Visualization Page',
    },
    openSelectionPane: {
        id: 'exploration.keybindings.OpenSelectionPane',
        defaultMessage: 'Open selection pane',
    },
    openFilterPane: {
        id: 'exploration.keybindings.OpenFilterPane',
        defaultMessage: 'Open filter pane',
    },
    takeScreenshot: {
        id: 'exploration.keybindings.TakeScreenshot',
        defaultMessage: 'Take a screenshot',
    },
    clearSelection: {
        id: 'exploration.keybindings.ClearSelection',
        defaultMessage: 'Clear selection',
    },
    zoomIn: {
        id: 'exploration.keybindings.ZoomIn',
        defaultMessage: 'Zoom In',
    },
    zoomOut: {
        id: 'exploration.keybindings.ZoomOut',
        defaultMessage: 'Zoom Out',
    },
    findShortestPath: {
        id: 'exploration.keybindings.FindShortestPath',
        defaultMessage: 'Find shortest path',
    },
    findInExploration: {
        id: 'exploration.keybindings.FindInExploration',
        defaultMessage: 'Find in visualization',
    },
    openInGraph: {
        id: 'exploration.keybindings.OpenInGraph',
        defaultMessage: 'Open in graph',
    },
    openInForm: {
        id: 'exploration.keybindings.OpenInForm',
        defaultMessage: 'Open in form',
    },
    openInMap: {
        id: 'exploration.keybindings.OpenInMap',
        defaultMessage: 'Open in map',
    },
    openInTable: {
        id: 'exploration.keybindings.OpenInTable',
        defaultMessage: 'Open in table',
    },
    openInChronogram: {
        id: 'exploration.keybindings.OpenInChronogram',
        defaultMessage: 'Open in chronogram',
    },
    openInList: {
        id: 'exploration.keybindings.OpenInList',
        defaultMessage: 'Open in list',
    },
    removeSelectedObjects: {
        id: 'exploration.keybindings.RemoveSelectedObjects',
        defaultMessage: 'Remove selected objects',
    },
    addSelectionToWatchlist: {
        id: 'exploration.keybindings.AddSelectionToWatchlist',
        defaultMessage: 'Add to alert',
    },
    deleteSelectedObjects: {
        id: 'exploration.keybindings.DeleteSelectedObjects',
        defaultMessage: 'Delete selected objects',
    },
    removeOtherObjects: {
        id: 'exploration.keybindings.RemoveOtherObjects',
        defaultMessage: 'Remove other objects',
    },
    openPropertiesPane: {
        id: 'exploration.keybindings.OpenPropertiesPane',
        defaultMessage: 'Open properties pane',
    },
    openBrushPane: {
        id: 'exploration.keybindings.OpenBrushPane',
        defaultMessage: 'Open personalization pane',
    },
    switchToGraphView: {
        id: 'exploration.keybindings.SwitchToGraphView',
        defaultMessage: 'Switch to graph view',
    },
    switchToMapView: {
        id: 'exploration.keybindings.SwitchToMapView',
        defaultMessage: 'Switch to map view',
    },
    switchToTableView: {
        id: 'exploration.keybindings.SwitchToTableView',
        defaultMessage: 'Switch to table view',
    },
    switchToChronogramView: {
        id: 'exploration.keybindings.SwitchToChronogramView',
        defaultMessage: 'Switch to chronogram view',
    },
    switchToListView: {
        id: 'exploration.keybindings.SwitchToListView',
        defaultMessage: 'Switch to list view',
    },
    timeline: {
        id: 'exploration.keybindings.ToggleTimeline',
        defaultMessage: 'Toggle timeline',
    },
    cursor: {
        id: 'exploration.keybindings.Cursor',
        defaultMessage: 'Use cursor tool',
    },
    hand: {
        id: 'exploration.keybindings.Hand',
        defaultMessage: 'Use hand tool',
    },
    center: {
        id: 'exploration.keybindings.Center',
        defaultMessage: 'Center selection',
    },
    polygon: {
        id: 'exploration.keybindings.Polygon',
        defaultMessage: 'Use polygon tool',
    },
    square: {
        id: 'exploration.keybindings.Square',
        defaultMessage: 'Use square tool',
    },
    mapMarker: {
        id: 'exploration.keybindings.MapMarker',
        defaultMessage: 'Use map marker tool',
    },
    circle: {
        id: 'exploration.keybindings.Circle',
        defaultMessage: 'Use circle tool',
    },
    previousSelection: {
        id: 'exploration.keybindings.PreviousSelection',
        defaultMessage: 'Previous selected item',
    },
    nextSelection: {
        id: 'exploration.keybindings.NextSelection',
        defaultMessage: 'Next selected item',
    },
    openExternalMapView: {
        id: 'exploration.keybindings.OpenExternalMapView',
        defaultMessage: 'Open external map view',
    },
    openExternalTableView: {
        id: 'exploration.keybindings.OpenExternalTableView',
        defaultMessage: 'Open external table view',
    },
    openExternalListView: {
        id: 'exploration.keybindings.OpenExternalListView',
        defaultMessage: 'Open external list view',
    },
    openExternalGraphView: {
        id: 'exploration.keybindings.OpenExternalGraphView',
        defaultMessage: 'Open external graph view',
    },
    openExternalChronogramView: {
        id: 'exploration.keybindings.OpenExternalChronogramView',
        defaultMessage: 'Open external chronogram view',
    },
    heatmap: {
        id: 'exploration.keybindings.ToggleHeatMap',
        defaultMessage: 'Toggle heat map',
    },
    switchToOverview: {
        id: 'exploration.keybindings.SwitchToOverview',
        defaultMessage: 'Switch to overview',
    },
    createObject: {
        id: 'exploration.keybindings.CreateObject',
        defaultMessage: 'Create object',
    },
    createRelationship: {
        id: 'exploration.keybindings.CreateRelationship',
        defaultMessage: 'Create relationship',
    },
    groupObjects: {
        id: 'exploration.keybindings.GroupObjects',
        defaultMessage: 'Group',
    },
    ungroupObjects: {
        id: 'exploration.keybindings.UngroupObjects',
        defaultMessage: 'Ungroup',
    },
    editGroup: {
        id: 'exploration.keybindings.EditGroup',
        defaultMessage: 'Edit',
    },
    selectGroupContent: {
        id: 'exploration.keybindings.SelectGroupContent',
        defaultMessage: 'Select content',
    },
    toggleSynchronizeRealtime: {
        id: 'exploration.keybindings.ToggleSynchronizeRealtime',
        defaultMessage: 'Toggle realtime synchronization',
    },
    reverseSelection: {
        id: 'exploration.keybindings.ReverseSelection',
        defaultMessage: 'Invert selection',
    },
    selectAll: {
        id: 'exploration.keybindings.SelectAll',
        defaultMessage: 'Select all',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'exploration.Exploration',
    name: messages.explorationScope,
    extends: CASE_SCOPE,
};

export const KEY_BINDINGS_GRAPH = defineKeyBindings({
    openInGraph: {
        id: 'exploration.exploration.OpenInGraph',
        scope: SCOPE,
        name: messages.openInGraph,
        defaultKeys: 'alt+ctrl+G',
    },
    switchToGraphView: {
        id: 'exploration.exploration.SwitchToGraphView',
        scope: SCOPE,
        name: messages.switchToGraphView,
        defaultKeys: 'alt+G',
    },
    openExternalGraphView: {
        id: 'exploration.exploration.OpenExternalGraphView',
        scope: SCOPE,
        name: messages.openExternalGraphView,
        defaultKeys: 'alt+shift+G',
    },
});

export const KEY_BINDINGS_MAP = defineKeyBindings({
    openInMap: {
        id: 'exploration.exploration.OpenInMap',
        scope: SCOPE,
        name: messages.openInMap,
        defaultKeys: 'alt+ctrl+M',
    },
    switchToMapView: {
        id: 'exploration.exploration.SwitchToMapView',
        scope: SCOPE,
        name: messages.switchToMapView,
        defaultKeys: 'alt+M',
    },
    openExternalMapView: {
        id: 'exploration.exploration.OpenExternalMapView',
        scope: SCOPE,
        name: messages.openExternalMapView,
        defaultKeys: 'alt+shift+M',
    },
    circle: {
        id: 'exploration.exploration.Circle',
        scope: SCOPE,
        name: messages.circle,
    },
    polygon: {
        id: 'exploration.exploration.Polygon',
        scope: SCOPE,
        name: messages.polygon,
    },
    square: {
        id: 'exploration.exploration.Square',
        scope: SCOPE,
        name: messages.square,
    },
    mapMarker: {
        id: 'exploration.exploration.MapMarker',
        scope: SCOPE,
        name: messages.mapMarker,
    },
    cursor: {
        id: 'exploration.exploration.Cursor',
        scope: SCOPE,
        name: messages.cursor,
        defaultKeys: 'C',
    },
    hand: {
        id: 'exploration.exploration.Hand',
        scope: SCOPE,
        name: messages.hand,
        defaultKeys: 'H',
    },
    center: {
        id: 'exploration.exploration.Center',
        scope: SCOPE,
        name: messages.center,
        defaultKeys: 'alt+C',
    },
});

export const KEY_BINDINGS_TABLE = defineKeyBindings({
    openInTable: {
        id: 'exploration.exploration.OpenInTable',
        scope: SCOPE,
        name: messages.openInTable,
        defaultKeys: 'alt+ctrl+T',
    },
    switchToTableView: {
        id: 'exploration.exploration.SwitchToTableView',
        scope: SCOPE,
        name: messages.switchToTableView,
        defaultKeys: 'alt+T',
    },
    openExternalTableView: {
        id: 'exploration.exploration.OpenExternalTableView',
        scope: SCOPE,
        name: messages.openExternalTableView,
        defaultKeys: 'alt+shift+T',
    },
});

export const KEY_BINDINGS_CHRONOGRAM = defineKeyBindings({
    openInChronogram: {
        id: 'exploration.exploration.OpenInChronogram',
        scope: SCOPE,
        name: messages.openInChronogram,
        defaultKeys: 'alt+ctrl+K',
    },
    switchToChronogramView: {
        id: 'exploration.exploration.SwitchToChronogramView',
        scope: SCOPE,
        name: messages.switchToChronogramView,
        defaultKeys: 'alt+K',
    },
    openExternalChronogramView: {
        id: 'exploration.exploration.OpenExternalChronogramView',
        scope: SCOPE,
        name: messages.openExternalChronogramView,
        defaultKeys: 'alt+shift+K',
    },
});

export const KEY_BINDINGS_SCREENSHOT = defineKeyBindings({
    takeScreenshot: {
        id: 'exploration.exploration.Screenshot',
        scope: SCOPE,
        name: messages.takeScreenshot,
        defaultKeys: 'alt+ctrl+S',
    },
});

export const KEY_BINDINGS_BRUSH = defineKeyBindings({
    openBrushTab: {
        id: 'exploration.exploration.OpenBrushPane',
        scope: SCOPE,
        name: messages.openBrushPane,
        defaultKeys: 'alt+B',
    },
});

export const KEY_BINDINGS = defineKeyBindings({

    // Exploration
    openSelectionPane: {
        id: 'exploration.exploration.OpenSelectionPane',
        scope: SCOPE,
        name: messages.openSelectionPane,
        defaultKeys: 'alt+S',
    },
    openFilterPane: {
        id: 'exploration.exploration.OpenFilterPane',
        scope: SCOPE,
        name: messages.openFilterPane,
        defaultKeys: 'alt+F',
    },
    clearSelection: {
        id: 'exploration.exploration.ClearSelection',
        scope: SCOPE,
        name: messages.clearSelection,
        defaultKeys: 'alt+U',
    },
    zoomIn: {
        id: 'exploration.exploration.ZoomIn',
        scope: SCOPE,
        name: messages.zoomIn,
        defaultKeys: '+',
    },
    zoomOut: {
        id: 'exploration.exploration.ZoomOut',
        scope: SCOPE,
        name: messages.zoomOut,
        defaultKeys: '-',
    },
    findShortestPath: {
        id: 'exploration.exploration.FindShortestPath',
        scope: SCOPE,
        name: messages.findShortestPath,
        defaultKeys: 'alt+ctrl+F',
    },
    findInExploration: {
        id: 'exploration.exploration.FindInExploration',
        scope: SCOPE,
        name: messages.findInExploration,
        defaultKeys: 'alt+F',
    },
    openInForm: {
        id: 'exploration.exploration.OpenInForm',
        scope: SCOPE,
        name: messages.openInForm,
        defaultKeys: 'alt+ctrl+O',
    },
    openInList: {
        id: 'exploration.exploration.OpenInList',
        scope: SCOPE,
        name: messages.openInList,
        defaultKeys: 'alt+ctrl+L',
    },
    removeSelectedObjects: {
        id: 'exploration.exploration.RemoveSelectedObjects',
        scope: SCOPE,
        name: messages.removeSelectedObjects,
        defaultKeys: 'Delete',
        locked: true,
    },
    addSelectionToWatchlist: {
        id: 'exploration.exploration.WatchlistSelection',
        scope: SCOPE,
        name: messages.addSelectionToWatchlist,
    },
    deleteSelectedObjects: {
        id: 'exploration.exploration.DeleteSelectedObjects',
        scope: SCOPE,
        name: messages.deleteSelectedObjects,
    },
    removeOtherObjects: {
        id: 'exploration.exploration.RemoveOtherObjects',
        scope: SCOPE,
        name: messages.removeOtherObjects,
        defaultKeys: 'alt+Delete',
        locked: true,
    },
    openPropertiesTab: {
        id: 'exploration.exploration.OpenPropertiesPane',
        scope: SCOPE,
        name: messages.openPropertiesPane,
        defaultKeys: 'alt+P',
    },
    /*    switchToListView: {
            id: 'exploration.exploration.SwitchToListView',
            scope: SCOPE,
            name: messages.switchToListView,
            defaultKeys: 'alt+L',
        },*/
    timeline: {
        id: 'exploration.exploration.ToggleTimeline',
        scope: SCOPE,
        name: messages.timeline,
        defaultKeys: 'ctrl+T',
    },
    previousSelection: {
        id: 'exploration.exploration.PreviousSelection',
        scope: SCOPE,
        name: messages.previousSelection,
        defaultKeys: 'ArrowLeft',
    },
    nextSelection: {
        id: 'exploration.exploration.NextSelection',
        scope: SCOPE,
        name: messages.nextSelection,
        defaultKeys: 'ArrowRight',
    },
    /*    openExternalListView: {
            id: 'exploration.exploration.OpenExternalListView',
            scope: SCOPE,
            name: messages.openExternalListView,
            defaultKeys: 'alt+shift+L',
        },*/
    heatmap: {
        id: 'exploration.exploration.ToggleHeatMap',
        scope: SCOPE,
        name: messages.heatmap,
    },
    switchToOverview: {
        id: 'exploration.exploration.SwitchToOverview',
        scope: SCOPE,
        name: messages.switchToOverview,
        defaultKeys: 'alt+O',
    },
    createObject: {
        id: 'exploration.exploration.CreateObject',
        scope: SCOPE,
        name: messages.createObject,
    },
    createRelationship: {
        id: 'exploration.exploration.CreateRelationship',
        scope: SCOPE,
        name: messages.createRelationship,
    },
    groupObjects: {
        id: 'exploration.exploration.GroupObjects',
        scope: SCOPE,
        name: messages.groupObjects,
    },
    ungroupObjects: {
        id: 'exploration.exploration.UngroupObjects',
        scope: SCOPE,
        name: messages.ungroupObjects,
    },
    editGroup: {
        id: 'exploration.exploration.EditGroup',
        scope: SCOPE,
        name: messages.editGroup,
    },
    selectGroupContent: {
        id: 'exploration.exploration.SelectGroupContent',
        scope: SCOPE,
        name: messages.selectGroupContent,
    },
    synchronizeState: {
        id: 'exploration.exploration.ToggleSynchronizeRealtime',
        scope: SCOPE,
        name: messages.toggleSynchronizeRealtime,
    },
    reverseSelection: {
        id: 'exploration.exploration.ReverseSelection',
        scope: SCOPE,
        name: messages.reverseSelection,
        defaultKeys: 'ctrl+shift+I',
    },
    selectAll: {
        id: 'exploration.exploration.SelectAll',
        scope: SCOPE,
        name: messages.selectAll,
        defaultKeys: 'ctrl+shift+A',
    },
});

