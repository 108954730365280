import { ClassValue, useClassNames } from 'src/components/basic';

import './custom-saturation-pointer.less';

interface CustomSaturationPointerProps {
    className?: ClassValue;
}

export function CustomSaturationPointer({ className }: CustomSaturationPointerProps) {
    const classNames = useClassNames('custom-saturation-pointer');

    return (
        <div className={classNames('&', className)} />
    );
}
