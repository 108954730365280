import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { omit } from 'lodash';

import {
    ArgButton,
    ArgFormLabel,
    ArgInputText,
    ArgModalContainerContext,
    ArgSwitch,
    ArgTable2,
    ArgTable2Column,
    ArgTooltip2,
    ClassValue,
    immutableSet,
    useClassNames,
    useMemoDeepEquals,
} from 'src/components/basic';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { WebHookHeaders, WebhookEvent } from 'src/settings/models/detailed-webhooks';
import { WebhookAddEventsModal } from '../webhook-add-events-modal/webhook-add-events-modal';
import { ArgonosModuleId } from 'src/components/application/modules';

import './webhook-events.less';

const CLASSNAME = 'settings-webhook-events';
const HEADERS_ROW_HEIGHT = 28;

const messages = defineMessages({
    events: {
        id: 'settings.webhook-events.events',
        defaultMessage: 'Associated events',
    },
    addEvents: {
        id: 'settings.webhook-events.addEvents',
        defaultMessage: 'Add events',
    },
    nameColumnTitle: {
        id: 'settings.webhook-events.table.idColumnTitle',
        defaultMessage: 'Name',
    },
    isEnabledColumnTitle: {
        id: 'settings.webhook-events.table.isEnabledColumnTitle',
        defaultMessage: 'Active',
    },
    emptyEventsList: {
        id: 'settings.webhook-events.emptyEventsList',
        defaultMessage: 'No events',
    },
    headers: {
        id: 'settings.webhook-events.headers',
        defaultMessage: 'Headers',
    },
    headerName: {
        id: 'settings.webhook-events.headerName',
        defaultMessage: 'Name',
    },
    headerValue: {
        id: 'settings.webhook-events.headerValue',
        defaultMessage: 'Value',
    },
    addHeader: {
        id: 'settings.webhook-events.addHeader',
        defaultMessage: 'Add',
    },
    emptyHeadersList: {
        id: 'settings.webhook-events.emptyHeadersList',
        defaultMessage: 'No headers',
    },
});

interface HeaderEntry {
    name: string;
    value: string;
}

export interface WebhookEventsProps {
    className?: ClassValue;
    events?: WebhookEvent[];
    onEventsChange: (events: WebhookEvent[]) => void;
    headers?: WebHookHeaders;
    onHeadersChange: (headers: WebHookHeaders) => void;
    argonosModule: ArgonosModuleId;
}

export function WebhookEvents(props: WebhookEventsProps) {
    const { className, events = [], onEventsChange, headers, onHeadersChange, argonosModule } = props;
    const classNames = useClassNames(CLASSNAME);
    const modalContainer = useContext(ArgModalContainerContext);

    const [headerName, onHeaderNameChange] = useState<string>('');
    const [headerValue, onHeaderValueChange] = useState<string>('');

    const headersDataSource = useMemoDeepEquals<HeaderEntry[]>(() => {
        if (!headers) {
            return [];
        }

        const headersData = Object.entries(headers).map(([name, value]) => {
            return {
                name,
                value,
            };
        });

        return headersData;
    }, [headers]);

    const handleEventChange = useCallback((webhookEvent: WebhookEvent) => {
        const idx = events.findIndex((w) => w.id === webhookEvent.id);
        if (idx < 0) {
            return;
        }

        const newEvent = immutableSet(events, [idx, 'isEnabled'], !webhookEvent.isEnabled);
        onEventsChange(newEvent);
    }, [events, onEventsChange]);

    const handleSelectEvents = useCallback((selectedEvents: WebhookEvent[]) => {
        const newEvents = selectedEvents.map((event) => {
            return {
                ...event,
                isEnabled: events?.find((e) => e.id === event.id)?.isEnabled ?? true,
            };
        });
        onEventsChange(newEvents);
    }, [events, onEventsChange]);

    const handleRemoveEvent = useCallback((webhookEvent: WebhookEvent) => {
        const newEvent = events.filter((w) => w.id !== webhookEvent.id);
        onEventsChange(newEvent);
    }, [events, onEventsChange]);

    const handleOpenEventsModal = useCallback(() => {
        modalContainer.open('webhook-add-events-modal', (
            <WebhookAddEventsModal
                initialEvents={events}
                onClose={() => modalContainer.close('webhook-add-events-modal')}
                onSelect={handleSelectEvents}
                argonosModule={argonosModule}
            />
        ));
    }, [events, handleSelectEvents, modalContainer, argonosModule]);

    const isEventEnabledCustomRender = useCallback((isEnabled: boolean, item: WebhookEvent) => {
        return (
            <div className={classNames('&-events-actions')}>
                <ArgSwitch
                    size='medium'
                    checked={isEnabled}
                    onChange={() => handleEventChange(item)}
                />
                <ArgButton
                    size='medium'
                    type='ghost'
                    icon='icon-trash'
                    className={classNames('&-events-actions-remove')}
                    onClick={() => handleRemoveEvent(item)}
                />
            </div>

        );
    }, [handleEventChange, classNames, handleRemoveEvent]);

    const handleAddHeader = useCallback(() => {
        if (!headerName || !headerValue) {
            return;
        }

        onHeadersChange(immutableSet(headers ?? {}, [headerName], headerValue));
        onHeaderNameChange('');
        onHeaderValueChange('');
    }, [headerName, headerValue, onHeadersChange, headers]);

    const handleRemoveHeader = useCallback((name: string) => {
        onHeadersChange(omit(headers ?? {}, name));
    }, [onHeadersChange, headers]);

    const columns = useMemo<ArgTable2Column<WebhookEvent>[]>(() => {
        return [
            {
                key: 'name',
                title: messages.nameColumnTitle,
                ellipsis: true,
                render: (_data: string, event: WebhookEvent) => {
                    return event.id;
                },
                dataIndex: 'name',
            },
            {
                key: 'isEnabled',
                title: messages.isEnabledColumnTitle,
                render: isEventEnabledCustomRender,
                width: 80,
                dataIndex: 'isEnabled',
            },
        ];
    }, [isEventEnabledCustomRender]);

    const headersColumns = useMemo<ArgTable2Column<HeaderEntry>[]>(() => {
        return [
            {
                key: 'name',
                title: '',
                ellipsis: true,
                dataIndex: 'name',
                render: (name: string) => {
                    return (
                        <ArgTooltip2 title={name}>{name}</ArgTooltip2>
                    );
                },
            },
            {
                key: 'value',
                title: '',
                dataIndex: 'value',
                ellipsis: true,
                render: (value: string) => {
                    return (
                        <ArgTooltip2 title={value}>{value}</ArgTooltip2>
                    );
                },
            },
            {
                key: 'delete',
                title: '',
                dataIndex: 'name',
                width: 50,
                render: (name: string) => {
                    return (
                        <ArgButton
                            size='small'
                            type='ghost'
                            icon='icon-trash'
                            className={classNames('&-headers-actions-remove')}
                            onClick={() => handleRemoveHeader(name)}
                        />
                    );
                },
            },
        ];
    }, [classNames, handleRemoveHeader]);

    return (
        <div className={classNames('&', className)} data-testid='webhook-events'>
            <div className={classNames('&-events')}>
                <div className={classNames('&-events-title')}>
                    <span className={classNames('&-events-title-label')}>
                        <FormattedMessage {...messages.events} />
                    </span>
                    <ArgButton
                        size='medium'
                        type='ghost'
                        icon='icon-add-outline'
                        label={messages.addEvents}
                        className={classNames('&-events-title-button')}
                        onClick={handleOpenEventsModal}
                    />
                </div>

                <div className={classNames('&-events-body')}>
                    {events.length > 0 ? (
                        <ArgTable2
                            rowKey='key'
                            columns={columns}
                            dataSource={events}
                            className={classNames('&-events-table', 'scrollable-body')}
                        />
                    ) : (
                        <EmptyPane
                            size='small'
                            message={messages.emptyEventsList}
                            className={classNames('&-events-empty')}
                        />
                    )}
                </div>
            </div>

            <div className={classNames('&-headers')}>
                <div className={classNames('&-headers-title')}>
                    <span className={classNames('&-headers-title-label')}>
                        <FormattedMessage {...messages.headers} />
                    </span>

                    <div className={classNames('&-headers-title-form')}>
                        <ArgFormLabel
                            addedRow={true}
                            propertyName={messages.headerName}
                            className={classNames('&-headers-title-form-item')}
                        >
                            <ArgInputText
                                value={headerName}
                                onInputChange={onHeaderNameChange}
                            />
                        </ArgFormLabel>
                        <ArgFormLabel
                            addedRow={true}
                            propertyName={messages.headerValue}
                            className={classNames('&-headers-title-form-item')}
                        >
                            <ArgInputText
                                value={headerValue}
                                onInputChange={onHeaderValueChange}
                            />
                        </ArgFormLabel>

                        <ArgButton
                            size='medium'
                            type='secondary'
                            label={messages.addHeader}
                            onClick={handleAddHeader}
                            disabled={!headerName.length || !headerValue.length}
                            className={classNames('&-headers-title-form-button')}
                        />

                    </div>
                </div>

                <div className={classNames('&-headers-body')}>
                    {headersDataSource.length > 0 ? (
                        <ArgTable2
                            rowKey='name'
                            columns={headersColumns}
                            dataSource={headersDataSource}
                            rowHeight={HEADERS_ROW_HEIGHT}
                            className='scrollable-body'
                        />
                    ) : (
                        <EmptyPane size='small' message={messages.emptyHeadersList} />
                    )}
                </div>
            </div>
        </div>
    );
}
