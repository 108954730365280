import { Template } from 'src/framework/templates/templates-types';
import { VertexOrEdgeVertexStyle } from './style-template';
import { TableConfiguration } from './table-configuration';

export enum TemplateType {
    Brief = 'Brief',
    Watchlist = 'Watchlist',
    StructuredSearch = 'StructuredSearch',
    TextualSearch = 'TextualSearch',
    TableConfiguration = 'TableConfiguration',
    ExplorationStyle = 'ExplorationStyle',
}

export interface ExplorationStyleTemplateContent {
    vertexStyles?: VertexOrEdgeVertexStyle;
    edgeStyles?: VertexOrEdgeVertexStyle;
}

export type BriefTemplateContent = string;
export type TableTemplateContent = TableConfiguration|undefined;

export type BriefTemplate = Template<BriefTemplateContent>;

export type ExplorationStyleTemplate = Template<ExplorationStyleTemplateContent>;

export type TableTemplate = Template<TableTemplateContent>;
