import React, { ReactNode, UIEvent, useCallback, useRef, useState } from 'react';
import { isFunction } from 'lodash';
import { Menu } from 'antd';
import { defineMessages, FormattedMessage } from 'react-intl';
import { MenuInfo } from 'rc-menu/lib/interface';

import { ArgTableColumn3, DEFAULT_SORTABLE } from './arg-table3';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMenu } from '../arg-menu/arg-menu';
import { DataSorter, PropertySorter } from '../arg-providers/data-provider';
import { ArgButton } from '../arg-button/arg-button';
import { isMessageDescriptor } from '../utils/is-message-descriptor';
import { ArgPopover } from '../arg-popover/arg-popover';

import './virtual-column-header.less';

const POPOVER_OFFSET = 4;

const messages = defineMessages({
    sortAscending: {
        id: 'basic.arg-table3-virtual-columns-container.sort-ascending.Label',
        defaultMessage: 'Sort ascending',
    },
    sortDescending: {
        id: 'basic.arg-table3-virtual-columns-container.sort-descending.Label',
        defaultMessage: 'Sort descending',
    },
    noSort: {
        id: 'basic.arg-table3-virtual-columns-container.no-sort.Label',
        defaultMessage: 'Remove sort',
    },
    hideColumn: {
        id: 'basic.arg-table3-virtual-columns-container.hide-column.Label',
        defaultMessage: 'Hide this column',
    },
    lockColumn: {
        id: 'basic.arg-table3-virtual-columns-container.lock-column.Label',
        defaultMessage: 'Lock this column',
    },
    unlockColumn: {
        id: 'basic.arg-table3-virtual-columns-container.unlock-column.Label',
        defaultMessage: 'Unlock this column',
    },
});

interface VirtualColumnHeaderProps<T> {
    column: ArgTableColumn3<T>;
    locked?: boolean;
    sort?: DataSorter;
    left: number;
    columnWidth: number;
    className?: ClassValue;
    onColumnSort: (column: ArgTableColumn3<T>, event: UIEvent) => void;
    onColumnLock: (column: ArgTableColumn3<T>, locked: boolean) => void;
    onColumnVisible: (column: ArgTableColumn3<T>, lock: boolean) => void;
    canLockColumn?: boolean;
    dragTransform?: string;
    dragged?: boolean;
    selected?: boolean;
    disabled?: boolean;
}

export function VirtualColumnHeader<T>(props: VirtualColumnHeaderProps<T>) {
    const {
        column,
        locked,
        sort,
        left,
        className,
        selected,
        onColumnSort,
        onColumnLock,
        onColumnVisible,
        canLockColumn,
        dragTransform,
        dragged,
        columnWidth,
        disabled,
    } = props;

    const classNames = useClassNames('arg-table3-virtual-column-header');

    const [popupVisible, setPopupVisible] = useState<boolean>(false);

    const handlePopupVisibleChange = useCallback((visible: boolean) => {
        setPopupVisible(visible);
    }, []);

    const propertySorter: PropertySorter | undefined = sort?.propertySorters.find((p) => p.propertyName === (column.columnSortName || column.key));

    const handleColumnSort = useCallback((order: 'ascending' | 'descending' | undefined, e: MenuInfo) => {
        e.domEvent.preventDefault();
        //        e.domEvent.stopPropagation();
        if (propertySorter?.order !== order) {
            onColumnSort(column, e.domEvent);
        }
        setPopupVisible(false);
    }, [column, onColumnSort, propertySorter?.order]);

    const handleSort = useCallback((e: UIEvent) => {
        onColumnSort(column, e);
    }, [column, onColumnSort]);


    const handleColumnLock = useCallback(() => {
        onColumnLock(column, !locked);
        setPopupVisible(false);
    }, [column, onColumnLock, locked]);

    const handleColumnVisible = useCallback(() => {
        onColumnVisible(column, false);
        setPopupVisible(false);
    }, [column, onColumnVisible]);

    const handleHeaderContextMenu = useCallback(
        (column: ArgTableColumn3<T>, locked: boolean): ReactNode => {
            if (!popupVisible) {
                return null;
            }

            return (
                <ArgMenu className={classNames('&-menu')}>
                    {(column.sortable ?? DEFAULT_SORTABLE) && (
                        <Menu.Item
                            key='_ascending-sort_'
                            className={classNames({ selected: propertySorter?.order === 'ascending' })}
                            icon={<ArgIcon name='icon-arrow-up' className={classNames('&-menu-item-icon')} />}
                            onClick={(e) => handleColumnSort('ascending', e)}
                        >
                            <span>
                                <FormattedMessage {...messages.sortAscending} />
                            </span>
                        </Menu.Item>
                    )}
                    {(column.sortable ?? DEFAULT_SORTABLE) && (
                        <Menu.Item
                            key='_descending-sort_'
                            className={classNames({ selected: propertySorter?.order === 'descending' })}
                            icon={<ArgIcon name='icon-arrow-down' className={classNames('&-menu-item-icon')} />}
                            onClick={(e) => handleColumnSort('descending', e)}
                        >
                            <span>
                                <FormattedMessage {...messages.sortDescending} />
                            </span>
                        </Menu.Item>
                    )}
                    {(column.sortable ?? DEFAULT_SORTABLE) && (
                        <Menu.Item
                            key='_remove-sort_'
                            className={classNames({ disabled: !propertySorter?.order })}
                            icon={<ArgIcon name='icon-updown' className={classNames('&-menu-item-icon')} />}
                            onClick={(e) => handleColumnSort(undefined, e)}
                        >
                            <span>
                                <FormattedMessage {...messages.noSort} />
                            </span>
                        </Menu.Item>
                    )}
                    <Menu.Item
                        key='_hide-column_'
                        icon={<ArgIcon name='icon-eye-crossed' className={classNames('&-menu-item-icon')} />}
                        onClick={handleColumnVisible}
                        hidden={true}
                    >
                        <span>
                            <FormattedMessage {...messages.hideColumn} />
                        </span>
                    </Menu.Item>
                    {canLockColumn && (
                        <Menu.Item
                            key='_lock-column_'
                            icon={<ArgIcon name='icon-padlock' className={classNames('&-menu-item-icon')} />}
                            onClick={handleColumnLock}
                        >
                            <span>
                                <FormattedMessage {...(locked ? messages.unlockColumn : messages.lockColumn)} />
                            </span>
                        </Menu.Item>
                    )}
                </ArgMenu>
            );
        },
        [handleColumnSort, handleColumnLock, handleColumnVisible, propertySorter, canLockColumn, classNames, popupVisible]);

    const containerRef = useRef<HTMLDivElement>(null);

    let lockIcon;
    if (locked && !column.rowHeader) {
        lockIcon = <ArgIcon name='icon-padlock' className={classNames('&-lock-icon')} />;
    }

    let sortIconName;
    if ((column.sortable ?? DEFAULT_SORTABLE) && !column.rowHeader) {
        sortIconName = (!disabled) ? 'icon-updown' : undefined;

        switch (propertySorter?.order) {
            case 'ascending':
                sortIconName = 'icon-arrow-up';
                break;
            case 'descending':
                sortIconName = 'icon-arrow-down';
                break;
        }
    }

    let sortIcon;
    const titleDisabled = !(column.sortable ?? DEFAULT_SORTABLE);
    const titleCls = {
        disabled: titleDisabled || disabled,
        selected: selected,
    };

    let titleComponent = column.title || column.columnName;

    // Render with title as function
    if (isFunction(titleComponent)) {
        const columnTitleTestId = column.columnName;

        sortIcon = sortIconName ?
            <ArgButton
                disabled={titleDisabled}
                onClick={handleSort} className={classNames('&-sort-icon')}
                type='ghost'
                icon={sortIconName}
                size='small'
                data-testid={`sort-icon-${columnTitleTestId}`}
            /> : undefined;
        titleComponent =
            <div
                key='title'
                className={classNames('&-title', titleCls)}>
                {titleComponent(column, propertySorter, lockIcon, sortIcon)}
            </div>;
    } else {
        let columnTitleTestId;

        let columnTitle = titleComponent || column.key;
        if (isMessageDescriptor(columnTitle)) {
            columnTitleTestId = columnTitle.id;
            columnTitle = <FormattedMessage {...columnTitle} />;
        }

        // Render by default
        sortIcon = sortIconName ?
            <ArgIcon
                className={classNames('&-sort-icon')}
                name={sortIconName}
                data-testid={`sort-icon-${columnTitleTestId}`}
            /> : undefined;

        titleComponent = <button
            key='title'
            type='button'
            className={classNames('&-title', titleCls)}
            disabled={titleDisabled}
            onClick={sortIcon ? handleSort : undefined}
        >
            <span className={classNames('&-title-text')}>{columnTitle}</span>
            {lockIcon}
            {sortIcon}
        </button>;
    }

    let title;
    if (column.rowHeader) {
        title = <div className={classNames('&-column-header-of-row')} />;
    } else if (column.disableContextMenu) {
        title = <div className={classNames('&-column-header')}>
            {titleComponent}
            {column.resizable && <div
                key='resize'
                data-columnresizer={column.key}
                className={classNames('&-resizer')}
            />}
        </div>;
    } else {
        title = (
            <ArgPopover
                content={() => handleHeaderContextMenu(column, !!locked)}
                placement='bottomLeft'
                offset={POPOVER_OFFSET}
                open={popupVisible}
                onOpenChange={handlePopupVisibleChange}
                trigger='contextMenu'
                triggerClassName={classNames('&-popup-container')}
            >
                {titleComponent}
                {column.resizable && (
                    <div
                        key='resize'
                        data-columnresizer={column.key}
                        className={classNames('&-resizer')}
                    />
                )}
            </ArgPopover>
        );
    }

    const cls = {
        'popup-opened': popupVisible,
        sorted: !!propertySorter,
        dragged,
        'drag-transform': dragTransform,
        movable: column.movable !== false,
        disabled,
    };

    const additionalHeader: ReactNode = isFunction(column.additionalHeader) ? column.additionalHeader(column) : column.additionalHeader;

    const ret = (
        <div
            className={classNames('&', className, cls)}
            data-header={column.key}
            ref={containerRef}
            key={column.key}
            style={{
                left: `${left}px`,
                width: `${columnWidth}px`,
                transform: dragTransform,
            }}>
            {title}
            <div className={classNames('&-column-additional-header')}>
                {additionalHeader}
            </div>
        </div>);

    return ret;
}
