import { ReactNode, useEffect, useRef, useState } from 'react';

import { ArgTooltip2, useClassNames } from 'src/components/basic';
import { ROW_HEIGHT } from '../policy-utils';

import './dropdown-tooltip-field.less';

interface DropdownTooltipFieldProps {
    value: ReactNode;
    className?: string;
}

export function DropdownTooltipField({ value, className }: DropdownTooltipFieldProps) {
    const classNames = useClassNames('settings-dropdown-tooltip-field');
    const ref = useRef<HTMLDivElement>(null);
    const [showTooltip, setShowTooltip] = useState<boolean | null>(null);

    useEffect(() => {
        setShowTooltip(ref.current && ref.current?.offsetWidth < ref.current?.scrollWidth);
    }, []);

    return (
        <ArgTooltip2
            placement='left'
            title={showTooltip ? value : ''}
            open={showTooltip ? undefined : false}
        >
            <div
                className={classNames('&-container', className)}
                style={{ maxHeight: ROW_HEIGHT }}
            >
                <div className={classNames('&-text')} ref={ref}>
                    {value}
                </div>
            </div>
        </ArgTooltip2>
    );
}
