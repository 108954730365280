import { MessageDescriptor } from 'react-intl';

import {
    SystemVertexTypeNames,
    UniverseEdgeType,
    UniversePropertyType,
    UniversePropertyTypes,
    UniverseSchemaType,
    UniverseType,
    UniverseVertexType,
} from '../model/universe';
import { getDataTypesIconClass, getDataTypesMessageDescriptor } from 'src/components/common/data-types';

export function getUniverseVertexTypeByName(predicate: (value: UniverseVertexType) => boolean, universe: UniverseType): UniverseVertexType | undefined {
    const vertex = universe.schema.vertices.find(predicate);

    return vertex;
}

export function getObjectTypeProperty(objectType: UniverseVertexType, propertyName: string): UniversePropertyType | undefined {
    const property = objectType.properties.find((p) => p.name === propertyName);

    return property;
}

export function getUniverseObjectType(universe: UniverseType, objectName: string): UniverseVertexType | undefined {
    const objectType = universe.schema.vertices.find((v) => v.name === objectName);

    return objectType;
}

export function getObjectOrRelationType(elementType: 'Object' | 'Link', elementName?: string, universe?: UniverseType) {
    if (!universe || !elementName) {
        return undefined;
    }

    const objectOrRelation = elementType === 'Object' ? getUniverseObjectType(universe, elementName) : getUniverseRelationType(universe, elementName);

    return objectOrRelation;
}

export function getUniverseRelationType(universe: UniverseType, relationName: string): UniverseEdgeType | undefined {
    const relationType = universe.schema.edges.find((v) => v.name === relationName);

    return relationType;
}

export function doesUniverseContainGeolocatedObjectTypes(universe: UniverseType): boolean {
    const doesContainGeolocatedObjectTypes = universe.schema.vertices.some((objectType: UniverseVertexType) => !!objectType.defaultGeographyProperty);

    return doesContainGeolocatedObjectTypes;
}

export function getUniverseGeolocatedObjectTypes(universe: UniverseType): UniverseVertexType[] {
    const geolocatedObjectTypes = universe.schema.vertices.filter((vertex) => vertex.properties.some((property) => property.type === UniversePropertyTypes.Geoshape));

    return geolocatedObjectTypes;
}

export function getPropertyIconClass(propertyType: UniversePropertyTypes) {
    const iconClass = getDataTypesIconClass(propertyType);

    return iconClass || 'icon-question';
}

export const getUniverseObjectMandatoryProperties = (universe: UniverseType, ojectType: string): string[] => {
    const mandatoryPropertyNames = universe.settings?.vertices?.[ojectType]?.mandatoryPropertyNames || [];

    return mandatoryPropertyNames;
};

export const getUniverseObjectMultivaluedProperties = (universe: UniverseType, ojectType: string): string[] => {
    const multivaluedPropertyNames = universe.settings?.vertices?.[ojectType]?.multivaluedPropertyNames || [];

    return multivaluedPropertyNames;
};

export const getUniverseRelationshipMandatoryProperties = (universe: UniverseType, relationshipType: string): string[] => {
    const mandatoryPropertyNames = universe.settings?.edges?.[relationshipType]?.mandatoryPropertyNames || [];

    return mandatoryPropertyNames;
};

export const getUniverseRelationshipMultivaluedProperties = (universe: UniverseType, relationshipType: string): string[] => {
    const multivaluedPropertyNames = universe.settings?.edges?.[relationshipType]?.multivaluedPropertyNames || [];

    return multivaluedPropertyNames;
};

export const getUniverseObjectReadOnlyProperties = (universe: UniverseType, ojectType: string): string[] => {
    const mandatoryPropertyNames = universe.settings?.vertices?.[ojectType]?.readOnlyPropertyNames || [];

    return mandatoryPropertyNames;
};
export const getUniverseRelationshipReadOnlyProperties = (universe: UniverseType, relationshipType: string): string[] => {
    const mandatoryPropertyNames = universe.settings?.edges?.[relationshipType]?.readOnlyPropertyNames || [];

    return mandatoryPropertyNames;
};

export function getUniversePropertyMessageDescriptor(propertyType: UniversePropertyTypes): MessageDescriptor {
    const descriptor = getDataTypesMessageDescriptor(propertyType);

    return descriptor;
}

export function getClearedUniverseSchema(schema: UniverseSchemaType): UniverseSchemaType {
    return {
        ...schema,
        vertices: schema.vertices.filter((vertex) => !SystemVertexTypeNames[vertex.name]),
        edges: schema.edges.filter((edge) => !SystemVertexTypeNames[edge.name] && !SystemVertexTypeNames[edge.fromLabel] && !SystemVertexTypeNames[edge.toLabel]),
    };
}

export function isPropertyNumber(propertyType: UniversePropertyTypes) {
    switch (propertyType) {
        case UniversePropertyTypes.Byte:
        case UniversePropertyTypes.Short:
        case UniversePropertyTypes.Int:
        case UniversePropertyTypes.Long:
        case UniversePropertyTypes.Double:
        case UniversePropertyTypes.Float:
            return true;
    }

    return false;
}

export function getObjectDateProperties(universe: UniverseType, objectName: string | undefined, asString: true): string[]
export function getObjectDateProperties(universe: UniverseType, objectName: string | undefined, asString?: false): UniversePropertyType[]
export function getObjectDateProperties(universe: UniverseType, objectName?: string, asString = false) {
    if (!objectName) {
        return [];
    }

    const object = universe.schema.vertices.find(vertex => vertex.name === objectName);

    if (!object) {
        return [];
    }

    const objectDateProperties = object.properties.filter(p => ['Date', 'DateTime'].includes(p.type));

    if (!asString) {
        return objectDateProperties;
    }

    return objectDateProperties.map(p => p.name);
}
