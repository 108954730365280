import { defineMessages } from 'react-intl';

import { ArgonosModule } from 'src/components/application/modules';
import { ArgCollapse, ArgMessageRenderer, BasicTag, ClassValue, useClassNames, useMemoAsync } from 'src/components/basic';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { ErrorPane } from 'src/components/common/panes/error-pane';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { ExtensionComponent } from 'src/settings/models/extension';
import ExtensionConnector from 'src/settings/connectors/extensions-connector';
import { ExtensionsRegistry } from 'src/framework/extensions/extensions-registry';

import './extension-components.less';

const messages = defineMessages({
    emptyExtension: {
        id: 'settings.extension-information-panel.extension-components.EmptyExtension',
        defaultMessage: 'No components found!',
    },
    extensionComponents: {
        id: 'settings.extension-information-panel.extension-components.ExtensionComponents',
        defaultMessage: 'Components {isError, select, false {<grey>({count})</grey>} other {}} ',
    },
    loadingExtensionComponents: {
        id: 'settings.extension-information-panel.extension-components.LoadingExtensionComponents',
        defaultMessage: 'Loading extension components',
    },
    extensionComponentsError: {
        id: 'settings.extension-information-panel.extension-components.ExtensionComponentsError',
        defaultMessage: 'Failed to load extension components',
    },
    threeDotsLoadingComponents: {
        id: 'settings.extension-information-panel.extension-components.ThreeDotsLoadingComponents',
        defaultMessage: 'Components <grey>({threeDotsLoading})</grey>',
    },
});

const CLASSNAME = 'settings-extension-components';
const FROCE_LOADING = false;
const FROCE_ERROR = false;

type ExtensionComponentsProps = {
	className?: ClassValue;
	argonosModule?: ArgonosModule;
	extensionName?: string;
	extensionContentHash?: string;
}


export function ExtensionComponents(props: ExtensionComponentsProps) {
    const { argonosModule, extensionName, extensionContentHash, className } = props;
    const classNames = useClassNames(CLASSNAME);

    const [extensionComponents = [], extensionComponentsProgressMonitor, extensionComponentsError] = useMemoAsync<ExtensionComponent[]>(async (progressMonitor) => {
        if (!extensionName) {
            return;
        }

        try {
            const components = await ExtensionConnector.getInstance().getExtensionComponents(extensionName, extensionContentHash, argonosModule, progressMonitor);

            return components;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }

            throw error;
        }
    }, [extensionName, extensionContentHash, argonosModule]);

    const isError = FROCE_ERROR || !!extensionComponentsError;
    const isLoading = !isError && (FROCE_LOADING || !extensionComponents || extensionComponentsProgressMonitor?.isRunning);
    const extensionHasNocomponents = !isLoading && !isError && extensionComponents && extensionComponents.length === 0;
    const extensionHasComponents = !isLoading && !isError && extensionComponents && extensionComponents.length > 0;
    const headerMessage = isLoading ? messages.threeDotsLoadingComponents : messages.extensionComponents;

    return (
        <div className={classNames('&', className)}>
            <ArgCollapse
                title={() => (
                    <ArgMessageRenderer
                        className={classNames('&-header')}
                        message={headerMessage}
                        messageValues={{ count: extensionComponents?.length, isError: isError.toString() }}
                    />
                )}
            >
                {isLoading && (
                    <LoadingPane size='small' message={messages.loadingExtensionComponents} />
                )}

                {isError && (
                    <ErrorPane size='small' message={messages.extensionComponentsError} />
                )}

                {extensionHasNocomponents && (
                    <EmptyPane
                            size='small'
                            message={messages.emptyExtension}
                            backgroundAnimation='wave'
                    />
                )}
                <div className={classNames('&-content')}>
                    {extensionHasComponents && (
                        extensionComponents.map(component => {
                            const componentType = ExtensionsRegistry.getInstance().getArgonosExtensionComponentType(component.type).label;
                            const componentName = component.name;

                            return (
                                <BasicTag
                                    size='medium'
                                    key={component.id}
                                    icon={component.icon ?? 'icon-image'}
                                    label={() => {
                                        return (
                                            <div className={classNames('&-container')}>
                                                <div className={classNames('&-component-name')}>{componentName}</div>
                                                <ArgMessageRenderer
                                                    className={classNames('&-component-type')}
                                                    message={componentType}
                                                />
                                            </div>
                                        );
                                    }}
                                />
                            );
                        })
                    )}
                </div>
            </ArgCollapse>
        </div>
    );
}
