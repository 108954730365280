import { cloneDeep, keyBy, set } from 'lodash';
import { useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgCombo, useClassNames } from 'src/components/basic';
import { OntologyProperty } from 'src/settings/universes/ontology/types';
import { useAccessRuleStateContext, useOntologyContext } from '../../../../common/providers/policy-rules-provider';
import { EffectFilter } from '../../../../../models/policy';
import { DropdownTooltipField } from '../../../../common/dropdown-tooltip-field/dropdown-tooltip-field';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';
import { getTargetsUniqProperties } from 'src/settings/universes/common/policy-utils';

import './rule-effect.less';

const messages = defineMessages({
    select: {
        id: 'settings.rule-effect.item.select',
        defaultMessage: 'Select',
    },
    all: {
        id: 'settings.rule-effect.all',
        defaultMessage: 'all',
    },
});

interface UniverseItemDropdownProps {
    targetType: 'object' | 'property';
    effectValue: EffectFilter;
    index: number;
}

export function UniverseItemDropdown(props: UniverseItemDropdownProps) {
    const { effectValue, targetType, index } = props;

    const intl = useIntl();
    const classNames = useClassNames('settings-rule-effect');
    const { setRule, rule, editable } = useAccessRuleStateContext();
    const { ontologySchema } = useOntologyContext();

    const properties = useMemo(() => (
        getTargetsUniqProperties(ontologySchema, rule.Targets)
    ), [ontologySchema, rule.Targets]);

    const propertiesByName = useMemo(() => {
        return keyBy(properties, property => property.name);
    }, [properties]);

    const dropdownOpts = [...new Set(properties)];
    const dropdownOptsThatMatchTheEffect = dropdownOpts.filter((property) => {
        if (effectValue.match?.length === 0) {
            return true;
        }

        return effectValue.match?.some((match) => `*${property.name}`.match(new RegExp(match.replace('*', '/*'))));
    });
    const dropdownDisplayText =
        dropdownOptsThatMatchTheEffect.length === dropdownOpts.length
            ? intl.formatMessage(messages.all)
            : effectValue.match?.map(matchValue => propertiesByName[matchValue]?.displayName || matchValue).join(', ');

    const [dropDownSelected, setDropdownSelected] = useState(dropdownOptsThatMatchTheEffect);

    return editable ? (
        <ArgCombo<OntologyProperty>
            className={classNames('&-universe-items-filters')}
            hideTags={true}
            placeholder={intl.formatMessage(messages.select)}
            items={dropdownOpts}
            getItemKey={(option) => option.name}
            getItemLabel={(option) => option.displayName}
            value={dropDownSelected}
            cardinality='zeroMany'
            size='small'
            popoverClassName='arg-input-popover-no-max-width'
            renderInput={() => (
                <DropdownTooltipField
                    className={classNames('&-universe-items-filters-input-text')}
                    value={dropdownDisplayText}
                />
            )}
            onChange={setDropdownSelected}
            onPopoverClose={(value) => {
                setRule((currentRule) => {
                    const newMatch = Array.isArray(value) ? (value.length === dropdownOpts.length ? ['*'] : value.map(val => val.name)) : value?.name;

                    return set(cloneDeep(currentRule), `Effects[${index}]`, {
                        [targetType]: { ...effectValue, match: newMatch || [] },
                    });
                });
            }}
            enableFilter={true}
        />
    ) : (
        <ReadOnlyFilterInput
            className={classNames('&-universe-items-filters')}
            value={dropdownDisplayText}
        />
    );
}
