import { cloneDeep, set } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';

import { ArgCombo, useClassNames } from 'src/components/basic';
import { useAccessRuleStateContext } from '../../../../common/providers/policy-rules-provider';
import { RuleEffect } from '../../../../../models/policy';
import { DropdownTooltipField } from '../../../../common/dropdown-tooltip-field/dropdown-tooltip-field';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';

import './rule-effect.less';

const messages = defineMessages({
    object: {
        id: 'settings.rule-effect.object',
        defaultMessage: 'Targets',
    },
    property: {
        id: 'settings.rule-effect.property',
        defaultMessage: 'Target properties',
    },
    select: {
        id: 'settings.rule-effect.select',
        defaultMessage: 'Select',
    },
});

interface TargetOrPropertyDropdownProps {
    targetType: 'object' | 'property' | undefined;
    index: number;
    effect: RuleEffect;
    search?: string;
}

export function TargetOrPropertyDropdown(props: TargetOrPropertyDropdownProps) {
    const { targetType, index, effect, search } = props;

    const intl = useIntl();
    const classNames = useClassNames('settings-rule-effect');
    const { setRule, editable } = useAccessRuleStateContext();

    const value = targetType ? intl.formatMessage(messages[targetType]) : undefined;

    const objPropertyTranslate = (value: string) => {
        if (value === intl.formatMessage(messages.object)) return 'object';

        return 'property';
    };

    return editable ? (
        <ArgCombo
            className={classNames('&-object-property-field')}
            placeholder={intl.formatMessage(messages.select)}
            items={[intl.formatMessage(messages.object), intl.formatMessage(messages.property)]}
            value={value}
            cardinality='one'
            size='small'
            popoverClassName='arg-input-popover-no-max-width'
            onChange={(value) => {
                setRule((currentRule) => {
                    const newTargetType = objPropertyTranslate(value);
                    const currentEffect = targetType ? effect[targetType] : {};

                    if (newTargetType === targetType) return currentRule;

                    return set(cloneDeep(currentRule), `Effects[${index}]`, {
                        [newTargetType]: { ...currentEffect, match: [], Permissions: [] },
                    });
                });
            }}
            renderInput={() => (
                <DropdownTooltipField
                    className={classNames('&-object-property-field')}
                    value={value}
                />
            )}
        />
    ) : (
        <ReadOnlyFilterInput className={classNames('&-object-property-field')} value={value} search={search} />
    );
}
