import React, { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import {
    ArgCombo,
    ArgFormLabel,
    ArgIcon,
    ArgInputText,
    ArgInputTextArea,
    ClassValue,
    useArgNotifications,
    useClassNames,
} from '../../../components/basic';
import { ConfirmModal } from '../../../components/common/modal2/confirm-modal/confirm-modal';
import { ValidationDetail, ValidationSummary } from '../../../components/common/validation-summary';
import { ContextualVariable, ContextualVariableType } from '../../../exploration/model/contextual-variable';
import { VariableValueControl } from './variable-value-control';
import { ExplorationConnector } from '../../../exploration/utils/connector/exploration-connector';

import './create-variable-modal.less';

export interface CreateVariableModalProps {
    className?: ClassValue;
    closeModal: () => void;
    setContextualVariables: React.Dispatch<React.SetStateAction<ContextualVariable[]>>;
}

enum ContextualVariableMode {
    Auto,
    Manual,
}

export enum ContextualVariableAction {
    Create,
    Delete,
    Edit,
}

type ValueTypeDetails = {
    label: MessageDescriptor;
    icon: string;
};

export const messages = defineMessages({
    confirmInProgress: {
        id: 'settings.contextual-variables.create-variable-modal.confirm-in-progress',
        defaultMessage: 'Confirm in progress',
    },
    newVar: {
        id: 'settings.contextual-variables.create-variable-modal.new-var',
        defaultMessage: 'New variable',
    },
    deleteVar: {
        id: 'settings.contextual-variables.create-variable-modal.delete-var',
        defaultMessage: 'Delete variable "{name}"',
    },
    varName: {
        id: 'settings.contextual-variables.create-variable-modal.var-name',
        defaultMessage: 'Variable name',
    },
    varId: {
        id: 'settings.contextual-variables.create-variable-modal.var-id',
        defaultMessage: 'Variable ID',
    },
    varIdPlaceholder: {
        id: 'settings.contextual-variables.create-variable-modal.var-id-placeholder',
        defaultMessage: 'ex: MY_UNIQUE_VARIABLE',
    },
    required: {
        id: 'settings.contextual-variables.create-variable-modal.required',
        defaultMessage: 'Required',
    },
    IdUnique: {
        id: 'settings.contextual-variables.create-variable-modal.id-unique',
        defaultMessage: 'Variable ID must be unique',
    },
    valueType: {
        id: 'settings.contextual-variables.create-variable-modal.value-type',
        defaultMessage: 'Value type',
    },
    valueMode: {
        id: 'settings.contextual-variables.create-variable-modal.value-mode',
        defaultMessage: 'Value mode',
    },
    auto: {
        id: 'settings.contextual-variables.create-variable-modal.auto',
        defaultMessage: 'Auto',
    },
    manual: {
        id: 'settings.contextual-variables.create-variable-modal.manual',
        defaultMessage: 'Manual',
    },
    valueTypeBoolean: {
        id: 'settings.contextual-variables.create-variable-modal.value-type.boolean',
        defaultMessage: 'Boolean',
    },
    valueTypeText: {
        id: 'settings.contextual-variables.create-variable-modal.value-type.text',
        defaultMessage: 'Text',
    },
    valueTypeHour: {
        id: 'settings.contextual-variables.create-variable-modal.value-type.hour',
        defaultMessage: 'Hour',
    },
    valueTypeInt: {
        id: 'settings.contextual-variables.create-variable-modal.value-type.int',
        defaultMessage: 'Integer',
    },
    valueTypeDecimal: {
        id: 'settings.contextual-variables.create-variable-modal.value-type.decimal',
        defaultMessage: 'Decimal',
    },
    valueTypeDate: {
        id: 'settings.contextual-variables.create-variable-modal.value-type.Date',
        defaultMessage: 'Date',
    },
    varValue: {
        id: 'settings.contextual-variables.create-variable-modal.var-value',
        defaultMessage: 'Variable value',
    },
    varDescription: {
        id: 'settings.contextual-variables.create-variable-modal.var-description',
        defaultMessage: 'Description',
    },
    selectAGroup: {
        id: 'settings.contextual-variables.create-variable-modal.select-a-group',
        defaultMessage: 'Select a group',
    },
    deleteVarConfirmMessage: {
        id: 'settings.contextual-variables.create-variable-modal.delete-variable.confirm-message',
        defaultMessage: 'Are you sure you want to delete the "{name}" variable?',
    },
    variableNameErrorMessage: {
        id: 'settings.contextual-variables.create-variable-modal.var-name-error',
        defaultMessage: '{fieldName} : value does not match to the expected pattern',
    },
    variableValueMissingError: {
        id: 'settings.contextual-variables.create-variable-modal.var-value-missing-error',
        defaultMessage: '{fieldName} : you must enter a value',
    },
    saveContextualVariablesError: {
        id: 'settings.contextual-variables.create-variable-modal.SaveContextualVariablesError',
        defaultMessage: 'Failed to save contextual variables',
    },
});

const getNewVariable = () => {
    const ret: ContextualVariable = {
        id: '',
        displayName: '',
        type: ContextualVariableType.bool,
        value: false,
    };

    return ret;
};

const FORBIDDEN_VARIABLE_NAME_PATTERN = '[^a-zA-Z0-9_-]';

const VALUE_TYPES: Record<ContextualVariableType, ValueTypeDetails> = {
    bool: {
        label: messages.valueTypeBoolean,
        icon: 'icon-boolean',
    },
    decimal: {
        label: messages.valueTypeDecimal,
        icon: 'icon-decimal',
    },
    int: {
        label: messages.valueTypeInt,
        icon: 'icon-numeric',
    },
    string: {
        label: messages.valueTypeText,
        icon: 'icon-text',
    },
    hour: {
        label: messages.valueTypeHour,
        icon: 'icon-time',
    },
};

export function CreateVariableModal({
    className,
    closeModal,
    setContextualVariables,
}: CreateVariableModalProps) {
    const classNames = useClassNames('create-variable-modal');
    const intl = useIntl();
    const notifications = useArgNotifications();

    const variableRef = useRef<ContextualVariable>(getNewVariable());
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(
        !variableRef.current.displayName,
    );
    const [groupAffected, setGroupAffected] = useState<string>(variableRef.current.path || '');
    const [validationDetails, setValidationDetails] = useState<ValidationDetail[]>([]);
    const [renderId, setRenderId] = useState(0);

    const values = Object.keys(VALUE_TYPES) as ContextualVariableType[];
    const currentValueTypeDetails = VALUE_TYPES[variableRef.current.type];

    useEffect(() => {
        // Update mode value : auto is not yet implemented
        variableRef.current.auto = undefined;

        // Update path
        variableRef.current.path = groupAffected;
    }, [groupAffected]);

    const validateForm = useCallback(() => {
        const errorDetails = new Array<ValidationDetail>();
        const { id: variableName, value: variableValue } = variableRef.current;

        if (variableName) {
            if (variableName.match(new RegExp(FORBIDDEN_VARIABLE_NAME_PATTERN))) {
                errorDetails.push({
                    fieldName: intl.formatMessage(messages.varName),
                    message: messages.variableNameErrorMessage,
                });
            }
        }
        if (
            variableValue === undefined ||
            (variableRef.current.type === ContextualVariableType.string &&
                !(variableValue as string))
        ) {
            errorDetails.push({
                fieldName: intl.formatMessage(messages.varValue),
                message: messages.variableValueMissingError,
            });
        }

        setValidationDetails(errorDetails);

        return errorDetails.length < 1;
    }, [intl]);

    const addVariable = async (variable: ContextualVariable): Promise<boolean> => {
        const isValid = validateForm();
        if (isValid) {
            try {
                await ExplorationConnector.getInstance().saveContextualVariable(variable, false);
                const variables = await ExplorationConnector.getInstance().getContextualVariables();
                setContextualVariables(variables);
                variableRef.current = getNewVariable();

                return true;
            } catch (error) {
                notifications.snackError({ message: messages.saveContextualVariablesError }, error as Error);
            }
        }

        return false;
    };

    return (
        <div className={classNames('&', className)}>
            <ConfirmModal
                size='medium'
                title={messages.newVar}
                type='create'
                confirmDisabled={isConfirmButtonDisabled}
                onConfirm={async () => {
                    const isValid = validateForm();
                    if (!isValid) {
                        return false;
                    }

                    const ret = addVariable(variableRef.current);

                    return ret;
                }}
                onClose={closeModal}
            >
                {validationDetails.length > 0 && (
                    <div className={classNames('&-validation-summary')}>
                        <ValidationSummary type='error' details={validationDetails} />
                    </div>
                )}
                <div className={classNames('&-form')}>
                    <ArgFormLabel
                        className={classNames('&-form-row')}
                        propertyName={messages.varName}
                        required={messages.required}
                    >
                        <ArgInputText
                            className={classNames('&-form-variable-name')}
                            size='medium'
                            maxLength={128}
                            value={variableRef.current.displayName}
                            autoFocus={true}
                            onInputChange={(value) => {
                                const trimmedValue = value?.trim();
                                setIsConfirmButtonDisabled(!trimmedValue);
                                variableRef.current.displayName = trimmedValue;
                            }}
                        />
                    </ArgFormLabel>
                    <div className={classNames('&-form-row', 'two-columns')}>
                        <ArgFormLabel
                            propertyName={messages.varId}
                            description={messages.IdUnique}
                        >
                            <ArgInputText
                                className={classNames('&-form-variable-id')}
                                size='medium'
                                maxLength={64}
                                value={variableRef.current.id}
                                onInputChange={(value) => {
                                    variableRef.current.id = value;
                                }}
                                placeholder={messages.varIdPlaceholder}
                            />
                        </ArgFormLabel>
                        <div />
                    </div>
                    <div className={classNames('&-form-row', 'two-columns')}>
                        <ArgFormLabel propertyName={messages.valueType}>
                            <ArgCombo<ContextualVariableType>
                                size='medium'
                                className={classNames('&-combo-value-type')}
                                items={values}
                                getItemKey={(type) => type}
                                getItemLabel={(type) => {
                                    return type ? VALUE_TYPES[type]?.label : undefined;
                                }}
                                onChange={(type: ContextualVariableType) => {
                                    variableRef.current.type = type;
                                    variableRef.current.value = undefined;
                                    setRenderId((x) => x + 1);
                                }}
                                left={currentValueTypeDetails?.icon}
                                renderItem={(type) => {
                                    const value = VALUE_TYPES[type];

                                    return (
                                        <div
                                            className={classNames('&-combo-value-type-item')}
                                            key={type}
                                        >
                                            <ArgIcon name={value.icon} />
                                            <span>
                                                <FormattedMessage {...value.label} />
                                            </span>
                                        </div>
                                    );
                                }}
                                value={variableRef.current.type}
                            />
                        </ArgFormLabel>
                    </div>
                    <ArgFormLabel
                        className={classNames('&-form-row')}
                        propertyName={messages.varValue}
                    >
                        <VariableValueControl
                            variableRef={variableRef}
                            refreshRef={() => setRenderId((x) => x + 1)}
                        />
                    </ArgFormLabel>
                    <ArgFormLabel
                        className={classNames('&-form-row')}
                        propertyName={messages.varDescription}
                    >
                        <ArgInputTextArea
                            className={classNames('&-form-variable-description')}
                            size='large'
                            maxLength={512}
                            rows={4}
                            value={variableRef.current.description}
                            onInputChange={(value) => {
                                variableRef.current.description = value;
                            }}
                        />
                    </ArgFormLabel>
                </div>
            </ConfirmModal>
        </div>
    );
}
