import { ReferenceTable, ReferenceTableEntry } from './reference-tables';
import { mapDate } from '../../../../utils/connectors/mappers';

export function mapReferenceTableEntry(raw: any): ReferenceTableEntry {
    const result:ReferenceTableEntry = {
        ...raw,
        createdAt: mapDate(raw.createdAt),
    };

    return result;
}

export function mapReferenceTable(raw: any): ReferenceTable {
    const result: ReferenceTable = {
        ...raw,
    };

    return result;
}
