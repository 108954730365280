import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic';
import { SCOPE as FOLDER_SCOPE } from './folder';

const messages = defineMessages({
    processScope: {
        id: 'preparation.keybindings.ProcessScope',
        defaultMessage: 'Process Page',
    },
    toggleComponentsLibrary: {
        id: 'preparation.keybindings.ToggleComponentsLibrary',
        defaultMessage: 'Toggle components panel',
    },
    toggleComponentsSearch: {
        id: 'preparation.keybindings.ToggleComponentsSearch',
        defaultMessage: 'Toggle components search panel',
    },
    runDebug: {
        id: 'preparation.keybindings.RunDebug',
        defaultMessage: 'Run on sample data',
    },
    start: {
        id: 'preparation.keybindings.Start',
        defaultMessage: 'Start the process',
    },
    suspend: {
        id: 'preparation.keybindings.Suspend',
        defaultMessage: 'Suspend process',
    },
    stop: {
        id: 'preparation.keybindings.Stop',
        defaultMessage: 'Stop process',
    },
    productionSettings: {
        id: 'preparation.keybindings.ProductionSettings',
        defaultMessage: 'Production settings',
    },
    toggleSupervision: {
        id: 'preparation.keybindings.ToggleSupervision',
        defaultMessage: 'Toggle supervision panel',
    },
    toggleProperties: {
        id: 'preparation.keybindings.ToggleProperties',
        defaultMessage: 'Toggle properties panel',
    },
    toggleLinks: {
        id: 'preparation.keybindings.ToggleLinks',
        defaultMessage: 'Toggle priority manager panel',
    },
    toggleHistory: {
        id: 'preparation.keybindings.ToggleHistory',
        defaultMessage: 'Toggle history panel',
    },
    toggleDesignIssues: {
        id: 'preparation.keybindings.ToggleDesignIssues',
        defaultMessage: 'Toggle design issues',
    },
    toggleExecutionLogs: {
        id: 'preparation.keybindings.ToggleExecutionLogs',
        defaultMessage: 'Toggle execution logs',
    },
    toggleExecutionTraces: {
        id: 'preparation.keybindings.ToggleExecutionTraces',
        defaultMessage: 'Toggle execution traces',
    },
    switchToOverview: {
        id: 'preparation.keybindings.SwitchToOverview',
        defaultMessage: 'Switch to overview',
    },
    configurePins: {
        id: 'preparation.keybindings.ConfigurePins',
        defaultMessage: 'Configure pins',
    },
    configureWrangler: {
        id: 'preparation.keybindings.ConfigureWrangler',
        defaultMessage: 'Configure wrangler',
    },
    emptyReferenceTable: {
        id: 'preparation.keybindings.EmptyTableReference',
        defaultMessage: 'Empty reference table',
    },
    openVisualizationData: {
        id: 'preparation.keybindings.OpenVisualizationData',
        defaultMessage: 'Visualize component data',
    },
    createComponent: {
        id: 'preparation.keybindings.CreateComponent',
        defaultMessage: 'Create component',
    },
    bookmarkComponent: {
        id: 'preparation.keybindings.BookmarkComponent',
        defaultMessage: 'Manage component bookmark',
    },
    duplicateComponent: {
        id: 'preparation.keybindings.DuplicateComponent',
        defaultMessage: 'Duplicate component',
    },
    centerView: {
        id: 'preparation.keybindings.CenterView',
        defaultMessage: 'Center view',
    },
    autoLayout: {
        id: 'preparation.keybindings.AutoLayout',
        defaultMessage: 'Auto layout',
    },
    previousComponent: {
        id: 'preparation.keybindings.PreviousComponent',
        defaultMessage: 'Previous component',
    },
    nextComponent: {
        id: 'preparation.keybindings.NextComponent',
        defaultMessage: 'Next component',
    },
    searchComponentList: {
        id: 'preparation.keybindings.SearchComponentList',
        defaultMessage: 'Search a component',
    },
    clearSelection: {
        id: 'preparation.keybindings.ClearSelection',
        defaultMessage: 'Clear selection',
    },
    backToHome: {
        id: 'preparation.keybindings.BackToHomeFromProcess',
        defaultMessage: 'Back to home page',
    },
    searchComponentPin: {
        id: 'preparation.keybindings.SearchComponentPin',
        defaultMessage: 'Search a component pin',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'preparation.Process',
    name: messages.processScope,
    extends: FOLDER_SCOPE,
};

export const KEY_BINDINGS = defineKeyBindings({
    toggleComponentsList: {
        id: 'preparation.process.ToggleComponentsList',
        scope: SCOPE,
        name: messages.toggleComponentsLibrary,
        defaultKeys: 'alt+shift+C',
    },
    toggleComponentsSearch: {
        id: 'preparation.process.ToggleComponentsSearch',
        scope: SCOPE,
        name: messages.toggleComponentsSearch,
        defaultKeys: 'alt+shift+F',
    },
    runDebug: {
        id: 'preparation.process.RunDebug',
        scope: SCOPE,
        name: messages.runDebug,
        defaultKeys: 'alt+R',
    },
    start: {
        id: 'preparation.process.Start',
        scope: SCOPE,
        name: messages.start,
        defaultKeys: 'alt+Enter',
    },
    suspend: {
        id: 'preparation.process.suspend',
        scope: SCOPE,
        name: messages.suspend,
        defaultKeys: 'alt+PageDown',
    },
    stop: {
        id: 'preparation.process.stop',
        scope: SCOPE,
        name: messages.stop,
        defaultKeys: 'alt+shift+Enter',
    },
    productionSettings: {
        id: 'preparation.process.ProductionSettings',
        scope: SCOPE,
        name: messages.productionSettings,
        defaultKeys: 'alt+shift+X',
    },
    toggleSupervision: {
        id: 'preparation.process.ToggleSupervision',
        scope: SCOPE,
        name: messages.toggleSupervision,
        defaultKeys: 'alt+shift+S',
    },
    toggleProperties: {
        id: 'preparation.process.ToggleProperties',
        scope: SCOPE,
        name: messages.toggleProperties,
        defaultKeys: 'alt+shift+P',
    },
    toggleLinks: {
        id: 'preparation.process.ToggleLinks',
        scope: SCOPE,
        name: messages.toggleLinks,
        defaultKeys: 'alt+shift+L',
    },
    toggleHistory: {
        id: 'preparation.process.ToggleHistory',
        scope: SCOPE,
        name: messages.toggleHistory,
        defaultKeys: 'alt+shift+H',
    },
    toggleDesignIssues: {
        id: 'preparation.process.ToggleDesignIssues',
        scope: SCOPE,
        name: messages.toggleDesignIssues,
        defaultKeys: 'alt+shift+I',
    },
    toggleExecutionLogs: {
        id: 'preparation.process.ToggleExecutionLogs',
        scope: SCOPE,
        name: messages.toggleExecutionLogs,
        defaultKeys: 'alt+shift+E',
    },
    toggleExecutionTraces: {
        id: 'preparation.process.ToggleExecutionTraces',
        scope: SCOPE,
        name: messages.toggleExecutionTraces,
        defaultKeys: 'alt+shift+T',
    },
    switchToOverview: {
        id: 'preparation.process.SwitchToOverview',
        scope: SCOPE,
        name: messages.switchToOverview,
        defaultKeys: 'alt+O',
    },
    configurePins: {
        id: 'preparation.process.ConfigurePins',
        scope: SCOPE,
        name: messages.configurePins,
        defaultKeys: 'alt+P',
    },
    configureWrangler: {
        id: 'preparation.process.ConfigureWrangler',
        scope: SCOPE,
        name: messages.configureWrangler,
        defaultKeys: 'alt+W',
    },
    emptyReferenceTable: {
        id: 'preparation.process.EmptyReferenceTable',
        scope: SCOPE,
        name: messages.emptyReferenceTable,
        defaultKeys: 'alt+T',
    },
    openVisualizationData: {
        id: 'preparation.process.OpenVisualizationData',
        scope: SCOPE,
        name: messages.openVisualizationData,
        defaultKeys: 'alt+V',
    },
    createComponent: {
        id: 'preparation.process.CreateComponent',
        scope: SCOPE,
        name: messages.createComponent,
        defaultKeys: 'alt+N',
    },
    bookmarkComponent: {
        id: 'preparation.process.Bookmark',
        scope: SCOPE,
        name: messages.bookmarkComponent,
        defaultKeys: 'alt+B',
    },
    duplicateComponent: {
        id: 'preparation.process.DuplicateComponent',
        scope: SCOPE,
        name: messages.duplicateComponent,
        defaultKeys: 'ctrl+D',
    },
    centerView: {
        id: 'preparation.process.CenterView',
        scope: SCOPE,
        name: messages.centerView,
        defaultKeys: 'alt+C',
    },
    autoLayout: {
        id: 'preparation.process.AutoLayout',
        scope: SCOPE,
        name: messages.autoLayout,
        defaultKeys: 'alt+L',
    },
    previousComponent: {
        id: 'preparation.process.PreviousComponent',
        scope: SCOPE,
        name: messages.previousComponent,
        defaultKeys: 'ArrowLeft',
    },
    nextComponent: {
        id: 'preparation.process.NextComponent',
        scope: SCOPE,
        name: messages.nextComponent,
        defaultKeys: 'ArrowRight',
    },
    searchComponentLibrary: {
        id: 'preparation.process.SearchComponentLibrary',
        scope: SCOPE,
        name: messages.searchComponentList,
        defaultKeys: 'alt+shift+R',
    },
    clearSelection: {
        id: 'preparation.process.ClearSelection',
        scope: SCOPE,
        name: messages.clearSelection,
        defaultKeys: 'Escape',
    },
    backToHome: {
        id: 'preparation.process.BackToHome',
        scope: SCOPE,
        name: messages.backToHome,
        defaultKeys: 'alt+ArrowLeft',
    },
    searchComponentPin: {
        id: 'preparation.process.SearchComponentPin',
        scope: SCOPE,
        name: messages.searchComponentPin,
        defaultKeys: 'ctrl+F',
    },
});

