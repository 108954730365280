import { KeyboardEvent, useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { FormikHelpers, useFormik } from 'formik';

import {
    ArgDragAndDropUploader,
    ArgFormLabel,
    ArgIcon,
    ArgInputText,
    ArgModal,
    ArgUploaderButton,
    ProgressMonitor,
    SubProgressMonitor,
    useCallbackAsync,
    useClassNames,
    useArgNotifications,
} from 'src/components/basic';
import { importOntology } from 'src/settings/universes/utils/import-export';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

import './create-universe-modal.less';

const SUPPORTED_IMPORT_MIME_TYPES = [
    'application/x-zip-compressed', 'application/zip',
];

const MAX_UNIVERSE_NAME_LENGTH = 128;

export const messages = defineMessages({
    modalTitle: {
        id: 'settings.create-universe-modal.title',
        defaultMessage: 'New universe',
    },
    nameField: {
        id: 'settings.create-universe-modal.name',
        defaultMessage: 'Name',
    },
    required: { id: 'settings.create-universe-modal.required', defaultMessage: 'Required' },
    descriptionField: {
        id: 'settings.create-universe-modal.description',
        defaultMessage: 'Description',
    },
    submit: {
        id: 'settings.create-universe-modal.submitButton',
        defaultMessage: 'Create',
    },
    cancel: {
        id: 'settings.create-universe-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    addUniversesErrorMsg: {
        id: 'settings.error-message.adding-universe',
        defaultMessage: 'Something went wrong while adding the universe',
    },
    importMessage: {
        id: 'settings.error-message.import-message',
        defaultMessage: 'You can start your creation from an empty space or import a file.',
    },
    dragTitle: {
        id: 'settings.error-message.drag-title',
        defaultMessage: 'Drag a file here or ',
    },
    browseTitle: {
        id: 'settings.error-message.browse-title',
        defaultMessage: 'browse',
    },
    importError: {
        id: 'settings.error-message.import-error',
        defaultMessage: 'The imported file isn\'t compatible',
    },
});

interface FormFields {
    name?: string;
    description?: string;
}

type FormFieldsErrors = Partial<Record<keyof FormFields, boolean>>;

export interface CreateUniverseModalProps {
    onUniverseCreation: (progressMonitor: ProgressMonitor) => Promise<void>;
    onClose: () => void;
}

export function CreateUniverseModal(props: CreateUniverseModalProps) {
    const {
        onUniverseCreation,
        onClose,
    } = props;

    const classNames = useClassNames('settings-create-universe-modal');

    const notifications = useArgNotifications();

    const [ontologyModel, setOntologyModel] = useState<{
        name: string;
        blob: Blob;
    }>();

    const [submitForm, submitting] = useCallbackAsync(async (progressMonitor: ProgressMonitor, values: FormFields, { resetForm }: FormikHelpers<FormFields>) => {
        if (!values.name && !ontologyModel) {
            return;
        }

        try {
            if (ontologyModel) {
                const sub1 = new SubProgressMonitor(progressMonitor, 1);
                await importOntology(ontologyModel.blob, sub1);
            } else if (values.name) {
                const sub1 = new SubProgressMonitor(progressMonitor, 1);

                const sub10 = new SubProgressMonitor(sub1, 1);
                const ontologyIdPromise = ontologiesConnector.addOntology(values.name, values.description, sub10);

                const sub11 = new SubProgressMonitor(sub1, 1);
                const universeIdPromise = ontologiesConnector.createUniverse(values.name, values.description, sub11);

                const [ontologyId, universeId] = await Promise.all([ontologyIdPromise, universeIdPromise]);

                const sub12 = new SubProgressMonitor(sub1, 1);
                await ontologiesConnector.executeOntology(ontologyId, 'MapUniverse', {
                    universeIds: [universeId],
                }, undefined, undefined, sub12);
            }

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await onUniverseCreation(sub2);

            setOntologyModel(undefined);
            onClose();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.addUniversesErrorMsg }, error as Error);
            throw error;
        }
    }, [onClose, ontologyModel, onUniverseCreation, notifications]);

    const validateFrom = useCallback((values: FormFields) => {
        const errors: FormFieldsErrors = {};
        if (!values.name && !ontologyModel) {
            errors.name = true;
        }

        return errors;
    }, [ontologyModel]);

    const {
        handleSubmit,
        handleChange,
        values: formValues,
        errors: formErrors,
    } = useFormik<FormFields>({
        initialValues: {},
        validateOnChange: false,
        validate: validateFrom,
        onSubmit: submitForm,
    });

    const onCloseModal = useCallback(() => {
        onClose();
    }, [onClose]);

    const onImportOntologyClick = useCallback(async (blob: Blob) => {
        if (!blob || !SUPPORTED_IMPORT_MIME_TYPES.includes(blob.type)) {
            notifications.snackError({ message: messages.importError });

            return;
        }
        try {
            const name = (blob as File).name;
            setOntologyModel({ blob, name });
        } catch (e) {
            notifications.snackError({ message: messages.importError }, e as Error);
        }
    }, [notifications]);

    const deleteUploadedFile = useCallback(() => {
        setOntologyModel(undefined);
    }, []);

    const handleKeyDown = useCallback((event: KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }, [handleSubmit]);

    return (
        <ArgModal
            size='large'
            title={messages.modalTitle}
            onCancel={onCloseModal}
            onClose={onCloseModal}
            onOk={() => handleSubmit()}
            loading={submitting?.isRunning}
            okDataTestId='create'
        >
            <form
                onSubmit={handleSubmit}
                onKeyDown={handleKeyDown}
                className={classNames('&')}>
                <ArgFormLabel
                    propertyName={messages.nameField}
                    required={messages.required}
                >
                    <ArgInputText
                        value={!ontologyModel ? formValues.name : undefined}
                        onInputChange={handleChange('name')}
                        data-testid='name'
                        state={formErrors.name ? 'invalid' : undefined}
                        autoFocus={true}
                        disabled={!!ontologyModel}
                        maxLength={MAX_UNIVERSE_NAME_LENGTH}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    propertyName={messages.descriptionField}
                    addedRow={true}
                >
                    <ArgInputText
                        value={!ontologyModel ? formValues.description : undefined}
                        onInputChange={handleChange('description')}
                        data-testid='description'
                        disabled={!!ontologyModel}
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    size='large'
                    propertyName={messages.importMessage}
                    className={classNames('&-upload-message')}
                >
                    {ontologyModel ? (
                        <div className={classNames('&-uploaded')}>
                            <ArgIcon name='icon-file' className={classNames('&-uploaded-file-icon')} />
                            <span className={classNames('&-uploaded-title')}>{ontologyModel.name}</span>
                            <div
                                className={classNames('&-uploaded-trash-icon')}
                                onClick={deleteUploadedFile}
                            >
                                <ArgIcon name='icon-trash' />
                            </div>
                        </div>
                    ) : (
                        <ArgDragAndDropUploader
                            method={onImportOntologyClick}
                            className={classNames('&-upload')}
                        >
                            <div>
                                <span><FormattedMessage {...messages.dragTitle} /></span>
                                <ArgUploaderButton
                                    type='link'
                                    key='upload'
                                    size='medium'
                                    method={onImportOntologyClick}
                                    label={messages.browseTitle}
                                    className={classNames('&-upload-button')}
                                />
                            </div>
                        </ArgDragAndDropUploader>
                    )}
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
}
