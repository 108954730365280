import Debug from 'debug';

import { ArgUserLocale } from 'src/contexts/user-locale-context';
import { ArgLocale } from './langs/arg-locale';
import { fr_FR } from './langs/fr-FR';
import { en_150 } from './langs/en-150';
import { en_GB } from './langs/en-GB';
import { en_US } from './langs/en-US';

const debug = Debug('common:i18n:GetLocale');

// Convert lang to the locales supported by argonos
export function toArgUserLocale(lang: string): ArgUserLocale {
    switch (lang) {
        case 'fr':
        case 'fr-FR':
            return 'fr';

        case 'en':
        case 'en-GB':
        case 'en-150':
        case 'en-US':
        default:
            return 'en';
    }
}

export function getArgLocale(lang: string, strict?: boolean): ArgLocale | undefined {
    switch (lang) {
        case 'fr':
            return fr_FR;

        case 'en':
            return en_150;

        case 'en-GB':
            return en_GB;

        case 'en-150':
            return en_150;

        case 'en-US':
            return en_US;
    }

    if (strict === true) {
        return;
    }

    const reg = /^(\w+)\W(\w+)/.exec(lang);
    if (reg) {
        return getArgLocale(reg[1]);
    }
}

export function normalizeLocale(lang: string): string | undefined {
    const reg = /^(\w+)(\W(\w+))?/.exec(lang);
    if (!reg) {
        return undefined;
    }

    if (!reg[3]) {
        return reg[1].toLowerCase();
    }

    const l = `${reg[1].toLowerCase()}-${reg[3].toUpperCase()}`;

    return l;
}
