import React, { ReactNode } from 'react';
import { isFunction } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { Tool } from '../arg-tools/tool';
import { ArgRenderedText } from '../types';
import { ArgPopoverRenderer } from '../arg-popover/types';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';
import { ArgTooltip2 } from '../arg-tooltip/arg-tooltip2';

import './arg-toolbar-label.less';

export interface ArgToolBarLabelRenderContext {
    onCloseMenu?(): void;

    menuItemClassName?: ClassValue;
}

export type ArgToolBarLabelRenderWithContextFunction<T = undefined> = (props: Tool<T>, context: ArgToolBarLabelRenderContext, popoverRender?: ArgPopoverRenderer) => ReactNode;

export interface ArgToolBarLabelProps<T = undefined> {
    label: ArgRenderedText;
    testid?: string;
    tooltip?: boolean | ArgRenderedText;
    customRender?: ArgToolBarLabelRenderWithContextFunction<T>;
    visible?: boolean;
    className?: ClassValue;
    environmentContext: T;
}

export function ArgToolBarLabel<T>(props: ArgToolBarLabelProps<T>) {
    const {
        testid,
        customRender,
        tooltip,
        label,
        className,
        environmentContext,
        visible,
    } = props;

    const classNames = useClassNames('arg-toolbar-label');

    const toolProps = props as any as Tool<T>;

    if (visible === false) {
        return null;
    }

    if (isFunction(customRender)) {
        return <>
            {customRender(toolProps, {})}
        </>;
    }

    let body = (
        <div className={classNames('&', className)}>
            <ArgMessageRenderer
                message={label}
                data-testid={testid}
                className={classNames('&-message')}
            />
        </div>
    );

    if (tooltip) {
        body = (
            <ArgTooltip2 title={tooltip}>
                {body}
            </ArgTooltip2>
        );
    }

    return body;
}
