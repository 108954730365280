import { Menu } from 'antd';
import React, { DragEvent, ReactNode, useCallback, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import {
    ArgButton,
    ArgCheckbox,
    ArgIcon,
    ArgMenu,
    ArgRenderedText,
    ArgTooltip2,
    ClassValue,
    computeText,
    highlightSplit,
    useClassNames,
} from '../basic';

import './image-viewer-card.less';

export interface ActionOption {
    key: string;
    icon?: string;
    label?: string | MessageDescriptor;
    isDisabled?: boolean;
    tooltip?: string | MessageDescriptor;
}

export type ActionInfo = {
    key: string;
};

export interface ImageViewerCardProps {
    className?: ClassValue;
    caption?: string;
    search?: string;
    moreActions?: ActionOption[];
    onSeeAction?: () => void;
    onSelectAction?: () => void;
    onActionClick?: (info: ActionInfo) => void;
    draggable?: boolean;
    onDragStart?: (event: DragEvent) => void;
    onDragEnd?: (event: DragEvent) => void;
    onClick?: () => void;
    onDoubleClick?: () => void;

    children?: ReactNode;

    tooltip?: ArgRenderedText;
}

export function ImageViewerCard(props: ImageViewerCardProps) {
    const {
        className,
        caption,
        search,
        children,
        moreActions,
        onSeeAction,
        onSelectAction,
        onActionClick,
        draggable,
        onDragStart,
        onDragEnd,
        onClick,
        onDoubleClick,
        tooltip,
    } = props;

    const classNames = useClassNames('image-viewer-card');

    const intl = useIntl();
    const [hover, setHover] = useState<boolean>(false);
    const [menuIsVisible, setMenuIsVisible] = useState<boolean>(false);

    const handleMouseEnter = useCallback(() => {
        setHover(true);
    }, []);
    const handleMouseLeave = useCallback(() => {
        setHover(false);
    }, []);

    const handleClickSeeMore = useCallback(() => {
        onSeeAction?.();
    }, [onSeeAction]);

    const actionsMenu = (moreActions !== undefined &&
        <ArgMenu
            className={classNames('&-menu')}
            onClick={param => onActionClick && onActionClick({
                key: param.key,
            } as ActionInfo)}
        >
            {moreActions.map((action: ActionOption) => {
                const title = computeText(intl, action.label);
                const tooltip = computeText(intl, action.tooltip);

                return <Menu.Item
                    key={action.key}
                    onClick={() => {
                        setMenuIsVisible(false);
                    }}
                    disabled={action.isDisabled}
                    title={tooltip}
                    className={classNames('&-menu-item', action.key)}
                    icon={action.icon ? <ArgIcon
                        name={`icon-${action.icon}`}
                        className={classNames('&-menu-item-icon')}
                    /> : undefined}
                >
                    <span>
                        {title}
                    </span>
                </Menu.Item>;
            })}
        </ArgMenu>
    );

    const cls = {
        '&-hover': hover,
        'menu-open': menuIsVisible,
        draggable,
        clickable: onClick || onDoubleClick,
    };

    let TagName = 'div';
    const tagProps: any = {};
    if (onClick || onDoubleClick) {
        TagName = 'button';
        tagProps.onClick = onClick;
        tagProps.onDoubleClick = onDoubleClick;
    }

    const captionHighlighted = caption ? highlightSplit(caption, search) : '';

    return (
        <TagName
            className={classNames('&', cls, className)}
            onMouseEnter={actionsMenu ? handleMouseEnter : undefined}
            onMouseLeave={actionsMenu ? handleMouseLeave : undefined}
            draggable={draggable}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            {...tagProps}
            data-testid='image-viewer-card'
        >
            <div className={classNames('&-image-container')}>

                <figure className={classNames('&-image')}>
                    {children}

                    {onSelectAction && <ArgCheckbox className={classNames('&-checkbox')} size='node' onChange={onSelectAction} />}

                    <div className={classNames('&-menu-container')} data-testid='menu-container'>

                        {onSeeAction && <ArgButton
                            type='ghost'
                            icon='icon-eye'
                            className={classNames('&-menu-container-button')}
                            onClick={handleClickSeeMore}
                        />}

                        {actionsMenu && <ArgButton
                            type='ghost'
                            icon='icon-options'
                            popover={actionsMenu}
                            popoverTrigger='click'
                            popoverVisible={menuIsVisible}
                            className={classNames('&-menu-container-button')}
                            popoverPlacement='bottomLeft'
                            onPopoverVisibleChange={setMenuIsVisible}
                            popoverOffset={{ mainAxis: 4, crossAxis: 2 }}
                            popoverClassName={classNames('&-popover')}
                            data-testid='action-menu-button'
                        />}
                    </div>
                </figure>
                <ArgTooltip2
                    title={tooltip || caption}
                    data-testid='tooltip'>
                    <figcaption className={classNames('&-caption')}>
                        <div className={classNames('&-caption-content')}>
                            {captionHighlighted}
                        </div>
                    </figcaption>
                </ArgTooltip2>
            </div>
        </TagName>
    );
}
