import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { first } from 'lodash';

import { Action } from '../../../../utils/actions/action';
import { HistoryManager } from '../../../../utils/history-manager';
import { ActionsEngine } from '../../../../utils/actions/actions-engine';
import { ProgressMonitor, SelectionProvider } from '../../../../components/basic';
import { FormDocument, FormElement } from '../../../../components/common/forms/model';
import { FormRepository } from './form-actions-engine';

export const REMOVE_FORM_ELEMENTS_ACTION = Symbol('Form:RemoveFormElements');

export const messages = defineMessages({
    removeFormElements: {
        id: 'exploration.forms.actions.remove-form-elements.TaskName',
        defaultMessage: '{count, plural, =1 {Remove an element} other {Remove {count} elements}}',
    },
    undoRemoveFormElements: {
        id: 'exploration.forms.actions.remove-form-elements.Undo',
        defaultMessage: 'Cancel {count, plural, =1 {remove an element} other {remove {count} elements}}',
    },
});

export function createFormRemoveElements(
    formElements: FormElement[],
    selectionProvider: SelectionProvider<FormElement> | undefined,
): Action<FormRepository, void> {
    let previousValue: FormDocument;

    return {
        type: REMOVE_FORM_ELEMENTS_ACTION,

        renderEntry: function ChangeStepActionTitle(undo: boolean) {
            return (
                <FormattedMessage
                    {...(undo) ? messages.undoRemoveFormElements : messages.removeFormElements}
                    values={{ count: formElements.length }}
                />
            );
        },
        action: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor,
        ) => {
            progressMonitor.beginTask(messages.removeFormElements, 1, {
                count: formElements.length,
            });

            previousValue = actionsEngine.repository.formDocument;

            actionsEngine.repository.remove(formElements);

            selectionProvider?.clear('form-remove-elements');
        },
        reverseAction: async (
            historyManager: HistoryManager<ActionsEngine<FormRepository>>,
            actionsEngine: ActionsEngine<FormRepository>,
            progressMonitor: ProgressMonitor,
        ) => {
            progressMonitor.beginTask(messages.undoRemoveFormElements, 1, {
                count: formElements.length,
            });

            actionsEngine.repository.setDocument(previousValue);
            if (formElements.length) {
                selectionProvider?.set(first(formElements)!, 'form-remove-elements');
            }
        },
    };
}
