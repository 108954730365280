import { BasicOption, OptionKey } from './option';
import { ArgRenderedIcon } from '../../components/basic';

export type ComponentPinKey = string;

export enum ComponentPinType {
    In = 'in',
    Out = 'out',
    Error = 'error',
}

export interface ComponentPin {
    key: ComponentPinKey;
    name: string;
    description: string;
    messageType: string;
    connectionType: 'Component'|'Event';
}

export type ComponentCategory = string;

export enum ComponentType {
    Module = 'Module',
    Connector = 'Connector',
    Injector = 'Injector',
    Phantom = 'Phantom', // Unknown from lib
}

export type ComponentKey = string;

export type ComponentOption = BasicOption;

export interface ComponentOptionsGroup {
    key: OptionKey;
    name: string;
    description: string;
}

export interface ComponentDocumentationInfos {
    lastSegment: string;
    fragment: string;
}

export interface Component {
    key: ComponentKey;
    name: string;
    description: string;
    type: ComponentType;
    category: ComponentCategory;
    documentationURIInfos?: ComponentDocumentationInfos;
    icon?: ArgRenderedIcon;
    outPins?: ComponentPin[];
    inPins?: ComponentPin[];
    errorPins?: ComponentPin[];
    options: ComponentOption[];
    optionsGroups: ComponentOptionsGroup[];
}

export type ComponentLibrary = Readonly<Component>[];
