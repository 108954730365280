import { FoldersState } from './folders-state';
import { useBasicState } from '../use-basic-state';
import { RtApi } from '../rt-api';
import { FOLDERS_INFO_EVENT_NAMES } from './events';

export function useFoldersState(api: RtApi): FoldersState {
    const state = useBasicState<FoldersState>((url) => {
        return new FoldersState(api, url, FOLDERS_INFO_EVENT_NAMES);
    }, '/folders');

    return state!;
}
