import { useMemo } from 'react';

import { ProcessBody } from '../model/process';
import { ProcessActionEngine } from '../process/process-action-engine';
import { ComponentLibrary } from '../model/component';
import { PreparationEnvironmentContext } from './preparation-environment-context';
import { usePreparationEnvironmentContext } from '../hooks/use-preparation-environment-context';

export const PREPARATION_PROCESS_PAGE_MENU_CONTEXT_NAME = 'preparation.process-page.menu';
export const PREPARATION_PROCESS_PAGE_TOOLBAR_CONTEXT_NAME = 'preparation.process-page.toolbar';
export const PREPARATION_PROCESS_PAGE_FLOATING_TOOLBAR_CONTEXT_NAME = 'preparation.process-page.floating';

export const PROCESS_COMPONENT_MIME_TYPE = 'application/arg-preparation-process-component';
export const PROCESS_CONNECTION_MIME_TYPE = 'application/arg-preparation-process-connection';

export interface ProcessEnvironmentContext extends PreparationEnvironmentContext {
    processBody: ProcessBody;

    processActionsEngine: ProcessActionEngine;

    componentLibrary: Readonly<ComponentLibrary>;
}


export function useProcessEnvironmentContext(
    processBody: ProcessBody,
    processActionsEngine: ProcessActionEngine,
    componentLibrary:Readonly<ComponentLibrary>,
):ProcessEnvironmentContext {
    const preparationEnvironmentContext = usePreparationEnvironmentContext();

    const environmentContext = useMemo<ProcessEnvironmentContext>(() => {
        const result: ProcessEnvironmentContext = {
            ...preparationEnvironmentContext,
            processBody,
            processActionsEngine,
            componentLibrary,
        };

        return result;
    }, [preparationEnvironmentContext, componentLibrary, processActionsEngine, processBody]);

    return environmentContext;
}
