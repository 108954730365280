import { defineMessages, MessageDescriptor } from 'react-intl';

import { ItemActions } from '../types/item-actions';
import { ButtonClickEvent } from '../../components/basic';

const onClick = (evt: ButtonClickEvent) => {
    evt.preventDefault();
};
const messages = defineMessages({
    open: {
        id: 'settings.constants.items-actions.Open',
        defaultMessage: 'Open',
    },
    edit: {
        id: 'settings.constants.items-actions.Edit',
        defaultMessage: 'Edit',
    },
    rename: {
        id: 'settings.constants.items-actions.Rename',
        defaultMessage: 'Rename',
    },
    remove: {
        id: 'settings.constants.items-actions.Remove',
        defaultMessage: 'Remove',
    },
    delete: {
        id: 'settings.constants.items-actions.Delete',
        defaultMessage: 'Delete',
    },
    add: {
        id: 'settings.constants.items-actions.Add',
        defaultMessage: 'Add',
    },
});

export const ITEM_ACTIONS: Record<ItemActions, { label: MessageDescriptor; onClick: (event: ButtonClickEvent) => void; key: string }> = {
    [ItemActions.Open]: {
        key: ItemActions.Open,
        onClick,
        label: messages.open,
    },
    [ItemActions.Rename]: {
        key: ItemActions.Rename,
        onClick,
        label: messages.rename,
    },
    [ItemActions.Edit]: {
        key: ItemActions.Edit,
        onClick,
        label: messages.edit,
    },
    [ItemActions.Remove]: {
        key: ItemActions.Remove,
        onClick,
        label: messages.remove,
    },
    [ItemActions.Delete]: {
        key: ItemActions.Delete,
        onClick,
        label: messages.delete,
    },
    [ItemActions.Add]: {
        key: ItemActions.Add,
        onClick,
        label: messages.add,
    },
};
