import { Navigate, Route, Routes } from 'react-router-dom';

import { Logout } from './components/features/logout/logout';
import { Login } from './components/features/login/login';
import { ArgonosModulesRoutes } from './components/containers/argonos-modules-routes';
import { UserAndPermissionsProviders } from './components/containers/user-and-permissions-providers';
import { AuthenticatedRedirection } from './components/containers/authenticated-redirection';

export function AppRoutes() {
    return (
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='login' element={<Navigate to='/' replace={true} />} />
            <Route path='logout' element={<Logout />} />
            <Route element={<UserAndPermissionsProviders />}>
                <Route path='/redirect' element={<AuthenticatedRedirection />} />
                <Route path='*' element={<ArgonosModulesRoutes />} />
            </Route>
        </Routes>
    );
}

