import { useClassNames } from 'src/components/basic';
import { useScopeStateContext } from '../../providers/policy-rules-provider';
import { ScopeProcessed, Scope } from '../../../../models/policy';
import { ApplicationConditionTree } from './application-condition-tree';
import { Filter } from './filter';
import { FilterButtons } from './filter-buttons';
import { ContextualVariable } from 'src/exploration/model/contextual-variable';
import { UserProfileField } from '../../../../../model/user-metadata';

import './application-condition-expression-of-logic.less';

interface ApplicationConditionExpressionOfLogicProps {
    contextualVariables: ContextualVariable[];
    userProfileFields: UserProfileField[];
    search?: string;
}

export function ApplicationConditionExpressionOfLogic({
    contextualVariables,
    userProfileFields,
    search,
}: ApplicationConditionExpressionOfLogicProps) {
    const { scope, editable } = useScopeStateContext();
    const classNames = useClassNames('settings-application-condition-expression-of-logic');

    return (
        <div className={classNames('&-policy-scope-container')}>
            {renderScope(scope, '', editable, contextualVariables, userProfileFields, true, search)}
            {editable && 'Type' in scope && <FilterButtons currentPath='' scope={scope} />}
        </div>
    );
}

const renderScope = (
    scope: Scope | ScopeProcessed,
    currentPath: string,
    editable: boolean,
    contextualVariables: ContextualVariable[],
    userProfileFields: UserProfileField[],
    firstRecursiveCall?: boolean,
    search?: string,
) => {
    if (!('or' in scope) && !('and' in scope)) {
        return (
            <Filter
                key={currentPath}
                filter={scope}
                editable={editable}
                currentPath={currentPath}
                contextualVariables={contextualVariables}
                userProfileFields={userProfileFields}
                search={search}
            />
        );
    }

    const borderClassName = firstRecursiveCall
        ? undefined
        : `${editable ? 'block-container-editable' : 'block-container'}`;

    const subPath = currentPath === '' ? '' : `${currentPath}.`;

    if ('or' in scope) {
        return (
            <div className={borderClassName} key={currentPath}>
                <div className='and-block'>
                    <ApplicationConditionTree
                        scopes={scope.or}
                        block='or'
                        currentPath={currentPath}
                    />
                    <div className='target-sub-block'>
                        {scope.or.map((scope, index) =>
                            renderScope(
                                scope,
                                `${subPath}or[${index}]`,
                                editable,
                                contextualVariables,
                                userProfileFields,
                            ),
                        )}
                    </div>
                </div>
                {editable && (
                    <FilterButtons
                        currentPath={`${subPath}or`}
                        blockScopes={scope.or}
                        operator='or'
                    />
                )}
            </div>
        );
    }

    if ('and' in scope) {
        return (
            <div className={borderClassName} key={currentPath}>
                <div className='and-block'>
                    <ApplicationConditionTree
                        scopes={scope.and}
                        block='and'
                        currentPath={currentPath}
                    />
                    <div className='target-sub-block'>
                        {scope.and.map((scope, index) =>
                            renderScope(
                                scope,
                                `${subPath}and[${index}]`,
                                editable,
                                contextualVariables,
                                userProfileFields,
                            ),
                        )}
                    </div>
                </div>
                {editable && (
                    <FilterButtons
                        currentPath={`${subPath}and`}
                        blockScopes={scope.and}
                        operator='and'
                    />
                )}
            </div>
        );
    }
};
