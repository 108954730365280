import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { NotificationKey, NotificationType } from './types';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ICONS_NAME } from '../arg-alert-card/arg-alert-card';
import { ArgIcon, renderIcon } from '../arg-icon/arg-icon';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgButton } from '../arg-button/arg-button';
import { ArgMessageValues, ArgRenderedIcon, ArgRenderedText } from '../types';

import './arg-snackbar.less';

const ANIMATION_TIMEOUT = 1000;

export interface ArgSnackbarProps {
    key: NotificationKey;
    type: NotificationType;
    message: ArgRenderedText;
    description?: ArgRenderedText;
    details?: ArgRenderedText;
    buttonLabel?: ArgRenderedText;
    messageValues?: ArgMessageValues;
    onClose?: () => void;
    duration?: number | null;
    icon?: ArgRenderedIcon;
    className?: ClassValue;
    onClick?: () => void;
}

export function ArgSnackbar(props: ArgSnackbarProps) {
    const {
        type,
        message,
        description,
        className,
        messageValues,
        icon,
        onClose,
    } = props;

    const snackbarRef = useRef<HTMLDivElement | null>(null);

    const [isSnackbarDisplayed, setSnackbarDisplayed] = useState<boolean>(false);

    const classNames = useClassNames('arg-snackbar');

    useEffect(() => {
        setSnackbarDisplayed(true);
    }, []);

    let iconComponent: ReactNode = <ArgIcon name={ICONS_NAME[type]} />;

    if (icon) {
        iconComponent = renderIcon(icon);
    }

    const cls: ClassValue = {
        error: type === 'error',
        warning: type === 'warning',
        success: type === 'success',
        info: type === 'info',
    };

    return (
        <CSSTransition
            nodeRef={snackbarRef}
            in={isSnackbarDisplayed}
            timeout={ANIMATION_TIMEOUT}
        >
            <div ref={snackbarRef} className={classNames('&', cls, className)}>
                <div className={classNames('&-icon')}>
                    {iconComponent}
                </div>

                <div className={classNames('&-content')}>
                    <span className={classNames('&-content-message')}>
                        {renderText(message, messageValues)}
                    </span>
                    <span className={classNames('&-content-description')}>
                        {renderText(description, messageValues)}
                    </span>
                </div>

                <ArgButton
                    icon='icon-cross'
                    type='ghost'
                    onClick={onClose}
                    className={classNames('&-close')}
                />
            </div>
        </CSSTransition>
    );
}
