import { Dispatch, SetStateAction } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { Policy } from 'src/settings/models/dtoApi';
import { ArgCheckbox, ArgIcon, ArgRelativeTime, highlightSplit, useClassNames } from 'src/components/basic';
import { PoliciesListKebabMenu } from '../policies-list-kebab-menu';
import { useReorder } from 'src/hooks/use-reorder';
import { computePolicyDetailsUrl } from 'src/settings/utils/compute-url';

import './policy-component.less';

export const messages = defineMessages({
    statusColumnActivated: {
        id: 'settings.policies-list.field.status.activated',
        defaultMessage: 'Activated',
    },
    statusColumnDeactivated: {
        id: 'settings.policies-list.field.status.deactivated',
        defaultMessage: 'Deactivated',
    },
    by: {
        id: 'settings.policies-list.field.by',
        defaultMessage: 'By ',
    },
    editing: {
        id: 'settings.policies-list.field.editing',
        defaultMessage: 'Modifications not applied',
    },
    completed: {
        id: 'settings.policies-list.field.completed',
        defaultMessage: 'Applied',
    },
    pendingOrInProgress: {
        id: 'settings.policies-list.field.pendingOrInProgress',
        defaultMessage: 'Applying modifications',
    },
    progressWithPercent: {
        id: 'settings.policies-list.field.progressWithPercent',
        defaultMessage: 'Applying modifications ({updateProgress, number, percent})',
    },
});

export const ItemTypes = {
    OPTION: 'option',
};

export enum UpdateStatus {
    EDITING = 'editing',
    COMPLETED = 'completed',
    PENDING = 'pending',
    IN_PROGRESS = 'inProgress',
}


export interface PolicyComponentProps {
    policy: Policy;
    visualIndex: number;
    index: number;
    selected: boolean;
    setPoliciesIdSelected: Dispatch<SetStateAction<Set<string>>>;
    setPolicies: Dispatch<SetStateAction<Policy[]>>;
    movePolicies: (dragIndex: number, hoverIndex: number) => void;
    onReorder: () => void;
    textToHighlight: string | null;
}

export function PolicyComponent(props: PolicyComponentProps) {
    const {
        policy,
        visualIndex,
        index,
        selected,
        setPoliciesIdSelected,
        setPolicies,
        movePolicies,
        onReorder,
        textToHighlight,
    } = props;

    const intl = useIntl();
    const navigate = useNavigate();
    const classNames = useClassNames('settings-policy-component');
    const { ontologyId } = useParams<{ ontologyId: string }>();

    const { handlerId, isDragging, drag, ref } = useReorder(
        ItemTypes.OPTION,
        movePolicies,
        index,
        policy,
        onReorder,
    );

    const handlePolicySelection = (checked: boolean, policyId: string) => {
        setPoliciesIdSelected((prevIds) => {
            const newSet = new Set(prevIds);
            if (checked) {
                newSet.add(policyId);
            } else {
                newSet.delete(policyId);
            }

            return newSet;
        });
    };

    const getState = (updateStatus?: string, updateProgress?: number) => {
        if (updateStatus?.toLowerCase() === UpdateStatus.EDITING) {
            return intl.formatMessage(messages.editing);
        }
        if (updateStatus?.toLowerCase() === UpdateStatus.COMPLETED) {
            return intl.formatMessage(messages.completed);
        }
        if (updateProgress !== undefined) {
            return intl.formatMessage(messages.progressWithPercent, { updateProgress });
        } else {
            return intl.formatMessage(messages.pendingOrInProgress);
        }
    };

    const navigateToPolicy = () => {
        if (!ontologyId) {
            return;
        }

        const policyUrl = computePolicyDetailsUrl(ontologyId, policy.universeId, policy.id);

        navigate(policyUrl);
    };

    return (
        <div
            className={classNames('&')}
            data-handler-id={handlerId}
            style={{ opacity: isDragging ? 0 : 1 }}
            ref={ref}
        >
            <div className={classNames('&-checkbox')}>
                <ArgCheckbox
                    size='node'
                    value={selected}
                    onChange={(checked) => handlePolicySelection(checked, policy.id)}
                />
            </div>
            <div
                className={classNames(
                    '&-fields',
                    selected ? '&-fields-blue' : '',
                    policy.enabled ? '' : '&-fields-disabled',
                )}
            >
                <div className={classNames('&-index')} ref={drag}>
                    <div className={classNames('&-index-drag')}>
                        <ArgIcon name='icon-6dots' className={classNames('&-drag-icon')} />
                    </div>
                    <div className={classNames('&-index-order')}>{visualIndex}</div>
                </div>
                <div
                    className={classNames('&-fields-clickable')}
                    onClick={navigateToPolicy}
                >
                    <div className={classNames('&-name')}>
                        {highlightSplit(policy.name || '', textToHighlight)}
                    </div>
                    <div className={classNames('&-description')}>
                        {highlightSplit(policy.description || '', textToHighlight)}
                    </div>
                    <div className={classNames('&-update-status')}>
                        {getState(policy.updateStatus, policy.updateProgress)}
                    </div>
                    <div className={classNames('&-last-modified')}>
                        {policy.lastPublishedDate ? (
                            <>
                                <ArgRelativeTime
                                    date={policy.lastPublishedDate}
                                    numeric='auto'
                                />
                                <span className={classNames('&-last-modified-user')}>
                                    <FormattedMessage {...messages.by} />
                                    {policy.lastPublishedBy?.displayName}
                                </span>
                            </>
                        ) : null}
                    </div>
                    <div className={classNames('&-enabled')}>
                        {policy.enabled
                            ? intl.formatMessage(messages.statusColumnActivated)
                            : intl.formatMessage(messages.statusColumnDeactivated)}
                    </div>
                </div>
                <div className={classNames('&-kebab')}>
                    <PoliciesListKebabMenu policy={policy} setPolicies={setPolicies} />
                </div>
            </div>
        </div>
    );
}
