import Debug from 'debug';
import { isNumber } from 'lodash';

import { ArgTableColumn3 } from './arg-table3';
import { escapeColumnKey } from '../utils';

const debug = Debug('argonode:components:argTable3:ExpandColumn');

const MIN_WIDTH = 48;


export function expandColumn<T>(
    container: HTMLElement,
    column: ArgTableColumn3<T>,
    setWidth: (width: number) => void,
) {
//    console.log('expandColumn', 'column=', column.columnName, column.key);

    let minWidth = MIN_WIDTH;
    if (isNumber(column.minWidth)) {
        minWidth = column.minWidth;
    }

    let headerWidth;
    let bodyWidth;

    const bodyColumnComponent = container.querySelector(`[data-column="${escapeColumnKey(column.key)}"]`) as HTMLElement;
    if (bodyColumnComponent) {
        const cells: Element[] = Array.from(bodyColumnComponent.children);

        const maxWidth = cells.reduce((acc, cell, index) => {
            const firstChild = cell.firstElementChild;
            if (!firstChild) {
                return acc;
            }

            const ret = Math.max(acc, firstChild.scrollWidth);

            //console.log('MaxWidth=', ret, index, acc, firstChild.scrollWidth, firstChild);

            return ret;
        }, minWidth);

        //console.log('MaxWidth=', maxWidth, minWidth);

        bodyWidth = maxWidth;
        if (cells[0]?.tagName) {
            const computedStyle = window.getComputedStyle(cells[0]);

            const paddingLeft = computedStyle.paddingLeft;
            const paddingRight = computedStyle.paddingRight;

            bodyWidth += ((paddingLeft) ? parseInt(paddingLeft) : 0) + ((paddingRight) ? parseInt(paddingRight) : 0) + 8;

            //  console.log('PaddingLeft=', paddingLeft, 'paddingRight=', paddingRight);

            debug('expandColumn', 'Computed paddings =', paddingLeft, paddingRight);
        }

        //console.log('BodyWidth=', bodyWidth);
    }

    const headerColumnComponent = container.querySelector(`[data-header="${escapeColumnKey(column.key)}"]`) as HTMLElement;
    if (headerColumnComponent) {
        const div = headerColumnComponent.firstElementChild?.firstElementChild;

        if (div) {
            let w = 0;

            Array.from(div.children).forEach((child) => {
                w += child.scrollWidth;
            });

            //console.log('Header w=', w);

            const computedStyle = window.getComputedStyle(div);

            const paddingLeft = computedStyle.paddingLeft;
            const paddingRight = computedStyle.paddingRight;

            w += ((paddingLeft) ? parseInt(paddingLeft) : 0) + ((paddingRight) ? parseInt(paddingRight) : 0) + 8;

            //console.log('Header+padding w=', w);

            headerWidth = w;
        }
    }

    //console.log('Final', 'bodyWidth=', bodyWidth, 'headerWidth=', headerWidth);

    if (!bodyWidth && !headerWidth) {
        return;
    }

    const w = Math.max(headerWidth || 0, bodyWidth || 0);
    setWidth(w);
}
