import React, { Dispatch, SetStateAction, useState } from 'react';
import { defineMessages } from 'react-intl';
import * as uuid from 'uuid';

import { PolicyProcessed } from 'src/settings/models/dtoApi';
import { ArgButton, ArgInputSearch, useClassNames, useArgNotifications } from 'src/components/basic';
import { RuleProcessed } from '../../../../../models/policy';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

import './rules-page-toolbar.less';

const messages = defineMessages({
    newRule: {
        id: 'settings.policy-rules-page.toolbar.new',
        defaultMessage: 'New',
    },
    import: {
        id: 'settings.policy-rules-page.toolbar.import',
        defaultMessage: 'Import',
    },
    searchPlaceholder: {
        id: 'settings.policy-rules-page.toolbar.search-bar.placeholder',
        defaultMessage: 'Search...',
    },
    savingPolicyError: {
        id: 'settings.policy-rules-page.toolbar.savingPolicyError',
        defaultMessage: 'An error occurred while saving the policy',
    },
});

interface RulesPageToolbarProps {
    search: string | undefined;
    policy: PolicyProcessed;
    setSearch: Dispatch<SetStateAction<string | undefined>>;
    setPolicy: Dispatch<SetStateAction<PolicyProcessed | undefined>>;
    editableRuleIds: Set<string>;
}

export function RulesPageToolbar(props: RulesPageToolbarProps) {
    const {
        search,
        setSearch,
        setPolicy,
        policy,
        editableRuleIds,
    } = props;

    const classNames = useClassNames('settings-rules-page-toolbar');
    const notifications = useArgNotifications();

    const [loading, setLoading] = useState(false);

    const addRule = async () => {
        try {
            setLoading(true);

            const newRule: RuleProcessed = {
                InferenceDetection: false,
                Targets: [{ object: {} }],
                Effects: [],
                id: uuid.v4(),
            };
            const newPolicy = {
                ...policy,
                statement: { ...policy.statement, Actions: [...policy.statement.Actions, newRule] },
            };

            await explorationSettingsConnector.editPolicy(policy.id, newPolicy);

            setPolicy(newPolicy);
            setLoading(false);
        } catch (error) {
            notifications.snackError({ message: messages.savingPolicyError }, error as Error);
            setLoading(false);
        }
    };

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-buttons-group')}>
                <div className={classNames('&-search-bar-container')}>
                    <ArgInputSearch
                        className={classNames('&-search-bar')}
                        placeholder={messages.searchPlaceholder}
                        value={search || undefined}
                        onChange={(value) => setSearch(value || undefined)}
                    />
                </div>
            </div>
            <div className={classNames('&-buttons-group')}>
                <ArgButton
                    size='medium'
                    type='primary'
                    icon='icon-plus'
                    label={messages.newRule}
                    onClick={() => addRule()}
                    disabled={loading || editableRuleIds.size > 0}
                    loading={loading}
                />
            </div>
        </div>
    );
};
