import { isString } from 'lodash';
import Editor from '@ckeditor/ckeditor5-core/src/editor/editor';
import { scrollAncestorsToShowTarget } from '@ckeditor/ckeditor5-utils/src/dom/scroll';
import { EditorWithUI } from '@ckeditor/ckeditor5-core/src/editor/editorwithui';
import Sessions from '@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativeediting/sessions';
import { UserData, Users } from '@ckeditor/ckeditor5-collaboration-core/src/users';
import { Marker } from '@ckeditor/ckeditor5-engine/src/model/markercollection';

import { UserActionDatesProps } from '../../common/toolbar/user-action-dates';
import { BriefUser, HeaderItem } from './common-types';
import { BriefCasePiece, BriefSection } from '../../model/brief';
import { ProgressMonitor, SubProgressMonitor } from '../../../components/basic';
import { BriefConnector } from '../../utils/connector/brief-connector';

interface InternalUser {
    id: string;
    permissions: string[];
    role: string;
    user: UserData;
}

export function cleanUserId(user: BriefUser): string {
    return user.userId.replace('user-', '');
}

export function scrollToTheSelection(editor: Editor) {
    if (editor) {
        const editingView = editor.editing.view;
        const range = editingView.document.selection.getFirstRange();

        if (range) {
            scrollAncestorsToShowTarget(editingView.domConverter.viewRangeToDom(range));
        }
    }
}

export function computeHeaderItems(editor: Editor): HeaderItem[] {
    const headers: HeaderItem[] = [];
    const editorModel = editor?.model;
    const root = editorModel?.document?.getRoot();

    if (!root) {
        return headers;
    }

    const range = editorModel.createRangeIn(root);
    for (const value of range.getWalker({ ignoreElementEnd: true })) {
        const item = value?.item;
        if (!item?.is('element') || !item?.name?.match(/^heading/)) {
            continue;
        }

        const firstChild = item?.getChild(0);
        if (!firstChild || !firstChild.is('text')) {
            continue;
        }

        const data = firstChild.data;
        const tag = item.name.replace('heading', 'h');
        if (!data || !tag) {
            continue;
        }

        const headerItem: HeaderItem = {
            tag,
            data,
            rowIndex: value.nextPosition.path[0],
        };

        headers.push(headerItem);
    }

    return headers;
}

export function mapToUserActionDates(brief: BriefCasePiece): UserActionDatesProps {
    const ret: UserActionDatesProps = {
        createdDate: brief.createdDate,
        createdBy: brief.createdBy,
        updatedDate: brief.lastUpdatedDate,
        updatedBy: brief.lastUpdatedBy,
    };

    return ret;
}

export function getBriefUsers(editor: EditorWithUI): BriefUser[] {
    const initialBriefUsers: Record<string, BriefUser> = {};

    const sessions = editor.plugins.get('Sessions') as any as Sessions;
    const { me } = editor.plugins.get('Users') as any as Users;

    const processed: Record<string, true> = {};

    const markers = Array.from(editor.model.markers);

    for (const iterator of markers) {
        const userSessionId = iterator.name.split(':')[2];

        if (processed[userSessionId]) {
            continue;
        }
        processed[userSessionId] = true;

        let internalUser: InternalUser | undefined = undefined;

        const channelSessions: Map<string, InternalUser[]> = sessions.channelSessions as any as Map<string, InternalUser[]>;
        channelSessions.forEach((internalUsers: InternalUser[]) => {
            const ret = internalUsers.find((s) => s.id === userSessionId);
            if (ret) {
                internalUser = ret;
            }
        });

        if (!internalUser) {
            continue;
        }

        const userId = (internalUser as InternalUser).user.id;
        if (userId === me?.id || initialBriefUsers[userId]) {
            continue;
        }

        const briefUser: BriefUser = {
            userId: userId,
            userName: (internalUser as InternalUser).user.name!,
            rowIndex: iterator.getStart().path[0],
        };

        initialBriefUsers[userId] = briefUser;
    }


    const ret = Object.values(initialBriefUsers);

    return ret;
}

export const getBriefToken = async (brief: BriefCasePiece, briefSection: BriefSection, progressMonitor: ProgressMonitor) => {
    const tokenUrl = briefSection.manifest?.cloudServices?.tokenUrl;
    if (tokenUrl === undefined) {
        throw new Error('Undefined Brief Token URL');
    }

    const sub1 = new SubProgressMonitor(progressMonitor, 1);
    let jwtToken;
    try {
        jwtToken = await BriefConnector.getInstance().getBriefSectionToken(brief.id, briefSection.id, sub1);
    } catch (x) {
        const ex = new Error('Get Brief token error');
        (ex as any).cause = x;

        console.error(ex);
        throw ex;
    }

    if (!isString(jwtToken) || !jwtToken) {
        throw new Error('Invalid JWT token');
    }

    return jwtToken;
};

export function getPositionMarkers(editor: EditorWithUI): Marker[] {
    const positionMarkers = Array.from(editor.model.markers).filter((marker) => {
        const ret = marker.name.startsWith('user:position');

        return ret;
    });

    return positionMarkers;
}
