import { lazy, Suspense } from 'react';

import { ArgMessageValues, ArgRenderedText, ClassValue } from '../../basic';
import { Country } from '../../../model/country';
import { LoadingSuspense } from '../../containers/loading-suspense';

const LazyCountryComponent = lazy(() => import('./lazy-country-component'));

interface CountryComponentProps {
    className?: ClassValue;
    country: Country;
    label?: true | false | ArgRenderedText;
    flag?: boolean;
    messageValues?: ArgMessageValues;
}

export function CountryComponent(props: CountryComponentProps) {
    const {
        className,
        country,
        messageValues,
        label,
        flag,
    } = props;

    return <Suspense fallback={<LoadingSuspense />}>
        <LazyCountryComponent
            className={className}
            country={country}
            label={label}
            flag={flag}
            messageValues={messageValues}
        />
    </Suspense>;
}
