import { cloneDeep, set } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Policy, PolicyProcessed } from 'src/settings/models/dtoApi';
import {
    ArgButton,
    ArgIcon,
    ThreeDotsLoading,
    useClassNames,
    useArgNotifications,
} from 'src/components/basic';
import { AccessRuleStateContext, useOntologyContext } from '../../../../common/providers/policy-rules-provider';
import { PolicyRuleBlockEffect } from '../policy-rule-block-effect/policy-rule-block-effect';
import { RuleProcessed } from 'src/settings/models/policy';
import { isEmptyObject, isValidEffect, isValidTarget } from 'src/settings/universes/common/policy-utils';
import { PolicyRuleBlockTarget } from 'src/settings/universes/common/rules/policy-rule/policy-rule-block-target';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

import './policy-rule-block.less';

interface PolicyRuleBlockProps {
    rule: RuleProcessed;
    index: number;
    setPolicy: Dispatch<SetStateAction<PolicyProcessed | undefined>>;
    editable: boolean;
    setEditable: (value: boolean) => void;
    policy: PolicyProcessed;
    search?: string;
}

const messages = defineMessages({
    rule: {
        id: 'settings.policy-rule-page.rule',
        defaultMessage: 'Rule',
    },
    invalidRule: {
        id: 'settings.policy-rule-block-target.invalidRule',
        defaultMessage:
            'The rule target has been created on another system and its details cannot be displayed through this interface',
    },
    savingPolicyError: {
        id: 'settings.policy-rule-block-target.savingPolicyError',
        defaultMessage: 'An error occurred while saving the policy',
    },
    invalidRuleTitle: {
        id: 'settings.policy-rule-block-target.invalidRuleTitle',
        defaultMessage: 'Error in the logical expression',
    },
    invalidRuleDescription: {
        id: 'settings.policy-rule-block-target.invalidRuleDescription',
        defaultMessage: 'Unable to validate the rule',
    },
    cancel: {
        id: 'settings.contextual-variables.delete-variable-tooltip.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.contextual-variables.delete-variable-tooltip.delete',
        defaultMessage: 'Delete',
    },
    confirm: {
        id: 'settings.contextual-variables.delete-variable-tooltip.confirm',
        defaultMessage: 'Validate',
    },
});

export function PolicyRuleBlock(props: PolicyRuleBlockProps) {
    const {
        rule,
        index,
        setPolicy,
        editable,
        setEditable,
        policy,
        search,
    } = props;

    const intl = useIntl();
    const notifications = useArgNotifications();

    const classNames = useClassNames('policy-rule-block');
    const [expanded, setExpanded] = useState(true);
    const [ruleState, setRuleState] = useState(rule);
    const [loading, setLoading] = useState(false);

    const invalidRule = ruleState.Targets.length !== 1;

    const { ontologySchema, userProfileFields } = useOntologyContext();

    const saveRule = async () => {
        const canBeSaved =
            ruleState.Targets.every((target) => isValidTarget(target) || isEmptyObject(target)) &&
            ruleState.Effects.every((effect) => isValidEffect(effect));

        if (!canBeSaved) {
            notifications.snackError({
                message: messages.invalidRuleTitle,
                description: messages.invalidRuleDescription,
            });
        } else {
            try {
                setLoading(true);
                const newPolicy = set(cloneDeep(policy), `statement.Actions[${index}]`, ruleState);
                await explorationSettingsConnector.editPolicy(policy.id, newPolicy as Policy);
                setPolicy(newPolicy);
                setLoading(false);
                setEditable(false);
            } catch (error) {
                notifications.snackError({ message: messages.savingPolicyError }, error as Error);
                setLoading(false);
            }
        }
    };

    const resetRule = () => {
        setRuleState(rule);
        setEditable(false);
    };

    const deleteRule = async () => {
        try {
            setLoading(true);
            const newPolicy = set(
                cloneDeep(policy),
                'statement.Actions',
                policy.statement.Actions.filter((act, idx) => idx !== index),
            );
            await explorationSettingsConnector.editPolicy(policy.id, newPolicy);
            setPolicy(newPolicy);
            setLoading(false);
            setEditable(false);
        } catch (error) {
            notifications.snackError({ message: messages.savingPolicyError }, error as Error);
        }
    };

    return (
        <AccessRuleStateContext.Provider value={{ rule: ruleState, setRule: setRuleState, editable }}>
            <div className={classNames('&-container')}>
                <div className={classNames('&-header')}>
                    <div className={classNames('&-header-left')}>
                        <div
                            className={classNames('&-rule-title', editable && '&-color-editable')}
                        >{`${intl.formatMessage(messages.rule)} ${index + 1}`}</div>
                        <div
                            onClick={() => setExpanded(!expanded)}
                            className={classNames('&-icon')}
                        >
                            <ArgIcon
                                name={expanded ? 'icon-cheveron-up' : 'icon-cheveron-down'}
                                size={20}
                            />
                        </div>
                    </div>
                    <div className={classNames('&-header-right')}>
                        {editable && loading && <ThreeDotsLoading />}
                        {editable && !loading && (
                            <>
                                <ArgButton size='medium'
                                           type='ghost'
                                           tooltip={messages.confirm}
                                           icon='icon-checkmark1'
                                           onClick={() => saveRule()}
                                />
                                <ArgButton size='medium'
                                           type='ghost'
                                           tooltip={messages.cancel}
                                           icon='icon-cross'
                                           onClick={() => resetRule()}
                                />
                                <ArgButton size='medium'
                                           type='ghost'
                                           tooltip={messages.delete}
                                           icon='icon-trash'
                                           onClick={() => deleteRule()}
                                />
                            </>
                        )}
                        {!editable && (
                            <div onClick={() => setEditable(true)} className={classNames('&-icon')}>
                                <ArgIcon name='icon-edit-pencil' size={14} />
                            </div>
                        )}
                    </div>
                </div>
                {expanded && !invalidRule && (
                    <div
                        className={classNames(
                            '&-target-effect-container',
                            editable && '&-editable',
                        )}
                    >
                        {ruleState.Targets.map((target, policyIndex) => {
                            return <div key={policyIndex}
                                        className={classNames('&-target-container', editable && '&-editable')}>
                                <PolicyRuleBlockTarget
                                    editable={editable}
                                    policyTargetsPath='Targets'
                                    target={target}
                                    policyIndex={policyIndex}
                                    onRuleChange={setRuleState}
                                    schema={ontologySchema}
                                    userProfileFields={userProfileFields}
                                    search={search}
                                />
                            </div>;
                        })}
                        <div className={classNames('&-effect-container', editable && '&-editable')}>
                            <PolicyRuleBlockEffect
                                effects={ruleState.Effects}
                                editable={editable}
                                search={search}
                            />
                        </div>
                    </div>
                )}
                {expanded && invalidRule && (
                    <div className={classNames('&-invalidRule')}>
                        <div>{intl.formatMessage(messages.invalidRule)}</div>
                    </div>
                )}
            </div>
        </AccessRuleStateContext.Provider>
    );
}
