import { ReactNode, useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ArgTable2, ArgTable2Column, ClassValue, SelectionProvider, useClassNames } from 'src/components/basic';
import { DateByUser } from 'src/components/common/date-by-user';
import { ValuationPolicy } from 'src/settings/models/valuation-policy';
import { dateSorter } from 'src/utils/sorter';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { ValuationPolicyActions } from '../valuation-policies-actions/valuation-policies-actions';
import { computeValuationPolicyDetailsUrl } from 'src/settings/utils/compute-url';
import { OntologyId } from 'src/settings/universes/ontology/types';
import { UniverseId } from 'src/exploration/model/universe';

import './valuation-policies-table.less';

const SETTINGS_VALUATION_TABLE_SELECTION_SOURCE = 'settings-valuation-policy-table';

const messages = defineMessages({
    name: {
        id: 'settings.valuation-policies.table.name',
        defaultMessage: 'Name',
    },
    description: {
        id: 'settings.valuation-policies.table.description',
        defaultMessage: 'Description',
    },
    status: {
        id: 'settings.valuation-policies.table.status',
        defaultMessage: 'Status',
    },
    enabled: {
        id: 'settings.valuation-policies.table.enabled',
        defaultMessage: 'Enabled',
    },
    disabled: {
        id: 'settings.valuation-policies.table.disabled',
        defaultMessage: 'Disabled',
    },
    lastPublished: {
        id: 'settings.valuation-policies.table.last-published',
        defaultMessage: 'Last published',
    },
    emptyValuationPolicies: {
        id: 'settings.valuation-policies.table.empty',
        defaultMessage: 'No valuation policies',
    },
});

interface ValuationPoliciesTableProps {
    className?: ClassValue;
    valuationPolicies: ValuationPolicy[];
    search?: string;
    handleRefresh: () => void;
    ontologyId?: OntologyId;
    universeId?: UniverseId;
}

export function ValuationPoliciesTable(props: ValuationPoliciesTableProps) {
    const { className, valuationPolicies, search, handleRefresh, ontologyId, universeId } = props;

    const classNames = useClassNames('settings-valuation-policies-table');

    const navigate = useNavigate();

    const valuationPoliciesSelectionProvider = useMemo(
        () => new SelectionProvider<ValuationPolicy>('settings-valuation-policy-table', (valuationPolicy) => valuationPolicy.id),
        [],
    );

    const handleRowDoubleClick = useCallback((valuationPolicy: ValuationPolicy) => {
        if (!ontologyId || !universeId) {
            return;
        }
        const valuationPolicyUrl = computeValuationPolicyDetailsUrl(ontologyId, universeId, valuationPolicy.id);
        navigate(valuationPolicyUrl);
    }, [navigate, ontologyId, universeId]);

    const columns = useMemo<ArgTable2Column<ValuationPolicy>[]>(() => {
        return [
            {
                key: 'name',
                title: messages.name,
                dataIndex: 'name',
            },
            {
                key: 'description',
                title: messages.description,
                dataIndex: 'description',
                width: '40%',
            },
            {
                key: 'status',
                title: messages.status,
                dataIndex: 'enabled',
                render: function renderStatus(enabled: boolean) {
                    return <FormattedMessage {...(enabled ? messages.enabled : messages.disabled)} />;
                },
            },
            {
                key: 'lastPublishedDate',
                title: messages.lastPublished,
                dataIndex: 'lastPublishedDate',
                sorter: (a, b) => dateSorter<ValuationPolicy>(a, b, (item) => item.lastPublishedDate),
                ellipsis: true,
                render: function LastPublished(date: Date, valuationPolicy: ValuationPolicy) {
                    if (!valuationPolicy.lastPublishedBy) {
                        return '-';
                    }

                    return (
                        <DateByUser
                            date={date}
                            relative={true}
                            user={valuationPolicy.lastPublishedBy}
                        />
                    );
                },
            },
            {
                key: 'actions',
                columnName: ' ',
                title: ' ',
                dataIndex: 'actions',
                sortable: false,
                width: 50,
                render: function display(data: string, valuationPolicy: ValuationPolicy) {
                    return (
                        <ValuationPolicyActions
                            valuationPolicy={valuationPolicy}
                            className={classNames('&-action-button')}
                            onActionSuccess={handleRefresh}
                        />
                    );
                },
            },
        ];
    }, [classNames, handleRefresh]);

    let table: ReactNode = null;
    if (valuationPolicies.length === 0) {
        table = (
            <EmptyPane
                message={messages.emptyValuationPolicies}
                className={classNames('&-pane')}
            />
        );
    } else {
        table = (
            <ArgTable2<ValuationPolicy>
                className={classNames('&-table')}
                columns={columns}
                rowHeight={45}
                dataSource={valuationPolicies}
                search={search}
                selectionProvider={valuationPoliciesSelectionProvider}
                onRowDoubleClick={handleRowDoubleClick}
                selectionSource={SETTINGS_VALUATION_TABLE_SELECTION_SOURCE}
            />
        );
    }

    return (
        <div className={classNames('&', className)}>
            {table}
        </div>
    );
}
