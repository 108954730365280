import { InputMaskProps } from './arg-input-mask';

export function validateMaskPlaceholder(props: InputMaskProps) {
    const { mask, maskPlaceholder } = props;

    if (
        !mask ||
        !maskPlaceholder ||
        maskPlaceholder.length === 1 ||
        maskPlaceholder.length === mask.length
    ) {
        return;
    }

    console.error('react-input-mask: maskPlaceholder should either be a single character or have the same length as the mask:\n' +
            `mask: ${mask}\n` +
            `maskPlaceholder: ${maskPlaceholder}`,
    );
}
