import React from 'react';

import { ClassValue, useClassNames } from 'src/components/basic';

import './property-form-modal.less';

interface CreateFormModalProps {
    children: React.ReactNode;
    className?: ClassValue;
}

export function PropertyFormModal(props: CreateFormModalProps) {
    const { className, children } = props;
    const classNames = useClassNames('property-form-modal');

    return (
        <form autoComplete='off' className={classNames('&', className)}>
            {children}
        </form>
    );
}
