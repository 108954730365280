import { ContextPlugin, Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import ViewElement from '@ckeditor/ckeditor5-engine/src/view/element';
import ContainerElement from '@ckeditor/ckeditor5-engine/src/view/containerelement';
import Range from '@ckeditor/ckeditor5-engine/src/view/range';
import ModelElement from '@ckeditor/ckeditor5-engine/src/model/element';
import { DowncastWriter } from '@ckeditor/ckeditor5-engine';
import Debug from 'debug';
import { capitalize, isObject, kebabCase } from 'lodash';
import * as UUID from 'uuid';

import InsertCommand from './insert-command';

const debug = Debug('exploration:brief:PreviewEditing');

export interface Config {
    defineClipboardInputOutput?: (plugin: Plugin, config: Config, itemType: string, itemAttributes: string[]) => void;
    defineExtends?: (plugin: Plugin) => void;
    requires?: Array<typeof Plugin | typeof ContextPlugin>;
    containerElement?: string;
    containerElementClassName?: string;
    hasSelectionHandle?: boolean;
    isBlock?: boolean;
    customProperty?: string;
    postEditingDowncast?: (plugin: Plugin, viewWriter: DowncastWriter, containerElement: ContainerElement, viewElement: ViewElement, modelElement: ModelElement) => void;

    attributeTypes?: Record<string, 'number' | 'boolean'>;
}

export function createTypePlugin(pluginName: string,
    itemAttributes: string[],
    config?: Config,
) {
    const clz = class extends Plugin {
        static get requires() {
            return [Widget, ...(config?.requires || [])];
        }

        /**
         * @inheritDoc
         */
        static get pluginName() {
            return `${pluginName}`;
        }

        init() {
            this._defineSchema();
            this._defineConverters();

            config?.defineExtends && config.defineExtends(this);

            config?.defineClipboardInputOutput && config.defineClipboardInputOutput(this, config, pluginName, itemAttributes);

            this.editor.commands.add(`insert${capitalize(pluginName)}`, new InsertCommand(this.editor, pluginName, itemAttributes));
        }

        _defineSchema() {
            const schema = this.editor.model.schema;

            if (config?.isBlock) {
                schema.register(`${pluginName}`, {
                    isObject: true,

                    isBlock: true,
                    allowWhere: '$block',

                    allowAttributes: ['id', 'uuid', ...itemAttributes],
                });

                return;
            }

            schema.register(`${pluginName}`, {
                isObject: true,

                allowWhere: '$text',
                isInline: true,
                allowAttributesOf: '$text',

                allowAttributes: ['id', 'uuid', ...itemAttributes],
            });
        }

        _defineConverters() {
            const editor = this.editor;
            const conversion = editor.conversion;

            conversion.for('upcast').elementToElement({
                view: {
                    name: (config?.isBlock ? 'div' : 'span'),
                    classes: `arg-ck-${pluginName}`,
                },
                model: (viewElement, { writer: modelWriter }) => {
                    const attributes = {
                        id: viewElement.getAttribute('data-id')!,
                        uuid: viewElement.getAttribute('data-uuid') || UUID.v4(),
                        ...itemAttributes.reduce((acc, attributeName) => {
                            let value: any = viewElement.getAttribute(`data-${kebabCase(attributeName)}`);

                            if (value === undefined) {
                                return acc;
                            }

                            const convertType = config?.attributeTypes?.[attributeName];
                            if (convertType === 'number') {
                                value = Number(value);
                            } else if (convertType === 'boolean') {
                                value = Boolean(value);
                            }

                            acc[attributeName] = value;

                            return acc;
                        }, {} as Record<string, string | number | boolean>),
                    };

                    debug('upcast', 'ViewElement=', viewElement, 'attributes=', attributes);

                    const modelElement = modelWriter.createElement(`${pluginName}`, attributes);

                    return modelElement;
                },
            });

            // model → data view
            conversion.for('dataDowncast').elementToElement({
                model: pluginName,
                view: (modelElement, { writer: viewWriter }) => {
                    const attributes = {
                        class: `arg-ck-${pluginName}`,

                        'data-id': modelElement.getAttribute('id')!,
                        'data-arg-type': pluginName,

                        ...itemAttributes.reduce((acc, attributeName) => {
                            const value = modelElement.getAttribute(attributeName);
                            if (value === undefined) {
                                return acc;
                            }

                            if (attributeName === 'width') {
                                acc.style += `width: ${value};`;
                            }

                            acc[`data-${kebabCase(attributeName)}`] = value;

                            return acc;
                        }, { style: '' } as Record<string, any>),
                    } as Record<string, any>;

                    if (attributes['data-screenshot-width'] && attributes['data-screenshot-height'] && !attributes['data-exploration-id']) {
                        attributes.style += `aspect-ratio: ${attributes['data-screenshot-width']} / ${attributes['data-screenshot-height']};`;
                    }

                    debug('dataDowncast', 'modelElement=', modelElement, 'attributes=', attributes);

                    const element = viewWriter.createEmptyElement('span', attributes);

                    viewWriter.setCustomProperty(pluginName, true, element);
                    if (config?.customProperty) {
                        viewWriter.setCustomProperty(config.customProperty, true, element);
                    }

                    return element;
                },
            });

            // model → editing view
            conversion.for('editingDowncast').elementToElement({
                model: pluginName,
                view: (modelElement, { writer: viewWriter }) => {
                    const id = modelElement.getAttribute('id')!;
                    const uuid = modelElement.getAttribute('uuid')!;

                    const viewAttributes = {
                        class: `arg-ck-${pluginName} ${config?.containerElementClassName ?? ''}`,
                        draggable: 'true',
                        'data-id': id,
                        'data-arg-type': pluginName,
                        'data-uuid': uuid,

                        ...itemAttributes.reduce((acc, attributeName) => {
                            const value = modelElement.getAttribute(attributeName);
                            if (value === undefined) {
                                return acc;
                            }

                            if (attributeName === 'width') {
                                acc.style += `width: ${value};`;
                            }

                            acc[`data-${kebabCase(attributeName)}`] = value;

                            return acc;
                        }, { style: '' } as Record<string, any>),
                    } as Record<string, any>;

                    //                    console.log('editingDowncast', 'modelElement=', modelElement, 'attributes=', attributes);

                    if (viewAttributes['data-screenshot-width'] && viewAttributes['data-screenshot-height'] && !viewAttributes['data-exploration-id']) {
                        viewAttributes.style += `aspect-ratio: ${viewAttributes['data-screenshot-width']} / ${viewAttributes['data-screenshot-height']};`;
                    }

                    const detail = {
                        $itemType: pluginName,
                        $uuid: uuid,
                    };

                    debug('ModelElement=', modelElement, 'attributes=', viewAttributes, 'detail=', detail);

                    const section = viewWriter.createContainerElement(config?.containerElement || 'span', viewAttributes, {
                        isAllowedInsideAttributeElement: false,
                    });

                    viewWriter.setCustomProperty(pluginName, true, section);
                    if (config?.customProperty) {
                        viewWriter.setCustomProperty(config.customProperty, true, section);
                    }

                    const reactPortal = viewWriter.createRawElement('span', {
                        class: `arg-ck-${pluginName}-react-wrapper`,
                    }, (domElement) => {
                        const reactGlueEvent = new CustomEvent('arg-react-glue', {
                            bubbles: true,
                            detail,
                        });

                        viewWriter.document.once('layoutChanged', () => {
                            domElement.dispatchEvent(reactGlueEvent);
                        });
                    });

                    viewWriter.insert(viewWriter.createPositionAt(section, 0), reactPortal);

                    const ret: ViewElement = toWidget(section, viewWriter, {
                        label: pluginName,
                        hasSelectionHandle: config?.hasSelectionHandle,
                    });

                    config?.postEditingDowncast?.(this, viewWriter, section, ret, modelElement);

                    return ret;
                },
            });
        }
    };

    return clz;
}

export function createDragDropSupport(dndType: string) {
    return function (plugin: Plugin, config: Config, itemType: string, attributes: string[]) {
        const editor = plugin.editor;
        const view = editor.editing.view;
        const viewDocument = view.document;

        // Processing pasted or dropped content.
        plugin.listenTo(viewDocument, 'clipboardInput', (evt, data) => {
            if (data.content) {
                return;
            }

            const jsonData = data.dataTransfer.getData(dndType);

            debug('clipboardInput', dndType, 'dataTransfer=', data.dataTransfer, 'json=', jsonData);

            if (!jsonData) {
                return;
            }

            const explorationData = JSON.parse(jsonData);

            const attrs = {
                ...attributes.reduce((acc, name) => {
                    let value = explorationData[name];

                    if (isObject(value)) {
                        value = JSON.stringify(value);
                    }

                    acc[name] = value;

                    return acc;
                }, {} as Record<string, any>),
                id: `id${Date.now()}`,
                uuid: UUID.v4(),
            };

            debug('clipboardInput', dndType, 'explorationData=', explorationData, attrs);

            evt.stop();

            editor.model.change((writer) => {
                console.log(dndType, 'EXECUTE', `insert${capitalize(itemType)}`, { detail: attrs });

                if (data.targetRanges) {
                    writer.setSelection(data.targetRanges.map((viewRange: StaticRange) => {
                        return editor.editing.mapper.toModelRange(viewRange as any as Range);
                    }));
                }
                editor.execute(`insert${capitalize(itemType)}`, { detail: attrs });
            });
        });

        // Processing copied, pasted or dragged content.
        plugin.listenTo(viewDocument, 'clipboardOutput', (evt, data) => {
            debug('clipboardOutput', 'Data.content=', data.content);
            if (data.content.childCount !== 1) {
                return;
            }

            const rootElement = data.content.getChild(0);
            let viewElement = rootElement;
            for (; ;) {
                if (!viewElement.is('attributeElement')) {
                    break;
                }
                viewElement = viewElement.getChild(0);
            }
            debug('clipboardOutput', 'viewElement=', viewElement, 'itemType=', itemType);
            if (!viewElement?.getCustomProperty(itemType)) {
                return;
            }

            const transferData = attributes.reduce((acc, attributeName) => {
                let value = viewElement.getAttribute(`data-${kebabCase(attributeName)}`);

                if (typeof (value) !== 'string') {
                    return acc;
                }

                const convertType = config?.attributeTypes?.[attributeName];
                if (convertType === 'number') {
                    value = Number(value);
                } else if (convertType === 'boolean') {
                    value = Boolean(value);
                }

                acc[attributeName] = value;

                return acc;
            }, {} as Record<string, string | number | boolean>);

            debug('clipboardOutput', dndType, 'clipboardOutput ViewElement=', viewElement, 'transferData=', transferData);

            data.dataTransfer.setData(dndType, JSON.stringify(transferData));
        });
    };
}
