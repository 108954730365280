import { defineMessages } from 'react-intl';

import { AdvancedStyleLabel, AdvancedStyleType } from './graph-style';

export const messages = defineMessages({
    defaultProperties: {
        id: 'common.graph.customisation.default.properties',
        defaultMessage: 'Default properties',
    },
    displayProperty: {
        id: 'common.graph.customisation.display.property.label',
        defaultMessage: 'Display property',
    },
    size: {
        id: 'common.graph.customisation.display.size.label',
        defaultMessage: 'Size',
    },
    loadingMessage: {
        id: 'common.graph.customisation.loading',
        defaultMessage: 'Loading {threeDotsLoading}',
    },
    clusteringPropertyInputPlaceholder: {
        id: 'common.graph.customisation.clustering-property-placeholder',
        defaultMessage: 'Select a clustering property',
    },
    clusteringProperty: {
        id: 'common.graph.customisation.clustering-propeprty',
        defaultMessage: 'Clustering property',
    },
    clusteringNone: {
        id: 'common.graph.customisation.clustering-mode-none',
        defaultMessage: 'None',
    },
    clusteringType: {
        id: 'common.graph.customisation.clustering-mode-type',
        defaultMessage: 'By type',
    },
    clusteringProp: {
        id: 'common.graph.customisation.clustering-mode-property',
        defaultMessage: 'By property',
    },
    groupingType: {
        id: 'common.graph.customisation.chronogram-grouping-mode-type',
        defaultMessage: 'By type',
    },
    groupingProperty: {
        id: 'common.graph.customisation.chronogram-grouping-mode-property',
        defaultMessage: 'By property',
    },
    defaultGeographyPropertyInputPlaceholder: {
        id: 'common.graph.customisation.default-geography-property-placeholder',
        defaultMessage: 'Select a geography property',
    },
    groupingPropertyComboPlaceholderLabel: {
        id: 'common.graph.customisation.GroupingPropertyComboPlaceholderLabel',
        defaultMessage: 'Select a grouping property',
    },
    timePropertyComboPlaceholderLabel: {
        id: 'common.graph.customisation.TimePropertyComboPlaceholderLabel',
        defaultMessage: 'Select a time property',
    },
    geographicalProperty: {
        id: 'common.graph.customisation.default-geography-property',
        defaultMessage: 'Geography property',
    },
    groupingPropertyLabel: {
        id: 'common.graph.customisation.GroupingPropertyLabel',
        defaultMessage: 'Grouping property',
    },
    timePropertyLabel: {
        id: 'common.graph.customisation.TimePropertyLabel',
        defaultMessage: 'Time property',
    },
    edgeStyle: {
        id: 'common.graph.customisation.edge-style',
        defaultMessage: 'Style',
    },
    edgeAnimation: {
        id: 'common.graph.customisation.edge-animation',
        defaultMessage: 'Animation',
    },
    noVertices: {
        id: 'common.graph.customisation.no.vertex',
        defaultMessage: 'The visualization does not contain any Vertex',
    },
    noEdges: {
        id: 'common.graph.customisation.no.edge',
        defaultMessage: 'The visualization does not contain any Edge',
    },
    advancedColor: {
        id: 'common.graph.customisation.AdvancedColor',
        defaultMessage: 'Advanced color',
    },
    advancedIcon: {
        id: 'common.graph.customisation.AdvancedIcon',
        defaultMessage: 'Advanced icon',
    },
    advancedSize: {
        id: 'common.graph.customisation.AdvancedSize',
        defaultMessage: 'Advanced size',
    },
    advancedBadge: {
        id: 'common.graph.customisation.AdvancedBadge',
        defaultMessage: 'Advanced badge',
    },
    advancedStyle: {
        id: 'common.graph.customisation.AdvancedStyle',
        defaultMessage: 'Advanced style',
    },
    noAnimation: {
        id: 'common.graph.customisation.noAnimation',
        defaultMessage: 'No animation',
    },
    fast: {
        id: 'common.graph.customisation.fast',
        defaultMessage: 'Fast',
    },
    slow: {
        id: 'common.graph.customisation.slow',
        defaultMessage: 'Slow',
    },
    mainColor: {
        id: 'common.graph.customisation.MainColor',
        defaultMessage: 'Main color',
    },
    defaultEdgeDisplayPropertyPlaceholder: {
        id: 'common.graph.customisation.DefaultEdgeDisplayPropertyPlaceholder',
        defaultMessage: 'Link type (by default)',
    },
});

export const advancedStyleLabelMessageMapper: Record<AdvancedStyleType, AdvancedStyleLabel> = {
    color: 'advancedColor',
    icon: 'advancedIcon',
    size: 'advancedSize',
    badges: 'advancedBadge',
    style: 'advancedStyle',
};
