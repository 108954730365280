import { defineMessages } from 'react-intl';
import Debug from 'debug';
import { ReactNode } from 'react';

import { ArgonosModule } from 'src/components/application/modules';
import { ExtensionsRegistry } from 'src/framework/extensions/extensions-registry';
import { Tool, ToolType, ToolsRegistry } from '../../../components/basic';
import { ExplorationEnvironmentContext } from 'src/exploration/envrionment-contexts/exploration-environment-context';
import { ArgonosExtensionComponentTypeDescriptor, ExtensionComponent, ExtensionId } from 'src/framework/extensions/models';
import { ArgonosVisualizationExtensionComponentTypes } from 'src/exploration/extensions/types';

const debug = Debug('framework:extensions:ExtensionToolsRegistry');

const messages = defineMessages({
    explorationVisualizationViewTypeLabel: {
        id: 'exploration.visualization-extensions-registry.ExplorationVisualizationViewTypeLabel',
        defaultMessage: 'View',
    },
    explorationVisualizationPanelTypeLabel: {
        id: 'exploration.visualization-extensions-registry.ExplorationVisualizationPanelTypeLabel',
        defaultMessage: 'Panel',
    },
    explorationVisualizationToolTypeLabel: {
        id: 'exploration.visualization-extensions-registry.ExplorationVisualizationToolTypeLabel',
        defaultMessage: 'Tool',
    },
});

interface VisualizationExtensionDescriptor extends ArgonosExtensionComponentTypeDescriptor {
    contextId: string;
    type?: ToolType;
    path: (extensionName: string, name: string) => string;
    render: 'panelRender' | 'customRender';
}
// Used extensionName along with componentName in defining the path since this comobination is unique!
const ArgonosExtensionAnchorsMap = new Map<ArgonosVisualizationExtensionComponentTypes, VisualizationExtensionDescriptor>([
    ['exploration-visualization-view',
        {
            contextId: 'exploration-view:toolbar-context',
            type: 'editor',
            path: (extensionName, componentName) => `right/${extensionName}-${componentName}`,
            render: 'panelRender',
            label: messages.explorationVisualizationViewTypeLabel,

        },
    ],
    ['exploration-visualization-panel',
        {
            contextId: 'exploration-view:toolbar-context',
            type: 'panel',
            path: (extensionName, componentName) => `left/begin/${extensionName}-${componentName}`,
            render: 'panelRender',
            label: messages.explorationVisualizationPanelTypeLabel,

        },
    ],
    ['exploration-visualization-tool',
        {
            contextId: 'exploration-view:toolbar-context',
            type: 'custom',
            path: (extensionName, componentName) => `left/end/${extensionName}-${componentName}`,
            render: 'customRender',
            label: messages.explorationVisualizationToolTypeLabel,
        },
    ],
]);


interface Module {
    baseURL: string;
    argonosModule: ArgonosModule;
    components?: any[];
}

interface VisualzationComponent extends ExtensionComponent<ArgonosVisualizationExtensionComponentTypes> {
    render: (props: Tool<ExplorationEnvironmentContext>, env: ExplorationEnvironmentContext, module: Module) => ReactNode;
}

export class VisualizationExtensionsRegistry {
    private static instance: VisualizationExtensionsRegistry;

    static computeToolOptions = (extensionName: ExtensionId, component: VisualzationComponent): {contextId: string; tool: Tool<ExplorationEnvironmentContext>; renderKey: 'panelRender' | 'customRender'} | undefined => {
        const anchorTool = ArgonosExtensionAnchorsMap.get(component.type);

        if (!anchorTool) {
            return;
        }

        return {
            contextId: anchorTool.contextId,
            renderKey: anchorTool.render,
            tool: {
                path: anchorTool.path(extensionName, component.name),
                type: anchorTool.type,
                icon: component.icon,
                tooltip: component?.tooltip,
            },
        };
    };

    constructor() {
        ExtensionsRegistry.getInstance().onManifestExtensionPointDeclaration((extensionDescriptor, extensionPointDescriptor) => {
            if (!ArgonosExtensionAnchorsMap.has(extensionPointDescriptor?.type as ArgonosVisualizationExtensionComponentTypes)) {
                return;
            }

            const contextId = ArgonosExtensionAnchorsMap.get(extensionPointDescriptor.type as ArgonosVisualizationExtensionComponentTypes)?.contextId;

            if (!contextId) {
                console.error('Unhandled contextId. Please update the ArgonosExtensionAnchorsMap Map');

                return;
            }

            ToolsRegistry.getInstance().onceShownToolContext(contextId, async () => {
                debug('onceShownToolContext', 'contextId=', contextId);

                try {
                    await ExtensionsRegistry.getInstance().loadExtension(extensionDescriptor);
                } catch (error) {
                    console.error('Can not load extension at lazy loading', 'error=', error);
                }
            });
        });

        ExtensionsRegistry.getInstance().onExtensionPointSetupRegister<VisualzationComponent>([...ArgonosExtensionAnchorsMap.keys()], (event) => {
            const { extensionRuntime, component } = event;

            const extensionName = extensionRuntime.descriptor.name;
            const options = VisualizationExtensionsRegistry.computeToolOptions(extensionName, component);

            if (!options) {
                return;
            }

            const { descriptor: { baseURL, argonosModule, components } } = extensionRuntime;

            const module: Module = {
                baseURL, argonosModule, components,
            };

            ToolsRegistry.getInstance().registerToolItem(
                options.contextId,
                {
                    ...options.tool,
                    [options.renderKey]: ((props, env) => {
                        return component.render?.(
                            props,
                            env,
                            module,
                        );
                    }) as Tool<ExplorationEnvironmentContext>['panelRender'],
                },
            );

            event.setProcessed();
        });
    }

    static getInstance(): VisualizationExtensionsRegistry {
        if (!VisualizationExtensionsRegistry.instance) {
            VisualizationExtensionsRegistry.instance = new VisualizationExtensionsRegistry();
        }

        return VisualizationExtensionsRegistry.instance;
    }
}


export const registerVisualizationExtensionComponentTypes = () => {
    for (const [key, value] of ArgonosExtensionAnchorsMap) {
        ExtensionsRegistry.getInstance().addArgonosExtensionComponentType(key, value);
    }
};
