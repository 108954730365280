import { chain, CollectionChain, forEach, isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { ArgonosModule, ArgonosModuleId } from './modules';
import { hasAnyPermissions } from '../../contexts/user-permission';
import { UserPermissions } from '../../model/user';
import { EventEmitter, immutableEmptyArray, StateId } from '../basic';

export interface ArgonosModulesEventNames {
    onModuleEnabled: (argonosModule: ArgonosModule, enabled: boolean)=>void;
    onStartup: (argonosModule: ArgonosModule)=>void;
    onStartupCompleted: ()=>void;
}

export class ArgonosModulesRegistry extends EventEmitter<ArgonosModulesEventNames> {
    private static instance: ArgonosModulesRegistry;

    #modulesById: Record<ArgonosModuleId, ArgonosModule> = {};
    #registerEnabled: Record<ArgonosModuleId, boolean> = {};
    #startupModules: Record<ArgonosModuleId, true> = {};

    #permissions?: UserPermissions;
    #startupCompleted = false;

    static getInstance(): ArgonosModulesRegistry {
        if (!ArgonosModulesRegistry.instance) {
            ArgonosModulesRegistry.instance = new ArgonosModulesRegistry();
        }

        return ArgonosModulesRegistry.instance;
    }

    constructor() {
        super();
    }

    register(module: ArgonosModule): void {
        if (this.#modulesById[module.id]) {
            throw new Error('Module already registered');
        }

        this.#modulesById[module.id] = module;
        if (this.#registerEnabled[module.id]) {
            module.enabled = true;
        }
    }

    getById = (id: ArgonosModuleId): ArgonosModule | undefined => {
        const result = this.#modulesById[id];

        return result;
    };

    list = (): CollectionChain<ArgonosModule> => {
        const list = Object.values(this.#modulesById);

        const result = chain(list);

        return result;
    };

    listEnabled = (): CollectionChain<ArgonosModule> => {
        const list = Object.values(this.#modulesById);

        const result = chain(list).filter((argonosModule: ArgonosModule) => !!argonosModule.enabled);

        return result;
    };

    listAvailable = (permissions: UserPermissions): CollectionChain<ArgonosModule> => {
        const result = chain(this.#modulesById)
            .filter((argonosModule: ArgonosModule<any>) => {
                if (!this.#startupModules[argonosModule.id]) {
                    return false;
                }

                return true;
            });

        return result;
    };

    enable = (argonosModuleId: ArgonosModuleId): void => {
        const module = this.#modulesById[argonosModuleId];
        if (!module) {
            throw new Error(`Unknown module ${argonosModuleId}`);
        }

        if (this.#startupModules[argonosModuleId]) {
            return;
        }

        this.#registerEnabled[argonosModuleId] = true;
        this.#startupModules[argonosModuleId] = true;

        module.startup?.(this.#permissions!);

        this.emit('onStartup', module);
    };

    startup(permissions: UserPermissions): void {
        this.#permissions = permissions;

        forEach(this.#modulesById, (module: ArgonosModule) => {
            if (module.enabled === false) {
                return;
            }

            const isApplicationPermitted = !module.requiredPermissions
                || hasAnyPermissions<any>(permissions, ...module.requiredPermissions);

            console.log('Module starting', module.id, 'permitted=', isApplicationPermitted);
            if (!isApplicationPermitted) {
                return;
            }

            this.#startupModules[module.id] = true;

            module.startup?.(permissions);

            this.emit('onStartup', module);
        });

        this.#startupCompleted = true;
        this.emit('onStartupCompleted');
    }
}

export function useArgonosModulesRegistry() {
    const [stateId, setStateId] = useState<StateId>();

    useEffect(() => {
        function onStartup(argonosModule: ArgonosModule) {
            setStateId(ArgonosModulesRegistry.getInstance().stateId);
        }

        ArgonosModulesRegistry.getInstance().on('onStartup', onStartup);

        return () => {
            ArgonosModulesRegistry.getInstance().off('onStartup', onStartup);
        };
    }, []);
}

const NO_ARGONOS_MODULES = immutableEmptyArray<ArgonosModule>();

export function useAvailableArgonosModules(permissions: UserPermissions): Readonly<ArgonosModule[]> {
    const [available, setAvailable] = useState<Readonly<ArgonosModule[]>>(() => {
        const result = ArgonosModulesRegistry.getInstance().listAvailable(permissions).value();

        return result;
    });

    useEffect(() => {
        function onStartup(argonosModule: ArgonosModule) {
            const available = ArgonosModulesRegistry.getInstance().listAvailable(permissions).value();
            setAvailable(available);
        }

        const result = ArgonosModulesRegistry.getInstance().listAvailable(permissions).value();
        setAvailable((prev) => {
            if (isEqual(prev, result)) {
                return prev;
            }

            return result;
        });

        ArgonosModulesRegistry.getInstance().on('onStartup', onStartup);

        return () => {
            ArgonosModulesRegistry.getInstance().off('onStartup', onStartup);
        };
    }, [permissions]);

    return available;
}
