import { SettingsEnvironmentContext } from '../../settings/envrionment-contexts/settings-environment-context';
import { registerRolesToolItems } from '../../settings/roles';
import { registerConfigurationToolItems } from '../../settings/configuration';
import { registerExternalComponentsToolItems } from '../../settings/webhooks-external-components';
import { registerToolItem } from '../../components/basic';
import { ArgonosModule } from '../../components/application/modules';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../../settings/envrionment-contexts/context-names';
import { registerWebhookToolItems } from '../../settings/webhooks-external-components/index';

export function setupSettings(preparationModule: ArgonosModule) {
    registerToolItem<SettingsEnvironmentContext>(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path: 'modules/preparation',
        order: 200,
        type: 'group',
        icon: preparationModule.iconURL,
        visible: !!preparationModule.enabled,
        label: preparationModule.name,
    });
    registerRolesToolItems('modules/preparation/roles', 100, preparationModule);
    registerConfigurationToolItems('modules/preparation/configuration', 200, preparationModule.scope);
    registerWebhookToolItems('modules/preparation/webhook', 250, preparationModule);
    registerExternalComponentsToolItems('modules/preparation/extensions', 300);
}
