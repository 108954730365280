import { compact, isEmpty } from 'lodash';

import { Filter, FilterOperation, FilterOperationType } from './filter';
import { ProgressMonitor, SubProgressMonitor } from '../../components/basic';
import { FilterOperationKind, UniverseConnector } from '../utils/connector/universe-connector';
import { UniverseId } from './universe';

const NO_FILTER: FilterOperation = { filter: undefined };

const OPTIMIZE_FILTER = false; // SET to TRUE after 7/9/2023

export class FilterChain {
    readonly #operation: FilterOperation;

    static EMPTY_FILTER_CHAIN: FilterChain = new FilterChain(NO_FILTER);

    private constructor(operation: FilterOperation) {
        this.#operation = operation;
    }

    toFilterOperation() {
        return this.#operation;
    }

    union(...filters: (Filter | FilterOperation | undefined)[]): FilterChain {
        const newChain = this.chain('union', ...filters);

        return newChain;
    }

    substract(...filters: (Filter | FilterOperation | undefined)[]): FilterChain {
        const newChain = this.chain('subtract', ...filters);

        return newChain;
    }


    intersect(...filters: (Filter | FilterOperation | undefined)[]): FilterChain {
        const newChain = this.chain('intersect', ...filters);

        return newChain;
    }

    chain(operationType: FilterOperationType, ...filters: (Filter | FilterOperation | undefined)[]): FilterChain {
        const fs = compact(filters).filter((f) => {
            return f !== NO_FILTER && !isEmpty(f);
        });

        if (fs.length === 0) {
            return this;
        }

        // OO: On peut optimiser en recherchant UN SEUL filtre !
        if (OPTIMIZE_FILTER) {
            if (fs.length === 1) {
                if (isFilterOperation(fs[0])) {
                    return new FilterChain(fs[0]);
                }

                const operation: FilterOperation = {
                    filter: fs[0],
                };

                return new FilterChain(operation);
            }
        }

        const operations: FilterOperation[] = fs.map((filter: Filter | FilterOperation) => {
            if (isFilterOperation(filter)) {
                return filter;
            }
            const operation: FilterOperation = {
                filter,
            };

            return operation;
        });

        if (this.#operation !== NO_FILTER) {
            operations.unshift(this.#operation);
        }

        const operation = {
            operationType,
            operations,
        };

        const newChain = new FilterChain(operation);

        return newChain;
    }

    async value(universeId: UniverseId, kind: FilterOperationKind = 'Vertex', progressMonitor: ProgressMonitor): Promise<Filter> {
        async function compute(operation: FilterOperation): Promise<Filter> {
            if (operation.filter) {
                return operation.filter;
            }

            const filterPromises: Promise<Filter>[] = operation.operations!.map((operation: FilterOperation) => {
                const filter = compute(operation);

                return filter;
            });

            const filters = await Promise.all(filterPromises);

            const sub1 = new SubProgressMonitor(progressMonitor, 1);

            const ret = await UniverseConnector.getInstance().filterOperations(
                universeId,
                operation.operationType!,
                kind,
                filters,
                sub1,
            );

            return ret;
        }

        const ret = await compute(this.#operation);

        return ret;
    }

    static from(filter: Filter | FilterOperation): FilterChain {
        if (filter === NO_FILTER || isFilterOperation(filter)) {
            const chain = new FilterChain(filter as FilterOperation);

            return chain;
        }

        const operation = {
            filter,
        };
        const chain = new FilterChain(operation);

        return chain;
    }

    static fromFilterOperation(json: any) {
        if (!isFilterOperation(json)) {
            throw new Error('Invalid JSON');
        }

        const ret = new FilterChain(json);

        return ret;
    }
}

export function isFilterOperation(operation: any): operation is FilterOperation {
    if (!operation) {
        return false;
    }

    if ('filter' in operation) {
        return true;
    }

    if (('operationType' in operation) && ('operations' in operation)) {
        return true;
    }

    return false;
}

export function filterChain(filter?: Filter | FilterOperation): FilterChain {
    if (filter === undefined || filter === NO_FILTER) {
        return FilterChain.EMPTY_FILTER_CHAIN;
    }

    const ret = FilterChain.from(filter);

    return ret;
}
