import { ProgressMonitor, SubProgressMonitor } from 'src/components/basic';
import { createPatchRequest, JsonChange } from 'src/utils/connector';
import { BaseConnector } from 'src/utils/connectors/base-connector';
import {
    CreationTemplate,
    Template,
    TemplateId,
    TemplateParentId,
    RawTemplate,
} from './templates-types';
import { mapTemplate } from './mappers';

export class TemplateConnector extends BaseConnector {
    private static baseUrl = '/templates';

    async listTemplates<TContent>(templateType: string, parentId?: TemplateParentId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Template<TContent>[]> {
        const results: RawTemplate<TContent>[] = await this.request(
            TemplateConnector.baseUrl,
            {
                verifyJSONResponse: true,
                method: 'GET',
                params: {
                    templateType: templateType,
                    parentId: parentId,
                },
            },
            progressMonitor,
        );

        const templates = results.map(mapTemplate);

        return templates;
    }

    async listPublicTemplates<TContent>(templateType: string, parentId?: TemplateParentId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Template<TContent>[]> {
        const results: RawTemplate<TContent>[] = await this.request(
            `${TemplateConnector.baseUrl}/public`,
            {
                verifyJSONResponse: true,
                method: 'GET',
                params: {
                    templateType: templateType,
                    parentId: parentId,
                },
            },
            progressMonitor,
        );

        const templates = results.map(mapTemplate);

        return templates;
    }

    async getTemplate<TContent>(id: TemplateId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Template<TContent>> {
        const url = `${TemplateConnector.baseUrl}/${encodeURIComponent(id)}`;
        const result: RawTemplate<TContent> = await this.request(
            url,
            {
                verifyJSONResponse: true,
                method: 'GET',
            },
            progressMonitor,
        );

        const template = mapTemplate(result);

        return template;
    }

    async createTemplate<TContent>(templateToCreate: CreationTemplate<TContent>, setAsDefault: boolean, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Template<TContent>> {
        const p1 = new SubProgressMonitor(progressMonitor, 1);
        const result: RawTemplate<TContent> = await this.request(
            TemplateConnector.baseUrl,
            {
                verifyJSONResponse: true,
                method: 'POST',
                json: templateToCreate,
            },
            p1,
        );

        const template = mapTemplate(result);

        if (setAsDefault) {
            const p2 = new SubProgressMonitor(progressMonitor, 1);
            await this.setTemplateAsDefault(result.id, true, p2);
        }

        return template;
    }

    async deleteTemplate(id: TemplateId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const url = `${TemplateConnector.baseUrl}/${encodeURIComponent(id)}`;
        await this.request(
            url,
            {
                verifyJSONResponse: true,
                method: 'DELETE',
            },
            progressMonitor,
        );
    }

    async updateTemplate<TContent>(templateToUpdate: Template<TContent>, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const url = `${TemplateConnector.baseUrl}/${encodeURIComponent(templateToUpdate.id)}`;
        await this.request(
            url,
            {
                verifyJSONResponse: true,
                json: {
                    name: templateToUpdate.name,
                    description: templateToUpdate.description,
                    content: templateToUpdate.content,
                },
                method: 'PUT',
            },
            progressMonitor,
        );
    }

    async patchTemplate(templateId: TemplateId, changes: JsonChange[], comment?: string, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const url = `${TemplateConnector.baseUrl}/${encodeURIComponent(templateId)}`;
        const options = createPatchRequest(comment || '', ...changes);
        await this.request(
            url,
            options,
            progressMonitor,
        );
    }

    async setTemplateAsDefault(id: TemplateId, isDefault: boolean, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const url = `${TemplateConnector.baseUrl}/${encodeURIComponent(id)}/as-default`;
        await this.request(
            url,
            {
                verifyJSONResponse: true,
                params: {
                    isDefault: isDefault,
                },
                method: 'PUT',
            },
            progressMonitor,
        );
    }
}
