import { useCallback, useState } from 'react';
import { Form } from 'antd';
import { defineMessages } from 'react-intl';
import { Store } from 'rc-field-form/lib/interface';

import {
    ArgButton,
    ArgFormattedMessage,
    ArgInputText,
    ClassValue,
    ProgressMonitor,
    SubProgressMonitor,
    useCallbackAsync,
    useClassNames,
} from '../../basic';
import { Connector } from '../../../utils/connector';

import './login-form.less';


const messages = defineMessages({
    passwordPlaceholder: {
        id: 'common.login.form.PasswordPlaceHolder',
        defaultMessage: 'Password',
    },
    usernamePlaceholder: {
        id: 'common.login.form.IdentifierPlaceHolder',
        defaultMessage: 'Identifier',
    },
    rememberMe: {
        id: 'common.login.form.RememberMe',
        defaultMessage: 'Remember me',
    },
    forgetPassword: {
        id: 'common.login.form.ForgotPassword',
        defaultMessage: 'Forgot password?',
    },
    submitButton: {
        id: 'common.login.form.SubmitButton',
        defaultMessage: 'Continue',
    },
    loginLabel: {
        id: 'common.login.form.LoginLabel',
        defaultMessage: 'Login',
    },
    invalidPassword: {
        id: 'common.login.form.InvalidPassword',
        defaultMessage: 'The identifier or/and the password are incorrect',
    },
});


interface LoginFormProps {
    className?: ClassValue;
    onContinue: () => void;
}

export function LoginForm(props: LoginFormProps) {
    const { className, onContinue } = props;

    const [form] = Form.useForm();

    const [failedAttempt, setFailedAttempt] = useState(false);

    const classNames = useClassNames('common-login-form');

    const [finishHandler, loginProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor, formValues: Store) => {
        const sub1 = new SubProgressMonitor(progressMonitor, 1);

        try {
            const result = await Connector.getInstance().login__credentials__legacy(formValues.user.identifier, formValues.user.password, sub1);
            if (!result) {
                setFailedAttempt(true);

                return;
            }
            setFailedAttempt(false);

            onContinue();
        } catch (error) {
            console.error('Can not login', error);
            setFailedAttempt(true);

            throw error;
        }
    }, [onContinue]);

    const changeHandler = useCallback(() => {
        setFailedAttempt(false);
    }, []);

    return <>
        <div className={classNames('&-title')}>
            <ArgFormattedMessage message={messages.loginLabel} />
        </div>
        <Form
            form={form}
            size='large'
            layout='vertical'
            onFinish={finishHandler}
            onChange={changeHandler}
            className={classNames('&', className)}
        >
            {/* Identifier */}
            <Form.Item name={['user', 'identifier']} className={classNames('&-item')}>
                <ArgInputText
                    size='large'
                    autoFocus={true}
                    htmlAutoComplete='username'
                    performChangeOnInput={true}
                    placeholder={messages.usernamePlaceholder}
                    className={classNames('&-item-input', '&-item-input-login')}
                    state={failedAttempt ? 'invalid' : undefined}
                />
            </Form.Item>

            {/* Password */}
            <Form.Item name={['user', 'password']} className={classNames('&-item')}>
                <ArgInputText
                    size='large'
                    inputType='password'
                    htmlAutoComplete='current-password'
                    performChangeOnInput={true}
                    placeholder={messages.passwordPlaceholder}
                    className={classNames(
                        '&-item-input',
                        '&-item-input-password',
                    )}
                    state={failedAttempt ? 'invalid' : undefined}
                />
            </Form.Item>

            <div
                hidden={!failedAttempt}
                data-testid='error-message'
                className={classNames('&-item', '&-error-message')}
            >
                <ArgFormattedMessage message={messages.invalidPassword} />
            </div>

            <Form.Item className={classNames('&-item', '&-submit')}>
                <ArgButton
                    data-testid='login-button'
                    size='large'
                    htmlType='submit'
                    disabled={loginProgressMonitor?.isRunning}
                    loading={loginProgressMonitor?.isRunning}
                    className={classNames('&-item', '&-submit-button')}
                    label={messages.submitButton}
                />
            </Form.Item>
        </Form>
    </>;
}
