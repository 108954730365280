import { defineMessages } from 'react-intl';
import { useContext, useMemo, useState } from 'react';

import { ArgButton, ArgMenu, ArgMenuItem, ArgMessageRenderer, ProgressMonitor, SubProgressMonitor, useArgModalContext, useArgNotifications, useCallbackAsync, useClassNames } from 'src/components/basic';
import { ConfirmModal } from '../../../../components/common/modal2/confirm-modal/confirm-modal';
import { useHasPermission } from '../../../../contexts/user-permission';
import { SettingsPermissions } from '../../../permissions/permissions';
import { Group } from '../../../../model/user';
import { UsersAndGroupsStateContext } from '../../../users/providers/usersState';
import { UserGroupsConnector } from '../../../connectors/user-groups-connector';


const CLASSNAME = 'settings-groups-actions-button-menu';
const GROUPS_DELETE_MODAL_ID = 'GROUPS_DELETE_MODAL_ID';

const messages = defineMessages({
    actions: {
        id: 'settings.groups-actions-button.menu.actions',
        defaultMessage: 'Actions',
    },
    delete: {
        id: 'settings.groups-actions-button.menu.delete',
        defaultMessage: 'Delete',
    },
    deleteGroupErrorMsg: {
        id: 'settings.groups-actions-button.menu.deleteGroupErrorMsg',
        defaultMessage: 'An error occurred while deleting the groups',
    },
    deleteConfirmTitle: {
        id: 'setting.groups-actions-button.menu.deleteConfirmTitle',
        defaultMessage: '{count, plural, =1 {Delete group} other {Delete groups}}',
    },
    deleteConfirmMessage: {
        id: 'settings.groups-actions-button.menu.deleteConfirmMessage',
        defaultMessage: '{count, plural, =1 {Are you sure you want to delete \"{name}\" ?} other {Are you sure you want to delete these {count} groups ?}}',
    },
});

export interface GroupsActionsButtonMenuProps {
    selectedGroups: Group[];
}

export function GroupsActionsButtonMenu(props: GroupsActionsButtonMenuProps) {
    const {
        selectedGroups,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const [visible, setVisible] = useState<boolean>(false);

    const notifications = useArgNotifications();

    const modalContext = useArgModalContext();

    const { setGroups } = useContext(UsersAndGroupsStateContext);

    const canEditGroups = useHasPermission<SettingsPermissions>('admin.user.group.edition');

    const [handleDeleteGroups] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const promises = selectedGroups.map(async (group) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);

            const p = UserGroupsConnector.getInstance().deleteGroup(group.id, sub);

            return p;
        });

        try {
            await Promise.all(promises);
            const selectedGroupsIds = new Set(selectedGroups.map((group) => group.id));
            setGroups((currentGroups) => currentGroups.filter((group) => !selectedGroupsIds.has(group.id)));
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.deleteGroupErrorMsg }, error as Error);
            throw error;
        }
    }, [selectedGroups, setGroups, notifications]);

    const groupsDeletedName = selectedGroups.length === 1 ? selectedGroups[0].name : undefined;

    const actions = useMemo(() => [
        {
            key: 'delete',
            label: messages.delete,
            disabled: !canEditGroups,
            onClick: () => {
                modalContext.open(GROUPS_DELETE_MODAL_ID, (
                    <ConfirmModal
                        type='delete'
                        title={messages.deleteConfirmTitle}
                        alertMessage={messages.deleteConfirmMessage}
                        messageValues={{ count: selectedGroups.length, name: groupsDeletedName }}
                        onClose={() => modalContext.close(GROUPS_DELETE_MODAL_ID)}
                        onConfirm={handleDeleteGroups}
                    />
                ));
            },
        },
    ], [canEditGroups, modalContext, selectedGroups.length, groupsDeletedName, handleDeleteGroups]);

    const actionsMenu = (
        <ArgMenu>
            {actions.map((action) => (
                <ArgMenuItem
                    key={action.key}
                    onClick={() => {
                        action.onClick();
                        setVisible(!visible);
                    }}
                    disabled={action.disabled}
                >
                    <ArgMessageRenderer message={action.label} />
                </ArgMenuItem>
            ))}
        </ArgMenu>
    );

    return (
        <ArgButton
            size='medium'
            type='secondary'
            right='dropdown'
            label={messages.actions}
            disabled={selectedGroups.length === 0}
            popover={visible && actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            className={classNames('&')}
            data-testid='actions-menu'
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            popoverClassName={classNames('&-popover')}
        />
    );
}
