import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { chain, isEqual, isString } from 'lodash';
import Debug from 'debug';

import { BasicState, StateId } from './basic-state';
import { StateRecord, StatesRegistry, StatesRegistryContext } from './states-registry';

const debug = Debug('utils:rt-states:UseBasicStates');

export interface BasicStates<T> {
    readonly records: StateRecord<T>[];
    stateId: StateId;
}

function computeBasicStates<T extends BasicState, K>(factory: (url: string, key: K) => T, registry: StatesRegistry, keys: Readonly<(K | undefined)[]>, computeUrl?: (key: K) => (string | undefined)): BasicStates<T> {
    const records = chain(keys).map((key: K | undefined): ({ key: K; url: string } | undefined) => {
        if (!key) {
            return undefined;
        }

        const url = computeUrl ? computeUrl(key) : key;

        if (!isString(url)) {
            return undefined;
        }

        return { url, key };
    }).compact().map(({ url, key }): StateRecord<T> => {
        const [stateRecord] = registry.get(url, () => factory(url, key));

        return stateRecord;
    }).value();

    const ret: BasicStates<T> = {
        records,
        stateId: {
            id: records.map((record) => record.state.stateId),
            url: `composite:${records.map((record) => record.state.url).join(',')}`,
        },
    };

    return ret;
}

export function useBasicStates<T extends BasicState, K>(factory: (url: string, key: K) => T, keys: Readonly<(K | undefined)[]> | undefined, computeUrl?: (key: K) => (string | undefined)): BasicStates<T> | undefined {
    const [stateId, setStateId] = useState<StateId | undefined>(undefined);
    const unmoutedRef = useRef<boolean>(false);

    const registry = useContext(StatesRegistryContext);

    const cachedRecordsRef = useRef<BasicStates<T>>();

    const basicStates = useMemo<BasicStates<T> | undefined>(() => {
        if (!registry || !keys) {
            return undefined;
        }

        const ret = computeBasicStates<T, K>(factory, registry, keys, computeUrl);

        if (cachedRecordsRef.current) {
            if (isEqual(ret.stateId.id, cachedRecordsRef.current.stateId.id)) {
                return cachedRecordsRef.current;
            }
        }

        cachedRecordsRef.current = ret;

        return ret;
    }, [keys]);

    useEffect(() => {
        return () => {
            unmoutedRef.current = true;
        };
    }, []);

    useEffect(() => {
        if (!basicStates) {
            return;
        }

        const fct = (property: any, value: any, url: string) => {
            debug('HandleMessage', 'property=', property, 'value=', value, 'url=', url);
            if (unmoutedRef.current) {
                return;
            }

            const newId = basicStates.records.map((record) => record.state.stateId);
            if (isEqual(newId, basicStates.stateId)) {
                console.error('*** No modification ?');

                return;
            }

            const newStateId: StateId = {
                ...basicStates.stateId,
                id: newId,
            };

            basicStates.stateId = newStateId;

            debug('UpdateState', 'newStateId=', newStateId);
            setStateId(newStateId);
        };

        const records = basicStates.records;
        records.forEach((stateRecord) => {
            stateRecord.state.on('Change', fct);
        });

        return () => {
            records.forEach((stateRecord) => {
                stateRecord.state.off('Change', fct);
            });

            records.forEach((stateRecord) => {
                stateRecord.unregister().catch((error) => {
                    console.error(error);
                });
            });
        };
    }, [basicStates]);

    return basicStates;
}

