import * as uuid from 'uuid';
import { defineMessages } from 'react-intl';
import { filter, find, findIndex, includes, isEmpty, map, omit, size } from 'lodash';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgImage } from '../arg-image/arg-image';
import { ArgUploaderContext } from './arg-uploader-context';
import { useClassNames } from '../arg-hooks/use-classNames';
import { TopBarProgress } from '../arg-loading/top-bar-progress';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { useEffectAsync } from '../arg-hooks/use-effect-async';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { immutableUpdate } from '../utils/immutable-set';
import { NotificationKey } from '../arg-notifications/types';
import { useArgNotifications } from '../arg-notifications/notifications';
import { ArgNotificationDescription } from '../arg-notifications/use-arg-notifications';

import './arg-uploader-context-provider.less';

export type UploadMethod = (file: Blob, progressMonitor: ProgressMonitor, information?: UploadRequestInformation) => Promise<any>;

export type UploadRequestInformation = Record<string, any>

export interface UploadRequest {
    files: Blob[];
    uploadMessages?: UploadMessages;
    information?: UploadRequestInformation;
    method: UploadMethod;
}


export interface QueuedItem {
    uuid: string;
    file: File;
    uploadMessages?: UploadMessages;
    information?: UploadRequestInformation;
    method: UploadMethod;
    preview?: string;
    status: 'done' | 'cancelled' | 'failed' | 'uploading' | 'waiting';
    error?: Error;
}

export interface UploadedItem {
    uploadMessages?: UploadMessages;
    information?: UploadRequestInformation;
    uuid: string;
    response: any;
}

export interface UploadMessages {
    failureTitle?: ArgRenderedText;
    failureDescription?: ArgRenderedText;
    uploadingTitle?: ArgRenderedText;
    uploadingDescription?: ArgRenderedText;
    waitingTitle?: ArgRenderedText;
    waitingDescription?: ArgRenderedText;
    successTitle?: ArgRenderedText;
    successDescription?: ArgRenderedText;
}

const messages = defineMessages({
    failureTitle: {
        id: 'basic.arg-uploader-context-provider.failure.title',
        defaultMessage: '{failedCount, plural, =1 {Upload} other {Uploads}} failed',
    },
    failureDescription: {
        id: 'basic.arg-uploader-context-provider.failure.description',
        defaultMessage: '{failedCount} {failedCount, plural, =1 {item} other {items}} in error{doneCount, plural, =0 {} other {, {doneCount} {doneCount, plural, =1 {item} other {items}} uploaded}}',
    },
    uploadingTitle: {
        id: 'basic.arg-uploader-context-provider.uploading.title',
        defaultMessage: '{fileName}',
    },
    uploadingDescription: {
        id: 'basic.arg-uploader-context-provider.uploading.description',
        defaultMessage: 'Uploading <loader></loader>',
    },
    waitingTitle: {
        id: 'basic.arg-uploader-context-provider.waiting.title',
        defaultMessage: 'Preparing {waitingCount, plural, =1 {upload} other {uploads}}',
    },
    waitingDescription: {
        id: 'basic.arg-uploader-context-provider.waiting.description',
        defaultMessage: 'Preparing the upload of {waitingCount} {waitingCount, plural, =1 {item} other {items}}',
    },
    successTitle: {
        id: 'basic.arg-uploader-context-provider.success.title',
        defaultMessage: '{queueCount, plural, =1 {Upload} other {Uploads}} complete',
    },
    successDescription: {
        id: 'basic.arg-uploader-context-provider.success.description',
        defaultMessage: '{doneCount} {doneCount, plural, =1 {item} other {items}} uploaded',
    },
});

export interface ArgUploaderEngine {
    get: () => QueuedItem | QueuedItem[];
    add: (request: UploadRequest) => void;
    clear: () => void;
    clearUploaded: (predicate?: (uploadedItem: UploadedItem) => boolean) => void;
    queue: QueuedItem[];
    current?: QueuedItem;
    uploaded: UploadedItem[];
}

export interface ArgUploaderProviderProps {
    children?: ReactNode;
}

export function ArgUploaderProvider(props: ArgUploaderProviderProps) {
    const { children } = props;

    const [queue, setQueue] = useState<QueuedItem[]>([]);
    const [uploaded, setUploaded] = useState<UploadedItem[]>([]);

    const notifications = useArgNotifications();

    const notificationsKeyRef = useRef<Record<string, NotificationKey>>();

    // Get the current item (first item that is not done)
    const current: QueuedItem | undefined = useMemo(() => {
        const ret = find(queue, (queuedItem) => (
            includes(['waiting', 'uploading'], queuedItem.status)
        ));

        return ret;
    }, [queue]);

    const classNames = useClassNames('arg-uploader-context-provider');

    //const currentProgressMonitorRef = useRef<ProgressMonitor>();

    const handleAdd = useCallback((request: UploadRequest) => {
        setQueue((queue = []) => {
            return ([
                ...queue,

                // Add the request to the queue as a Queued Item
                ...map(request.files, (file) => {
                    const item: QueuedItem = {
                        ...omit(request, 'files'),
                        file: file as File,
                        uuid: uuid.v4(),
                        status: 'waiting',
                        preview: /^(image)\//.test(file.type) && URL.createObjectURL(file) || undefined,
                    };

                    return item;
                }),
            ]);
        });
    }, []);

    // Cancel every progress monitors and clear the queue
    const handleClear = useCallback(() => {
        // Clear the queue
        setQueue((queue) => {
            queue.forEach((queuedItem) => {
                // Clear the preview object url
                queuedItem.preview && URL.revokeObjectURL(queuedItem.preview);

                const notificationId = notificationsKeyRef.current?.[queuedItem.uuid];
                if (notificationId) {
                    delete notificationsKeyRef.current?.[queuedItem.uuid];

                    notifications.notifClose(notificationId);
                }
            });

            return [];
        });
    }, [notifications]);

    // Clear uploaded items for which predicate returns truthy or every uploaded item if predicate isn't provided
    const handleClearUploaded = useCallback((predicate?: (uploadedItem: UploadedItem) => boolean) => {
        // Clear the queue
        setUploaded((uploaded) => {
            const ret = (predicate
                ? uploaded.filter((uploadedItem) => !predicate(uploadedItem))
                : []
            );

            return ret;
        });
    }, []);

    // Function that get queue w/ or w/o filtering
    const handleGet = useCallback((): QueuedItem | QueuedItem[] => {
        return queue;
    }, [queue]);

    const [currentProgressMonitor] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        if (!current) {
            return;
        }

        const file = current.file;

        try {
            const response = await current.method(file, progressMonitor, current.information);
            // Keep the uploaded files in the state
            setUploaded((uploaded) => {
                const ret = ([
                    ...uploaded,
                    {
                        ...omit(current, ['file', 'preview', 'status', 'error', 'method']),
                        response,
                    },
                ]);

                return ret;
            });

            // Update the queue
            setQueue((prevQueue) => {
                const index = findIndex(prevQueue, { uuid: current.uuid });

                if (index < 0) {
                    return prevQueue;
                }

                const newQueue: QueuedItem[] = immutableUpdate(prevQueue, [index], (prev: QueuedItem) => {
                    return {
                        ...prev,
                        status: 'done',
                    };
                });

                return newQueue;
            });
        } catch (error) { // Catch the api error
            if (progressMonitor.isCancelled) {
                throw error;
            }

            setQueue((prevQueue) => {
                const index = findIndex(prevQueue, { uuid: current.uuid });

                if (index < 0) {
                    return prevQueue;
                }

                const newQueue: QueuedItem[] = immutableUpdate(prevQueue, [index], (prev: QueuedItem) => {
                    return {
                        ...prev,
                        error: error as Error,
                        status: 'failed',
                    };
                });

                return newQueue;
            });
            throw error;
        }
    }, [current], current?.file?.name, 1);

    useEffect(() => {
        if (isEmpty(queue)) {
            return;
        }

        // Manage the notification of uploads
        const done = filter(queue, { status: 'done' });
        const waiting = filter(queue, { status: 'waiting' });
        const failed = filter(queue, { status: 'failed' });

        const icon = size(waiting)
            ? current
                ? current.preview
                    ? <ArgImage
                        src={current.preview}
                        alt={current.file.name}
                        fit='cover'
                        className={classNames('&-notification-icon')}
                    />
                    : <ArgIcon
                        name='icon-upload'
                        className={classNames('&-notification-icon')}
                        color='primary'
                    />
                : <ThreeDotsLoading className={classNames('&-notification-icon')} />
            : undefined;

        const notificationMethod = size(waiting)
            ? 'info'
            : size(failed)
                ? 'error'
                : 'success';
        const intlParams: ArgMessageValues = {
            doneCount: size(done),
            queueCount: size(queue),
            failedCount: size(failed),
            waitingCount: size(waiting),
            loader: function Loader() {
                return currentProgressMonitor?.isRunning && (<ThreeDotsLoading />);
            },
            fileName: current?.file.name,
        };

        const description = (size(waiting) && current)
            ? (<TopBarProgress className={classNames('&-notification-progress')} visible={true} />)
            : (size(waiting) && !current)
                ? (waiting[0]?.uploadMessages?.waitingDescription ?? messages.waitingDescription)
                : size(failed)
                    ? (failed[0]?.uploadMessages?.failureDescription ?? messages.failureDescription)
                    : (done[0]?.uploadMessages?.successDescription ?? messages.successDescription);

        const message = size(waiting)
            ? current
                ? (waiting[0]?.uploadMessages?.uploadingTitle ?? messages.uploadingTitle)
                : (waiting[0]?.uploadMessages?.waitingTitle ?? messages.waitingTitle)
            : size(failed)
                ? (failed[0]?.uploadMessages?.failureTitle ?? messages.failureTitle)
                : (done[0].uploadMessages?.successTitle ?? messages.successTitle);

        // Manage the closing of the notification when queue is not empty
        const allIsUploaded = isEmpty(waiting);

        const notificationDescription: ArgNotificationDescription = {
            key: current ? notificationsKeyRef.current?.[current?.uuid!] : 'uploader-context-notification-key',
            description,
            message,
            icon,
            onClick: () => {},
            onClose: () => {
                allIsUploaded && handleClear();
            },
        };

        let notificationKey: NotificationKey;
        switch (notificationMethod) {
            case 'error':
                notificationKey = notifications.notifError(notificationDescription, undefined, intlParams);
                break;
            case 'success':
                notificationKey = notifications.notifSuccess(notificationDescription, intlParams);
                break;
            case 'info':
                notificationKey = notifications.notifInfo(notificationDescription, intlParams);
                break;
        }

        if (allIsUploaded) {
            if (notificationKey) {
                notifications.notifClose(notificationKey);
            }

            if (notificationsKeyRef.current) {
                Object.values(notificationsKeyRef.current).forEach((notificationKey) => {
                    notifications.notifClose(notificationKey);
                });

                notificationsKeyRef.current = undefined;
            }

            return;
        }

        if (current) {
            if (!notificationsKeyRef.current) {
                notificationsKeyRef.current = {};
            }

            notificationsKeyRef.current[current.uuid!] = notificationKey;

            return;
        }
    }, [current, queue]);

    useEffect(() => {
        // Clear the queue on component unmount
        handleClear();
    }, []);

    const providerValue = useMemo(() => {
        return {
            queue,
            current,
            uploaded,
            get: handleGet,
            add: handleAdd,
            clear: handleClear,
            clearUploaded: handleClearUploaded,
        };
    }, [current, handleAdd, handleClear, handleClearUploaded, handleGet, queue, uploaded]);

    return (
        <ArgUploaderContext.Provider value={providerValue}>
            {children}
        </ArgUploaderContext.Provider>
    );
}
