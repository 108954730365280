import { defineMessages } from 'react-intl';

import { ArgRenderedIcon, ArgRenderedText, BasicTag, ClassValue, useClassNames } from 'src/components/basic';

import './result-tag.less';

const CLASSNAME = 'common-result-tag';

const messages = defineMessages({
    failed: {
        id: 'common.result-tag.failed',
        defaultMessage: 'Failed',
    },
    success: {
        id: 'common.result-tag.success',
        defaultMessage: 'Success',
    },
});

interface ResultTagProps {
    className?: ClassValue;
    hasFailed?: boolean;
    label?: ArgRenderedText;
    tooltip?: ArgRenderedText;
    icon?: ArgRenderedIcon | false;
}

export function ResultTag(props: ResultTagProps) {
    const {
        className,
        hasFailed,
        label,
        tooltip,
        icon,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const cls = {
        failed: hasFailed,
        succeed: !hasFailed,
    };

    let _icon = icon;
    if (_icon === undefined) {
        _icon = (hasFailed ? 'icon-close-solid' : 'icon-checkmark-outline');
    }

    return (
        <BasicTag
            icon={_icon}
            label={label || (hasFailed ? messages.failed : messages.success)}
            tooltip={tooltip || label || (hasFailed ? messages.failed : messages.success)}
            className={classNames('&', className, cls)}
        />
    );
}
