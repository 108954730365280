import { defineMessages } from 'react-intl';

import { NavItem } from '../models/navigation';
import { ConfigurationsScope } from './configuration-type';
import { registerToolItem, Tool } from '../../components/basic';
import { SettingsEnvironmentContext } from '../envrionment-contexts/settings-environment-context';
import { SETTINGS_ROOT_URL } from '../../components/containers/routes-urls';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../envrionment-contexts/context-names';

const messages = defineMessages({
    defaultPreferences: {
        id: 'settings.users.menu.defaultPreferences',
        defaultMessage: 'Default preferences',
    },
});

export function configurationNavItems(configurationScope?: ConfigurationsScope) {
    if (!configurationScope) {
        return [];
    }

    const navItem: NavItem = {
        path: `/settings/${encodeURIComponent(configurationScope)}/preferences`,
        label: messages.defaultPreferences,
        icon: 'icon-file-table-box-outline',
        iconSize: 20,
        children: [],
    };

    return [navItem];
}

export function registerConfigurationToolItems(path: string, order: number, configurationScope?: ConfigurationsScope): void {
    if (!configurationScope) {
        return;
    }

    registerToolItem(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path,
        order,
        label: messages.defaultPreferences,
        icon: 'icon-file-table-box-outline',
        visible: !!configurationScope,
        onClick: (tool: Tool<SettingsEnvironmentContext>, environmentContext: SettingsEnvironmentContext) => {
            const url = `${SETTINGS_ROOT_URL}/${encodeURIComponent(configurationScope!)}/preferences`;

            environmentContext.navigate(url);
        },
    });
}
