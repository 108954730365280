import { KeyboardEvent } from 'react';
import { findIndex, forEach, isArray, isEmpty, isFunction, isNumber, isString, pick } from 'lodash';
import Debug from 'debug';
import shallowequal from 'shallowequal';

import {
    isToolDisabled,
    isToolSelected,
    isToolVisible,
    Tool,
    ToolChanges,
    ToolItemWithPropsGenerator,
    ToolPath,
} from './tool';
import { EventEmitter } from '../utils/event-emitter';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { deepDifference } from '../../../utils/deep-difference';
import { immutableSet, immutableUpdate } from '../utils/immutable-set';
import { ToolsRegistry } from './tools-registry';
import { isPromise } from '../utils/promise';
import { $yield } from '../utils/yield';


const debug = Debug('common:basic:ToolContext');

const debugDifference = debug.extend('LogDifference');

export type ToolContextId = string;

export interface ToolContextEventTypes<T> {
    ItemAdded: (tool: Tool<T>) => void;
    ItemsFetched: (tool: Tool<T>, children: Tool<T>[]) => void;
    ItemRemoved: (tool: Tool<T>) => void;
    ItemUpdated: (tool: Tool<T>, changes: ToolChanges<T>) => void;

    NewEnvironmentContext: (newEnvironment: T | undefined) => void;

    OnShow: () => void; // For context-menu
    OnHide: () => void; // For context-menu
}

export interface ToolTreeNode<T = undefined> extends Tool<T> {
    children?: ToolTreeNode<T>[];
}

interface ToolTreeState<T> {
    nodes: Tool<T>[];
    stateId: number;
    progressMonitor?: ProgressMonitor;
    error?: Error;
}

export interface ToolTreeContext<T> {
    fetchedTools: Record<string, ToolTreeState<T>>;
}

export class ToolContext<T = undefined> extends EventEmitter<ToolContextEventTypes<T>> {
    #tools: Tool<T>[] = [];
    #cachedTree?: ToolTreeNode<T>[];
    readonly #id: ToolContextId;
    #nodeStateIds: Record<string, number> = {};
    #mountCount = 0;
    #currentEnvironmentContext: T | undefined;

    constructor(idOrClone: ToolContextId|ToolContext<T>) {
        super({ id: 0, url: `toolContext:${isString(idOrClone) ? idOrClone : idOrClone.id}` });

        if (isString(idOrClone)) {
            this.#id = idOrClone;
            ToolsRegistry.getInstance().appendRegisteredToolItems(this.#tools, this.#id);
        } else {
            this.#id = idOrClone.#id;
            this.#tools = idOrClone.#tools;
        }

        ToolsRegistry.getInstance().onNewRegisteredTool(this.#id, this.newRegisteredToolItems);
    }

    get id(): ToolContextId {
        return this.#id;
    }

    addItem(tool: Tool<T> | ToolItemWithPropsGenerator<T>, changes?: Record<string, any>): void {
        const existingItem = this.#tools.find((item) => item.path === tool.path);

        if (existingItem && !isNumber(tool.override)) {
            if (existingItem.type === tool.type && existingItem.order === tool.order) {
                switch (existingItem.type) {
                    case 'marker':
                    case 'group':
                    case 'separator':
                        return;
                }
            }
            throw new Error(`A tool with the same path must have a positioned override, path=${tool.path}`);
        }

        if (changes) {
            tool = { ...tool, ...changes };
        }

        this.#cachedTree = undefined;
        this.#tools.push({
            ...tool,
        });

        this.updateStateId();
        this.emit('ItemAdded', tool);
    }

    removeItem(tool: Tool<T> | ToolItemWithPropsGenerator<T>): boolean {
        const newList = this.#tools.filter((item) => item.path !== tool.path || item.override !== tool.override);

        if (newList.length === this.#tools.length) {
            return false;
        }

        this.#tools = newList;
        this.#cachedTree = undefined;

        this.updateStateId();
        this.emit('ItemRemoved', tool);

        return true;
    }

    updateItem(toolOrPath: string | Tool<T> | ToolItemWithPropsGenerator<T>, changes: ToolChanges<T>): boolean {
        const tool = isString(toolOrPath) ? { path: toolOrPath } : toolOrPath;

        const itemIndex = findIndex(this.#tools, pick(tool, 'path', 'override'));

        if (itemIndex < 0) {
            console.error('Could not find the requested item:', tool, this.#tools);

            return false;
        }

        const item = this.#tools[itemIndex];

        const newItem: Tool<T> = { ...item, ...changes };

        if (shallowequal(item, newItem)) {
            return false;
        }

        this.#nodeStateIds[item.path] = (this.#nodeStateIds[item.path] || 0) + 1;
        this.#tools[itemIndex] = newItem;
        this.#cachedTree = undefined;

        if (debugDifference.enabled) {
            debugDifference('Update item', 'path=', item.path, '=>', this.#nodeStateIds[item.path]);

            const d = deepDifference(item, newItem, 1);
            debugDifference('  diff=', d);
        }

        this.updateStateId();
        this.emit('ItemUpdated', item, changes);

        return true;
    }

    updateEnvironment(newEnvironmentContext: T) {
        if (shallowequal(newEnvironmentContext, this.#currentEnvironmentContext)) {
            return;
        }

        this.#currentEnvironmentContext = newEnvironmentContext;
        this.updateStateId();
        $yield(() => {
            this.emit('NewEnvironmentContext', newEnvironmentContext);
        });
    }

    togglePanel(environmentContext: T, tool: Tool<T>, prefix?: string): boolean {
        const toolPath = tool.path;

        // Toggle the panel
        const items = this.#tools.filter(t => t.path === toolPath);

        if (items.length === 0) {
            console.error('Could not find the requested item:', tool, this.#tools);

            return false;
        }

        for (const item of items) {
            const selected = !isToolSelected(item, environmentContext);
            this.updateItem(item, { selected });
        }

        // Close other panels
        this.#tools.forEach((item) => {
            // Do not pick the toggled item
            if (item.path === toolPath || !item.path) {
                return;
            }

            // Pick toolbar items by prefix
            if (prefix && !item.path.startsWith(prefix)) {
                return;
            }

            // Pick toolbar items by type
            if (item.type !== 'panel') {
                return;
            }

            this.updateItem(item, { selected: false });
        });

        return true;
    }

    toggleEditor(tool: Tool<T>): boolean {
        const toolPath = tool.path;

        // Toggle the panel
        const items = this.#tools.filter(t => t.path === toolPath);

        if (items.length === 0) {
            console.error('Could not find the requested item:', tool, this.#tools);

            return false;
        }

        for (const item of items) {
            this.updateItem(item, { selected: !item.selected });
        }

        // Close other panels
        this.#tools.forEach((item) => {
            // Do not pick the toggled item
            if (item.path === toolPath) {
                return;
            }

            // Pick toolbar items by type
            if (item.type !== 'editor') {
                return;
            }

            this.updateItem(item, { selected: false });
        });

        return true;
    }

    clear() {
        const tools = this.#tools;

        this.#tools = [];
        this.#cachedTree = undefined;
        this.#nodeStateIds = {};

        this.updateStateId();
        tools.forEach((item) => {
            this.emit('ItemRemoved', item);
        });
    }

    computeTree(environmentContext: T, context?: ToolTreeContext<T>, prefix?: string, removeEditors?: boolean): ToolTreeNode<T>[] {
        const root: ToolTreeNode<T> = {
            path: '',
            type: 'group',
            children: [],
        };

        const items = removeDuplicate(this.#tools, environmentContext);

        if (environmentContext) {
            items.forEach((item: Tool<T>, itemIndex: number) => {
                const fct = (item as ToolItemWithPropsGenerator<T>)?.__internal_propsGenerator;
                if (!isFunction(fct)) {
                    return;
                }

                const changes = fct(environmentContext);
                const newItem = { ...item, ...changes };

                if (shallowequal(item, newItem)) {
                    return;
                }
                items[itemIndex] = newItem;
                this.#cachedTree = undefined;
            });
        }

        const hiddenTools: Record<ToolPath, true> = {};

        for (; items.length;) {
            const item = items.shift()!;

            // Pick toolbar items by prefix
            if (prefix && !item.path.startsWith(prefix)) {
                continue;
            }

            if (removeEditors && item.type === 'editor') {
                continue;
            }

            const toolVisible = isToolVisible(item, environmentContext);
            if (!toolVisible) {
                hiddenTools[item.path] = true;
            }

            if (toolVisible && context && item.computeChildren) {
                const nodeStateId = this.#nodeStateIds[item.path] || 0;
                const computedItems = context.fetchedTools[item.path];

                if (computedItems === undefined || computedItems.stateId !== nodeStateId) {
                    // console.log('STATEID CHANGED', computedItems?.stateId, nodeStateId, item.path);
                    computedItems?.progressMonitor?.cancel();

                    const progressMonitor = new ProgressMonitor(`Fetching ${item.path} items`, 1);

                    context.fetchedTools = immutableSet(context.fetchedTools, [item.path], {
                        nodes: [],
                        stateId: nodeStateId,
                        progressMonitor,
                    });

                    const result = item.computeChildren(item, environmentContext, progressMonitor);
                    if (isArray(result)) {
                        progressMonitor.done();
                        context.fetchedTools = immutableUpdate(context.fetchedTools, [item.path], (prev) => {
                            return {
                                ...prev,
                                nodes: result,
                                progressMonitor: undefined,
                            };
                        });

                        items.push(...result);
                    } else if (isPromise(result)) {
                        result.then((result) => {
                            context.fetchedTools = immutableSet(context.fetchedTools, [item.path, 'nodes'], result);

                            this.updateStateId();
                            this.emit('ItemsFetched', item, result);
                        }, (error) => {
                            if (progressMonitor.isCancelled) {
                                return;
                            }

                            context.fetchedTools = immutableSet(context.fetchedTools, [item.path, 'error'], error);

                            console.error(error);
                        }).finally(() => {
                            context.fetchedTools = immutableSet(context.fetchedTools, [item.path, 'progressMonitor'], undefined);

                            progressMonitor.done();
                        });
                    } else {
                        // Invalid return ?
                        progressMonitor.done();
                    }
                } else {
                    items.push(...computedItems.nodes);
                }
            }

            function normalizePath(path: string): string {
                if (!prefix) {
                    return path;
                }

                return path.substring(prefix.length + 1);
            }


            const segments = normalizePath(item.path).split('/');

            let node = root;
            let path = '';

            segments.find((segment, index) => {
                path += (path ? '/' : '') + segment;

                let child = node.children?.find((item) => normalizePath(item.path) === path);

                if (child) {
                    node = child;

                    if (node.visible === false) {
                        return true; // Break the loop
                    }

                    if (index + 1 === segments.length) {
                        // Merge informations ?
                        if ((node as any).$$implicit) {
                            delete (node as any).$$implicit;

                            forEach(item, (value, name) => {
                                if (name === 'children') {
                                    return;
                                }
                                (node as any)[name] = value;
                            });
                        }
                    }

                    return false;
                }

                if (!node.children) {
                    node.children = [];
                }

                if (index + 1 === segments.length) {
                    node.children.push({ ...item });

                    return false;
                }

                child = {
                    path: (prefix) ? (`${prefix}/${path}`) : path,
                    type: 'marker',
                    children: [],
                    order: item.order,
                };
                (child as any).$$implicit = true;

                if (!toolVisible) {
                    // child.visible = false;
                }

                node.children.push(child);
                node = child;

                return false;
            });
        }

        const filterEmptyGroupsAndRemoveHiddenTools = (node: ToolTreeNode<T>) => {
            if (!node.children) {
                return;
            }

            node.children.forEach((n) => {
                if (!n.children || hiddenTools[n.path]) {
                    return;
                }

                filterEmptyGroupsAndRemoveHiddenTools(n);
            });

            node.children = node.children.filter((child) => {
                if (hiddenTools[child.path]) {
                    return false;
                }

                if (child.type !== 'group') {
                    return true;
                }

                if (isEmpty(child.children)) {
                    return false;
                }

                return true;
            });
        };

        // Filter empty groups and hide hidden tools
        filterEmptyGroupsAndRemoveHiddenTools(root);

        const sortNode = (node: ToolTreeNode<T>) => {
            if (!node.children) {
                return;
            }

            node.children.forEach((n) => {
                if (!n.children) {
                    return;
                }

                sortNode(n);
            });

            node.children.sort((n1, n2) => {
                return (n1.order || 0) - (n2.order || 0);
            });
        };

        let toolNodes: ToolTreeNode<T>[] = [];

        sortNode(root);

        const flatMarkers = (node: ToolTreeNode<T>): ToolTreeNode<T>[] => {
            if (!node.children) {
                return [];
            }

            const children = [...node.children];

            const ret = [];
            for (let i = 0; i < children.length; i++) {
                const item = children[i];
                if (item.type === 'group' || item.type === 'combo' || item.type === 'menu') {
                    const newChildren = flatMarkers(item);

                    const newItem = {
                        ...item,
                        children: newChildren,
                    };

                    ret.push(newItem);
                    continue;
                }

                if (item.type !== 'marker') {
                    ret.push(item);
                    continue;
                }

                const mks = flatMarkers(item);

                ret.push(...mks);
            }

            if (ret.length === 1) {
                if (ret[0].type === 'separator') {
                    return [];
                }
            }

            return ret;
        };

        toolNodes = flatMarkers(root);

        const normalizeSeparators = (node: ToolTreeNode<T>): ToolTreeNode<T>[] => {
            const children = node.children;
            if (!children) {
                return [];
            }

            let ret = [];
            let idx = 0;
            for (; idx < children.length && children[idx].type === 'separator'; idx++) ;

            let separator = false;
            for (; idx < children.length; idx++) {
                const node = children[idx];
                if (node.type === 'separator') {
                    if (separator) {
                        continue;
                    }
                    separator = true;
                    ret.push(node);
                    continue;
                }
                separator = false;

                if (node.type === 'group' || node.type === 'menu') {
                    const ch = normalizeSeparators(node);
                    ret.push({
                        ...node,
                        children: ch,
                    });
                    continue;
                }

                ret.push(node);
            }

            for (; ret.length > 0;) {
                if (ret[ret.length - 1].type !== 'separator') {
                    break;
                }

                ret = ret.slice(0, ret.length - 1);
            }

            return ret;
        };

        toolNodes = normalizeSeparators({
            ...root,
            children: toolNodes,
        });

        this.#cachedTree = toolNodes;

        return toolNodes;
    }

    handleKeyBinding(tool: Tool<T>, event: KeyboardEvent) {
        const environmentContext = this.#currentEnvironmentContext;
        if (!environmentContext) {
            return;
        }

        if (!isToolVisible(tool, environmentContext)) {
            return;
        }
        if (isToolDisabled(tool, environmentContext)) {
            return;
        }

        const internalTool = this.#tools.find((t) => t.path === tool.path);
        if (!internalTool) {
            return;
        }

        if (internalTool.type === 'panel' && !internalTool.onClick) {
            this.togglePanel(environmentContext, internalTool);

            return;
        }

        if (internalTool.type === 'editor' && !internalTool.onClick) {
            this.toggleEditor(internalTool);

            return;
        }

        internalTool.onClick?.(internalTool, environmentContext, event);
    }

    newRegisteredToolItems = (tool: Tool<T>) => {
        this.addItem(tool);
    };

    mount() {
        debug('mount', 'before mountCount=', this.#mountCount);

        this.#mountCount++;
    }

    onUnmount() {
        debug('onUnmount', 'Call unmount', 'before mountCount=', this.#mountCount);

        if (this.#mountCount < 1) {
            throw new Error('ToolContext is already unmounted');
        }

        this.#mountCount--;

        if (this.#mountCount) {
            return;
        }

        this.#tools.forEach((tool) => {
            try {
                tool.onUnmount?.();
            } catch (x) {
                console.error(x);
            }
        });

        debug('onUnmount', 'End of call unmount');
    }

    destroy() {
        ToolsRegistry.getInstance().offNewRegisteredTool(this.id, this.newRegisteredToolItems);
    }
}

// Returns a new array of tools that only have unique path. When
// several tools have same path, it keeps only the one with the higher
// override property, couting 0 for undefined.
function removeDuplicate<T>(tools: Tool<T>[], environmentContext: T): Tool<T>[] {
    const toolsByPath = new Map<string, Tool<T>>();
    const getOverride = (tool: Tool<T>) => tool.override || 0;
    for (const tool of tools) {
        const otherTool = toolsByPath.get(tool.path);
        if (otherTool
            && isToolVisible(tool, environmentContext)
            && !isToolDisabled(tool, environmentContext)
            && (getOverride(tool) > getOverride(otherTool))
            || !otherTool
        ) {
            toolsByPath.set(tool.path, tool);
        }
    }

    return [...toolsByPath.values()];
}
