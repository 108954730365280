import { compact, identity, isArray, isEmpty, mapValues, pickBy } from 'lodash';

import { CasePermissions, CaseUserGroupPermissions, CaseUserPermissions, FolderCasePiece } from '../../model/folder-case-piece';
import { Collaborator, CollaboratorRole, CollaboratorType } from '../../model/collaborator';
import { mapUser } from './users-connector';
import { CasePieceType } from '../../model/case-piece-type';
import { BasicCasePiece, BasicCasePieceWithSettings } from '../../model/basic-case-piece';
import { Folder } from '../../model/folder';
import { FilterCasePieceCondition } from './argonos-folders-connector';
import { FolderCustomFieldDefinition, FolderCustomFieldName } from '../../model/folder-custom-field-definition';
import { ResourceCasePiece, ResourceId } from '../../model/resource';
import { DataType } from 'src/components/common/data-types';
import { TagLink } from '../../exploration/features/tag/types';
import { RawTagLink } from '../../exploration/utils/connector/raws';
import { RawScheduleSettings } from '../../model/raws';
import { ScheduleSettings } from '../../components/crontab/schedule-settings';

export type CasePieceMapper<T extends BasicCasePiece = BasicCasePiece> = (
    target: T,
    properties: Record<string, any> | undefined,
    // settings: Record<string, any>,// Bad Idea, the casepiece will not be reloaded if SETTINGS-CHANGED event
    // userSettings: Record<string, any>, // Bad Idea, the casepiece will not be reloaded if USER-SETTINGS-CHANGED event
) => void;

const casePieceTypeMapper: Map<string, CasePieceType> = new Map();
const casePieceCustomMapper: Map<CasePieceType, CasePieceMapper> = new Map();

function formatCasePath(path: string): string {
    let startIndex = 0;
    let endIndex = path.length;

    if (path[startIndex] === '/') {
        startIndex++;
    }

    if (path[endIndex] === '/') {
        endIndex--;
    }

    const formattedPath = path
        .slice(startIndex, endIndex)
        .trim()
        .replace('/', ' / ')
        .replace('  ', ' ');

    return formattedPath;
}

export function getRoleFromPermissions(permissions: CasePermissions): CollaboratorRole {
    const { hasOwnership, allowWrite, allowRead, allowDelete } = permissions;

    if (allowWrite && allowRead && !hasOwnership && !allowDelete) {
        return CollaboratorRole.Writer;
    }

    if (!allowWrite && allowRead && !hasOwnership && !allowDelete) {
        return CollaboratorRole.Reader;
    }

    return CollaboratorRole.Owner;
    // return CollaboratorRole.Custom;
}


export function mapCollaborators(userPermissions?: CaseUserPermissions[], userGroupPermissions?: CaseUserGroupPermissions[]) {
    let ret = userPermissions?.map((userPermission) => {
        const collaborator: Collaborator = {
            identityId: userPermission.user.id,
            name: userPermission.user.displayName || '',
            type: CollaboratorType.User,
            user: mapUser(userPermission.user),
            role: getRoleFromPermissions(userPermission.permissions),
            permission: userPermission.permissions,
        };

        return collaborator;
    }) || [];

    if (userGroupPermissions) {
        ret = ret.concat(userGroupPermissions.map(userGroupPermission => {
            const collaborator: Collaborator = {
                identityId: userGroupPermission.userGroup.id,
                name: userGroupPermission.userGroup.name,
                type: CollaboratorType.Group,
                role: getRoleFromPermissions(userGroupPermission.permissions),
                permission: userGroupPermission.permissions,
            };

            return collaborator;
        }));
    }

    return ret;
}

export function mapFolder(raw: any, filterCasePieceCondition?: FilterCasePieceCondition, folderCustomFieldsDefinition?: Record<FolderCustomFieldName, FolderCustomFieldDefinition>): Folder {
    const folderCasePiece = mapFolderCasePiece(raw, folderCustomFieldsDefinition);

    let argonosPieces: BasicCasePiece[] = raw.argonosPieces?.map((argonosPieceRaw: any) => {
        const ret = mapArgonosPiece(argonosPieceRaw);

        return ret;
    }) ?? [];

    if (filterCasePieceCondition) {
        argonosPieces = compact(argonosPieces).filter(filterCasePieceCondition);
    }

    const folder: Folder = {
        ...folderCasePiece,
        pieces: compact(argonosPieces),
    };

    return folder;
}

export function mapFolderCasePiece(result: any, folderCustomFieldsDefinition?: Record<FolderCustomFieldName, FolderCustomFieldDefinition>): FolderCasePiece {
    const folderCasePiece: FolderCasePiece = {
        id: result.id,
        displayName: result.name,
        description: result.description,
        parentFolderId: result.parentFolderId,
        isParentFolderReadable: result.isParentFolderReadable,
        path: formatCasePath(result.path),
        lastVisitedDate: mapDate(result.lastVisitedDate),
        createdDate: mapDate(result.createdDate),
        createdBy: result.createdBy,
        lastUpdatedBy: result.lastUpdatedBy,
        lastUpdatedDate: mapDate(result.lastUpdatedDate),
        permissions: result.permissions,
        scope: result.scope,
        isPrivate: !!result.isSandbox,
        userPermissions: result.allPermissions?.userPermissions,
        userGroupPermissions: result.allPermissions?.userGroupPermissions,
        customFields: result.extendedData?.customFields,

        type: CasePieceType.Folder,
    };

    folderCasePiece.customFields = folderCustomFieldsDefinition
        ? pickBy(mapValues(folderCasePiece.customFields, (customFieldRawValue, customFieldName) => {
            return mapFolderCustomFieldValue(folderCustomFieldsDefinition, customFieldName, customFieldRawValue);
        }), identity)
        : undefined;


    return folderCasePiece;
}

const mapFolderCustomFieldValue = (folderCustomFieldsDefinition: Record<FolderCustomFieldName, FolderCustomFieldDefinition>, customFieldName: FolderCustomFieldName, rawValue: any) => {
    let value = rawValue;
    const fieldDefinition = folderCustomFieldsDefinition[customFieldName];
    if (!fieldDefinition) {
        return undefined;
    }

    const convertValue = (_value: any) => {
        if (isEmpty(_value) && fieldDefinition.isRequired) {
            throw Error(`Value is missing for mandatory folder field ${customFieldName}`);
        }

        switch (fieldDefinition.type) {
            case DataType.Date:
            case DataType.DateTime:
                return mapDate(_value);

            default:
                return _value;
        }
    };

    if (isArray(value)) {
        if (!fieldDefinition.allowMultipleValues) {
            throw Error(`Folder field ${customFieldName} does not allow multiple values`);
        }

        value = value.map(convertValue);
    } else {
        value = convertValue(value);
    }

    return value;
};

export function mapArgonosPiece(piece: any, mapSettings?: boolean): BasicCasePieceWithSettings | undefined {
    const type = convertCasePieceTypeToFront(piece);
    if (type === undefined) {
        console.warn('Unknown type of casePiece=', piece);

        return undefined;
    }

    const ret: BasicCasePieceWithSettings = {
        id: piece.entityId,

        displayName: piece.name,
        description: piece.description,
        type,

        createdBy: piece.createdBy,
        createdDate: mapDate(piece.createdDate),

        lastUpdatedBy: piece.lastUpdatedBy,
        lastUpdatedDate: mapDate(piece.lastUpdatedDate),

        lastVisitedDate: mapDate(piece.lastVisitedDate),

    };

    const { entityProperties } = piece;
    const mapper = casePieceCustomMapper.get(type);

    if (mapper) {
        mapper(ret, entityProperties);
    }

    if (mapSettings) {
        // We don't want the mapper to use the settings
        ret.userSettings = piece.userSettings;
        ret.settings = piece.settings;
    }

    return ret;
}

export function convertCasePieceTypeToFront(piece: any): CasePieceType | undefined {
    const type = piece.entityType;
    const entityProperties = piece.entityProperties;
    switch (type) {
        case 'bulksearch': {
            return CasePieceType.MassiveSearch;
        }
        case 'exploration': {
            const origin = entityProperties?.origin;
            if (origin === 'FastSearch') {
                return CasePieceType.FastSearch;
            }
            if (origin === 'AdvancedSearch') {
                return CasePieceType.AdvancedSearch;
            }

            return CasePieceType.Exploration;
        }
    }

    const ret = casePieceTypeMapper.get(type);

    return ret;
}

export function convertCasePieceTypeToBack(type: CasePieceType): string | undefined {
    switch (type) {
        case CasePieceType.Exploration:
        case CasePieceType.FastSearch:
        case CasePieceType.AdvancedSearch:
            return 'exploration';
        case CasePieceType.MassiveSearch:
            return 'bulksearch';
    }

    for (const [casePieceTypeName, casePieceType] of casePieceTypeMapper.entries()) {
        if (casePieceType === type) {
            return casePieceTypeName;
        }
    }

    return undefined;
}


export function mapDate(date: undefined): undefined;
export function mapDate(date: string): Date;
export function mapDate(date: string | undefined): Date | undefined;

export function mapDate(date: string | undefined): Date | undefined {
    if (!date) {
        return undefined;
    }

    // TODO to be removed !
    if (date === '0001-01-01T00:00:00+00:00') {
        return undefined;
    }

    return new Date(date);
}

export function registerCasePieceType<T extends BasicCasePiece>(casePieceTypeName: string, casePieceType: CasePieceType, mapper?: CasePieceMapper<T>) {
    casePieceTypeMapper.set(casePieceTypeName, casePieceType);
    if (mapper) {
        casePieceCustomMapper.set(casePieceType, mapper as CasePieceMapper);
    }
}

export function mapResource(raw: any, resourceId: ResourceId): ResourceCasePiece {
    const ret: ResourceCasePiece = {
        id: resourceId,
        type: CasePieceType.Resource,

        displayName: raw.name,
        contentLength: raw.contentLength,
        contentType: raw.contentType,
        metadata: raw.metadata,
    };

    return ret;
}

export function mapTagLink(raw: RawTagLink): TagLink {
    const annotationProperties = JSON.parse(raw.annotationLocation);
    const ret: TagLink = {
        tagId: raw.annotationId.id,
        linkId: raw.annotationId.linkId,
        properties: annotationProperties.tagProperties,
        vertexId: raw.identifiedObjectId,
        vertexProperty: annotationProperties.vertexProperty,
        imageResourceId: raw.thumbnailResourceId,
        identifiedPropertyName: raw.identifiedPropertyName,
    };

    return ret;
}

export function mapScheduleSettings(raw: RawScheduleSettings) {
    const ret: ScheduleSettings = {
        ...raw,
        start: mapDate(raw.start),
        end: mapDate(raw.end),
    };

    if (ret.cronExpression) {
        const r1 = /^([^\s]+)(\s+[^\s]+)?(\s+[^\s]+)?(\s+[^\s]+)?(\s+[^\s]+)?(\s+[^\s]+)?(\s+[^\s]+)?/.exec(ret.cronExpression);
        if (!r1) {
            console.warn('CRON expression is invalid', ret.cronExpression);
        }

        ret.cronExpression = r1?.splice(1).join(' ');
    }

    return ret;
}
