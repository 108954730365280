import { useCallback, useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import { ArgChangeReason, ArgFilteredMenu, useArgNotifications, useProgressMonitor } from '../../basic';
import { EditorPickerOptions, EditorPopoverProps } from './controls-type';

const messages = defineMessages({
    loadPropertyPossibleValuesError: {
        id: 'common.controls.property-picker.LoadPropertyPossibleValuesError',
        defaultMessage: 'Failed to load property possible values',
    },
    fetchPropertyPossibleValues: {
        id: 'common.controls.property-picker.FetchPropertyPossibleValues',
        defaultMessage: 'Fetch property possible values',
    },
});

export function usePropertyPicker<T = string>(
    pickerOptions?: EditorPickerOptions<T>,
    onChange?: (value: T | null, reason: ArgChangeReason) => void,
): EditorPopoverProps {
    const {
        pickList = true,
        getPossibleValues,
        fixedValues,
        getItemKey = (item: T) => item as string,
        getItemLabel = (item: T) => item as string,
    } = pickerOptions ?? { pickList: false };

    const notifications = useArgNotifications();

    const [list, setList] = useState<T[]>(fixedValues ?? []);
    const [progressMonitor, createProgressMonitor] = useProgressMonitor();
    const [popoverVisible, setPopoverVisible] = useState<boolean>();

    useEffect(() => {
        if (pickList && !fixedValues && getPossibleValues) {
            const fetchProgressMonitor = createProgressMonitor(messages.fetchPropertyPossibleValues, 1);
            getPossibleValues(fetchProgressMonitor)
                .then(setList)
                .catch((error) => {
                    if (fetchProgressMonitor.isCancelled) {
                        return;
                    }
                    console.error(error);
                    notifications.snackError({ message: messages.loadPropertyPossibleValuesError }, error as Error);
                })
                .finally(() => {
                    fetchProgressMonitor.done();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickList, fixedValues, getPossibleValues]);

    // Reset list if fixedValues change
    useEffect(() => {
        if (!fixedValues) {
            return;
        }

        setList(fixedValues);
    }, [fixedValues]);

    const handleSelect = useCallback((item: T) => {
        setPopoverVisible(false);
        onChange?.(item, 'selection');
    }, [onChange]);

    const handlePopoverVisibleChange = useCallback((visible: boolean) => {
        setPopoverVisible(visible);
    }, [setPopoverVisible]);

    const computeMenu = useCallback(() => {
        return (
            <ArgFilteredMenu<T>
                items={list}
                getItemLabel={getItemLabel}
                getItemKey={getItemKey}
                enableFilter={list.length > 0}
                onSelect={handleSelect}
                progressMonitor={progressMonitor}
                autoFocus={!!fixedValues}
            />
        );
    }, [
        list,
        getItemLabel,
        getItemKey,
        progressMonitor,
        handleSelect,
        fixedValues,
    ]);

    const ret: EditorPopoverProps = (pickList && (progressMonitor?.isRunning || list.length > 0)) ? {
        progressMonitor,
        popover: computeMenu,
        popoverVisible,
        onPopoverVisibleChange: handlePopoverVisibleChange,
        right: 'dropdown',
    } : {};

    return ret;
}
