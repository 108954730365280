import { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { RangePickerDateProps } from 'antd/lib/date-picker/generatePicker';
import { DatePicker } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import { isEqual } from 'lodash';
import Debug from 'debug';

import { dayjs } from '../utils/dayjs';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import {
    IncludeTime,
    internalisationDateFormat,
    internalisationDatePlaceholder,
} from '../../../utils/dates/internalisation-date-format';
import { ArgTooltip2 } from '../arg-tooltip/arg-tooltip2';
import { ArgRenderedText } from '../types';

import './arg-range-picker.less';

export type ArgDateRange = RangeValue<dayjs.Dayjs>;

const { RangePicker } = DatePicker;

const debug = Debug('basic:ArgRangePicker');

export interface ArgRangePickerProps {
    className?: ClassValue;
    popupClassName?: ClassValue;
    showTime?: boolean | RangePickerDateProps<dayjs.Dayjs>['showTime'];
    allowClear?: boolean;
    disabled?: boolean;
    tooltip?: ArgRenderedText;
    value?: ArgDateRange;
    onChange: (dates: ArgDateRange | undefined) => void;
    disabledDate?: (date: dayjs.Dayjs) => boolean;
    includeTime?: IncludeTime;
}

export function ArgRangePicker(props: ArgRangePickerProps) {
    const { allowClear, className, popupClassName, disabled, tooltip, disabledDate, onChange, value, showTime, includeTime } = props;

    const intl = useIntl();
    const classNames = useClassNames('arg-range-picker');

    const rangePickerRef = useRef<any>(null);
    const [open, setOpen] = useState<boolean>(false);

    const valueRef = useRef<ArgDateRange | undefined>();
    valueRef.current = value;

    const format = useMemo(() => (
        internalisationDateFormat(intl, includeTime)
    ), [includeTime, intl]);

    const placeholder = useMemo(() => (
        internalisationDatePlaceholder(intl, includeTime)
    ), [includeTime, intl]);

    const handleOnChange = useCallback((dates: RangeValue<dayjs.Dayjs>) => {
        const newDates = dates ?? undefined;

        debug('handleOnChange', 'current=', valueRef.current, 'newDates=', newDates);
        if (isEqual(valueRef.current, newDates)) {
            return;
        }
        valueRef.current = newDates;

        onChange(newDates);
    }, [onChange]);

    let rangePicker = (
        <RangePicker
            ref={rangePickerRef}
            open={open}
            value={value}
            separator='-'
            disabled={disabled}
            onOpenChange={setOpen}
            allowClear={allowClear}
            onChange={handleOnChange}
            format={format}
            placeholder={[placeholder, placeholder]}
            className={classNames('&', className)}
            suffixIcon={<ArgIcon name='icon-calendar' />}
            clearIcon={<ArgIcon name='icon-cross' />}
            popupClassName={classNames('&-dropdown', popupClassName)}
            disabledDate={disabledDate}
            showTime={showTime}
        />
    );

    if (tooltip) {
        rangePicker = (
            <ArgTooltip2 title={tooltip}>
                <span>
                    {rangePicker}
                </span>
            </ArgTooltip2>
        );
    }

    return rangePicker;
}
