import Debug from 'debug';
import { isEmpty } from 'lodash';

import { CaseId } from '../../../model/folder-case-piece';
import { CasePieceType } from '../../../model/case-piece-type';
import { ProgressMonitor, SubProgressMonitor } from '../../../components/basic';
import { ConnectorRequestInit } from '../../../utils/connector';
import { mapBriefPreview, mapCasePiece } from './mappers';
import { BaseConnector } from '../../../utils/connectors/base-connector';
import { EditorName, UpdateBriefRequestBody } from 'src/exploration/model/types';
import { Brief, BriefCasePiece, BriefId, BriefPreview, BriefSectionId } from '../../model/brief';
import { getDataExplorationApi } from '../../../utils/connectors/api-url';
import { PropertyValueMetaData, UniverseId } from '../../model/universe';
import { UserMetadata } from '../../../model/user-metadata';
import { registerCasePieceType } from '../../../utils/connectors/mappers';
import { ExplorationPieceConnector } from './exploration-pieces-connector';

const debug = Debug('argonode:exploration:utils:BriefConnector');

export class BriefConnector extends BaseConnector {
    private static instance: BriefConnector;

    static getInstance(): BriefConnector {
        if (!BriefConnector.instance) {
            BriefConnector.instance = new BriefConnector('exploration.brief', getDataExplorationApi());
        }

        return BriefConnector.instance;
    }

    async createBrief(
        caseId: CaseId,
        name: string,
        editor: EditorName,
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Brief> {
        const url = '/briefs';

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                brief: {
                    name,
                    editor: {
                        name: editor,
                    },
                },
                universe: {
                    id: universeId,
                },
            },
        };

        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const createdBrief = await this.request(url, options, sub1);

        const brief = mapCasePiece(createdBrief, CasePieceType.Brief) as Brief;

        if (caseId) {
            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await ExplorationPieceConnector.getInstance().addPiece(caseId, brief.id, CasePieceType.Brief, sub2);
        }

        return brief;
    }

    async updateBrief(
        briefId: BriefId,
        name: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<BriefCasePiece> {
        const url = `/briefs/${encodeURIComponent(briefId)}`;
        const options: ConnectorRequestInit = {
            method: 'PUT',
            json: {
                brief: {
                    name: name,
                },
            } as UpdateBriefRequestBody,
        };

        const updatedBrief = await this.request(url, options, progressMonitor);
        const briefCasePiece = mapCasePiece(updatedBrief, CasePieceType.Brief) as BriefCasePiece;

        return briefCasePiece;
    }

    async getBriefCasePiece(caseId: CaseId, casePieceId: BriefId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<BriefCasePiece> {
        const ret = await ExplorationPieceConnector.getInstance().getPiece(caseId, casePieceId, true, true, progressMonitor);

        if (ret.type !== CasePieceType.Brief) {
            throw new Error('Invalid brief piece');
        }

        return ret as BriefCasePiece;
    }

    async getBrief(
        briefId: BriefId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Brief> {
        const url = `/briefs/${encodeURIComponent(briefId)}`;
        const rawBrief = await this.request(url, { verifyJSONResponse: true }, progressMonitor);

        const brief = mapCasePiece(rawBrief, CasePieceType.Brief) as Brief;

        return brief;
    }

    async visitBrief(
        caseId: CaseId,
        briefId: BriefId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        await ExplorationPieceConnector.getInstance().visitPiece(caseId, briefId, progressMonitor);
    }

    async saveSection(
        briefId: BriefId,
        briefSectionId: BriefSectionId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/briefs/${encodeURIComponent(briefId)}/sections/${encodeURIComponent(briefSectionId)}/save`;

        await this.request(url, { method: 'POST' }, progressMonitor);
    }

    async addSection(
        briefId: BriefId,
        editorName: EditorName,
        position?: { before?: BriefSectionId; after?: BriefSectionId },
        initialContent?: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<BriefSectionId> {
        const url = `/briefs/${encodeURIComponent(briefId)}/sections`;

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                before: position?.before,
                after: position?.after,
                editor: {
                    name: editorName,
                },
                content: initialContent,
            },
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret.id;
    }

    async updateSection(
        briefId: BriefId,
        briefSectionId: BriefSectionId,
        sectionName: string | undefined,
        metadata: PropertyValueMetaData | undefined,
        locked: boolean | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/briefs/${encodeURIComponent(briefId)}/sections/${encodeURIComponent(briefSectionId)}`;

        await this.request(url, {
            method: 'PUT',
            json: {
                name: (sectionName) ? sectionName : undefined,
                metadata,
                locked,
            },
        }, progressMonitor);
    }

    async removeSection(
        briefId: BriefId,
        briefSectionId: BriefSectionId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/briefs/${encodeURIComponent(briefId)}/sections/${encodeURIComponent(briefSectionId)}`;

        await this.request(url, { method: 'DELETE' }, progressMonitor);
    }

    async deleteBrief(
        briefId: BriefId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/briefs/${encodeURIComponent(briefId)}`;

        await this.request(
            url,
            {
                method: 'DELETE',
            },
            progressMonitor,
        );
    }

    async deleteBriefs(
        briefIds: BriefId[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        progressMonitor.beginTask('delete briefs', briefIds.length);

        const ps: Promise<void>[] = briefIds.map((briefId) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);
            const p = this.deleteBrief(briefId, sub);

            return p;
        });

        await Promise.all(ps);

        debug('deleteBriefs', 'Briefs deleted:', briefIds);
    }

    async getBriefSectionToken(
        briefId: BriefId,
        briefSectionId: BriefSectionId,
        progressMonitor = ProgressMonitor.empty(),
    ): Promise<string> {
        const url = `/briefs/${encodeURIComponent(briefId)}/sections/${encodeURIComponent(briefSectionId)}/token`;
        const jwtToken = await this.request(url, undefined, progressMonitor);

        return jwtToken;
    }

    async getBriefPreview(
        briefId: BriefId,
        userMetadata: UserMetadata,
        progressMonitor = ProgressMonitor.empty(),
    ): Promise<BriefPreview> {
        const url = `/briefs/${encodeURIComponent(briefId)}/visualization`;

        const response = await this.request(url, {
            json: {
                targetProfiles: isEmpty(userMetadata) ? undefined : [{
                    profileFields: userMetadata,
                }],
            },
        }, progressMonitor);

        const preview = mapBriefPreview(response);

        return preview;
    }
}

registerCasePieceType('brief', CasePieceType.Brief, (ret: BriefCasePiece, entityProperties: Record<string, any> | undefined) => {
    ret.universeId = entityProperties?.universeId || entityProperties?.universe?.id;
    if (!ret.universeId) {
        console.warn('No universeId defined for brief #', ret.id);
    }
});
