import { isEmpty } from 'lodash';

import { ToolContextId } from './tool-context';
import { Tool } from './tool';
import { EventEmitter } from '../utils/event-emitter';

interface ToolContextEvents {
    'Show': ()=>void;
}

export class ToolsRegistry {
    private static instance: ToolsRegistry;

    #registeredTools: Record<ToolContextId, Tool<any>[]> = {};
    #toolsContextEvents: EventEmitter = new EventEmitter<ToolContextEvents>();


    static getInstance(): ToolsRegistry {
        if (!ToolsRegistry.instance) {
            ToolsRegistry.instance = new ToolsRegistry();
        }

        return ToolsRegistry.instance;
    }

    constructor() {
    }

    registerToolItem<T>(toolContextId: ToolContextId, tool: Tool<T> /*, toolPropsGenerator?: ToolPropsGenerator<T>*/) {
        /* if (toolPropsGenerator) {
            const toolWithPropsGenerator: ToolItemWithPropsGenerator<any> = {
                ...tool,
                __internal_propsGenerator: toolPropsGenerator,
            };
            tool = toolWithPropsGenerator;
        } */

        let ts = this.#registeredTools[toolContextId];
        if (ts) {
            ts.push(tool);
        } else {
            ts = [tool];
            this.#registeredTools[toolContextId] = ts;
        }

        this.#toolsContextEvents.emit(`Register:${toolContextId}`, tool);
    }

    /**
     * INTERNAL USE ONLY !
     */
    appendRegisteredToolItems<T>(tools: Tool<T>[], toolContextId: ToolContextId) {
        this.#toolsContextEvents.emit(`Show:${toolContextId}`);

        const ts = this.#registeredTools[toolContextId];
        if (isEmpty(ts)) {
            return;
        }

        tools.push(...ts);
    }

    /* ----------------------------------------------------------- */

    onceShownToolContext(toolContextId: ToolContextId, handle: () => void): void {
        this.#toolsContextEvents.once(`Show:${toolContextId}`, handle);
    }

    /**
     * INTERNAL USE ONLY !
     */
    onNewRegisteredTool(toolContextId: ToolContextId, handle: (tool: Tool<any>) => void): void {
        this.#toolsContextEvents.on(`Register:${toolContextId}`, handle);
    }

    /**
     * INTERNAL USE ONLY !
     */
    offNewRegisteredTool(toolContextId: ToolContextId, handle: (tool: Tool<any>) => void): void {
        this.#toolsContextEvents.off(`Register:${toolContextId}`, handle);
    }
}

export function registerToolItem<T>(toolContextId: ToolContextId, tool: Tool<T>) {
    ToolsRegistry.getInstance().registerToolItem(toolContextId, tool);
}
