import React, { useCallback, useMemo, useState } from 'react';
import { find } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgFilteredMenu } from '../arg-menu/arg-filtered-menu';
import { ArgChangeReason } from '../types';
import { ArgButton } from '../arg-button/arg-button';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';

//import './arg-list-button-selector.less';

type ListItem = string;

export interface ListItemsType {
    key: string;
    value: ListItem;
}

interface ArgListButtonSelectorProps {
    className?: ClassValue;
    popoverClassName?: ClassValue;
    list?: (ListItemsType | ListItem)[];
    selectedListItem?: ListItem;
    onChangeSelectedItem?: (property: ListItem) => void;
    progressMonitor?: ProgressMonitor;
}


export function ArgListButtonSelector({
    className,
    popoverClassName,
    list,
    selectedListItem,
    onChangeSelectedItem,
    progressMonitor,
}: ArgListButtonSelectorProps) {
    const classNames = useClassNames('arg-list-button-selector');
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

    const sortedList = useMemo(() => {
        let sortedList: ListItemsType[] = [];
        if (!list) {
            return sortedList;
        }
        if (list.findIndex(item => !!(item as ListItemsType).key) < 0) {
            sortedList = (list as ListItem[])
                .map(item => {
                    const mappedItem: ListItemsType = {
                        key: item,
                        value: item,
                    };

                    return mappedItem;
                });
        } else {
            sortedList = [...(list as ListItemsType[])];
        }

        sortedList = sortedList.sort((v1, v2) => v1.value.localeCompare(v2.value));

        return sortedList;
    }, [list]);

    const onListItemSelection = useCallback((item: ListItemsType, _: ArgChangeReason) => {
        onChangeSelectedItem?.(item.key);
        setPopoverVisible(false);
    }, [onChangeSelectedItem]);

    const selectedItem = useMemo(() => {
        const selected = find(
            sortedList,
            (sortedListItem: ListItemsType) => sortedListItem.key === selectedListItem,
        );

        return selected;
    }, [selectedListItem, sortedList]);

    const computeMenu = useCallback(() => {
        return (
            <ArgFilteredMenu<ListItemsType>
                items={sortedList}
                selected={selectedItem}
                enableFilter={true}
                autoFocus={true}
                getItemKey={(item) => item.key}
                getItemLabel={(item) => item.value}
                onSelect={onListItemSelection}
            />
        );
    }, [sortedList, selectedItem, onListItemSelection]);

    return (
        <ArgButton
            className={classNames('&', className)}
            type='ghost'
            label={selectedItem?.value || ''}
            right='dropdown'
            size='medium'
            popover={computeMenu}
            popoverVisible={popoverVisible}
            onPopoverVisibleChange={setPopoverVisible}
            popoverPlacement='bottomLeft'
            popoverClassName={classNames('&-popover', popoverClassName)}
            progressMonitor={progressMonitor}
        />
    );
}
