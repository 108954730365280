import { useCallback, useMemo, useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { mapValues, size } from 'lodash';
import useResizeObserver from '@react-hook/resize-observer';
import 'ace-builds/src-noconflict/mode-mysql';

import {
    ArgButton,
    ArgDragAndDropUploader,
    ArgIcon,
    ArgModal,
    ArgTab,
    ArgTable3,
    ArgTableColumn3,
    ArgTooltip2,
    ArgUploaderButton,
    useClassNames,
    ArgTabs,
} from 'src/components/basic';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType } from '../../types';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { useFeedSources } from 'src/settings/universes/ontology/components/feed-sources-modal/use-feed-sources';
import { FeedSourceEditor } from './feed-source-editor';
import { Panel } from './feed-source-panel';

import './feed-sources-modal.less';

const ERROR_TAB_KEY = 'error';
const RESULT_TAB_KEY = 'result';
const RESULTS_COLUMN_WIDTH = 150;
const EMPTY_COLUMN_KEY = 'empty-column-key';
const EMPTY_QUERY_REGEX = /^\s*$/;
const FORCE_LOADING = false;
const PANEL_ENTRIES = ['object-or-relation-properties'] as const;

export const messages = defineMessages({
    title: {
        id: 'settings.feed-sources-modal.title',
        defaultMessage: 'Define feed sources',
    },
    import: {
        id: 'settings.feed-sources-modal.import',
        defaultMessage: 'Import',
    },
    export: {
        id: 'settings.feed-sources-modal.export',
        defaultMessage: 'Export',
    },
    test: {
        id: 'settings.feed-sources-modal.test',
        defaultMessage: 'Test',
    },
    addSource: {
        id: 'settings.feed-sources-modal.addSource',
        defaultMessage: 'Add a source',
    },
    syntaxError: {
        id: 'settings.feed-sources-modal.syntaxError',
        defaultMessage: 'Syntax error',
    },
    testResult: {
        id: 'settings.feed-sources-modal.testResult',
        defaultMessage: 'Test result',
    },
    submit: {
        id: 'settings.feed-sources-modal.submitButton',
        defaultMessage: 'Define',
    },
    errorLine: {
        id: 'settings.feed-sources-modal.errorLine',
        defaultMessage: 'Error line {line} :',
    },
    fetchFeedSources: {
        id: 'settings.feed-sources-modal.fetchFeedSources',
        defaultMessage: 'Loading feed sources <ThreeDotsLoading></ThreeDotsLoading>',
    },
    resultNumber: {
        id: 'settings.feed-sources-modal.resultNumber',
        defaultMessage: '{count, plural,=0 {No object found} =1 {1 object found :} other {{count} objects found :}}',
    },
    sourceItemLabel: {
        id: 'settings.feed-sources-modal.SourceItemLabel',
        defaultMessage: 'Source {count}',
    },
});

export interface FeedSourcesModalProps {
    closeModal: () => void;
    vertexOrEdge: FullOntologyObjectType | FullOntologyLinkType;
    ontology: FullOntology;
    ontologyType: 'object' | 'relation';
}

export function FeedSourcesModal(props: FeedSourcesModalProps) {
    const {
        closeModal,
        vertexOrEdge,
        ontology,
        ontologyType,
    } = props;

    const classNames = useClassNames('settings-feed-sources-modal');

    const [editorsContainerSize, setEditorsContainerSize] = useState<{ width: number; height: number } | undefined>();

    const editorsContainerRef = useRef<HTMLDivElement | null>(null);

    const {
        exportFeedSource,
        importFeedSources,
        handleTestQuery,
        isTestingQuery,

        handleSubmitFeedSources,
        isSubmittingFeedSource,

        queries,
        focusedEditorIndex,
        results,
        errors,

        isLoadingFeedSources,


        isErrorPanelVisible,
        isResultPanelVisible,

        resultsDataProvider,

        handleAddSource,
        handleSelectedSourceIndexChange,
        handleRemoveEditor,
        isSelected,

        selectedSourceIndex,

        handleEditorChange,
        handleFocus,
        handleTabChange,
        activeTab,
    } = useFeedSources(ontology, vertexOrEdge, ontologyType, closeModal);

    const errorBody = (
        <div className={classNames('&-errors-container')}>
            {errors[focusedEditorIndex]?.map((error, index) => (
                <div key={index}><FormattedMessage {...messages.errorLine} values={{ line: error.line }} /> {error.message}</div>
            ))}
        </div>
    );

    const columns = useMemo<ArgTableColumn3<Record<string, string>>[]>(() => {
        const columns: ArgTableColumn3<Record<string, string>>[] = [];
        const result = results[focusedEditorIndex];
        if (!result?.rows?.length) {
            return columns;
        }
        for (const key in result.rows[0]) {
            columns.push({
                key,
                ellipsis: true,
                columnName: key,
                render: function FeedSourceColumn(value: string) {
                    return (
                        <ArgTooltip2 title={value}>
                            <span className={classNames('&-cell-name')}>
                                {value}
                            </span>
                        </ArgTooltip2>
                    );
                },
                dataIndex: [key],
                sortable: false,
            });
        }
        columns.push({
            key: EMPTY_COLUMN_KEY,
            ellipsis: true,
            columnName: ' ',
            dataIndex: [],
            sortable: false,
        });

        return columns;
    }, [classNames, focusedEditorIndex, results]);

    const columnWidths = useMemo(() => {
        const result = results[focusedEditorIndex];
        if (!result?.rows?.length) {
            return {};
        }
        const tableSize = (editorsContainerSize?.width ?? 0) - 30;
        const columnWidths = mapValues(result.rows[0], () => RESULTS_COLUMN_WIDTH);
        const totalWidth = size(columnWidths) * RESULTS_COLUMN_WIDTH;
        const remaining = tableSize > totalWidth ? tableSize - totalWidth : 0;
        columnWidths[EMPTY_COLUMN_KEY] = remaining;

        return columnWidths;
    }, [editorsContainerSize?.width, focusedEditorIndex, results]);

    const resultBody = <>
        <span className={classNames('&-result-count')}>
            <FormattedMessage {...messages.resultNumber} values={{ count: results[focusedEditorIndex]?.count ?? 0 }} />
        </span>
        {
            columns.length > 0 && (
                <ArgTable3<Record<string, string>>
                    className={classNames('&-result-table')}
                    columns={columns}
                    dataProvider={resultsDataProvider}
                    initialItemsCount={results[focusedEditorIndex]?.rows?.length || 0}
                    rowHeight={30}
                    adjustColumnsOnFirstDraw={true}
                    columnWidths={columnWidths}
                />
            )
        }

    </>;

    const tabs: ArgTab[] = [{
        key: ERROR_TAB_KEY,
        icon: 'icon-embed2',
        title: messages.syntaxError,
        closable: false,
    },
    {
        key: RESULT_TAB_KEY,
        icon: 'icon-embed2',
        title: messages.testResult,
        closable: false,
    }];

    const isPanelVisible = isErrorPanelVisible || isResultPanelVisible;

    useResizeObserver(editorsContainerRef.current, (entry: ResizeObserverEntry) => {
        setEditorsContainerSize({
            width: entry.target.clientWidth,
            height: entry.target.clientHeight,
        });
    });

    const setEditorsContainerRef = useCallback((element: HTMLDivElement | null) => {
        if (element) {
            editorsContainerRef.current = element;
            setEditorsContainerSize({
                width: element.clientWidth,
                height: element.clientHeight,
            });
        }
    }, []);


    const [selectedPanel, setSelectedPanel] = useState<typeof PANEL_ENTRIES[number]>();

    const body = isLoadingFeedSources || FORCE_LOADING ? (
        <LoadingPane
            className={classNames('&-loading')}
            size='large'
            message={messages.fetchFeedSources}
        />
    ) : (
        <>
            <div className={classNames('&-editors-header')}>
                <div className={classNames('&-editors-header-left')}>
                    <ArgButton
                        icon='icon-add-outline'
                        className={classNames('&-editors-header-left-add-source-button')}
                        label={messages.addSource}
                        type='ghost'
                        onClick={handleAddSource}
                    />
                    <ArgUploaderButton
                        type='ghost'
                        size='medium'
                        icon='icon-download'
                        className={classNames('&-editors-header-left-import-button')}
                        label={messages.import}
                        acceptedFiles='.json'
                        method={importFeedSources}
                    />
                    <ArgButton
                        icon='icon-upload'
                        className={classNames('&-editors-header-left-export-button')}
                        label={messages.export}
                        type='ghost'
                        onClick={exportFeedSource}
                        disabled={queries.every((query) => !query)}
                    />
                </div>

                <div className={classNames('&-editors-header-right')}>
                    <ArgButton
                        onClick={handleTestQuery}
                        label={messages.test}
                        loading={isTestingQuery}
                        disabled={!queries[focusedEditorIndex] || EMPTY_QUERY_REGEX.test(queries[focusedEditorIndex])}
                    />

                    {PANEL_ENTRIES.map(panelEntry => {
                        return (
                            <ArgButton
                                key={panelEntry}
                                onClick={() => setSelectedPanel(prev => ((prev === panelEntry) ? undefined : panelEntry))}
                                type='ghost'
                                icon='icon-info'
                                className={classNames('&-editors-header-right-info', { selected: panelEntry === selectedPanel })}
                            />
                        );
                    })}
                </div>
            </div>
            <div className={classNames('&-editors-content')}>
                {queries.length > 1 && (
                    <ul className={classNames('&-source-list')} role='listbox' aria-label='Source list'>
                        {queries.map((query, index) => (
                            <li
                                key={index}
                                className={classNames('&-source-list-item', { selected: isSelected(index), error: errors[index] && errors[index].length > 0 })}
                            >
                                <ArgButton
                                    onClick={() => handleSelectedSourceIndexChange(index)}
                                    type='ghost'
                                    label={messages.sourceItemLabel}
                                    messageValues={{ count: index + 1 }}
                                    className={classNames('&-source-list-item-selection-btn')}
                                />
                                <ArgButton
                                    type='ghost'
                                    className={classNames('&-source-list-item-delete-btn')}
                                    icon='icon-trash'
                                    aria-label={`Delete Source ${index + 1}`}
                                    onClick={() => handleRemoveEditor(index)}
                                    disabled={!(queries.length > 1)}
                                />
                            </li>
                        ))}
                    </ul>
                )}
                <div className={classNames('&-editors-container')} ref={setEditorsContainerRef}>
                    <FeedSourceEditor
                        key={selectedSourceIndex}
                        height={editorsContainerSize?.height}
                        onChange={(value) => handleEditorChange(value, selectedSourceIndex)}
                        focused={true}
                        query={queries[selectedSourceIndex]}
                        errors={errors[selectedSourceIndex]}
                        onFocus={() => handleFocus(selectedSourceIndex)}
                        showTrash={queries.length > 1}
                        onRemove={() => handleRemoveEditor(selectedSourceIndex)}
                    />
                </div>

                {selectedPanel && (
                    <Panel
                        selectedPanel={selectedPanel}
                        classNames={classNames}
                        vertexOrEdge={vertexOrEdge}
                    />
                )}
            </div>
            {isPanelVisible && (
                <div className={classNames('&-bottom-panel')}>
                    <div
                        onClick={() => handleTabChange(undefined)}
                        className={classNames('&-bottom-panel-close-button')}
                    >
                        <ArgIcon name='icon-cross' />
                    </div>
                    {isErrorPanelVisible && errorBody}
                    {isResultPanelVisible && resultBody}
                </div>
            )}
            <div className={classNames('&-tabs-container')}>
                <ArgTabs
                    tabs={tabs}
                    activeTabKey={activeTab}
                    showListTabs={false}
                    onChange={handleTabChange}
                    className={classNames('&-tabs')}
                />
            </div>
        </>);

    return (
        <ArgModal
            size='xlarge'
            title={messages.title}
            visible={true}
            maskClosable={false}
            centered={true}
            onCancel={closeModal}
            onClose={closeModal}
            className={classNames('&')}
            onOk={handleSubmitFeedSources}
            okText={messages.submit}
            loading={isSubmittingFeedSource}
            okDisabled={isLoadingFeedSources}
        >
            <ArgDragAndDropUploader
                method={importFeedSources}
            >
                <div className={classNames('&-container')}>
                    {body}
                </div>
            </ArgDragAndDropUploader>
        </ArgModal>
    );
}
