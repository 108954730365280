import { defineMessages } from 'react-intl';

import { ArgMessageRenderer, MESSAGE_DESCRIPTOR_FORMATTERS, ProgressMonitor, useArgNotifications, useMemoAsync } from '../../basic';

export interface ApplicationService {
    serviceName: string;
    version: string;
}

export interface ApplicationVersion {
    version: string;
    services: ApplicationService[];
}

const messages = defineMessages({
    version: {
        id: 'common.applications-version.Title',
        defaultMessage: 'Version: {version}',
    },
    versionLoading: {
        id: 'common.applications-version.Loading',
        defaultMessage: 'Version: {threeDotsLoading}',
    },
    versionError: {
        id: 'preparation.applications-version.Error',
        defaultMessage: 'Can not get application version',
    },
});

export interface ApplicationsVersionProps {
    getApplicationVersion: (progressMonitor: ProgressMonitor) => Promise<ApplicationVersion>;
}

export function ApplicationsVersion(props: ApplicationsVersionProps) {
    const { getApplicationVersion } = props;

    const notifications = useArgNotifications();

    const [version] = useMemoAsync(async(progressMonitor) => {
        try {
            const ret = await getApplicationVersion(progressMonitor);

            return ret;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.versionError }, error as Error);
        }
    }, [getApplicationVersion, notifications]);

    if (!version) {
        return <ArgMessageRenderer message={messages.versionLoading} messageValues={MESSAGE_DESCRIPTOR_FORMATTERS} />;
    }

    return (
        <ArgMessageRenderer message={messages.version} messageValues={{ version: version.version }} />
    );
}
