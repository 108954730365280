import { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton, ArgMenu, ArgMenuItem, ClassValue, useClassNames } from 'src/components/basic';
import { ArgonosModule } from '../../../components/application/modules';
import { useExtensions } from '../../hooks/use-extensions';
import { Extension } from '../../models/extension';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';

const CLASSNAME = 'settings-extension-action-menu';

const messages = defineMessages({
    delete: {
        id: 'settings-extension-action-menu.delete',
        defaultMessage: 'Delete',
    },
    activate: {
        id: 'settings-extension-action-menu.activate',
        defaultMessage: 'Activate',
    },
    deactivate: {
        id: 'settings-extension-action-menu.deactivate',
        defaultMessage: 'Deactivate',
    },
    export: {
        id: 'settings-extension-action-menu.export',
        defaultMessage: 'Export',
    },
});

export interface ExtensionActionMenuProps {
    extension: Extension;
    argonosModule: ArgonosModule | undefined;
    className?: ClassValue;
    onSuccess?: () => void;
}

export function ExtensionActionMenu(props: ExtensionActionMenuProps) {
    const {
        extension,
        argonosModule,
        className,
        onSuccess,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const { handleUpdateStatus, handleDeleteConfirmModal, handleExport, popoverVisible, setPopoverVisible } = useExtensions([extension], argonosModule, onSuccess);

    const allowDeleteExtension = useHasPermission<SettingsPermissions>('admin.moduleExtensions.delete');
    const allowPublishExtension = useHasPermission<SettingsPermissions>('admin.moduleExtensions.publish');

    const handleActivate = useCallback(() => {
        setPopoverVisible(false);
        handleUpdateStatus(true);
    }, [handleUpdateStatus, setPopoverVisible]);

    const handleDeactivate = useCallback(() => {
        setPopoverVisible(false);
        handleUpdateStatus(false);
    }, [handleUpdateStatus, setPopoverVisible]);

    const actionsMenu = (
        <ArgMenu data-testid='extension-action-button-menu'>
            {allowPublishExtension && !extension.state.enabled && <ArgMenuItem
                key={messages.activate.id}
                data-testid='activate'
                onClick={handleActivate}
                label={messages.activate}
            />}
            {allowPublishExtension && extension.state.enabled && <ArgMenuItem
                key={messages.deactivate.id}
                data-testid='deactivate'
                onClick={handleDeactivate}
                label={messages.deactivate}
            />}
            {allowDeleteExtension && <ArgMenuItem
                key={messages.delete.id}
                data-testid='delete'
                onClick={handleDeleteConfirmModal}
                label={messages.delete}
            />}
            <ArgMenuItem
                key={messages.export.id}
                data-testid='export'
                onClick={handleExport}
                label={messages.export}
            />
        </ArgMenu>
    );

    return (
        <ArgButton
            className={classNames('&', className)}
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={popoverVisible}
            onPopoverVisibleChange={setPopoverVisible}
            popoverPlacement='bottomLeft'
        />
    );
}
