import { defineMessages } from 'react-intl';

import { defineKeyBindings, KeyBindingScopeDescriptor } from '../../components/basic/keybindings/keybinding';
import { SCOPE as CASE_SCOPE } from './case';

const messages = defineMessages({
    overviewScope: {
        id: 'exploration.keybindings.OverviewScope',
        defaultMessage: 'Overview Page',
    },
    newExploration: {
        id: 'exploration.keybindings.NewExploration',
        defaultMessage: 'New visualization',
    },
    newExplorationGraph: {
        id: 'exploration.keybindings.NewExplorationGraph',
        defaultMessage: 'New visualization graph',
    },
    newExplorationMap: {
        id: 'exploration.keybindings.NewExplorationMap',
        defaultMessage: 'New visualization map',
    },
    newExplorationTable: {
        id: 'exploration.keybindings.NewExplorationTable',
        defaultMessage: 'New visualization table',
    },
    newExplorationChronogram: {
        id: 'exploration.keybindings.NewExplorationChronogram',
        defaultMessage: 'New visualization chronogram',
    },
    newBrief: {
        id: 'exploration.keybindings.NewBrief',
        defaultMessage: 'New brief',
    },
    newDashboard: {
        id: 'exploration.keybindings.NewDashboard',
        defaultMessage: 'New dashboard',
    },
    newDatasource: {
        id: 'exploration.keybindings.NewDatasource',
        defaultMessage: 'New data source',
    },
    newChart: {
        id: 'exploration.keybindings.NewChart',
        defaultMessage: 'New chart',
    },
});

export const SCOPE: KeyBindingScopeDescriptor = {
    id: 'exploration.Overview',
    name: messages.overviewScope,
    extends: CASE_SCOPE,
};

export const KEY_BINDING_NEW_VISUALIZATION = defineKeyBindings({
    newExploration: {
        id: 'exploration.overview.NewExploration',
        scope: SCOPE,
        name: messages.newExploration,
        defaultKeys: 'alt+E',
    },
});

export const KEY_BINDING_NEW_VISUALIZATION_MAP = defineKeyBindings({
    newExplorationMap: {
        id: 'exploration.overview.NewExplorationMap',
        scope: SCOPE,
        name: messages.newExplorationMap,
    },
});

export const KEY_BINDING_NEW_VISUALIZATION_GRAPH = defineKeyBindings({
    newExplorationGraph: {
        id: 'exploration.overview.NewExplorationGraph',
        scope: SCOPE,
        name: messages.newExplorationGraph,
    },
});

export const KEY_BINDING_NEW_VISUALIZATION_TABLE = defineKeyBindings({
    newExplorationTable: {
        id: 'exploration.overview.NewExplorationTable',
        scope: SCOPE,
        name: messages.newExplorationTable,
    },
});

export const KEY_BINDING_NEW_VISUALIZATION_CHRONOGRAM = defineKeyBindings({
    newExplorationChronogram: {
        id: 'exploration.overview.NewExplorationChronogram',
        scope: SCOPE,
        name: messages.newExplorationChronogram,
    },
});

export const KEY_BINDINGS = defineKeyBindings({
    // Overview
    newDashboard: {
        id: 'exploration.overview.NewDashboard',
        scope: SCOPE,
        name: messages.newDashboard,
    },
    newDatasource: {
        id: 'exploration.overview.NewDatasource',
        scope: SCOPE,
        name: messages.newDatasource,
    },
    newChart: {
        id: 'exploration.overview.NewChart',
        scope: SCOPE,
        name: messages.newChart,
    },
});

export const KEY_BINDINGS_BRIEF = defineKeyBindings({
    newBrief: {
        id: 'exploration.overview.NewBrief',
        scope: SCOPE,
        name: messages.newBrief,
        defaultKeys: 'alt+B',
    },
});
