import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Avatar } from 'antd';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import { isEqual, reduce } from 'lodash';

import {
    ArgFormLabel,
    ArgInputText,
    ArgModal,
    ArgRenderedText,
    ArgSwitch,
    ArgTabsSubLevel,
    ProgressMonitor,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useMemoDeepEquals,
} from 'src/components/basic';
import { computeDigram, computeUserName } from 'src/utils/digram';
import { EditUserDTO, Role, RolesScope } from 'src/settings/models/dtoApi';
import { User } from 'src/model/user';
import { UsersAndGroupsStateContext } from '../../providers/usersState';
import { getRolesFromRoleIds } from '../../utils';
import { AssignRoles } from 'src/settings/roles/components/assign-roles';
import { UserMetadata, UserMetadataValue, UserProfileField } from '../../../../model/user-metadata';
import { UserMetadataTable } from 'src/components/common/user-metadata/user-metadata-table';
import { SettingsConnector } from '../../../connectors/settings-connector';
import { UsersAdminConnector } from '../../../../utils/connectors/users-admin-connector';

import './edit-user-modal.less';

const FORCE_MANDATORY = false;

const METADATA_PREFIX = '$$$$:';

interface FormFields {
    [key: string]: any;

    firstName?: string;
    lastName?: string;
    displayName?: string;
    invalidUsername?: string;
    isActive?: boolean;
    roleIds?: string[];
}

type FormFieldsErrors = Partial<Record<keyof FormFields, boolean>>;

export const messages = defineMessages({
    title: {
        id: 'settings.edit-user-modal.title',
        defaultMessage: 'Edit User',
    },
    fieldUsername: {
        id: 'settings.edit-user-modal.field.username',
        defaultMessage: 'Username',
    },
    required: {
        id: 'settings.edit-user-modal.required',
        defaultMessage: 'Required',
    },
    fieldFirstName: {
        id: 'settings.edit-user-modal.field.firstName',
        defaultMessage: 'First Name',
    },
    fieldLastName: {
        id: 'settings.edit-user-modal.field.lastName',
        defaultMessage: 'Last Name',
    },
    fieldFullName: {
        id: 'settings.edit-user-modal.field.fullName',
        defaultMessage: 'Full Name',
    },
    fieldRoles: {
        id: 'settings.edit-user-modal.field.roles',
        defaultMessage: 'Roles',
    },
    fieldAdmin: {
        id: 'settings.edit-user-modal.field.admin',
        defaultMessage: 'Admin',
    },
    isActive: {
        id: 'settings.edit-user-modal.field.isActive',
        defaultMessage: 'yes',
    },
    isInactive: {
        id: 'settings.edit-user-modal.field.isInactive',
        defaultMessage: 'no',
    },
    submit: {
        id: 'settings.edit-user-modal.submitButton',
        defaultMessage: 'Modify',
    },
    cancel: {
        id: 'settings.edit-user-modal.cancelButton',
        defaultMessage: 'Cancel',
    },
    editUserErrorMsg: {
        id: 'settings.error-message.editing-user',
        defaultMessage: 'Something went wrong while editing the user',
    },
    active: {
        id: 'settings.edit-user-modal.active',
        defaultMessage: 'Active',
    },
    avatar: {
        id: 'settings.edit-user-modal.avatar',
        defaultMessage: 'Avatar',
    },
    loadRolesError: {
        id: 'settings.edit-user-modal.loadRolesError',
        defaultMessage: 'An error has occured while loading roles',
    },
    accountTabTitle: {
        id: 'settings.edit-user-modal.accountTabTitle',
        defaultMessage: 'Account',
    },
    propertiesTabTitle: {
        id: 'settings.edit-user-modal.propertiesTabTitle',
        defaultMessage: 'Properties',
    },
    mandatory: {
        id: 'settings.edit-user-modal.Mandatory',
        defaultMessage: 'Mandatory',
    },
});

const TABS = [{
    key: 'account',
    title: messages.accountTabTitle,
}, {
    key: 'properties',
    title: messages.propertiesTabTitle,
}];

export interface EditUserModalProps {
    closeModal: () => void;
    user: User;
    isCurrentUser?: boolean;
    userProfilesFields?: UserProfileField[];
}

export function EditUserModal(props: EditUserModalProps) {
    const {
        closeModal,
        user,
        isCurrentUser,
        userProfilesFields,
    } = props;

    const classNames = useClassNames('settings-edit-user-modal');
    const notifications = useArgNotifications();

    const [isActive, setIsActive] = useState(user.isActive);
    const [userRoles, setUserRoles] = useState<Role[]>();
    const { setUsers, allRoles } = useContext(UsersAndGroupsStateContext);

    const [loadRoles, progressMonitorRoles] = useCallbackAsync(async (progressMonitor?: ProgressMonitor) => {
        if (!user) {
            return;
        }

        try {
            const userRoles = await SettingsConnector.getInstance().getAllUserRoles(user.id, progressMonitor);
            setUserRoles(userRoles);
        } catch (error) {
            if (progressMonitor?.isCancelled) {
                throw error;
            }
            console.error(error);
            notifications.snackError({ message: messages.loadRolesError }, error as Error);
        }
    }, [notifications, user]);

    useEffect(() => {
        if (user) {
            loadRoles();
        }
    }, [user]);

    const updateUser = useCallback((progressMonitor: ProgressMonitor, formValues: FormFields, newProfileFields?: UserMetadata) => {
        const editUserPayload: EditUserDTO = {
            displayName: formValues.displayName,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            userName: user.userName,
            isActive: isActive,
            profile: newProfileFields,
        };

        const sub1 = new SubProgressMonitor(progressMonitor, 1);

        return UsersAdminConnector.getInstance().editUser(editUserPayload, user.id, sub1);
    }, [isActive, user.id, user.userName]);

    const updateRoles = useCallback((progressMonitor: ProgressMonitor, rolesToUpdate: Role[]) => {
        const rolesScopes = [...new Set(rolesToUpdate.map(role => role.scope as RolesScope))];

        const apiCalls = rolesScopes.map((scope) => {
            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            const scopedRoleIds = rolesToUpdate.filter(role => role.scope === scope).map(role => role.id);

            return SettingsConnector.getInstance().editUserRoles(user.id, scopedRoleIds, scope, sub2);
        });

        return Promise.all(apiCalls);
    }, [user.id]);

    const [submitForm, submitProcessing] = useCallbackAsync(async (progressMonitor: ProgressMonitor, formValues: FormFields) => {
        if (!formValues.displayName) {
            return;
        }

        const newProfileMetadata = fieldsToMetadata(userProfilesFields, formValues);
        const userChanged = formValues.displayName !== user.displayName
            || formValues.firstName !== user.firstName
            || formValues.lastName !== user.lastName
            || !isEqual(user.profile, newProfileMetadata)
            || user.isActive !== isActive;

        const roleIdsToAdd = userRoles ? formValues.roleIds?.filter(roleId => userRoles.findIndex(role => role.id === roleId) < 0) : formValues.roleIds;
        const rolesToAdd = getRolesFromRoleIds(allRoles, roleIdsToAdd);
        const rolesToDelete = userRoles?.filter(role => !formValues.roleIds?.includes(role.id));
        const rolesToUpdate = rolesToAdd ? rolesToDelete ? [...rolesToAdd, ...rolesToDelete] : rolesToAdd : rolesToDelete;
        const rolesChanged = rolesToUpdate !== undefined && rolesToUpdate.length > 0;

        const updateCalls = [
            ...(userChanged ? [updateUser(progressMonitor, formValues, newProfileMetadata)] : []),
            ...(rolesChanged ? [updateRoles(progressMonitor, rolesToUpdate)] : []),
        ];

        if (updateCalls.length === 0) {
            closeModal();

            return;
        }

        try {
            await Promise.all(updateCalls);

            const sub3 = new SubProgressMonitor(progressMonitor, 1);
            const newUser = await UsersAdminConnector.getInstance().getUser(user.id, sub3);

            setUsers((currentUsers) =>
                currentUsers.map((existingUser) => {
                    return existingUser.id === user.id ? newUser : existingUser;
                }),
            );
            closeModal();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.editUserErrorMsg }, error as Error);
            console.error(error);
        }
    }, [allRoles, closeModal, isActive, notifications, setUsers, user.displayName, user.firstName, user.isActive,
        user.id, user.lastName, user.profile, userProfilesFields, userRoles, updateRoles, updateUser]);

    const validateForm = useCallback((values: FormFields) => {
        const errors: FormFieldsErrors = {};
        if (!values.displayName) {
            errors.displayName = true;
        }
        if (!values.roleIds || values.roleIds.length === 0) {
            errors.roleIds = true;
        }

        if (userProfilesFields) {
            userProfilesFields.forEach((f: UserProfileField) => {
                if (!f.isMandatory && !FORCE_MANDATORY) {
                    return;
                }
                if (values[METADATA_PREFIX + f.id]) {
                    return;
                }

                errors[METADATA_PREFIX + f.id] = true;
            });
        }

        return errors;
    }, [userProfilesFields]);

    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        resetForm,
        values: formValues,
        errors: formErrors,
    } = useFormik<FormFields>({
        initialValues: {
            displayName: user.displayName || '',
            lastName: user.lastName,
            firstName: user.firstName,
            roleIds: userRoles?.map((role) => role.id),
            ...metadataToFields(userProfilesFields, user.profile),
        },
        validateOnChange: false,
        validate: validateForm,
        onSubmit: submitForm,
        enableReinitialize: true,
    });

    const handleCloseModal = useCallback(() => {
        resetForm();
        closeModal();
    }, [closeModal, resetForm]);

    const handleMetaChange = useCallback((field: UserProfileField, value: any) => {
        setFieldValue(`${METADATA_PREFIX}${field.id}`, value);
    }, [setFieldValue]);

    const [activeTab, setActiveTab] = useState<string | undefined>('account');

    const userMetadata = useMemoDeepEquals<Record<string, any>>(() => {
        const ret = reduce(formValues, (acc: Record<string, any>, value: any, key: string) => {
            if (!key.startsWith(METADATA_PREFIX)) {
                return acc;
            }
            acc[key.substring(METADATA_PREFIX.length)] = value;

            return acc;
        }, {} as Record<string, any>);

        return ret;
    }, [formValues]);

    const userProfilesErrors = useMemo<Record<string, ArgRenderedText>>(() => {
        const ret = reduce(formErrors, (acc: Record<string, ArgRenderedText>, value, key) => {
            if (!key.startsWith(METADATA_PREFIX)) {
                return acc;
            }
            const k = key.substring(METADATA_PREFIX.length);
            acc[k] = messages.mandatory;

            return acc;
        }, {} as Record<string, ArgRenderedText>);

        return ret;
    }, [formErrors]);

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            className={classNames('&')}
            onClose={handleCloseModal}
            onCancel={handleCloseModal}
            okDisabled={submitProcessing?.isRunning}
            loading={submitProcessing?.isRunning}
            cancelText={messages.cancel}
            onOk={() => handleSubmit()}
            okDataTestId='edit'
        >
            <div className={classNames('&-form')}>
                <ArgTabsSubLevel tabs={TABS} onChange={setActiveTab} activeTabKey={activeTab} />

                <div className={classNames('&-scrollable-container')}>

                    <div className={classNames({ hide: activeTab !== 'account' })}>

                        <div className={classNames('&-switch-and-id')}>
                            {!isCurrentUser && (
                                <div className={classNames('&-active-and-switch')}>
                                    <div className={classNames('&-active')}>
                                        <FormattedMessage {...messages.active} />
                                    </div>
                                    <ArgSwitch
                                        checked={isActive}
                                        onClick={() => {
                                            setIsActive(!isActive);
                                        }}
                                        size='large'
                                        label={isActive ? messages.isActive : messages.isInactive}
                                        disabled={!!user.identityIssuer}
                                    />
                                </div>
                            )}
                            <div>
                                GUID: {'{'} {user.id} {'}'}
                            </div>
                        </div>
                        <div className={classNames('&-name-and-photo')}>
                            <div className={classNames('&-avatar-and-label')}>
                                <FormattedMessage {...messages.avatar} />
                                <Avatar
                                    key={formValues.firstName}
                                    alt={formValues.firstName?.split('')[0]}
                                    className={classNames('&-avatar')}
                                    size={80}
                                >
                                    {computeDigram(computeUserName({ ...formValues }))}
                                </Avatar>
                            </div>
                            <div className={classNames('&-first-name-last-name')}>
                                <ArgFormLabel
                                    propertyName={messages.fieldLastName}
                                    addedRow={true}
                                    className={classNames('&-label')}
                                >
                                    <ArgInputText
                                        value={formValues.lastName}
                                        onInputChange={handleChange('lastName')}
                                        data-testid='lastName'
                                        disabled={!!user.identityIssuer}
                                    />
                                </ArgFormLabel>
                                <ArgFormLabel
                                    propertyName={messages.fieldFirstName}
                                    addedRow={true}
                                    className={classNames('&-label')}
                                >
                                    <ArgInputText
                                        value={formValues.firstName}
                                        onInputChange={handleChange('firstName')}
                                        data-testid='firstName'
                                        disabled={!!user.identityIssuer}
                                    />
                                </ArgFormLabel>
                            </div>
                        </div>
                        <ArgFormLabel
                            propertyName={messages.fieldFullName}
                            className={classNames('&-label')}
                            required={messages.required}
                        >
                            <ArgInputText
                                value={formValues.displayName}
                                onInputChange={handleChange('displayName')}
                                data-testid='fullName'
                                state={formErrors.displayName ? 'invalid' : undefined}
                                disabled={!!user.identityIssuer}
                            />
                        </ArgFormLabel>
                        <div className={classNames('&-username')}>
                            <ArgFormLabel
                                className={classNames('&-label')}
                                propertyName={messages.fieldUsername}
                                addedRow={true}
                                required={messages.required}
                            >
                                {user.userName}
                            </ArgFormLabel>
                        </div>
                        <ArgFormLabel
                            propertyName={messages.fieldRoles}
                            addedRow={true}
                            className={classNames('&-label')}
                            required={messages.required}
                        >
                            {!progressMonitorRoles?.isRunning && (
                                <AssignRoles
                                    allRoles={allRoles}
                                    value={userRoles || []}
                                    onChange={(roleNamesSelected) =>
                                        setFieldValue('roleIds', roleNamesSelected.map((role) => role.id))}
                                    state={formErrors.roleIds ? 'invalid' : undefined}
                                />)}
                        </ArgFormLabel>
                    </div>

                    <div className={classNames({ hide: activeTab !== 'properties' })}>
                        <div className={classNames('&-properties-container')}>
                            <UserMetadataTable
                                className={classNames('&-properties')}
                                metadata={userMetadata}
                                onChange={handleMetaChange}
                                joinMultivalue={true}
                                userProfilesFields={userProfilesFields}
                                formErrors={userProfilesErrors}
                                disabled={!!user.identityIssuer}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ArgModal>
    );
}

function metadataToFields(userProfilesFields: UserProfileField[] | undefined, metadata: UserMetadata | undefined) {
    return userProfilesFields?.reduce((obj, field) => {
        let v = metadata?.[field.id];
        if (v === undefined) {
            return obj;
        }

        if (field.isMultivalued && Array.isArray(v)) {
            v = v.join(', ');
        }

        obj[`${METADATA_PREFIX}${field.id}`] = v;

        return obj;
    }, {} as Record<string, any>);
}

function fieldsToMetadata(userProfilesFields: UserProfileField[] | undefined, formValues: FormFields) {
    return userProfilesFields?.reduce((obj, field) => {
        let v = formValues[`${METADATA_PREFIX}${field.id}`];
        if (v === undefined) {
            return obj;
        }

        if (field.isMultivalued) {
            v = v.split(',').map((s: string) => s.trim()).filter((s: string) => s);
        }

        obj[field.id] = v;

        return obj;
    }, {} as Record<string, UserMetadataValue>);
}
