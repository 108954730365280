import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { filter, forEach, isEmpty, map, reduce } from 'lodash';

import {
    ArgButton,
    ArgIcon,
    ArgInputSearch,
    ArgModal,
    ArgUploaderButton,
    ClassValue,
    GLOBAL_SCOPE_ID,
    highlightSplit,
    KeyBindingDescriptor,
    KeyBindingsConfiguration,
    KeyBindingsContext,
    KeyBindingScopeDescriptor,
    KeyBindingsScopeIdentifier,
    KeyCommand,
    normalizeText,
    useClassNames,
    useArgNotifications,
} from '../../basic';
import { KeyBindingEditor } from './keybinding-editor';
import { Environment } from '../../../utils/environment';
import { downloadFile } from 'src/utils/file';

import './keybinding-panel.less';

const KEYBOARD_LAYOUT_EXTENSION = '.keyboard-shortcuts';

const messages = defineMessages({
    title: {
        id: 'common.keybindings-panel.Title',
        defaultMessage: 'Keyboard Shortcuts',
    },
    importLabel: {
        id: 'common.keybindings-panel.Import',
        defaultMessage: 'Import',
    },
    exportLabel: {
        id: 'common.keybindings-panel.Export',
        defaultMessage: 'Export',
    },
    searchPlaceholder: {
        id: 'common.keybindings-panel.SearchPlaceholder',
        defaultMessage: 'Search',
    },
    resetLabel: {
        id: 'common.keybindings-panel.Reset',
        defaultMessage: 'Reset',
    },
    noResetTooltip: {
        id: 'common.keybindings-panel.NoResetTooltip',
        defaultMessage: 'There is nothing to reset',
    },
    noExportTooltip: {
        id: 'common.keybindings-panel.NoExportTooltip',
        defaultMessage: 'Current configuration cannot be exported',
    },
    importError: {
        id: 'common.keybindings-panel.ImportError',
        defaultMessage: 'Can not import this file',
    },
    importBadFormat: {
        id: 'common.keybindings-panel.ImportBadFormat',
        defaultMessage: 'The content of the selected file is invalid',
    },
    importSucceed: {
        id: 'common.keybindings-panel.ImportSucceed',
        defaultMessage: 'Keyboard shortcuts imported',
    },
    errorTitle: {
        id: 'common.keybindings-panel.ErrorTitle',
        defaultMessage: 'Error',
    },
    filename: {
        id: 'common.keybindings-panel.ExportFileName',
        defaultMessage: '{appName}-{year, number, ::group-off}-{month, number, ::00}-{date, number, ::00}-{hours, number, ::00}-{minutes, number, ::00}-{seconds, number, ::00}{extension}',
    },
    emptyResult: {
        id: 'common.keybindings-panel.EmptyResult',
        defaultMessage: 'No results',
    },
});

interface ByScope {
    scope: KeyBindingScopeDescriptor;
    keys: KeyBindingDescriptor[];
    extended?: boolean;
}

interface KeyBindingProps {
    visible?: boolean;
    className?: ClassValue;
    onClose?: () => void;
}


export function KeyBindingPanel(props: KeyBindingProps) {
    const {
        visible,
        className,
        onClose,
    } = props;
    const intl = useIntl();
    const notifications = useArgNotifications();

    const classNames = useClassNames('keybindings-panel');

    const keyBindingContext = useContext(KeyBindingsContext);

    const [userConfig, setUserConfig] = useState<KeyBindingsConfiguration | undefined>(keyBindingContext?.getUserConfig());

    useEffect(() => {
        if (!keyBindingContext) {
            return;
        }

        function update(config?: KeyBindingsConfiguration) {
            setUserConfig(config);
        }

        keyBindingContext.eventEmitter.on('UserConfigChanged', update);

        return () => {
            keyBindingContext.eventEmitter.off('UserConfigChanged', update);
        };
    }, [keyBindingContext]);

    const [searchedToken, setSearchedToken] = useState<string>('');

    useEffect(() => {
        return () => {
            setSearchedToken('');
        };
    }, [visible]);

    const byScopes: ByScope[] = useMemo(() => {
        const defs = keyBindingContext?.defs ?? {};

        const m: Record<KeyBindingsScopeIdentifier, ByScope> = {};

        let ret = reduce(defs, (acc, def) => {
            let scope = m[def.scope.id];
            if (!scope) {
                scope = {
                    scope: def.scope,
                    keys: [],
                };
                m[def.scope.id] = scope;
                acc.push(scope);
            }

            scope.keys.push(def);

            return acc;
        }, [] as ByScope[]);

        forEach(ret, (byScope) => {
            byScope.extended = true;
            for (let b = byScope; b;) {
                if (!b.scope.extends) {
                    break;
                }

                const parent = m[b.scope.extends.id];
                if (!parent) {
                    break;
                }

                byScope.keys.push(...parent.keys.filter((k) => !k.global));

                b = parent;

                if (b.extended) {
                    break;
                }
            }
        });

        ret = filter(ret, (byScope) => byScope.scope.hidden !== true);

        forEach(ret, (byScope) => {
            byScope.keys.sort((k1, k2) => {
                const label1 = intl.formatMessage(k1.name);
                const label2 = intl.formatMessage(k2.name);

                return label1.localeCompare(label2);
            });
        });

        ret.sort((s1, s2) => {
            if (s1.scope.id === GLOBAL_SCOPE_ID) {
                if (s2.scope.id === GLOBAL_SCOPE_ID) {
                    return 0;
                }

                return -1;
            }
            if (s2.scope.id === GLOBAL_SCOPE_ID) {
                return 1;
            }

            const label1 = intl.formatMessage(s1.scope.name);
            const label2 = intl.formatMessage(s2.scope.name);

            return label1.localeCompare(label2);
        });

        return ret;
    }, [keyBindingContext]);

    const handleResetClick = useCallback(() => {
        keyBindingContext?.setUserConfig(undefined);
    }, [keyBindingContext]);

    const handleUserConfigurationChange = useCallback((scope: KeyBindingScopeDescriptor, keyBindingDescriptor: KeyBindingDescriptor, keyCommand: KeyCommand | undefined) => {
        if (keyCommand === undefined) {
            keyBindingContext?.resetUserConfig(scope, keyBindingDescriptor);

            return;
        }
        keyBindingContext?.changeUserConfig(scope, keyBindingDescriptor, keyCommand);
    }, [keyBindingContext]);

    const handleSearchedTokenChange = useCallback((searchedToken: string) => {
        setSearchedToken(searchedToken);
    }, []);

    const handleUploadMethod = useCallback((files: File[]) => {
        const fileReader = new FileReader();
        fileReader.onloadend = (() => {
            const result = fileReader.result;

            if (typeof (result) !== 'string' || !result?.length) {
                notifications.snackError({
                    message: messages.errorTitle,
                    description: messages.importBadFormat,
                });

                return;
            }

            let json;
            try {
                json = JSON.parse(result);
            } catch (x) {
                console.error(x);
                notifications.snackError({
                    message: messages.errorTitle,
                    description: messages.importBadFormat,
                });

                return;
            }

            if (!Array.isArray(json.scopes)) {
                notifications.snackError({
                    message: messages.errorTitle,
                    description: messages.importBadFormat,
                });

                return;
            }

            notifications.snackInfo({ message: messages.importSucceed });
            keyBindingContext?.setUserConfig({
                scopes: [...json.scopes],
            });
        });
        fileReader.onerror = (() => {
            console.error(fileReader.error);
            notifications.snackError({ message: messages.importError });
            try {
                fileReader.abort();
            } catch (x) {
                console.error(x);
            }
        });

        fileReader.readAsText(files[0]);
    }, [keyBindingContext, notifications]);

    const handleExportClick = useCallback(() => {
        const exportedJSON = {
            ...keyBindingContext?.getUserConfig() || {},

            exportedDate: new Date(),
        };

        const now = new Date();

        const fileName = intl.formatMessage(messages.filename, {
            appName: Environment.appName,
            extension: KEYBOARD_LAYOUT_EXTENSION,
            year: now.getFullYear(),
            month: now.getMonth(),
            date: now.getDate(),
            hours: now.getHours(),
            minutes: now.getMinutes(),
            seconds: now.getSeconds(),
        });
        downloadFile(fileName, JSON.stringify(exportedJSON, null, 2), 'application/json');
    }, [intl, keyBindingContext]);

    const filteredByScopes: ByScope[] = useMemo(() => {
        let _byScopes;
        if (searchedToken) {
            const normalizedSearchedToken = normalizeText(searchedToken);

            const ret: ByScope[] = [];
            forEach(byScopes, (byScope) => {
                const scope = byScope.scope;
                const scopeLabel = normalizeText(intl.formatMessage(scope.name));
                if (scopeLabel.indexOf(normalizedSearchedToken) >= 0) {
                    ret.push(byScope);

                    return;
                }

                let ks: KeyBindingDescriptor[];

                forEach(byScope.keys, (keyBindingDescriptor) => {
                    const keyLabel = normalizeText(intl.formatMessage(keyBindingDescriptor.name));
                    if (keyLabel.indexOf(normalizedSearchedToken) < 0) {
                        return;
                    }

                    if (!ks) {
                        ks = [];
                        ret.push({
                            scope,
                            keys: ks,
                        });
                    }
                    ks.push(keyBindingDescriptor);
                });
            });

            _byScopes = ret;
        } else {
            _byScopes = Object.values(byScopes);
        }

        return _byScopes;
    }, [byScopes, searchedToken]);

    return <ArgModal
        visible={visible}
        title={messages.title}
        closable={true}
        onClose={onClose}
        width={800}
        footer={false}
        closeIcon={<ArgIcon name='icon-cross' />}
        className={classNames('&', 'arg-modal', className)}
    >
        <div className={classNames('&-header')}>
            <ArgInputSearch className={classNames('&-header-search')}
                            autoFocus={true}
                            onInputChange={handleSearchedTokenChange}
                            placeholder={messages.searchPlaceholder} />
            <ArgUploaderButton type='ghost'
                               size='large'
                               className={classNames('&-header-import')}
                               icon='icon-download'
                               label={messages.importLabel}
                               onChange={handleUploadMethod}
                               acceptedFiles={KEYBOARD_LAYOUT_EXTENSION}
            />
            <ArgButton type='ghost'
                       size='large'
                       className={classNames('&-header-export')}
                       icon='icon-upload'
                       disabled={isEmpty(userConfig)}
                       tooltip={isEmpty(userConfig) ? messages.noExportTooltip : undefined}
                       tooltipPlacement='bottom'
                       label={messages.exportLabel}
                       onClick={handleExportClick}
            />
            <ArgButton type='ghost'
                       size='large'
                       className={classNames('&-header-reset')}
                       icon='icon-reset'
                       onClick={handleResetClick}
                       disabled={isEmpty(userConfig)}
                       tooltip={isEmpty(userConfig) ? messages.noResetTooltip : undefined}
                       tooltipPlacement='bottom'
                       label={messages.resetLabel} />
        </div>
        <div className={classNames('&-body')}>
            {map(filteredByScopes, (byScope) => {
                const scope = byScope.scope;
                const userScopeBindings = userConfig?.scopes.find((s) => s.id === scope.id)?.bindings ?? [];

                let label: ReactNode = intl.formatMessage(scope.name);
                if (typeof (label) === 'string' && searchedToken) {
                    const refCount = { current: 0 };
                    label = highlightSplit(label, searchedToken, undefined, refCount);
                }

                return <div className={classNames('&-body-scope')} key={scope.id}>
                    <div className={classNames('&-body-scope-title')}>
                        {label}
                    </div>
                    {byScope.keys.map((keyBindingDescriptor) => {
                        return <KeyBindingEditor
                            key={keyBindingDescriptor.id}
                            keyBindingDescriptor={keyBindingDescriptor}
                            scopeDescriptor={scope}
                            userKeys={userScopeBindings}
                            definedKeys={byScope.keys}
                            searchedToken={searchedToken}
                            onUserConfigurationChange={(keyCommand) => handleUserConfigurationChange(scope, keyBindingDescriptor, keyCommand)}
                            className={classNames('&-body-scope-keydescriptor')}
                        />;
                    })}
                </div>;
            })}
            {isEmpty(filteredByScopes) && <div className={classNames('&-body-empty')}>
                <FormattedMessage {...messages.emptyResult} />
            </div>}
        </div>
    </ArgModal>;
}
