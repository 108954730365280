import { useCallback } from 'react';

import { ArgButton, ArgIcon, ArgInputText, useClassNames } from 'src/components/basic';
import { FormTab } from 'src/components/common/forms/model';
import { useReorder } from 'src/hooks/use-reorder';
import { FormPagesPanelItemMenu } from './form-pages-panel-item-menu';
import { FAKE_TAB_ID } from '../../utils';

import './form-pages-panel-item.less';

const PAGE_DND_TYPE = 'form-page';

export interface FormPagesPanelItemProps {
    tab: FormTab;
    index: number;
    editMode: boolean;
    setEditMode: (tab: FormTab | undefined) => void;
    movePolicies: (dragIndex: number, hoverIndex: number) => void;
    onReorder: (dragItem: { id: string | number; index: number }) => void;
    handleTabNameChange: (value: string | null, tab: FormTab) => void;
    handleRemovePage: (id: string) => void;
}

export function FormPagesPanelItem(props: FormPagesPanelItemProps) {
    const {
        tab,
        index,
        editMode,
        setEditMode,
        movePolicies,
        onReorder,
        handleTabNameChange,
        handleRemovePage,
    } = props;

    const classNames = useClassNames('form-pages-panel-item');

    const { handlerId, isDragging, drag, ref, canDrop } = useReorder(
        PAGE_DND_TYPE,
        movePolicies,
        index,
        tab,
        onReorder,
    );

    const draggable = index !== 0 && !editMode;
    const isFirstPage = index === 0;

    const handlePageDoubleClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>, tab: FormTab) => {
        event.stopPropagation();
        if (tab.id === FAKE_TAB_ID) {
            return;
        }

        setEditMode(tab);
    }, [setEditMode]);

    const _handleTabNameChange = useCallback((value: string | null) => {
        setEditMode(undefined);
        handleTabNameChange(value, tab);
    }, [handleTabNameChange, setEditMode, tab]);

    const cls = {
        '&-page-title': true,
        draggable,
        dragging: isDragging,
        'edit-mode': editMode,
        candrop: canDrop,
    };

    return (
        <li
            key={tab.id}
            ref={draggable ? ref : null}
            data-handler-id={handlerId}
            className={classNames('&', cls)}
            onDoubleClick={(event) => handlePageDoubleClick(event, tab)}
        >
            <div ref={draggable ? drag : null}>
                <ArgIcon
                    name='icon-6dots'
                    className={classNames('&-drag-icon')}
                />
            </div>
            <div className={classNames('&-title')}>
                {!editMode && (
                    <label className={classNames('&-title-label')}>
                        {tab.name}
                    </label>
                )}
                {editMode &&
                    <ArgInputText
                        className={classNames('&-title-input')}
                        initialValue={tab.name}
                        onChange={_handleTabNameChange}
                        autoFocus={true}
                        debounce={false}
                    />}
            </div>
            {editMode && !isFirstPage && (
                <ArgButton
                    icon='icon-trash'
                    size='node'
                    type='ghost'
                    onClick={() => handleRemovePage(tab.id)}
                    className={classNames('&-remove')}
                />)}
            {!editMode && (
                <FormPagesPanelItemMenu
                    className={classNames('&-menu')}
                    index={index}
                    tab={tab}
                    handleRemovePage={handleRemovePage}
                    setEditMode={setEditMode}
                />
            )}
        </li>
    );
}
