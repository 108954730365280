import { Dispatch, SetStateAction, useState } from 'react';
import { defineMessages } from 'react-intl';

import { ArgFormLabel, ArgInputText, ArgModal, useArgNotifications } from 'src/components/basic';
import { FullOntology, FullOntologyObjectType } from '../../types';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

export const messages = defineMessages({
    title: {
        id: 'settings.rename-object-modal.title',
        defaultMessage: 'Rename object',
    },
    fieldName: {
        id: 'settings.rename-object-modal.field.name',
        defaultMessage: 'Object name',
    },
    submit: {
        id: 'settings.rename-object-modal.submitButton',
        defaultMessage: 'Rename',
    },
    renameVertexError: {
        id: 'settings.rename-object-modal.renameVertexError',
        defaultMessage: 'Something went wrong while trying to rename the object',
    },
    required: {
        id: 'settings.rename-object-modal.required',
        defaultMessage: 'Required',
    },
});

export interface RenameObjectModalProps {
    closeModal: () => void;
    vertex: FullOntologyObjectType;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
}

export function RenameObjectModal({
    closeModal,
    vertex,
    ontology,
    setOntology,
}: RenameObjectModalProps) {
    const notifications = useArgNotifications();

    const [objectName, setObjectName] = useState<string>(() => vertex.displayName);
    const [loading, setLoading] = useState(false);

    const handleObjectTypeNameChange = (newDisplayName: string | null) => {
        setObjectName(newDisplayName?.trim() ?? '');
    };

    const renameObject = async () => {
        setLoading(true);

        try {
            await ontologiesConnector.editOntologyObject({
                ontologyId: ontology.id,
                name: vertex.name,
                newDisplayName: objectName,
                newProperties: vertex.properties,
            });
            const newOntology = await ontologiesConnector.getFullOntology(ontology.id);
            setOntology(newOntology);
            setLoading(false);
            handleObjectTypeNameChange('');
            closeModal();
        } catch (e) {
            notifications.snackError({ message: messages.renameVertexError }, e as Error);
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        renameObject();
    };

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            onCancel={closeModal}
            okText={messages.submit}
            onOk={renameObject}
            okDisabled={objectName === ''}
            loading={loading}
        >
            <form autoComplete='off' onSubmit={handleSubmit}>
                <ArgFormLabel
                    propertyName={messages.fieldName}
                    required={messages.required}
                >
                    <ArgInputText
                        value={objectName}
                        autoSelect={true}
                        onChange={handleObjectTypeNameChange}
                    />
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
}
