import { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton, ArgCheckbox, ArgImage, ArgMessageRenderer, ArgSwitch, highlightSplit, SelectionProvider, useClassNames, useIsSelected } from 'src/components/basic';
import { ArgonosModule } from '../../../components/application/modules';
import { useExtensions } from '../../hooks/use-extensions';
import { Extension } from '../../models/extension';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';

import './extension-card-item.less';

const CLASSNAME = 'settings-extension-card-item';
const messages = defineMessages({
    active: {
        id: 'settings.extension-card-item.active',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'settings.extension-card-item.inactive',
        defaultMessage: 'Inactive',
    },
    delete: {
        id: 'settings.extension-card-item.delete',
        defaultMessage: 'Delete',
    },
    byAuthor: {
        id: 'settings.extension-card-item.byAuthor',
        defaultMessage: 'By {author}',
    },
});

export interface ExtensionCardItemProps {
    extension: Extension;
    argonosModule: ArgonosModule | undefined;
    search?: string;
    selectionProvider?: SelectionProvider<Extension>;
    onSuccess?: () => void;
}

export function ExtensionCardItem(props: ExtensionCardItemProps) {
    const {
        extension,
        argonosModule,
        search,
        selectionProvider,
        onSuccess,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const { handleUpdateStatus, handleDeleteConfirmModal } = useExtensions([extension], argonosModule, onSuccess);

    const allowDeleteExtension = useHasPermission<SettingsPermissions>('admin.moduleExtensions.delete');
    const allowPublishExtension = useHasPermission<SettingsPermissions>('admin.moduleExtensions.publish');

    const isSelected = useIsSelected(selectionProvider, extension);

    const handleSelectCardItem = useCallback(() => {
        if (!selectionProvider) {
            return;
        }
        if (selectionProvider.has(extension)) {
            selectionProvider.remove(extension, 'cardItem');

            return;
        }
        selectionProvider.add(extension, 'cardItem');
    }, [extension, selectionProvider]);

    const cls = { checked: isSelected };

    return (
        <div className={classNames('&', cls)}>
            <div className={classNames('&-title')}>
                <div className={classNames('&-title-container')}>
                    <ArgImage className={classNames('&-logo')} src={extension.metadata.icon} />
                    <div className={classNames('&-title-name-author')}>
                        <div>{highlightSplit(extension.name, search)}</div>
                        <ArgMessageRenderer message={messages.byAuthor} messageValues={{ author: extension.metadata.author }} />
                    </div>
                </div>
                <ArgCheckbox
                    className={classNames('&-checkbox', cls)}
                    initialValue={isSelected}
                    value={isSelected}
                    size='node'
                    onChange={handleSelectCardItem}
                />
            </div>
            <div className={classNames('&-content')}>
                {extension.metadata?.description && highlightSplit(extension.metadata?.description, search)}
            </div>
            <div className={classNames('&-footer')}>
                {allowPublishExtension
                && <ArgSwitch
                        checked={extension.state.enabled}
                        onChange={handleUpdateStatus}
                        size='large'
                        label={extension.state.enabled
                            ? <ArgMessageRenderer message={messages.active} />
                            : <ArgMessageRenderer message={messages.inactive} />}
                />}
                {allowDeleteExtension
                && <ArgButton
                        type='ghost'
                        size='large'
                        icon='icon-trash'
                        label={messages.delete}
                        onClick={handleDeleteConfirmModal}
                />}
            </div>
        </div>
    );
}
