// import { createRoot } from 'react-dom/client';
import React from 'react';
import * as ReactDOM from 'react-dom/client';

import * as serviceWorker from './serviceWorker';
import { Application } from './application';

declare global {
    interface Window {
        React: typeof React;
    }
}

window.React = React;

disableBrowserContextMenu();
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);

// StrictMode is commented since it shows many bugs
// When in dev, I uncomment to fix, it will be uncommented once for all, when all bugs are fixed
root.render(
    // <StrictMode>
    <Application />,
    // </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

function disableBrowserContextMenu() {
    if (!(process.env.NODE_ENV === 'production' && localStorage?.KEEP_CONTEXT_MENU !== 'true')) {
        return;
    }
    document.body.oncontextmenu = (event) => {
        event.preventDefault();

        return false;
    };
}

if (localStorage?.ARG_DARK_SUPPORT) {
    document.body.classList.add('arg-dark-support');
}
