import { isEmpty, isNil, isUndefined, merge } from 'lodash';
import { defineMessages, IntlShape, MessageDescriptor } from 'react-intl';

import { ArgButton, ArgCheckbox, ArgCheckboxMinus, ArgTable2Column, BasicTag, renderText } from 'src/components/basic';
import { DateByUser } from 'src/components/common/date-by-user';
import { ItemActions } from 'src/settings/types/item-actions';
import { dateSorter, stringSorter } from 'src/utils/sorter';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType } from '../ontology/types';
import { ActionsMenu } from './components/action-menu';
import {
    RententionPolicyExpireAtProperty,
    RententionPolicyTimeToLive,
    RententionPolicyTimeToLiveBasedOnDelay,
    RententionPolicyTimeToLiveBasedOnProperty,
    RetentionPolicy,
    RetentionPolicyAction,
    RetentionPolicyActionEffect,
    RetentionPolicyActionEffectOnObject,
    RetentionPolicyActionEffectOnProperty,
    RetentionPolicyActionTargetKind,
    RetentionPolicyRow,
    RetentionPolicyRowSelection,
} from './types';
import { DEFAULT_VERTEX_STYLE } from 'src/exploration/constants/default-vertex-style';
import { DEFAULT_EDGE_STYLE } from 'src/exploration/constants/default-edge-style';
import { VertexStyle } from 'src/exploration/model/vertex';
import { EdgeStyle } from 'src/exploration/model/edge';

const messages = defineMessages({
    noRetentionPolicy: {
        id: 'settings.retention-rules.NoRetentionPolicy',
        defaultMessage: 'No Deletion',
    },
    expireAtPropertyForObject: {
        id: 'settings.retention-rules.ExpireAtProperty',
        defaultMessage: 'Delete on : "{objectType}.{dateProperty}"',
    },
    expireAtPropertyForProperty: {
        id: 'settings.retention-rules.ExpireAtPropertyForProperty',
        defaultMessage: 'Delete on : "{dateProperty}"',
    },
    retentionWithDelay: {
        id: 'settings.retention-rules.RetentionWithDelay',
        defaultMessage: 'Delete after : {unit, select, Year {{delay, plural, one {\"#\" Year} other {\"#\" Years}}} Month {{delay, plural, one {\"#\" Month} other {\"#\" Months} }} Week {{delay, plural, one {\"#\" Week} other {\"#\" Weeks}}} other {{delay, plural, one {\"#\" Day} other {\"#\" Days} }}}',
    },
    retentionWithDelayPropertyOnProperty: {
        id: 'settings.retention-rules.RetentionWithDelayPropertyOnProperty',
        defaultMessage: 'Delete after : "{delayProperty}" {unit, select, Year {Year(s)} Month {Month(s)} Week {Week(s)} other {Day(s)}}',
    },
    retentionWithDelayPropertyOnObject: {
        id: 'settings.retention-rules.RetentionWithDelayPropertyOnObject',
        defaultMessage: 'Delete after : "{objectType}.{delayProperty}" {unit, select, Year {Year(s)} Month {Month(s)} Week {Week(s)} other {Day(s)}}',
    },
    noRuleToDeleteTooltipMessage: {
        id: 'settings.retention-rules.NoRuleToDeleteTooltipMessage',
        defaultMessage: 'No rule to delete',
    },
    noRulesToDeleteTooltipMessage: {
        id: 'settings.retention-rules.NoRulesToDeleteTooltipMessage',
        defaultMessage: 'No rule to delete',
    },
});

export const containsPropertyEffects = (retentionPolicyAction?: RetentionPolicyAction) => !!retentionPolicyAction?.effects.some(isActionEffectOnProperty);

export const getRetentionPolicyActionEffects = (retentionPolicyAction: RetentionPolicyAction) => retentionPolicyAction.effects;
export const getRetentionPolicyActionTarget = (retentionPolicyAction: RetentionPolicyAction) => retentionPolicyAction.target;
export const getRetentionPolicyActionTargetKind = (retentionPolicyAction: RetentionPolicyAction) => getRetentionPolicyActionTarget(retentionPolicyAction).kind;
export const isVertex = (retentionPolicyAction: RetentionPolicyAction) => getRetentionPolicyActionTargetKind(retentionPolicyAction) === 'Vertex';
export const isEdge = (retentionPolicyAction: RetentionPolicyAction) => getRetentionPolicyActionTargetKind(retentionPolicyAction) === 'Edge';
export const getRetentionPolicyActionTargetType = (retentionPolicyAction: RetentionPolicyAction) => getRetentionPolicyActionTarget(retentionPolicyAction).type;

export const isActionEffectOnObject = (actionEffect: RetentionPolicyActionEffect): actionEffect is RetentionPolicyActionEffectOnObject => {
    return !!(actionEffect as RetentionPolicyActionEffectOnObject)?.object;
};
export const isActionEffectOnProperty = (actionEffect: RetentionPolicyActionEffect): actionEffect is RetentionPolicyActionEffectOnProperty => {
    return !!(actionEffect as RetentionPolicyActionEffectOnProperty)?.property;
};
export const getActionEffectOnPropertyTargetProperty = (actionEffect: RetentionPolicyActionEffect) => {
    return isActionEffectOnProperty(actionEffect) ? actionEffect.property.targetProperty : undefined;
};

export const getActionEffectRetention = (actionEffect: RetentionPolicyActionEffect) => (isActionEffectOnObject(actionEffect) ? actionEffect.object.retention : actionEffect.property.retention);

export const isTimeToLiveRetentionPolicy = (actionEffect: RetentionPolicyActionEffect) => {
    const retentionPolicy = getActionEffectRetention(actionEffect);

    return !!(retentionPolicy as RententionPolicyTimeToLive).timeToLive;
};

export const isTimeToLiveRetentionPolicyBasedOnProperty = (actionEffect: RetentionPolicyActionEffect) => {
    if (!isTimeToLiveRetentionPolicy(actionEffect)) {
        return false;
    }
    const retentionPolicy = getActionEffectRetention(actionEffect);

    return !!((retentionPolicy as RententionPolicyTimeToLive).timeToLive as RententionPolicyTimeToLiveBasedOnProperty).delayProperty;
};
export const isTimeToLiveRetentionPolicyBasedOnDelay = (actionEffect: RetentionPolicyActionEffect) => {
    if (!isTimeToLiveRetentionPolicy(actionEffect)) {
        return false;
    }
    const retentionPolicy = getActionEffectRetention(actionEffect);

    return !!((retentionPolicy as RententionPolicyTimeToLive).timeToLive as RententionPolicyTimeToLiveBasedOnDelay).delay;
};

export const isExpireAtRetentionPolicy = (actionEffect: RetentionPolicyActionEffect) => {
    const retentionPolicy = getActionEffectRetention(actionEffect);

    return !!(retentionPolicy as RententionPolicyExpireAtProperty).expireAtProperty;
};
export const getRetentionPolicyExpireAtProperty = (actionEffect: RetentionPolicyActionEffect) => {
    if (!isExpireAtRetentionPolicy(actionEffect)) {
        return undefined;
    }

    const retentionPolicy = getActionEffectRetention(actionEffect);

    return (retentionPolicy as RententionPolicyExpireAtProperty).expireAtProperty;
};
export const getRetentionPolicyDelay = (actionEffect: RetentionPolicyActionEffect) => {
    if (!isTimeToLiveRetentionPolicyBasedOnDelay(actionEffect)) {
        return undefined;
    }

    const retentionPolicy = getActionEffectRetention(actionEffect);

    return ((retentionPolicy as RententionPolicyTimeToLive).timeToLive as RententionPolicyTimeToLiveBasedOnDelay).delay;
};

export const getRetentionPolicyLabel = (
    actionEffect: RetentionPolicyActionEffect | undefined,
    objectyTypeType: string,
    isProperty?: boolean,
): { message: MessageDescriptor; values?: Record<string, any> } => {
    if (!actionEffect) {
        return {
            message: messages.noRetentionPolicy,
        };
    }

    const retentionPolicy = getActionEffectRetention(actionEffect);
    const expireAtProperty = getRetentionPolicyExpireAtProperty(actionEffect);
    if (expireAtProperty) {
        return {
            message: isProperty ? messages.expireAtPropertyForProperty : messages.expireAtPropertyForObject,
            values: {
                objectType: isProperty ? undefined : objectyTypeType,
                dateProperty: expireAtProperty,
            },
        };
    }

    const delay = getRetentionPolicyDelay(actionEffect);

    if (delay !== undefined) {
        const unit = (((retentionPolicy as RententionPolicyTimeToLive).timeToLive) as RententionPolicyTimeToLiveBasedOnDelay).unit;

        return {
            message: messages.retentionWithDelay,
            values: {
                delay,
                unit,
            },
        };
    }
    const unit = (((retentionPolicy as RententionPolicyTimeToLive).timeToLive) as RententionPolicyTimeToLiveBasedOnProperty).unit;
    if (isProperty) {
        return {
            message: messages.retentionWithDelayPropertyOnProperty,
            values: {
                delayProperty: (((retentionPolicy as RententionPolicyTimeToLive).timeToLive) as RententionPolicyTimeToLiveBasedOnProperty).delayProperty,
                unit,
            },
        };
    }

    return {
        message: messages.retentionWithDelayPropertyOnObject,
        values: {
            objectType: objectyTypeType,
            delayProperty: (((retentionPolicy as RententionPolicyTimeToLive).timeToLive) as RententionPolicyTimeToLiveBasedOnProperty).delayProperty,
            unit,
        },
    };
};

export const getObjectTypesOrLinkTypesIndex = (kind: RetentionPolicyActionTargetKind) => (kind === 'Vertex' ? 'objectTypes' : 'linkTypes');

export const getRowId = (row: Pick<RetentionPolicyRow, 'kind' | 'type' | 'propertyName'>) => {
    return `${row.kind}/${row.type}/${row.propertyName}`;
};
export const getSelectionObject = (row: Pick<RetentionPolicyRow, 'kind' | 'type' | 'propertyName' | 'retentionPolicyLabel'>) => {
    return {
        id: getRowId(row),
        kind: row.kind,
        type: row.type,
        propertyName: row.propertyName,
        retentionPolicyLabel: row.retentionPolicyLabel,
    };
};

const getKindSlashTypeKeyFromRow = (row: RetentionPolicyRow) => `${row.kind}/${row.type}`;
export const addOrRemoveFromList = (list: string[], row: RetentionPolicyRow) => {
    const kindSlashTypeKeyFromRow = getKindSlashTypeKeyFromRow(row);

    if (list.includes(kindSlashTypeKeyFromRow)) {
        return list.filter(el => el !== kindSlashTypeKeyFromRow);
    }

    return [...list, kindSlashTypeKeyFromRow];
};

export const getSelectionColumn = (
    doesCheckAllEnabled: boolean,
    allItemsCheckboxValue: boolean | 'minus',
    handleSelectAll: () => void,
    isRowSelected: (row: RetentionPolicyRow) => boolean,
    handleSelectionChange: (row: RetentionPolicyRowSelection, checked: boolean) => void,
): ArgTable2Column<RetentionPolicyRow> => {
    const selectionColumn: ArgTable2Column<RetentionPolicyRow> = {
        key: 'object-selection',
        maxWidth: 40,
        width: 40,
        dataIndex: 'propertyName',
        title: function selectionHeader() {
            const isDisabled = !doesCheckAllEnabled;
            const tooltipWhenDisabled = !isDisabled ? undefined : messages.noRulesToDeleteTooltipMessage;

            return (
                <ArgCheckboxMinus
                    size='node'
                    value={allItemsCheckboxValue}
                    onChange={handleSelectAll}
                    disabled={isDisabled}
                    tooltip={tooltipWhenDisabled}
                />
            );
        },
        render: function RenderObjectSelectionColumn(propertyName: string | undefined, row) {
            if (propertyName) {
                return null;
            }

            const isDisabled = !row.policy;
            const tooltipWhenDisabled = !isDisabled ? undefined : messages.noRuleToDeleteTooltipMessage;

            return (
                <ArgCheckbox
                    size='node'
                    value={isRowSelected(row)}
                    disabled={isDisabled}
                    tooltip={tooltipWhenDisabled}
                    onChange={(checked) => handleSelectionChange(getSelectionObject(row), checked)}
                />
            );
        },
    };

    return selectionColumn;
};

export const getExpandColumn = (
    getExpandIconName: (row: RetentionPolicyRow) => string,
    handleExpandClick: (row: RetentionPolicyRow) => void,
): ArgTable2Column<RetentionPolicyRow> => {
    const expandColumn: ArgTable2Column<RetentionPolicyRow> = {
        key: 'expand',
        maxWidth: 40,
        width: 40,
        dataIndex: '',
        render: function RenderExpandColumn(_: any, row: RetentionPolicyRow) {
            if (isEmpty(row.children)) {
                return null;
            }

            const iconName = getExpandIconName(row);

            return (
                <ArgButton
                    icon={iconName}
                    size='medium'
                    type='ghost'
                    onClick={() => {
                        handleExpandClick(row);
                    }}
                />
            );
        },
    };

    return expandColumn;
};

export const getObjectOrRelationOrPropertyColumn = (
    title: MessageDescriptor,
    isRowSelected: (row: RetentionPolicyRow) => boolean,
    handleSelectionChange: (row: RetentionPolicyRowSelection, checked: boolean) => void,
    ontology: FullOntology | undefined,
): ArgTable2Column<RetentionPolicyRow> => {
    const objectOrRelationOrPropertyColumn: ArgTable2Column<RetentionPolicyRow> = {
        key: 'objectOrRelationOrPropertyColumn',
        getCellStringValue: (row) => row.label,
        sorter: (a, b) => stringSorter(a, b, (item) => item.label),
        title,
        dataIndex: 'retentionPolicy',
        render: function RenderObjectOrRelationOrPropertyColumn(data: any, row: RetentionPolicyRow, index?: number | undefined, search?: string | null | undefined) {
            const {
                propertyName,
                kind,
                type,
            } = row;

            const searchToken = search || undefined;

            if (propertyName) {
                return (
                    <ArgCheckbox
                        size='node'
                        label={renderText(row.propertyName, undefined, searchToken)}
                        value={isRowSelected(row)}
                        onChange={(checked) => handleSelectionChange(getSelectionObject(row), checked)}
                    />
                );
            }

            const defaultItemTypeStyle = kind === 'Vertex' ? DEFAULT_VERTEX_STYLE : DEFAULT_EDGE_STYLE;
            const ontologyItemType = ontology?.[getObjectTypesOrLinkTypesIndex(kind)].find(itemType => itemType.name === type);
            const styles = ontologyItemType?.style ? merge({}, defaultItemTypeStyle, ontologyItemType.style) : defaultItemTypeStyle;

            return (
                <BasicTag
                    label={ontologyItemType?.displayName}
                    icon={kind === 'Vertex' ? (styles as VertexStyle).iconName : 'icon-dot-arrow-right'}
                    iconColor='#FFFFFF'
                    backgroundColor={kind === 'Vertex' ? (styles as VertexStyle).iconColor : (styles as Required<EdgeStyle>).color}
                    search={searchToken}
                    tooltip={ontologyItemType?.displayName}
                />
            );
        },
    };

    return objectOrRelationOrPropertyColumn;
};

export const getRetentionPolicyColumn = (
    title: MessageDescriptor,
    intl: IntlShape,
): ArgTable2Column<RetentionPolicyRow> => {
    const retentionPolicyColumn: ArgTable2Column<RetentionPolicyRow> = {
        key: 'retentionPolicyColumn',
        title,
        dataIndex: 'retentionPolicyLabel',
        getCellStringValue: (row) => intl.formatMessage(row.retentionPolicyLabel.message, row.retentionPolicyLabel.values),
        sorter: (a, b) => stringSorter(a, b, (item) => intl.formatMessage(item.retentionPolicyLabel.message, item.retentionPolicyLabel.values)),
        render: function RendeRetentionPolicyColumn(retentionPolicyLabel: RetentionPolicyRow['retentionPolicyLabel'], item: RetentionPolicyRow, index?: number | undefined, search?: string | null | undefined) {
            const searchToken = search || undefined;
            const stringValue = intl.formatMessage(retentionPolicyLabel.message, retentionPolicyLabel.values);

            return renderText(stringValue, undefined, searchToken);
        },
    };

    return retentionPolicyColumn;
};

export const getModificationDateColumn = (title: MessageDescriptor): ArgTable2Column<RetentionPolicyRow> => {
    const modificationDateColumn: ArgTable2Column<RetentionPolicyRow> = {
        key: 'modificationDate',
        maxWidth: 150,
        width: 150,
        sorter: (a, b) => dateSorter(a, b, (row) => row.modificationDate),
        title,
        dataIndex: 'modificationDate',
        render: function LastUpdated(_date, item, idx, search) {
            if (isNil(item.modificationDate)) {
                return null;
            }
            const searchToken = search || undefined;

            return (
                <DateByUser
                    relative={true}
                    date={item.modificationDate}
                    user={item.modificationActor}
                    search={searchToken}
                />
            );
        },
    };

    return modificationDateColumn;
};
export const getActionsColumn = (
    deleteRetentionPolicy: (retentionPolicyRow: RetentionPolicyRow) => Promise<void>,
    editRetentionPolicy: (retentionPolicyRow: RetentionPolicyRow) => void,
    supportedActions: ItemActions[],
): ArgTable2Column<RetentionPolicyRow> => {
    const actionsColumn: ArgTable2Column<RetentionPolicyRow> = {
        key: 'actions',
        width: 50,
        title: '',
        dataIndex: 'id',
        render: function Actions(data, retentionPolicyRow) {
            const onMenuItemClick = async (action: ItemActions) => {
                switch (action) {
                    case ItemActions.Delete: {
                        await deleteRetentionPolicy(retentionPolicyRow);
                        break;
                    }
                    case ItemActions.Edit: {
                        editRetentionPolicy(retentionPolicyRow);
                        break;
                    }
                    default:
                        break;
                }
            };

            const isActionDisabled = (action: ItemActions) => action === ItemActions.Delete && retentionPolicyRow.policy === undefined;

            return (
                <ActionsMenu isActionDisabled={isActionDisabled} actions={supportedActions}
                             onMenuItemClick={onMenuItemClick} />
            );
        },
    };

    return actionsColumn;
};


const getItemTypeRows = (kind: RetentionPolicyActionTargetKind, ontology: FullOntology, retentionPolicy?: RetentionPolicy): RetentionPolicyRow[] => {
    const retentionPolicyActions = retentionPolicy?.actions;

    const list: (FullOntologyObjectType | FullOntologyLinkType)[] = ontology[getObjectTypesOrLinkTypesIndex(kind)];
    const rows = list.map((itemType) => {
        const idx = retentionPolicyActions?.findIndex(action => action.target.kind === kind && action.target.type === itemType.name);
        const itemTypeName = itemType.name;
        if (!isUndefined(retentionPolicyActions) && !isUndefined(idx) && idx > -1) {
            const policyAction = retentionPolicyActions[idx];
            const objectPolicyIdx = policyAction.effects.filter(isActionEffectOnObject)[0];
            const objectPolicy = objectPolicyIdx?.object?.retention;
            const children = policyAction.effects.filter(isActionEffectOnProperty).map(effect => ({
                id: `${policyAction.target.kind}/${policyAction.target.type}`,
                kind: policyAction.target.kind,
                type: policyAction.target.type,
                label: effect.property.targetProperty,
                policy: effect.property.retention,
                propertyName: effect.property.targetProperty,
                modificationDate: retentionPolicy?.lastUpdatedDate,
                modificationActor: retentionPolicy?.lastUpdatedBy,
                retentionPolicyLabel: getRetentionPolicyLabel(effect, itemTypeName, true),
            }));

            return {
                id: `${policyAction.target.kind}/${policyAction.target.type}`,
                kind: policyAction.target.kind,
                type: policyAction.target.type,
                label: itemType.displayName,
                policy: objectPolicy,
                children: isEmpty(children) ? undefined : children,
                modificationDate: retentionPolicy?.lastUpdatedDate,
                modificationActor: retentionPolicy?.lastUpdatedBy,
                retentionPolicyLabel: getRetentionPolicyLabel(objectPolicyIdx, itemTypeName),
            };
        }

        return {
            id: `${kind}/${itemTypeName}`,
            kind: kind,
            type: itemTypeName,
            label: itemType.displayName,
            modificationDate: ontology.lastPublishedDate, // lastPublishedDate is not well typed
            modificationActor: ontology.lastPublishedBy,
            retentionPolicyLabel: getRetentionPolicyLabel(undefined, itemTypeName),
        };
    });

    return rows;
};

export function constructRetentionPolicyRows(retentionPolicy?: RetentionPolicy, ontology?: FullOntology, isExpanded?: (key: string) => boolean): RetentionPolicyRow[] {
    if (!ontology) {
        return [];
    }

    const objectTypeRows = getItemTypeRows('Vertex', ontology, retentionPolicy);
    const linkTypeRows = getItemTypeRows('Edge', ontology, retentionPolicy);

    const allRows = [...objectTypeRows, ...linkTypeRows];

    if (!isExpanded) {
        return allRows;
    }

    const filteredRows: RetentionPolicyRow[] = [];

    for (const row of allRows) {
        if (row.children && isExpanded(row.id)) {
            filteredRows.push(...[row, ...row.children]);
            continue;
        }

        filteredRows.push(row);
    }

    return filteredRows;
}

export function flattenRows(rows: RetentionPolicyRow[]): RetentionPolicyRow[] {
    const flattenedRows: RetentionPolicyRow[] = [];

    for (const row of rows) {
        if (row.children) {
            flattenedRows.push(...[row, ...row.children]);
            continue;
        }

        flattenedRows.push(row);
    }

    return flattenedRows;
}

export function hasPolicies(rows: RetentionPolicyRow[]): boolean {
    const flattenedRows = flattenRows(rows);

    return flattenedRows.some(row => row.policy);
}
