import { diff } from 'jsondiffpatch';
import { cloneDeep, get, isArray, isNumber, set, unset } from 'lodash';

import { format, Op as Operation } from './json-patch-formatter';

export function compare(left: Record<string, any>, right: Record<string, any>): Operation[] {
    const delta = diff(left, right);
    const operations = format(delta, left);

    return operations;
}

/**
 * Legacy function to get value from json patch path
 * Used for backward compatiblty with fast-json-patch
 * @deprecated
 */
export function getValueByPointer(
    object: unknown,
    path: string,
): unknown {
    const lodashPath = jsonPathToLodashPath(path, object);
    const value = get(object, lodashPath);

    return value;
}

/**
 * Legacy function to apply json patch operation to on object
 * Used for backward compatiblty with fast-json-patch
 * Support only add, replace and remove
 * @deprecated
 */
export function applyOperation<T extends object>(
    object: T,
    operation: Operation,
    validateObject: boolean,
    mutateDocument: boolean,
): { newDocument: T } {
    const mutateObject = mutateDocument ? object : cloneDeep(object);
    const lodashPath = jsonPathToLodashPath(operation.path, object);

    switch (operation.op) {
        case 'add':
        case 'replace':
            set(mutateObject, lodashPath, operation.value);
            break;
        case 'remove':
            applyRemoveOperation(mutateObject, lodashPath);
            break;
        default:
            throw new Error('Operation is not supported');
    }

    return { newDocument: mutateObject };
}

function applyRemoveOperation(mutateObject: unknown, lodashPath: (string | number)[]) {
    const prev = get(mutateObject, lodashPath.slice(0, -1));
    const index = lodashPath.at(-1);
    if (prev && isArray(prev) && isNumber(index)) {
        prev.splice(index, 1);
    } else {
        unset(mutateObject, lodashPath);
    }
}

function jsonPathToLodashPath(path: string, object?: unknown): (string | number)[] {
    const appendToArray = path.slice(-1) === '-';
    if (appendToArray) {
        path = path.slice(0, -1);
    }

    // Trim '/'
    path = path.replaceAll(/^\/+|\/+$/g, '');

    const pathParts = path.split('/').map((part) => (
        isNaN(Number(part)) ? part : Number(part)
    ));

    if (!appendToArray || !object) {
        return pathParts;
    }

    const array = get(object, pathParts);
    if (!array) {
        return [...pathParts, 0];
    }

    return [...pathParts, array.length];
}

export type { Operation };
