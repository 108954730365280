import { RefObject, useLayoutEffect, useRef, useState } from 'react';

export function useClamp<T extends HTMLElement>(
    rowHeight: number,
    rowsCount: number,
): [boolean | undefined, () => void, RefObject<T>] {
    const [clamp, setClamp] = useState<boolean | undefined>(undefined);
    const commentContainerRef = useRef<T>(null);

    useLayoutEffect(() => {
        if (commentContainerRef.current) {
            if (commentContainerRef.current.clientHeight > rowsCount * rowHeight) {
                setClamp(true);
            }
        }
    }, [commentContainerRef.current]);

    const clampToggle = () => setClamp((prev) => !prev);

    return [clamp, clampToggle, commentContainerRef];
}
