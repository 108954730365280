import { ArgButton, ArgChangeReason, ArgInputNumber, useClassNames } from '../../../../basic';
import { CommonControlProps, useGetStyleModifier } from './utils';
import { DiscreteControlMessages } from '../advanced-body';

import './discrete-control.less';

export interface DiscreteNumberControlProps extends CommonControlProps {
    fieldValue?: number;

    onFieldValueChange?: (value: number | null, reason: ArgChangeReason) => void;

    messages: DiscreteControlMessages;
}

export function DiscreteNumberControl({
    type,
    fieldValue,

    index,

    fillColor,
    iconColor,
    strokeColor,

    badgeFillColor,
    badgeIconName,
    doesBadgeBlink,

    size,

    iconName,
    lineStyle,

    onStyleChange,
    onFieldValueChange,
    canBeRemoved,
    onRemoveComponent,
    isUndefinedStyleRule,
    messages,
}: DiscreteNumberControlProps) {
    const classNames = useClassNames('discrete-control');
    const styleModifier = useGetStyleModifier(
        type,
        classNames,
        index,
        fillColor,
        iconColor,
        strokeColor,
        badgeFillColor,
        badgeIconName,
        size,
        iconName,
        onStyleChange,
        doesBadgeBlink,
        lineStyle,
    );

    const cls = {
        size: type === 'size',
        'line-style': type === 'style',
    };

    const inputPlaceholder = isUndefinedStyleRule
        ? messages.undefinedStyleRulePlaceholder
        : messages.inputPlaceholder;

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-content', cls)}>
                {styleModifier}
                <ArgInputNumber
                    value={fieldValue}
                    onChange={onFieldValueChange}
                    placeholder={inputPlaceholder}
                    displayRightControl={true}
                    disabled={!!isUndefinedStyleRule}
                />
            </div>

            {canBeRemoved && (
                <ArgButton
                    icon='icon-trash'
                    type='ghost'
                    className={classNames('&-trash-button')}
                    onClick={() => onRemoveComponent?.(type, index)}
                />
            )}
        </div>
    );
}
