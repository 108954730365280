import { defineMessages } from 'react-intl';
import { Outlet } from 'react-router';

import { UserContext } from '../../contexts/user-context';
import { useClassNames } from '../basic';
import { LoadingPane } from '../common/panes/loading-pane';
import { ErrorPane } from '../common/panes/error-pane';
import { UserPermissionsProvider } from 'src/contexts/user-permissions-context';
import { ApplicationConfigurationsContext } from '../../contexts/application-configurations';
import { useApplicationConfigurations } from '../../hooks/use-application-configuration';
import { useCurrentUserWitRetry } from 'src/hooks/use-current-user-with-retry';


import './user-and-permissions-providers.less';

const FORCE_ERROR = false;
const FORCE_LOADING = false;

const messages = defineMessages({
    errorLoadingUserProfile: {
        id: 'authenticated-apps-router.ErrorLoadingUserProfile',
        defaultMessage: '<p><b>Internal server error</b></p><p>Error while loading user profile.</p><p>Please retry in few minutes.</p>',
    },
});

export function UserAndPermissionsProviders() {
    const classNames = useClassNames('common-user-and-permissions-providers');

    const [applicationConfigurations] = useApplicationConfigurations();
    const [currentUser, progressMonitor, error] = useCurrentUserWitRetry();

    if (FORCE_ERROR || error) {
        return (
            <div className={classNames('&', 'error')}>
                <ErrorPane
                    message={messages.errorLoadingUserProfile}
                    error={error}
                    className={classNames('&-error')}
                    size='large'
                />
            </div>
        );
    }

    if (FORCE_LOADING || currentUser === undefined || progressMonitor?.isRunning) {
        return (
            <div className={classNames('&', 'loading')}>
                <LoadingPane
                    progressMonitor={progressMonitor}
                    className={classNames('&-loading')}
                    size='large'
                />
            </div>
        );
    }

    return (
        <ApplicationConfigurationsContext.Provider value={applicationConfigurations}>
            <UserContext.Provider value={currentUser}>
                <UserPermissionsProvider>
                    <Outlet />
                </UserPermissionsProvider>
            </UserContext.Provider>
        </ApplicationConfigurationsContext.Provider>
    );
}
