import { ProgressMonitor } from 'src/components/basic';
import { BaseConnector } from 'src/utils/connectors/base-connector';
import {
    AddEditEdgeVertextRetentionDTO,
    DeleteEdgeVertexRetentionDTO,
    GetEdgeVertextRetentionPolicy,
} from '../models/dtoApi';
import { OntologyId } from '../universes/ontology/types';
import {
    RetentionPolicy,
    RetentionPolicyActionEffects,
    RetentionPolicyActionTargetKind,
    RetentionPolicyVertexEdge,
} from '../universes/retention/types';
import { mapRetentionPolicy, mapReverseRetentionPolicy } from './mappers';
import { getDataExplorationApi } from '../../utils/connectors/api-url';

class RetentionPolicyConnector extends BaseConnector {
    private static instance: RetentionPolicyConnector;

    static getInstance(): RetentionPolicyConnector {
        if (!RetentionPolicyConnector.instance) {
            RetentionPolicyConnector.instance = new RetentionPolicyConnector('settings.retentionPolicy', getDataExplorationApi());
        }

        return RetentionPolicyConnector.instance;
    }

    async getRetentionPolicy(
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<RetentionPolicy> {
        const url = `/ontology-retention-policies/${encodeURIComponent(ontologyId)}`;
        const options = {
            verifyJSONResponse: true,
        };

        const retentionPolicy: RetentionPolicy = await this.request(url, options, progressMonitor);
        const mappedRetentionPolicy = mapRetentionPolicy(retentionPolicy);

        return mappedRetentionPolicy;
    }

    async publishRetentionPolicy(
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontology-retention-policies/${encodeURIComponent(ontologyId)}`;
        const options = {
            method: 'POST',
            params: {
                operation: 'Publish',
            },
        };

        await this.request(url, options, progressMonitor);
    }


    async getRetentionPolicyByEdgeOrVertex(
        getEdgeVertextRetentionPolicy: GetEdgeVertextRetentionPolicy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<RetentionPolicyVertexEdge> {
        const { ontologyId, retentionLinkKind, edgeOrVertexName } = getEdgeVertextRetentionPolicy;
        const url = `/ontology-retention-policies/${encodeURIComponent(ontologyId)}/${encodeURIComponent(retentionLinkKind)}/${encodeURIComponent(edgeOrVertexName)}`;
        const options = {
            verifyJSONResponse: true,
        };

        const retentionPolicyEffects: RetentionPolicyVertexEdge = await this.request(url, options, progressMonitor);

        return retentionPolicyEffects;
    }

    async addEditEdgeVertexRetention(
        addEditEdgeVertexRetentionPayload: AddEditEdgeVertextRetentionDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const { ontologyId, edgeOrVertexName, retentionLinkKind, retention } = addEditEdgeVertexRetentionPayload;
        const url = `/ontology-retention-policies/${encodeURIComponent(ontologyId)}/${encodeURIComponent(retentionLinkKind)}/${encodeURIComponent(edgeOrVertexName)}`;
        const options = {
            method: 'PUT',
            json: retention,
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteEdgeVertexRetention(
        deleteEdgeVertexRetentionPayload: DeleteEdgeVertexRetentionDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const { ontologyId, edgeOrVertexName, retentionLinkKind } = deleteEdgeVertexRetentionPayload;
        const url = `/ontology-retention-policies/${encodeURIComponent(ontologyId)}/${encodeURIComponent(retentionLinkKind)}/${encodeURIComponent(edgeOrVertexName)}`;
        const options = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    // Create or update retention action for a given object type in ontology
    async updateObjectTypesRetentionPolicy(
        ontologyId: OntologyId,
        objectTypesName: string,
        objectTypesKind: RetentionPolicyActionTargetKind,
        newEffects: RetentionPolicyActionEffects,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ) {
        const url = `/ontology-retention-policies/${encodeURIComponent(ontologyId)}/${objectTypesKind === 'Vertex' ? 'object-types' : 'link-types'}/${encodeURIComponent(objectTypesName)}`;
        const options = {
            verifyJSONResponse: true,
            method: 'PUT',
            json: { effects: newEffects },
        };

        await this.request(url, options, progressMonitor);
    }

    // Create or update ontology retention policy.
    async updateOntologyRetentionPolicy(
        ontologyId: OntologyId,
        retentionPolicy: Pick<RetentionPolicy, 'actions' | 'type'>,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ) {
        const url = `/ontology-retention-policies/${encodeURIComponent(ontologyId)}`;
        const options = {
            verifyJSONResponse: true,
            method: 'PUT',
            json: mapReverseRetentionPolicy(retentionPolicy),
        };

        await this.request(url, options, progressMonitor);
    }
}


export default RetentionPolicyConnector.getInstance();
