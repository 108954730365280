export const ICON_FONT_FAMILIES = {
    'icon-': 'icomoon',
    'fa ': 'FontAwesome',
    'fab ': 'FontAwesome',
};

export function getIconFontFamily(icon: string | undefined): string | undefined {
    if (!icon) {
        return undefined;
    }

    for (const [prefix, fontFamily] of Object.entries(ICON_FONT_FAMILIES)) {
        if (icon.startsWith(prefix)){
            return fontFamily;
        }
    }

    return undefined;
}
