import { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ArgButton, ArgModal, useClassNames } from 'src/components/basic';
import { User } from 'src/model/user';

import './multi-user-delete-modal.less';

interface DeleteUsersModalProps {
    visible: boolean;
    closeModal: () => void;
    users: User[];
}

export const messages = defineMessages({
    title: {
        id: 'settings.delete-users-modal.title',
        defaultMessage: 'Delete {totalNumber} users',
    },
    description: {
        id: 'settings.delete-users-modal.description',
        defaultMessage: 'Are you sure you want to delete {totalNumber} users?',
    },
    cancel: {
        id: 'settings.delete-users-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.delete-users-modal.button.delete',
        defaultMessage: 'Delete',
    },
});

export function DeleteUsersModal (props: DeleteUsersModalProps) {
    const { visible, closeModal, users } = props;

    const classNames = useClassNames('settings-delete-users-modal');
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        window.alert('delete the users');
        setLoading(false);
        closeModal();
    };

    return (
        <ArgModal
            size='small'
            title={<FormattedMessage {...messages.title} values={{ totalNumber: users.length }} />}
            visible={visible}
            onClose={closeModal}
            footer={
                <div>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                        disabled={loading}
                    />
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        onClick={() => handleSubmit()}
                        label={messages.delete}
                        loading={loading}
                        disabled={loading}
                        data-testid='edit'
                    />
                </div>
            }
        >
            <div className={classNames('&-content')}>
                <div>
                    <FormattedMessage
                        {...messages.description}
                        values={{ totalNumber: users.length }}
                    />
                </div>
                <b className={classNames('&-users-deleted')}>
                    {users.map((user) => user.displayName).join(', ')}
                </b>
            </div>
        </ArgModal>
    );
};
