import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ProgressMonitor } from 'src/components/basic';
import { ListOntologies } from 'src/settings/models/dtoApi';
import { OntologiesStateContext, OntologiesStateContextType } from '../management/providers/universes';
import { UniversesManagementView } from '../management/views/universes-management-view';
import { OntologyView } from './views/ontology-view';
import { ConfigurationType } from 'src/settings/configuration/configuration-type';
import { ConfigurationView } from 'src/settings/configuration/views/configuration-view';
import { FormPolicyDetailsView } from '../form-policy-details/views/form-policy-details-view';
import { FormPoliciesView } from '../form-policy/views/form-policies-view';
import { FormCustomisation } from '../form/views/form-customisation';
import { PoliciesView } from '../policies/views/policies-view';
import { PolicyDetailsView } from '../policy-details/views/policy-details-view';
import { RetentionRules } from '../retention/views/retention-rules';
import { ValuationPolicyDetailsView } from '../valuation-policy-details/views/valuation-policy-details-view';
import { ValuationPoliciesView } from '../valuation-policy/views/valuation-policies-view';

export const UNIVERSE_ROUTE = '/settings/data_exploration/ontology';
export const UNIVERSE_MANAGEMENT_ROUTE = '/settings/data_exploration/ontology/manage';
export const UNIVERSE_ONTOLOGY_ROUTE = '/settings/data_exploration/ontology/:ontologyId/schema';
export const UNIVERSE_POLICIES_ROUTE = '/settings/data_exploration/ontology/:ontologyId/universe/:universeId/policies';
export const UNIVERSE_VALUATION_POLICIES_ROUTE = '/settings/data_exploration/ontology/:ontologyId/universe/:universeId/valuation-policies';
export const UNIVERSE_VALUATION_POLICY_ENTRY_ROUTE = '/settings/data_exploration/ontology/:ontologyId/universe/:universeId/valuation-policies/:policyId';
export const UNIVERSE_RETENTION_ROUTE = '/settings/data_exploration/ontology/:ontologyId/universe/:universeId/retention';
export const UNIVERSE_POLICY_ENTRY_ROUTE = '/settings/data_exploration/ontology/:ontologyId/universe/:universeId/policies/:policyId';
export const UNIVERSE_CONFIGURATION_ROUTE = '/settings/data_exploration/ontology/configuration';
export const UNIVERSE_ONTOLOGY_CONFIGURATION_ROUTE = '/settings/data_exploration/ontology/:ontologyId/universe/:universeId/configuration';
export const UNIVERSE_FORM_CUSTOMISATION_ROUTE = '/settings/data_exploration/ontology/:ontologyId/forms/:objectName';
export const UNIVERSE_FORMS_ROUTE = '/settings/data_exploration/ontology/:ontologyId/forms';
export const UNIVERSE_FORM_POLICIES_ROUTE = '/settings/data_exploration/ontology/:ontologyId/universe/:universeId/forms-policies';
export const UNIVERSE_FORM_POLICY_ENTRY_ROUTE = '/settings/data_exploration/ontology/:ontologyId/universe/:universeId/forms-policies/:policyId';

interface OntologyRouterProps {
    listOntologies?: ListOntologies;
    updateOntologies: (progressMonitor: ProgressMonitor) => Promise<void>;
}
export function OntologyRouter(props: OntologyRouterProps) {
    const { listOntologies, updateOntologies } = props;
    const ontologiesContextValue = useMemo<OntologiesStateContextType | undefined>(() => {
        if (!listOntologies) {
            return undefined;
        }

        return {
            listOntologies,
            updateOntologies,
        };
    }, [listOntologies, updateOntologies]);

    if (!ontologiesContextValue) {
        return null;
    }

    return (
        <OntologiesStateContext.Provider value={ontologiesContextValue}>
            <Routes>
                <Route
                    path='manage'
                    element={(
                        <UniversesManagementView listOntologies={listOntologies} />
                    )}
                />
                <Route
                    path=':ontologyId/schema'
                    element={<OntologyView />}
                />
                <Route
                    path=':ontologyId/universe/:universeId/policies'
                    element={<PoliciesView />}
                />
                <Route
                    path=':ontologyId/universe/:universeId/policies/:policyId'
                    element={<PolicyDetailsView />}
                />
                <Route
                    path=':ontologyId/universe/:universeId/valuation-policies'
                    element={<ValuationPoliciesView />}
                />
                <Route
                    path=':ontologyId/universe/:universeId/valuation-policies/:policyId'
                    element={<ValuationPolicyDetailsView />}
                />
                <Route
                    path=':ontologyId/universe/:universeId/retention'
                    element={<RetentionRules />}
                />
                <Route
                    path=':ontologyId/universe/:universeId/configuration'
                    element={<ConfigurationView type={ConfigurationType.Universe} />}
                />
                <Route
                    path=':ontologyId/forms/:objectName'
                    element={<FormCustomisation />}
                />
                <Route
                    path=':ontologyId/universe/:universeId/forms-policies'
                    element={<FormPoliciesView />}
                />
                <Route
                    path=':ontologyId/universe/:universeId/forms-policies/:policyId'
                    element={<FormPolicyDetailsView />}
                />
            </Routes>
        </OntologiesStateContext.Provider>
    );
}
