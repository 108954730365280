import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { ArgInputCustomComponentProps, ArgInputProps } from '../arg-input';
import { ArgInputMask } from './arg-input-mask';
import { TextState } from './types';
import { ArgChangeReason } from '../../types';
import { ArgIcon } from '../../arg-icon/arg-icon';
import MaskUtils from './mask';


export function useArgInputMask<T>(
    mask: string|(string|RegExp)[],
    value: T|undefined,
    format: (v: T|null)=>string,
    onChange?: (value: T | null, reason: ArgChangeReason) => void,
    onClear?: ()=>void,
):Partial<ArgInputProps<T, unknown>> {
    const maskPlaceholder = '_';

    const [stringValue, setStringValue] = useState<string>('');

    useEffect(() => {
        if (!value) {
            setStringValue('');

            return;
        }

        const formatted = format(value);

        setStringValue(formatted);
    }, [value]);

    const maskUtils = useMemo(() => {
        return new MaskUtils(mask, maskPlaceholder);
    }, [mask, maskPlaceholder]);

    const handleInputMaskKeyDown = useCallback((event: KeyboardEvent, props: ArgInputCustomComponentProps<T>) => {
        if (event.key === 'Enter') {
            if (maskUtils.isValueFilled(stringValue)) {
                props.onChange(stringValue, 'enter');
            }
        }
    }, [maskUtils, stringValue]);

    const handleChange = useCallback((value: string, state: TextState, props: ArgInputCustomComponentProps<T>) => {
        setStringValue(value);

        console.log('HandleChange', maskUtils.isValueFilled(value), '=>', value);
        if (maskUtils.isValueFilled(value)) {
            console.log('Fire change', value);
            props.onChange(value, 'enter');
        } else {
            props.onChange(null, 'keypress');
        }
    }, [maskUtils]);

    const handleOnClear = useCallback(() => {
        setStringValue('');

        if (onClear) {
            onClear();

            return;
        }
        onChange?.(null, 'clear');
    }, [onChange, onClear]);

    const isNoValue = useMemo<boolean>(() => {
        const result = maskUtils.isValueEmpty(stringValue);

        return result;
    }, [maskUtils, stringValue]);

    const handleRenderInputComponent = useCallback((props: ArgInputCustomComponentProps<T>) => {
        return <>
            <ArgInputMask
                ref={props.ref}
                className={classNames('input-mask', props.className)}
                alwaysShowMask={true}
                mask={mask}
                onChange={(value, state) => handleChange(value, state, props)}
                onBlur={() => {
                    props.onChange(stringValue);
                }}
                onKeyDown={(event) => handleInputMaskKeyDown(event, props)}
                onFocus={props.onFocus}
                disabled={props.disabled}
                readOnly={props.readOnly}
                value={stringValue}
                maskPlaceholder={maskPlaceholder}
                data-testid='arg-input-mask'
            />
            {!isNoValue && <button
                key='clear'
                tabIndex={-1}
                type='button'
                data-testid='clear-input'
                className={classNames('arg-input-mask-clear', 'arg-input-button', 'arg-input-right')}
                disabled={props.disabled}
                onClick={handleOnClear}
            >
                <ArgIcon className={classNames('&-right-icon')} name='icon-cross' />
            </button>}
        </>;
    }, [handleChange, handleInputMaskKeyDown, handleOnClear, isNoValue, mask, stringValue]);

    return {
        renderInputComponent: handleRenderInputComponent,
        clearable: false,
    };
}
