import React, { ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { includes, isNil } from 'lodash';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import Debug from 'debug';

import { internalisationDateFormat } from '../../../utils/dates/internalisation-date-format';
import { ArgInput, ArgInputKeyPressEvent, ArgInputProps } from './arg-input';
import { ArgIcon } from '../arg-icon/arg-icon';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgCalendar, CalendarMode } from '../arg-calendar/arg-calendar';
import { ArgChangeReason } from '../types';
import { dayjs } from '../utils/dayjs';
import { useArgInputMask } from './masks/use-arg-input-mask';

import './arg-input-date.less';

const debug = Debug('argonode:components:ArgInputDate');

const DATE_MASK = [
    /[0-3]/,
    /[0-9]/,
    '/',
    /[0-1]/,
    /[0-9]/,
    '/',
    /[1-2]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
];

const messages = defineMessages({
    defaultPlaceholder: {
        id: 'basic.arg-input-date.DefaultPlaceholder',
        defaultMessage: 'Select date',
    },
});

const MOMENT_FILTERED_KEY = /[0-9\/\-\.]/i;
const DEFAULT_PLACEHOLDER = messages.defaultPlaceholder;

function momentKeypress(event: ArgInputKeyPressEvent): void {
    const keyboardEvent = event.keyboardEvent;
    const key = keyboardEvent.key;


    if (key.length === 1
        && !keyboardEvent.ctrlKey
        && !keyboardEvent.altKey
        && !keyboardEvent.metaKey
        && !MOMENT_FILTERED_KEY.test(key)) {
        keyboardEvent.preventDefault();
        keyboardEvent.stopPropagation();


        return;
    }
}

export interface ArgInputDateProps extends Omit<ArgInputProps<dayjs.Dayjs, any>, 'formatValue' | 'parseValue'> {
    format?: string;
    locale?: PickerLocale;
    disabledDate?: ((date: dayjs.Dayjs) => boolean);
}

export function ArgInputDate(props: ArgInputDateProps) {
    const {
        format,
        onChange,
        readOnly,
        disabledDate,
        initialValue,
        value: externalValue,
        placeholder = DEFAULT_PLACEHOLDER,
        className,
        ...otherProps
    } = props;
    const intl = useIntl();
    const classNames = useClassNames('arg-input-date');

    const previousValue = useRef<dayjs.Dayjs>();

    const useInternalValue = !('value' in props);

    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [internalValue, setInternalValue] = useState<dayjs.Dayjs | undefined>(initialValue);


    const value: dayjs.Dayjs | undefined = useInternalValue ? internalValue : externalValue;


    useEffect(() => {
        debug('initialValue', 'Update new initialValue=', initialValue);
        setInternalValue(initialValue);
    }, [initialValue]);


    const myFormat = useMemo(() => {
        if (format) {
            return format;
        }

        const dateFormat = internalisationDateFormat(intl);

        debug('computeFormat', 'dateFormat=', dateFormat);

        return dateFormat;
    }, [format, intl]);


    const momentFormatValue = useCallback((value: any | null): string => {
        if (value === null) {
            return '';
        }

        if (!dayjs.isDayjs(value)) {
            value = dayjs(value);
        }

        if (!value.isValid()) {
            return '';
        }

        // UTC -> Locale
        const localeDate = dayjs([value.year(), value.month(), value.date()]);

        return value.format && localeDate.format(myFormat);
    }, [myFormat]);


    const momentParseValue = useCallback((value: string): dayjs.Dayjs | null => {
        if (value === '') {
            return null;
        }

        const locale = dayjs(value, myFormat);
        if (!locale.isValid()) {
            return null;
        }

        // Locale => UTC
        const utc = dayjs.utc([locale.year(), locale.month(), locale.date()]);
        if (previousValue.current && utc.isSame(previousValue.current)) {
            return previousValue.current;
        }

        previousValue.current = utc;

        return utc;
    }, [myFormat]);


    const handleDateChanged = useCallback((date: dayjs.Dayjs | null, reason: ArgChangeReason, mode?: CalendarMode) => {
        if (includes(['selection', 'clear'], reason) && (mode !== 'year' && mode !== 'decade')) {
            setPopoverVisible(false);
        }

        if (useInternalValue) {
            setInternalValue(date || undefined);
        }

        debug('handleChange', 'value=', date);

        if (reason === 'keypress') {
            return;
        }

        onChange?.(date, 'selection');
    }, [onChange, useInternalValue]);


    const popover = useMemo<ReactNode>(() => {
        const m = !isNil(value) && dayjs(value) || undefined;

        return <div className={classNames('&-popover')} data-testid='calendar-popover-container'>
            <ArgCalendar
                onChange={handleDateChanged}
                disabledDate={disabledDate}
                className={classNames('&-calendar')}
                value={m?.isValid() ? m : undefined}
            />
        </div>;
    }, [value, classNames, handleDateChanged, disabledDate]);


    const handlePopoverVisibleChange = useCallback((visible: boolean) => {
        setPopoverVisible(visible);
    }, [setPopoverVisible]);

    const onCalendarIconClick = useCallback(
        (evt: SyntheticEvent) => {
            evt.preventDefault();
            setPopoverVisible((popoverVisible) => !popoverVisible);
        },
        [setPopoverVisible],
    );

    const maskProps = useArgInputMask(DATE_MASK, value, momentFormatValue, onChange);

    return (
        <ArgInput<dayjs.Dayjs>
            {...otherProps}
            value={value}
            debounce={false}
            readOnly={readOnly}
            popoverTrigger={undefined}
            popover={popover}
            placeholder={placeholder}
            className={classNames('&', className)}
            onKeyPress={momentKeypress}
            onChange={handleDateChanged}
            parseValue={momentParseValue}
            formatValue={momentFormatValue}
            popoverVisible={popoverVisible}
            popoverFitWidth={false}
            popoverClassName={classNames('&-popover')}
            onPopoverVisibleChange={handlePopoverVisibleChange}
            right={
                !readOnly && <button
                    onClick={onCalendarIconClick}
                    className={classNames('&-open', 'arg-input-button', 'arg-input-right')}
                    data-testid='input-button-right-calendar'
                >
                    <ArgIcon name='icon-calendar' />
                </button>
            }
            {...maskProps}
        />
    );
}
