import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { ProcessId } from '../model/process';
import { ProcessMonitoringDataState } from './process-monitoring-data-state';

export function preparationProcessMonitoringDataStateFactory(url: string, processId: ProcessId) {
    return new ProcessMonitoringDataState(url, processId);
}

export function computeProcessMonitoringDataStateURL(processId: ProcessId) {
    return `/processes/${processId}/monitoringData`;
}

export function usePreparationProcessMonitoringDataState(processId: ProcessId): ProcessMonitoringDataState;
export function usePreparationProcessMonitoringDataState(processId: ProcessId | undefined): ProcessMonitoringDataState | undefined;

export function usePreparationProcessMonitoringDataState(processId: ProcessId | undefined): ProcessMonitoringDataState | undefined {
    const state = useBasicState<ProcessMonitoringDataState>(
        (url) => preparationProcessMonitoringDataStateFactory(url, processId!),
        (processId) ? computeProcessMonitoringDataStateURL(processId) : undefined,
    );

    return state;
}
