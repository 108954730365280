import { useCallback, useEffect, useMemo, useState } from 'react';
import { Locale } from 'antd/lib/locale-provider';
import dayjs from 'dayjs';
import Debug from 'debug';

import { useLoadMessages } from 'src/i18n/arg-locales/use-load-messages';
import { installDayjs } from 'src/i18n/arg-locales/dayjs-install';
import { installFormatJSPolyfills } from 'src/i18n/arg-locales/formatjs-polyfills';
import { installCKEditorLocale } from 'src/i18n/arg-locales/ckeditor-install';
import { ARG_LOCALE_KEY, Connector } from 'src/utils/connector';
import { UserLocaleConfig } from 'src/contexts/user-locale-context';
import { getArgLocale, normalizeLocale, toArgUserLocale } from 'src/i18n/arg-locales/get-locale';
import { useAntdLocale } from 'src/i18n/arg-locales/use-antd-locale';
import { Environment } from 'src/utils/environment';

const debug = Debug('common:arg-locales:useArgI18n');

const DEFAULT_LOCALE = Environment.defaultLocale || 'en';

interface UseArgI18nReturnTpe {
    antdLocale: Locale;
    userLocale: string;
    userLocaleConfig: UserLocaleConfig;
    messages: Record<string, string> | null | undefined;
}

export function useArgI18n(): UseArgI18nReturnTpe {
    const [userLocale, setUserLocale] = useState<string>();
    const messages = useLoadMessages(userLocale);

    useEffect(() => {
        if (!userLocale) {
            document.documentElement.removeAttribute('lang');

            return;
        }
        debug('useArgI18n', 'UserLocale=', userLocale);
        document.documentElement.setAttribute('lang', userLocale);
    }, [userLocale]);

    const installLocales = useCallback(async (locale: string) => {
        debug('installPolyfill', 'Selected language=', locale);

        const p1 = installDayjs(locale);
        const p2 = installFormatJSPolyfills(locale);
        const p3 = installCKEditorLocale(locale);

        return Promise.all([p1, p2, p3]).then(() => {
            dayjs.locale(locale);
        }, (error) => {
            console.error('Install polyfill throws error=', error);
        });
    }, []);

    const handleChangeLocale = useCallback(async (newLocale: string) => {
        debug('handleChangeLocale', 'Current locale=', userLocale, 'requestedLocale=', newLocale);
        if (userLocale === newLocale) {
            return;
        }

        localStorage.setItem(ARG_LOCALE_KEY, newLocale);

        await installLocales(newLocale);
        Connector.getInstance().setLanguage(newLocale);

        setUserLocale(newLocale);
    }, [installLocales, userLocale]);

    useEffect(() => {
        let selectedLocale = Environment.forcedLocale || localStorage.getItem(ARG_LOCALE_KEY) || undefined;
        if (selectedLocale && !getArgLocale(selectedLocale)) {
            selectedLocale = undefined;
        }

        if (!selectedLocale) {
            const normalizedLocales = (navigator.languages || []).map((l) => normalizeLocale(l)).filter((l) => (l));
            normalizedLocales.find((normalizedLocale) => {
                if (!getArgLocale(normalizedLocale!)) {
                    return false;
                }

                selectedLocale = normalizedLocale;

                return true;
            });
        }

        if (!selectedLocale) {
            selectedLocale = DEFAULT_LOCALE;
        }

        try {
            if (localStorage?.FORCE_LANGUAGE) {
                selectedLocale = normalizeLocale(localStorage.FORCE_LANGUAGE);
                console.warn('*** LOCALE is FORCED by localStorage to', selectedLocale);
            }
        } catch (x) {
            // Can throw an exception for Safari in private mode
        }

        selectedLocale && installLocales(selectedLocale);
        Connector.getInstance().setLanguage(selectedLocale);
        setUserLocale(selectedLocale);
    }, []);

    const _userLocale = userLocale || Environment.forcedLocale || localStorage.getItem(ARG_LOCALE_KEY) || Environment.defaultLocale || DEFAULT_LOCALE;

    const userLocaleConfig = useMemo<UserLocaleConfig>(() => {
        return {
            userLocale: toArgUserLocale(_userLocale),
            changeUserLocale: handleChangeLocale,
        };
    }, [_userLocale, handleChangeLocale]);

    const antdLocale = useAntdLocale(_userLocale);

    return {
        antdLocale,

        userLocale: _userLocale,

        userLocaleConfig,
        messages,
    };
}
