import { merge } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';


import { GetOntologyStyleDTO } from 'src/settings/models/dtoApi';
import { DEFAULT_EDGE_STYLE } from 'src/exploration/constants/default-edge-style';
import {
    GraphCustomizerState,
    UseGraphStyleCustomisationReturnType,
} from 'src/exploration/hooks/use-graph-style-customisation';
import { FullOntology, FullOntologyLinkType } from '../../types';
import { addProperty, getDefaultValueFromControlType, getEdgeColor, updateStyleFromState } from './utils';
import {
    AdvancedStyleType,
    DiscreteValue,
    Interval,
    LineAnimation,
    LineStyle,
    StyleControlType,
    UserDefinedContent,
} from 'src/components/common/graph/customisation/graph-style';
import { graphStyleCustomisationReducer, TYPES } from 'src/exploration/reducers/graph-style-customisation-reducer';
import { Side } from 'src/components/common/graph/customisation/advanced-style';
import { dayjs, useArgNotifications } from '../../../../../components/basic';
import ontologiesConnector from '../../../../connectors/ontologies-connector';

const messages = defineMessages({
    styleChangesError: {
        id: 'settings.useEdgeStyleCustomisation.message',
        defaultMessage: 'An error occurred while trying to edit the edge style',
    },
});

export const useEdgeStyleCustomisation = (
    ontology: FullOntology,
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>,
    edgeSelected: FullOntologyLinkType | undefined,
    setEdgeSelected: Dispatch<SetStateAction<FullOntologyLinkType | undefined>>,
): UseGraphStyleCustomisationReturnType => {
    const intl = useIntl();
    const notifications = useArgNotifications();

    const [colorAndIconEditorVisible, setColorAndIconEditorVisible] = useState(false);
    const [defaultPropertiesExpanded, setDefaultPropertiesExpanded] = useState(true);

    const defaultEdgeStyle = merge({}, DEFAULT_EDGE_STYLE, edgeSelected?.style);

    const objectType = edgeSelected?.name
        ? ontology.fullStyle.linkTypes[edgeSelected.name]
        : undefined;
    const convertedObject = {
        ...objectType,
        userDefinedContent: objectType?.userDefinedContent
            ? (objectType.userDefinedContent as UserDefinedContent)
            : {},
        ruleSets: objectType?.ruleSets || {},
    };

    const graphCustomiserState: GraphCustomizerState = {
        ...convertedObject,
        colorAndIconEditorVisible,
        defaultPropertiesExpanded,
    };

    const submitNewStyle = useCallback(
        async (newStyle?: GetOntologyStyleDTO) => {
            if (!newStyle) {
                return;
            }
            try {
                await ontologiesConnector.editOntologyStyle(ontology.id, { style: newStyle });
                const newOntology = await ontologiesConnector.getFullOntology(ontology.id);
                setOntology(newOntology);
            } catch (e) {
                notifications.snackError({ message: messages.styleChangesError }, e as Error);
            }
        },
        [intl, ontology.id, setOntology],
    );

    const objectTypeList = useMemo(() => {
        return ontology?.linkTypes.map((edge) => edge.name);
    }, [ontology?.linkTypes]);

    return {
        initialStyles: {
            vertexStyles: {},
            edgeStyles: {},
        },
        currentStyles: {
            vertexStyles: {},
            edgeStyles: {},
        },
        refreshInitialStyles: () => {
        },
        loadStyles: async () => {
        },
        resetStyles: async () => {
        },
        defaultVertexStyle: undefined,
        state: {
            label: edgeSelected?.name,
            titleProperty: edgeSelected?.style.titleProperty,
            clusteringProperty: undefined,
            userDefinedContent: {
                size: defaultEdgeStyle?.size,
                gradientSize: undefined,
                fillColor: getEdgeColor(defaultEdgeStyle),
                iconColor: getEdgeColor(defaultEdgeStyle),
                strokeColor: undefined,
                iconName: undefined,
                iconFontFamily: undefined,
                iconScale: undefined,
                offsetX: undefined,
                offsetY: undefined,
                gradientBounds: undefined,
                badgeBlink: undefined,
                lineStyle: defaultEdgeStyle?.lineStyle,
                lineAnimation: defaultEdgeStyle?.lineAnimation,
                id: undefined,
            },
            ruleSets: convertedObject.ruleSets,
            defaultPropertiesExpanded: defaultPropertiesExpanded,
            colorAndIconEditorVisible: colorAndIconEditorVisible,
        },
        objectTypeList,
        clusterableProperties: [],
        geographyProperties: [],
        canBeDefinedAsTitleProperties: edgeSelected?.properties.map(({ name }) => name) || [],
        properties: edgeSelected?.properties.map(({ name }) => name) || [],
        setFillColor: async (input: string) => {
            const newProperties = { fillColor: input };
            const newStyle = addProperty(
                ontology.fullStyle,
                edgeSelected,
                newProperties,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        setStrokeColor: async () => {
        },
        setIconColor: async () => {
        },
        setIcon: async () => {
        },
        setIsStrokeTransparent: async () => {
        },
        setIconAndColorVisibility: (visible: boolean) => setColorAndIconEditorVisible(visible),
        setClusteringProperty: async () => {
        },
        setGeographicalProperty: async () => {
        },
        setAdvancedStyleProperty: async (
            advancedStyleType: AdvancedStyleType,
            property: string | undefined,
            controlType?: StyleControlType,
        ) => {
            const value = getDefaultValueFromControlType(property, controlType);
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.SET_ADVANCED_STYLE_PROPERTY,
                undoRedo: () => {
                },
                payload: { advancedStyleType, property, value, controlType },
            });
            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                edgeSelected,
                newState,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        setAdvancedStyleDiscretePropertyValue: async (
            advancedStyleType: AdvancedStyleType,
            value: string | number | boolean | null,
            index: number,
        ) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.SET_ADVANCED_DISCRETE_PROPERTY_VALUE,
                undoRedo: () => {
                },
                payload: { advancedStyleType, value, index, state: graphCustomiserState },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                edgeSelected,
                newState,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        setAdvancedStyleRangeValue: async (
            advancedStyleType: AdvancedStyleType,
            side: Side,
            value: number | dayjs.Dayjs | null,
            index: number,
        ) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.SET_RANGE_VALUE,
                undoRedo: () => {
                },
                payload: { advancedStyleType, side, value, index },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                edgeSelected,
                newState,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        setAdvancedStyleRangeIntervalType: async (
            advancedStyleType: AdvancedStyleType,
            side: Side,
            index: number,
        ) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: advancedStyleType,
                undoRedo: () => {
                },
                payload: { advancedStyleType, clickSide: side, position: index },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                edgeSelected,
                newState,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        setAdvancedStyle: async (
            advancedStyleType: AdvancedStyleType,
            style: Record<string, any>,
            index: number,
        ) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.SET_ADVANCED_STYLE,
                undoRedo: () => {
                },
                payload: { advancedStyleType, style, index },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                edgeSelected,
                newState,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        addNewAdvancedStyleRule: async (
            advancedStyleType: AdvancedStyleType,
            value: DiscreteValue | Interval | null,
        ) => {
            let payloadValue = value;
            if (value && 'interval' in value) {
                payloadValue = null;
            }
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.ADD_NEW_ADVANCED_STYLE_RULE,
                undoRedo: () => {
                },
                payload: { advancedStyleType, value: payloadValue },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                edgeSelected,
                newState,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        removeAdvancedStyleRule: async (advancedStyleType: AdvancedStyleType, index: number) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.REMOVE_ADVANCED_STYLE_RULE,
                undoRedo: () => {
                },
                payload: { advancedStyleType, position: index },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                edgeSelected,
                newState,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        onVertexLabelChange: async (input: string) => {
            const edgeSelected = ontology.linkTypes.find((edge) => edge.name === input);
            setEdgeSelected(edgeSelected);
        },
        isSomeProgressMonitorsRunning: false,
        onToggleDefaultPropClick: async () => setDefaultPropertiesExpanded((current) => !current),
        onDisplayPropertyChange: async (displayProperty: string | undefined) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.UPDATE_DISPLAY_PROPERTY,
                undoRedo: () => { },
                payload: displayProperty,
            });
            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                edgeSelected,
                newState,
                'linkTypes',
            );
            submitNewStyle(newStyle);
        },
        onSizeChange: async (input: number | null) => {
            const newProperties = { size: typeof (input) === 'number' ? input : undefined };
            const newStyle = addProperty(
                ontology.fullStyle,
                edgeSelected,
                newProperties,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        toggleGradientInterval: async () => {
        },
        setGradientPropertyRangeValue: async () => {
        },
        intl: intl,
        onResetStyles: async () => {
            const newProperties = {
                color: undefined,
                size: undefined,
                lineStyle: undefined,
                lineAnimation: undefined,
            };
            const newStyle = addProperty(
                ontology.fullStyle,
                edgeSelected,
                newProperties,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        addUndefinedValueStylesRule: async (advancedStyleType: AdvancedStyleType) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.ADD_UNDEFINED_VALUE_STYLES_RULE,
                undoRedo: () => {
                },
                payload: { advancedStyleType },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                edgeSelected,
                newState,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        removeUndefinedValueStylesRule: async (advancedStyleType: AdvancedStyleType) => {
            const newState = graphStyleCustomisationReducer(graphCustomiserState, {
                type: TYPES.REMOVE_UNDEFINED_VALUE_STYLES_RULE,
                undoRedo: () => {
                },
                payload: { advancedStyleType },
            });

            const newStyle = updateStyleFromState(
                ontology.fullStyle,
                edgeSelected,
                newState,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        setLineStyle: async (input: LineStyle) => {
            const newProperties = { lineStyle: input };
            const newStyle = addProperty(
                ontology.fullStyle,
                edgeSelected,
                newProperties,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
        setLineAnimation: async (input: LineAnimation) => {
            const newProperties = { lineAnimation: input };
            const newStyle = addProperty(
                ontology.fullStyle,
                edgeSelected,
                newProperties,
                'linkTypes',
            );
            if (newStyle === ontology.fullStyle) {
                return;
            }
            submitNewStyle(newStyle);
        },
    };
};
