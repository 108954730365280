import { ArgButton, ArgIcon, ButtonClickEvent, useClassNames } from 'src/components/basic';

import './icon-button.less';

interface IconButtonProps {
    iconName: string;
    onClick: ((event: ButtonClickEvent) => any) | undefined;
    isSelected?: boolean;
}

export function IconButton(props: IconButtonProps) {
    const { iconName, onClick, isSelected } = props;
    const classNames = useClassNames('common-icon-button');

    return (
        <ArgButton
            key={iconName}
            className={classNames('&', { selected: isSelected })}
            type='ghost'
            size='medium'
            icon={
                <ArgIcon
                    key={iconName}
                    name={iconName}
                    size='20px'
                />
            }
            onClick={onClick}
        />
    );
}
