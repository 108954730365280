import { Steps } from 'antd/lib';
import React, { useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Debug from 'debug';
import { isEmpty } from 'lodash';

import {
    ArgButton,
    ArgDragAndDropUploader,
    ArgIcon,
    ArgModal,
    ArgRadio,
    ArgTooltip2,
    ArgUploaderButton,
    ClassValue,
    ProgressMonitor,
    Tool,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { ConfigurationManifest, ConfigurationOption, SynchronizationAction } from '../../../../model/configuration';
import {
    ApplicationsParametersConfiguration,
} from '../../../../components/common/applications/applications-parameters-configuration';
import { LoadingPane } from '../../../../components/common/panes/loading-pane';
import { ConfigurationConnector } from 'src/utils/connectors/configuration-connector';
import {
    ConfigurationErrorNotificationDescription,
} from '../configuration-error-notification-description/configuration-error-notification-description';
import { ConfigurationsScope } from '../../configuration-type';
import { SettingsEnvironmentContext } from '../../../envrionment-contexts/settings-environment-context';
import { registerToolItem } from '../../../../components/basic';
import { SETTINGS_TOOLBAR_CONTEXT_NAME } from '../../../envrionment-contexts/context-names';

import './global-import-modal.less';


const debug = Debug('settings:GlobalImportModal');

const CLASSNAME = 'settings-global-import-modal';

const CONFIGURATION_SCOPE: ConfigurationsScope = 'settings';

const SUPPORTED_IMPORT_MIME_TYPES = [
    'application/x-zip-compressed', 'application/zip',
];

const messages = defineMessages({
    importGlobalPopupTitle: {
        id: 'settings.global-import-modal.importGlobalPopupTitle',
        defaultMessage: 'Global import',
    },
    importFileStep: {
        id: 'settings.global-import-modal.importFileStep',
        defaultMessage: 'Import file',
    },
    selectItemsStep: {
        id: 'settings.global-import-modal.selectElementsStep',
        defaultMessage: 'Select items',
    },
    dropImportMessage: {
        id: 'settings.global-import-modal.dropImportMessage',
        defaultMessage: 'Drag and drop a .zip file or open',
    },
    browseTitle: {
        id: 'settings.global-import-modal.browseTitle',
        defaultMessage: 'the file explorer',
    },
    invalidType: {
        id: 'settings.global-import-modal.InvalidType',
        defaultMessage: 'Invalid file',
    },
    importError: {
        id: 'settings.global-import-modal.importError',
        defaultMessage: 'An error occurred while getting the configurations',
    },
    importConfigurationError: {
        id: 'settings.global-import-modal.importConfigurationError',
        defaultMessage: 'Cannot import configurations',
    },
    importConfigurationDescriptionError: {
        id: 'settings.global-import-modal.importConfigurationDescriptionError',
        defaultMessage: '{count, plural, =1 {1 error detected } other {{count} errors detected}}',
    },
    import: {
        id: 'settings.global-import-modal.import',
        defaultMessage: 'Import',
    },
    cancel: {
        id: 'settings.global-import-modal.cancel',
        defaultMessage: 'Cancel',
    },
    importSucceed: {
        id: 'settings.global-import-modal.importSucceed',
        defaultMessage: 'Configurations have successfully been imported',
    },
    importInProgress: {
        id: 'settings.global-import-modal.importInProgress',
        defaultMessage: 'Import of file in progress',
    },
    keepConfigurations: {
        id: 'settings.global-import-modal.keepConfigurations',
        defaultMessage: 'Keep',
    },
    overwriteConfigurations: {
        id: 'settings.global-import-modal.overwriteConfigurations',
        defaultMessage: 'Overwrite',
    },
    globalConfiguration: {
        id: 'settings.global-import-modal.globalConfiguration',
        defaultMessage: 'the global configuration',
    },
    identicalConfigurations: {
        id: 'settings.global-import-modal.identicalConfigurations',
        defaultMessage: 'In case of identical configurations :',
    },
    updateExistingConfigurations: {
        id: 'settings.global-import-modal.updateExistingConfigurations',
        defaultMessage: 'Update existing configurations',
    },
    ignoreNewConfigurations: {
        id: 'settings.global-import-modal.ignoreNewConfigurations',
        defaultMessage: 'Ignore new configurations',
    },
    renameIdenticalConfigurations: {
        id: 'settings.global-import-modal.renameIdenticalConfigurations',
        defaultMessage: 'Rename automatically identical configurations',
    },
    overwriteWarning: {
        id: 'settings.global-import-modal.overwriteWarning',
        defaultMessage: 'If you overwrite the global configuration, all current configuration items will be deleted, if you have the right to do so',
    },
    overwriteTooltip: {
        id: 'settings.global-import-modal.overwriteTooltip',
        defaultMessage: 'Choose the behavior to adopt in case of inability to overwrite current configurations',
    },
    globalImport: {
        id: 'settings.home-page.fetch-universe.globalImport',
        defaultMessage: 'Global import',
    },
});

interface GlobalImportModalProps {
    className?: ClassValue;
    onClose: () => void;
}

function GlobalImportModal(props: GlobalImportModalProps) {
    const { className, onClose } = props;

    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const [current, setCurrent] = useState(0);
    const [currentConfigurations, setCurrentConfigurations] = useState<ConfigurationManifest[]>([]);
    const [file, setFile] = useState<Blob>();
    const [selectedOptions, setSelectedOptions] = useState<ConfigurationOption[]>();
    const [keepConfigurations, setKeepConfigurations] = useState<boolean>(true);
    const [importAction, setImportAction] = useState<SynchronizationAction>(SynchronizationAction.UpdateExisting);

    const onImportSucceed = useCallback(() => {
        notifications.snackInfo({ message: messages.importSucceed });
        onClose();
    }, [notifications, onClose]);

    const [onImportGlobalManifestClick] = useCallbackAsync(async (progressMonitor: ProgressMonitor, file: Blob) => {
        try {
            if (!file || !SUPPORTED_IMPORT_MIME_TYPES.includes(file.type)) {
                notifications.snackError({ message: messages.invalidType });

                return;
            }

            const manifest = await ConfigurationConnector.getInstance().importManifest(file, CONFIGURATION_SCOPE, progressMonitor);

            debug('import', 'manifest=', manifest);
            setCurrentConfigurations(manifest.configurations);
            setCurrent(1);
            setFile(file);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.importError }, error as Error);
            throw error;
        }
    }, [notifications]);

    const importFileStepContent = (
        <ArgDragAndDropUploader
            method={onImportGlobalManifestClick}
            className={classNames('&-upload')}
        >
            <div className={classNames('&-upload-container')}>
                <ArgIcon size={40} name='icon-download' className={classNames('&-download-button')} />
                <span><FormattedMessage {...messages.dropImportMessage} /></span>
                <ArgUploaderButton
                    type='link'
                    key='upload'
                    size='large'
                    method={onImportGlobalManifestClick}
                    label={messages.browseTitle}
                    className={classNames('&-upload-button')}
                />
            </div>
        </ArgDragAndDropUploader>
    );

    const handleSelection = useCallback((selectedOptions: ConfigurationOption[]) => {
        setSelectedOptions(selectedOptions);
    }, []);

    const selectItemsStepContent = (
        <React.Fragment>
            <div className={classNames('&-element-switcher')}>
                <ArgButton
                    className={classNames('&-element-switcher-keep-configuration', {
                        inactive: keepConfigurations === false,
                    })}
                    size='medium'
                    label={messages.keepConfigurations}
                    type={keepConfigurations === true ? 'primary' : 'ghost'}
                    onClick={() => setKeepConfigurations(true)}
                />
                <ArgButton
                    className={classNames('&-element-switcher-erase-configuration', {
                        inactive: keepConfigurations === true,
                    })}
                    size='medium'
                    label={messages.overwriteConfigurations}
                    type={keepConfigurations === false ? 'primary' : 'ghost'}
                    onClick={() => setKeepConfigurations(false)}
                />
                <span
                    className={classNames('&-configuration-label')}><FormattedMessage {...messages.globalConfiguration} /></span>
            </div>
            {keepConfigurations === false && (
                <div className={classNames('&-configuration-warning')}>
                    <ArgIcon size='large' className={classNames('&-configuration-warning-icon')}
                             name='icon-exclamation-point' />
                    <FormattedMessage {...messages.overwriteWarning} />
                </div>
            )}
            <div className={classNames('&-import-global-container')}>
                <ApplicationsParametersConfiguration
                    currentConfigurations={currentConfigurations}
                    onSelectionChange={handleSelection}
                />
            </div>
            <div className={classNames('&-configurations-radio-container')}>
                <div className={classNames('&-configurations-radio-container-label')}>
                    <FormattedMessage {...messages.identicalConfigurations} />
                    {keepConfigurations === false && (
                        <ArgTooltip2 title={messages.overwriteTooltip}>
                            <ArgIcon name='icon-notification' />
                        </ArgTooltip2>
                    )}
                </div>

                {keepConfigurations === true && <ArgRadio
                    className={classNames('&-radio')}
                    value={importAction === SynchronizationAction.UpdateExisting}
                    label={messages.updateExistingConfigurations}
                    onChange={() => setImportAction(SynchronizationAction.UpdateExisting)}
                />}
                <ArgRadio
                    className={classNames('&-radio')}
                    value={importAction === SynchronizationAction.Ignore}
                    label={messages.ignoreNewConfigurations}
                    onChange={() => setImportAction(SynchronizationAction.Ignore)}
                />
                <ArgRadio
                    className={classNames('&-radio')}
                    value={importAction === SynchronizationAction.RenameConflicts}
                    label={messages.renameIdenticalConfigurations}
                    onChange={() => setImportAction(SynchronizationAction.RenameConflicts)}
                />
            </div>
        </React.Fragment>
    );

    const steps = [
        {
            title: <FormattedMessage {...messages.importFileStep} />,
            content: importFileStepContent,
        },
        {
            title: <FormattedMessage {...messages.selectItemsStep} />,
            content: selectItemsStepContent,
        },
    ];

    const [onImport, importPM] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            if (!file || !selectedOptions) {
                return;
            }

            const result = await ConfigurationConnector.getInstance().importConfigurations(
                file,
                { options: selectedOptions },
                keepConfigurations ? importAction : SynchronizationAction.ReplaceAll,
                CONFIGURATION_SCOPE,
                progressMonitor,
            );
            if (isEmpty(result) || !result.errors) {
                onImportSucceed();

                return;
            }
            const nbImportErrors = result.errors.length;

            notifications.notifError(
                {
                    message: messages.importConfigurationError,
                    description: messages.importConfigurationDescriptionError,
                    details: (
                        <ConfigurationErrorNotificationDescription
                            errors={result.errors}
                        />
                    ),
                },
                undefined,
                {
                    count: nbImportErrors,
                });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.importError }, error as Error);
            throw error;
        }
    }, [file, selectedOptions, keepConfigurations, importAction, onImportSucceed, notifications]);


    const items = steps.map((item) => ({ key: item.title, title: item.title }));
    const isImportButtonDisabled = current === 0 || !selectedOptions || selectedOptions.length === 0;

    let modal;
    if (!importPM?.isRunning) {
        modal = (
            <ArgModal
                size='medium'
                title={messages.importGlobalPopupTitle}
                onClose={onClose}
                okText={messages.import}
                cancelText={messages.cancel}
                okDisabled={isImportButtonDisabled}
                onOk={onImport}
                progressMonitor={importPM}
            >
                <Steps size='small' current={current} items={items} />
                <div className={classNames('&-step-container')}>{steps[current].content}</div>
            </ArgModal>);
    } else {
        modal = (
            <ArgModal
                size='medium'
                onClose={onClose}
                okText={false}
                cancelText={false}
            >
                <div className={classNames('&', className, 'loading')}>
                    <LoadingPane size='small' message={messages.importInProgress}
                                 className={classNames('&-loading-pane')} />
                </div>
            </ArgModal>
        );
    }

    return modal;
}

export function setupGlobalImport() {
    registerToolItem<SettingsEnvironmentContext>(SETTINGS_TOOLBAR_CONTEXT_NAME, {
        path: 'right/global-import',
        order: 100,
        label: messages.globalImport,
        type: 'button',
        icon: 'icon-download',
        className: `${CLASSNAME}-button-import`,
        visible: (settingsEnvironmentContext: SettingsEnvironmentContext) => {
            return settingsEnvironmentContext.hasAnyPermissions('admin.import.export.settings');
        },
        onClick: (tool: Tool<SettingsEnvironmentContext>, settingsEnvironmentContext: SettingsEnvironmentContext) => {
            settingsEnvironmentContext.modalContext.open('global-import',
                <GlobalImportModal onClose={() => settingsEnvironmentContext.modalContext.close('global-import')} />,
            );
        },
    });
}
