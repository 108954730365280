import { UniverseState } from './universe-state';
import { UniverseId } from '../model/universe';
import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { UNIVERSE_CONTENT_EVENTS, UNIVERSE_INFO_EVENTS } from './events';
import { RtMessageEventList } from '../../utils/rt-states/events';

function universeStateFactory(url: string, universeId: UniverseId, acceptedEvents: RtMessageEventList) {
    return new UniverseState(url, universeId, acceptedEvents);
}

export function useUniverseContentState(universeId: UniverseId): UniverseState;
export function useUniverseContentState(universeId: UniverseId | undefined): UniverseState | undefined;

export function useUniverseContentState(universeId: UniverseId | undefined): UniverseState | undefined {
    const universeState = useBasicState<UniverseState>(
        (url) => universeStateFactory(url, universeId!, UNIVERSE_CONTENT_EVENTS),
        (universeId) ? `/universes/${universeId}/content` : undefined,
    );

    return universeState;
}

export function useUniverseInfoState(universeId: UniverseId): UniverseState;
export function useUniverseInfoState(universeId: UniverseId | undefined): UniverseState | undefined;

export function useUniverseInfoState(universeId: UniverseId | undefined): UniverseState | undefined {
    const universeState = useBasicState<UniverseState>(
        (url) => universeStateFactory(url, universeId!, UNIVERSE_INFO_EVENTS),
        (universeId) ? `/universes/${universeId}/info` : undefined,
    );

    return universeState;
}
