import { isEmpty, isEqual, isNumber, isString, map } from 'lodash';

import { ComponentLibrary, ComponentPinType } from '../../model/component';
import { Composite, CompositeCasePiece, CompositePin } from '../../model/composite';
import {
    ClipboardProcess,
    DEFAULT_PRIORITY,
    Process,
    ProcessBody,
    ProcessCasePiece,
    ProcessComponent,
    ProcessComponentId,
    ProcessConnection,
    ProcessDebugInformation,
    ProcessDefinitionIssue,
    ProcessPriority,
} from '../../model/process';
import { VisualizationData } from '../../common/visualization/visualization-data-types';
import { ScheduleSettings } from '../../../components/crontab/schedule-settings';
import {
    ProcessMonitoringComponent,
    ProcessMonitoringData,
    ProcessMonitoringInputPin,
    ProcessMonitoringOutputPin,
} from '../../model/process-monitoring';
import { mapDate, mapScheduleSettings } from '../../../utils/connectors/mappers';
import { SecretCasePiece } from '../../secrets/secrets';
import { ProcessHistoryTag } from '../../model/process-history';
import { CompositeHistoryTag } from '../../model/composite-history';
import { immutableSet, immutableUpdate } from '../../../components/basic';
import { CasePieceType } from '../../../model/case-piece-type';
import { ArgonosPieceInFolder, FolderId } from '../../../model/folder';
import { ETaggedObject } from '../../../utils/connector';

export const BLACK_BOX_NODE_ID = 'black-box-id';

export interface ValidationReport {
    missingComponentUniqueId?: ProcessComponent[];

    duplicateComponentUniqueId?: ProcessComponent[];

    invalidComponentKey?: ProcessComponent[];

    duplicateConnectionUniqueId?: ProcessConnection[];

    invalidConnectionTarget?: ProcessConnection[];

    invalidFromPin?: ProcessConnection[];

    invalidToPin?: ProcessConnection[];
}


class ValidationError extends Error {
    public readonly detail: any;

    constructor(message: string, detail?: any) {
        super(message);

        this.detail = detail;
    }
}

function mapProcessInfo(result: any): Process {
    const processInfo: Process = {
        id: result.id,
        //processKey: result.key,
        type: result.type,
        definition: result.definition,
        displayName: result.displayName,
        description: result.description,
        createdBy: result.createdBy,
        lastUpdatedBy: result.lastUpdatedBy,
        createdDate: result.createdDate ? new Date(result.createdDate) : undefined,
        lastVisitedDate: result.lastVisitedDate ? new Date(result.lastVisitedDate) : undefined,
        lastUpdatedDate: result.lastUpdatedDate ? new Date(result.lastUpdatedDate) : undefined,

        //status: ProcessStatus.Stopped,
        //priority: result.priority,
    };

    return processInfo;
}

function mapCompositePin(pins: any, pinType: ComponentPinType): CompositePin[] {
    const formattedPins: CompositePin[] = pins?.map((pin: any) => ({
        ...pin,
        type: pinType,
    }));

    return formattedPins;
}

function mapCompositeDefinitionIssues(result: any): ProcessDefinitionIssue[] {
    const issues: ProcessDefinitionIssue[] = result?.definitionIssues?.map((issue: any) => ({
        ...issue,
        componentUniqueId: issue.componentUniqueId.replace('_component_to_test__', ''),
    }));

    return issues;
}

function mapCompositeInfo(result: any): CompositeCasePiece {
    const compositeComponentInfo: CompositeCasePiece = {
        id: result.id,
        displayName: result.displayName,
        type: CasePieceType.Component,
        category: result.category,
        description: result.description,
        createdBy: result.createdBy,
        lastUpdatedBy: result.lastUpdatedBy,
        createdDate: result.createdDate ? new Date(result.createdDate) : undefined,
        lastVisitedDate: result.lastVisitedDate ? new Date(result.lastVisitedDate) : undefined,
        lastUpdatedDate: result.lastUpdatedDate ? new Date(result.lastUpdatedDate) : undefined,
    };

    return compositeComponentInfo;
}

export function mapComposite(result: any): Composite {
    const composite: Composite = {
        ...mapCompositeInfo(result),
        definition: {
            ...result.definition,
            inPins: mapCompositePin(result?.definition?.inPins, ComponentPinType.In),
            outPins: mapCompositePin(result?.definition?.outPins, ComponentPinType.Out),
            errorPins: mapCompositePin(result?.definition?.errorPins, ComponentPinType.Error),
        },
        editorContent: result.editorContent,
        definitionIssues: mapCompositeDefinitionIssues(result),
    };

    (composite as ETaggedObject).etag = result.etag;

    return composite;
}

/**
 * Verifies the given process and performs various validations.
 *
 * @param {Partial<Process> | ClipboardProcess} process - The process to be verified.
 * @param {Readonly<ComponentLibrary>} componentLibrary - The component library used for validation.
 * @param {boolean} [isPartial=false] - Indicates whether the process is partial or not.
 * @param {ValidationReport} [validationReport] - The validation report to store the results.
 *
 * @throws {ValidationError} Throws an error if the process name is invalid or the format is invalid.
 *
 * @return {Partial<Process> | ClipboardProcess} Returns the verified process.
 */
export function verifyProcess<T extends Partial<Process> | ClipboardProcess>(process: T, componentLibrary: Readonly<ComponentLibrary>, isPartial = false, validationReport?: ValidationReport): T {
    if (!isPartial && !isString(process.displayName)) {
        const error = new ValidationError('Name is invalid');
        throw error;
    }

    if (!process.definition || typeof (process.definition) !== 'object') {
        const error = new ValidationError('Format is invalid');
        throw error;
    }

    let verifiedProcess = immutableUpdate(process, ['definition', 'components'], (prev: ProcessComponent[]) => {
        let ret = prev.filter((c) => {
            if (c.uniqueId) {
                return true;
            }

            console.warn('Missing uniqueId', c);

            if (validationReport && !validationReport.missingComponentUniqueId) {
                validationReport.missingComponentUniqueId = [];
            }
            validationReport?.missingComponentUniqueId!.push(c);

            return false;
        });

        const alreadyKnown: Record<string, true> = {};
        // Check uniqueIds to be uniq
        ret = ret.filter((c) => {
            if (!alreadyKnown[c.uniqueId]) {
                alreadyKnown[c.uniqueId] = true;

                return true;
            }

            console.warn('Duplicate uniqueId', c);

            if (validationReport && !validationReport.duplicateComponentUniqueId) {
                validationReport.duplicateComponentUniqueId = [];
            }
            validationReport?.duplicateComponentUniqueId!.push(c);

            return false;
        });

        return ret;
    });

    const alreadyKnownConnections: Record<string, true> = {};
    // Check uniqueIds to be uniq
    verifiedProcess = immutableUpdate(verifiedProcess, ['definition', 'connections'], (prev: ProcessConnection[]) => {
        const ret = prev.filter((processConnection) => {
            const uniqueId = computeConnectionId(processConnection);
            if (!uniqueId) {
                return true;
            }

            if (!alreadyKnownConnections[uniqueId]) {
                alreadyKnownConnections[uniqueId] = true;

                return true;
            }

            console.warn('Duplicate uniqueId', processConnection);

            if (validationReport && !validationReport.duplicateConnectionUniqueId) {
                validationReport.duplicateConnectionUniqueId = [];
            }
            validationReport?.duplicateConnectionUniqueId!.push(processConnection);

            return false;
        });

        return ret;
    });

    return verifiedProcess;
}

export function mapProcessBody(result: any): ProcessBody {
    const process: ProcessBody = {
        definition: {
            ...result.definition,
            // Manage a uniqueId if the server doesn't provide one
            connections: map(result?.definition?.connections, (connection: ProcessConnection, index: number) => ({
                //uniqueId: `edge-${index}`, // @TODO REMOVE IT !
                ...connection,
            })),
        },
        editorContent: result.editorContent,
    };

    return process;
}

export function mapCompositeBody(result: any): ProcessBody {
    const body = mapProcessBody(result);

    return body;
}

export function mapProcessDefinitionIssues(result: any): ProcessDefinitionIssue[] {
    if (isEmpty(result?.definitionIssues)) {
        return [];
    }

    const definitionIssues: ProcessDefinitionIssue[] = result.definitionIssues.map((issue: ProcessDefinitionIssue) => {
        const componentUniqueId = issue.componentUniqueId.includes('_') ? issue.componentUniqueId.split('_')[0] : issue.componentUniqueId;

        const ret: ProcessDefinitionIssue = {
            ...issue,
            componentUniqueId,
        };

        return ret;
    });

    return definitionIssues;
}

export function mapProcessSchedule(raw: any): ScheduleSettings | undefined {
    if (!raw) {
        return undefined;
    }

    const result = mapScheduleSettings(raw);

    return result;
}

export function mapProcess(result: any): Process {
    const process: Process = {
        ...mapProcessInfo(result),
        ...mapProcessBody(result),
        //processKey: result.key,
        //schedule: mapProcessSchedule(result.schedule),
        sampleData: result.sampleData || {},
        definitionIssues: mapProcessDefinitionIssues(result),
        componentsCompleteness: result.componentsCompleteness,
        //automaticChangeDetection: result.automaticChangeDetection,
        //nextScheduledExecution: result.nextScheduledExecution ? new Date(result.nextScheduledExecution) : undefined,
        //priority: result.priority,
    };

    (process as ETaggedObject).etag = result.etag;

    return process;
}

export function mapHistoryTag(tag: ProcessHistoryTag | CompositeHistoryTag): ProcessHistoryTag | CompositeHistoryTag {
    tag = immutableSet(tag, 'createdDate', new Date(tag.createdDate));

    return tag;
}

export function mapDebugInformation(processDebugInformation: any): ProcessDebugInformation[] {
    return map(processDebugInformation, (processDebugInformationItem: ProcessDebugInformation) => {
        return ({
            ...processDebugInformationItem,
            logEntries: map(processDebugInformationItem.logEntries, (logEntrie) => ({
                ...logEntrie,
                utcTime: new Date(logEntrie.utcTime),
            })),
            pinDebugInformation: map(processDebugInformationItem.pinDebugInformation, (pinDebugInformationItem) => ({
                ...pinDebugInformationItem,
                utcTime: new Date(pinDebugInformationItem.utcTime),
            })),
        });
    });
}

export function normalizeVisualizationData(data: VisualizationData): VisualizationData {
    const values = data.values;

    data.columns.forEach((column, columnIndex) => {
        if (column.type === 'Date') {
            if (values) {
                values.forEach((rowValue) => {
                    const cellValue = rowValue[columnIndex];
                    if (!cellValue) {
                        return;
                    }
                    if (typeof (cellValue) === 'string') {
                        rowValue[columnIndex] = new Date(cellValue as string);

                        return;
                    }
                    if (typeof (cellValue) === 'number') {
                        rowValue[columnIndex] = new Date(cellValue as number);
                    }
                });
            }

            if (column.statistics?.valueSpread) {
                column.statistics?.valueSpread.forEach((vc) => {
                    let bound = vc.lowerBound;
                    if (typeof (bound) === 'string') {
                        vc.lowerBound = new Date(bound as string);
                    } else if (typeof (bound) === 'number') {
                        vc.lowerBound = new Date(bound as number);
                    }
                    bound = vc.upperBound;
                    if (typeof (bound) === 'string') {
                        vc.upperBound = new Date(bound as string);
                    } else if (typeof (bound) === 'number') {
                        vc.upperBound = new Date(bound as number);
                    }
                });
            }

            return;
        }
    });


    return data;
}

export function mapMonitoringData(raw: any): ProcessMonitoringData {
    const components = raw.components?.map((raw: any) => {
        const inputPins = raw.inputPins?.map((raw: any) => {
            const ret: ProcessMonitoringInputPin = {
                ...raw,
                firstProcessing: mapDate(raw.firstProcessing),
                lastProcessing: mapDate(raw.lastProcessing),
            };

            return ret;
        });

        const outputPins = raw.outputPins?.map((raw: any) => {
            const ret: ProcessMonitoringOutputPin = {
                ...raw,
                firstProcessing: mapDate(raw.firstProcessing),
                lastProcessing: mapDate(raw.lastProcessing),
            };

            return ret;
        });

        const ret: ProcessMonitoringComponent = {
            ...raw,
            inputPins,
            outputPins,
        };

        return ret;
    });

    const ret: ProcessMonitoringData = {
        ...raw,
        components,
        statusDate: mapDate(raw.statusDate),
    };

    return ret;
}

export function mapRawProcessPriority(rawPriority: number): ProcessPriority {
    let priority: ProcessPriority = DEFAULT_PRIORITY;

    switch (rawPriority) {
        case 0:
            priority = ProcessPriority.Low;
            break;
        case 1:
            priority = ProcessPriority.Normal;
            break;
        case 2:
            priority = ProcessPriority.High;
            break;
        default:
            break;
    }

    return priority;
}

export function mapSecret(result: any): SecretCasePiece {
    const secretCasePiece: SecretCasePiece = {
        ...result,
        secretType: isNumber(result.type) ? result.secretType : result.type,
        displayName: result?.name || result.displayName,
        createdDate: result.createdDate ? new Date(result.createdDate) : undefined,
        lastVisitedDate: result.lastVisitedDate ? new Date(result.lastVisitedDate) : undefined,
        lastUpdatedDate: result.lastUpdatedDate ? new Date(result.lastUpdatedDate) : undefined,
    };

    return secretCasePiece;
}

export function formatProcessCasePieceToFolderProcessCasePiece(allProcesss: Record<FolderId, ProcessCasePiece[]>): ArgonosPieceInFolder<ProcessCasePiece>[] {
    const ret: ArgonosPieceInFolder<ProcessCasePiece>[] = map(allProcesss, (processes, folderId) => {
        const ret: ArgonosPieceInFolder<ProcessCasePiece>[] = processes.map((process) => {
            return {
                folderId,
                piece: process,
            };
        });

        return ret;
    }).flat();

    return ret;
}

export function formatCompositeCasePieceToFolderCompositeCasePiece(allComposites: Record<FolderId, CompositeCasePiece[]>): ArgonosPieceInFolder<CompositeCasePiece>[] {
    const ret: ArgonosPieceInFolder<CompositeCasePiece>[] = map(allComposites, (composites, folderId) => {
        const ret: ArgonosPieceInFolder<CompositeCasePiece>[] = composites.map((composite) => {
            return {
                folderId,
                piece: composite,
            };
        });

        return ret;
    }).flat();

    return ret;
}

export function computeConnectionId(processConnection: ProcessConnection) {
    const ret = `${processConnection.from.componentUniqueId}:${processConnection.from.pinKey}=>${processConnection.to.componentUniqueId}:${processConnection.to.pinKey}`;

    return ret;
}

export function computePinConnectionId(sourceComponentId: ProcessComponentId, sourcePinKey: string, targetComponentId: ProcessComponentId, targetPinKey: string) {
    const id = `${sourceComponentId}:${sourcePinKey}=>${targetComponentId}:${targetPinKey}`;

    return id;
}


export function isConnectionEqual(c1: ProcessConnection, c2: ProcessConnection) {
    if (!isEqual(c1.from, c2.from)) {
        return false;
    }

    if (!isEqual(c1.to, c2.to)) {
        return false;
    }

    return true;
}

