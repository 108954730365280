import { isFunction, isString } from 'lodash';

import { ArgNavigateTo, ArgNavigateToType } from './arg-navigate-to';


export function resolveNavigateTo(navigateTo: ArgNavigateToType): ArgNavigateTo {
    if (isFunction(navigateTo)) {
        navigateTo = navigateTo();
    }

    if (isString(navigateTo)) {
        const result:ArgNavigateTo = {
            to: navigateTo,
        };

        return result;
    }

    return navigateTo;
}
