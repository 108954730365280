import { useEffect, useState } from 'react';

import { ToolContext, ToolContextId } from './tool-context';

export function useToolContext<T = undefined>(identifier: ToolContextId, overriddenToolContext?: ToolContext<T>): ToolContext<T> {
    const [toolbarContext] = useState<ToolContext<T>>(
        () => new ToolContext<T>(identifier),
    );

    useEffect(() => {
        return () => {
            toolbarContext.destroy();
        };
    }, []);

    return overriddenToolContext || toolbarContext;
}
