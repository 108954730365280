import { isNil } from 'lodash';

import { ClassValue, ArgMessageRenderer, useClassNames } from '../../../../../components/basic';
import { PropertyInformation } from './types';

import './kb-feeding-property-value.less';

const CLASSNAME = 'settings-kb-feeding-property-value';

interface KBFeedingPropertyValueProps {
    className?: ClassValue;
    propertyValue: PropertyInformation;
}

export function KBFeedingPropertyValue({
    className,
    propertyValue,
}: KBFeedingPropertyValueProps) {
    const classNames = useClassNames(CLASSNAME);
    const { label, content } = propertyValue;

    return (
        <div className={classNames('&', className)}>
            <ArgMessageRenderer className={classNames('&-title')} message={label} size='small' />
            <div className={classNames('&-content')}>
                {isNil(content) ? '-' : content}
            </div>
        </div>
    );
}
