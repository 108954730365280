import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ArgButton, ArgMenu, ArgMenuItem, ClassValue, useArgModalContext, useClassNames } from 'src/components/basic';
import { RetentionPolicyActionTargetKind, RetentionPolicyLinkKind, RetentionPolicyVertexEdge } from 'src/settings/universes/retention/types';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType } from '../../types';
import { AutomaticObjectDeletionModal } from '../automatic-object-deletion-modal/automatic-object-deletion-modal';

const CLASSNAME = 'object-properties-panel-menu';

export const MODAL_DELETION = 'modal-deletion';

const messages = defineMessages({
    edit: {
        id: 'settings.object-properties-panel.menu.edit',
        defaultMessage: 'Edit',
    },
    deleteConfiguration: {
        id: 'settings.object-properties-panel.menu.setup-automatic-deletion',
        defaultMessage: 'Set up automatic deletion',
    },
});

export interface ObjectPropertiesPanelMenuProps {
    className?: ClassValue;
    ontology: FullOntology;
    edgeOrVertex: FullOntologyObjectType | FullOntologyLinkType;
    retentionTarget: RetentionPolicyActionTargetKind;
    retentionLink: RetentionPolicyLinkKind;
    editVertexOrEgde: () => void;
    retention: RetentionPolicyVertexEdge;
    setRetention: Dispatch<SetStateAction<RetentionPolicyVertexEdge>>;
}

export function ObjectPropertiesPanelMenu(props: ObjectPropertiesPanelMenuProps) {
    const {
        className,
        ontology,
        edgeOrVertex,
        retentionTarget,
        retentionLink,
        editVertexOrEgde,
        retention,
        setRetention,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const modalContext = useArgModalContext();

    const [visible, setVisible] = useState<boolean>(false);

    const handleEditVertexOrEdge = () => {
        setVisible(false);
        editVertexOrEgde?.();
    };

    const handleModalDeletion = useCallback(async () => {
        setVisible(false);

        modalContext.open(MODAL_DELETION,
            <AutomaticObjectDeletionModal
                closeModal={() => modalContext.close(MODAL_DELETION)}
                ontology={ontology}
                edgeOrVertex={edgeOrVertex}
                retentionTarget={retentionTarget}
                retentionLink={retentionLink}
                retention={retention}
                onChange={setRetention}
            />,
        );
    }, [edgeOrVertex, modalContext, ontology, retention, setRetention, retentionTarget, retentionLink]);

    const actionsMenu = (
        <ArgMenu>
            <ArgMenuItem
                key={messages.edit.id}
                onClick={handleEditVertexOrEdge}
            >
                <span>
                    <FormattedMessage {...messages.edit} />
                </span>
            </ArgMenuItem>
            <ArgMenuItem
                key={messages.deleteConfiguration.id}
                onClick={handleModalDeletion}
            >
                <span>
                    <FormattedMessage {...messages.deleteConfiguration} />
                </span>
            </ArgMenuItem>
        </ArgMenu>
    );

    return (
        <div className={classNames('&', className)} data-testid='object-properties-panel-menu'>
            <ArgButton
                type='ghost'
                icon='icon-options'
                popover={actionsMenu}
                popoverTrigger='click'
                popoverVisible={visible}
                popoverPlacement='bottom'
                onPopoverVisibleChange={setVisible}
            />
        </div>
    );
}
