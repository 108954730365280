import { Dispatch, SetStateAction, useState } from 'react';

import { ArgInputText, useClassNames, useArgNotifications } from 'src/components/basic';
import { ConstraintItem, FormatItem, InputType, messages, TypeItem } from '../const';
import { PropertyInputType } from '../property-input-type';
import { FullOntology, OntologyId, OntologyMetaProperty } from '../../../types';
import { getBaseType } from '../utils';
import { Environment } from 'src/utils/environment';
import { PropertyTypeAndConstraint } from '../property-type-and-constraints';
import { PropertyPreview } from '../property-preview';
import { ArgFormLabel } from '../../../../../../components/basic';
import ontologiesConnector from '../../../../../connectors/ontologies-connector';
import { PropertyFormModal } from '../property-form-modal/property-form-modal';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';

export interface CreateMetapropertyModalProps {
    closeModal: () => void;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    ontologyId: OntologyId;
}

export function CreateMetapropertyModal({
    closeModal,
    setOntology,
    ontologyId,
}: CreateMetapropertyModalProps) {
    const classNames = useClassNames('create-metaproperty-modal');
    const notifications = useArgNotifications();

    const [loading, setLoading] = useState(false);

    const [propertyName, setPropertyName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [type, setType] = useState<TypeItem | undefined>(TypeItem.boolean);
    const [format, setFormat] = useState<FormatItem>();
    const [constraint, setConstraint] = useState<ConstraintItem>(ConstraintItem.none);
    const [characters, setCharacters] = useState<[number, number]>([10, 40]);
    const [displayFinalOptionOnly, setDisplayFinalOptionOnly] = useState(false);

    const [optionsList, setOptionsList] = useState([
        { value: '', id: 1 },
        { value: '', id: 2 },
    ]);

    const handleSubmit = async () => {
        const baseType = getBaseType(type);
        if (!baseType) return;
        const newProperty: Omit<OntologyMetaProperty, 'name'> = {
            displayName: propertyName,
            clientMetadata: { [Environment.appId]: { description } },
            baseType: baseType,
            constraint: optionsList.length === 0 ? undefined : {
                fixedValues: optionsList.map((fixedValue) => ({ value: fixedValue.value })),
                hideFullPath: displayFinalOptionOnly,
            },
            pathDefinition: {
                separator: '/',
            },
        };

        setLoading(true);
        try {
            await ontologiesConnector.addOntologyMetaProperty(newProperty, ontologyId);
            const ontology = await ontologiesConnector.getFullOntology(ontologyId);
            setOntology(ontology);
            setLoading(false);
        } catch (error) {
            notifications.snackError({ message: messages.errorCreatingMetaproperty }, error as Error);
            setLoading(false);
        }
    };

    return (
        <ConfirmModal
            size='large'
            title={messages.metapropertyTitle}
            onClose={closeModal}
            className={classNames('&-modal')}
            type='create'
            onConfirm={handleSubmit}
            confirmDisabled={!type || loading || propertyName === ''}
            confirmLoading={loading}
            confirmText={messages.submit}
        >
            <PropertyFormModal>
                <ArgFormLabel
                    propertyName={messages.metapropertyFieldName}
                    description={messages.metapropertySubtitle}
                >
                    <ArgInputText
                        value={propertyName}
                        onChange={(input) => setPropertyName(input ?? '')}
                    />
                </ArgFormLabel>
                <PropertyTypeAndConstraint
                    type={type}
                    setType={setType}
                    setFormat={setFormat}
                    constraint={constraint}
                    setConstraint={setConstraint}
                    characters={characters}
                    setCharacters={setCharacters}
                    continuousNumber={false}
                    setContinuousNumber={() => {
                    }}
                    isMetaProperty={true}
                    inEditModal={false}
                />
                <PropertyInputType
                    type={type}
                    inputType={InputType.singleSelect}
                    setInputType={() => {
                    }}
                    inputs={[InputType.singleSelect]}
                    format={format}
                    setFormat={setFormat}
                    optionsList={optionsList}
                    setOptionsList={setOptionsList}
                    isMetaProperty={true}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    setDisplayFinalOptionOnly={setDisplayFinalOptionOnly}
                />

                <ArgFormLabel
                    propertyName={messages.fieldMessage}
                    description={messages.fieldMessageDescription}
                >
                    <ArgInputText
                        value={description}
                        onChange={(input) => setDescription(input ?? '')}
                    />
                </ArgFormLabel>
                <PropertyPreview
                    inputType={InputType.singleSelect}
                    propertyName={propertyName}
                    optionsList={optionsList}
                    isMetaproperty={true}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    type={type}
                />
            </PropertyFormModal>
        </ConfirmModal>
    );
}
