import Debug from 'debug';
import { isEmpty } from 'lodash';

import type { User } from '../../model/user';
import { ProgressMonitor } from '../../components/basic';
import { BaseConnector } from './base-connector';
import { mapDate } from './mappers';
import { getAdministrationApi } from './api-url';
import type { Configuration } from '../../components/basic/utils/configurations';
import { Environment } from '../environment';
import { compare } from '../json-patch/json-patch-adapter';

const debug = Debug('common:utils:Connector');

export class UsersSettingsConnector extends BaseConnector {
    private static instance: UsersSettingsConnector;

    static getInstance(): UsersSettingsConnector {
        if (!UsersSettingsConnector.instance) {
            UsersSettingsConnector.instance = new UsersSettingsConnector('settings.users', getAdministrationApi());
        }

        return UsersSettingsConnector.instance;
    }

    async getAppSettings(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Configuration> {
        const appId = Environment.appId;

        const result = await this.request(`/application/settings/me/${encodeURIComponent(appId)}`, {}, progressMonitor);

        debug('getAppSettings', 'result=', result);

        return result;
    }

    async updateAppSettings(
        settings: Configuration,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const appId = Environment.appId;

        await this.request(
            `/application/settings/me/${encodeURIComponent(appId)}`,
            {
                json: settings,
                method: 'PUT',
            },
            progressMonitor,
        );
    }


    async patchAppSettings(
        settings: Configuration,
        previousSettings: Configuration,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const operations = compare(previousSettings, settings);
        if (isEmpty(operations)) {
            return;
        }

        const appId = Environment.appId;

        await this.request(
            `/application/settings/me/${encodeURIComponent(appId)}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json-patch+json' },
                json: {
                    comment: 'Modify settings',
                    changes: operations,
                },
            },
            progressMonitor,
        );
    }
}

export default UsersSettingsConnector.getInstance();

export function mapUser(userInfo: any): User {
    const user: User = {
        ...userInfo,
        displayName: userInfo.displayName || userInfo.fullName || userInfo.userName,
        createdDate: mapDate(userInfo.createdDate),
        lastUpdatedDate: mapDate(userInfo.lastUpdatedDate),
    };

    return user;
}

export function mapMeToUser(basicUser: User): User {
    const user = mapUser(basicUser);

    return { ...user };
}

