import { ComponentKey, ComponentPinKey, ComponentPinType } from './component';
import { VisualizationDataType } from '../common/visualization/visualization-data-types';
import { WranglerStepId } from './wrangler';
import { BasicCasePiece } from '../../model/basic-case-piece';
import { ScheduleSettings } from '../../components/crontab/schedule-settings';

export type ProcessId = string;
export type ProcessComponentId = string;
export type ProcessComponentEventId = string;
export type SampleDataId = string;
export type SampleFileId = string;

export enum LogLevel {
    Information = 'Information',
    Debug = 'Debug',
    Warning = 'Warning',
    Error = 'Error',
}

export enum ProcessStatus {
    Initializing = 'Initializing',
    Running = 'Running',
    Debugging = 'Debugging',
    Stopped = 'Stopped',
    Stopping = 'Stopping',
    Testing = 'Testing',
    Suspended = 'Suspended',
    Suspending = 'Suspending',
    Resuming = 'Resuming',
}

export enum ProcessInstanceResult {
    Success = 'Success',
    Failed = 'Failed',
}

export enum ProcessComponentStatus {
    Processing = 'Processing',
    Stopped = 'Stopped',
    Paused = 'Paused',
    Idle = 'Idle',
    NotFound = 'NotFound',
}

export enum ProcessInstanceStatusEnum {
    Scheduled = 'Scheduled',
    // Succeed = 'Succeed',
    // Error = 'Error',
}

export type ProcessInstanceStatus = ProcessStatus | ProcessInstanceStatusEnum;

export enum ProcessPriority {
    High = 'High',
    Normal = 'Normal',
    Low = 'Low',
}

export enum ProcessComponentCompletenessStep {
    Connection = 'Connection',
    Sample = 'Sample',
    Debug = 'Debug',
    Configuration = 'Configuration',
}

export const DEFAULT_PRIORITY = ProcessPriority.Normal;

export type ProcessKey = string;

export interface ProcessProductionSettings {
    scheduleSettings?: ScheduleSettings;
    automaticChangeDetection?: boolean;
}

export interface ProcessCasePiece extends BasicCasePiece, ProcessProductionSettings {
    processKey: ProcessKey;
    status: ProcessStatus;
    priority: ProcessPriority;
    processIssueCount?: number;
}

export interface ProcessSampleDataIds {
    sampleFileIds: SampleFileId[];
    sampleTableIds: SampleDataId[];
}

export interface ProcessComponent {
    uniqueId: ProcessComponentId;
    componentKey: ComponentKey; // key of the component in library
    name?: string;
    comment?: string;
    isPaused?: boolean;
    options?: Record<string, any>;
    sampleData?: ProcessSampleDataIds;

    //priority?: ProcessPriority;
    //queryIssues?: TestResultItem[];
    //queryResults?: TestResultItem[];
}

export interface ProcessComponentCompleteness {
    uniqueId: ProcessComponentId;
    completeness: ProcessComponentCompletenessStep;
}

export interface ProcessComponentPosition {
    componentUniqueId: ProcessComponentId;
    x?: number;
    y?: number;
}

export interface ProcessConnectionPin {
    componentUniqueId: ProcessComponentId;
    pinKey: ComponentPinKey;
}

export type ProcessConnectionId = string;

export interface ProcessConnection {
//    uniqueId: ProcessConnectionId;
    from: ProcessConnectionPin;
    to: ProcessConnectionPin;
    priority?: ProcessPriority;
}

export interface ProcessComponentPinDebugInformation {
    data: string;
    elapsedMilliseconds: number;
    pinKey: ComponentPinKey;
    utcTime: Date;
}

export interface ProcessComponentLogEntries {
    message: string;
    utcTime: Date;
    elapsedMilliseconds: number;
    level: LogLevel;
}

export interface ProcessDebugInformation {
    componentUniqueId: ProcessComponentId;
    logEntries: ProcessComponentLogEntries[];
    pinDebugInformation: ProcessComponentPinDebugInformation[];
}

export interface ProcessOutputIssue {
    componentUniqueId: ProcessComponentId;
    description: string;
    type: string;
    additionalInformation: AdditionalInformationIssue;
}

export enum ProcessDefinitionIssueType {
    InvalidOption = 'InvalidOption',
    MissingOption = 'MissingOption',
    UnknownOption = 'UnknownOption',
    InvalidConnection = 'InvalidConnection',
    InvalidPinType = 'InvalidPinType',
    MissingPin = 'MissingPin',
    MissingConnection = 'MissingConnection',
    InvalidComponent = 'InvalidComponent',
    MissingComponent = 'MissingComponent',
    InvalidSampleData = 'InvalidSampleData',
    UnknownStep = 'UnknownStep',
    UnknownError = 'UnknownError',
    InvalidStepOption = 'InvalidStepOption',
    MissingStepOption = 'MissingStepOption',
    UnknownStepOption = 'UnknownStepOption'
}

export interface RawAdditionalInformationIssue {
    Key: string;
    Type?: 'from' | 'to';
    Value?: string;
    StepId?: WranglerStepId;
    StepIndex?: string;
    StepOptionKey?: string;
}

export interface AdditionalInformationIssue {
    key: string;
    type?: 'from' | 'to';
    value?: string;
    stepId?: WranglerStepId;
    stepIndex?: string;
    stepOptionKey?: string;
    stepOptionValue?: string;
}

export interface RawProcessDefinitionIssue {
    description: string;
    componentUniqueId: ProcessComponentId;
    type: ProcessDefinitionIssueType;
    additionalInformation: RawAdditionalInformationIssue;
    level?: LogLevel;
}

export interface ProcessDefinitionIssue {
    description: string;
    componentUniqueId: ProcessComponentId;
    type: ProcessDefinitionIssueType;
    additionalInformation: AdditionalInformationIssue;
    level?: LogLevel;
}

export interface ProcessDefinition {
    components: ProcessComponent[];
    connections: ProcessConnection[];
}

export enum ScheduleIssueType {
    EndBeforeStart = 'EndBeforeStart',
    InvalidRecurrence = 'InvalidRecurrence',
    NegativeNumberOfExecutions = 'NegativeNumberOfExecutions',
    NegativeOrZeroInterval = 'NegativeOrZeroInterval',
    InvalidWeekdays = 'InvalidWeekdays',
    InvalidMonthDay = 'InvalidMonthDay',
}

export interface ScheduleIssue {
    type: ScheduleIssueType;
    description: string;
}

export type ProcessComponentVisiblePins = Partial<Record<ComponentPinType, ComponentPinKey[] | undefined>>;

export type ProcessComponentsVisiblePins = Record<ProcessConnectionId, ProcessComponentVisiblePins | undefined>;

export enum SampleDataType {
    SampleTable = 'sampleTable',
    SampleFile = 'sampleFile',
}

export type SampleDataUsage = 'Unspecified' | 'JSON' | 'CSV'

export interface SampleDataTableColumn {
    name: string;
    type: VisualizationDataType;
    multiValued: boolean;
    order: string;
}

export type SampleDataTableValue = any[];

export interface SampleDataTableDefinition {
    columns: SampleDataTableColumn[];
    values: SampleDataTableValue[];
}

export interface SampleDataTableInfo {
    name: string;
    usage: SampleDataUsage;
    table: SampleDataTableDefinition;
}

export interface SampleDataTable extends SampleDataTableInfo {
    id: string;
}

export interface SampleDataInfo {
    id: string;
    name: string;
}

export interface ProcessSampleData {
    sampleFiles: SampleDataInfo[];
    sampleTables: SampleDataInfo[];
}

export interface EditorContent {
    componentsPosition?: ProcessComponentPosition[];
    componentsVisiblePins?: ProcessComponentsVisiblePins;
}

export interface ProcessBody {
    definition: ProcessDefinition;

    editorContent?: EditorContent;

    definitionIssues?: ProcessDefinitionIssue[];
}

export interface Process extends Omit<ProcessCasePiece, 'status' | 'priority' | 'schedule' | 'automaticChangeDetection' | 'processKey'>, ProcessBody {
    componentsCompleteness?: ProcessComponentCompleteness[];
    sampleData?: ProcessSampleData;
}

// Clipboard format of process
export interface ClipboardProcess extends Omit<Partial<Process>, 'definition'> {
    definition: {
        connections: ProcessConnection[];
        components: ProcessComponent[];
    };
}

export interface ProcessComponentsPriorities {
    components?: {
        uniqueId: ProcessComponentId;
        priority: ProcessPriority;
    }[];
    connections?: {
        from: ProcessConnectionPin;
        to: ProcessConnectionPin;
        priority: ProcessPriority;
    }[];
}

export interface ProcessPriorities {
    processPriority: ProcessPriority;
}

export interface PriorityChangesRequest extends Omit<ProcessPriorities & ProcessComponentsPriorities, 'processPriority'> {
    processPriority?: ProcessPriority;
}

export interface ProcessComponentTestResultItem {
    query: string;
    error?: string;
    scriptQueryOffsets: {
        startIndex: number;
        endIndex: number;
    };
    itemsCount?: number;
    canHaveMoreItems?: boolean | null;
}

export interface ProcessComponentTestResult {
    queryTestResult: ProcessComponentTestResultItem[];
}
