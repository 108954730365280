import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Debug from 'debug';

import { BasicState, StateId } from './basic-state';
import { StateRecord, StatesRegistryContext } from './states-registry';

const debug = Debug('urls:rt-states:UseBasicState');

export function useBasicState<T extends BasicState>(factory: (url: string) => T, key: string | undefined): T | undefined {
    const [stateId, setStateId] = useState<StateId>();
    const unmoutedRef = useRef<boolean>(false);

    const registry = useContext(StatesRegistryContext);

    const stateRecord = useMemo<StateRecord<T> | undefined>(() => {
        if (key === undefined) {
            return undefined;
        }

        if (!registry) {
            return undefined;
        }

        const [stateRecord] = registry.get(key, factory);

        return stateRecord;
    }, [key, registry]);

    useEffect(() => {
        return () => {
            unmoutedRef.current = true;
        };
    }, []);

    useEffect(() => {
        debug('useBasicState:Event', 'Install', 'key=', key, 'stateRecord=', stateRecord);
        if (!stateRecord) {
            return;
        }

        const fct = (key: string, value: any) => {
            debug('useBasicState:Event', 'CATCH CHANGED for', key, 'value=', value, 'state=', stateRecord.state.stateId);
            if (unmoutedRef.current) {
                return;
            }
            setStateId((s) => {
                return stateRecord.state.stateId;
            });
        };

        stateRecord.state.on('Change', fct);

        return () => {
            debug('useBasicState:Event', 'Uninstall', 'key=', key, 'stateRecord=', stateRecord);

            stateRecord.state.off('Change', fct);

            stateRecord.unregister().catch((error) => {
                console.error(error);
            });
        };
    }, [stateRecord]);

    return stateRecord?.state;
}
