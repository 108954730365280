import Debug from 'debug';

import type { WebSocketChannel } from '../../../components/ws/websocket-connector';
import { RtStateMessage } from '../rt-basic-state';
import { RtMessageEventList } from '../events';
import { FolderBasicState } from './folder-basic-state';
import { FOLDERS_EVENT_NAMES } from './events';
import { RtApi, RtApiSubType } from '../rt-api';

const debug = Debug('argonode:utils:rt-states:FoldersState');

export type FoldersStateMessage = RtStateMessage;

export class FoldersState extends FolderBasicState<FoldersStateMessage> {
    #acceptedEvents: RtMessageEventList;
    #deleted = false;

    constructor(api: RtApi, url: string, acceptedEvents: RtMessageEventList = FOLDERS_EVENT_NAMES) {
        super(api, url);

        if (api.subType !== RtApiSubType.Folders) {
            throw new Error(`Invalid subType: ${api.subType}`);
        }

        this.#acceptedEvents = acceptedEvents;
    }

    get acceptedEvents(): RtMessageEventList {
        return this.#acceptedEvents;
    }

    get deleted(): boolean {
        return this.#deleted;
    }

    protected processMessage = async (channel: WebSocketChannel<FoldersStateMessage>, type: string, message: FoldersStateMessage): Promise<boolean | undefined> => {
        if (!this.acceptedEvents[message.type]) {
            // Ignore message
            debug('processMessage', 'messageType=', message.type, 'IGNORED', 'url=', this.url);

            return true;
        }

        if (message.type === 'CaseDeleted') {
            this.#deleted = true;
            debug('processMessage', 'DELETED url=', this.url);
        }

        debug('processMessage', 'messageType=', message.type, 'ACCEPTED', 'url=', this.url);

        const ret = this.handleCasesMessage(message);

        return ret;
    };

    protected handleCasesMessage(message: FoldersStateMessage) {
        return false;
    }
}
