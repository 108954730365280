import { defineMessages } from 'react-intl';

import { registerToolItem, Tool } from '../../components/basic';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../envrionment-contexts/context-names';
import { SettingsEnvironmentContext } from '../envrionment-contexts/settings-environment-context';

export const VISUAL_IDENTITY_PATH = '/settings/visual-identity';

export const messages = defineMessages({
    visualIdentity: {
        id: 'settings.visual-identity.menu.title',
        defaultMessage: 'Visual identity',
    },
});

export function registerVisualIdentityToolItem(path: string, order: number): void {
    registerToolItem(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path,
        order,
        label: messages.visualIdentity,
        icon: 'icon-travel_explore',
        onClick: (tool: Tool<SettingsEnvironmentContext>, environmentContext: SettingsEnvironmentContext) => {
            environmentContext.navigate(VISUAL_IDENTITY_PATH);
        },
    });
}

export function setupVisualIdentity() {
    registerVisualIdentityToolItem('global/visual-identity', 200);
}
