import { RtMessageEventList } from 'src/utils/rt-states/events';
import { ENTITY_ALL_EVENTS, ENTITY_EVENTS } from '../../utils/rt-states/folders/events';

export const EXPLORATION_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
    'VertexStyleUpdated': true,
    'EdgeStyleUpdated': true,
    'HeatmapStyleUpdated': true,
    'ExplorationUpdated': true,
};

export const EXPLORATION_CONFIGURATION_EVENTS: RtMessageEventList = {
    'ExplorationConfigurationUpdated': true,
};

export const UNIVERSE_EDGE_EVENTS: RtMessageEventList = {
    'LinkUpdated': true,
    'LinkDeleted': true,
};

export const UNIVERSE_VERTEX_EVENTS: RtMessageEventList = {
    'ObjectCreated': true,
    'ObjectUpdated': true,
    'ObjectDeleted': true,
    'LinkCreated': true,
    ...UNIVERSE_EDGE_EVENTS,
};

export const UNIVERSE_CONTENT_EVENTS: RtMessageEventList = {
    ...UNIVERSE_VERTEX_EVENTS,
    'UniverseUpdated': true,
};

export const UNIVERSE_INFO_EVENTS: RtMessageEventList = {
    // Currently not existing. Maybe will be implemented later by API ?.
};

export const UNIVERSE_ALL_EVENTS: RtMessageEventList = {
    ...UNIVERSE_CONTENT_EVENTS,
    ...UNIVERSE_INFO_EVENTS,
};

export const RESOURCE_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
};

export const BRIEF_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
    'BriefUpdated': true,
};

export const DASHBOARD_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
    'DashboardUpdated': true,
};

export const CHART_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
    'PivotTableUpdated': true,
};

export const DATASOURCE_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
    'UniverseProjectionUpdated': true,
};

export const EXPLORATION_ENTITY_ALL_EVENTS = {
    ...ENTITY_ALL_EVENTS,

    ...BRIEF_EVENTS,
    ...CHART_EVENTS,
    ...DASHBOARD_EVENTS,
    ...RESOURCE_EVENTS,
    ...DATASOURCE_EVENTS,
    ...EXPLORATION_EVENTS,
    ...EXPLORATION_CONFIGURATION_EVENTS,
};
