import { defineMessages } from 'react-intl';

import {
    ArgModal,
    ProgressMonitor,
    useCallbackAsync,
    useArgNotifications,
    SubProgressMonitor,
} from 'src/components/basic';
import { NotifyBox } from 'src/settings/common-modals/notify-box/notify-box';
import { WARNING_ORANGE } from 'src/settings/models/colors';
import ontologiesConnector from 'src/settings/connectors/ontologies-connector';
import { Ontology } from 'src/settings/models/dtoApi';

export const messages = defineMessages({
    title: {
        id: 'settings.reset-syncs-modal.title',
        defaultMessage: 'Reset the synchronization',
    },
    description: {
        id: 'settings.reset-syncs-modal.description',
        defaultMessage: 'This action will trigger a complete rereading of the knowledge base all the selected universes.',
    },
    cancel: {
        id: 'settings.reset-syncs-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.reset-syncs-modal.button.reset',
        defaultMessage: 'Reset',
    },
    deleteSyncsErrorMsg: {
        id: 'settings.reset-syncs-modal.error-message',
        defaultMessage: 'Something went wrong while resetting the synchronization',
    },
    syncsResetSuccess: {
        id: 'settings.reset-syncs-modal.UniverseResetSuccess',
        defaultMessage: 'The synchronization has been successfully reset',
    },
    syncsResetPartialSuccess: {
        id: 'settings.reset-syncs-modal.UniverseResetPartialSuccess',
        defaultMessage: '{count, plural, =1 {The synchronization was partially reset with 1 failed operation} other {The synchronization was partially reset with # failed operations}}',
    },
});

interface ResetSyncModalProps {
    onClose: () => void;
    listOntologies: Ontology[];
}

export function ResetSyncsModal(props: ResetSyncModalProps) {
    const {
        onClose,
        listOntologies,
    } = props;

    const notifications = useArgNotifications();

    const [handleSubmit, submitting] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const results = await Promise.allSettled(
                listOntologies.map((ontology) => {
                    return ontologiesConnector.resetSync(
                        ontology.universeIds[0],
                        new SubProgressMonitor(progressMonitor, 1),
                    );
                }),
            );

            const failures = results.filter(
                (result) => result.status === 'rejected',
            );

            if (failures.length > 0) {
                console.error('Some resets failed:', failures);
                notifications.snackWarning({
                    message: messages.syncsResetPartialSuccess,
                }, { count: failures.length });
            } else {
                notifications.snackSuccess({ message: messages.syncsResetSuccess });
            }

            onClose();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.deleteSyncsErrorMsg }, error as Error);
            throw error;
        }
    }, [listOntologies, notifications, onClose]);

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={onClose}
            okText={messages.delete}
            cancelText={messages.cancel}
            onOk={handleSubmit}
            onCancel={onClose}
            loading={submitting?.isRunning}
        >
            <NotifyBox
                icon='icon-exclamation-point'
                text={messages.description}
                subtext={listOntologies.map(ontology => ontology.name).join(', ')}
                color={WARNING_ORANGE}
            />
        </ArgModal>
    );
}
