import Debug from 'debug';

import type { User, UserPermissionId, UserPermissions } from '../../model/user';
import { ProgressMonitor } from '../../components/basic';
import { BaseConnector } from './base-connector';
import { mapDate } from './mappers';
import { mapUserProfileField } from 'src/settings/connectors/mappers';
import { UserProfileField, UserProfileFieldId } from '../../model/user-metadata';
import { getAdministrationApi } from './api-url';
import { ResponseError } from '../../components/basic/utils/response-error';
import { AddEditUserProfileField } from '../../settings/models/dtoApi';
import { ArgonosModule } from '../../components/application/modules';
import { APIConnectorRequestInit } from '../connector';

const debug = Debug('common:utils:Connector');

const FORCE_500_SERVER_ERROR = false;

export class UsersConnector extends BaseConnector {
    private static instance: UsersConnector;

    static getInstance(): UsersConnector {
        if (!UsersConnector.instance) {
            UsersConnector.instance = new UsersConnector('utils.users', getAdministrationApi());
        }

        return UsersConnector.instance;
    }

    async myUserDetails(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
        noRedirectIfUnauthorized = false,
    ): Promise<User> {
        if (FORCE_500_SERVER_ERROR) {
            const response = {
                status: 500,
            } as Response;

            const error = new ResponseError(
                'FORCE-500',
                'FORCE-500',
                undefined,
                response,
            );

            throw error;
        }

        const options: APIConnectorRequestInit = {
            api: getAdministrationApi()!,
            method: 'GET',
            verifyJSONResponse: true,
            noRedirectIfUnauthorized,
        };

        const result: User = await this.request('/users/me', options, progressMonitor);

        debug('myUserDetails', 'result=', result);

        if (!result) {
            throw new Error('Invalid /users/me WS result');
        }

        const ret = mapMeToUser(result);

        return ret;
    }

    async getMyUserPermissions(argonosModule: ArgonosModule, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<string[]> {
        const api = argonosModule.apiURL;
        const result = await this.request('/users/me/permissions', {
            verifyJSONResponse: true,
            api,
        }, progressMonitor);

        debug('myUserPermissions', 'result=', result);

        return result;
    }

    async getUserProfileFields(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<UserProfileField[]> {
        const url = '/user-profiles/fields';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        const ret = (result?.fields || []).map((raw: any) => {
            const ret = mapUserProfileField(raw);

            return ret;
        });

        return ret;
    }

    async createUserProfileField(newProperty: AddEditUserProfileField, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<any> {
        const url = '/user-profiles/fields';
        const options = {
            method: 'POST',
            json: newProperty,
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        return result;
    }

    async editUserProfileField(propertyId: UserProfileFieldId, updatedProperty: AddEditUserProfileField, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<any> {
        const url = `/user-profiles/fields/${encodeURIComponent(propertyId)}`;
        const options = {
            method: 'PUT',
            json: updatedProperty,
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        return result;
    }

    async deleteUserProfileField(propertyId: UserProfileFieldId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<any> {
        const url = `/user-profiles/fields/${encodeURIComponent(propertyId)}`;
        const options = {
            method: 'DELETE',
        };

        const result = await this.request(url, options, progressMonitor);

        return result;
    }
}

export function mapUser(userInfo: any): User {
    const user: User = {
        ...userInfo,
        displayName: userInfo.displayName || userInfo.fullName || userInfo.userName,
        createdDate: mapDate(userInfo.createdDate),
        lastUpdatedDate: mapDate(userInfo.lastUpdatedDate),
    };

    return user;
}

export function mapMeToUser(basicUser: User): User {
    const user = mapUser(basicUser);

    return { ...user };
}

export function mapUserPermission(permissions: UserPermissionId[] | null): UserPermissions {
    const ret: Record<string, true> = {};

    permissions?.forEach((permission) => {
        ret[permission] = true;
    });

    return ret;
}
