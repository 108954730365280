import { defineMessages } from 'react-intl';

import { ProgressMonitor, useArgNotifications, useCallbackAsync, useClassNames } from 'src/components/basic';
import { Role, RolesScope } from 'src/settings/models/dtoApi';
import { ConfirmModal } from 'src/components/common/modal2/confirm-modal/confirm-modal';
import { SettingsConnector } from '../../connectors/settings-connector';

import './delete-role-modal.less';

export const messages = defineMessages({
    title: {
        id: 'settings.delete-role-modal.title',
        defaultMessage: 'Delete a role',
    },
    description: {
        id: 'settings.delete-role-modal.description',
        defaultMessage: 'Are you sure you want to delete "{roleName}" ?',
    },
    subtext: {
        id: 'settings.delete-role-modal.subtext',
        defaultMessage: 'This role can be used by users or groups. It will be permanently removed from user accounts and groups to which it is associated.',
    },
    cancel: {
        id: 'settings.delete-role-modal.button.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'settings.delete-role-modal.button.delete',
        defaultMessage: 'Delete',
    },
    deleteUserErrorMsg: {
        id: 'settings.delete-role-modal.error-message',
        defaultMessage: 'Something went wrong while deleting the role',
    },
});

interface DeleteRoleModalProps {
    closeModal: () => void;
    handleOnRoleDeleted: (progressMonitor: ProgressMonitor) => void;
    role: Role;
    rolesScope: RolesScope;
}

export function DeleteUserModal(props: DeleteRoleModalProps) {
    const { closeModal, handleOnRoleDeleted, role, rolesScope } = props;

    const classNames = useClassNames('settings-delete-role-modal');
    const notifications = useArgNotifications();

    const [handleSubmit] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            await SettingsConnector.getInstance().deleteRole(role.id, rolesScope, progressMonitor);
            handleOnRoleDeleted(progressMonitor);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.deleteUserErrorMsg }, error as Error);
        }
    }, [handleOnRoleDeleted, notifications, role.id, rolesScope]);

    return (
        <ConfirmModal
            className={classNames('&')}
            title={messages.title}
            messageValues={{ roleName: role.name }}
            onClose={closeModal}
            onConfirm={handleSubmit}
            alertMessage={messages.description}
            alertDescription={messages.subtext}
            confirmText={messages.delete}
            type='delete'
        />
    );
}
