import { DependencyList, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { useDebugEffect } from './use-debug-effect';
import { ProgressMonitor, ProgressMonitorOptions } from '../progress-monitors/progress-monitor';
import { useProgressMonitor } from '../progress-monitors/use-progress-monitor';

export function useEffectAsync(
    asyncEffect: (progressMonitor: ProgressMonitor) => Promise<void>,
    deps: DependencyList,
    progressMonitorName?: string | MessageDescriptor,
    progressMonitorTaskCount = 1,
    progressMonitorOptions?: ProgressMonitorOptions,
    debugName?: string,
): [ProgressMonitor | undefined, Error | undefined] {
    const [progressMonitor, createProgressMonitor] = useProgressMonitor();
    const [error, setError] = useState<Error>();

    const effectCb = () => {
        setError(undefined);
        const progressMonitor = createProgressMonitor(progressMonitorName, progressMonitorTaskCount, progressMonitorOptions);

        asyncEffect(progressMonitor).then((result) => {
            // console.log(result);

        }, (error) => {
            if (progressMonitor.isCancelled) {
                return;
            }
            setError(error);
        }).finally(() => {
            progressMonitor.done();
        });
    };

    useDebugEffect(effectCb, deps, debugName);

    return [progressMonitor, error];
}
