import Debug from 'debug';

import { RtStateMessage } from '../rt-basic-state';
import { EntityId } from '../../../model/argonos-piece';
import { RtMessageEventList } from '../events';
import { WebSocketChannel } from '../../../components/ws/websocket-connector';
import { FolderBasicState, WatchCommands } from './folder-basic-state';
import { RtApi, RtApiSubType } from '../rt-api';
import { ENTITY_EVENTS } from './events';

const debug = Debug('argonode:utils:states:EntityState');

const WATCH_COMMANDS: WatchCommands = {
    watch: 'Watch',
    unwatch: 'Unwatch',
    linkName: 'watch',
};

export type EntityStateMessage = RtStateMessage;

export function computeEntityStateURL(casePieceId: EntityId) {
    return `/entities/${casePieceId}`;
}

export class EntityState extends FolderBasicState<EntityStateMessage> {
    #entityId: EntityId;
    #acceptedEvents: RtMessageEventList;
    #deleted = false;

    constructor(api: RtApi, url: string, entityId: EntityId, acceptedEvents: RtMessageEventList = ENTITY_EVENTS) {
        super(api, url);

        if (api.subType !== RtApiSubType.Entities) {
            throw new Error(`Invalid subType: ${api.subType}`);
        }

        this.#entityId = entityId;
        this.#acceptedEvents = acceptedEvents;
    }

    protected get keepChannelOpened() {
        return true;
    }

    get entityId(): EntityId {
        return this.#entityId;
    }

    get deleted(): boolean {
        return this.#deleted;
    }

    protected get watchKey(): string {
        return this.#entityId;
    }

    protected get watchCommands(): WatchCommands {
        return WATCH_COMMANDS;
    }

    protected processMessage = async (channel: WebSocketChannel<EntityStateMessage>, type: string, message: EntityStateMessage): Promise<boolean | undefined> => {
        const { entityId } = message.messageContent;

        if (entityId !== this.#entityId) {
            // Ignore message
            return true;
        }

        if (!this.#acceptedEvents[message.type]) {
            // Ignore message
            return true;
        }

        if (message.type === 'ArgonosPieceDeleted') {
            this.#deleted = true;
            debug('processMessage', 'entity DELETED url=', this.url);
        }

        const ret = this.processPieceMessage(message);

        return ret;
    };

    protected processPieceMessage(message: EntityStateMessage): boolean {
        return false;
    }
}
