import { merge } from 'lodash';
import { Link, Node } from 'regraph';

import { FullOntology, FullOntologyObjectType } from 'src/settings/universes/ontology/types';
import { DEFAULT_VERTEX_STYLE } from 'src/exploration/constants/default-vertex-style';
import { verticesSizeRange } from 'src/exploration/constants/nodes-size-range';
import { computeGraphItemsSize, fillMediumNodeIcon } from 'src/exploration/features/exploration/render-engine';
import { UniverseType, UniverseVertexType } from 'src/exploration/model/universe';
import { VertexStyle } from 'src/exploration/model/vertex';
import { ThumbnailSize } from '../model/thumbnail-size';
import { BlobImagesProvider } from '../../utils/blob-images-provider';
import { ImageAlignmentProvider } from '../../utils/image-alignment-provider';
import { edgesSizeRange } from '../constants/edges-size-range';

export function buildReGraphNode(
    vertexType: UniverseVertexType | FullOntologyObjectType,
    universe: UniverseType | FullOntology,
    labelText: string,
    isSelected: boolean,
    vertexContentCounts: number[] | undefined,
    blobImagesProvider: BlobImagesProvider,
    imageAlignmentProvider: ImageAlignmentProvider,
    thumbnailSize?: ThumbnailSize,
    useStrokeColor?: boolean,
): Node {
    const _style: VertexStyle = merge({}, DEFAULT_VERTEX_STYLE, vertexType.style);
    const style: VertexStyle = {
        ..._style,
        fillColor: 'white',
        strokeColor: 'none',
    };

    let size = style.size || 2.5;
    if (vertexContentCounts) {
        const idx = (universe as UniverseType).schema.vertices.indexOf(vertexType as UniverseVertexType);
        if (idx >= 0) {
            const count = vertexContentCounts[idx];
            size = computeGraphItemsSize(verticesSizeRange, vertexContentCounts)[count];
        }
    }

    const {
        iconColor,
        fillColor,
        strokeColor,
    } = style;

    const node: Node = {
        cutout: true,
        color: isSelected ? iconColor : fillColor,
    };

    fillMediumNodeIcon(
        node,
        universe,
        isSelected,
        style,
        blobImagesProvider,
        imageAlignmentProvider,
        thumbnailSize,
    );

    return {
        ...node,
        glyphs: [
            {
                label: {
                    text: labelText,
                    fontFamily: 'Roboto',
                    color: isSelected ? fillColor : iconColor,
                },
                color: isSelected ? iconColor : fillColor,
                size: 0.9,
                angle: 180,
                radius: 41,
            },
        ],
        border: (useStrokeColor && strokeColor && strokeColor !== 'none') ? {
            color: strokeColor,
            width: 3,
        } : {
            width: 3,
            color: isSelected ? fillColor : iconColor,
        },
        size,
    };
}

export const buildReGraphLink = (
    fromLabel: string,
    toLabel: string,
    linkName: string,
    verticesCount?: number,
    edgeContentCounts?: number[],
    isEdgeSelected?: boolean,
    edgeCount?: number,
): Link => {
    let width = 2;
    if (edgeContentCounts && edgeCount) {
        width = computeGraphItemsSize(edgesSizeRange, edgeContentCounts)[edgeCount];
    }

    return {
        id1: fromLabel,
        id2: toLabel,
        label: {
            text: linkName,
            fontSize: verticesCount && verticesCount > 2 ? 20 : 15,
            backgroundColor: 'white',
            bold: isEdgeSelected,
        },
        end2: {
            arrow: true,
        },
        width: width,
        color: isEdgeSelected ? 'black' : undefined,
    };
};
