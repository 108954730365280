import React, { ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { isString } from 'lodash';
import { defineMessages } from 'react-intl';

import {
    ArgButton,
    ArgImage,
    ArgMessageRenderer,
    ArgRenderedText,
    ArgTooltip2,
    ClassValue,
    DEFAULT_TOOLTIP_BUTTON_DELAY,
    renderText,
    useArgNotifications,
    useClassNames,
    useMemoAsync,
} from 'src/components/basic';
import { useGetMyPermissions } from 'src/contexts/user-permissions-context';
import { ApplicationButton } from './application-button';
import { ArgonosModuleId } from 'src/components/application/modules';
import { useAvailableArgonosModules } from '../../application/argonos-modules-registry';
import { Environment } from '../../../utils/environment';
import { useApplicationBranding } from '../../application/argonos-application-branding';
import { useArgonosModuleBranding } from 'src/components/application/argonos-modules-branding';
import { useCurrentArgonosModule } from 'src/components/application/argonos-current-module';
import { SettingsConnector } from '../../../settings/connectors/settings-connector';

import ChapsIcon from '../../../assets/chapsicon.svg';
import './applications.less';

const messages = defineMessages({
    versionError: {
        id: 'common.top-bar.versionError',
        defaultMessage: 'Can not get application version',
    },
    versionPreview: {
        id: 'common.top-bar.versionPreview',
        defaultMessage: 'Preview {version}',
    },
});

interface ApplicationsProps {
    className?: ClassValue;
    currentModuleId: ArgonosModuleId;
    title?: ArgRenderedText | false;
    backUrl?: string;
    backTooltip?: ArgRenderedText;
}

export function Applications(props: ApplicationsProps) {
    const { className, currentModuleId, title, backUrl, backTooltip } = props;

    const [popoverVisibility, setPopoverVisibility] = useState(false);

    const classNames = useClassNames('common-applications');
    const module = useCurrentArgonosModule();
    const [moduleBranding] = useArgonosModuleBranding(module.id);
    const applicationBranding = useApplicationBranding();

    const notifications = useArgNotifications();

    const { permissions: userPermissions } = useGetMyPermissions();

    const [version] = useMemoAsync(async(progressMonitor) => {
        try {
            const ret = SettingsConnector.getInstance().applicationVersion(module.apiURL, progressMonitor);

            return ret;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.versionError }, error as Error);
        }
    }, [notifications, module.apiURL]);

    const availableArgonosModules = useAvailableArgonosModules(userPermissions);

    const handlePopover = useCallback(() => {
        return (
            <div className={classNames('&-popup')}>
                {availableArgonosModules.map((argonosModule) => {
                    return (
                        <ApplicationButton
                            key={argonosModule.id}
                            selected={argonosModule.id === currentModuleId}
                            argonosModule={argonosModule}
                        />
                    );
                })}
            </div>
        );
    }, [classNames, availableArgonosModules, currentModuleId]);

    const _title = (title !== false)
        ? renderText(title
            || moduleBranding?.brandingName
            || module.name
            || Environment.appName,
        )
        : undefined;

    const brandingLogoURL = moduleBranding?.brandingLogoURL
        || applicationBranding.brandingLogoURL;
    let logo: ReactNode = <Link to={backUrl || '/'} className={classNames('&-logo')} replace={true}>
        {isString(brandingLogoURL) ? <ArgImage
            className={classNames('&-logo-image')}
            src={brandingLogoURL}
        /> : <img
            className={classNames('&-logo-image')}
            src={ChapsIcon}
        />}
    </Link>;

    if (backTooltip) {
        logo = <ArgTooltip2
            placement='bottomLeft'
            mouseEnterDelay={DEFAULT_TOOLTIP_BUTTON_DELAY}
            title={backTooltip}>
            {logo}
        </ArgTooltip2>;
    }

    return (
        <div className={classNames('&', className)}>
            <ArgButton
                className={classNames('&-dots')}
                icon='icon-menu-9dots'
                size='large'
                type='ghost'
                popover={handlePopover}
                popoverVisible={popoverVisibility}
                onPopoverVisibleChange={setPopoverVisibility}
                popoverPlacement='bottomLeft'
            />
            {logo}
            {_title && (
                <div className={classNames('&-branding')}>
                    {renderText(_title)}
                </div>
            )}
            {version && version.version?.includes('preview') && (
                <div className={classNames('&-preview')}>
                    <ArgMessageRenderer
                        message={messages.versionPreview}
                        messageValues={{ version: version.version }}
                    />
                </div>
            )}
        </div>
    );
}
