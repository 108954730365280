import React, { lazy, Suspense } from 'react';
import { defineMessages } from 'react-intl';

import { registerToolItem, Tool } from '../../../components/basic';
import {
    KB_TABS_CONTEXT_NAME,
    KBEnvironmentContext,
} from '../../../knowledge-base/environment-contexts/kb-environment-context';
import { PreparationPermissions } from '../../permissions/permissions';
import { LoadingSuspense } from '../../../components/containers/loading-suspense';
import './actions/view-reference-table';
import './actions/delete-reference-table';

const LazyReferenceTables = lazy(() => import('./reference-tables'));

const FORCE_HIDDEN = false;

const messages = defineMessages({
    title: {
        id: 'preparation.reference-tables.Title',
        defaultMessage: 'Reference tables',
    },
    loadingReferenceTables: {
        id: 'preparation.reference-tables.LoadingReferenceTables',
        defaultMessage: 'Loading Reference tables',
    },
});

registerToolItem<KBEnvironmentContext>(KB_TABS_CONTEXT_NAME, {
    path: 'reference-tables',
    order: 200,
    type: 'panel',
    label: messages.title,
    visible: (environmentContext: KBEnvironmentContext) => {
        if (FORCE_HIDDEN) {
            return false;
        }

        const result = environmentContext.hasAllPermissions<PreparationPermissions>(
            'preparation.reference.table.read',
        );

        return result;
    },
    panelRender: (tool: Tool<KBEnvironmentContext>, environmentContext: KBEnvironmentContext) => {
        return (
            <Suspense fallback={<LoadingSuspense message={messages.loadingReferenceTables} />}>
                <LazyReferenceTables environmentContext={environmentContext} />
            </Suspense>
        );
    },
});

