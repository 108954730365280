import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import * as UUID from 'uuid';
import Debug from 'debug';
import 'ace-builds/src-noconflict/mode-javascript';

import {
    ArgButton,
    ArgCombo,
    ArgFormLabel,
    ArgInputExpression,
    ArgInputExpressionCompleter,
    ArgInputExpressionCompletion,
    ArgInputNumber,
    ArgInputText,
    ArgInputTextArea,
    ClassValue,
    SelectionProvider,
    useClassNames,
    useMemoDeepEquals,
    useSelection,
} from 'src/components/basic';
import { FormButton, FormComposite, FormCompositeItem, FormElement, FormElementId } from '../../../../components/common/forms/model';
import { EmptyPane } from '../../../../components/common/panes/empty-pane';
import { FormActionsEngine } from '../actions/form-actions-engine';
import { useFormRepository } from '../use-form-repository';
import { createFormChangeProperty } from '../actions/change-property';
import { UniversePropertyType } from 'src/exploration/model/universe';
import { IconPicker } from 'src/components/common/graph/customisation/common/icon-picker';
import { ButtonWithBadge } from 'src/components/common/graph/customisation/common/button-with-badge';
import { createFormRemoveElements } from '../actions/remove-elements';
import { InformationText } from 'src/components/basic/arg-input/arg-input-expression-information-panel';
import { OntologyObjectType } from 'src/settings/universes/ontology/types';
import { walk } from 'src/components/common/forms/utils';

import './form-properties-panel.less';

const debug = Debug('exploration:forms:form-properties-panel');

const messages = defineMessages({
    title: {
        id: 'explorations.forms.properties-panel.Title',
        defaultMessage: 'Properties',
    },
    displayName: {
        id: 'explorations.forms.properties-panel.DisplayName',
        defaultMessage: 'Name',
    },
    description: {
        id: 'explorations.forms.properties-panel.Description',
        defaultMessage: 'Description',
    },
    iframeUrl: {
        id: 'explorations.forms.properties-panel.IFrameUrl',
        defaultMessage: 'IFrame URL script',
    },
    imageSrc: {
        id: 'explorations.forms.properties-panel.ImageSrc',
        defaultMessage: 'Image source',
    },
    delete: {
        id: 'explorations.forms.properties-panel.Delete',
        defaultMessage: 'Remove from selection',
    },
    tooltip: {
        id: 'explorations.forms.properties-panel.Tooltip',
        defaultMessage: 'Tooltip',
    },
    script: {
        id: 'explorations.forms.properties-panel.Script',
        defaultMessage: 'Code in JavaScript',
    },
    concatenatedProperties: {
        id: 'explorations.forms.properties-panel.ConcatenatedProperties',
        defaultMessage: 'Concatenated properties',
    },
    addComposite: {
        id: 'explorations.forms.properties-panel.AddComposite',
        defaultMessage: 'Add property',
    },
    width: {
        id: 'explorations.forms.properties-panel.Width',
        defaultMessage: 'Width',
    },
    height: {
        id: 'explorations.forms.properties-panel.Height',
        defaultMessage: 'Height',
    },
    horizontalAlignment: {
        id: 'explorations.forms.properties-panel.HorizontalAlignment',
        defaultMessage: 'Horizontal alignment',
    },
    left: {
        id: 'explorations.forms.properties-panel.Left',
        defaultMessage: 'Left',
    },
    center: {
        id: 'explorations.forms.properties-panel.Center',
        defaultMessage: 'Center',
    },
    right: {
        id: 'explorations.forms.properties-panel.Right',
        defaultMessage: 'Right',
    },
    buttonType: {
        id: 'explorations.forms.properties-panel.ButtonType',
        defaultMessage: 'Button type',
    },
    primary: {
        id: 'explorations.forms.properties-panel.Primary',
        defaultMessage: 'Primary',
    },
    secondary: {
        id: 'explorations.forms.properties-panel.Secondary',
        defaultMessage: 'Secondary',
    },
    danger: {
        id: 'explorations.forms.properties-panel.Danger',
        defaultMessage: 'Danger',
    },
    link: {
        id: 'explorations.forms.properties-panel.Link',
        defaultMessage: 'Link',
    },
    icon: {
        id: 'explorations.forms.properties-panel.Icon',
        defaultMessage: 'Icon',
    },
    size: {
        id: 'explorations.forms.properties-panel.Size',
        defaultMessage: 'Size',
    },
    small: {
        id: 'explorations.forms.properties-panel.Small',
        defaultMessage: 'Small',
    },
    medium: {
        id: 'explorations.forms.properties-panel.Medium',
        defaultMessage: 'Medium',
    },
    large: {
        id: 'explorations.forms.properties-panel.Large',
        defaultMessage: 'Large',
    },
    compositeCountWarning: {
        id: 'explorations.forms.properties-panel.CompositeCountWarning',
        defaultMessage: 'A minimum of two properties and one separator is expected',
    },
    buttonInformation: {
        id: 'explorations.forms.properties-panel.ButtonInformation',
        defaultMessage: 'This interface allows you to write JavaScript code that will be executed on a click on the button.\n\nThe variable \"form\" can be used to access the values of the form.\n\nExample of valid code to open a new tab on your browser:\n\n{code}',
    },
    iframeInformation: {
        id: 'explorations.forms.properties-panel.IFrameInformation',
        defaultMessage: 'This interface allows you to value the URL of the iframe, you can either write the URL directly or write JavaScript code that returns the URL.\n\nThe variable "form" can be used to access the values of the form.\n\nExample of valid code to generate the URL:\n\n{code}',
    },
});

interface PropertyItem<T = string> {
    label: MessageDescriptor;
    value: T;
}

type HandleFormElementValueChange = (propertyName: string, value: any) => void;

interface Property<T = string> {
    fieldName: string;
    displayName: MessageDescriptor;
    type: 'string' | 'multiline' | 'number' | 'boolean' | 'script' | 'custom' | 'combo';
    hiddenFieldName?: string;
    render?: (formElement: FormElement, onChange: HandleFormElementValueChange, ontologyVertexType: OntologyObjectType) => ReactNode;
    items?: PropertyItem<T>[];
    editorInformation?: InformationText;
}

const NAME_PROPERTY: Property = {
    fieldName: 'name',
    type: 'string',
    displayName: messages.displayName,
};

const DESCRIPTION_PROPERTY: Property = {
    fieldName: 'description',
    type: 'multiline',
    displayName: messages.description,
    hiddenFieldName: 'hideDescription',
};

const WIDTH_PROPERTY: Property = {
    fieldName: 'width',
    type: 'string',
    displayName: messages.width,
};

const HEIGHT_PROPERTY: Property = {
    fieldName: 'height',
    type: 'string',
    displayName: messages.height,
};

const HORIZONTAL_ALIGNMENT_PROPERTY: Property = {
    fieldName: 'horizontalAlignment',
    type: 'combo',
    displayName: messages.horizontalAlignment,
    items: [
        { label: messages.left, value: 'left' },
        { label: messages.center, value: 'center' },
        { label: messages.right, value: 'right' },
    ],
};

const propertiesByType: Record<string, Property[]> = {
    'property': [NAME_PROPERTY],
    'uuid': [NAME_PROPERTY],
    'section': [
        {
            ...NAME_PROPERTY,
            hiddenFieldName: 'hideName',
        },
        DESCRIPTION_PROPERTY,
    ],
    'button': [
        {
            ...NAME_PROPERTY,
            type: 'custom',
            render: (formElement: FormElement, onChange: HandleFormElementValueChange) => {
                return <FormButtonNameAndIcon
                    formButton={formElement as FormButton}
                    onChange={onChange}
                    displayName={messages.displayName}
                />;
            },
        }, {
            fieldName: 'tooltip',
            type: 'string',
            displayName: messages.tooltip,
        }, {
            fieldName: 'buttonType',
            type: 'combo',
            displayName: messages.buttonType,
            items: [
                { label: messages.primary, value: 'primary' },
                { label: messages.secondary, value: 'secondary' },
                { label: messages.danger, value: 'danger' },
                { label: messages.link, value: 'link' },
            ],
        }, {
            fieldName: 'size',
            type: 'combo',
            displayName: messages.size,
            items: [
                { label: messages.small, value: 'small' },
                { label: messages.medium, value: 'medium' },
                { label: messages.large, value: 'large' },
            ],
        },
        HORIZONTAL_ALIGNMENT_PROPERTY, {
            fieldName: 'code',
            type: 'script',
            displayName: messages.script,
            editorInformation: {
                content: messages.buttonInformation,
                messageValues: {
                    code: 'window.open(\`https://www.XXXX.com?q=${form.username}\`);',
                },
            },
        },
    ],
    'iframe': [
        NAME_PROPERTY, {
            fieldName: 'url',
            type: 'script',
            displayName: messages.iframeUrl,
            editorInformation: {
                content: messages.iframeInformation,
                messageValues: {
                    code: 'return \`https://www.XXXX.com?q=${form.username}\`;',
                },
            },
        },
        WIDTH_PROPERTY,
        HEIGHT_PROPERTY,
    ],
    'image': [
        {
            fieldName: 'src',
            type: 'string',
            displayName: messages.imageSrc,
        },
        WIDTH_PROPERTY,
        HEIGHT_PROPERTY,
        HORIZONTAL_ALIGNMENT_PROPERTY,
    ],
    'composite': [
        NAME_PROPERTY,
        DESCRIPTION_PROPERTY, {
            fieldName: 'concatenatedProperties',
            type: 'custom',
            displayName: messages.concatenatedProperties,
            render: (formElement: FormElement, onChange: HandleFormElementValueChange, ontologyVertexType: OntologyObjectType) => {
                return <CompositeEditor
                    formComposite={formElement as FormComposite}
                    onChange={onChange}
                    displayName={messages.concatenatedProperties}
                    ontologyVertexType={ontologyVertexType}
                />;
            },
        },
    ],
};

interface FormPropertiesPanelProps {
    className?: ClassValue;

    selectionProvider?: SelectionProvider<FormElement>;

    formActionsEngine: FormActionsEngine;
    ontologyVertexType: OntologyObjectType;
}

export function FormPropertiesPanel(props: FormPropertiesPanelProps) {
    const {
        className,
        selectionProvider,
        formActionsEngine,
        ontologyVertexType,
    } = props;

    const classNames = useClassNames('exploration-form-properties-panel');

    const formDocument = useFormRepository(formActionsEngine.repository);

    useSelection(selectionProvider);

    const formElementKey = selectionProvider?.first();

    const formElement = formDocument && walk(formDocument, (element: FormElement) => {
        if (element.id === formElementKey) {
            return element;
        }
    });

    const handleDelete = useCallback(() => {
        const action = createFormRemoveElements([formElement], selectionProvider);

        formActionsEngine.do(action).catch((error) => {
            console.error(error);
        });
    }, [formElement, selectionProvider, formActionsEngine]);

    const handleValueChange = useCallback((propertyName: string, value: any) => {
        debug('propertyName=', propertyName, 'value=', value);

        const action = createFormChangeProperty(formElement, propertyName, value);

        formActionsEngine.do(action).catch((error) => {
            console.error(error);
        });
    }, [formActionsEngine, formElement]);

    if (!formElement || !formDocument) {
        return (
            <div className={classNames('&', className, 'empty')}>
                <EmptyPane className={classNames('&-empty')} />
            </div>
        );
    }


    const properties = propertiesByType[formElement.type] ?? [];

    const body: ReactNode[] = properties.map((property: Property) => {
        if (property.type === 'custom' && property.render) {
            return property.render(formElement, handleValueChange, ontologyVertexType);
        }

        const editor = <PropertyEditor
            key={property.fieldName}
            className={classNames('&-body-property')}
            property={property}
            formElement={formElement}
            ontologyVertexType={ontologyVertexType}
            onChange={(propertyName: string, value: any) => {
                handleValueChange(propertyName, value);
            }}
        />;

        return editor;
    }, []);

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-title')}>
                <div className={classNames('&-title-text')}>
                    <FormattedMessage {...messages.title} />
                </div>
                <ArgButton
                    className={classNames('&-title-delete')}
                    type='ghost'
                    icon='icon-trash'
                    label={messages.delete}
                    onClick={handleDelete}
                    disabled={!formDocument || !formElement}
                />
            </div>

            <div className={classNames('&-body')} key={formElement.id}>
                {body}
            </div>
        </div>
    );
}

interface PropertyEditorProps {
    property: Property;
    formElement: FormElement;
    onChange: (propertyName: string, value: any) => void;
    className?: ClassValue;
    ontologyVertexType: OntologyObjectType;
}

function PropertyEditor(props: PropertyEditorProps) {
    const {
        property,
        formElement,
        onChange,
        className,
        ontologyVertexType,
    } = props;

    const classNames = useClassNames('exploration-form-properties-field');

    const values: any = formElement;

    const scriptCompleters = useMemo<ArgInputExpressionCompleter | undefined>(() => {
        if (property?.type !== 'script') {
            return;
        }

        const ret: ArgInputExpressionCompleter = {
            completerId: 'scriptCompleters',
            identifierRegexps: [/\./],
            getCompletions: async (editor, session, pos, prefix) => {
                const completions: ArgInputExpressionCompletion[] = ontologyVertexType.properties.map((property) => {
                    return {
                        value: `form.${property.name}`,
                        score: 100,
                    };
                });

                return completions.filter((completion) => completion.value.startsWith(prefix));
            },
        };

        return ret;
    }, [property?.type, ontologyVertexType.properties]);

    const value = values[property.fieldName];

    let editor: ReactNode = null;
    switch (property.type) {
        case 'string':
            editor = <ArgInputText
                className={classNames('&-body-editor-input')}
                value={value}
                onChange={(value) => onChange(property.fieldName, value)}
            />;
            break;
        case 'multiline':
            editor = <ArgInputTextArea
                className={classNames('&-body-editor-input')}
                value={value}
                onChange={(value) => onChange(property.fieldName, value)}
            />;
            break;
        case 'script':
            editor = <ArgInputExpression
                className={classNames('&-body-editor-input')}
                value={value}
                onChange={(value) => onChange(property.fieldName, value)}
                language='javascript'
                expandable={true}
                completers={scriptCompleters}
                information={property.editorInformation}
            />;
            break;
        case 'number':
            editor = <ArgInputNumber
                className={classNames('&-body-editor-input')}
                value={values[property.fieldName]}
                onChange={(value) => onChange(property.fieldName, value)}
            />;
            break;
        case 'combo':
            if (property.items) {
                editor = <ArgCombo<PropertyItem>
                    className={classNames('&-body-editor-input')}
                    value={property.items.find((item) => item.value === values[property.fieldName])}
                    onChange={({ value }) => onChange(property.fieldName, value)}
                    items={property.items}
                    getItemKey={(item) => item.value}
                    getItemLabel={(item) => item.label}
                />;
            }
            break;
    }

    if (!editor) {
        return <div>No editor for property</div>;
    }

    return <ArgFormLabel propertyName={property.displayName} className={classNames('&', className)}>
        <div className={classNames('&-body')}>
            <div className={classNames('&-body-editor')}>
                {editor}
            </div>
            {property.hiddenFieldName && <div className={classNames('&-body-hidden')}>
                <ArgButton
                    type='ghost'
                    icon={values[property.hiddenFieldName] ? 'icon-eye' : 'icon-eye-crossed'}
                    onClick={() => onChange(property.hiddenFieldName!, !values[property.hiddenFieldName!])}
                />
            </div>}
        </div>
    </ArgFormLabel>;
}


interface CompositeEditorProps {
    formComposite: FormComposite;
    onChange: HandleFormElementValueChange;
    className?: ClassValue;
    displayName: MessageDescriptor;
    ontologyVertexType: OntologyObjectType;
}

function CompositeEditor(props: CompositeEditorProps) {
    const {
        displayName,
        formComposite,
        onChange,
        className,
        ontologyVertexType,
    } = props;

    const classNames = useClassNames('exploration-form-properties-composite-field');

    const compositeItems = useMemoDeepEquals(() => {
        return formComposite.children.filter((child) => child.type === 'composite-item') as FormCompositeItem[];
    }, [formComposite.children]);

    const handleAddCompositeItem = useCallback(() => {
        const newCompositeItem: FormCompositeItem = {
            id: UUID.v4(),
            type: 'composite-item',
            propertyName: ontologyVertexType.properties[0].name,
            separator: ' ',
        };
        onChange('children', [...formComposite.children, newCompositeItem]);
    }, [formComposite, onChange, ontologyVertexType]);

    const handleChangeCompositeItem = useCallback((id: FormElementId, compositeItem: FormCompositeItem) => {
        const newCompositeItems = formComposite.children.map((child) => {
            if (child.id === id) {
                return compositeItem;
            }

            return child;
        });
        onChange('children', newCompositeItems);
    }, [formComposite, onChange]);

    const handleGetSeparator = useCallback((value: string | null) => {
        const match = value?.match(/^"(.*)"$/);
        const separator = match?.[1];

        return separator;
    }, []);

    const handleShouldPerformChange = useCallback((value: string) => {
        const separator = handleGetSeparator(value);

        return separator !== undefined;
    }, [handleGetSeparator]);

    const linkItem = <div className={classNames('&-body-editor-link-item')}>
        <div className={classNames('&-body-editor-link-item-square')} />
    </div>;

    return <ArgFormLabel propertyName={displayName} className={classNames('&', className)}>
        <div className={classNames('&-body')}>
            {compositeItems.length < 2 && (
                <div className={classNames('&-body-warning')}>
                    <FormattedMessage {...messages.compositeCountWarning} />
                </div>
            )}
            <div className={classNames('&-body-editor')}>
                {compositeItems.map((compositeItem, index) => {
                    const property = ontologyVertexType.properties.find((property) => property.name === compositeItem.propertyName);
                    if (!property) {
                        return null;
                    }

                    const separator = `"${compositeItem.separator ?? ''}"`;

                    return (
                        <div key={compositeItem.id} className={classNames('&-body-editor-item')}>
                            <div className={classNames('&-body-editor-item-inputs')}>
                                {index !== 0 && (
                                    <div className={classNames('&-body-editor-container')}>
                                        {linkItem}
                                        <ArgInputText
                                            className={classNames('&-body-editor-separator-input')}
                                            value={separator}
                                            onChange={(value) => {
                                                const separator = handleGetSeparator(value);
                                                if (separator === undefined && value !== null) {
                                                    return;
                                                }
                                                const newCompositeItem: FormCompositeItem = {
                                                    ...compositeItem,
                                                    separator,
                                                };
                                                handleChangeCompositeItem(compositeItem.id, newCompositeItem);
                                            }}
                                            shouldPerformChange={handleShouldPerformChange}
                                            clearable={false}
                                        />
                                    </div>
                                )}
                                <div className={classNames('&-body-editor-container')}>
                                    {linkItem}
                                    <ArgCombo
                                        className={classNames('&-body-editor-property')}
                                        value={property}
                                        onChange={(value: UniversePropertyType) => {
                                            const newCompositeItem: FormCompositeItem = {
                                                ...compositeItem,
                                                propertyName: value.name,
                                            };
                                            handleChangeCompositeItem(compositeItem.id, newCompositeItem);
                                        }}
                                        items={ontologyVertexType.properties}
                                        getItemKey={(item) => item.name}
                                        getItemLabel={(item) => item.displayName}
                                    />
                                </div>
                            </div>
                            <div className={classNames('&-body-editor-item-delete')}>
                                <ArgButton
                                    type='ghost'
                                    icon='icon-trash'
                                    onClick={() => {
                                        const newCompositeItems = formComposite.children.filter((child) => child.id !== compositeItem.id);
                                        onChange('children', newCompositeItems);
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <ArgButton
                type='link'
                size='medium'
                icon='icon-plus'
                className={classNames('&-add-button')}
                onClick={handleAddCompositeItem}
                label={messages.addComposite}
            />
        </div>
    </ArgFormLabel>;
}

let _globalIconsList: string[];

interface FormButtonIconPickerProps {
    formButton: FormButton;
    onChange: HandleFormElementValueChange;
    className?: ClassValue;
    displayName: MessageDescriptor;
}

function FormButtonNameAndIcon(props: FormButtonIconPickerProps) {
    const {
        displayName,
        formButton,
        onChange,
        className,
    } = props;

    const classNames = useClassNames('exploration-form-properties-name-and-icon-picker');

    const [iconList, setIconList] = useState<string[]>(() => _globalIconsList ?? []);
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        if (iconList.length > 0) {
            return;
        }

        import('../../../../styles/fonts/selection-icon-list.json')
            .then(icons => icons.default)
            .then((defaultIcons: any) => {
                _globalIconsList = Object.keys(defaultIcons);
                setIconList(Object.keys(defaultIcons));
            });
    }, []);

    const handleIconChange = useCallback((icon: string) => {
        onChange('icon', icon);
        setVisible(false);
    }, [onChange]);

    const handleNameChange = useCallback((name: string | null) => {
        onChange('name', name ?? undefined);
    }, [onChange]);

    const handleButtonClick = useCallback(() => {
        setVisible((visible) => !visible);
    }, []);

    return <ArgFormLabel propertyName={displayName} className={classNames('&', className)}>
        <div className={classNames('&-body')}>
            <div className={classNames('&-body-editor')}>
                <ButtonWithBadge
                    className={classNames('&-body-editor-icon-button')}
                    popoverClassName={classNames('&-body-editor-icon-picker')}
                    popover={<IconPicker
                        className={classNames('&-icons')}
                        icons={iconList}
                        selectedIcon={formButton.icon}
                        onIconChange={handleIconChange}
                    />}
                    popoverVisible={visible}
                    popoverPlacement='bottomLeft'
                    onPopoverVisibleChange={handleButtonClick}
                    iconName={formButton.icon}
                    badgeIconName='icon-pencil'
                    borderColor='#000000'
                />
                <ArgInputText
                    className={classNames('&-body-editor-input')}
                    value={formButton.name}
                    onChange={handleNameChange}
                />
            </div>
        </div>
    </ArgFormLabel>;
}
