import { Dispatch, SetStateAction, useState } from 'react';
import { defineMessages } from 'react-intl';

import { ArgInputText, ArgModal, useArgNotifications } from 'src/components/basic';
import { FullOntology, FullOntologyLinkType } from '../../types';
import ontologiesConnector from '../../../../connectors/ontologies-connector';
import { ArgFormLabel } from '../../../../../components/basic';


export const messages = defineMessages({
    title: {
        id: 'settings.rename-edge-modal.title',
        defaultMessage: 'Rename relation',
    },
    fieldName: {
        id: 'settings.rename-edge-modal.field.name',
        defaultMessage: 'Relation name',
    },
    submit: {
        id: 'settings.rename-edge-modal.submitButton',
        defaultMessage: 'Rename',
    },
    renameEdgeError: {
        id: 'settings.rename-edge-modal.renameEdgeError',
        defaultMessage: 'Something went wrong while trying to rename the edge',
    },
    required: {
        id: 'settings.rename-edge-modal.required',
        defaultMessage: 'Required',
    },
});

export interface RenameEdgeModalProps {
    closeModal: () => void;
    edge: FullOntologyLinkType;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
}

export function RenameEdgeModal(props: RenameEdgeModalProps) {
    const {
        closeModal,
        edge,
        ontology,
        setOntology,
    } = props;

    const notifications = useArgNotifications();

    const [edgeDisplayName, setEdgeDisplayName] = useState<string>(() => edge.displayName);
    const handleEdgeDisplayNameChange = (newDisplayName: string | null) => {
        setEdgeDisplayName(newDisplayName?.trim() ?? '');
    };
    const [loading, setLoading] = useState(false);

    const renameEdge = async () => {
        setLoading(true);

        try {
            await ontologiesConnector.editOntologyEdge({
                ontologyId: ontology.id,
                name: edge.name,
                newDisplayName: edgeDisplayName,
                newProperties: edge.properties,
            });
            const newOntology = await ontologiesConnector.getFullOntology(ontology.id);
            setOntology(newOntology);
            setLoading(false);
            handleEdgeDisplayNameChange('');
            closeModal();
        } catch (e) {
            notifications.snackError({ message: messages.renameEdgeError }, e as Error);
            setLoading(false);
        }
    };


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        renameEdge();
    };

    return (
        <ArgModal
            size='medium'
            title={messages.title}
            onClose={closeModal}
            onCancel={closeModal}
            okText={messages.submit}
            onOk={renameEdge}
            okDisabled={edgeDisplayName === ''}
            loading={loading}
        >
            <form autoComplete='off' onSubmit={handleSubmit}>
                <ArgFormLabel
                    propertyName={messages.fieldName}
                    required={messages.required}
                >
                    <ArgInputText
                        value={edgeDisplayName}
                        autoSelect={true}
                        onChange={handleEdgeDisplayNameChange}
                    />
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
}
