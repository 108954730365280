import { DependencyList, useCallback, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { GLOBAL_PM, ProgressMonitor, ProgressMonitorOptions } from '../progress-monitors/progress-monitor';
import { useProgressMonitor } from '../progress-monitors/use-progress-monitor';


export const NOT_UNIQ = { uniq: false };

export const NOT_UNIQ_AND_GLOBAL = { ...GLOBAL_PM, uniq: false };

export interface UseProgressMonitorOptions extends ProgressMonitorOptions {
    uniq?: boolean;
}


export function useCallbackAsync<U extends any[], K>(
    asyncCallback: (progressMonitor: ProgressMonitor, ...args: U) => Promise<K>,
    deps: DependencyList,
    progressMonitorName?: string | MessageDescriptor,
    progressMonitorTaskCount = 1,
    progressMonitorOptions?: UseProgressMonitorOptions,
): [(...args: U) => Promise<K>, ProgressMonitor | undefined, Error | undefined] {
    const [progressMonitor, createProgressMonitor] = useProgressMonitor(progressMonitorOptions?.uniq);
    const [error, setError] = useState<Error>();

    const ret = useCallback((...args: U) => {
        setError(undefined);

        const progressMonitor = createProgressMonitor(progressMonitorName || 'useCallbackAsync', progressMonitorTaskCount, progressMonitorOptions);

        const ret = asyncCallback(progressMonitor, ...args).then((result) => {
            // console.log(result);
            return result;
        }, (error) => {
            console.error(error);

            if (!progressMonitor.isCancelled) {
                setError(error);
            }

            return Promise.reject(error);
        }).finally(() => {
            progressMonitor.done();
        });

        return ret;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return [ret, progressMonitor, error];
}
