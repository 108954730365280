import { KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';

import { KeyBindingDescriptor, KeyBindingScopeDescriptor } from '../keybindings/keybinding';
import { DndAction } from '../arg-dnd/droppable';
import { DragAndDropEffects } from '../arg-dnd/drag-drop';
import { ArgRenderedIcon, ArgRenderedText } from '../types';
import { ArgNavigateTo } from '../arg-navigation/arg-navigate-to';

export type ArgTabKey = string;

export interface ArgTabMenuItem {
    key?: ArgTabKey;
    icon?: ArgRenderedIcon;
    label: ArgRenderedText;
    onClick?: (event: MouseEvent | KeyboardEvent) => void;
    disabled?: boolean;
    tooltip?: ArgRenderedText;
    hasDivider?: boolean;
}

export type ArgTabMenuItems = Record<string, ArgTabMenuItem> | ArgTabMenuItem[];

export type ArgTagRenderTooltip = (tab: ArgTab) => ReactNode;

export type ArgTabTitleWrapper = (
    tab: ArgTab,
    closable: boolean,
    selected: boolean,
    externalDragging: boolean,
    onChange: (tabKey: ArgTabKey | undefined, action: ArgTabAction) => void,
    getBodyElement: (tabKey: ArgTabKey) => HTMLElement | undefined,
) => ReactNode;

export interface ArgTab {
    key: ArgTabKey;
    icon?: ReactNode;
    iconColor?: string;
    title?: ArgRenderedText;
    closable?: boolean;
    children?: ReactNode | ((visible: boolean) => ReactNode);
    onClose?: () => void;
    menu?: ArgTabMenuItems;
    activeKeyBinding?: KeyBindingDescriptor;
    keyBindingsScope?: KeyBindingScopeDescriptor;

    // Supported by ArgTab
    pinned?: boolean;
    pinnedEnd?: boolean;
    dropAction?: DndAction;
    dragEffects?: DragAndDropEffects;
    titleWrapper?: ArgTabTitleWrapper;
    description?: ArgRenderedText;
    respawn?: () => void;
    additional?: ReactNode;
    draggable?: boolean;
    underlineColor?: string;
    tooltip?: ReactNode | boolean | ArgTagRenderTooltip | MessageDescriptor;
    hideTitleLabel?: boolean;

    // Supported by ArgTabSubLevel
    titleCount?: number;
    titleLoading?: boolean;

    navigateTo?: ArgNavigateTo|string;
}

export enum ArgTabAction {
    Add = 'add',
    Show = 'show',
    Close = 'close',
    Move = 'move',

    // Internal
    Destroy = 'destroy',
    ResourceClosed = 'resourceClose',
}
