import { mapKeys, omit, size, trim } from 'lodash';
import Debug from 'debug';
import { defineMessages } from 'react-intl';

import { Environment } from '../../../utils/environment';
import { CaseId } from '../../../model/folder-case-piece';
import { TableVisualizationSorting, TableVisualizationSortingOrder } from '../../model/graph-visualization';
import { UniverseEdgeTypeName, UniverseId, UniverseVertexTypeName } from '../../model/universe';
import { Paths } from '../../model/paths';
import {
    ArgMapTiles,
    Configuration,
    DataSorter,
    LatitudeAndLongitude,
    LatitudeAndLongitudeResponse,
    ProgressMonitor,
    SubProgressMonitor,
} from '../../../components/basic';
import { PathType } from '../../model/responses';
import { Expansion, ParameterValues } from '../../model/expansion';
import {
    CreatedCaseExplorationResult,
    CreatedExplorationResult,
    Exploration,
    ExplorationCasePiece,
    ExplorationId,
    ExplorationItemResult,
    ExplorationOrigin,
    ExplorationProcessingResult,
    ExplorationStyleDefinition,
    TraversalExploreResult,
} from '../../model/exploration';
import { ConnectorRequestInit, createPatchRequest, JsonChange } from '../../../utils/connector';
import {
    ExplorationVisualizationStyleCapabilities,
    ExplorationVisualizationStyles,
} from '../../model/exploration-visualization';
import { SearchOptions } from '../../../model/search-suggestion';
import { GraphStylesInfos, VertexOrEdge } from '../../hooks/use-graph-style-customisation';
import { User } from '../../../model/user';
import { ContextualVariable } from '../../model/contextual-variable';
import { TraversalCaseExploreRequestBody } from '../request-types';
import { ExplorationGroup, ExplorationGroupId } from '../../model/group';
import { MapLayer, MapLayerList } from 'src/model/layers';
import { mapEdgeBasicInfo, mapExplorationGroup, mapExplorationInfo, mapRawVertexToVertex, mapToPath } from './mappers';
import { BaseConnector } from '../../../utils/connectors/base-connector';
import { CreateExplorationFromSearchQueryBodyType, UpdateExplorationType } from 'src/exploration/model/types';
import {
    RawContextualVariableResult,
    RawCreatedExplorationResult,
    RawCustomPathsList,
    RawExplorationGroup,
    RawPathsComputationItem,
} from './raws';
import { Filter, FilterOperation, VertexFilter } from 'src/exploration/model/filter';
import { mapUser, UsersConnector } from 'src/utils/connectors/users-connector';
import { CasePieceType } from '../../../model/case-piece-type';
import { getDataExplorationApi } from '../../../utils/connectors/api-url';
import { WSProgressMonitor } from '../../../components/basic/progress-monitors/ws-progress-monitor';
import { registerCasePieceType } from 'src/utils/connectors/mappers';
import { FlaggedObjectCasePiece } from '../../model/flagged-object';
import { ResponseError } from '../../../components/basic/utils/response-error';
import { BulkSearchCasePiece } from 'src/exploration/model/bulk-search';
import { filterChain, isFilterOperation } from '../../model/filter-chain';
import { AddressDetails } from 'src/exploration/model/address-details';
import { ExplorationPieceConnector } from './exploration-pieces-connector';
import { ApplicationVersion } from 'src/components/common/applications/applications-version';
import { AdvancedClusteringOption } from 'src/exploration/model/map-visualization';

const debug = Debug('argonode:exploration:utils:ExplorationConnector');

export const VERTEX_ICON_PROPERTY_KEY = '$_$_vertex_icon_$_$';

const MOCK_TILES = true;

export interface VertexVisualizationReturnOptions {
    listAttachments?: boolean;
    listRelatedVertices?: boolean;
}

export interface DocumentAsHtml {
    name: string;
    uri: string;
}

export interface ExplorationMapRegion {
    southWestCorner: {
        latitude: number;
        longitude: number;
    };
    northEastCorner: {
        latitude: number;
        longitude: number;
    };
}

export interface ExplorationMapRequest {
    selection?: Filter;
    edgeSelection?: Filter;
    filter?: Filter | FilterOperation;
    folderId?: CaseId;
    region: ExplorationMapRegion;
    mode: 'Heatmap' | 'Standard';
    advancedClusteringOption?: AdvancedClusteringOption;
}

export interface SubTableOptions {
    relationFilter: Filter;
    initialObjectsFilter: Filter;
    selection: Filter;
    sorting: TableVisualizationSorting[] | null;
    skip: number | null;
    top: number | null;
    includesColumns: boolean;
    searchOptions: SearchOptions;
}

export interface TablePropertiesSelection {
    typeName: string | null;
    property: string;
    specialProperty?: 'Id' | 'Type' | null;
}

const messages = defineMessages({
    computedPathsProcessCount: {
        id: 'exploration.traversal.connector.ComputedPathsProcessCount',
        defaultMessage: '{parameter, plural, =0 {Computing paths {threeDotsLoading}} other {{parameter, number} paths computed ({workedTicks, number, percent})}}',
    },
});

export class ExplorationConnector extends BaseConnector {
    private static instance: ExplorationConnector;
    private static usersConnectorInstance: UsersConnector;

    static getInstance(): ExplorationConnector {
        if (!ExplorationConnector.instance) {
            ExplorationConnector.instance = new ExplorationConnector('exploration', getDataExplorationApi());
        }

        return ExplorationConnector.instance;
    }

    async getExploration(
        id: ExplorationId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<Exploration>> {
        const url = `/explorations/${encodeURIComponent(id)}`;

        const result = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        debug('getExploration', 'Exploration received from the server:', result);

        const ret = mapExplorationInfo(result);

        return ret;
    }

    async visitExploration(
        caseId: CaseId,
        explorationId: ExplorationId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        await ExplorationPieceConnector.getInstance().visitPiece(caseId, explorationId, progressMonitor);
    }

    async createExploration(
        explorationName: string | undefined,
        description: string | undefined,
        universeId: UniverseId,
        caseId: CaseId,
        origin: ExplorationOrigin,
        universeFilter: VertexFilter | FilterOperation,
        fromExplorationId?: ExplorationId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<ExplorationCasePiece>> {
        if (isFilterOperation(universeFilter)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            universeFilter = await filterChain(universeFilter).value(universeId, 'Vertex', sub1);
        }

        const url = '/explorations';
        const options: ConnectorRequestInit = {
            json: {
                explorationName,
                description,
                origin,
                universeFilter,
                fromExplorationId,
                universeId,
            },
            verifyJSONResponse: true,
        };
        const sub1 = new SubProgressMonitor(progressMonitor, 1);

        let createdExplorationResult: RawCreatedExplorationResult;

        try {
            createdExplorationResult = await this.request(url, options, sub1);
            debug('createExploration', 'Exploration created:', createdExplorationResult);
        } catch (error) {
            console.error('Cannot create exploration', 'explorationName=', explorationName, 'url=', url, 'error=', error);
            throw new ResponseError('Cannot create exploration', url, error as Error);
        }

        const explorationId = this.getExplorationCreatedResult(createdExplorationResult).explorationId;
        if (!explorationId) {
            throw new Error('Cannot retrieve exploration id');
        }

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        await ExplorationPieceConnector.getInstance().addPiece(caseId, explorationId, CasePieceType.Exploration, sub2);

        const sub3 = new SubProgressMonitor(progressMonitor, 1);
        const explorationCasePiece = await this.getExplorationCasePiece(caseId, explorationId, sub3);

        return explorationCasePiece;
    }

    async createExplorationFromSearchQuery(
        name: string,
        description: string | undefined,
        universeId: UniverseId,
        caseId: CaseId,
        query: string,
        labels?: UniverseVertexTypeName[],
        origin?: ExplorationOrigin,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<ExplorationCasePiece | undefined> {
        const url = '/explorations';
        const options = {
            json: {
                explorationName: name,
                initialQuery: {
                    searchTerm: query,
                    caseId,
                },
                description,
                universeId,
                origin,
            },
        } as {
            json: Omit<CreateExplorationFromSearchQueryBodyType, 'universeFilter'>;
        };

        if (labels?.length) {
            const universeFilter: Filter = {
                filterGroups: [{
                    type: {
                        included: labels,
                    },
                }],
            };

            options.json = {
                ...options.json,
                universeFilter,
            } as CreateExplorationFromSearchQueryBodyType;
        }

        let createdExplorationResult: RawCreatedExplorationResult;
        const sub1 = new SubProgressMonitor(progressMonitor, 1);

        try {
            createdExplorationResult = await this.request(url, options, sub1);
            debug(
                'createExplorationFromSearchQuery',
                'Exploration corresponding to the search query ${query} is created:',
                createdExplorationResult,
            );
            if (createdExplorationResult === null) {
                return undefined;
            }
        } catch (error) {
            console.error('Cannot create exploration', 'explorationName=', name, 'url=', url, 'error=', error);
            throw new ResponseError('Cannot create exploration', url, error as Error);
        }

        const explorationId = this.getExplorationCreatedResult(createdExplorationResult).explorationId;
        if (!explorationId) {
            throw new Error('Cannot retrieve exploration id');
        }
        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        await ExplorationPieceConnector.getInstance().addPiece(caseId, explorationId, CasePieceType.Exploration, sub2);

        const sub3 = new SubProgressMonitor(progressMonitor, 1);
        const explorationCasePiece = await this.getExplorationCasePiece(caseId, explorationId, sub3);

        return explorationCasePiece;
    }

    async getExplorationCasePiece(caseId: CaseId, explorationId: ExplorationId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ExplorationCasePiece> {
        const ret2 = await ExplorationPieceConnector.getInstance().getPiece(caseId, explorationId, true, true, progressMonitor);

        return ret2 as ExplorationCasePiece;
    }

    getExplorationCreatedResult(createdExplorationResult: RawCreatedExplorationResult) {
        let ret: CreatedExplorationResult;
        if ((createdExplorationResult as ExplorationProcessingResult).exploration) {
            ret = {
                processId: (createdExplorationResult as ExplorationProcessingResult).processId,
                explorationId: (createdExplorationResult as ExplorationProcessingResult).exploration.id,
            };
        } else if ((createdExplorationResult as TraversalExploreResult).explorationId) {
            ret = {
                explorationId: (createdExplorationResult as TraversalExploreResult).explorationId,
            };
        } else {
            ret = {
                explorationId: (createdExplorationResult as ExplorationItemResult).id,
            };
        }

        return ret;
    }

    async traversalCaseExplore(universeId: UniverseId, request: TraversalCaseExploreRequestBody, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<CreatedCaseExplorationResult> {
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const url = `/universes/${encodeURIComponent(universeId)}/traversals/explore`;
        const options: ConnectorRequestInit = {
            json: request,
            verifyJSONResponse: true,
        };

        let result: RawCreatedExplorationResult;
        try {
            result = await this.request(url, options, sub1);
        } catch (error) {
            throw new ResponseError('Cannot create exploration', url, error as Error);
        }

        const explorationCreatedResult = this.getExplorationCreatedResult(result);
        const explorationId = explorationCreatedResult.explorationId;
        if (!explorationId) {
            throw new Error('Cannot retrieve exploration id');
        }

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        await ExplorationPieceConnector.getInstance().addPiece(request.newExploration.caseId, explorationId, CasePieceType.Exploration, sub2);

        const sub3 = new SubProgressMonitor(progressMonitor, 1);
        let explorationCasePiece: ExplorationCasePiece;

        try {
            explorationCasePiece = await this.getExplorationCasePiece(request.newExploration.caseId, explorationId, sub3);
        } catch (error) {
            throw new Error('Cannot retrieve exploration');
        }

        const ret: CreatedCaseExplorationResult = {
            createdExplorationResult: explorationCreatedResult,
            explorationCasePiece: explorationCasePiece,
        };

        return ret;
    }

    async deleteExploration(caseId: CaseId | undefined, explorationId: ExplorationId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        if (caseId) {
            await ExplorationPieceConnector.getInstance().deletePiece(caseId, explorationId, true, progressMonitor);
        } else {
            const url = `/explorations/${encodeURIComponent(explorationId)}`;
            const options: ConnectorRequestInit = {
                method: 'DELETE',
            };
            await this.request(url, options, progressMonitor);
        }

        debug('deleteExploration', 'Exploration deleted:', explorationId);
    }

    async deleteExplorations(
        caseId: CaseId,
        explorationIds: ExplorationId[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        for (let i = 0; i < explorationIds.length; i++) {
            const explorationId = explorationIds[i];

            await this.deleteExploration(caseId, explorationId, progressMonitor);
        }

        debug('deleteExplorations', 'Explorations deleted:', explorationIds);
    }

    async updateExplorationWithConstraints(
        explorationId: ExplorationId,
        universeId: UniverseId,
        type: UpdateExplorationType,
        selection: Filter | FilterOperation,
        fromExplorationId?: ExplorationId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        if (isFilterOperation(selection)) {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            selection = await filterChain(selection).value(universeId, 'Vertex', sub1);
        }

        const url = `/explorations/${encodeURIComponent(explorationId)}/content`;

        enum FilterType {
            Union = 'Union',
            Intersect = 'Intersect',
            Subtract = 'Subtract',
            Replace = 'Replace',
        }

        let filterType: FilterType = FilterType.Union;
        switch (type) {
            case UpdateExplorationType.Delete:
                filterType = FilterType.Subtract;
                break;
            case UpdateExplorationType.DeleteOthers:
                filterType = FilterType.Intersect;
                break;
            case UpdateExplorationType.Add:
            default:
                filterType = FilterType.Union;
                break;
        }

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                filter: {
                    type: filterType,
                    value: selection,
                    fromOtherExplorationId: fromExplorationId,
                },
            },
        };

        try {
            await this.request(url, options, progressMonitor);

            debug('update exploration');
        } catch (error) {
            console.error(
                'Cannot update exploration',
                'explorationId= ',
                explorationId,
                'url= ',
                url,
                'error= ',
                error,
            );
            throw new ResponseError('Cannot update exploration', url, error as Error);
        }
    }

    async getDocumentAsHtml(path: string, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Readonly<DocumentAsHtml>> {
        const result = await this.request(
            `/documents/html/${encodeURIComponent(path)}`,
            undefined,
            progressMonitor,
        );

        const ret: DocumentAsHtml = {
            name: result.name as string,
            uri: result.uri as string,
        };

        return ret;
    }

    async updateExplorationVisualizationHeatmapBehavior(
        explorationId: ExplorationId,
        filter: Filter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/explorations/${encodeURIComponent(explorationId)}/visualizations/map/behavior`;
        await this.request(
            url,
            {
                method: 'PUT',
                json: {
                    filter,
                },
            },
            progressMonitor,
        );
    }

    // We must use variable form of function to keep this in scope.
    getAddressCoordinates = async (
        address: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<LatitudeAndLongitudeResponse> | undefined> => {
        if (!size(trim(address))) {
            return;
        }

        let latitudeAndLongitude;
        try {
            latitudeAndLongitude = await this.request(
                '/geography/search',
                {
                    json: {
                        address,
                    },
                    verifyJSONResponse: true,
                },
                progressMonitor,
            );
        } catch (x) {
            if ((x as ResponseError)?.response?.status === 404) {
                return null;
            }

            throw x;
        }

        debug('getAddressCoordinates', 'Latitude and longitude received from the server=', latitudeAndLongitude);

        return latitudeAndLongitude;
    };

    getCoordinatesAddressDetails = async (coordinates: LatitudeAndLongitude, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<AddressDetails> => {
        const url = '/geography/reverse';
        const options: ConnectorRequestInit = {
            json: {
                latitude: coordinates.latitude,
                longitude: coordinates.longitude,
            },
            verifyJSONResponse: true,
        };

        const addressDetails = await this.request(url, options, progressMonitor) as AddressDetails;

        return addressDetails;
    };

    async getExpansionsList(
        explorationId: ExplorationId,
        filter: Filter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<Expansion[]>> {
        const url = `/explorations/${encodeURIComponent(explorationId)}/expansions`;

        const expansionsList = await this.request(
            url,
            {
                json: {
                    originFilter: filter,
                },
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        return expansionsList.expansions;
    }

    async getPossibleExpansions(
        explorationId: ExplorationId,
        expandId: string,
        parameterName: string,
        filter: Filter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<ParameterValues>> {
        const url = `/explorations/${encodeURIComponent(explorationId)}/expansions/${encodeURIComponent(expandId)}/values/${encodeURIComponent(parameterName)}`;

        const parameterValuesResponse = await this.request(
            url,
            {
                json: {
                    originFilter: filter,
                },
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        return parameterValuesResponse.values;
    }

    async applyExpansions(
        explorationId: ExplorationId,
        expandId: string,
        filter: Filter,
        edgeTypes?: UniverseEdgeTypeName[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/explorations/${encodeURIComponent(explorationId)}/expansions/${encodeURIComponent(expandId)}`;
        const json = {
            originFilter: filter,
            parameters: edgeTypes ? {
                _EdgeLabels_: edgeTypes,
            } : undefined,
        };
        await this.request(
            url,
            {
                method: 'POST',
                json,
            },
            progressMonitor,
        );
    }

    async getPathsListForSelectedVertices(
        explorationId: ExplorationId,
        filter?: Filter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<PathType[]>> {
        const url = `/explorations/${encodeURIComponent(explorationId)}/paths`;
        const options = {
            method: 'POST',
            json: { filter: filter || { all: true } },
            verifyJSONResponse: true,
        };

        try {
            const pathsList: RawCustomPathsList = await this.request(url, options, progressMonitor);

            return mapToPath(pathsList.paths);
        } catch (error) {
            console.error(
                'Cannot get the list of paths',
                'explorationId=',
                explorationId,
                'vertexIds=',
                filter,
                'url=',
                url,
                'error=',
                error,
            );
            throw error;
        }
    }

    async getAndApplyPaths(
        explorationId: ExplorationId,
        pathId: string,
        parametersByIdentifier: Record<string, string[]>,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Readonly<Paths> | null> {
        const url = `/explorations/${encodeURIComponent(explorationId)}/paths/${encodeURIComponent(pathId)}`;
        const options = {
            json: {
                searchForAllCombinations: true,
                parametersByIdentifier,
            },
        };

        try {
            const result: RawPathsComputationItem = await this.request(url, options, progressMonitor);
            const pathResult: Paths = {
                newVertices: result.newVertices.map(mapRawVertexToVertex),
                newEdges: result.newEdges.map(mapEdgeBasicInfo),
                paths: result.paths,
            };

            return pathResult;
        } catch (error) {
            console.error(
                'Cannot get and apply paths',
                'explorationId=',
                explorationId,
                'pathId=',
                pathId,
                'url=',
                url,
                'error=',
                error,
            );
            throw error;
        }
    }

    async getExplorationStyleDefinition(
        explorationId: ExplorationId,
        label: string,
        vertexOrEdge: VertexOrEdge,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<ExplorationStyleDefinition> {
        const verticesOrEdges = vertexOrEdge === VertexOrEdge.Vertex ? 'vertices' : 'edges';
        const url = `/explorations/${encodeURIComponent(
            explorationId,
        )}/visualizations/styles/${encodeURIComponent(verticesOrEdges)}/${encodeURIComponent(label)}`;

        const explorationStyleDefinition: ExplorationStyleDefinition = await this.request(
            url,
            { verifyJSONResponse: true },
            progressMonitor,
        );

        return explorationStyleDefinition;
    }

    async saveExplorationStyleDefinition(
        explorationId: ExplorationId,
        body: Record<string, any>,
        vertexOrEdge: VertexOrEdge,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const label = body.label;
        const verticesOrEdges = vertexOrEdge === VertexOrEdge.Vertex ? 'vertices' : 'edges';
        const url = `/explorations/${encodeURIComponent(
            explorationId,
        )}/visualizations/styles/${encodeURIComponent(verticesOrEdges)}/${encodeURIComponent(label)}`;

        const json = mapKeys(
            omit(body, ['label', 'defaultPropertiesExpanded', 'colorAndIconEditorVisible']),
            (_, key) => {
                if (key === 'style') {
                    return 'userDefinedContent';
                }

                return key;
            },
        );

        await this.request(url, { json }, progressMonitor);
    }

    async resetExplorationStyles(
        explorationId: ExplorationId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/explorations/${encodeURIComponent(explorationId)}/visualizations/styles/reset`;

        await this.request(url, undefined, progressMonitor);
    }

    async getExplorationSettings(
        explorationId: ExplorationId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Configuration> {
        const appId = Environment.appId;

        const result = await this.request(
            `/explorations/${encodeURIComponent(explorationId)}/appdata/${encodeURIComponent(appId)}`,
            {
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        return result.appData;
    }

    async updateExplorationSettings(
        explorationId: ExplorationId,
        settings: Configuration,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const appId = Environment.appId;
        debug('updateExplorationSettings', settings);

        await this.request(
            `/explorations/${encodeURIComponent(explorationId)}/appdata/${encodeURIComponent(appId)}`,
            {
                json: { appData: settings },
                method: 'PUT',
            },
            progressMonitor,
        );
    }

    async getExplorationVisualizationStyleCapabilities(
        explorationId: ExplorationId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<ExplorationVisualizationStyleCapabilities> {
        const explorationVisualizationStyleCapabilities: ExplorationVisualizationStyleCapabilities =
            await this.request(
                `/explorations/${encodeURIComponent(explorationId)}/visualizations/styles/capabilities`,
                {
                    verifyJSONResponse: true,
                },
                progressMonitor,
            );

        return explorationVisualizationStyleCapabilities;
    }

    async applyExplorationVisualizationStyles(
        explorationId: ExplorationId,
        templateId: string,
        vertexStyles?: Record<UniverseVertexTypeName, GraphStylesInfos>,
        edgeStyles?: Record<UniverseEdgeTypeName, GraphStylesInfos>,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                templateId,
                vertexStyles,
                edgeStyles,
            },
        };
        await this.request(
            `/explorations/${encodeURIComponent(explorationId)}/visualizations/styles`,
            options,
            progressMonitor,
        );
    }

    async getExplorationVisualizationStyles(
        explorationId: ExplorationId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<ExplorationVisualizationStyles> {
        const explorationVisualizationStyles: ExplorationVisualizationStyles =
            await this.request(
                `/explorations/${encodeURIComponent(explorationId)}/visualizations/styles`,
                {
                    verifyJSONResponse: true,
                },
                progressMonitor,
            );

        return explorationVisualizationStyles;
    }

    async getUsers(
        search?: string,
        deleted?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<User[]> {
        const url = '/users';

        const options: ConnectorRequestInit = {
            params: {
                q: search,
                deleted,
            },
            verifyJSONResponse: true,
        };

        const userResults: any = await this.request(url, options, progressMonitor);

        const ret = userResults.users?.map(mapUser) || [];

        return ret;
    }

    async getContextualVariables(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<ContextualVariable[]> {
        const url = '/contextual-variables';

        const contextualVariablesResult: RawContextualVariableResult = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        return contextualVariablesResult.variables || [];
    }

    async saveContextualVariable(
        variable: ContextualVariable,
        isEditMode: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const { id: variableId } = variable;
        let url = '';
        let method = '';
        if (isEditMode && variableId) {
            url = `/contextual-variables/${encodeURIComponent(variableId)}`;
            method = 'PUT';
        } else {
            url = '/contextual-variables';
            method = 'POST';
        }

        const options: ConnectorRequestInit = {
            method: method,
            json: {
                displayName: variable.displayName,
                description: variable.description,
                path: variable.path,
                type: variable.type,
                value: variable.value,
                auto: variable.auto,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteContextualVariable(
        variableName: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/contextual-variables/${encodeURIComponent(variableName)}`;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async createExplorationGroup(
        explorationId: ExplorationId,
        newGroup: Omit<ExplorationGroup, 'filter'>,
        filter: VertexFilter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<ExplorationGroupId> {
        const url = `/explorations/${encodeURIComponent(explorationId)}/vertex-groups`;

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                newGroup,
                filter,
            },
            verifyJSONResponse: true,
        };

        const { id }: { id: ExplorationGroupId } = await this.request(url, options, progressMonitor);

        return id;
    }

    async updateExplorationGroup(
        groupId: ExplorationGroupId,
        updatedGroup: Omit<ExplorationGroup, 'id' | 'filter'>,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/vertex-groups/${encodeURIComponent(groupId)}`;

        const options: ConnectorRequestInit = {
            method: 'PUT',
            json: {
                ...updatedGroup,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async getExplorationGroup(
        groupId: ExplorationGroupId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<ExplorationGroup> {
        const url = `/vertex-groups/${encodeURIComponent(groupId)}`;

        const ret: RawExplorationGroup = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const explorationGroup = mapExplorationGroup(ret);

        return explorationGroup;
    }

    async ungroupExplorationGroup(
        groupId: ExplorationGroupId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/vertex-groups/${encodeURIComponent(groupId)}`;

        const options: ConnectorRequestInit = { method: 'DELETE' };

        await this.request(url, options, progressMonitor);
    }

    async removeObjectFromGroup(
        groupId: ExplorationGroupId,
        filter: Filter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/vertex-groups/${encodeURIComponent(groupId)}/vertices`;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
            json: {
                filter,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async addObjectToGroup(
        groupId: ExplorationGroupId,
        filter: Filter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/vertex-groups/${encodeURIComponent(groupId)}/vertices`;

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                filter,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async getMapTilesGlServer(tilesServerGlURL: string, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ArgMapTiles[] | undefined> {
        let rawMapTiles: any;
        if (MOCK_TILES
            && process.env.NODE_ENV !== 'production'
            && tilesServerGlURL === 'https://maps.agence-plus.net/index.json'
        ) {
            const ret: any = await import('./tiles-index.json');

            rawMapTiles = [];
            Object.keys(ret).forEach((k) => {
                if (!/^\d+$/.test(k)) {
                    return;
                }
                rawMapTiles.push(ret[k]);
            });
        } else {
            const options: ConnectorRequestInit = {
                method: 'GET',
                verifyJSONResponse: true,
                forceURL: true,
            };

            rawMapTiles = await this.request(tilesServerGlURL, options, progressMonitor);
        }

        rawMapTiles = rawMapTiles.filter((m: any) => {
            if ((m.format === 'png' || m.format === 'jpg') && m.type === 'overlay') {
                return true;
            }
            if ((m.format === 'png' || m.format === 'jpg') && m.type === 'baselayer') {
                return true;
            }

            return false;
        });

        const ret = rawMapTiles.map((m: any) => {
            const ret: ArgMapTiles = {
                ...m,
                id: m.id || m.tiles[0],
                url: m.tiles[0],
                maxZoom: (m.maxZoom !== undefined) ? m.maxZoom : m.maxzoom,
                minZoom: (m.minZoom !== undefined) ? m.minZoom : m.minzoom,
            };

            return ret;
        });

        return ret;
    }

    async getMapLayers(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<MapLayerList> {
        const url = '/map-layers';

        const options: ConnectorRequestInit = {
            method: 'GET',
            verifyJSONResponse: true,
            params: {
                includeInactiveLayers: false,
            },
        };
        const rawMapLayers = await this.request(url, options, progressMonitor);

        const existingMapLayers = rawMapLayers.existingMapLayers;

        const mapLayers: MapLayer[] = existingMapLayers.map((layer: any) => {
            const ret: MapLayer = {
                id: layer.id,
                name: layer.name,
                description: layer.name,
                serverUrl: layer.serverUrl, // "https://layers.chapsvision.com",
                attribution: layer.attribution,
                urlLayerReference: layer.urlLayerReference,
                format: 'image/png',
            };

            return ret;
        });

        const response: MapLayerList = { existingMapLayers: mapLayers };

        return response;
    }

    async renameExploration(caseId: CaseId, explorationId: ExplorationId, newName: string | undefined, newDescription: string | undefined, progressMonitor: ProgressMonitor): Promise<void> {
        const changes: JsonChange[] = [];
        if (newName) {
            changes.push({ path: 'name', value: newName });
        }
        if (newDescription) {
            changes.push({ path: 'description', value: newDescription });
        }

        if (!changes.length) {
            return;
        }

        const url = `/explorations/${encodeURIComponent(explorationId)}`;

        const options = createPatchRequest('Change name and/or description', ...changes);

        await this.request(url, options, progressMonitor);
    }

    static getUsersConnector(): UsersConnector { // TODO pass UsersConnector to user-metadata-table props
        if (!ExplorationConnector.usersConnectorInstance) {
            ExplorationConnector.usersConnectorInstance = new UsersConnector('exploration.users', getDataExplorationApi());
        }

        return ExplorationConnector.usersConnectorInstance;
    }

    async getApplicationVersion(progressMonitor = ProgressMonitor.empty()): Promise<ApplicationVersion> {
        const url = '/version';
        const version = await this.request(url, undefined, progressMonitor) as ApplicationVersion;

        return version;
    }

    async extractFromCluster(
        universeId: UniverseId,
        explorationId: ExplorationId,
        filter: VertexFilter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/explorations/${encodeURIComponent(explorationId)}/extraction/extract`;

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                filter,
                universeId,
            },
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async restoreToCluster(
        explorationId: ExplorationId,
        filter: Filter,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/explorations/${encodeURIComponent(explorationId)}/extraction/restore`;

        const options: ConnectorRequestInit = {
            method: 'PUT',
            json: {
                ...filter,
            },
        };

        await this.request(url, options, progressMonitor);
    }
}

export function mapDataSorter(sorter: DataSorter | undefined) {
    return sorter?.propertySorters.map((propertySorter) => {
        const ret: TableVisualizationSorting = {
            order: propertySorter.order === 'ascending'
                ? TableVisualizationSortingOrder.Ascending
                : TableVisualizationSortingOrder.Descending,
            column: propertySorter.propertyName,
        };

        return ret;
    });
}

export class TraversalDataProgressMonitor extends WSProgressMonitor {
    processMessage(messageKey: string, parameter: any) {
        debug('TraversalDataProgressMonitor.processMessage', messageKey, parameter);
        switch (messageKey) {
            case 'ComputedPathsProcessCount':
                this.setTaskName(messages.computedPathsProcessCount, { parameter });

                return;
        }
    }
}

registerCasePieceType('exploration', CasePieceType.Exploration, (ret: ExplorationCasePiece, entityProperties: Record<string, any> | undefined) => {
    ret.universeId = entityProperties?.universeId;
    ret.explorationOrigin = entityProperties?.origin;

    if (!ret.universeId) {
        console.warn('No universeId defined for exploration #', ret.id);
    }
});

registerCasePieceType('fastsearch', CasePieceType.FastSearch, (ret: ExplorationCasePiece, entityProperties: Record<string, any> | undefined) => {
    ret.universeId = entityProperties?.universeId;
    ret.explorationOrigin = entityProperties?.origin;

    if (!ret.universeId) {
        console.warn('No universeId defined for fast search #', ret.id);
    }
});
registerCasePieceType('advancedsearch', CasePieceType.AdvancedSearch, (ret: ExplorationCasePiece, entityProperties: Record<string, any> | undefined) => {
    ret.universeId = entityProperties?.universeId;
    ret.explorationOrigin = entityProperties?.origin;

    if (!ret.universeId) {
        console.warn('No universeId defined for advanced search #', ret.id);
    }
});
registerCasePieceType('massivesearch', CasePieceType.MassiveSearch, (ret: BulkSearchCasePiece, entityProperties: Record<string, any> | undefined) => {
    ret.universeId = entityProperties?.universeId;
    ret.origin = entityProperties?.origin;

    if (!ret.universeId) {
        console.warn('No universeId defined for massive search #', ret.id);
    }
    if (!ret.origin) {
        console.warn('No origin defined for massive search #', ret.id);
    }
});

registerCasePieceType('case-flag', CasePieceType.FlaggedObject, (ret: FlaggedObjectCasePiece, entityProperties: Record<string, any> | undefined) => {
    ret.universeId = entityProperties?.universeId;
    ret.vertexId = entityProperties?.targetId;
    ret.flag = { label: ret.displayName };

    if (!ret.universeId) {
        console.warn('No universeId defined for case-flag #', ret.id);
    }
});
