import { defineMessages } from 'react-intl';

import { NavItem } from '../models/navigation';
import { registerToolItem, Tool } from '../../components/basic';
import { SettingsEnvironmentContext } from '../envrionment-contexts/settings-environment-context';
import { SETTINGS_ROOT_URL } from '../../components/containers/routes-urls';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../envrionment-contexts/context-names';
import { ArgonosModule } from '../../components/application/modules';

export const ROLES_PATH = '/settings/roles';
export const ROLES_ROUTE = '/settings/roles/:rolesScope';

const messages = defineMessages({
    roles: {
        id: 'settings.roles.menu.title',
        defaultMessage: 'Roles',
    },
});

export function rolesNavItems(argonosModule?: ArgonosModule) {
    if (!argonosModule) {
        return [];
    }

    const navItem: NavItem = {
        path: `/settings/${encodeURIComponent(argonosModule.id)}/roles`,
        label: messages.roles,
        icon: 'icon-user-badge',
        iconSize: argonosModule.scope === 'admin' ? 20 : 18,
        children: [],
    };

    return [navItem];
}

export function registerRolesToolItems(path: string, order: number, argonosModule?: ArgonosModule): void {
    if (!argonosModule) {
        return;
    }

    registerToolItem(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path,
        order,
        label: messages.roles,
        icon: 'icon-user-badge',
        visible: (settingsEnvironmentContext: SettingsEnvironmentContext) => {
            if (!settingsEnvironmentContext.hasAnyPermissions('admin.user.role.access')) {
                return false;
            }

            return true;
        },
        onClick: (tool: Tool<SettingsEnvironmentContext>, environmentContext: SettingsEnvironmentContext) => {
            const url = `${SETTINGS_ROOT_URL}/${encodeURIComponent(argonosModule.id)}/roles`;
            environmentContext.navigate(url);
        },
    });
}

export function setupRoles(argonosModule: ArgonosModule) {
    registerRolesToolItems('global/admin', 100, argonosModule);
}
