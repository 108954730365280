import Debug from 'debug';
import { isNumber } from 'lodash';

import { ArgTable4Column } from './arg-table4';
import { escapeColumnKey } from '../utils';

const debug = Debug('argonode:components:argTable4:ExpandColumn');

const MIN_WIDTH = 48;


export function expandColumn<T>(
    container: HTMLElement,
    column: ArgTable4Column<T>,
    setWidth: (width: number) => void,
) {
    let minWidth = MIN_WIDTH;

    if (isNumber(column.minWidth)) {
        minWidth = column.minWidth;
    }

    let headerWidth;
    let bodyWidth;

    const bodyColumnComponent = container.querySelector(`[data-column="${escapeColumnKey(column.key)}"]`) as HTMLElement;
    if (bodyColumnComponent) {
        const cells: Element[] = Array.from(bodyColumnComponent.children);

        const maxWidth = cells.reduce((acc, cell) => {
            const firstChild = cell.firstElementChild;
            if (!firstChild) {
                return acc;
            }

            return Math.max(acc, firstChild.scrollWidth);
        }, minWidth);

        bodyWidth = maxWidth;
        /* Si vous savez pourquoi, contacter OO !
        if (cells[0]?.tagName) {
            const computedStyle = window.getComputedStyle(cells[0]);

            const paddingLeft = computedStyle.paddingLeft;
            const paddingRight = computedStyle.paddingRight;

            bodyWidth += ((paddingLeft) ? parseInt(paddingLeft) : 0) + ((paddingRight) ? parseInt(paddingRight) : 0);

            debug('expandColumn', 'Computed paddings =', paddingLeft, paddingRight);
        }
         */
    }

    const headerColumnComponent = container.querySelector(`[data-header="${escapeColumnKey(column.key)}"]`) as HTMLElement;
    if (headerColumnComponent) {
        const div = headerColumnComponent.firstElementChild?.firstElementChild;

        if (div) {
            let w = 0;

            Array.from(div.children).forEach((child) => {
                w += child.scrollWidth;
            });

            const computedStyle = window.getComputedStyle(div);

            const paddingLeft = computedStyle.paddingLeft;
            const paddingRight = computedStyle.paddingRight;

            w += ((paddingLeft) ? parseInt(paddingLeft) : 0) + ((paddingRight) ? parseInt(paddingRight) : 0);

            headerWidth = w;
        }
    }

    debug('expandColumn', 'key=', column.key, 'bodyWidth=', bodyWidth, 'headerWidth=', headerWidth);

    if (!bodyWidth && !headerWidth) {
        return;
    }

    const w = Math.max(headerWidth || 0, bodyWidth || 0);
    setWidth(w);
}
