import Debug from 'debug';

import { RtBasicState, RtStateMessage } from '../rt-basic-state';
import { WebSocketConnectionChannel } from '../../../components/ws/websocket-connector';
import { RtApi } from '../rt-api';

const debug = Debug('argonode:utils:states:RtBasicState');

export interface WatchCommands {
    watch: string;
    unwatch: string;
    linkName: string;
}

export class FolderBasicState<T extends RtStateMessage> extends RtBasicState<T> {
    readonly #api: RtApi;

    constructor(api: RtApi, url: string) {
        super(url);

        this.#api = api;
    }

    protected getRtApi(): RtApi {
        return this.#api;
    }

    protected get watchCommands(): WatchCommands | undefined {
        return undefined;
    }

    protected get watchKey(): string | undefined {
        return undefined;
    }

    async connected(channel: WebSocketConnectionChannel<T>): Promise<void> {
        await super.connected(channel);

        const commands = this.watchCommands;
        if (!commands) {
            return;
        }

        if (!channel.link(`${commands.linkName}:${this.watchKey}`)) {
            return;
        }

        await channel.connection.invoke(commands.watch, this.watchKey);
    }

    async disconnecting(channel: WebSocketConnectionChannel<T>): Promise<void> {
        const commands = this.watchCommands;
        if (commands) {
            if (channel.unlink(`${commands.linkName}:${this.watchKey}`)) {
                await channel.connection.invoke(commands.unwatch, this.watchKey);
            }
        }

        await super.disconnecting(channel);
    }
}
