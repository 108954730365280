import { defineMessages } from 'react-intl';
import { Navigate } from 'react-router';

import { useGetMyPermissions } from 'src/contexts/user-permissions-context';
import { useAvailableArgonosModules } from '../application/argonos-modules-registry';
import { useClassNames } from '../basic';
import { computePageAccess } from '../caches/pages-access';
import { ErrorPane } from '../common/panes/error-pane';

import './authenticated-redirection.less';

const FORCE_NO_AVAILABLE_MODULE = false;

const messages = defineMessages({
    noModuleAvailable: {
        id: 'common.authenticated-redirection.NoModuleAvailable',
        defaultMessage: 'No Argonos modules are available. Modules are disabled or you do not have the necessary permissions to access them.',
    },
});

export function AuthenticatedRedirection() {
    const { permissions } = useGetMyPermissions();
    const availableArgonosModules = useAvailableArgonosModules(permissions);
    const argonosModule = computePageAccess(availableArgonosModules, permissions);

    const classNames = useClassNames('common-authenticated-redirection');

    if (!FORCE_NO_AVAILABLE_MODULE && argonosModule?.routeURL) {
        return <Navigate to={argonosModule?.routeURL} replace={true} />;
    }

    return (
        <div className={classNames('&')}>
            <ErrorPane
                message={messages.noModuleAvailable}
                size='large'
            />
        </div>
    );
}
