import { useBasicState } from '../../utils/rt-states/use-basic-state';
import { ReferencesTableState } from './references-table-state';
import { ReferenceTableId } from '../knowledge-base/reference-tables/utils/reference-tables';


export function referencesTableFactory(url: string):ReferencesTableState {
    return new ReferencesTableState(url);
}

export function computeReferencesTableStateURL(referenceTableId: ReferenceTableId) {
    return `/references-table/${referenceTableId}`;
}

export function useReferencesTableState(referenceTableId: ReferenceTableId|undefined): ReferencesTableState|undefined {
    const state = useBasicState<ReferencesTableState>(
        (url) => referencesTableFactory(url),
        referenceTableId ? computeReferencesTableStateURL(referenceTableId) : undefined,
    );

    return state;
}
