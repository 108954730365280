import { ArgUserId, ArgUserInfo } from 'src/components/basic';
import {
    OntologyId,
    OntologyItemStyle,
    OntologyLinkType,
    OntologyMetaProperty,
    OntologyObjectType,
    OntologyProperty,
} from '../universes/ontology/types';
import { Rule, RuleProcessed, Scope, ScopeProcessed } from './policy';
import {
    RetentionPolicyActionTargetKind,
    RetentionPolicyLinkKind,
    RetentionPolicyVertexEdge,
} from '../universes/retention/types';
import { UniverseId, UniversePropertyName, UniverseVertexTypeName } from '../../exploration/model/universe';
import { DataType } from '../../components/common/data-types';
import { RawOntology } from './raws';

export interface AddUserDTO {
    displayName: string;
    firstName?: string;
    lastName?: string;
    userName: string;
    isActive: boolean;
    roleIds?: string[];
    password: string;
    passwordChangeRequired: boolean;

    profile?: Record<string, any>;
}

export interface EditUserDTO {
    firstName?: string;
    lastName?: string;
    displayName?: string;
    userName?: string;
    isActive?: boolean;
    roleIds?: string[];

    profile?: Record<string, any>;
}

export interface DetailedOntology extends Ontology {
    lastPublishedDate: Date;
    lastPublishedBy: ArgUserInfo;
    version: OntologyVersion;
}

export interface GetOntologySchemaDTO {
    objectTypes: OntologyObjectType[];
    linkTypes: OntologyLinkType[];
    metaProperties: OntologyMetaProperty[];
    createdBy: { id: string; displayName: string };
    createdDate: Date;
    lastUpdatedBy: { id: string; displayName: string };
    lastUpdatedDate: string;
}

export interface GetOntologyStyleDTO {
    objectTypes: Record<string, OntologyItemStyle>;
    linkTypes: Record<string, OntologyItemStyle>;
}

export interface PutOntologyStyleDTO {
    style: GetOntologyStyleDTO;
}

export interface ListOntologies {
    ontologies: Ontology[];
    kbFeedingDataByOntologyId?: Record<OntologyId, UniverseKBFeedingData>;
}

export interface Ontology extends Omit<RawOntology, 'createdDate' | 'lastUpdatedDate'> {
    createdDate: Date;
    lastUpdatedDate: Date;
}

export interface OntologyVersion {
    published?: number;
    required?: number;
    latest: number;
}

export interface AddPolicyDTO {
    universeId: string;
    name: string;
    description?: string;
    scopes: unknown[];
    enabled: boolean;
    statement: { Actions: Rule[] };
    type: string;
    position?: string;
    selectedPolicyId?: string;
}

export interface AddGroupDTO {
    name: string;
    description: string;
    ownerIds?: string[];
}

export interface EditGroupDTO {
    name: string;
    description?: string;
    ownerIds: string[];
}

export interface AddGroupMembersDTO {
    userIds: ArgUserId[];
    groupIds: string[];
}

export interface EditGroupMembersDTO {
    userIds: string[];
    groupIds: string[];
}

export interface GetPolicyResponse {
    [key: string]: { vectorPolicies: Policy[] };
}

export type PolicyId = string;

export interface Policy {
    id: PolicyId;

    createdBy?: ArgUserInfo;
    createdDate?: Date;
    description?: string | null;
    enabled: boolean;
    lastPublishedBy?: ArgUserInfo;
    lastPublishedDate?: Date;
    name: string;
    scopes: Scope[];
    statement: { Actions: Rule[]; StatementType: string };
    type: string;
    universeId: UniverseId;
    updateProgress?: number;
    updateStatus?: string;
    version: { latest: number; published: number; required: number };
    settings?: string | null;
}

export interface PolicyProcessed extends Policy {
    statement: { Actions: RuleProcessed[]; StatementType: string };
    scopes: ScopeProcessed[];
}

export type GetPolicyDto = Record<string, Policy>;

// TODO: also useful outside of roles, hence rename to ApiScope
export type RolesScope = string; //'data_exploration' | 'data_preparation' | 'data_collection' | 'admin' | 'proceo';

export type RoleId = string;

export interface Role {
    id: RoleId;
    name: string;
    description: string;
    displayName: string;
    scope: string;
    readOnly: boolean;
    hasDraft: boolean;
    isDeleted: boolean;
    lastPublishBy: ArgUserInfo;
    publishDate?: Date;
    permissions: RolePermission[];
}

export type RolePermissionId = string;

export interface RolePermission {
    id: RolePermissionId;
    roleId: RoleId;
    isAccess: boolean;
    key: string;
    name: string;
    scope: string;
    section: string;
    type: string;
}

export interface AddRoleDTO {
    name: string;
    description?: string;
    scope: string;
}

export interface EdgeVertextRetentionDTO {
    ontologyId: OntologyId;
    edgeOrVertexName: string;
    type: RetentionPolicyActionTargetKind;
}

export interface GetEdgeVertextRetentionPolicy {
    ontologyId: OntologyId;
    edgeOrVertexName: string;
    retentionLinkKind: RetentionPolicyLinkKind;
}

export interface AddEditEdgeVertextRetentionDTO extends GetEdgeVertextRetentionPolicy {
    retention: RetentionPolicyVertexEdge;
}

export interface DeleteEdgeVertexRetentionDTO {
    ontologyId: OntologyId;
    edgeOrVertexName: string;
    retentionLinkKind: RetentionPolicyLinkKind;
}


export interface DetailedWebHookDTO {
    name: string;
    description?: string;
    isEnabled?: boolean;
    events: RawWebhookEvent[];
    behavior?: RawHookBehavior;
    target: RawHookTarget;
    options?: RawWebHookOptions;
    id: string;
    notifyError?: RawHookNotifyError;
    createdBy?: ArgUserInfo;
    createdDate?: string;
    lastUpdatedBy?: ArgUserInfo;
    lastUpdatedDate?: string;
}

export interface RawHookNotifyError {
    type: 'http' | 'timeout';
    detail: {
        code?: number;
    };
}

export interface RawHookBehavior {
    detailLevel?: 'None' | 'Minimal' | 'Full';
    interactivity?: 'None' | 'Synchronized' | 'Full';
    ignoreError?: boolean;
}

export interface RawHookTarget {
    url: string;
    connectTimeout?: number;
    readTimeout?: number;
    basicAuthName?: string;
    basicAuthPassword?: string;
    certificate?: string;
    headers?: Record<string, string>;
}

export interface RawWebhookEvent {
    id: string;
    isEnabled: boolean;
}

export type RawWebHookOptions = Record<string, any>;

export interface AddEditOntology {
    ontologyId: OntologyId;
    name: UniverseVertexTypeName;
    newDisplayName: string;
    newProperties: AddEditOntologyProperty[];
}

export interface AddEditOntologyProperty extends Omit<OntologyProperty, 'name'> {
    name?: UniversePropertyName;
}

export interface AddEditUserProfileField {
    displayName: string;
    isMultivalued: boolean;
    baseType?: DataType;
    customDataType?: DataType;
    possibleValues?: string[];
}

export interface ExtensionState {
    enabled: boolean;
}

export interface ExtensionImage {
    content: string;
    title?: string;
    description?: string;
}

export interface ExtensionMetadata {
    version: string;
    author: string;
    description?: string;
    icon?: string;
    packageSize: number;
}

export interface ExtensionDTO {
    name: string;
    state: ExtensionState;
    metadata: ExtensionMetadata;
    images?: ExtensionImage[];
    createdBy: ArgUserInfo;
    createdDate: string;
    lastUpdatedBy: ArgUserInfo;
    lastUpdatedDate: string;
    lastPublishedBy?: ArgUserInfo;
    lastPublishedDate?: string;
    contentHash?: string;
    tags?: string[];
}

export interface UniverseKBFeedingData {
    universeId: UniverseId;
    /**
     * current order over total count
     * @example [1, 8]
     */
    order: [number, number];
    lastSynchronizationStatus?: UniverseKBLastFeedingStatus;
    synchronizationStatus: UniverseKBFeedingStatus;
    lastSynchronizationDate?: Date;
    firstSynchronizationDate?: Date;
    lastUpdatedDate?: Date;

    current?: UniverseKBFeedingCurrentSynchronization;
    previous?: UniverseKBFeedingLastSynchronization;
}

export interface UniverseKBFeedingCurrentSynchronization {
    rangeDates: [Date, Date];
    object?: UniverseKBFeedingSynchronizationProcess;
    edges?: UniverseKBFeedingSynchronizationProcess;
}

export interface UniverseKBFeedingLastSynchronization {
    rangeDates: [Date, Date];
    synchronizationStatus: UniverseKBLastFeedingStatus;
}

export interface UniverseKBFeedingSynchronizationProcess {
    total?: number;
    ratio?: number;
};

export enum UniverseKBLastFeedingStatus {
    Success = 'Success',
    Failed = 'Failed',
}

export enum UniverseKBFeedingStatus {
    Pending = 'Pending',
    Running = 'Running',
    Stopped = 'Stopped',
    Failed = 'Failed',
}
