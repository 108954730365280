import { isEmpty } from 'lodash';
import Debug from 'debug';

import { Tool } from './tool';
import { ToolContext } from './tool-context';
import { useToolItem } from './use-tool-item';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { TargetEnvironmentContext } from './target-tool-context';

const debug = Debug('basic.arg-tools.ToolsRegistryByType');

const toolsByMimeType:Record<string, Tool<any>[]> = {};

const NO_TOOLS:Tool<any>[] = [];

export function registerToolItemForMimeType<T=undefined, M=any>(mimeType: string, tool: Tool<TargetEnvironmentContext<T, M>>) {
    let tools:Tool<any>[] = toolsByMimeType[mimeType];
    if (!tools) {
        tools = [];
        toolsByMimeType[mimeType] = tools;
    }

    tools.push(tool);
}

export function useToolItemByMimeType<M=any, T=undefined>(
    toolContext: ToolContext<TargetEnvironmentContext<T, M>>,
    path: string,
    order: number|undefined = 100,
    type: 'marker'|'group' = 'marker',
) {
    useToolItem<TargetEnvironmentContext<T, M>>(toolContext, {
        path,
        order,
        type,
        computeChildren(
            parent: Tool<TargetEnvironmentContext<T, M>>,
            environmentContext: TargetEnvironmentContext<T, M>,
            progressMonitor: ProgressMonitor,
        ):Tool<TargetEnvironmentContext<T, M>>[] {
            const mimeTypes = environmentContext.getItemsMimeTypes?.();
            if (isEmpty(mimeTypes)) {
                debug('computeChildren', 'No mime types=', mimeTypes);

                return NO_TOOLS;
            }

            if (mimeTypes!.length === 1) {
                const tools:Tool<TargetEnvironmentContext<T, M>>[] = toolsByMimeType[mimeTypes![0]];

                debug('computeChildren', 'ONE mime types', 'tools=', tools, 'mimeTypes=', mimeTypes);

                return tools || NO_TOOLS;
            }

            const result: Tool<TargetEnvironmentContext<T, M>>[] = [];
            mimeTypes!.forEach((mimeType) => {
                const tools = toolsByMimeType[mimeType];

                if (isEmpty(tools)) {
                    return;
                }

                result.push(...tools);
            });

            debug('computeChildren', 'MULTIPLE mime types', 'result=', result, 'mimeTypes=', mimeTypes);

            return result;
        },
    });
}
