import { useMemo } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { ArgTag, ClassNamesFn, ClassValue, renderText, useClassNames } from '../../../../../components/basic';
import { UniverseKBFeedingData, UniverseKBFeedingStatus } from '../../../../models/dtoApi';
import { DateByUser } from '../../../../../components/common/date-by-user';
import { PropertyInformation } from './types';
import { KBFeedingPropertyValue } from './kb-feeding-property-value';
import { ResultTag } from 'src/components/common/tags/result-tag';

import './kb-feeding-monitoring-main-infos.less';

const messages = defineMessages({
    order: {
        id: 'settings-kb-feeding-monitoring-main-infos.order',
        defaultMessage: 'Order',
    },
    orderOf: {
        id: 'settings-kb-feeding-monitoring-main-infos.orderOf',
        defaultMessage: '<bold>{order}</bold>/{total}',
    },
    firstSynchronizationDate: {
        id: 'settings-kb-feeding-monitoring-main-infos.firstSynchronizationDate',
        defaultMessage: 'First execution date',
    },
    lastSynchronizationDate: {
        id: 'settings-kb-feeding-monitoring-main-infos.lastSynchronizationDate',
        defaultMessage: 'Last execution date',
    },
    status: {
        id: 'settings-kb-feeding-monitoring-main-infos.status',
        defaultMessage: 'Status',
    },
    running: {
        id: 'settings-kb-feeding-monitoring-main-infos.running',
        defaultMessage: 'Running',
    },
    pending: {
        id: 'settings-kb-feeding-monitoring-main-infos.pending',
        defaultMessage: 'Pending',
    },
    stopped: {
        id: 'settings-kb-feeding-monitoring-main-infos.stopped',
        defaultMessage: 'Stopped',
    },
});

interface UniverseKBFeedingStatusDescriptor {
    label: MessageDescriptor;
    className: string;
}

const UniverseKBFeedingStatusRegistry: Record<string, UniverseKBFeedingStatusDescriptor> = {
    Running: {
        label: messages.running,
        className: 'running',
    },
    Pending: {
        label: messages.pending,
        className: 'pending',
    },
    Stopped: {
        label: messages.stopped,
        className: 'stopped',
    },
};

const CLASSNAME = 'settings-kb-feeding-monitoring-main-infos';

interface KBFeedingMonitoringMainInfosProps {
    className?: ClassValue;
    universeKBFeedingData: UniverseKBFeedingData;
}
export function KBFeedingMonitoringMainInfos({
    className,
    universeKBFeedingData,
}: KBFeedingMonitoringMainInfosProps) {
    const classNames = useClassNames(CLASSNAME);

    const propertiesData = useMemo<PropertyInformation[]>(() => getProperyInformation(universeKBFeedingData, classNames), [universeKBFeedingData, classNames]);

    return (
        <div className={classNames('&', className)}>
            {propertiesData.map((property) => (
                <KBFeedingPropertyValue key={property.key} propertyValue={property} />
            ))}
        </div>
    );
}

function getProperyInformation(universeKBFeedingData: UniverseKBFeedingData, classNames: ClassNamesFn) {
    const ret: PropertyInformation[] = [
        {
            key: 'order',
            label: messages.order,
            content: renderText(messages.orderOf, { order: universeKBFeedingData.order[0], total: universeKBFeedingData.order[1] }),
        },
        {
            key: 'firstSynchronizationDate',
            label: messages.firstSynchronizationDate,
            content: universeKBFeedingData.firstSynchronizationDate && <DateByUser date={universeKBFeedingData.firstSynchronizationDate} />,
        },
        {
            key: 'lastSynchronizationDate',
            label: messages.lastSynchronizationDate,
            content: universeKBFeedingData.lastSynchronizationDate && <DateByUser date={universeKBFeedingData.lastSynchronizationDate} />,
        },
        {
            key: 'status',
            label: messages.status,
            content: buildUniverseKBFeedingDataStatus(universeKBFeedingData, classNames),
        },
    ];

    return ret;
}

function buildUniverseKBFeedingDataStatus(universeKBFeedingData: UniverseKBFeedingData, classNames: ClassNamesFn) {
    if (universeKBFeedingData.synchronizationStatus === UniverseKBFeedingStatus.Failed) {
        return (
            <ResultTag
                className={classNames('&-status')}
                hasFailed={true}
            />
        );
    }

    const statusDescriptor = UniverseKBFeedingStatusRegistry[universeKBFeedingData.synchronizationStatus];
    if (!statusDescriptor) {
        return;
    }

    const { label, className } = statusDescriptor;

    const ret = (
        <ArgTag
            className={classNames('&-status', className)}
            size='large'
            label={label}
        />
    );

    return ret;
}
