import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { includes, isString, map, range, split, toString } from 'lodash';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import Debug from 'debug';

import { internalisationDateFormat } from '../../../utils/dates/internalisation-date-format';
import { ArgInput, ArgInputKeyPressEvent, ArgInputProps } from './arg-input';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgCalendar, CalendarMode } from '../arg-calendar/arg-calendar';
import { ArgChangeReason, ArgPlaceholderText } from '../types';
import { ArgButton } from '../arg-button/arg-button';
import { ArgFilteredMenu } from '../arg-menu/arg-filtered-menu';
import { dayjs } from '../utils/dayjs';
import { isIn } from '../utils/is-in';
import { useArgInputMask } from './masks/use-arg-input-mask';
import { ArgInputMasks } from './masks/arg-input-mask';
import { parseMask } from './masks/utils';
import { useCacheDayjs } from '../../common/scheduler/use-dayjs';

import './arg-input-date-time.less';


const debug = Debug('argonode:components:ArgInputDateTime');

const messages = defineMessages({
    defaultPlaceholderDate: {
        id: 'basic.arg-input-date-time.DefaultPlaceholderDate',
        defaultMessage: 'Select date',
    },
    defaultPlaceholderDateAndTime: {
        id: 'basic.arg-input-date-time.DefaultPlaceholderDateAndTime',
        defaultMessage: 'Select date and time',
    },
    defaultPlaceholderTime: {
        id: 'basic.arg-input-date-time.DefaultPlaceholderTime',
        defaultMessage: 'Select time',
    },
    hour: {
        id: 'basic.arg-input-date-time.Hour',
        defaultMessage: 'Hours',
    },
    minute: {
        id: 'basic.arg-input-date-time.Minute',
        defaultMessage: 'Minutes',
    },
    second: {
        id: 'basic.arg-input-date-time.Second',
        defaultMessage: 'Seconds',
    },
    mask: {
        id: 'basic.arg-input-date-time.InputMask',
        defaultMessage: '[0-3][0-9]/[0-1][0-9]/[1-2][0-9][0-9][0-9] at [0-2][0-9]:[0-5][0-9]:[0-5][0-9]',
    },
    maskPlaceholder: {
        id: 'basic.arg-input-date-time.InputMaskPlaceholder',
        defaultMessage: '31/12/2024 23:59:59',
    },
});

export enum DateTimeMode {
    DateAndTime = 'date-time',
    DateOnly = 'date',
    TimeOnly = 'time',
}

const MOMENT_FILTERED_KEY = /[0-9\/\-\.\:]/i;
const DEFAULT_MOMENT_DATE = dayjs().startOf('year');

function momentKeypress(event: ArgInputKeyPressEvent): void {
    const keyboardEvent = event.keyboardEvent;
    const key = keyboardEvent.key;

    if (key.length === 1
        && !keyboardEvent.ctrlKey
        && !keyboardEvent.altKey
        && !keyboardEvent.metaKey
        && !MOMENT_FILTERED_KEY.test(key)) {
        keyboardEvent.preventDefault();
        keyboardEvent.stopPropagation();

        return;
    }
}

export interface ArgInputDateTimeProps extends Omit<ArgInputProps<dayjs.Dayjs, any>, 'formatValue' | 'parseValue' | 'placeholder'> {
    format?: string;
    locale?: PickerLocale;
    dateTimeMode?: DateTimeMode;
    disabledDate?: ((date: dayjs.Dayjs) => boolean);
    disabledHour?: (HH: number) => boolean;
    disabledMinute?: (mm: number) => boolean;
    disabledSecond?: (ss: number) => boolean;
    placeholder?: ArgPlaceholderText | null;

    mask?: ArgInputMasks;
    localMode?: boolean; // Local or UTC ?
}

export function ArgInputDateTime(props: ArgInputDateTimeProps) {
    const {
        format,
        onChange,
        readOnly,
        dateTimeMode = DateTimeMode.DateAndTime,
        disabledDate,
        disabledHour,
        disabledMinute,
        disabledSecond,
        initialValue,
        value: externalValue,
        placeholder,
        className,
        onClear,
        mask: externalMask,
        localMode,
        ...otherProps
    } = props;
    const intl = useIntl();
    const classNames = useClassNames('arg-input-date-time');

    const useInternalValue = !isIn(props, 'value');

    const [popoverTimeVisible, setPopoverTimeVisible] = useState<boolean>(false);
    const [popoverDateVisible, setPopoverDateVisible] = useState<boolean>(false);
    const [internalValue, setInternalValue] = useState<dayjs.Dayjs | undefined>(initialValue);

    const value: dayjs.Dayjs | undefined = useInternalValue ? internalValue : externalValue;

    const mask = useMemo<ArgInputMasks>(() => {
        if (externalMask) {
            if (isString(externalMask) && externalMask.includes('[')) {
                const result = parseMask(externalMask);

                return result;
            }

            return externalMask;
        }

        const mask = intl.formatMessage(messages.mask);
        const result = parseMask(mask);

        return result;
    }, [externalMask, intl]);

    const defaultPlaceHolder = useMemo(() => {
        if (placeholder === null) {
            return undefined;
        }
        if (placeholder) {
            return placeholder;
        }
        switch (dateTimeMode) {
            case DateTimeMode.DateAndTime:
                return messages.defaultPlaceholderDateAndTime;
            case DateTimeMode.DateOnly:
                return messages.defaultPlaceholderDate;
            case DateTimeMode.TimeOnly:
                return messages.defaultPlaceholderTime;
        }
    }, [dateTimeMode, placeholder]);

    useEffect(() => {
        debug('initialValue', 'Update new initialValue=', initialValue);
        setInternalValue(initialValue);
    }, [initialValue]);


    const myFormat = useMemo(() => {
        if (format) {
            return format;
        }

        const dateFormat = dateTimeMode === DateTimeMode.TimeOnly ? '' : internalisationDateFormat(intl);

        const f = intl.formatTime(new Date(2000, 2, 1, 10, 20, 30), {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        });
        const timeFormat = dateTimeMode === DateTimeMode.DateOnly ? '' : f.replace('10', 'HH').replace('20', 'mm').replace('30', 'ss');

        const space = dateTimeMode === DateTimeMode.DateAndTime ? ' ' : '';

        const finalFormat = `${dateFormat}${space}${timeFormat}`;

        debug('computeFormat', 'dateFormat=', finalFormat);

        return finalFormat;
    }, [format, intl, dateTimeMode]);

    const momentFormatValue = useCallback((value: any | null): string => {
        if (value === null) {
            return '';
        }

        if (!dayjs.isDayjs(value)) {
            value = dayjs(value);
        }

        if (!value.isValid()) {
            return '';
        }

        // UTC -> Locale
        const localeDate = dayjs(value).local();

        return value.format && localeDate.format(myFormat);
    }, [myFormat]);

    const dayjsCache = useCacheDayjs();

    const momentParseValue = useCallback((value: string): dayjs.Dayjs | null => {
        console.log('momentParseValue', 'string=', value);
        if (value === '') {
            return null;
        }

        const locale = dayjs(value, myFormat);

        console.log('momentParseValue', 'string=', value, '=>', locale);

        if (!locale.isValid()) {
            return null;
        }

        // Locale => UTC
        const utc = locale.utc();

        const result = dayjsCache(utc)!;

        return result;
    }, [dayjsCache, myFormat]);


    const handleDateChanged = useCallback((date: dayjs.Dayjs | null, reason: ArgChangeReason | 'minute' | 'hour' | 'second', mode?: CalendarMode) => {
        if (includes(['selection', 'clear'], reason) && mode !== 'year') {
            setPopoverTimeVisible(false);
            setPopoverDateVisible(false);
        }

        let newDate = date;
        if (mode !== undefined && newDate) {
            // CalendarMode : the date is chosen by user in locale but given here in UTC
            // -> respect the local choice
            //console.log('newDate=', newDate.toISOString());
        }

        if (newDate && !localMode) {
            // keep and callback onChange always in utc
            newDate = newDate.utc();
        }

        if (useInternalValue) {
            setInternalValue(newDate || undefined);
        }

        debug('handleChange', 'value=', newDate);

        onChange?.(newDate, 'selection');
    }, [localMode, onChange, useInternalValue]);

    const popoverDate = useMemo<ReactNode>(() => {
        const m = value?.local();

        return <div className={classNames('&-popover', '&-date-popover')} data-testid='calendar-popover-container'>
            <ArgCalendar
                onChange={handleDateChanged}
                disabledDate={disabledDate}
                className={classNames('&-calendar')}
                value={m?.isValid() ? m : undefined}
                localMode={localMode}
            />
        </div>;
    }, [value, classNames, handleDateChanged, disabledDate, localMode]);

    const popoverTime = useMemo<ReactNode>(() => {
        const m = value?.local();

        const handleSelect = (value: number, mode: 'minute' | 'hour' | 'second') => {
            let d:dayjs.Dayjs;
            if (m) {
                d = m.set(mode, value);
            } else {
                d = dayjs().set(mode, value);
                if (d.isBefore(dayjs())) {
                    d = d.add(1, 'day');
                }
            }

            handleDateChanged(d, mode);
        };

        return <div className={classNames('&-popover', '&-time-popover')} data-testid='time-popover-container'>
            <div className={classNames('&-selectors')}>
                {map(split(myFormat, ':'), (partSplitted: string) => {
                    const part = partSplitted.substr(-2);
                    const mode = (
                        part === 'HH' ? 'hour' :
                            part === 'mm' ? 'minute' :
                                part === 'ss' ? 'second' :
                                    undefined
                    );

                    if (!mode) {
                        return;
                    }

                    const modeLabel = (
                        mode === 'hour' ? messages.hour :
                            mode === 'minute' ? messages.minute :
                                mode === 'second' ? messages.second :
                                    undefined
                    );

                    const disabledItem = (
                        mode === 'hour' ? disabledHour :
                            mode === 'minute' ? disabledMinute :
                                mode === 'second' ? disabledSecond :
                                    undefined
                    );

                    const items = range(part === 'HH' ? 24 : 60);

                    return (
                        <ArgFilteredMenu<number>
                            key={mode}
                            items={items}
                            autoScroll={true}
                            getItemLabel={toString}
                            getItemDisabled={disabledItem}
                            topRender={() => modeLabel && (
                                <span
                                    className={classNames('&-selectors-item-header', `&-selectors-item-${mode}-header`)}>
                                    {intl.formatMessage(modeLabel)}
                                </span>
                            )}
                            onSelect={(value) => handleSelect(value, mode)}
                            selected={m?.isValid() ? m?.[mode]() : undefined}
                            className={classNames('&-selectors-item', `&-selectors-item-${mode}`)}
                            menuClassName={classNames('&-selectors-item-menu', `&-selectors-item-${mode}-menu`)} />
                    );
                })}
            </div>
        </div>;
    }, [value, classNames, myFormat, handleDateChanged, disabledHour, disabledMinute, disabledSecond, intl]);

    const handlePopoverDateVisibleChange = useCallback((visible: boolean) => {
        setPopoverDateVisible(visible);
    }, [setPopoverDateVisible]);

    const handlePopoverTimeVisibleChange = useCallback((visible: boolean) => {
        setPopoverTimeVisible(visible);
    }, [setPopoverTimeVisible]);

    const handleClear = useCallback(() => {
        if (useInternalValue) {
            setInternalValue(undefined);
        }

        if (!onClear) {
            onChange?.(null, 'clear');

            return;
        }

        onClear();
    }, [useInternalValue, onChange, onClear]);

    const maskProps = useArgInputMask(mask, value, momentFormatValue, onChange, handleClear);

    return (
        <ArgInput<dayjs.Dayjs>
            {...otherProps}
            value={value}
            debounce={false}
            readOnly={readOnly}
            popoverTrigger={undefined}
            placeholder={defaultPlaceHolder}
            className={classNames('&', className)}
            onKeyPress={momentKeypress}
            onChange={handleDateChanged}
            parseValue={momentParseValue}
            formatValue={momentFormatValue}
            popoverFitWidth={false}
            right={
                !readOnly && <>
                    {dateTimeMode !== DateTimeMode.TimeOnly &&
                        <ArgButton
                            icon='icon-calendar'
                            type='ghost'
                            size='small'
                            popoverVisible={popoverDateVisible}
                            onPopoverVisibleChange={handlePopoverDateVisibleChange}
                            className={classNames('&-open', 'arg-input-button', 'arg-input-right')}
                            popover={popoverDate}
                            popoverClassName={classNames('&-popover', '&-date-popover')}
                            popoverPlacement='topRight'
                            data-testid='input-button-right-calendar'
                        />}
                    {dateTimeMode !== DateTimeMode.DateOnly &&
                        <ArgButton
                            icon='icon-clock'
                            type='ghost'
                            size='small'
                            popoverVisible={popoverTimeVisible}
                            onPopoverVisibleChange={handlePopoverTimeVisibleChange}
                            className={classNames('&-open', 'arg-input-button', 'arg-input-right')}
                            popover={popoverTime}
                            popoverClassName={classNames('&-popover', '&-time-popover')}
                            popoverPlacement='topRight'
                            data-testid='input-button-right-clock'
                        />}
                </>
            }
            onClear={handleClear}
            {...maskProps}
        />
    );
}
