import { IntlShape } from 'react-intl';

import { ArgonosApplicationBranding } from 'src/components/application/argonos-application-branding';
import { ArgonosModuleBranding, getArgonosModuleBranding } from 'src/components/application/argonos-modules-branding';
import { ArgonosModulesRegistry } from 'src/components/application/argonos-modules-registry';
import { ArgonosModule, ArgonosModuleId } from 'src/components/application/modules';
import { computeText, ProgressMonitor, SubProgressMonitor } from 'src/components/basic';
import { isResponse404 } from 'src/components/basic/utils/response-error';
import { AppSettingsKey } from 'src/model/application-settings';
import { SettingsConnector } from 'src/settings/connectors/settings-connector';
import { ApplicationOrModuleBranding, ApplicationOrModuleBrandingUpdate } from 'src/settings/models/visual-identity';
import { ConnectorRequestInit } from 'src/utils/connector';
import { getSettingsApi } from 'src/utils/connectors/api-url';
import { BaseConnector } from 'src/utils/connectors/base-connector';
import { Environment } from 'src/utils/environment';

export class BrandingConnector extends BaseConnector {
    private static instance: BrandingConnector;

    static getInstance(): BrandingConnector {
        if (!BrandingConnector.instance) {
            BrandingConnector.instance = new BrandingConnector('settings.branding', getSettingsApi());
        }

        return BrandingConnector.instance;
    }

    async getAppBranding(
        progressMonitor: ProgressMonitor,
    ): Promise<ArgonosApplicationBranding | undefined> {
        const applicationId = Environment.appId;

        let descriptor;
        try {
            descriptor = await SettingsConnector.getInstance().getAppSettingsJSON(applicationId, progressMonitor);
        } catch (x) {
            if (progressMonitor.isCancelled) {
                throw x;
            }

            if (isResponse404(x)) {
                return undefined;
            }
            console.error('Can not get application branding', x);

            throw x;
        }

        const result: ArgonosApplicationBranding = {
            ...descriptor,
            applicationId,
            brandingLogoURL: descriptor?.hasLogo && SettingsConnector.getInstance().computeAppSettingsURL(`${applicationId}-logo`),
            brandingIconURL: descriptor?.hasIcon && SettingsConnector.getInstance().computeAppSettingsURL(`${applicationId}-icon`),
        };

        return result;
    }

    async getModuleBranding(
        moduleId: ArgonosModuleId,
        progressMonitor: ProgressMonitor,
    ): Promise<ArgonosModuleBranding | null> {
        const moduleBrandingKey = `${Environment.appId}-${moduleId}`;

        let descriptor;
        try {
            descriptor = await SettingsConnector.getInstance().getAppSettingsJSON(moduleBrandingKey, progressMonitor);
        } catch (x) {
            if (isResponse404(x)) {
                return null;
            }
            console.error('Can not get customized module settings', x);

            throw x;
        }

        const ret: ArgonosModuleBranding = {
            ...descriptor,
            brandingLogoURL: descriptor?.hasLogo && SettingsConnector.getInstance().computeAppSettingsURL(`${moduleBrandingKey}-logo`),
            brandingIconURL: descriptor?.hasIcon && SettingsConnector.getInstance().computeAppSettingsURL(`${moduleBrandingKey}-icon`),
            moduleId: moduleId,
        };

        return ret;
    }

    private async getAppSettingsBlob(
        key: AppSettingsKey,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Blob> {
        const url = `${this.api}/application/settings/${encodeURIComponent(key)}`;

        const options: ConnectorRequestInit = {
            method: 'GET',
            noCache: false,
        };

        const result = await this.request(url, options, progressMonitor);

        if (!(result instanceof Blob)) {
            throw new Error('Invalid response type (not a BLOB)');
        }

        return result;
    }

    async fetchSingleBrandingBlob(
        key: string,
        type: string,
        progressMonitor: SubProgressMonitor,
    ): Promise<Blob | undefined> {
        const subMonitor = new SubProgressMonitor(progressMonitor, 1);

        return await this.getAppSettingsBlob(`${key}-${type}`, subMonitor).catch(() => {
            return undefined;
        });
    }

    async fetchAllBrandingBlobs(
        settingsKey: string,
        branding: ApplicationOrModuleBranding,
        progressMonitor: ProgressMonitor,
    ): Promise<[Blob | undefined, Blob | undefined]> {
        const blobPromises: Promise<Blob | undefined>[] = [];

        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        if (branding.brandingLogoURL) {
            blobPromises.push(this.fetchSingleBrandingBlob(settingsKey, 'logo', sub1).catch(() => {
                return undefined;
            }));
        } else {
            blobPromises.push(Promise.resolve(undefined));
        }

        const sub2 = new SubProgressMonitor(progressMonitor, 1);
        if (branding.brandingIconURL) {
            blobPromises.push(this.fetchSingleBrandingBlob(settingsKey, 'icon', sub2).catch(() => {
                return undefined;
            }));
        } else {
            blobPromises.push(Promise.resolve(undefined));
        }

        return Promise.allSettled(blobPromises).then((results) => {
            return [
                results[0].status === 'fulfilled' ? results[0].value : undefined,
                results[1].status === 'fulfilled' ? results[1].value : undefined,
            ];
        });
    }

    async fetchAllModuleBrandingBlobs(
        appBranding: ArgonosApplicationBranding,
        intl: IntlShape,
        progressMonitor: ProgressMonitor,
    ): Promise<Promise<ApplicationOrModuleBrandingUpdate>[]> {
        return ArgonosModulesRegistry.getInstance().list().map(async (argonosModule: ArgonosModule) => {
            const moduleBranding: ApplicationOrModuleBrandingUpdate = {
                id: argonosModule.id,
                brandingName: '',
            };

            try {
                const result = await getArgonosModuleBranding(argonosModule.id, new SubProgressMonitor(progressMonitor, 1));
                if (!result) {
                    return moduleBranding;
                }

                moduleBranding.brandingName = computeText(intl, result.brandingName) || '';
                const settingsKey = `${appBranding.applicationId}-${argonosModule.id}`;

                const [logo, icon] = await BrandingConnector.getInstance().fetchAllBrandingBlobs(settingsKey, result, progressMonitor);

                return { ...moduleBranding, logo, icon };
            } catch (error) {
                console.error(error);
            }

            return moduleBranding;
        }).value();
    }

    async fetchAppBrandingBlobs(
        applicationBranding: ArgonosApplicationBranding,
        intl: IntlShape,
        progressMonitor: ProgressMonitor,
    ): Promise<ApplicationOrModuleBrandingUpdate> {
        const appBranding: ApplicationOrModuleBrandingUpdate = {
            id: applicationBranding.applicationId,
            brandingName: computeText(intl, applicationBranding.brandingName) || '',
        };

        const [logo, icon] = await BrandingConnector.getInstance().fetchAllBrandingBlobs(appBranding.id!, applicationBranding, progressMonitor);

        return { ...appBranding, logo, icon };
    }
}
