import { defineMessages } from 'react-intl';

import { renderText } from 'src/components/basic';

const messages = defineMessages({
    rangeDates: {
        id: 'settings.kb-feeding-monitoring-panel.utils.rangeDates',
        defaultMessage: 'from {start, date} {start, time}<br></br>to {end, date} {end, time}',
    },
    fullRange: {
        id: 'settings.kb-feeding-monitoring-panel.utils.fullRange',
        defaultMessage: 'In its entirety',
    },
});

// When KB is reset, the start date of the synchronization is set to january 1st 0001
const RESET_KB_START_DATE = '0001-01-01T00:00:00';

export function buildRangeDatesContent(rangeDates: Date[]) {
    const startDate = rangeDates[0];
    const endDate = rangeDates[1];

    if (startDate.getTime() === new Date(RESET_KB_START_DATE).getTime()) {
        return renderText(messages.fullRange);
    }

    return renderText(messages.rangeDates, { start: startDate, end: endDate });
}
