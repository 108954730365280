import { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ProgressMonitor, registerToolItemForMimeType } from '../../../../components/basic';
import {
    PROCESS_COMPONENT_MIME_TYPE,
    ProcessEnvironmentContext,
} from '../../../environment-contexts/process-environment-context';
import { PreparationEnvironmentContext } from '../../../environment-contexts/preparation-environment-context';
import { ProcessComponent } from '../../../model/process';
import { getReferenceTableName } from '../../../process/utils';
import { PreparationPermissions } from '../../../permissions/permissions';
import { ReferenceTablesConnector } from '../utils/references-tables-connector';
import { DeleteItemModal } from '../../../../components/common/modal2/delete-item/delete-item-modal';
import { ReferenceTable, ReferenceTableId } from '../utils/reference-tables';
import { PREPARATION_REFERENCE_TABLE_MIME_TYPE } from '../../../environment-contexts/mime-types';
import { runReferenceTablesState } from '../../../rt-states/use-preparation-state-access';
import { ReferenceTablesState } from '../../../rt-states/reference-tables-state';


const messages = defineMessages({
    deleteReferenceTable: {
        id: 'preparation.reference-tables.actions.delete.DeleteReferenceTable',
        defaultMessage: 'Delete the reference table',
    },
    title: {
        id: 'preparation.reference-tables.actions.delete.Title',
        defaultMessage: 'Delete a reference table',
    },
    descriptionTitle: {
        id: 'preparation.reference-tables.actions.delete.DescriptionTitle',
        defaultMessage: 'Can you confirm the deletion of {name} ?',
    },
    description: {
        id: 'preparation.reference-tables.actions.delete.Description',
        defaultMessage: ' ',
    },
    confirm: {
        id: 'preparation.reference-tables.actions.delete.Confirm',
        defaultMessage: 'Please enter {token} to confirm',
    },
    deleteToken: {
        id: 'preparation.reference-tables.actions.delete.DeleteToken',
        defaultMessage: 'delete',
    },
    canNotDelete: {
        id: 'preparation.reference-tables.actions.canNotDelete',
        defaultMessage: 'Can not process the deletion.',
    },
    referenceTableIsBeingDestroyed: {
        id: 'preparation.reference-tables.actions.ReferenceTableIsBeingDestroyed',
        defaultMessage: 'The references table "{name}" is being deleted {threeDotsLoading}',
    },
});

interface ReferenceTableDeleteProps {
    environmentContext: PreparationEnvironmentContext;
    referenceTableName: ReferenceTableId;
    onClose: () => void;
}

function ReferencesTableDelete(props: ReferenceTableDeleteProps) {
    const {
        environmentContext,
        referenceTableName,
        onClose,
    } = props;

    const handleConfirm = useCallback(async (progressMonitor: ProgressMonitor) => {
        try {
            await ReferenceTablesConnector.getInstance().deleteReferenceTable(referenceTableName, progressMonitor);
        } catch (error) {
            environmentContext.notifications.snackError({
                message: messages.canNotDelete,
            });

            throw error;
        }

        await runReferenceTablesState(
            environmentContext.statesRegistry,
            async(referenceTablesState: ReferenceTablesState) => {
                referenceTablesState.handleDeleted(referenceTableName);
            },
        );

        environmentContext.notifications.snackInfo({
            message: messages.referenceTableIsBeingDestroyed,
        }, { name: referenceTableName });
    }, [environmentContext, referenceTableName]);

    return <DeleteItemModal
        title={messages.title}
        descriptionTitle={messages.descriptionTitle}
        description={messages.description}
        confirmText={messages.confirm}
        deleteToken={messages.deleteToken}
        onClose={onClose}
        onConfirm={handleConfirm}
        messageValues={{
            name: referenceTableName,
        }}
    />;
}

registerToolItemForMimeType<ProcessEnvironmentContext, ProcessComponent>(PROCESS_COMPONENT_MIME_TYPE, {
    path: 'actions/reference-tables/clear-reference',
    type: 'button',
    label: messages.deleteReferenceTable,
    visible: (environmentContext) => {
        if (!environmentContext.hasAnyPermissions<PreparationPermissions>('preparation.reference.table.drop')) {
            return false;
        }

        const component = environmentContext.firstItem();
        if (!component) {
            return false;
        }

        if (getReferenceTableName(environmentContext.componentLibrary, component) === undefined) {
            return false;
        }

        return true;
    },
    disabled: (environmentContext) => {
        const component = environmentContext.firstItem();
        if (!component) {
            return true;
        }

        if (!getReferenceTableName(environmentContext.componentLibrary, component)) {
            return true;
        }

        const referenceTable = component.options?.['TableName'];
        if (!referenceTable) {
            return true;
        }

        return false;
    },
    onClick: (props, environmentContext) => {
        const processComponent = environmentContext.firstItem()!;

        const referenceTable = processComponent.options?.['TableName'];
        if (!referenceTable) {
            return;
        }

        environmentContext.modalContext.open(
            'REFERENCE-TABLE-DELETE',
            <ReferencesTableDelete
                environmentContext={environmentContext}
                referenceTableName={referenceTable}
                onClose={() => {
                    environmentContext.modalContext.close('REFERENCE-TABLE-DELETE');
                }}
            />);
    },
});


registerToolItemForMimeType<PreparationEnvironmentContext, ReferenceTable>(PREPARATION_REFERENCE_TABLE_MIME_TYPE, {
    path: 'delete-references-table',
    type: 'button',
    label: messages.deleteReferenceTable,
    visible: (environmentContext) => {
        if (!environmentContext.hasAnyPermissions<PreparationPermissions>('preparation.reference.table.drop')) {
            return false;
        }

        return true;
    },
    onClick: (props, environmentContext) => {
        const referenceTable = environmentContext.firstItem()?.name;
        if (!referenceTable) {
            return;
        }

        environmentContext.modalContext.open(
            'REFERENCE-TABLE-DELETE',
            <ReferencesTableDelete
                environmentContext={environmentContext}
                referenceTableName={referenceTable}
                onClose={() => {
                    environmentContext.modalContext.close('REFERENCE-TABLE-DELETE');
                    environmentContext.navigate(`?reload=${new Date().getTime()}`);
                }}
            />);
    },
});
